import client from './client';
import qs from 'qs';

export const getFaqs = () => client.get('/pub/faq/1');
export const getNotices = () => client.get('/pub/notices/1');
export const getUseGuides = () => client.get('/pub/use-guide/1');
export const getUserNotices = (userId: number) =>
  client.get(`/user-notices/${userId}`);
export const setUserNoticeConfirm = (userNoticeIds: number[]) =>
  client.patch(
    '/user-notices/confirm',
    qs.stringify({ userNoticeIds }, { indices: false }),
  );
export const userNoticeConfirmAll = (userId: number) =>
  client.patch('/user-notices/confirm/all', qs.stringify({ userId }));
