import { useMemo } from 'react';
import { useHistory } from 'react-router';
import moment, { Moment } from 'moment';
import styled from 'styled-components';
import { Col, DatePicker, Row, Select, Table } from 'antd';

import path from 'lib/path';
import palette from 'lib/styles/palette';
import { useCertificates } from 'hook/certificate';
import SearchInput from 'components/system/form/SearchInput';
import { Typography } from 'components/system';
import { ColumnsType } from 'antd/lib/table';

const CertificateCompleteBlock = styled.div`
  .ant-radio-button-wrapper {
    width: 50px;
  }
`;

const CertificateComplete = () => {
  const history = useHistory();
  const {
    certificates,
    loading,
    page,
    setPage,
    searchType,
    setSearchType,
    searchValue,
    searchCertificates,
    finishDate,
    setFinishDate,
  } = useCertificates(true);
  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: '인증신청번호',
        align: 'center',
        dataIndex: 'certRequestNo',
      },
      {
        title: '제품명 (용량)',
        render: (row) => (
          <Typography.Text
            type="secondary"
            style={{
              cursor: 'pointer',
              fontWeight: 500,
              color: palette.text.black,
            }}
            onClick={() => showCertificate(row)}
          >
            {`${row.estimateTarget.productDetails[0].productNameEn} ${
              row.estimateTarget.netWeight
                ? `(${row.estimateTarget.netWeight} ${row.estimateTarget.netWeightUnit})`
                : '( - )'
            }`}
          </Typography.Text>
        ),
      },
      {
        title: '제조사',
        align: 'center',
        dataIndex: 'manufacturerNameEn',
      },
      {
        title: '국가',
        align: 'center',
        render: ({ estimateTarget }) => estimateTarget.countryName,
      },
      {
        title: '인증 완료일',
        align: 'center',
        dataIndex: 'certFinYmd',
      },
    ],
    [],
  );
  const searchTypeOptions = useMemo(
    () => [
      { label: '전체', value: 'all' },
      { label: '제품명', value: 'productNameEn' },
      {
        label: '국가명',
        value: 'countryNameKo',
      },
    ],
    [],
  );
  const handleChangeFinishDate = (
    dates: (Moment | null)[] | null,
    [lower, upper]: [string, string],
  ) => {
    if (dates?.length === 3) {
      setFinishDate({ lower: '', upper: '' });
    } else {
      setFinishDate({ lower, upper });
    }
  };

  const showCertificate = (certificate: any) => {
    history.push(`${path.certificate.complete}/${certificate.certTargetId}`);
  };
  return (
    <CertificateCompleteBlock>
      <Row align="middle" justify="end" gutter={8}>
        <Col>
          <Row align="middle" gutter={[8, 8]}>
            <Col>
              <Typography.Text type="secondary">인증 완료일</Typography.Text>
            </Col>
            <Col>
              <DatePicker.RangePicker
                value={[
                  finishDate.lower ? moment(finishDate.lower) : moment(),
                  finishDate.upper ? moment(finishDate.upper) : moment(),
                ]}
                format="YYYY-MM-DD"
                ranges={{
                  전체: [moment(), moment()],
                  '1개월': [moment().subtract(1, 'months'), moment()],
                  '6개월': [moment().subtract(6, 'months'), moment()],
                  '1년': [moment().subtract(12, 'months'), moment()],
                }}
                placeholder={['전체', '전체']}
                onChange={handleChangeFinishDate}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row gutter={8}>
            <Col>
              <Select
                options={searchTypeOptions}
                value={searchType}
                onChange={setSearchType}
                style={{ width: 100 }}
              />
            </Col>
            <Col>
              <SearchInput
                placeholder="검색"
                defaultValue={searchValue}
                disabled={searchType === 'all'}
                onSearch={searchCertificates}
                style={{ width: 200 }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={certificates.content}
        rowKey={(row) => row.certTargetId}
        loading={loading}
        style={{ marginTop: 16 }}
        pagination={{
          current: page,
          pageSize: certificates.size,
          total: certificates.totalElements,
          onChange: setPage,
        }}
        scroll={{ x: 576 }}
      />
    </CertificateCompleteBlock>
  );
};

export default CertificateComplete;
