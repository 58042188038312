const prefix = 'app';
class Storage {
  static getItem(key) {
    const stringifiedItem = localStorage.getItem(`${prefix}.${key}`);
    return stringifiedItem ? JSON.parse(stringifiedItem) : null;
  }

  static setItem(key, value) {
    if (!key) throw Error('invalid storage item key');
    localStorage.setItem(`${prefix}.${key}`, JSON.stringify(value));
  }

  static removeItem(key) {
    localStorage.removeItem(`${prefix}.${key}`);
  }

  constructor() {
    throw new Error('There is no need to create an instance');
  }
}

export default Storage;
