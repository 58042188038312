import { Switch, Route } from 'react-router-dom';

import Error404Page from 'pages/error/Error404Page';
import path from 'lib/path';
import ProductSampleRouter from 'routers/manufacturer/ProductSampleRouter';
import ProductRouter from 'routers/manufacturer/ProductRouter';

const ManufacturersRouter = () => {
  return (
    <Switch>
      <Route path={path.manufacturer.product.root} component={ProductRouter} />
      <Route
        path={path.manufacturer.productSample.root}
        component={ProductSampleRouter}
      />
      <Route path="*" component={Error404Page} />
    </Switch>
  );
};

export default ManufacturersRouter;
