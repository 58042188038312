import { createAction } from 'typesafe-actions';
import { IIngredientOfBox } from './types';

export const ADD_INGREDIENT_TO_BOX =
  'material/ingredient/ADD_INGREDIENT_TO_BOX';
export const addIngredientToBox = createAction(
  ADD_INGREDIENT_TO_BOX,
)<IIngredientOfBox>();
// inciName 으로 필터해서 제거
export const DELETE_INGREDIENT_TO_BOX =
  'material/ingredient/DELETE_INGREDIENT_TO_BOX';
export const deleteIngredientToBox = createAction(
  DELETE_INGREDIENT_TO_BOX,
)<number>();
