import { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

import {
  ERawMaterialSearchType,
  IEfficacy,
  IFormulaPurpose,
} from 'types/material/rawMaterialSearch';
import * as rawMaterialSearchApi from 'lib/api/material/rawMaterialSearch';

export const useEfficaciesAndFormulaPurposes = () => {
  const {
    data: efficaciesAndFormulaPurposes = {
      allEfficacies: [],
      allFormulaPurposes: [],
    },
    isFetching: getLoading,
  } = useQuery(
    'material/rawMaterialSearch/getEfficaciesAndFormulaPurposes',
    rawMaterialSearchApi.getEfficaciesAndFormulaPurposes,
    { select: (res) => res.data.result },
  );

  return useMemo(
    () => ({
      efficacies: efficaciesAndFormulaPurposes.allEfficacies,
      formulaPurposes: efficaciesAndFormulaPurposes.allFormulaPurposes,
      getLoading,
    }),
    [efficaciesAndFormulaPurposes, getLoading],
  );
};

export const useRawMaterialSearch = () => {
  const location = useLocation();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [page, setPage] = useState<number>(1);
  const [selectedEfficacies, setSelectedEfficacies] = useState<IEfficacy[]>([]);
  const selectEfficacy = (efficacy: IEfficacy) => {
    setPage(1);
    if (selectedEfficacies.includes(efficacy)) {
      setSelectedEfficacies((draft) =>
        draft.filter((item) => item !== efficacy),
      );
    } else {
      setSelectedEfficacies((draft) => draft.concat(efficacy));
    }
  };

  const [selectedFormulaPurposes, setSelectedFormulaPurposes] = useState<
    IFormulaPurpose[]
  >([]);

  const selectFormulaPurpose = (formulaPurpose: IFormulaPurpose) => {
    setPage(1);
    if (selectedFormulaPurposes.includes(formulaPurpose)) {
      setSelectedFormulaPurposes((draft) =>
        draft.filter((item) => item !== formulaPurpose),
      );
    } else {
      setSelectedFormulaPurposes((draft) => draft.concat(formulaPurpose));
    }
  };

  const searchTypeOptions = useMemo(
    () => [
      { label: '전체', value: ERawMaterialSearchType.ALL },
      { label: '원료명', value: ERawMaterialSearchType.NAME },
      { label: '원료사', value: ERawMaterialSearchType.COMPANY },
      { label: 'INCI Name', value: ERawMaterialSearchType.INCI },
      { label: '마케팅 키워드', value: ERawMaterialSearchType.MARKETING },
    ],
    [],
  );

  const [searchType, setSearchType] = useState(
    query.inciName ? ERawMaterialSearchType.INCI : ERawMaterialSearchType.ALL,
  );
  const [isExact, setIsExact] = useState(query.inciName ? true : false);
  const changeSearchType = useCallback((searchType: ERawMaterialSearchType) => {
    setPage(1);
    setSearchType(searchType);
  }, []);
  const [searchKeyword, setSearchKeyword] = useState<string>(
    query.inciName ? (query.inciName as string) : '',
  );
  const changeKeyword = useCallback((searchKeyword: string) => {
    setPage(1);
    setSearchKeyword(searchKeyword);
  }, []);
  const refreshSearch = () => {
    setPage(1);
    setSelectedEfficacies([]);
    setSelectedFormulaPurposes([]);
    setSearchKeyword('');
    setTimeout(refetch);
  };

  const efficacyIds = selectedEfficacies.map(
    ({ efficacyCategoryId }) => efficacyCategoryId,
  );
  const purposeIds = selectedFormulaPurposes.map(
    ({ formulaPurposeCategoryId }) => formulaPurposeCategoryId,
  );

  const {
    data: rawMaterialsResult = { totalElements: 0, content: [] },
    isFetching: getLoading,
    refetch,
  } = useQuery(
    [
      'material/rawMaterialSearch/searchRawMaterias',
      page,
      efficacyIds,
      purposeIds,
    ],
    () =>
      rawMaterialSearchApi.searchRawMaterials({
        page,
        efficacyIds,
        purposeIds,
        keyword: searchKeyword,
        searchType,
        isExact,
      }),
    {
      select: (res) => res.data.result,
      onSuccess: () => {
        if (isExact) {
          setIsExact(false);
        }
      },
    },
  );

  return useMemo(
    () => ({
      page,
      setPage,
      selectedEfficacies,
      selectEfficacy,
      selectedFormulaPurposes,
      selectFormulaPurpose,
      searchTypeOptions,
      searchType,
      setSearchType: changeSearchType,
      searchKeyword,
      setSearchKeyword: changeKeyword,
      searchRawMaterials: refetch,
      refreshSearch,
      rawMaterialsResult,
      getLoading,
    }),
    [
      selectedEfficacies,
      selectedFormulaPurposes,
      searchTypeOptions,
      searchType,
      changeSearchType,
      searchKeyword,
      changeKeyword,
      rawMaterialsResult,
      getLoading,
    ],
  );
};
