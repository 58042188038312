import { useLocation, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Button,
  Col,
  Checkbox,
  Form,
  Input,
  Row,
  Radio,
  Select,
  Upload,
  Spin,
  message,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { nanoid } from 'nanoid';
import moment from 'moment';

import {
  EOriginType,
  ESpecialBrandDocResponseType,
  IDisclosures,
  IRawMaterialForm,
  ISpecialBrandForm,
  IThesisGet,
} from 'types/material/rawMaterial';
import {
  exceptAlphabetRule,
  exceptKoreanRule,
  requireRule,
} from 'lib/validate';
import {
  defaultMaterialEfficacies,
  defaultMaterialFormulaPurposes,
  messages,
  rawMaterialSpecialBrandOptions,
  scrollToFirstErrorOption,
} from 'lib/consts';
import palette from 'lib/styles/palette';
import {
  useMadeCountries,
  useRawMaterial,
  useRawMaterialNameEnDuplicateCheck,
} from 'service/material/rawMaterial';
import FooterBox from 'components/FooterBox';
import { MinusIcon } from 'components/system/general/icon';
import { Typography, Tip } from 'components/system';
import { useCompany } from 'service/company';
import LockButton from 'components/system/form/LockButton';
import DisclosureForm from './DisclosureForm';

const RawMaterialBasicBlock = styled.div`
  max-width: 520px;
  margin: 0 auto;
  padding-top: 36px;

  .ant-select-arrow {
    color: ${palette.text.disabled};
  }
`;

const FormItemWithAdditionalAction = styled(Form.Item)`
  & > .ant-form-item-label > label {
    width: 100%;

    & > .ant-row {
      width: 100%;
    }
  }
`;

const HorizontalFormItem = styled(Form.Item)`
  flex-direction: row !important;

  .ant-form-item-label {
    padding-top: 8px;
    margin-right: 16px;
  }
`;

const RawMaterialBasic = () => {
  const params = useParams<{ materialId: string }>();
  const location = useLocation();
  const materialId = Number(params.materialId) || undefined;
  const updateMode = typeof materialId !== 'undefined';
  const [isDiscontinued, setIsDiscontinued] = useState(false);
  const [form] = Form.useForm<IRawMaterialForm>();
  const [theses, setTheses] = useState<(File | IThesisGet)[]>([]);
  const handleUploadThesis = (_: File, files: File[]) => {
    setTheses((draft) => draft.concat(files));
    return false;
  };
  const handleDeleteThesis = (targetIndex: number) => {
    setTheses((draft) => draft.filter((_, index) => index !== targetIndex));
  };
  const rawMaterialNameEnDuplicateCheck = useRawMaterialNameEnDuplicateCheck();
  const {
    rawMaterial,
    getLoading,
    addRawMaterial,
    addLoading,
    updateRawMaterial,
    updateLoading,
  } = useRawMaterial(materialId);
  const {
    madeCountries,
    getLoading: getMadeCountriesLoading,
  } = useMadeCountries();
  const company = useCompany();
  const [disclosures, setDisclosures] = useState<IDisclosures>({
    disclosureBrandCompanyIds: [],
    disclosureManufacturerIds: [],
    disclosureMaterialCompanyIds: [],
  });

  const handleAddRawMaterial = (rawMaterialForm: IRawMaterialForm) => {
    if (!updateMode) {
      addRawMaterial({
        ...rawMaterialForm,
        theses,
        disclosures,
      });
    } else {
      updateRawMaterial({
        ...rawMaterialForm,
        theses,
        disclosures,
      });
    }
  };

  useEffect(() => {
    form.resetFields();
    setDisclosures({
      disclosureBrandCompanyIds: [],
      disclosureManufacturerIds: [],
      disclosureMaterialCompanyIds: [],
    });
    setTheses([]);
    setIsDiscontinued(false);
  }, [location]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'development' && !updateMode) {
      form.setFieldsValue({
        materialNameEn: `My RawMaterial-${moment().format('YY-MM-DD HH:mm')}`,
        materialNameKo: '테스트 이름',
        originManufacturerName: 'DooleyWorld',
        isSelfProduction: false,
        isShowManufacturerName: true,
        madeCountry: 'Republic of Korea',
        hsCode: '12345',
        manageCode: 'MANAGE_CD',
        hasExperienceSpecialBrandDelivery: false,
        originType: EOriginType.BOTH,
        efficacies: ['가려움', '간지러움'],
        formulaPurposes: ['pH 조절제', '산도 조절제'],
        marketingKeywords: '키워드1, 키워드2, 키워드3',
        patents: ['patent1', 'patent2'],
        combinationWarningText: 'combinationWarningText!',
        effectiveIngredients: [
          {
            ingredientName: '글리세린',
            ingredientMinContent: '0.00000001',
            ingredientUnit: '%',
          },
          {
            ingredientName: '세린',
            ingredientMinContent: '0.00101010',
            ingredientUnit: '*',
          },
        ],
      });
    }
  }, []);

  useEffect(() => {
    if (rawMaterial) {
      const {
        efficacies,
        formulaPurposes,
        marketingKeywords,
        patents,
        theses,
        isDiscontinued,
        materialDisclosureBrandCompanies,
        materialDisclosureManufacturers,
        materialDisclosureMaterialCompanies,
        ...rest
      } = rawMaterial;
      setDisclosures({
        disclosureBrandCompanyIds: materialDisclosureBrandCompanies.map(
          ({ brandCompanyId }) => brandCompanyId,
        ),
        disclosureManufacturerIds: materialDisclosureManufacturers.map(
          ({ manufacturerId }) => manufacturerId,
        ),
        disclosureMaterialCompanyIds: materialDisclosureMaterialCompanies.map(
          ({ materialCompanyId }) => materialCompanyId,
        ),
      });
      form.setFieldsValue({
        ...rest,
        efficacies: efficacies.map(({ efficacyName }) => efficacyName),
        formulaPurposes: formulaPurposes.map(
          ({ formulaPurposeName }) => formulaPurposeName,
        ),
        marketingKeywords: marketingKeywords
          .map(({ keyword }) => keyword)
          .join(', '),
        patents: patents.map(({ patentNumber }) => patentNumber),
      });
      setTheses(theses);
      if (isDiscontinued) {
        setIsDiscontinued(true);
      }
    }
  }, [rawMaterial]);
  const handleGenerateContent = (open: boolean) =>
    `실 생산자명 ${open ? '공개' : '비공개'}`;
  return (
    <RawMaterialBasicBlock>
      <Spin spinning={getLoading || getMadeCountriesLoading}>
        <Form
          layout="vertical"
          form={form}
          onFinish={handleAddRawMaterial}
          scrollToFirstError={scrollToFirstErrorOption}
        >
          <Form.Item
            label="원료명 (영문)"
            name="materialNameEn"
            rules={[
              requireRule,
              exceptKoreanRule,
              {
                validator: async (_, value) => {
                  if (!updateMode || value !== rawMaterial?.materialNameEn) {
                    const res = await rawMaterialNameEnDuplicateCheck(value);
                    if (res.data.result) throw Error('이미 사용중인 원료명');
                  }
                },
              },
            ]}
          >
            <Input placeholder="영문" disabled={isDiscontinued} />
          </Form.Item>
          <Form.Item
            label="원료명 (국문)"
            name="materialNameKo"
            initialValue=""
          >
            <Input placeholder="국문" disabled={isDiscontinued} />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prev: any, next: any) =>
              prev.isSelfProduction !== next.isSelfProduction ||
              prev.isShowManufacturerName !== next.isShowManufacturerName
            }
          >
            {({ getFieldValue }) => (
              <FormItemWithAdditionalAction
                label={
                  <Row justify="space-between">
                    <Col>원료 실 생산자명 (영문)</Col>
                    <Col>
                      <Row gutter={8} align="middle">
                        <Col>
                          <Form.Item
                            noStyle
                            name="isSelfProduction"
                            valuePropName="checked"
                            initialValue={false}
                          >
                            <Checkbox
                              disabled={isDiscontinued}
                              onChange={(e) =>
                                e.target.checked &&
                                form.setFieldsValue({
                                  originManufacturerName: company.companyNameEn,
                                })
                              }
                            >
                              자체 제조
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col>
                          <Form.Item
                            noStyle
                            name="isShowManufacturerName"
                            valuePropName="open"
                            initialValue={true}
                          >
                            <LockButton
                              onGenerateContent={handleGenerateContent}
                              disabled={isDiscontinued}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                }
                name="originManufacturerName"
                rules={[requireRule, exceptKoreanRule]}
              >
                <Input
                  placeholder="원료의 실 생산자를 영문으로 입력"
                  disabled={isDiscontinued || getFieldValue('isSelfProduction')}
                />
              </FormItemWithAdditionalAction>
            )}
          </Form.Item>
          <HorizontalFormItem
            label="Made in"
            name="madeCountry"
            rules={[requireRule]}
          >
            <Select
              showSearch
              disabled={isDiscontinued}
              placeholder="Country (국가 입력)"
            >
              {madeCountries.map(({ nameEn }) => (
                <Select.Option key={nameEn} value={nameEn}>
                  {nameEn}
                </Select.Option>
              ))}
            </Select>
          </HorizontalFormItem>
          <Row gutter={8} align="bottom">
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item
                label="원료 HS Code"
                name="hsCode"
                rules={[
                  requireRule,
                  exceptKoreanRule,
                  exceptAlphabetRule,
                  { pattern: /.{4,}/, message: '최소 4글자 이상 입력' },
                ]}
              >
                <Input disabled={isDiscontinued} maxLength={12} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item
                label="원료사 내부 관리 코드"
                name="manageCode"
                initialValue=""
              >
                <Input disabled={isDiscontinued} maxLength={15} />
              </Form.Item>
            </Col>
          </Row>
          <DisclosureForm
            isDiscontinued={isDiscontinued}
            disclosures={disclosures}
            handleChangeDisclosures={setDisclosures}
          />
          <Form.Item required>
            <Form.Item
              label="실 생산자의 특수 브랜드 납품 경험"
              name="hasExperienceSpecialBrandDelivery"
              rules={[requireRule]}
            >
              <Radio.Group
                options={[
                  { label: '없음', value: false },
                  { label: '있음', value: true },
                ]}
                disabled={isDiscontinued}
              />
            </Form.Item>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              prev.hasExperienceSpecialBrandDelivery !==
                next.hasExperienceSpecialBrandDelivery ||
              prev.specialBrands !== next.specialBrands
            }
          >
            {({ getFieldValue }) => {
              if (getFieldValue('hasExperienceSpecialBrandDelivery')) {
                const specialBrands: ISpecialBrandForm[] = getFieldValue(
                  'specialBrands',
                ) || [{}];
                const specialBrandOptions = rawMaterialSpecialBrandOptions.filter(
                  ({ value }) =>
                    specialBrands.every(({ brandCode }) => brandCode !== value),
                );

                return (
                  <Form.Item
                    label="해당 원료의 특수 브랜드 서류 대응 여부"
                    required
                  >
                    <Form.List
                      name="specialBrands"
                      initialValue={[
                        { brandCode: undefined, docResponseType: undefined },
                      ]}
                    >
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map((field) => {
                            const selfishOption = rawMaterialSpecialBrandOptions.find(
                              ({ value: brandCode }) =>
                                brandCode ===
                                specialBrands[field.name].brandCode,
                            );
                            return (
                              <Row
                                key={field.key}
                                gutter={8}
                                style={{ position: 'relative' }}
                              >
                                <Col span={16}>
                                  <Form.Item
                                    name={[field.name, 'brandCode']}
                                    rules={[requireRule]}
                                  >
                                    <Select
                                      style={{ width: '100%' }}
                                      placeholder="특수 브랜드 선택"
                                      options={
                                        selfishOption
                                          ? [
                                              selfishOption,
                                              ...specialBrandOptions,
                                            ]
                                          : specialBrandOptions
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={8}>
                                  <Form.Item
                                    name={[field.name, 'docResponseType']}
                                    rules={[requireRule]}
                                  >
                                    <Select
                                      placeholder="서류 대응 여부"
                                      style={{ width: '100%' }}
                                      options={[
                                        {
                                          label: '가능',
                                          value:
                                            ESpecialBrandDocResponseType.POSSIBLE,
                                        },
                                        {
                                          label: '불가능',
                                          value:
                                            ESpecialBrandDocResponseType.IMPOSSIBLE,
                                        },
                                        {
                                          label: '모름',
                                          value:
                                            ESpecialBrandDocResponseType.NONE,
                                        },
                                      ]}
                                    />
                                  </Form.Item>
                                </Col>
                                {field.name !== 0 && !isDiscontinued && (
                                  <MinusIcon
                                    style={{
                                      cursor: 'pointer',
                                      position: 'absolute',
                                      top: 10,
                                      right: -26,
                                    }}
                                    onClick={() => remove(field.name)}
                                  />
                                )}
                              </Row>
                            );
                          })}
                          {
                            <Form.Item>
                              <Button
                                type="dashed"
                                onClick={() => add({})}
                                block
                                icon={
                                  <PlusOutlined
                                    style={{ color: palette.text.primary }}
                                  />
                                }
                                disabled={
                                  specialBrandOptions.length === 0 ||
                                  isDiscontinued
                                }
                              >
                                특수 브랜드 추가
                              </Button>
                            </Form.Item>
                          }
                        </>
                      )}
                    </Form.List>
                  </Form.Item>
                );
              }
            }}
          </Form.Item>
          <Row gutter={8}>
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item label="효능" name="efficacies" rules={[requireRule]}>
                <Select
                  placeholder="선택 또는 직접 입력 후 Enter"
                  mode="tags"
                  options={defaultMaterialEfficacies.map((efficacy) => ({
                    label: efficacy,
                    value: efficacy,
                  }))}
                  disabled={isDiscontinued}
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item
                label="배합 목적"
                name="formulaPurposes"
                rules={[requireRule]}
              >
                <Select
                  placeholder="선택 또는 직접 입력 후 Enter"
                  mode="tags"
                  options={defaultMaterialFormulaPurposes.map((efficacy) => ({
                    label: efficacy,
                    value: efficacy,
                  }))}
                  disabled={isDiscontinued}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="마케팅 키워드"
            required
            shouldUpdate={(prev, next) =>
              prev.efficacies !== next.efficacies ||
              prev.formulaPurposes !== next.formulaPurposes ||
              prev.marketingKeywords !== next.marketingKeywords
            }
          >
            {() => {
              const marketingKeywords = new Set<string>(
                form
                  .getFieldValue('marketingKeywords')
                  ?.split(/,\s?/)
                  .map((keyword: string) => keyword.slice(0, 15))
                  .filter((keyword: string) => keyword.trim() !== '') || [],
              );

              const keywords = [
                ...(form.getFieldValue('efficacies') || []),
                ...(form.getFieldValue('formulaPurposes') || []),
                ...Array.from(marketingKeywords),
              ];

              return (
                <>
                  {keywords.length > 0 && (
                    <Row gutter={[8, 8]} style={{ marginBottom: 8 }}>
                      {keywords.map((keyword: string) => (
                        <Col key={nanoid()}>
                          <Typography.Label
                            type="primary"
                            style={{ minWidth: 0 }}
                          >
                            {keyword}
                          </Typography.Label>
                        </Col>
                      ))}
                    </Row>
                  )}
                  <Form.Item
                    noStyle
                    name="marketingKeywords"
                    rules={[
                      {
                        validator: async (_, marketingKeywords) => {
                          if (
                            !marketingKeywords ||
                            marketingKeywords.replace(/\s/g, '').length === 0
                          ) {
                            throw Error(messages.REQUIRED_FIELD);
                          }
                        },
                      },
                    ]}
                  >
                    <Input.TextArea
                      placeholder={
                        '- 쉼표로 구분하여 키워드를 입력해 주세요. (Ex. 키워드1, 키워드2, 키워드3)\n- 띄어쓰기 포함 15글자로 작성해 주세요. (초과 시 15글자로 고정)\n- 효능, 배합 목적을 제외한 키워드를 입력해주세요.\n- 중복으로 입력된 키워드는 하나의 키워드로 취급됩니다.'
                      }
                      autoSize={{ minRows: 6, maxRows: 6 }}
                      disabled={isDiscontinued}
                    />
                  </Form.Item>
                </>
              );
            }}
          </Form.Item>
          <Form.Item
            label="추출물 유래 선택"
            name="originType"
            rules={[requireRule]}
          >
            <Select
              placeholder="추출물 유래 선택"
              options={[
                { label: '식물성 추출물 포함', value: EOriginType.VEGAN },
                { label: '동물성 추출물 포함', value: EOriginType.ORGANIC },
                { label: '식물성·동물성 혼합', value: EOriginType.BOTH },
                { label: '해당 없음', value: EOriginType.NOTHING },
              ]}
              disabled={isDiscontinued}
            />
          </Form.Item>
          <Form.Item
            label={
              <>
                유효 성분
                <Tip style={{ marginLeft: 8, marginTop: -5 }}>
                  <Typography.Text type="secondary">
                    ex. 플라보노이드를 최소 200ppm 이상 함유하고 있는 원료
                    <br />
                    유효 성분명 : 플라보노이드(Flavonoid) / 성분 (최소)함량 :
                    200 / 단위 : ppm
                  </Typography.Text>
                </Tip>
              </>
            }
          >
            <Form.List name="effectiveIngredients">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => (
                    <Row
                      key={field.name}
                      gutter={8}
                      wrap={false}
                      align="middle"
                    >
                      <Col span={12}>
                        <Form.Item
                          name={[field.name, 'ingredientName']}
                          required
                          rules={[requireRule]}
                        >
                          <Input
                            placeholder="유효 성분명 입력"
                            maxLength={30}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          name={[field.name, 'ingredientMinContent']}
                          required
                          rules={[
                            requireRule,
                            {
                              pattern: /^(([1-9][0-9]*)|(0(?=\.)))(\.\d{1,10})?$/,
                              message: '양수 입력(소수점 가능)',
                            },
                          ]}
                        >
                          <Input placeholder="성분 (최소)함량" maxLength={10} />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          name={[field.name, 'ingredientUnit']}
                          required
                          rules={[
                            requireRule,
                            {
                              pattern: /^[^ㄱ-ㅎㅏ-ㅣ가-힣0-9]*$/i,
                              message: '영문, 기호 입력가능',
                            },
                          ]}
                        >
                          <Input placeholder="단위" maxLength={10} />
                        </Form.Item>
                      </Col>
                      <Col>
                        {!isDiscontinued && (
                          <MinusIcon
                            style={{
                              cursor: 'pointer',
                              position: 'relative',
                              top: -6,
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              remove(field.name);
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        if (fields.length + 1 > 10) {
                          return message.warning('최대 10개까지 입력가능');
                        }
                        add({});
                      }}
                      block
                      icon={
                        <PlusOutlined style={{ color: palette.text.primary }} />
                      }
                      disabled={isDiscontinued}
                    >
                      유효 성분 추가
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
          <Form.Item
            label="배합시 주의사항"
            name="combinationWarningText"
            style={{ marginTop: -16 }}
          >
            <Input.TextArea
              placeholder={
                '아래 예시를 참고하여 입력해 주세요.\n- 30 ~ 40℃로 온도를 유지하며 후첨해 주세요.\n- 믹서의 속도를 200rpm 이하에서 혼합해 주세요.\n- 용해 단계에서 반드시 수상과 함께 용해해 주세요.'
              }
              autoSize={{ minRows: 4, maxRows: 4 }}
              disabled={isDiscontinued}
            />
          </Form.Item>
          <Form.Item label="특허 등록 번호">
            <Form.List name="patents" initialValue={[]}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => (
                    <Row key={field.key} align="middle" wrap={false} gutter={8}>
                      <Col flex="0 0 100%">
                        <Form.Item
                          name={field.name}
                          rules={[
                            requireRule,
                            exceptKoreanRule,
                            {
                              validator: async (_, value) => {
                                if (
                                  value &&
                                  form
                                    .getFieldValue('patents')
                                    .filter(
                                      (patent: string) => patent === value,
                                    ).length >= 2
                                ) {
                                  throw new Error('중복된 특허 등록 번호');
                                }
                              },
                            },
                          ]}
                        >
                          <Input
                            placeholder="원료의 특허 등록 번호를 입력해주세요"
                            disabled={isDiscontinued}
                            maxLength={17}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        {!isDiscontinued && (
                          <MinusIcon
                            style={{
                              cursor: 'pointer',
                              position: 'relative',
                              top: -6,
                            }}
                            onClick={() => remove(field.name)}
                          />
                        )}
                      </Col>
                    </Row>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add('')}
                      block
                      icon={
                        <PlusOutlined style={{ color: palette.text.primary }} />
                      }
                      disabled={isDiscontinued}
                    >
                      특허 등록 번호 추가
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
          <Form.Item label="관련 논문 파일">
            <Row align="middle" gutter={8}>
              <Col>
                <Upload
                  multiple
                  beforeUpload={handleUploadThesis}
                  itemRender={() => null}
                >
                  <Button style={{ width: 120 }} disabled={isDiscontinued}>
                    파일 선택
                  </Button>
                </Upload>
              </Col>
              {theses.map((thesisFile, index) => (
                <Col key={nanoid()}>
                  <Typography.Label
                    type="primary"
                    closeable={!isDiscontinued}
                    onClick={
                      !isDiscontinued
                        ? () => handleDeleteThesis(index)
                        : undefined
                    }
                  >
                    {thesisFile instanceof File
                      ? thesisFile.name
                      : thesisFile.fileName}
                  </Typography.Label>
                </Col>
              ))}
            </Row>
          </Form.Item>
          {!isDiscontinued && (
            <FooterBox>
              <Button
                type="primary"
                htmlType="submit"
                loading={addLoading || updateLoading}
              >
                {!updateMode ? '저장' : '수정'}
              </Button>
            </FooterBox>
          )}
        </Form>
      </Spin>
    </RawMaterialBasicBlock>
  );
};

export default RawMaterialBasic;
