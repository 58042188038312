import { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import * as estimateActions from 'modules/estimate';

export const useEstimateHistories = (estimateId) => {
  const dispatch = useDispatch();
  const { loading, estimateHistories } = useSelector(
    ({ estimate, loading }) => ({
      loading: loading['estimate/GET_ESTIMATE_HISTORIES'],
      estimateHistories: estimate.estimateHistories,
    }),
  );

  useEffect(() => {
    if (estimateId) {
      dispatch(estimateActions.getEstimateHistories(estimateId));
    }
    return () => {
      if (estimateId) {
        dispatch(estimateActions.initializeEstimateHistories());
      }
    };
  }, [dispatch, estimateId]);

  return { loading, estimateHistories };
};

export const useEstimate = () => {
  const dispatch = useDispatch();
  const { companyId, estimate, getLoading } = useSelector(
    ({ auth, company, estimate, loading, device }) => ({
      companyId: auth.user.companyId,
      company: company.company,
      estimate: estimate.estimate,
      getLoading: loading['estimate/GET_ESTIMATE'],
      submitLoading: loading['estimate/CONTRACT'],
      isMobile: device.isMobile,
    }),
    shallowEqual,
  );

  useEffect(() => {
    dispatch(estimateActions.getEstimate(companyId));
  }, []);

  return useMemo(() => ({ estimate, loading: getLoading }), [
    estimate,
    getLoading,
  ]);
};
