import { Form, Input } from 'antd';
import styled from 'styled-components';

import { requireRule, exceptKoreanRule } from 'lib/validate';
import AlphabetLabel from './AlphabetLabel';
import { memo } from 'react';

const MSDSDisposalBlock = styled.div`
  max-width: 536px;
  padding-left: 16px;
  padding-bottom: 32px;
  margin: 0 auto;
`;

const MSDSDisposal = () => {
  return (
    <MSDSDisposalBlock>
      <Form.Item
        label={<AlphabetLabel alphabet="A">Disposal methods</AlphabetLabel>}
        name={['disposal', 'disposalMethods']}
        rules={[requireRule, exceptKoreanRule]}
        required={false}
        initialValue="Dispose of contents/containers in accordance with local/ regional/national/international regulations."
      >
        <Input.TextArea
          placeholder="폐기방법"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={500}
          showCount
        />
      </Form.Item>
      <Form.Item
        label={
          <AlphabetLabel alphabet="B">Contaminated packaging</AlphabetLabel>
        }
        name={['disposal', 'contaminatedPackaging']}
        rules={[requireRule, exceptKoreanRule]}
        required={false}
        initialValue="Disposal must be made according to official regulation."
      >
        <Input.TextArea
          placeholder="폐기시 주의사항(오염된 용기 및 포장의 폐기 방법을 포함)"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={500}
          showCount
        />
      </Form.Item>
    </MSDSDisposalBlock>
  );
};

export default memo(MSDSDisposal);
