import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as productActions from 'modules/product';
import history from 'lib/history';
import path from 'lib/path';
import { useProduct } from 'service/manufacturer/product';

export const useProductDetail = (productId?: number) => {
  const [isRegisterable, setRegisterable] = useState(false);
  const dispatch = useDispatch();
  const { product } = useProduct(productId);
  useEffect(() => {
    if (product) {
      dispatch(productActions.setCurrentProduct(product));
    }
  }, [product]);
  const {
    documents,
    currentProduct,
    fetchLoading,
    registerLoading,
  } = useSelector(({ product, loading }: any) => ({
    documents: product.manuDocuments,
    currentProduct: product.currentProduct,
    fetchLoading:
      loading['product/MANU_GET_DOCUMENTS'] ||
      loading['product/GET_DOC_STATUS'],
    registerLoading: loading['product/MANU_REGISTER_PRODUCT'],
  }));
  const registerable = useMemo(
    () =>
      documents.length !== 0 &&
      !documents.find(({ status }: any) => status === 'INP') &&
      currentProduct.manufacturerStatus === 'PRD-RDY',
    [documents],
  );
  const registerProduct = useCallback(() => {
    dispatch(productActions.manuRegisterProduct(productId));
  }, [productId]);

  const getManuDocuments = useCallback(() => {
    dispatch(productActions.manuGetDocuments(productId));
  }, [productId]);

  const showDocument = useCallback(
    (document: any) => {
      if (document.documentCode === 'basic') {
        history.push(
          `${path.manufacturer.product.root}/${currentProduct.productId}/basic`,
        );
      } else {
        history.push(
          `/product/${currentProduct.productId}/${document.documentCode}`,
        );
      }
    },
    [currentProduct],
  );

  useEffect(() => {
    getManuDocuments();
    dispatch(productActions.setSkipMode(false));
  }, []);

  useEffect(() => {
    setRegisterable(registerable);
  }, [registerable]);

  return useMemo(
    () => ({
      currentProduct,
      isRegisterable,
      documents,
      registerable,
      registerLoading,
      fetchLoading,
      registerProduct,
      showDocument,
      getManuDocuments,
    }),
    [
      currentProduct,
      isRegisterable,
      documents,
      fetchLoading,
      registerLoading,
      registerProduct,
      showDocument,
      getManuDocuments,
    ],
  );
};
