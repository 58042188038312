import { Col, Row } from 'antd';
import styled from 'styled-components';

import palette from 'lib/styles/palette';
import { Typography } from 'components/system';
import { s3AssetDomain } from 'lib/consts';

const ThirtyCOSAccountBlock = styled.div``;

const ThirtyCOSAccountBody = styled(Row)`
  padding: 20px 0;
  border-radius: 8px;
  background-color: ${palette.gray};

  p {
    font-size: 16px;
  }
`;

const ThirtyCOSAccount = () => {
  return (
    <ThirtyCOSAccountBlock>
      <Typography.Title gutter={{ top: 24, bottom: 16 }}>
        결제 방법 (계좌 이체)
      </Typography.Title>
      <ThirtyCOSAccountBody justify="space-around">
        <Col>
          <Row gutter={16}>
            <Col>
              <img
                style={{ width: 24 }}
                src={`${s3AssetDomain}/hanabank_V2.png`}
                alt="bank logo"
              />
            </Col>
            <Col>
              <Typography.Title type="secondary" color="slate" medium>
                하나은행
              </Typography.Title>
            </Col>
          </Row>
        </Col>
        <Col>
          <Typography.Title type="secondary" color="slate" medium>
            340-910039-73304
          </Typography.Title>
        </Col>
        <Col>
          <Typography.Title type="secondary" color="slate" medium>
            주식회사 30코스
          </Typography.Title>
        </Col>
      </ThirtyCOSAccountBody>
    </ThirtyCOSAccountBlock>
  );
};

export default ThirtyCOSAccount;
