import { Col, Modal, Row, Statistic } from 'antd';
import { useState } from 'react';

import StatisticCard from 'components/StatisticCard';
import { Typography } from 'components/system';
import PaymentInfoModal from './PaymentInfoModal';

const EstimateAdditionalsModal = ({
  estimateAdditionals,
  onClose,
  onPreview,
}: any) => {
  const [
    previewedPaymentEstimateUrl,
    setPreviewedPaymentEstimateUrl,
  ] = useState<string | null>(null);
  const closePaymentInfoModal = () => {
    setPreviewedPaymentEstimateUrl(null);
  };
  return (
    <>
      <PaymentInfoModal
        estimateUrl={previewedPaymentEstimateUrl}
        onClose={closePaymentInfoModal}
      />
      <Modal
        visible={estimateAdditionals !== null}
        width={880}
        onCancel={onClose}
        footer={null}
      >
        <Typography.Title>추가 견적 내역</Typography.Title>
        {estimateAdditionals?.map(
          ({
            productNameEn,
            netWeight,
            netWeightUnit,
            countryNameKo,
            totalPrice,
            status,
            estimateAdditionalCode,
            certRequestNo,
            registerDt,
            fileUrl,
          }: any) => (
            <StatisticCard
              key={estimateAdditionalCode}
              style={{ marginTop: 16 }}
            >
              <Row gutter={[8, 32]}>
                <Col
                  sm={{ span: 9 }}
                  xs={{ span: 12 }}
                  style={{ textAlign: 'center' }}
                >
                  <Statistic
                    title="제품명 (용량)"
                    value={`${productNameEn} ${
                      netWeight ? `(${netWeight} ${netWeightUnit})` : '( - )'
                    }`}
                  />
                </Col>
                <Col
                  sm={{ span: 5 }}
                  xs={{ span: 12 }}
                  style={{ textAlign: 'center' }}
                >
                  <Statistic title="국가" value={countryNameKo} />
                </Col>
                <Col
                  sm={{ span: 5 }}
                  xs={{ span: 12 }}
                  style={{ textAlign: 'center' }}
                >
                  <Statistic title="금액" value={totalPrice.toLocaleString()} />
                </Col>
                <Col
                  sm={{ span: 5 }}
                  xs={{ span: 12 }}
                  style={{ textAlign: 'center' }}
                >
                  <Statistic
                    title="처리 현황"
                    valueRender={() =>
                      status === 'DEP-RDY' ? (
                        <Typography.Label
                          type="primary"
                          onClick={() =>
                            setPreviewedPaymentEstimateUrl(fileUrl)
                          }
                        >
                          입금 대기
                          <i className="material-icons outlined md-12">
                            chevron_right
                          </i>
                        </Typography.Label>
                      ) : (
                        <Typography.Text medium>입금 완료</Typography.Text>
                      )
                    }
                  />
                </Col>
                <Col
                  sm={{ span: 9 }}
                  xs={{ span: 12 }}
                  style={{ textAlign: 'center' }}
                >
                  <Statistic
                    title="견적서 번호"
                    value={estimateAdditionalCode}
                  />
                </Col>
                <Col
                  sm={{ span: 5 }}
                  xs={{ span: 12 }}
                  style={{ textAlign: 'center' }}
                >
                  <Statistic title="인증 신청 번호" value={certRequestNo} />
                </Col>
                <Col
                  sm={{ span: 5 }}
                  xs={{ span: 12 }}
                  style={{ textAlign: 'center' }}
                >
                  <Statistic
                    title="추가 견적 발행일"
                    value={registerDt?.slice(0, 10)}
                  />
                </Col>
                <Col
                  sm={{ span: 5 }}
                  xs={{ span: 12 }}
                  style={{ textAlign: 'center' }}
                >
                  <Statistic
                    title="견적서"
                    valueRender={() => (
                      <i
                        className="material-icons outlined cursor"
                        onClick={() => onPreview(fileUrl)}
                      >
                        description
                      </i>
                    )}
                  />
                </Col>
              </Row>
            </StatisticCard>
          ),
        )}
      </Modal>
    </>
  );
};

export default EstimateAdditionalsModal;
