import { CalendarOutlined } from '@ant-design/icons';
import { DatePicker, Input, InputProps } from 'antd';
import moment from 'moment';
import { ChangeEvent, useRef, useState } from 'react';
import styled from 'styled-components';

interface DataInputProps extends InputProps {
  dateFormat?: string;
  checkIsValidDate?: (current: moment.Moment) => boolean;
}

const DateInputBlock = styled.div`
  position: relative;
`;

const DateInput = ({
  disabled,
  placeholder = 'YYYY-MM-DD',
  onChange,
  checkIsValidDate,
  dateFormat = 'YYYY-MM-DD',
  ...rest
}: DataInputProps) => {
  const [pickerVisible, setPickerVisible] = useState(false);
  const inputRef = useRef<any>(null);
  const pickerRef = useRef<any>(null);
  return (
    <DateInputBlock>
      <Input
        {...rest}
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChange}
        suffix={
          <CalendarOutlined
            style={{
              color: '#aaa',
              cursor: !disabled ? 'pointer' : 'not-allowed',
              visibility: !pickerVisible ? 'visible' : 'hidden',
            }}
            onClick={() => {
              !disabled && setPickerVisible(true);
            }}
          />
        }
        ref={inputRef}
        autoComplete="off"
      />
      <DatePicker
        ref={pickerRef}
        style={{
          visibility: 'hidden',
          height: 0,
          padding: 0,
          position: 'absolute',
          top: 44,
          left: 0,
        }}
        disabledDate={
          checkIsValidDate ? (current) => !checkIsValidDate(current) : undefined
        }
        open={pickerVisible}
        onChange={(date) => {
          onChange!({
            target: { value: date!.format(dateFormat) },
          } as ChangeEvent<any>);
        }}
        onOpenChange={() => {
          setPickerVisible(false);
        }}
      />
    </DateInputBlock>
  );
};
export default DateInput;
