import axios from 'axios';
import JSZip from 'jszip';

export const createFormData = (object) => {
  const formData = new FormData();
  for (const key in object) {
    const value = object[key];
    if (typeof value === 'undefined') {
      continue;
    }
    if (value instanceof FileList) {
      Array.prototype.forEach.call(value, (file) => {
        formData.append(key, file);
      });
    } else if (value instanceof Array) {
      value.forEach((element, index) => {
        if (element instanceof File || element === null) {
          formData.append(key, element);
        } else if (element instanceof Object) {
          Object.keys(element).forEach((fieldKey) => {
            formData.append(`${key}[${index}].${fieldKey}`, element[fieldKey]);
          });
        } else {
          formData.append(`${key}[${index}]`, element);
        }
      });
    } else {
      formData.append(key, value);
    }
  }
  return formData;
};

export const createFormDataForMarketing = (marketingFileForm) => {
  const formData = new FormData();
  for (const key in marketingFileForm) {
    const value = marketingFileForm[key];
    if (value instanceof Array) {
      //pages
      value.forEach((page, idx) => {
        for (const pageKey in page) {
          const pageValue = page[pageKey];
          if (pageValue instanceof Array) {
            //items
            pageValue.forEach((item, index) => {
              for (const itemKey in item) {
                const itemValue = item[itemKey];
                if (itemValue instanceof File) {
                  formData.append(
                    `pages[${idx}].items[${index}].${itemKey}`,
                    itemValue,
                  );
                } else if (
                  typeof itemValue === 'undefined' ||
                  itemValue === null
                ) {
                  continue;
                } else {
                  formData.set(
                    `pages[${idx}].items[${index}].${itemKey}`,
                    itemValue,
                  );
                }
              }
            });
          } else if (typeof pageValue === 'undefined' || pageValue === null) {
            continue;
          } else {
            formData.set(`pages[${idx}].${pageKey}`, pageValue);
          }
        }
      });
    } else if (typeof value === 'undefined' || value === null) {
      continue;
    } else {
      formData.set(key, value);
    }
  }
  return formData;
};

export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const base64ToFile = (base64String, filename) => {
  let arr = base64String.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};
const blobToBase64 = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = (error) => reject(error);
  });

export const toBinary = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const createBlobFile = async (fileUrl) => {
  const response = await axios.get(fileUrl, { responseType: 'blob' });
  return response.data;
};

export const downloadFile = async (fileUrl, name) => {
  if (fileUrl instanceof File) {
    let link = document.createElement('a');

    link.download = fileUrl.name;
    link.href = URL.createObjectURL(fileUrl);
    link.click();
  } else {
    let filename =
      name || fileUrl.substring(fileUrl.lastIndexOf('/') + 1).split('?')[0];
    if (!filename.includes('.')) {
      filename += fileUrl.substring(fileUrl.lastIndexOf('.'));
    }
    const response = await axios.get(fileUrl, { responseType: 'blob' });
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(response.data);
    a.download = decodeURIComponent(filename);
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};

export const downloadFiles = async (files, zipName = 'file.zip') => {
  const zip = new JSZip();
  for (const { name, url } of files) {
    const blobData = await axios.get(url, {
      responseType: 'blob',
    });
    const binaryData = await blobToBase64(blobData.data);
    zip.file(
      `${name}${!name.includes('.') ? url.slice(url.lastIndexOf('.')) : ''}`,
      binaryData,
      {
        base64: true,
      },
    );
  }

  return new Promise((resolve) => {
    zip.generateAsync({ type: 'base64' }).then((content) => {
      const a = document.createElement('a');
      a.href = `data:application/zip;base64, ${content}`;
      a.download = zipName;
      a.click();
      resolve(undefined);
    });
  });
};

export const getFilenameFromUrl = (url, excludeSuffix = false) => {
  if (!url || !url.includes('/') || !url.includes('.')) {
    throw new Error('Invalid URL');
  }
  return decodeURIComponent(
    url.slice(
      url.lastIndexOf('/') + 1,
      excludeSuffix ? url.lastIndexOf('.') : undefined,
    ),
  );
};

export const getReadableFileSize = (bytes, dp = 1) => {
  const thresh = 1000;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let unitIndex = -1;

  do {
    bytes /= thresh;
    ++unitIndex;
  } while (
    Math.round(Math.abs(bytes) * 10 ** dp) / 10 ** dp >= thresh &&
    unitIndex < units.length - 1
  );

  return bytes.toFixed(dp) + ' ' + units[unitIndex];
};

export const getFilenameExtension = (url) => {
  const splitUrl = url.split('.');
  return splitUrl[splitUrl.length - 1];
};
