import styled from 'styled-components';
import {
  Checkbox,
  Card,
  Typography as Typo,
  Button,
  Form,
  Collapse,
} from 'antd';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import FooterBox from 'components/FooterBox';
import path from 'lib/path';
import { deviceSize } from 'lib/styles';
import Typography from 'components/system/general/Typography';
import palette from 'lib/styles/palette';

const RegisterTermsBlock = styled.div`
  flex: 0 1 992px;

  @media ${deviceSize.sm} {
    padding: 20px 0;
  }

  .ant-form-item-control .ant-form-item-control-input {
    min-height: 24px;
  }

  .ant-collapse {
    border: unset;
    background-color: white;
  }

  .ant-collapse-header {
    background-color: ${palette.gray};
    padding: 16px 0px 0px 16px !important;

    .ant-collapse-arrow {
      top: 16px !important;
      right: 36px !important;
      font-size: 22px !important;
    }
  }

  .ant-collapse-content {
    &.ant-collapse-content-active {
      border-top: none;
    }
    .ant-collapse-content-box {
      padding: 0px;
    }
  }

  .ant-collapse > .ant-collapse-item {
    border-bottom: unset;
  }

  .ant-collapse-item {
    margin-bottom: 16px;
  }
`;

const CheckAllBlock = styled.div`
  display: flex;
  padding: 16px;
  background-color: ${palette.lightBlue10};
  border: 1px solid #d7e7fe;
  cursor: pointer;
  border-radius: 8px;
  margin-bottom: 16px;
`;

const TermsCard = styled(Card)`
  margin: 8px 0 8px;

  .ant-card-body {
    height: 200px;
    padding: 24px 48px;
    overflow: auto;

    @media ${deviceSize.sm} {
      padding: 12px 16px;
    }
  }

  h5.ant-typography {
    font-weight: 500;
  }

  ul + h5.ant-typography {
    margin-top: 1.2rem;
  }

  .list {
    &.depth1 {
      list-style: none;
      padding-left: 0;

      & > li:not(:first-child) {
        margin-top: 32px;
      }
      & > li > h5 {
        margin-bottom: 12px;
      }
    }
    &.depth2 {
      counter-reset: depth2;
      list-style: none;
      padding-left: 16px;

      & > li {
        position: relative;
        padding-left: 24px;
        margin-top: 16px;

        &::before {
          display: flex;
          counter-increment: depth2;
          position: absolute;
          top: 4px;
          left: 0;
          width: 14px;
          height: 14px;
          content: counter(depth2);
          font-size: 10px;
          border: 1px solid #333;
          border-radius: 50%;
          justify-content: center;
          align-items: center;
        }
      }
    }
    &.depth3 {
      padding-left: 16px;
      list-style: decimal;
      & > li {
        margin-top: 8px;
      }
    }
    &.dash {
      margin-top: 8px;
      list-style: none;
      padding-left: 0;
      & > li {
        position: relative;
        padding-left: 12px;
        &::before {
          content: '-';
          position: absolute;
          left: 0;
        }
      }
    }
  }

  table {
    text-align: center;
    border: 1px solid #666;
    margin: 16px auto 32px;

    th,
    td {
      border: 1px solid #666;
    }
  }
`;

const RegisterTerms = ({
  companyType,
  form,
  isTermsChecked,
  isPrivateChecked,
  isThirdPartyChecked,
  isPolicyChecked,
  isLogoChecked,
  onCheckAll,
  onCheckTerm,
  onCheckPrivate,
  onCheckThirdParty,
  onCheckPolicy,
  onCheckLogo,
  onClickNext,
  onFail,
}: any) => {
  const checkedLength = useMemo(
    () =>
      [
        isTermsChecked,
        isPrivateChecked,
        isThirdPartyChecked,
        isPolicyChecked,
        isLogoChecked,
      ].filter((el) => el).length,
    [
      isTermsChecked,
      isPrivateChecked,
      isThirdPartyChecked,
      isPolicyChecked,
      isLogoChecked,
    ],
  );
  return (
    <RegisterTermsBlock>
      <Form
        form={form}
        colon={false}
        onFinish={onClickNext}
        onFinishFailed={onFail}
        style={{ marginTop: 32 }}
      >
        <CheckAllBlock onClick={() => onCheckAll(checkedLength !== 5)}>
          <Checkbox
            indeterminate={checkedLength > 0 && checkedLength < 5}
            checked={checkedLength === 5}
          >
            <Typography.Text
              style={{ fontSize: 18 }}
              inline
              color="slate"
              medium
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              모든 약관에 동의
            </Typography.Text>
          </Checkbox>
        </CheckAllBlock>
        <Collapse
          defaultActiveKey={[
            'terms',
            'private',
            'thirdParty',
            'policy',
            'logo',
          ]}
          expandIconPosition="right"
          expandIcon={({ isActive }) =>
            isActive ? (
              <i className="material-icons outlined">expand_less</i>
            ) : (
              <i className="material-icons outlined">expand_more</i>
            )
          }
        >
          <Collapse.Panel
            key="terms"
            header={
              <Form.Item
                name="terms"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject('필수 체크항목입니다.'),
                  },
                ]}
              >
                <Checkbox
                  onChange={(e) => {
                    onCheckTerm(e.target.checked);
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Typography.Text
                    color="slate"
                    type="primary"
                    inline
                    medium
                    onClick={(e) => e.stopPropagation()}
                  >
                    이용 약관 동의
                  </Typography.Text>
                  <Typography.Text
                    color="primary"
                    type="primary"
                    inline
                    gutter={{ left: 4 }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    (필수)
                  </Typography.Text>
                </Checkbox>
              </Form.Item>
            }
          >
            {companyType === 'brand' && (
              <TermsCard size="small">
                <ul className="list depth1">
                  <li>
                    <Typo.Title level={5}>제1조(목적)</Typo.Title>
                    <Typo.Paragraph style={{ paddingLeft: 16 }}>
                      이 약관은 주식회사 30COS(이하 “회사”라 한다)에서 제공하는
                      각 국가의 미용 또는 소비재 규제관련 인증서비스(이하
                      “서비스”라 한다)를 이용함에 있어 “플랫폼”과 “이용자”의
                      권리․의무 및 책임사항을 규정함을 목적으로 합니다.
                    </Typo.Paragraph>
                  </li>
                  <li>
                    <Typo.Title level={5}>제2조(정의)</Typo.Title>
                    <ul className="list depth2">
                      <li>
                        이 약관에서 사용하는 용어의 의미는 다음 각 호와
                        같습니다.
                        <ul className="list depth3">
                          <li>
                            “플랫폼”은 “회사”가 이 약관에 의하여 “이용자”에게
                            정보통신설비와 정보통신망을 이용 또는 전자적 장치를
                            통하여 일정부분 비대면, 자동화된 방식으로 서비스를
                            제공하기 위하여 설정한 가상의 영업장 의미로
                            사용합니다.
                          </li>
                          <li>
                            “이용자”라 함은 “회사”가 제공하는 서비스를 이용하는
                            자를 말합니다.
                          </li>
                          <li>
                            “해외사업자”라 함은 대한민국 이외의 국적이거나
                            대한민국 이외의 국가에 사업자 등록, 법인, 영업소,
                            호스트서버 소재지 등을 가진 사업자를 의미합니다.
                          </li>
                          <li>
                            “위임형 인증대행”이라 함은 “이용자”가 “이용자”의
                            제품 등에 대하여 해외 국가에서의 필요 인증에 대해서
                            컨설팅 및 대행해 줄 것을 의뢰하면 “회사”가 해당 국가
                            업체 등을 통해서 인증 관련 업무 처리를 제공하는
                            서비스를 의미합니다.
                          </li>
                          <li>
                            “인증대행 수수료”는 “회사”가 위임형 인증대행 서비스
                            제공의 대가로 “이용자”에게 청구하는 서비스 이용료를
                            의미합니다.
                          </li>
                          <li>
                            “직접비”는 인증대행 서비스 제공을 중계하면서 해당
                            국가에서 발생하는 현지 수수료, 해당 국가 현지
                            에이전시 비용, 현지 업체, 해외 현지 제세금 등
                            발생하는 비용을 총칭하여 의미합니다.
                          </li>
                          <li>
                            “인증확인”이라 함은 “이용자”가 인증대행을 신청한
                            제품의 “인증확인” 여부를 “회사”가 정한 기준에 따라
                            확인하여 제공하는 서비스를 의미합니다.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Typo.Paragraph>
                          ‘회원’이라 함은 “플랫폼”에 회원등록을 한 자로서,
                          계속적으로 “플랫폼”이 제공하는 서비스를 이용할 수 있는
                          자를 말합니다.
                        </Typo.Paragraph>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typo.Title level={5}>
                      제3조(약관 등의 명시와 설명 및 개정)
                    </Typo.Title>
                    <ul className="list depth2">
                      <li>
                        “플랫폼”은 이 약관의 내용과 상호 및 대표자 성명, 영업소
                        소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를
                        포함), 전화번호, 모사전송번호, 전자우편주소,
                        사업자등록번호, 통신판매업 신고번호, 개인정보관리책임자
                        등을 “이용자”가 쉽게 알 수 있도록 “플랫폼”의 초기
                        서비스화면(전면)에 게시합니다. 다만, 약관의 내용은
                        “이용자”가 연결화면을 통하여 볼 수 있도록 할 수
                        있습니다.
                      </li>
                      <li>
                        “플랫폼”은 「전자상거래 등에서의 소비자보호에 관한
                        법률」, 「약관의 규제에 관한 법률」, 「전자문서 및
                        전자거래기본법」, 「전자금융거래법」, 「전자서명법」,
                        「정보통신망 이용촉진 및 정보보호 등에 관한 법률」,
                        「방문판매 등에 관한 법률」, 「소비자기본법」 등 관련
                        법을 위배하지 않는 범위에서 이 약관을 개정할 수
                        있습니다.
                      </li>
                      <li>
                        “플랫폼”이 약관을 개정할 경우에는 적용일자 및 개정사유를
                        명시하여 현행약관과 함께 “플랫폼”의 초기화면에 그
                        적용일자 7일 이전부터 적용일자 전일까지 공지합니다.
                        다만, “이용자”에게 불리하게 약관내용을 변경하는 경우에는
                        최소한 14일 이상의 사전 유예기간을 두고 공지하며
                        “이용자”에게 재동의를 받는 절차를 거치도록 합니다. 이
                        경우 “플랫폼”은 개정 전 내용과 개정 후 내용을 명확하게
                        비교하여 “이용자”가 알기 쉽도록 표시합니다.
                      </li>
                      <li>
                        “플랫폼”이 약관을 개정할 경우에는 그 개정약관은 그
                        적용일자 이후에 체결되는 계약에만 적용되고 그 이전에
                        이미 체결된 계약에 대해서는 개정 전의 약관조항이 그대로
                        적용됩니다. 다만 이미 계약을 체결한 “이용자”가 개정약관
                        조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의
                        공지기간 내에 “플랫폼”에 송신하여 “플랫폼”의 동의를 받은
                        경우에는 개정약관 조항이 적용됩니다.
                      </li>
                      <li>
                        이 약관에서 정하지 아니한 사항과 이 약관의 해석에
                        관하여는 전자상거래 등에서의 소비자보호에 관한 법률,
                        약관의 규제 등에 관한 법률, 공정거래위원회가 정하는
                        전자상거래 등에서의 소비자 보호지침 및 관계법령 또는
                        상관례에 따릅니다.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typo.Title level={5}>제4조(회원가입)</Typo.Title>
                    <ul className="list depth2">
                      <li>
                        “이용자”는 “플랫폼”이 정한 가입 양식에 따라 회원정보를
                        기입한 후 이 약관에 동의한다는 의사표시를 함으로써
                        회원가입을 신청합니다.
                      </li>
                      <li>
                        “플랫폼”은 제1항과 같이 회원으로 가입할 것을 신청한
                        “이용자” 중 다음 각 호에 해당하지 않는 한 회원으로
                        등록합니다.
                        <ul className="list depth3">
                          <li>
                            가입신청자가 이 약관 제5조 제2항에 의하여 이전에
                            회원자격을 상실한 적이 있는 경우, 다만 제5조 제2항에
                            의한 회원자격 상실 후 3년이 경과한 자로서 “플랫폼”의
                            회원재가입 승낙을 얻은 경우에는 예외로 한다.
                          </li>
                          <li> 등록 내용에 허위, 기재누락, 오기가 있는 경우</li>
                          <li>
                            기타 회원으로 등록하는 것이 “플랫폼”의 기술상 현저히
                            지장이 있다고 판단되는 경우
                          </li>
                        </ul>
                      </li>
                      <li>
                        회원가입 계약의 성립 시기는 “플랫폼”의 승낙이 회원에게
                        도달한 시점으로 합니다.
                      </li>
                      <li>
                        회원은 회원가입시 등록한 사항에 변경이 있는 경우, 5
                        영업일 이내에 “플랫폼”에 회원정보 수정 등의 방법으로 그
                        변경사항을 알려야 합니다.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typo.Title level={5}>
                      제5조(회원 탈퇴 및 자격 상실 등)
                    </Typo.Title>
                    <ul className="list depth2">
                      <li>
                        회원은 “플랫폼”에 언제든지 탈퇴를 요청할 수 있으며
                        “플랫폼”은 탈퇴 요청 시 10 영업일 내에 회원탈퇴를
                        처리합니다.
                      </li>
                      <li>
                        “회사” 또는 그 계열사 및 “플랫폼”은 회원의 탈퇴로 인한
                        정보소멸, 권리 상실, 손해 등에 대한 일체의 책임을 지지
                        아니한다.
                      </li>
                      <li>
                        회원이 다음 각 호의 사유에 해당하는 경우, “플랫폼”은
                        회원자격을 제한 및 정지시킬 수 있습니다.
                        <ul className="list depth3">
                          <li>가입 신청 시에 허위 내용을 등록한 경우</li>
                          <li>
                            “플랫폼”을 이용하여 신청한 서비스 등의 수수료,
                            직접비, 대금, 기타 “플랫폼”이용에 및 관련 서비스에
                            관련하여 회원이 부담하는 채무를 기일에 지급하지 않는
                            경우
                          </li>
                          <li>
                            다른 사람의 “플랫폼” 이용을 방해하거나 그 정보를
                            도용하는 등 전자상거래 질서를 위협하는 경우
                          </li>
                          <li>
                            “플랫폼”을 이용하여 법령 또는 이 약관이 금지하거나
                            공서양속에 반하는 행위를 하는 경우
                          </li>
                        </ul>
                      </li>
                      <li>
                        “플랫폼”이 회원 자격을 제한․정지시킨 후, 동일한 행위가
                        2회 이상 반복되거나 30일 이내에 그 사유가 시정되지
                        아니하는 경우 “플랫폼”은 회원자격을 상실시킬 수
                        있습니다.
                      </li>
                      <li>
                        “플랫폼”이 회원자격을 상실시키는 경우에는 회원등록을
                        말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록
                        말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를
                        부여합니다.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typo.Title level={5}>제6조(회원에 대한 통지)</Typo.Title>
                    <ul className="list depth2">
                      <li>
                        “플랫폼”이 회원에 대한 통지를 하는 경우, 회원이
                        “플랫폼”과 미리 약정하여 지정한 전자우편 주소로 할 수
                        있습니다.
                      </li>
                      <li>
                        “플랫폼”은 불특정다수 회원에 대한 통지의 경우 1주일 이상
                        “플랫폼” 게시판에 게시함으로써 개별 통지에 갈음할 수
                        있습니다. 다만, 회원 본인의 거래와 관련하여 중대한
                        영향을 미치는 사항에 대하여는 개별통지를 합니다.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typo.Title level={5}>
                      제7조(서비스의 제공 및 변경)
                    </Typo.Title>
                    <ul className="list depth2">
                      <li>
                        “플랫폼”은 “이용자”를 위하여 선량한 관리자의
                        주의의무로써 다음 각 호의 업무를 처리할 수 있습니다.
                        <ul className="list depth3">
                          <li>
                            “이용자”가 인증 신청한 제품 등의 컨설팅 및 인증
                          </li>
                          <li>
                            인증대행 비용 내역 제공(해당 비용은 직접비 및
                            인증대행 수수료를 포함한다)
                          </li>
                          <li>
                            “이용자”가 인증 신청한 제품 등에 대하여 해외인증
                            사업자와의 사이의 유관 계약의 체결 및 대금의 지급
                          </li>
                          <li>인증 등의 “인증확인” 등</li>
                          <li>인증 실패 시 관련 절차의 대행</li>
                          <li>기타 인증 등의 구매에 관련된 제반업무</li>
                        </ul>
                      </li>
                      <li>
                        “플랫폼”이 제공하기로 “이용자”와 체결한 본 약정의
                        서비스의 내용을 인증진행 국가의 정책, 법령, 규제 또는
                        해당국가의 해당인증 사업자의 상황 등의 사유로 변경할
                        경우에는 그 사유를 “이용자”에게 통지 가능한 주소나
                        상호간 정한 연락처로 즉시 통지합니다.
                      </li>
                      <li>
                        전항의 경우 “플랫폼”은 이로 인하여 “이용자”가 입은
                        손해를 배상합니다. 다만, “플랫폼”이 고의 또는 과실이
                        없음을 입증하는 경우에는 그러하지 아니합니다.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typo.Title level={5}>
                      제8조(서비스이용 제한 및 중단)
                    </Typo.Title>
                    <ul className="list depth2">
                      <li>
                        “회사”는 “이용자”의 서비스 이용 요청이 다음 각 호의 어느
                        하나에 해당하는 경우 서비스 제공을 거절하거나,
                        “이용자”의 동의를 구하고(다만, “이용자”에게 동의를 구할
                        수 없는 사정이 있는 경우에는 통지 후) 해당 인증 서비스
                        진행을 책임 없이 중단 또는 종료할 수 있습니다.
                        <ul className="list depth3">
                          <li>신청내용에 허위, 기재누락, 오기가 있는 경우</li>
                          <li>
                            “이용자”가 요청한 서비스의 제공이 “회사”의 경영상
                            또는 기술상의 이유로 현저히 곤란한 경우
                          </li>
                          <li>
                            “이용자”가 불법 또는 부당한 목적을 위해 서비스를
                            이용하는 것으로 판단되는 경우
                          </li>
                          <li>
                            “이용자”가 서비스 받으려는 제품의 안전성, 유해성,
                            위험정도가 높다고 “회사”에서 판단하는 경우
                          </li>
                          <li>
                            인증국을 포함한 서비스관련한 인증 법령에 의하여
                            인증이 금지되는 제품
                          </li>
                          <li>
                            해외 서비스 관련된 사업자 또는 인증 사업자에서
                            정책적으로 금지하고 있는 제품
                          </li>
                        </ul>
                      </li>
                      <li>
                        제1항에 따라 서비스 제공을 거절하는 경우, “회사”는
                        “이용자”에게 거절의 사유 및 근거를 통지하여야 합니다.
                      </li>
                      <li>
                        “플랫폼”은 컴퓨터 등 정보통신설비의 보수점검․교체 및
                        고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의
                        제공을 일시적으로 중단할 수 있습니다
                      </li>
                      <li>
                        “플랫폼”은 제3항의 사유로 서비스의 제공이 일시적으로
                        중단됨으로 인하여 “이용자” 또는 제3자가 입은 손해에
                        대하여 배상합니다. 단, “플랫폼”이 고의 또는 과실이
                        없음을 입증하는 경우에는 그러하지 아니합니다.
                      </li>
                      <li>
                        사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로
                        서비스를 제공할 수 없게 되는 경우에는 “플랫폼”은
                        “이용자”에게 통지하고 완료 또는 종료가 되지 않은
                        서비스에 대해서 받은 비용에 대해서는 환불절차를 진행하여
                        지급합니다.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typo.Title level={5}>제9조(계약의 성립)</Typo.Title>
                    <ul className="list depth2">
                      <li>
                        “회사”는 “플랫폼” 내에서 “이용자”의 신청서 제출이 있으면
                        수신확인통지를 합니다. 수신확인통지에는 신청내역, 금액,
                        수수료, 예상 직접비용, 수령 주소 등의 신청 정보와
                        인증신청, 인증범위, 인증국가, 신청의 정정, 취소 등에
                        관한 정보 등을 포함하여야 합니다.
                      </li>
                      <li>
                        인증대행 계약은 “이용자”가 인증 등을 대행해 줄 것을
                        신청하면 “회사”가 신청에 대해서 접수한 후 가능 여부를
                        확인하여 계약서 체결과 동시에(이하 “인증계약서”)
                        인증대행 수수료 및 예상 직접비용을 선입금 받았을 때에
                        성립됩니다.
                      </li>
                      <li>
                        “회사”는 다음 각 호에 해당하면 계약의 성립을 승낙하지
                        않을 수 있습니다.
                        <ul className="list depth3">
                          <li>신청 내용에 허위, 기재누락, 오기가 있는 경우</li>
                          <li>
                            기타 인증신청에 승낙하는 것이 “플랫폼” 기술상 현저히
                            지장이 있다고 판단하는 경우 또는 “회사”가 진행할 수
                            없는 수준의 인증일 경우
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typo.Title level={5}>제10조(지급방법)</Typo.Title>
                    <Typo.Paragraph style={{ paddingLeft: 16 }}>
                      “플랫폼”에서 신청한 재화 또는 용역에 대한 대금지급방법은
                      다음 각 호의 방법 중 “플랫폼”에서 지급시점에 허용 및
                      제공하는 방법으로 할 수 있습니다. 단, “플랫폼”은
                      “이용자”의 지급방법에 대하여 재화 등의 대금에 어떠한
                      명목의 수수료도 추가하여 징수할 수 없습니다.
                    </Typo.Paragraph>
                    <ul className="list depth2">
                      <li>폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체</li>
                      <li>선불카드, 직불카드, 신용카드 등의 각종 카드 결제</li>
                      <li>온라인무통장입금</li>
                      <li>전자화폐에 의한 결제</li>
                      <li>대금지급</li>
                      <li>“플랫폼”과 계약을 맺은 방식에 의한 결제</li>
                      <li>기타 전자적 지급 방법에 의한 대금 지급 등</li>
                    </ul>
                  </li>
                  <li>
                    <Typo.Title level={5}>
                      제11조(인증대행비용 및 사후정산)
                    </Typo.Title>
                    <ul className="list depth2">
                      <li>
                        인증대행 비용은 “이용자”가 인증 신청한 국가, 인증신청
                        제품, 인증범위 등에 따라 달리 책정되어 수신확인통지를
                        통하여 전달됩니다.
                      </li>
                      <li>
                        직접비의 경우 일부 또는 그 금액 전체가 “플랫폼” 또는
                        “회사”를 통하여 별도로 청구되거나 “이용자”가 직접
                        정산하도록 하며 해당 직접비를 납부하지 않아서 인증이
                        처리되지 않은 경우 “회사”는 인증 실패에 대한 책임이
                        없으며 수수료를 환불할 의무가 없습니다.
                      </li>
                      <li>
                        “회사”는 “이용자”가 인증대행에 필요한 비용 및 인증대행
                        비용을 지급하기 전에 해외 사업자의 인증관련 가격과 해외
                        수수료 및 해외 현지 제세금이 포함된 지급금액, 대행
                        수수료, 국내외 (예상)운송료 등의 형태로 구분하여
                        “이용자”에게 통지하여야 합니다. 단, 환율로 인한 변화에
                        대해서는 “회사”는 별도 통지하지 않으며 책임을 지지
                        않습니다.
                      </li>
                      <li>
                        직접비 관련하여 “이용자”가 직접 납부하지 않고 “회사”를
                        통해서 지급하는 경우 해외업체 서비스 가격 변경, 수수료
                        재협상, 품목분류 변경, 전산시스템 오류, 환율차이 등으로
                        인해 “이용자”가 지급한 금액과 인증대행 수행 시 발생한
                        실제 비용 간에 차이가 발생하는 경우 “회사”는
                        과부족금액을 사후 정산합니다. “회사”는 이러한 금액
                        차이가 발생할 수 있다는 사실 및 이를 사후 정산해야 함을
                        계약체결 전에 “이용자”에게 알기 쉽도록 고지해야 합니다.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typo.Title level={5}>제12조(인증확인)</Typo.Title>
                    <ul className="list depth2">
                      <li>
                        “회사”는 “이용자”가 인증대행을 신청한 제품 등에 대하여
                        신청한 인증이 제대로 되었는지 인증신청한 제품이 실제
                        제품과의 동일성 여부를 확인하고 해당 제품인증 신청이
                        수출입 법령 등에 접촉되지 않는지 여부 등의 확인을 할 수
                        있습니다. “회사”는 검수의 기준 및 범위를 정하여
                        “이용자”에게 미리 통지합니다.
                      </li>
                      <li>
                        “회사”가 해당 제품의 중대한 안전성 문제, 위법성, 유해성
                        등을 발견한 경우 선량한 관리자의 주의로써 인증대행을
                        거부할 의무가 있습니다. “회사”는 이로 인하여 인증대행
                        거부가 일어났을 시 수수료 환불에 대한 책임이 없으며
                        손해배상책임을 지지 않습니다.
                      </li>
                      <li>
                        “회사”는 검수의 하자로 인해 “이용자”가 입은 손해를
                        배상해야 합니다. 다만, “회사”가 제공하는 검수 범위를
                        벗어난 하자 및 재화 등의 성능·기능상의 하자에 대하여는
                        손해배상책임을 지지 않습니다.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typo.Title level={5}>제13조(통관) </Typo.Title>
                    <ul className="list depth2">
                      <li>
                        “회사”는 인증대행을 위하며 제품의 통관절차가 필요할 경우
                        수입요건을 구비하여 “회사”가 정한 운송 및 통관 업무
                        위탁사를 통하여 통관절차를 수행할 수 있습니다. 이때
                        발생하는 관세, 부가세 및 기타세금 (이하 “관·부가세
                        등”)은 “이용자” 또는 수하인이 부담합니다. “이용자”가
                        관·부가세 등을 납부하지 아니하면 “회사”는 해당 제품
                        인증대행을 중단할 수 있습니다.
                      </li>
                      <li>
                        “회사”는 수출입국가의 관세법 등 관련법령을 준수하고,
                        불법재화, 불법제품 등을 취급하지 않으며, 분할배송 및
                        가격 허위 신고 등 “이용자”의 불법행위 요청에는 협조하지
                        않습니다.
                      </li>
                      <li>
                        “회사”는 재화 또는 제품 등이 수출입국가의 관세규정에
                        따라 통관이 가능한지 여부에 대해서는 보증하지 않습니다.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typo.Title level={5}>제14조(긴급조치) </Typo.Title>
                    <ul className="list depth2">
                      <li>
                        “회사”는 제품, 재화 등에 관하여 “이용자”의 책임 있는
                        사유로 인하여 관할관청 등의 관련법령에 근거한 적법한
                        인도 요구가 있는 경우 해당 제품, 재화 등을 관할 기관에
                        인도합니다. 이 경우 “회사”는 지체 없이 이를 “이용자”에게
                        통지합니다.
                      </li>
                      <li>
                        전항의 조치로 인하여 “이용자”가 손해를 입었다고 할지라도
                        해당 손해에 대해서 “회사”는 책임을 지지 않으며, 이
                        경우에도 “이용자”의 해당 제품, 재화 등에 관한 서비스
                        이용 요금 및 관련 비용 등의 지급의무는 존속됩니다.
                      </li>
                      <li>
                        제1항의 관할관청 등이 제품, 재화 등을 반환하고
                        인증업무에 대해서 허가하였을 시, “회사”는 지체 없이
                        인증계약에 따른 업무를 계속해서 이행해야 합니다.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typo.Title level={5}>제15조(손해배상)</Typo.Title>
                    <ul className="list depth2">
                      <li>
                        “회사”가 “이용자”로부터 인증대행을 신청 받은 제품, 재화
                        등을 “해외사업자”에게 인도하는 과정 또는 운송구간에서
                        “회사”의 귀책사유가 아닌 이유로 해당 제품, 재화 등에
                        분실, 파손 등이 발생하였을 경우, “회사”는 “이용자”에게
                        재배송을 요청할 수 있습니다.
                      </li>
                      <li>
                        “이용자”가 부정확한 정보를 기입하여 손해가 발생한 경우,
                        “회사”의 고의 또는 중과실로 인한 경우를 제외하고
                        배상책임을 지지 아니합니다.
                      </li>
                      <li>
                        “해외사업자”의 귀책으로 인하여 서비스가 완료되지 않을
                        경우 “회사”는 손해배상 책임 지지 않습니다. 단, “회사”는
                        “회사”의 정책에 따라 “이용자”에게 수수료를 환불할 수
                        있습니다.
                      </li>
                      <li>
                        “회사”는 “해외사업자”와 신의성실로서 인증을 신청하였으나
                        제품의 성분으로 인하여 인증에 실패하는 경우 손해배상
                        책임을 지지 않으며 수수료 또한 환불하지 않습니다.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typo.Title level={5}>
                      제16조(책임의 특별소멸사유와 시효)
                    </Typo.Title>
                    <ul className="list depth2"></ul>
                  </li>
                  <li>
                    <Typo.Title level={5}></Typo.Title>
                    <ul className="list depth2">
                      <li>
                        “회사”가 손해배상책임을 부담하는 경우에도 이러한 책임은
                        “이용자”가 “회사”가 인증 실패를 통지한 날로부터 기산하여
                        1년안에 “이용자”가 해당 책임에 대해서 “회사”에게
                        통지하지 않고 경과하면 소멸합니다.
                      </li>
                      <li>
                        제1항의 규정에도 불구하고 “회사”가 “회사”의 중대한
                        귀책으로 인하여 인증실패한 사실을 알면서 이를 숨겼을 시
                        “회사”의 손해배상책임은 “이용자”가 인증 실패를 통지 받은
                        날로부터 5년간 존속합니다.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typo.Title level={5}>제17조(분쟁해결)</Typo.Title>
                    <ul className="list depth2">
                      <li>
                        “회사”는 “이용자”로부터 제출되는 불만사항 및 의견을 다른
                        사안에 우선하여 처리합니다. 다만, 신속한 처리가 곤란한
                        경우에는 “이용자”에게 그 사유와 처리일정을 지체 없이
                        통지하여야 합니다.
                      </li>
                      <li>
                        “회사”와 대한민국 국민 또는 대한민국에 사무소를 가지는
                        “이용자” 간에 발생한 분쟁과 관련하여 “이용자”의
                        피해구제신청이 있는 경우에는 공정거래위원회 또는
                        시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수
                        있습니다.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typo.Title level={5}>
                      제18조(관할법원 및 준거법)
                    </Typo.Title>
                    <ul className="list depth2">
                      <li>
                        “회사”와 대한민국 국민 또는 대한민국에 사무소를 가지는
                        “이용자” 간에 발생한 소송은 제소 당시의 “이용자”의
                        주소에 의하고, 주소가 없는 경우에는 거소를 관할하는
                        지방법원의 전속관할로 합니다. 다만, 제소 당시 “이용자”의
                        주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는
                        민사소송법상의 관할법원에 제기합니다.
                      </li>
                      <li>
                        “회사”와 “이용자” 간에 발생한 소송의 준거법은
                        대한민국법으로 합니다.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typo.Title level={5}>제19조(효력)</Typo.Title>
                    <ul className="list depth2">
                      “회사”와 “이용자”가 본 약관 제9조에 따라서 체결한
                      인증계약서의 내용과 본 약관의 내용과 상충되는 경우에는
                      인증계약서의 내용이 그 효력에 있어서 우선한다.
                    </ul>
                  </li>
                </ul>
              </TermsCard>
            )}
            {companyType === 'manu' && (
              <TermsCard>
                <ul className="list depth1">
                  <li>
                    <Typo.Title level={5}>제1조(목적)</Typo.Title>
                    <Typo.Paragraph style={{ paddingLeft: 16 }}>
                      이 약관은 주식회사 30COS(이하 “회사”라 한다)에서
                      “이용자”와 그 “이용자”와 사업관계에 있으며 “회사”가
                      제공하는 각 국가의 미용 또는 소비재 규제관련
                      인증서비스(이하 “서비스”라 한다)를 이용하는 업체(이하
                      “브랜드사”라 한다)간 업무 협의 및 편의성을 위하여
                      “이용자”에게 “플랫폼” 이용을 허하며 그에 따른 “이용자”의
                      권리․의무 및 책임사항을 규정함을 목적으로 합니다.
                    </Typo.Paragraph>
                  </li>
                  <li>
                    <Typo.Title level={5}>제2조(정의)</Typo.Title>
                    <ul className="list depth2">
                      <li>
                        이 약관에서 사용하는 용어의 의미는 다음 각 호와
                        같습니다.
                        <ul className="list depth3">
                          <li>
                            “플랫폼”은 “회사”가 이 약관에 의하여 “이용자”에게
                            정보통신설비와 정보통신망을 이용 또는 전자적 장치를
                            통하여 일정부분 비대면, 자동화된 방식으로 서비스를
                            제공하기 위하여 설정한 가상의 영업장 의미로
                            사용합니다.
                          </li>
                          <li>
                            “이용자”라 함은 “회사”가 제공하는 플랫폼을 이용하는
                            “제조사”를 말합니다.
                          </li>
                          <li>
                            “브랜드사”라 함은 “이용자”와 직접적 사업적 또는
                            계약적 관계에 있는 사업자로 “회사”의 인증대행
                            서비스를 이용하려고는 사업자를 지칭합니다.
                          </li>
                          <li>
                            “해외사업자”라 함은 대한민국 이외의 국적이거나
                            대한민국 이외의 국가에 사업자 등록, 법인, 영업소,
                            호스트서버 소재지 등을 가진 사업자를 의미합니다.
                          </li>
                          <li>
                            “인증대행”이라 함은 제품 등에 대하여 “브랜드사”의
                            해외 국가에서의 필요 인증에 대해서 컨설팅 및 대행해
                            줄 것을 의뢰하면 “회사”가 “이용자”가 제공한 정보를
                            취합하여 해당 국가 업체 등을 통해서 인증 관련 업무
                            처리를 제공하는 서비스를 의미합니다.
                          </li>
                          <li>
                            “인증확인”이라 함은 “이용자”가 인증대행을 신청한
                            제품의 “인증확인” 여부를 “회사”가 정한 기준에 따라
                            확인하여 제공하는 서비스를 의미합니다.
                          </li>
                        </ul>
                      </li>
                      <li>
                        “회원”이라 함은 “플랫폼”에 회원등록을 한 자로서,
                        계속적으로 “플랫폼”이 제공하는 서비스를 이용할 수 있는
                        자를 말합니다.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typo.Title level={5}>
                      제3조(약관 등의 명시와 설명 및 개정)
                    </Typo.Title>
                    <ul className="list depth2">
                      <li>
                        “플랫폼”은 이 약관의 내용과 상호 및 대표자 성명, 영업소
                        소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를
                        포함), 전화번호, 모사전송번호, 전자우편주소,
                        사업자등록번호, 통신판매업 신고번호, 개인정보관리책임자
                        등을 “이용자”가 쉽게 알 수 있도록 “플랫폼”의 초기
                        서비스화면(전면)에 게시합니다. 다만, 약관의 내용은
                        “이용자”가 연결화면을 통하여 볼 수 있도록 할 수
                        있습니다.
                      </li>
                      <li>
                        “플랫폼”은 「전자상거래 등에서의 소비자보호에 관한
                        법률」, 「약관의 규제에 관한 법률」, 「전자문서 및
                        전자거래기본법」, 「전자금융거래법」, 「전자서명법」,
                        「정보통신망 이용촉진 및 정보보호 등에 관한 법률」,
                        「방문판매 등에 관한 법률」, 「소비자기본법」 등 관련
                        법을 위배하지 않는 범위에서 이 약관을 개정할 수
                        있습니다.
                      </li>
                      <li>
                        “플랫폼”이 약관을 개정할 경우에는 적용일자 및 개정사유를
                        명시하여 현행약관과 함께 “플랫폼”의 초기화면에 그
                        적용일자 7일 이전부터 적용일자 전일까지 공지합니다.
                        다만, “이용자”에게 불리하게 약관내용을 변경하는 경우에는
                        최소한 14일 이상의 사전 유예기간을 두고 공지하며
                        “이용자”에게 재동의를 받는 절차를 거치도록 합니다. 이
                        경우 "플랫폼”은 개정 전 내용과 개정 후 내용을 명확하게
                        비교하여 “이용자”가 알기 쉽도록 표시합니다.
                      </li>
                      <li>
                        “플랫폼”이 약관을 개정할 경우에는 그 개정약관은 그
                        적용일자 이후에 체결되는 계약에만 적용되고 그 이전에
                        이미 체결된 계약에 대해서는 개정 전의 약관조항이 그대로
                        적용됩니다. 다만 이미 계약을 체결한 “이용자”가 개정약관
                        조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의
                        공지기간 내에 “플랫폼”에 송신하여 “플랫폼”의 동의를 받은
                        경우에는 개정약관 조항이 적용됩니다.
                      </li>
                    </ul>
                    <Typo.Paragraph style={{ paddingTop: 24 }}>
                      이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는
                      전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제
                      등에 관한 법률, 공정거래위원회가 정하는 전자상거래
                      등에서의 소비자 보호지침 및 관계법령 또는 상관례에
                      따릅니다.
                    </Typo.Paragraph>
                  </li>
                  <li>
                    <Typo.Title level={5}>제4조(회원가입)</Typo.Title>
                    <ul className="list depth2">
                      <li>
                        “이용자”는 “플랫폼”이 정한 가입 양식에 따라 회원정보를
                        기입한 후 이 약관에 동의한다는 의사표시를 함으로써
                        회원가입을 신청합니다.
                      </li>
                      <li>
                        “플랫폼”은 제1항과 같이 회원으로 가입할 것을 신청한
                        “이용자” 중 다음 각 호에 해당하지 않는 한 회원으로
                        등록합니다.
                        <ul className="list depth3">
                          <li>
                            가입신청자가 이 약관 제5조 제3항에 의하여 이전에
                            회원자격을 상실한 적이 있는 경우, 다만 제5조 제3항에
                            의한 회원자격 상실 후 3년이 경과한 자로서 “플랫폼”의
                            회원재가입 승낙을 얻은 경우에는 예외로 한다.
                          </li>
                          <li>등록 내용에 허위, 기재누락, 오기가 있는 경우</li>
                          <li>
                            기타 회원으로 등록하는 것이 “플랫폼”의 기술상 현저히
                            지장이 있다고 판단되는 경우
                          </li>
                        </ul>
                      </li>
                      <li>
                        회원가입계약의 성립 시기는 “플랫폼”의 승낙이 회원에게
                        도달한 시점으로 합니다.
                      </li>
                      <li>
                        회원은 회원가입시 등록한 사항에 변경이 있는 경우, 5
                        영업일 이내에 “플랫폼”에 회원정보 수정 등의 방법으로 그
                        변경사항을 알려야 합니다.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typo.Title level={5}>
                      제5조(회원 탈퇴 및 자격 상실 등)
                    </Typo.Title>
                    <ul className="list depth2">
                      <li>
                        회원은 “플랫폼”에 언제든지 탈퇴를 요청할 수 있으며
                        “플랫폼”은 탈퇴 요청 시 10 영업일 내에 회원탈퇴를
                        처리합니다.
                      </li>
                      <li>
                        “회사” 또는 그 계열사 및 “플랫폼”은 회원의 탈퇴로 인한
                        정보소멸, 권리 상실, 손해 등에 대한 일체의 책임을 지지
                        아니한다.
                      </li>
                      <li>
                        회원이 다음 각 호의 사유에 해당하는 경우, “플랫폼”은
                        회원자격을 제한 및 정지시킬 수 있습니다.
                        <ul className="list depth3">
                          <li>가입 신청 시에 허위 내용을 등록한 경우</li>
                          <li>
                            “플랫폼”을 이용하여 신청한 서비스 등의 수수료,
                            직접비, 대금, 기타 “플랫폼”이용에 및 관련 서비스에
                            관련하여 회원이 부담하는 채무를 기일에 지급하지 않는
                            경우
                          </li>
                          <li>
                            다른 사람의 “플랫폼” 이용을 방해하거나 그 정보를
                            도용하는 등 전자상거래 질서를 위협하는 경우
                          </li>
                          <li>
                            “플랫폼”을 이용하여 법령 또는 이 약관이 금지하거나
                            공서양속에 반하는 행위를 하는 경우
                          </li>
                        </ul>
                      </li>
                      <li>
                        “플랫폼”이 회원 자격을 제한․정지시킨 후, 동일한 행위가
                        2회 이상 반복되거나 30일 이내에 그 사유가 시정되지
                        아니하는 경우 “플랫폼”은 회원자격을 상실시킬 수
                        있습니다.
                      </li>
                      <li>
                        “플랫폼”이 회원자격을 상실시키는 경우에는 회원등록을
                        말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록
                        말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를
                        부여합니다.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typo.Title level={5}>제6조(회원에 대한 통지)</Typo.Title>
                    <ul className="list depth2">
                      <li>
                        “플랫폼”이 회원에 대한 통지를 하는 경우, 회원이
                        “플랫폼”과 미리 약정하여 지정한 전자우편 주소로 할 수
                        있습니다.
                      </li>
                      <li>
                        “플랫폼”은 불특정다수 회원에 대한 통지의 경우 1주일이상
                        “플랫폼” 게시판에 게시함으로써 개별 통지에 갈음할 수
                        있습니다. 다만, 회원 본인의 거래와 관련하여 중대한
                        영향을 미치는 사항에 대하여는 개별통지를 합니다.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typo.Title level={5}>
                      제7조(업무의 제공 및 변경)
                    </Typo.Title>
                    <ul className="list depth2">
                      <li>
                        “플랫폼”은 “이용자”를 위하여 선량한 관리자의 주의
                        의무로써 다음 각 호의 업무를 처리할 수 있습니다.
                        <ul className="list depth3">
                          <li>
                            “브랜드사”가 인증 신청한 제품 등의 컨설팅 및 인증 시
                            “이용자”가 제공한 정보의 처리
                          </li>
                          <li>
                            “브랜드사”와 “이용자”가 제공한 정보의 공유 및 재가공
                          </li>
                          <li>인증대행 시 정보보호 및 보안 관리</li>
                          <li>인증 등의 “인증확인” 등</li>
                          <li>인증 실패 시 관련 절차의 대행 및 정보제공</li>
                          <li>기타 인증 등의 관련된 제반업무</li>
                        </ul>
                        <Typo.Paragraph style={{ paddingTop: 8 }}>
                          명확성을 위하여 부연하자면 본 항 2호에 따라 “플랫폼”이
                          허용한 “브랜드사”에서 “플랫폼”을 통하여 직접 정보
                          재가공을 할 수 있습니다.
                        </Typo.Paragraph>
                      </li>
                      <li>
                        “플랫폼”이 제공하기로 “이용자”와 체결한 본 약정의
                        서비스의 내용을 인증진행 국가의 정책, 법령, 규제 또는
                        해당국가의 해당인증 사업자의 상황 등의 사유로 변경할
                        경우에는 그 사유를 “이용자”에게 통지 가능한 주소나
                        상호간 정한 연락처로 즉시 통지합니다.
                      </li>
                      <li>
                        전항의 경우 “플랫폼”은 이로 인하여 “이용자”가 입은
                        손해를 배상합니다. 다만, “플랫폼”이 고의 또는 과실이
                        없음을 입증하는 경우에는 그러하지 아니합니다.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typo.Title level={5}>
                      제8조(서비스이용 제한 및 중단)
                    </Typo.Title>
                    <ul className="list depth2">
                      <li>
                        “회사”는 “이용자”가 제공한 정보가 다음 각 호의 어느
                        하나에 해당하는 경우 “브랜드사” 및 “이용자”에 서비스
                        제공을 거절하고 서비스 진행을 책임 없이 중단 또는 종료할
                        수 있습니다.
                        <ul className="list depth3">
                          <li>신청내용에 허위, 기재누락, 오기가 있는 경우</li>
                          <li>
                            제공한 정보에 따라 서비스의 제공이 “회사”의 경영상
                            또는 기술상의 이유로 현저히 곤란한 경우
                          </li>
                          <li>
                            불법 또는 부당한 목적을 위해 서비스를 이용하는
                            것으로 판단되는 경우
                          </li>
                          <li>
                            서비스 받으려는 제품의 안전성, 유해성, 위험정도가
                            높다고 “회사”에서 판단하는 경우
                          </li>
                          <li>
                            인증국을 포함한 서비스관련한 인증 법령에 의하여
                            인증이 금지되는 제품
                          </li>
                          <li>
                            해외 서비스 관련된 사업자 또는 인증 사업자에서
                            정책적으로 금지하고 있는 제품
                          </li>
                        </ul>
                      </li>
                      <li>
                        제1항에 따라 서비스 제공을 거절하는 경우, “회사”는
                        “이용자”에게도 거절의 사유 및 근거를 통지하여야 합니다
                      </li>
                      <li>
                        “플랫폼”은 컴퓨터 등 정보통신설비의 보수점검․교체 및
                        고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의
                        제공을 일시적으로 중단할 수 있습니다.
                      </li>
                      <li>
                        “플랫폼”은 제3항의 사유로 서비스의 제공이 일시적으로
                        중단됨으로 인하여 “이용자” 또는 제3자가 입은 손해에
                        대하여 배상합니다. 단, “플랫폼”이 고의 또는 과실이
                        없음을 입증하는 경우에는 그러하지 아니합니다.
                      </li>
                      <li>
                        사업종목의 전환, 사업의 포기, 업체 간의 통합 등의(이하
                        총칭하여 “경영권의 변동”이라 한다) 이유로 서비스를
                        제공할 수 없게 되는 경우에는 “플랫폼”은 “이용자”에게
                        통지하고 완료 또는 종료가 되지 않은 서비스에 대해서 받은
                        비용에 대해서는 환불절차를 진행하여 지급합니다. 단,
                        경영권 변동 이후 서비스가 제공이 계속될 경우 “플랫폼”은
                        “이용자”에게 공지의 의무가 없으며 또는 “이용자”는 이에
                        대하여 이의제기를 할 수 없습니다. 단, 경영권 변동으로
                        인하여 약관이 변경될 경우 제3조 3항에 따라 공지합니다.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typo.Title level={5}>제9조(계약관계 성립)</Typo.Title>
                    <ul className="list depth2">
                      <li>
                        “이용자”는 명시적인 계약서를 체결하기 전에는 “회사”와 본
                        약정 외에 어떠한 계약적 관계도 성립하지 않음을 이해하고
                        동의합니다.
                      </li>
                      <li>
                        “이용자”는 “브랜드사”와 양 사간 계약관계는 “회사”와
                        무관함을 이해하며 “회사”가 체결하지 않은 계약의 경우
                        “회사”에 어떠한 구속력이 없음을 이해하고 동의합니다.
                      </li>
                      <li>
                        “이용자”는 “회사”에게 “브랜드사”의 인증관련 서비스와
                        관련하여 어떠한 권리, 책임, 손해배상을 청구할 수
                        없습니다.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typo.Title level={5}>제10조(제공정보 확인)</Typo.Title>
                    <ul className="list depth2">
                      <li>
                        “회사”는 “이용자”가 제공한 정보를 기초로 “브랜드사”가
                        인증대행을 신청한 제품 등에 대하여 신청한 인증진행
                        과정에서 추가로 필요정보를 요청하거나 인증신청한 제품이
                        실제 제품과의 동일성 여부를 확인하고 해당 제품인증
                        신청이 수출입 법령 등에 접촉되지 않는지 여부 등의 확인을
                        할 수 있습니다.
                      </li>
                      <li>
                        “회사”가 해당 제품의 중대한 안전성 문제, 위법성, 유해성
                        등을 발견한 경우 선량한 관리자의 주의로써 인증대행을
                        거부할 의무가 있습니다. “회사”는 이로 인하여 인증대행
                        거부가 일어났을 시 “이용자”에게 어떠한 책임이 없으며
                        손해배상책임을 지지 않습니다.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typo.Title level={5}>제11조(손해배상)</Typo.Title>
                    <ul className="list depth2">
                      <li>
                        “이용자”가 부정확한 정보를 기입하여 손해가 발생한 경우,
                        “회사”의 고의 또는 중과실로 인한 경우를 제외하고
                        배상책임을 지지 아니합니다
                      </li>
                      <li>
                        “이용자”의 부정확한 정보를 기입하여 “회사”에 손해가
                        발생한 경우, “이용자”는 “회사”에 배상책임이 있습니다.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typo.Title level={5}>제12조(분쟁해결)</Typo.Title>
                    <ul className="list depth2">
                      <li>
                        “회사”는 “이용자”로부터 제출되는 불만사항 및 의견을 다른
                        사안에 우선하여 처리합니다. 다만, 신속한 처리가 곤란한
                        경우에는 “이용자”에게 그 사유와 처리일정을 지체 없이
                        통지하여야 합니다.
                      </li>
                      <li>
                        “회사”와 대한민국 국민 또는 대한민국에 사무소를 가지는
                        “이용자” 간에 발생한 분쟁과 관련하여 “이용자”의
                        피해구제신청이 있는 경우에는 공정거래위원회 또는
                        시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수
                        있습니다.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typo.Title level={5}>
                      제13조(관할법원 및 준거법)
                    </Typo.Title>
                    <ul className="list depth2">
                      <li>
                        “회사”와 대한민국 국민 또는 대한민국에 사무소를 가지는
                        “이용자” 간에 발생한 소송은 제소 당시의 “이용자”의
                        주소에 의하고, 주소가 없는 경우에는 거소를 관할하는
                        지방법원의 전속관할로 합니다. 다만, 제소 당시 “이용자”의
                        주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는
                        민사소송법상의 관할법원에 제기합니다.
                      </li>
                      <li>
                        “회사”와 “이용자” 간에 발생한 소송의 준거법은
                        대한민국법으로 합니다.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typo.Title level={5}>제14조(효력)</Typo.Title>
                    <ul className="list depth2">
                      <li>
                        “회사”와 “이용자”가 본 약관 제9조에 따라서 체결한
                        인증계약서의 내용과 본 약관의 내용과 상충되는 경우에는
                        인증계약서의 내용이 그 효력에 있어서 우선한다.
                      </li>
                    </ul>
                  </li>
                </ul>
              </TermsCard>
            )}
            {companyType === 'material' && (
              <TermsCard>
                <ul className="list depth1">
                  <li>
                    <Typo.Title level={5}>제1조(목적)</Typo.Title>
                    <Typo.Paragraph style={{ paddingLeft: 16 }}>
                      이 약관은 주식회사 30COS(이하 “회사”라 한다)에서 제공하는
                      각 국가의 미용 또는 소비재 규제관련 인증서비스(이하
                      “서비스”라 한다)를 위하여 “제조사”, “브랜드사” 및
                      “원료사”간 업무 협의 및 편의성을 위하여 “원료사”에게
                      “플랫폼” 이용을 허하며 그에 따른 “이용자”의 권리.의무 및
                      책임사항을 규정함을 목적으로 합니다.
                    </Typo.Paragraph>
                  </li>
                  <li>
                    <Typo.Title level={5}>제2조(정의)</Typo.Title>
                    <ul className="list depth2">
                      <li>
                        이 약관에서 사용하는 용어의 의미는 다음 각 호와
                        같습니다.
                        <ul className="list depth3">
                          <li>
                            “플랫폼”은 “회사”가 이 약관에 의하여 “제조사”,
                            “브랜드사 및 “원료사”에게 정보통신설비와
                            정보통신망을 이용 또는 전자적 장치를 통하여 일정부분
                            비대면, 자동화된 방식으로 서비스를 제공하기 위하여
                            설정한 가상의 영업장 의미로 사용합니다.
                          </li>
                          <li>
                            “이용자”라 함은 “회사”가 제공하는 “플랫폼”을
                            이용하는 "원료사"를 말합니다.
                          </li>
                          <li>
                            “원료사”는 화장품 및 각종 미용 원료의 수입, 유통
                            또는 생산하는 업체 또는 사업자를 말합니다.
                          </li>
                          <li>
                            “브랜드사”라 함은 “제조사”와 사업적 또는 계약적
                            관계에 있는 사업자로 “회사”의 인증대행 서비스를
                            이용하는 사업자를 지칭합니다.
                          </li>
                          <li>
                            “해외사업자”라 함은 대한민국 이외의 국적이거나
                            대한민국 이외의 국가에 사업자 등록, 법인, 영업소,
                            호스트서버 소재지 등을 가진 사업자를 의미합니다.
                          </li>
                          <li>
                            “인증대행”이라 함은 제품 등에 대하여 “브랜드사” 해외
                            국가에서의 필요 인증에 대해서 컨설팅 및 대행해 줄
                            것을 의뢰하면 “회사”가 “원료사” 또는 “제조사”가
                            제공한 정보를 취합하여 해당 국가 업체 등을 통해서
                            인증 관련 업무 처리를 제공하는 서비스를 의미합니다.
                          </li>
                          <li>
                            “인증확인”이라 함은 “제조사”가 인증대행을 신청한
                            제품의 “인증확인” 여부를 “회사”사가 정한 기준에 따라
                            확인하여 제공하는 서비스를 의미합니다.
                          </li>
                        </ul>
                      </li>
                      <li>
                        “회원”이라 함은 제4조에 따라 “플랫폼”에 회원등록을 한
                        자로서, 계속적으로 “플랫폼”이 제공하는 서비스를 이용할
                        수 있는 자를 말합니다.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typo.Title level={5}>
                      제3조(약관 등의 명시와 설명 및 개정)
                    </Typo.Title>
                    <ul className="list depth2">
                      <li>
                        “플랫폼”은 이 약관의 내용과 상호 및 대표자 성명,영업소
                        소재지 주소 (소비자의 불만을 처리할 수 있는 곳의 주소를
                        포함), 전화번호, 모사전송번호, 전자우편주소,
                        사업자등록번호, 통신판매업 신고번호, 개인정보관리책임자
                        등을 “이용자”가 쉽게 알 수 있도록 “플랫폼”의 초기
                        서비스화면(전면)에 게시합니다. 다만, 약관의 내용은
                        “이용자”가 연결화면을 통하여 볼 수 있도록 할 수
                        있습니다.
                      </li>
                      <li>
                        “플랫폼”은 「전자상거래 등에서의 소비자보호에 관한
                        법률」, 「약관의 규 제에 관한 법률」, 「전자문서 및
                        전자거래기본법」, 「전자금융거래법」, 「전자서명법」,
                        「정보통신망 이용촉진 및 정보보호 등에 관한 법률」,
                        「방문판매 등에 관한 법률」, 「소비자기본법」 등
                        관련법을 위배하지 않는 범위에서 이 약관을 개정할 수
                        있습니다.
                      </li>
                      <li>
                        “플랫폼”이 약관을 개정할 경우에는 적용일자 및 개정사유를
                        명시하여 현행약관과 함께 “플랫폼”의 초기화면 또는
                        전자우편 주소로 그 적용일자 7일 이전부터 적용일자
                        전일까지 공지합니다. 다만, “이용자”에게 불리하게 약관
                        내용을 변경하는 경우에는 최소한 14일 이상의 사전
                        유예기간을 두고 공지하며 “이용자”에게 재동의를 받는
                        절차를 거치도록 합니다. 이 경우 "플랫폼”은 개정 전
                        내용과 개정 후 내용을 명확하게 비교하여 “이용자”가 알기
                        쉽도록 표시합니다.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typo.Title level={5}>제4조(회원가입)</Typo.Title>
                    <ul className="list depth2">
                      <li>
                        “원료사”는 “플랫폼”이 정한 가입 양식에 따라 회원정보를
                        기입한 후 이 약관에 동의한다는 의사표시를 함으로써
                        회원가입을 신청합니다.
                      </li>
                      <li>
                        “플랫폼”은 제1항과 같이 회원으로 가입할 것을 신청한
                        “이용자” 중 다음 각 호에 해당하지 않는 한 회원으로
                        등록합니다.
                        <ul className="list depth3">
                          <li>
                            가입신청자가 이 약관 제5조 제3항에 의하여 이전에
                            회원자격을 상실한 적이 있는 경우, 다만 제5조 제3항에
                            의한 회원자격 상실 후 2년이 경과한 자로서 “플랫폼”의
                            회원재가입 승낙을 얻은 경우에는 예외로 한다.
                          </li>
                          <li>등록 내용에 허위, 기재누락, 오기가 있는 경우</li>
                          <li>
                            기타 회원으로 등록하는 것이 “플랫폼”의 기술상 현저히
                            지장이 있다고 판단되는 경우
                          </li>
                        </ul>
                      </li>
                      <li>
                        회원가입계약의 성립 시기는 “플랫폼”의 승낙이 회원에게
                        도달한 시점으로 합니다.
                      </li>
                      <li>
                        회원은 회원가입시 등록한 사항에 변경이 있는 경우, 5
                        영업일 이내에 “플랫폼”에 회원정보 수정 등의 방법으로 그
                        변경사항을 알려야 합니다.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typo.Title level={5}>
                      제5조(회원 탈퇴 및 자격 상실 등)
                    </Typo.Title>
                    <ul className="list depth2">
                      <li>
                        회원은 “플랫폼”에 언제든지 탈퇴를 요청할 수 있으며
                        “플랫폼”은 탈퇴 요청 시 10 영업일 내에 회원탈퇴를
                        처리합니다.
                      </li>
                      <li>
                        “회사” 또는 그 계열사 및 “플랫폼”은 회원의 탈퇴로 인한
                        정보소멸, 권리 상실, 손해 등에 대한 일체의 책임을 지지
                        않습니다.
                      </li>
                      <li>
                        회원이 다음 각 호의 사유에 해당하는 경우, “플랫폼”은
                        회원자격을 제한 및 정지시킬 수 있습니다.
                        <ul className="list depth3">
                          <li>가입 신청 시에 허위 내용을 등록한 경우</li>
                          <li>
                            “플랫폼”을 이용하여 신청한 서비스 등의 수수료,
                            직접비, 대금, 기타 “플랫폼” 이용에 및 관련 서비스에
                            관련하여 회원이 부담하는 채무를 기일에 지급하지 않는
                            경우
                          </li>
                          <li>
                            다른 사람의 “플랫폼” 이용을 방해하거나 그 정보를
                            도용하는 등 전자상거래 질서를 위협하는 경우
                          </li>
                          <li>
                            “플랫폼”을 이용하여 법령 또는 이 약관이 금지하거나
                            공서양속에 반하는 행위를 하는 경우
                          </li>
                          <li>
                            고의성 여부와 상관없이 “이용자”로서 “플랫폼”에
                            정보를 오기재를 한 경우
                          </li>
                        </ul>
                      </li>
                      <li>
                        “플랫폼”이 회원 자격을 제한.정지시킨 후, 동일한 행위가
                        2회 이상 반복 되거나 30일 이내에 그 사유가 시정되지
                        아니하는 경우 “플랫폼”은 회원자격을 상실시킬 수
                        있습니다.
                      </li>
                      <li>
                        “플랫폼”이 회원자격을 상실시키는 경우에는 회원등록을
                        말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록
                        말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를
                        부여합니다.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typo.Title level={5}>제6조(회원에 대한 통지)</Typo.Title>
                    <ul className="list depth2">
                      <li>
                        “플랫폼”이 회원에 대한 통지를 하는 경우, 회원이
                        “플랫폼”과 미리 약정하여 지정한 전자우편 주소로 할 수
                        있습니다.
                      </li>
                      <li>
                        “플랫폼”은 불특정다수 회원에 대한 통지의 경우 1주일이상
                        “플랫폼” 게시판에 게시함으로써 개별 통지에 갈음할 수
                        있습니다. 다만, 회원 본인의 거래와 관련하여 중대한
                        영향을 미치는 사항에 대하여는 개별통지를 합니다.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typo.Title level={5}>
                      제7조(업무의 제공 및 변경)
                    </Typo.Title>
                    <ul className="list depth2">
                      <li>
                        “플랫폼”은 “이용자”를 위하여 선량한 관리자의 주의
                        의무로써 다음 각 호의 업무를 처리할 수 있습니다.
                        <ul className="list depth3">
                          <li>
                            “브랜드사”가 인증 신청한 제품 등의 컨설팅 및 인증 시
                            “이용자”가 제공한 정보의 처리
                          </li>
                          <li>
                            “이용자”가 제공한 정보를 기반한 “브랜드사” 제품의
                            인증 등의 “인증확인” 등
                          </li>
                          <li>
                            “이용자”가 기입 또는 제공한 정보의 오기재 및 문제 시
                            관련 절차의 대행 및 정보제공
                          </li>
                          <li>기타 인증 등의 관련된 제반업무</li>
                        </ul>
                      </li>
                      <li>
                        “회사”는 “브랜드사”, “제조사”, “원료사”가 제공한 정보의
                        검색, 공유 (원료 세부정보, 출처, HS CODE, 제조사
                        독점여부, 효능, 마케팅 자료 등을 포함 한다) 및 재가공의
                        업무를 서로 할 수 있도록 “플랫폼”을 제공합니다(이하
                        “협업툴”이라 한다). 단, “회사” 또는 “플랫폼”은 단순
                        정보의 “협업툴”을 제공하며 제공된 정보의 진실성, 적합성,
                        정확성, 안정성 등을 확인하지 아니 하면 그에 따른 책임을
                        지지 아니합니다.
                      </li>
                      <li>
                        “이용자”외에 “플랫폼”을 이용하는 제3의 타 원료사(이하
                        “타원료사”) 또한 본 항에 따라 “이용자”가 제공한 정보의
                        검색, 공유 및 재가공이 가능합니다.
                      </li>
                      <li>
                        “플랫폼”은 “이용자”에서 제공한 정보를 데이터로 이용 및
                        통계화 할 수 있으며 마케팅 목적으로 사용하거나
                        “브랜드사”, “제조사”, “타원료사”에 전 달할 수 있습니다.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typo.Title level={5}>
                      제8조(서비스이용 제한 및 중단)
                    </Typo.Title>
                    <ul className="list depth2">
                      <li>
                        “회사”는 “이용자”가 제공한 정보가 다음 각 호의 어느
                        하나에 해당하는 경우 “브랜드사” 및 “이용자”에 서비스
                        제공을 거절하고 서비스 진행을 책임 없이 중단 또는 종료할
                        수 있습니다.
                        <ul className="list depth3">
                          <li>신청내용에 허위, 기재누락, 오기가 있는 경우</li>
                          <li>
                            제공한 정보에 따라 서비스의 제공이 “회사”의 경영상
                            또는 기술상의 이유로 현저히 곤란한 경우
                          </li>
                          <li>
                            불법 또는 부당한 목적을 위해 서비스를 이용하는
                            것으로 판단되는 경우
                          </li>
                          <li>
                            원료의 안전성, 유해성, 위험정도가 높다고 “회사”에서
                            판단하는 경우 (단, 본 호로 인하여 “회사”또는
                            “플랫폼”에서 원료에 안전성여부를 판단한다고 해석되지
                            아니하며 제7조 2항에 따라 “회사” 또는 “플랫폼에서
                            원료에 대해서 안전성, 유해성, 위험 등에 대하여
                            책임지지 아니 한다.)
                          </li>
                          <li>
                            인증국가를 포함한 서비스 관련 인증 법령에 의하여
                            인증이 금지되는 원료
                          </li>
                          <li>
                            해외 서비스 관련된 사업자 또는 인증 사업자에서
                            정책적으로 금지하고 있는 제품
                          </li>
                          <li>
                            “플랫폼”으로 “이용자”가 획득한 정보를 “회사”의 사전
                            서면 승인없이 무단으로 유출 또는 제3자와
                            “플랫폼”에서 공유했을 경우
                          </li>
                        </ul>
                      </li>
                      <li>
                        제1항에 따라 서비스 제공을 거절하는 경우, “회사”는
                        “이용자”에게도 거절의 사유 및 근거를 통지하여야 합니다
                      </li>
                      <li>
                        “플랫폼”은 컴퓨터 등 정보통신설비의 보수점검․교체 및
                        고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의
                        제공을 일시적으로 중단할 수 있습니다.
                      </li>
                      <li>
                        “플랫폼”은 제3항의 사유로 서비스의 제공이 일시적으로
                        중단됨으로 인하여 “이용자” 또는 제3자가 입은 손해에
                        대하여 배상합니다. 단, “플랫폼”이 고의 또는 과실이
                        없음을 입증하는 경우에는 그러하지 아니합니다.
                      </li>
                      <li>
                        사업종목의 전환, 사업의 포기, 업체 간의 통합 등의(이하
                        총칭하여 “경영 권의 변동”이라 한다) 이유로 서비스를
                        제공할 수 없게 되는 경우에는 “플랫폼”은 “이용자”에게
                        통지하고 완료 또는 종료가 되지 않은 서비스에 대해서 받은
                        비용에 대해서는 환불절차를 진행하여 지급합니다. 단,
                        경영권 변동 이후 서비스가 제공이 계속될 경우 “플랫폼”은
                        “이용자”에게 공지의 의 무가 없으며 또는 “이용자”는 이에
                        대하여 이의제기를 할 수 없습니다. 단, 경영권 변동으로
                        인하여 약관이 변경될 경우 제3조 3항에 따라 공지합니다.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typo.Title level={5}>제9조(계약관계 성립)</Typo.Title>
                    <ul className="list depth2">
                      <li>
                        “이용자”는 명시적인 계약서를 체결하기 전에는 “회사”와 본
                        약정 외에 어떠한 계약적 관계도 성립하지 않음을 이해하고
                        동의합니다.
                      </li>
                      <li>
                        “이용자”는 “브랜드사”와 양 사간 계약관계가 있을 시 이는
                        “회사”와 무관함을 이해하며 “회사”가 체결하지 않은 계약의
                        경우 “회사”에 어떠한 구속력이 없음을 이해하고
                        동의합니다.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typo.Title level={5}>제10조(제공정보 확인)</Typo.Title>
                    <ul className="list depth2">
                      <li>
                        “회사”는 “이용자”가 제공한 정보를 기초로 “브랜드사”가
                        인증대행을 신청한 제품 등에 대하여 신청한 인증진행
                        과정에서 추가로 필요정보를 요청하거나 인증신청한 제품이
                        실제 제품과의 동일성 여부를 확인하고 해당 제품인증
                        신청이 수출입 법령 등에 접촉되지 않는지 여부 등의 확인을
                        할 수 있습니다.
                      </li>
                      <li>
                        “회사”가 원료의 중대한 안전성 문제, 위법성, 유해성 등을
                        발견한 경우 선량한 관리자의 주의로써 인증대행을 거부할
                        의무가 있습니다. “회사"는 이로 인하여 인증대행 거부가
                        일어났을 시 “이용자”에게 어떠한 책임이 없으며
                        손해배상책임을 지지 않습니다.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typo.Title level={5}>제11조(손해배상)</Typo.Title>
                    <ul className="list depth2">
                      <li>
                        “이용자”가 잘못된 정보제공, 부정확한 정보를 기입, 오기재
                        등을 하여 손해가 발생한 경우, “회사”의 고의 또는
                        중과실로 인한 경우를 제외하고 배상 책임을 지지
                        아니합니다.
                      </li>
                      <li>
                        “이용자”는 “플랫폼”에 “이용자”에서 잘못된 정보제공 또는
                        오기재로 인한 “회사”에 손해가 발생할 경우 “이용자”는
                        “회사”에 배상책임이 있습니다.
                      </li>
                      <li>
                        “이용자”는 “회사”에게 “브랜드사”의 인증관련 서비스와
                        관련하여 어떠한 권리, 책임, 손해배상을 청구할 수 없음에
                        동의합니다.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typo.Title level={5}>제12조(분쟁해결)</Typo.Title>
                    <ul className="list depth2">
                      <li>
                        “회사”는 “이용자”로부터 제출되는 불만사항 및 의견을 다른
                        사안에 우선하여 처리합니다. 다만, 신속한 처리가 곤란한
                        경우에는 “이용자”에게 그 사유와 처리일정을 지체 없이
                        통지하여야 합니다.
                      </li>
                      <li>
                        “회사”와 대한민국 국민 또는 대한민국에 사무소를 가지는
                        “이용자” 간에 발생한 분쟁과 관련하여 “이용자”의
                        피해구제신청이 있는 경우에는 공정거래위원회 또는
                        시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습
                        니다.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typo.Title level={5}>
                      제13조(관할법원 및 준거법)
                    </Typo.Title>
                    <ul className="list depth2">
                      <li>
                        “회사”와 대한민국 국민 또는 대한민국에 사무소를 가지는
                        “이용자” 간에 발생한 소송은 제소 당시의 “이용자”의
                        주소에 의하고, 주소가 없는 경우 에는 거소를 관할하는
                        지방법원의 전속관할로 합니다. 다만, 제소 당시 “이용자”의
                        주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는
                        민사소송법상의 관할법원에 제기합니다.
                      </li>
                      <li>
                        “회사”와 “이용자” 간에 발생한 소송의 준거법은 대한민국
                        법으로 합니다.
                      </li>
                      <li>
                        이 약관에서 정하지 아니한 사항과 이 약관의 해석에
                        관하여는 전자상거래 등에서의 소비자보호에 관한 법률,
                        약관의 규제 등에 관한 법률, 공정 거래위원회가 정하는
                        전자상거래 등에서의 소비자 보호지침 및 관계법령 또는
                        상관례에 따릅니다.
                      </li>
                    </ul>
                  </li>
                </ul>
              </TermsCard>
            )}
          </Collapse.Panel>
          <Collapse.Panel
            key="private"
            header={
              <Form.Item
                name="private"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject('필수 체크항목입니다.'),
                  },
                ]}
              >
                <Checkbox
                  onChange={(e) => onCheckPrivate(e.target.checked)}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Typography.Text
                    type="primary"
                    color="slate"
                    inline
                    medium
                    onClick={(e) => e.stopPropagation()}
                  >
                    개인정보 수집·이용 동의
                  </Typography.Text>
                  <Typography.Text
                    color="primary"
                    type="primary"
                    inline
                    gutter={{ left: 4 }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    (필수)
                  </Typography.Text>
                </Checkbox>
              </Form.Item>
            }
          >
            <TermsCard size="small">
              <Typo.Paragraph>
                주식회사 30COS
                (이하 “회사”이라 함)는 법령에서 정하는 사업 및 정보 등 각종 서비스 제공을 위한 개인정보 수집·이용·제공을 위하여 개인정보보호법 제15조, 제17조 및 제22조에 따라 귀하의 동의를 받고자 합니다.
              </Typo.Paragraph>
              <Typo.Paragraph>
                &lt;개인정보 수집·이용에 대한 동의&gt;
              </Typo.Paragraph>
              <ul className="list depth3" style={{ paddingLeft: 32 }}>
                <li>
                  개인정보의 수집‧이용 목적
                  <ul className="list dash">
                    <li>
                      “회사”가 수집하는 개인정보는 “회사”를 포함한 대한민국과
                      해외 정부, 정부기관 및 제3의 외주업체
                      등과의 원활한 사업수행, 인증과 관련한
                      서비스 및 각종 정보를 제공하기 위해 수집·이용합니다.
                    </li>
                  </ul>
                </li>
                <li>
                  수집하는 개인정보의 항목
                  <ul className="list dash">
                    {companyType !== 'manu' ? (
                      <li>
                        필수 정보 : 회사명(국문), 회사명(영문),
                        아이디(사업자등록번호), 이름, 부서명, 직급,
                        연락처, 이메일 등
                      </li>
                    ) : (
                      <li>
                        필수 정보 : 회사명(국문), 회사명(영문),
                        아이디(사업자등록번호), 제 1공장 소재지(국문), 제 1공장
                        소재지(영문), 대표자 성명(국문), 대표자 성명(영문), 회사
                        대표번호, 팩스 번호, 담당자이름, 부서명, 직급,
                        연락처, 이메일 등
                      </li>
                    )}
                  </ul>
                </li>
                <li>
                  개인정보의 보유 및 이용 기간 : 개인정보의 수집 및 이용
                  목적이 달성되면 파기하는 것을 원칙으로 합니다. 다만,
                  관계법령의 규정에 따라 아래와 같이 관계 법령에서 정한 일정한
                  기간 동안 회원정보를 보관하는 것을 포함하여, 인증유효기간
                  동안에는 지정한 실무자가 “회사”를 대신하는 것으로 간주하여
                  해당 서비스를 지속적으로 이용할 목적이 있다고 보여짐에 따라
                  인증유효기간이 만료되는 시점을 파기하는 시점으로 본다.
                  <ul className="list dash">
                    <li>
                      가입자 전기통신일시, 개시·종료시간, 상대방의 가입자번호,
                      사용도수, 발신기지국 위치추적자료(통신비밀보호법, 1년)
                    </li>
                    <li>
                      컴퓨터통신, 인터넷 로그기록자료, 접속지 추적자료
                      (통신비밀보호법, 3개월)
                    </li>
                    <li>
                      표시·광고에 관한 기록 (전자상거래 등에서의 소비자보호에
                      관한 법률, 6개월)
                    </li>
                    <li>
                      계약 또는 청약철회 등에 관한 기록 (전자상거래 등에서의
                      소비자보호에 관한 법률, 5년)
                    </li>
                    <li>
                      대금결제 및 재화 등의 공급에 관한 기록 (전자상거래
                      등에서의 소비자보호에 관한 법률, 5년)
                    </li>
                    <li>
                      소비자의 불만 또는 분쟁처리에 관한 기록 (전자상거래
                      등에서의 소비자보호에 관한 법률, 3년)
                    </li>
                    <li>전자금융 거래에 관한 기록 (전자금융거래법, 5년)</li>
                  </ul>
                </li>
                <li>
                  귀하는 개인정보 수집·이용에 동의하지 않으실 수 있습니다. 동의 거부 시에는
                  서비스 제공이 제한될 수 있습니다.
                </li>
              </ul>
            </TermsCard>
          </Collapse.Panel>
          <Collapse.Panel
            key="thirdParty"
            header={
              <Form.Item
                name="thirdParty"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject('필수 체크항목입니다.'),
                  },
                ]}
              >
                <Checkbox
                  onChange={(e) => onCheckThirdParty(e.target.checked)}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Typography.Text
                    type="primary"
                    color="slate"
                    inline
                    medium
                    onClick={(e) => e.stopPropagation()}
                  >
                    개인정보 제3자 제공 동의
                  </Typography.Text>
                  <Typography.Text
                    color="primary"
                    type="primary"
                    inline
                    gutter={{ left: 4 }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    (필수)
                  </Typography.Text>
                </Checkbox>
              </Form.Item>
            }
          >
            <TermsCard size="small">
              <ul className="list depth3" style={{ paddingLeft: 32 }}>
                {companyType !== 'manu' ? (
                  <li style={{ marginTop: 0 }}>
                    제공받는 자 : 30COS, 30COS에서 지정한 인증Agency,
                    “브랜드사”에서 지정한 buyer, 수출 바우처를 수행하기 위해
                    관련된 정부기관 등
                  </li>
                ) : (
                  <li>
                    제공받는 자 : 30COS, “브랜드사”, 30COS에서 지정한
                    인증Agency, “브랜드사”에서 지정한 buyer, 수출 바우처를
                    수행하기 위해 관련된 정부기관 등
                  </li>
                )}
                <li>
                  제공받는자의 이용 목적 :  해외 수출을 위한 인증 및 등록,
                  “회사” 수행사업 관련 사업성과 보고, 정산, 평가
                </li>
                {companyType !== 'manu' ? (
                  <li>
                    제공하는 개인정보 항목 :  회사명(국문), 회사명(영문),
                    아이디(사업자등록번호), 이름, 부서명, 직급, 연락처, 이메일
                    등
                  </li>
                ) : (
                  <li>
                    제공하는 개인정보 항목 :  회사명(국문), 회사명(영문),
                    아이디(사업자등록번호), 제 1공장 소재지(국문), 제 1공장
                    소재지(영문), 대표자 성명(국문), 대표자 성명(영문), 회사
                    대표번호, 팩스 번호, 담당자이름, 부서명, 직급,
                    연락처, 이메일 등
                  </li>
                )}
                <li>
                  제공받는자의 보유·이용 기간 : 인증유효기간 또는 세무 신고 및
                  과세에 활용. 5년 (국세기본법 제85조의 3에 근거)
                </li>
                <li>
                  귀하는 위와 같이 개인정보를 제3자에게 제공 되는 것에 대한 동의를 거부할 권리가 있습니다. 단, 제3자에게 개인정보 제공에 동의하지 않는 경우 프로그램
                  이용 및 인증 신청이 제한됩니다.
                </li>
              </ul>
            </TermsCard>
          </Collapse.Panel>
          <Collapse.Panel
            key="policy"
            header={
              <Form.Item
                name="policy"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject('필수 체크항목입니다.'),
                  },
                ]}
              >
                <Checkbox
                  onChange={(e) => {
                    onCheckPolicy(e.target.checked);
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Typography.Text
                    type="primary"
                    color="slate"
                    inline
                    medium
                    onClick={(e) => e.stopPropagation()}
                  >
                    개인정보 처리방침 동의
                  </Typography.Text>
                  <Typography.Text
                    color="primary"
                    type="primary"
                    inline
                    gutter={{ left: 4 }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    (필수)
                  </Typography.Text>
                </Checkbox>
              </Form.Item>
            }
          >
            <TermsCard size="small">
              <Typo.Paragraph>
                주식회사 30COS(이하 "회사"이라 함)이 취급하는 모든 개인정보는
                관련법령에 근거하거나 정보주체의 동의에 의하여 수집·보유 및
                처리되고 있습니다.
              </Typo.Paragraph>
              <Typo.Paragraph>
                본 방침은 2021년 3월 31일부터 시행됩니다.
              </Typo.Paragraph>
              <Typo.Paragraph>
                “회사”는 법령의 규정에 따라 수집·보유 및 처리하는 개인정보를
                업무의 적절한 수행과 정보주체의 권익을 보호하기 위하여 적법하고
                적정하게 취급할 것입니다. 또한, “회사”는 관련 법령에서 규정한
                바에 따라 “회사”에서 보유하고 있는 개인정보에 대한 열람청구권 및
                정정청구권 등 “이용자”의 권익을 존중하며, 여러분은 이러한 법령상
                권익의 침해 등에 대하여 행정심판법에서 정하는 바에 따라
                행정심판을 청구할 수 있으며, 개인정보분쟁조정위원회,
                개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수
                있습니다. “회사”의 개인정보 처리방침은 현행 「개인정보보호법에
                근거를 두고 있습니다. 개인정보를 처리하는 자사 소관 홈페이지의
                경우 해당 홈페이지에 별도의 개인정보처리방침을 정하여 운영하고
                있습니다.
              </Typo.Paragraph>
              <Typo.Title level={5}>제1조 개인정보의 처리 목적</Typo.Title>
              <Typo.Paragraph style={{ paddingLeft: 16 }}>
                “회사”는 업무 수행 및 서비스 제공 등의 목적으로 최소한으로
                개인정보를 수집하고 있으며, 자세한 사항은 30COS 홈페이지에
                게재하여 정보주체가 확인할 수 있도록 안내를 하고 있습니다.
              </Typo.Paragraph>
              <Typo.Title level={5}>
                제2조 개인정보의 처리 및 보유기간
              </Typo.Title>
              <Typo.Paragraph style={{ paddingLeft: 16 }}>
                “회사”에서 처리하는 개인정보는 수집·이용 목적으로 명시한 범위
                내에서 처리하며, 개인정보 보호법 및 관련 법령에서 정하는
                보유기간을 준용하여 이행하고 있습니다. 개인정보의 열람은
                마이페이지(링크)에서 내용을 확인할 수 있습니다.
              </Typo.Paragraph>
              <Typo.Title level={5}>
                제3조 개인정보의 제3자 제공에 관한 사항
              </Typo.Title>
              <Typo.Paragraph style={{ paddingLeft: 16 }}>
                “회사”는 원칙적으로 “이용자”의 개인정보를 수집·이용 목적으로
                명시한 범위 내에서 처리하며, “이용자”의 사전동의 없이는
                제3자에게 제공하지 않으며, 다음의 경우에는 개인정보를 제3자에게
                제공할 수 있습니다.
              </Typo.Paragraph>
              <ul className="list depth3" style={{ paddingLeft: 48 }}>
                <li style={{ marginTop: 0 }}>
                  “회사”에게 요청한 서비스의 수행하기 위한 목적인 경우
                </li>
                <li>정보주체로부터 별도의 동의를 받은 경우</li>
                <li>
                  법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여
                  불가피한 경우
                </li>
                <li>
                  정보주체 또는 그 법정대리인이 의사표시를 할 수 없는 상태에
                  있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서
                  명백히 정보 주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을
                  위하여 필요하다고 인정되는 경우
                </li>
                <li>
                  개인정보를 목적 외의 용도로 이용하거나 이를 제3자에게 제공하지
                  아니하면 다른 법률에서 정하는 소관 업무를 수행할 수 없는
                  경우로서 보호위원회의 심의·의결을 거친 경우
                </li>
                <li>
                  조약, 그 밖의 국제협정의 이행을 위하여 외국정부 또는
                  국제기구에 제공하기 위하여 필요한 경우
                </li>
                <li>범죄의 수사와 공소의 제기 및 유지를 위하여 필요한 경우</li>
                <li>법원의 재판업무 수행을 위하여 필요한 경우</li>
                <li>형(刑) 및 감호, 보호처분의 집행을 위하여 필요한 경우</li>
              </ul>
              <Typo.Paragraph style={{ paddingLeft: 16, marginTop: 32 }}>
                “회사”가 개인정보를 제3자에게 제공하는 경우 다음의 항목을
                “이용자”에게 알린 후 동의를 받겠습니다.
              </Typo.Paragraph>
              <ul className="list depth3" style={{ paddingLeft: 48 }}>
                <li>개인정보를 제공받는 자</li>
                <li>
                  개인정보의 이용 목적(제공 시에는 제공받는 자의 이용 목적을
                  말한다)
                </li>
                <li>이용 또는 제공하는 개인정보의 항목</li>
                <li>
                  개인정보의 보유 및 이용 기간(제공 시에는 제공받는 자의 보유 및
                  이용 기간을 말한다)
                </li>
                <li>
                  동의를 거부할 권리가 있다는 사실 및 동의 거부에 따른 불이익이
                  있는 경우에는 그 불이익의 내용
                </li>
              </ul>
              <Typo.Title level={5}>제4조 개인정보 처리의 위탁</Typo.Title>
              <Typo.Paragraph style={{ paddingLeft: 16 }}>
                “회사”는 원칙적으로 “이용자”의 동의없이 해당 개인정보의 처리를
                타인에게 위탁하지 않습니다. 다만, “회사”가 제3자에게 개인정보의
                처리업무를 위탁하는 경우에는 위탁 업무의 내용과 수탁자를 해당
                서비스의 홈페이지에 게시합니다. 당 홈페이지는 개인정보 처리와
                관련한 별도의 위탁사항이 없음을 알려드립니다.
              </Typo.Paragraph>
              <Typo.Title level={5}>
                제5조 정보주체와 법정대리인의 권리·의무 및 행사방법
              </Typo.Title>
              <Typo.Paragraph style={{ paddingLeft: 16 }}>
                “이용자”는 정보주체로서 다음과 같은 권리를 행사할 수 있습니다.
              </Typo.Paragraph>
              <ul className="list depth3" style={{ paddingLeft: 48 }}>
                <li>
                  정보주체(만14세 미만인 경우에는 법정대리인을 말함)는 “회사”에
                  대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의
                  권리를 행사할 수 있습니다.
                </li>
                <li>
                  제1항에 따른 권리 행사는 “회사”에 대해 「개인정보 보호법」
                  시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을
                  통하여 하실 수 있으며, “회사”는 이에 대해 지체 없이
                  조치하겠습니다.
                  <br />
                  [개인정보보호법 시행규칙 별지 제8호] 개인정보 열람정정삭제등
                  요구서
                </li>
                <li>
                  제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은
                  자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보
                  보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야
                  합니다.
                  <br />
                  [개인정보보호법 시행규칙 별지 제11호]위임장
                </li>
                <li>
                  개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조
                  제5항, 제37조 제2항에 의하여 정보주체의 권리가 제한될 수
                  있습니다.
                </li>
                <li>
                  개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가
                  수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수
                  없습니다.
                </li>
                <li>
                  정보주체가 열람, 정정·삭제, 처리정지의 요구에 대한 거절 등
                  조치에 대하여 불복이 있는 경우 이의를 제기할 수 있습니다. 이
                  경우 제 2항과 동일한 절차를 통하여 하실 수 있으며, 이에 대해
                  지체 없이 조치하겠습니다.
                </li>
                <li>
                  “회사”는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구,
                  처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한
                  대리인인지를 확인합니다.
                </li>
                <li>
                  개인정보의 열람, 정정·삭제, 처리정지 요구 접수·처리부서와
                  담당자는 아래와 같습니다.
                  <ul
                    style={{ listStyle: 'none', marginTop: 8, paddingLeft: 0 }}
                  >
                    <li>▶ 접수·처리 부서명 : CEO</li>
                    <li>담당자 : 김광일</li>
                    <li>연락처 : 02-6225-5253</li>
                    <li>이메일 :  info@30cos.com</li>
                    <li>
                      주소 : 충북 청주시 흥덕구 오송읍 오송생명 1로 194-25,
                      SB플라자 3층 A-13호
                    </li>
                  </ul>
                </li>
              </ul>
              <Typo.Title level={5}>제6조 처리하는 개인정보 항목</Typo.Title>
              <Typo.Paragraph style={{ paddingLeft: 16 }}>
                “회사”에서 처리하는 개인정보는 소관업무 및 법령에서 정한
                최소한의 개인정보만을 처리하며 자세한 사항은 각 부서에서
                운영하는 소관 홈페이지에 게재하여 정보주체가 확인할 수 있도록
                안내를 하고 있습니다. 자세한 사항은 마이페이지에 게재하여
                정보주체가 확인할 수 있도록 안내를 하고 있습니다.
              </Typo.Paragraph>
              <Typo.Title level={5}>
                제8조 개인정보의 자동수집 장치의 설치·운영 및 거부에 관한 사항
              </Typo.Title>
              <Typo.Paragraph style={{ paddingLeft: 16 }}>
                당 홈페이지는 “이용자”에게 개별적인 맞춤서비스를 제공하기 위해
                이용정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용하지
                않습니다.
              </Typo.Paragraph>
              <Typo.Title level={5}>제9조 개인정보 안전성 확보 조치</Typo.Title>
              <Typo.Paragraph style={{ paddingLeft: 16 }}>
                “회사”는 「개인정보 보호법」제29조에 따라 다음과 같이 안전성
                확보에 필요한 기술적, 관리적, 물리적 조치를 하고 있습니다.
              </Typo.Paragraph>
              <ul className="list depth3" style={{ paddingLeft: 48 }}>
                <li>
                  내부관리계획의 수립 및 시행
                  <br />
                  ·“회사”는 ‘개인정보의 안전성 확보조치 기준’에 의거하여
                  내부관리계획을 수립 및 시행합니다.
                </li>
                <li>
                  개인정보취급자 지정의 최소화 및 교육
                  <br />
                  ·개인정보취급자의 지정을 최소화하고 정기적인 교육을 시행하고
                  있습니다.
                </li>
                <li>
                  개인정보에 대한 접근 제한
                  <br />
                  ·개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의
                  부여, 변경, 말소를 통하여 개인정보에 대한 접근을 통제하고,
                  침입차단시스템과 침입방지 시스템을 이용하여 외부로부터의 무단
                  접근을 통제하고 있습니다.
                </li>
                <li>
                  접속기록의 보관 및 위변조 방지
                  <br />
                  ·개인정보처리시스템에 접속한 기록(웹 로그, 요약정보 등)을 최소
                  1년 이상 보관, 관리하고 있습니다.
                </li>
                <li>
                  개인정보의 암호화
                  <br />
                  “이용자”의 개인정보는 암호화되어 저장 및 관리되고 있습니다.
                  또한 중요한 데이터는 저장 및 전송 시 암호화하여 사용하는 등의
                  별도 보안기능을 사용하고 있습니다.
                </li>
                <li>
                  해킹 등에 대비한 기술적 대책
                  <br />
                  “회사”는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및
                  훼손을 막기 위하여 보안프로그램을 설치하고 주기적인
                  갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을
                  설치하고 기술적, 물리적으로 감시 및 차단하고 있습니다.
                </li>
                <li>
                  비인가자에 대한 출입 통제
                  <br />
                  ·개인정보를 보관하고 있는 개인정보시스템의 물리적 보관 장소를
                  별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.
                </li>
              </ul>
              <Typo.Title level={5}>제10조 권익침해 구제방법</Typo.Title>
              <Typo.Paragraph style={{ paddingLeft: 16 }}>
                정보주체는 개인정보침해로 인한 구제를 받기 위하여
                개인정보분쟁조정위원회, 개인정보침해신고센터 등에 분쟁해결이나
                상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고,
                상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
              </Typo.Paragraph>
              <ul className="list depth3" style={{ paddingLeft: 48 }}>
                <li>
                  1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972
                  (www.kopico.go.kr)
                </li>
                <li>
                  개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)
                </li>
                <li>대검찰청 사이버수사과 : (국번없이) 1301 (www.spo.go.kr)</li>
                <li>
                  경찰청 사이버수사국 : (국번없이) 182
                  (ecrm.cyber.go.kr/minwon/main)
                </li>
              </ul>
              <Typo.Paragraph style={{ paddingLeft: 16 }}>
                개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의
                정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에
                대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는
                이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을
                청구할 수 있습니다.
              </Typo.Paragraph>
              <Typo.Paragraph style={{ paddingLeft: 16 }}>
                ※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회
                (www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.
              </Typo.Paragraph>
              <Typo.Title level={5}>제11조 개인정보의 열람청구</Typo.Title>
              <Typo.Paragraph style={{ paddingLeft: 16 }}>
                정보주체는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를
                아래의 부서에 할 수 있습니다. “회사”에서는 정보주체의 개인정보
                열람청구가 신속하게 처리되도록 노력하겠습니다.
              </Typo.Paragraph>
              <ul style={{ listStyle: 'none', marginTop: 8 }}>
                <li>▶ 접수·처리 부서명 : CEO</li>
                <li>담당자 : 김광일</li>
                <li>연락처 : 02-6225-5253</li>
                <li>이메일 :  info@30cos.com</li>
                <li>
                  주소 : 충북 청주시 흥덕구 오송읍 오송생명 1로 194-25, SB플라자
                  3층 A-13호
                </li>
                <li>▶ 열람경로 : 메인페이지 → 마이페이지 → 회원정보</li>
              </ul>
              <Typo.Title level={5}>
                제12조 개인정보보호책임자, 개인정보보호 및 고충처리 담당자
              </Typo.Title>
              <table>
                <thead>
                  <tr>
                    <th>구분</th>
                    <th>개인정보보호 책임자</th>
                    <th>개인정보보호 분야별 책임자</th>
                    <th>개인정보보호 및 고충처리 담당자</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>부서명</td>
                    <td colSpan={3}>CEO</td>
                  </tr>
                  <tr>
                    <td>김광일</td>
                    <td colSpan={3}>김광일</td>
                  </tr>
                  <tr>
                    <td>연락처</td>
                    <td colSpan={3}>02-6225-5253</td>
                  </tr>
                </tbody>
              </table>
              <Typo.Title level={5}>제13조 개인정보 처리방침 이력</Typo.Title>
              <Typo.Paragraph style={{ paddingLeft: 16 }}>
                이 개인정보처리방침은 2021년 3월 31일부터 적용됩니다.
              </Typo.Paragraph>
              <Typo.Paragraph style={{ paddingLeft: 16 }}>
                이전의 개인정보 처리방침은 아래에서 확인할 수 있습니다.
              </Typo.Paragraph>
            </TermsCard>
          </Collapse.Panel>
          <Collapse.Panel
            key="logo"
            header={
              <Form.Item name="logo" valuePropName="checked">
                <Checkbox
                  onChange={(e) => onCheckLogo(e.target.checked)}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Typography.Text
                    color="slate"
                    type="primary"
                    inline
                    medium
                    onClick={(e) => e.stopPropagation()}
                  >
                    브랜드명, 회사명, 로고, 슬로건의 사용 및 게시에 관한 약정
                    동의
                  </Typography.Text>
                  <Typography.Text
                    type="primary"
                    color="slate"
                    inline
                    gutter={{ left: 4 }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    (선택)
                  </Typography.Text>
                </Checkbox>
              </Form.Item>
            }
          >
            <TermsCard size="small">
              <ul className="list depth1">
                <li>
                  <Typo.Title level={5}>1.목적</Typo.Title>
                  <Typo.Paragraph style={{ paddingLeft: 16 }}>
                    위 약관은 브랜드명, 회사명, 로고, 슬로건의 요청과 사용 및
                    게시를 동의하는 데에 있습니다. 사용범위를 아래와 같이
                    한정합니다. 사용범위가 변경되는 경우 해당 내용을 30일 이내에
                    “플랫폼”의 초기화면 또는 전자우편 주소로 공지합니다.
                  </Typo.Paragraph>
                </li>
                <li>
                  <Typo.Title level={5}>2.사용범위</Typo.Title>
                  <ul className="list depth2">
                    <li>30COS 관련 홈페이지 및 영업자료</li>
                    <li>30COS의 인증 에이전트 등</li>
                  </ul>
                </li>
                <li>
                  <Typo.Title level={5}>3.그 밖의 참고 사항</Typo.Title>
                  <ul className="list depth2">
                    <li>
                      30COS는 회원에게 BI나 CI 등을 파일로 요청할 수 있습니다.
                    </li>
                    <li>
                      동의를 철회하고자 할 때에는 철회 신청서를 받아 작성하여
                      양사 서면 후에 철회할 수 있습니다.
                    </li>
                    <li>
                      30COS는 회원사의 로고 크기를 변경하여 이용 가능하며,
                      회원사의 허락 없이 이 외의 가공, 모조 등 혼동 가능성이
                      있는 형태로 사용하지 않습니다.
                    </li>
                  </ul>
                </li>
              </ul>
            </TermsCard>
          </Collapse.Panel>
        </Collapse>
        <FooterBox>
          <Button>
            <Link to={path.login}>취소</Link>
          </Button>
          <Button type="primary" htmlType="submit">
            다음
          </Button>
        </FooterBox>
      </Form>
    </RegisterTermsBlock>
  );
};

export default RegisterTerms;
