import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Modal } from 'antd';

import {
  registerCompany,
  setChangePasswordModalVisible,
  updateCompany,
} from 'modules/auth';
import BrandRegisterForm from 'components/auth/brand/BrandRegisterForm';
import { getCompany } from 'modules/company';
import useUpdateEffect from 'hook/useUpdateEffect';
import { useBrandCompanies } from 'service/brand/company';

const BrandRegisterFormContainer = ({ tab, onChangeTab }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { user, company, submitLoading } = useSelector(
    ({ auth, company, loading }) => ({
      user: auth.user,
      company: company.company,
      submitLoading:
        loading['auth/REGISTER_COMPANY'] || loading['auth/UPDATE_COMPANY'],
    }),
  );

  const updateMode = user !== null && company !== null;
  const { data: brands = [] } = useBrandCompanies({
    isExcludeUserInserted: true,
  });
  const handleSubmit = (formData) => {
    const brand = brands.find(
      ({ companyNameKo }) => companyNameKo === formData.companyNameKo,
    );
    const companyForm = {
      isManufacturer: false,
      companyNameKo:
        formData.companyNameKo === 'companyNameKoDirect'
          ? formData.companyNameKoDirect
          : formData.companyNameKo,
      companyNameEn: formData.companyNameEn,
      ...(brand?.brandCompanyId && {
        brandCompanyId: brand.brandCompanyId,
      }),
      bizNumber: formData.bizNumber,
      addressKo: formData.addressKo,
      addressEn: formData.addressEn,
      ceoNameKo: formData.ceoNameKo,
      ceoNameEn: formData.ceoNameEn,
      tel: formData.tel,
      fax: formData.fax,
      ...(formData.homepageUrl && { homepageUrl: formData.homepageUrl }),
      ...(!updateMode && {
        bizLicenseFile: formData.bizLicenseFile,
        bizLicenseEnFile: formData.bizLicenseEnFile,
      }),
      companyType: 'BRAND',
    };
    const userForm = {
      isSuperUser: true,
      username: formData.bizNumber.replace(/-/g, ''),
      password: formData.password,
      name: formData.name,
      deptName: formData.deptName,
      positionName: formData.positionName,
      email: formData.email,
      mobile: formData.mobile,
      userType: 'BRAND',
    };
    if (!updateMode) {
      dispatch(registerCompany({ ...companyForm, ...userForm }));
    } else {
      Modal.confirm({
        title: '정말로 수정하시겠습니까?',
        icon: null,
        onOk: () => {
          dispatch(
            updateCompany({
              company: {
                ...companyForm,
                companyId: company.companyId,
              },
              user: { ...userForm, userId: user.userId },
              companyId: company.companyId,
            }),
          );
        },
      });
    }
  };
  const handleClickChangePassword = () => {
    dispatch(setChangePasswordModalVisible(true));
  };

  useEffect(() => {
    if (updateMode) {
      dispatch(getCompany(company.companyId));
      form.setFieldsValue({
        ...company,
        ...user,
        ...(company.bizLicenseUrl && {
          bizLicenseFile: { name: 'Uploaded File', url: company.bizLicenseUrl },
        }),
        ...(company.bizLicenseEnUrl && {
          bizLicenseEnFile: {
            name: 'Uploaded File',
            url: company.bizLicenseEnUrl,
          },
        }),
      });
    }
  }, []);

  useUpdateEffect(() => {
    if (company) {
      form.setFieldsValue({
        ...(company.bizLicenseUrl && {
          bizLicenseFile: { name: 'Uploaded File', url: company.bizLicenseUrl },
        }),
        ...(company.bizLicenseEnUrl && {
          bizLicenseEnFile: {
            name: 'Uploaded File',
            url: company.bizLicenseEnUrl,
          },
        }),
      });
    }
  }, [company]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'development' && !updateMode) {
      form.setFieldsValue({
        companyNameKo: '둘리나라',
        companyNameEn: 'DooleyWorld',
        addressKo: '서울시 종로구',
        addressEn: 'Jongro-gu, Seoul',
        ceoNameKo: '둘리',
        ceoNameEn: 'Dooley',
        tel: '0211112222',
        fax: '0211112222',
        homepageUrl: 'http://www.dooley.com',
        bizNumber: '111-11-11120',
        password: '1111',
        passwordConfirm: '1111',
        name: '장동혁',
        deptName: '고길동괴롭힘팀',
        positionName: '한량',
        email: 'flynn@fromom.net',
        mobile: '01012345678',
        isEmailVerified: false,
        isPhoneVerified: false,
        isSmsAgreed: true,
        isEmailAgreed: true,
      });
    }
  }, []);

  return (
    <BrandRegisterForm
      tab={tab}
      onChangeTab={onChangeTab}
      updateMode={updateMode}
      form={form}
      submitLoading={submitLoading}
      onClickChangePassword={handleClickChangePassword}
      onSubmit={handleSubmit}
      brands={brands}
      bizLicenseEnUrl={company?.bizLicenseEnUrl}
    />
  );
};

export default BrandRegisterFormContainer;
