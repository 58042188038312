import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Button, Form } from 'antd';

import path from 'lib/path';
import { scrollToFirstErrorOption } from 'lib/consts';
import FooterBox from 'components/FooterBox';
import SuperUserForm from 'components/auth/SuperUserForm';
import MaterialForm from './MaterialForm';
import { useMaterialRegister } from 'service/material/auth';
import { useEffect } from 'react';

const MaterialRegisterFormBlock = styled.div<{ updateMode: boolean }>`
  max-width: 520px;
  margin: 0 auto;
  ${(props) =>
    !props.updateMode &&
    css`
      padding: 40px 0;
    `}
`;

const MaterialRegisterForm = ({
  onChangeTab,
}: {
  onChangeTab?: (tab: 'company' | 'user') => void;
}) => {
  const {
    form,
    updateMode,
    materials,
    tab,
    submitLoading,
    bizLicenseEnUrl,
    handleClickChangePassword,
    handleClickBack,
    handleClickNext,
    handleSubmit,
  } = useMaterialRegister();

  useEffect(() => {
    onChangeTab && onChangeTab(tab);
  }, [tab]);
  return (
    <MaterialRegisterFormBlock updateMode={updateMode}>
      <Form
        form={form}
        colon={false}
        layout="vertical"
        scrollToFirstError={scrollToFirstErrorOption}
        onFinish={handleSubmit}
      >
        <MaterialForm
          visible={updateMode || tab === 'company'}
          form={form}
          updateMode={updateMode}
          materials={materials}
          bizLicenseEnUrl={bizLicenseEnUrl}
        />
        {(updateMode || tab === 'user') && (
          <SuperUserForm
            form={form}
            updateMode={updateMode}
            onClickChangePassword={handleClickChangePassword}
            onClickBack={handleClickBack}
          />
        )}
        <FooterBox>
          {!updateMode && tab === 'company' && (
            <Button>
              <Link to={path.login}>취소</Link>
            </Button>
          )}
          {!updateMode && tab === 'user' && (
            <Button onClick={handleClickBack}>뒤로</Button>
          )}
          {!updateMode && tab === 'company' && (
            <Button type="primary" onClick={handleClickNext}>
              다음
            </Button>
          )}
          {(updateMode || tab === 'user') && (
            <Button type="primary" loading={submitLoading} htmlType="submit">
              {!updateMode ? '회원가입' : '회원정보 수정'}
            </Button>
          )}
        </FooterBox>
      </Form>
    </MaterialRegisterFormBlock>
  );
};

export default MaterialRegisterForm;
