import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Form, message, Modal, Spin } from 'antd';
import styled from 'styled-components';
import produce from 'immer';

import {
  CertificationType,
  ICertificationItemForm,
  ICertificationItemGet,
} from 'types/material/certification';
import { scrollToFirstErrorOption } from 'lib/consts';
import { useCertificationItems } from 'service/material/certification';
import FooterBox from 'components/FooterBox';
import { CertificationGroup } from './CertificationGroup';
import { useUpdateMode } from 'hook/material';
import { Typography } from 'components/system';
import { useRawMaterial } from 'service/material/rawMaterial';

const RawMaterialCertificationBlock = styled.div``;

interface IDeFaultCertificationItem {
  title: string;
  autoPublished?: boolean;
  hasRestrictMaterial?: boolean;
}

const defaultCertificationItems: {
  [key in CertificationType]: IDeFaultCertificationItem[];
} = {
  GENERAL: [
    { title: 'Non-Animal Test', autoPublished: true },
    { title: 'BSE / TSE Free', autoPublished: true },
    {
      title: 'CMR Declaration',
      autoPublished: true,
      hasRestrictMaterial: false,
    },
    {
      title: 'Nano material Declaration',
      autoPublished: true,
      hasRestrictMaterial: false,
    },
    { title: 'CITES Statement' },
    { title: 'RSPO' },
    { title: 'REACH Registration' },
    { title: 'Halal Attestation' },
  ],
  VEGAN: [
    { title: 'Vegan Statement (자사)' },
    { title: 'The Vegan Society' },
    { title: 'EVE Vegan' },
    { title: 'Vegan Action' },
    { title: '한국 비건 인증원' },
  ],
  ORGANIC: [
    { title: 'Ecocert' },
    { title: 'USDA' },
    { title: 'ISO16128' },
    { title: 'COSMETIQUE' },
    { title: 'BDIH' },
    { title: 'Canada Organic' },
    { title: 'Biologique' },
    { title: 'COSMOS' },
  ],
  NATURAL: [
    { title: 'Natural Statement (자사)' },
    { title: 'COSMOS Natural' },
    { title: 'Natural Seal Personal Care' },
    { title: 'IBD certification' },
    { title: 'BioGro' },
    { title: 'CCPB' },
    { title: 'SGS INSTITUT FRESENIUS' },
  ],
  ETC: [],
};

const getDefaultCertificationItems = (certificationType: CertificationType) =>
  defaultCertificationItems[certificationType].map(
    ({ title, autoPublished, hasRestrictMaterial }) => ({
      certificationType,
      certificationTitle: title,
      isLaterUpload: false,
      isCustom: false,
      enable: false,
      ...(autoPublished && { autoPublished }),
      ...(typeof hasRestrictMaterial !== 'undefined' && {
        hasRestrictMaterial,
      }),
    }),
  );

const initializeCertificationItems = (
  isCustom: boolean,
  item: ICertificationItemGet,
  setter: React.Dispatch<React.SetStateAction<ICertificationItemForm[]>>,
) => {
  const {
    materialCertificationId,
    certificationType,
    certificationTitle,
    expiryDate,
    isLaterUpload,
    filename,
    attachUrl,
    hasRestrictMaterial,
  } = item;
  if (!isCustom) {
    setter((draft) =>
      produce(draft, (draft) => {
        const targetItem = draft.find(
          (item) => item.certificationTitle === certificationTitle,
        )!;
        targetItem.materialCertificationId = materialCertificationId;
        targetItem.isCustom = false;
        targetItem.enable = true;
        targetItem.isLaterUpload = isLaterUpload;
        targetItem.isOriginLaterUpload = isLaterUpload;
        if (hasRestrictMaterial !== null) {
          targetItem.hasRestrictMaterial = hasRestrictMaterial;
        }
        if (expiryDate) {
          targetItem.expiryDate = expiryDate;
        }
        if (filename && attachUrl) {
          targetItem.uploadFile = { name: filename, attachUrl };
        }
      }),
    );
  } else {
    setter((draft) =>
      draft.concat([
        {
          materialCertificationId,
          isCustom: true,
          enable: true,
          certificationType,
          certificationTitle,
          isLaterUpload,
          isOriginLaterUpload: isLaterUpload,
          ...(expiryDate && { expiryDate }),
          ...(filename &&
            attachUrl && {
              uploadFile: { name: filename, attachUrl },
            }),
        },
      ]),
    );
  }
};

const RawMaterialCertification = () => {
  const params = useParams<{ materialId: string }>();
  const materialId = Number(params.materialId);
  const { rawMaterial } = useRawMaterial(materialId);
  const isRawMaterialLaterUpload = rawMaterial
    ? rawMaterial.status === 'RM_REG' && rawMaterial.isLaterUpload
    : false;
  const [form] = Form.useForm();
  const [generalCertificationItems, setGeneralCertificationItems] = useState<
    ICertificationItemForm[]
  >(getDefaultCertificationItems('GENERAL'));

  const [veganCertificationItems, setVeganCertificationItems] = useState<
    ICertificationItemForm[]
  >(getDefaultCertificationItems('VEGAN'));

  const [organicCertificationItems, setOrganicCertificationItems] = useState<
    ICertificationItemForm[]
  >(getDefaultCertificationItems('ORGANIC'));

  const [naturalCertificationItems, setNaturalCertificationItems] = useState<
    ICertificationItemForm[]
  >(getDefaultCertificationItems('NATURAL'));

  const [etcCertificationItems, setEtcCertificationItems] = useState<
    ICertificationItemForm[]
  >(getDefaultCertificationItems('ETC'));
  const updateMode = useUpdateMode();
  const {
    certificationItems,
    getLoading,
    addCertificationItems,
    addLoading,
    updateCertificationItems,
    updateLoading,
  } = useCertificationItems(materialId, updateMode);

  const handleFinishFailed = ({ errorFields }: any) => {
    if (
      errorFields.some(
        ({ name }: any) => Array.isArray(name) && name.includes('uploadFile'),
      )
    ) {
      Modal.info({
        title: (
          <Typography.Title>
            체크하신 항목의 서류를 업로드해 주세요.
          </Typography.Title>
        ),
        closable: true,
        icon: null,
        width: 600,
        content: (
          <Typography.Text>
            서류가 업로드 되지 않았습니다. 추후 업로드를 원하시면 항목 우측
            ‘추후 서류 업로드’ 체크 후 등록하실 수 있습니다.
            <br />
            (단, 원료 등록 완료일로부터 14일 이내 업로드 안될 시 해당 항목이
            ‘보유 서류 및 인증 항목’에서 사라집니다.)
          </Typography.Text>
        ),
        okButtonProps: {
          style: {
            width: 160,
          },
        },
      });
    }
  };

  const handleSubmit = () => {
    const enableItems = [
      ...generalCertificationItems,
      ...veganCertificationItems,
      ...organicCertificationItems,
      ...naturalCertificationItems,
      ...etcCertificationItems,
    ].filter(({ enable }) => enable);
    if (enableItems.length === 0) {
      return message.warn('1개 이상의 항목을 입력해 주세요.');
    }
    if (!updateMode) {
      addCertificationItems({ materialId, certificationItems: enableItems });
    } else {
      updateCertificationItems({ materialId, certificationItems: enableItems });
    }
  };

  useEffect(() => {
    if (certificationItems.length) {
      certificationItems.forEach((item) => {
        const { certificationType, certificationTitle } = item;
        const isCustom = defaultCertificationItems[certificationType].every(
          ({ title }) => title !== certificationTitle,
        );
        switch (certificationType) {
          case 'GENERAL': {
            initializeCertificationItems(
              isCustom,
              item,
              setGeneralCertificationItems,
            );
            break;
          }
          case 'VEGAN': {
            initializeCertificationItems(
              isCustom,
              item,
              setVeganCertificationItems,
            );
            break;
          }
          case 'ORGANIC': {
            initializeCertificationItems(
              isCustom,
              item,
              setOrganicCertificationItems,
            );
            break;
          }
          case 'NATURAL': {
            initializeCertificationItems(
              isCustom,
              item,
              setNaturalCertificationItems,
            );
            break;
          }
          case 'ETC': {
            initializeCertificationItems(
              isCustom,
              item,
              setEtcCertificationItems,
            );
            break;
          }
        }
      });
    }
  }, [certificationItems]);

  return (
    <RawMaterialCertificationBlock>
      <Spin spinning={getLoading}>
        <Form
          form={form}
          onFinishFailed={handleFinishFailed}
          onFinish={handleSubmit}
          scrollToFirstError={scrollToFirstErrorOption}
        >
          <CertificationGroup
            form={form}
            title="General"
            certificationType="GENERAL"
            certificationItems={generalCertificationItems}
            onChangeCertificationItems={setGeneralCertificationItems}
            isRawMaterialLaterUpload={isRawMaterialLaterUpload}
          />
          <CertificationGroup
            form={form}
            title="Vegan"
            certificationType="VEGAN"
            addable
            certificationItems={veganCertificationItems}
            onChangeCertificationItems={setVeganCertificationItems}
            isRawMaterialLaterUpload={isRawMaterialLaterUpload}
          />
          <CertificationGroup
            form={form}
            title="Organic"
            certificationType="ORGANIC"
            addable
            certificationItems={organicCertificationItems}
            onChangeCertificationItems={setOrganicCertificationItems}
            isRawMaterialLaterUpload={isRawMaterialLaterUpload}
          />
          <CertificationGroup
            form={form}
            title="Natural"
            certificationType="NATURAL"
            addable
            certificationItems={naturalCertificationItems}
            onChangeCertificationItems={setNaturalCertificationItems}
            isRawMaterialLaterUpload={isRawMaterialLaterUpload}
          />
          <CertificationGroup
            form={form}
            title="기타"
            certificationType="ETC"
            addable
            certificationItems={etcCertificationItems}
            onChangeCertificationItems={setEtcCertificationItems}
            isRawMaterialLaterUpload={isRawMaterialLaterUpload}
          />
          <FooterBox>
            <Button
              htmlType="submit"
              type="primary"
              loading={addLoading || updateLoading}
            >
              {!updateMode ? '저장' : '수정'}
            </Button>
          </FooterBox>
        </Form>
      </Spin>
    </RawMaterialCertificationBlock>
  );
};

export default RawMaterialCertification;
