import { Typography } from 'antd';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import ProductArtworkContainer from 'containers/product/ProductArtworkContainer';
import ProductPageTemplate from 'templates/ProductPageTemplate';

const notices = [
  'Artwork : 제품에 최종 인쇄할 디자인 도면 (PDF)',
  '예시) 캡, 튜브, 개봉방지스티커, 라벨, 띠지, 삽지 등 제품에 인쇄될 모든 디자인 데이터',
  <>
    반드시{' '}
    <Typography.Text type="danger" style={{ padding: 0 }} strong>
      영문, PDF 파일
    </Typography.Text>
    로 업로드 바랍니다. 최대 10개의 파일까지 업로드가 가능합니다.
  </>,
  <MistakeNoticeItem />,
];

const ProductArtworkPage = () => {
  return (
    <ProductPageTemplate
      subtitle="Artwork"
      notices={notices}
      exampleImg="ArtWork.jpg"
    >
      <ProductArtworkContainer />
    </ProductPageTemplate>
  );
};

export default ProductArtworkPage;
