import { message, Modal } from 'antd';
import { useCallback, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import ProductCountry from 'components/product/ProductCountry';
import { addProductCountry, updateProductCountry } from 'modules/product';
import { messages } from 'lib/consts';
import UpdateLog from 'components/product/UpdateLog';
import { useCountries } from 'service/country';
import { Typography } from 'components/system';

const ProductCountryContainer = () => {
  const dispatch = useDispatch();
  const countries = useCountries();
  const { currentProduct, fetchLoading } = useSelector(
    ({ product, loading }) => ({
      currentProduct: product.currentProduct,
      fetchLoading:
        loading['product/ADD_PRODUCT_COUNTRY'] |
        loading['product/UPDATE_PRODUCT_COUNTRY'],
    }),
    shallowEqual,
  );
  const { productId, productCountries } = currentProduct;
  const updateMode = productCountries !== null;
  const savedCountryIds = !updateMode
    ? []
    : productCountries.map(({ countryId }) => countryId);
  const [selectedCountryIds, setSelectedCountryIds] = useState(savedCountryIds);
  const [searchKeyword, setSearchKeyword] = useState('');
  const handleSearch = useMemo(() => _.debounce(setSearchKeyword, 300), []);
  const approvedCountries = useMemo(
    () =>
      countries.map((country) => ({
        ...country,
        isOriginal: savedCountryIds.includes(country.countryId),
      })),
    [countries, savedCountryIds],
  );

  const deselectedCountries = useMemo(
    () =>
      approvedCountries.filter(
        ({ countryId, countryNameKo }) =>
          !selectedCountryIds.includes(countryId) &&
          countryNameKo.includes(
            searchKeyword.replace(/[ㄱ-ㅎㅏ-ㅣ]/g, '').toUpperCase(),
          ),
      ),
    [approvedCountries, selectedCountryIds, searchKeyword],
  );
  const selectedCountries = useMemo(
    () =>
      approvedCountries
        .filter(({ countryId }) => selectedCountryIds.includes(countryId))
        .sort((a, b) => (a.isOriginal ? -1 : 1)),
    [approvedCountries, selectedCountryIds],
  );
  const isSearching = useMemo(
    () =>
      countries.length - selectedCountries.length !==
      deselectedCountries.length,
    [countries, selectedCountries, deselectedCountries],
  );
  const selectCountry = useCallback((targetIds) => {
    setSelectedCountryIds((draft) => draft.concat(targetIds));
  }, []);
  const deselectCountry = useCallback((targetId) => {
    setSelectedCountryIds((draft) =>
      draft.filter((countryId) => countryId !== targetId),
    );
  }, []);
  const onSubmit = () => {
    if (selectedCountryIds.length === 0) {
      return message.warning('한 개 이상의 국가를 선택해 주세요');
    }
    if (!updateMode) {
      dispatch(
        addProductCountry({
          productId,
          countryIds: selectedCountryIds,
        }),
      );
    } else if (currentProduct.status === 'PRD-RDY') {
      // HINT: 수정모드 + 등록 중인 제품 => 국가 추가/삭제 가능
      if (selectedCountryIds.length === 0)
        return message.warn('한 개 이상의 국가를 선택해 주세요');
      const newCountryIds = selectedCountryIds.filter(
        (countryId) => !savedCountryIds.includes(countryId),
      );
      const deletedCountryIds = savedCountryIds.filter(
        (countryId) => !selectedCountryIds.includes(countryId),
      );
      if (!newCountryIds.length && !deletedCountryIds.length) {
        message.warning(messages.NO_NEED_TO_UPDATE);
        return;
      }

      // HINT : 국가의 삭제만 일어나는 경우(newCountryIds가 없고 deletedCountryIds만 있을 때)는 모달이 띄워지지 않음.
      if (!newCountryIds.length && deletedCountryIds.length) {
        dispatch(
          updateProductCountry(
            {
              productId,
              newCountryIds,
              deletedProductCountryIds: currentProduct.productCountries
                .filter(({ countryId }) =>
                  deletedCountryIds.includes(countryId),
                )
                .map(({ productCountryId }) => productCountryId),
            },
            false,
          ),
        );
        return;
      }
      Modal.confirm({
        icon: null,
        content: (
          <Typography.Text style={{ display: 'block', textAlign: 'center' }}>
            국가를 추가하는 경우, 추가로 서류 등록이 필요
            <br />할 수 있습니다. 추가하시겠습니까?
          </Typography.Text>
        ),
        onOk: () => {
          dispatch(
            updateProductCountry(
              {
                productId,
                newCountryIds,
                deletedProductCountryIds: currentProduct.productCountries
                  .filter(({ countryId }) =>
                    deletedCountryIds.includes(countryId),
                  )
                  .map(({ productCountryId }) => productCountryId),
              },
              false,
            ),
          );
        },
      });
    } else {
      // HINT: 수정모드 + 등록완료 제품 : 국가 추가만 가능, 경고 팝업 필요
      const newCountryIds = selectedCountryIds.filter(
        (countryId) => !savedCountryIds.includes(countryId),
      );
      if (!newCountryIds.length) {
        message.warning(messages.NO_NEED_TO_UPDATE);
        return;
      }
      Modal.confirm({
        icon: null,
        content: (
          <>
            <Typography.Text style={{ display: 'block', textAlign: 'center' }}>
              국가를 추가하는 경우, 추가로 서류 등록이 필요
              <br />할 수 있습니다. 추가하시겠습니까?
            </Typography.Text>
            <Typography.Text
              style={{ display: 'block', textAlign: 'center' }}
              type="tertiary"
              color
              gutter={{ top: 4 }}
            >
              (추가 서류 등록이 필요한 경우 해당 제품이
              <br /> ‘등록 중 제품 관리’로 이동합니다.)
            </Typography.Text>
          </>
        ),
        onOk: () => {
          dispatch(
            updateProductCountry(
              {
                productId,
                newCountryIds: newCountryIds,
                deletedProductCountryIds: [],
              },
              true,
            ),
          );
        },
      });
    }
  };

  const resetCountries = useCallback(() => {
    setSelectedCountryIds(savedCountryIds);
  }, []);

  return (
    <>
      {updateMode && <UpdateLog productId={productId} documentCode="country" />}
      <ProductCountry
        updateMode={updateMode}
        registered={currentProduct.status === 'PRD-REG'}
        deselectedCountries={deselectedCountries}
        selectedCountries={selectedCountries}
        selectedCountryIds={selectedCountryIds}
        isSearching={isSearching}
        fetchLoading={fetchLoading}
        onSelectCountry={selectCountry}
        onDeselectCountry={deselectCountry}
        onSubmit={onSubmit}
        onReset={resetCountries}
        onSearch={handleSearch}
      />
    </>
  );
};

export default ProductCountryContainer;
