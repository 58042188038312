import { ChangeEvent, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import palette from 'lib/styles/palette';

const SwitchBlock = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const SwitchButtonBlock = styled.div<{ $active: boolean }>`
  position: relative;
  width: 24px;
  height: 8px;
  margin-right: 8px;
  border-radius: 4px;
  background-color: ${palette.inactive};
  transition: background-color 0.25s ease;

  ${({ $active }) =>
    $active &&
    css`
      background-color: ${palette.primary};
    `}

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: -4px;
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1.2px solid ${palette.inactive};
    background-color: #fff;
    transition-property: border-color, transform;
    transition-timing-function: ease;
    transition-duration: 0.25s;

    ${({ $active }) =>
      $active &&
      css`
        border-color: ${palette.primary};
        transform: translateX(10px);
      `}
  }
`;

interface ISwitchProps {
  active?: boolean;
  style?: React.CSSProperties;
  children?: ReactNode;
  onChange?: (e: ChangeEvent<any>) => void;
  disabled?: boolean;
}

const Switch = ({
  active = true,
  style,
  children,
  onChange,
  disabled = false,
}: ISwitchProps) => {
  const handleClick = () => {
    if (onChange) {
      onChange({
        target: { active: !active },
      } as ChangeEvent<any>);
    }
  };

  return (
    <SwitchBlock onClick={() => !disabled && handleClick()} style={style}>
      <SwitchButtonBlock $active={active} />
      {children}
    </SwitchBlock>
  );
};

export default Switch;
