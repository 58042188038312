import styled from 'styled-components';
import {
  Form,
  Row,
  Col,
  Checkbox,
  Input,
  Select,
  Button,
  InputNumber,
  Radio,
  RadioChangeEvent,
  Spin,
  Modal,
} from 'antd';

import UpdateLog from 'components/product/UpdateLog';
import { Tip, Typography } from 'components/system';
import { exceptKoreanRule, numbersRule, requireRule } from 'lib/validate';
import FooterBox from 'components/FooterBox';
import { useProductBasic } from 'service/manufacturer/productBasic';
import { netWeightUnitOptions } from 'lib/selectOption';
import { parseWeightUnit } from 'lib/form';
import palette from 'lib/styles/palette';
import { scrollToFirstErrorOption } from 'lib/consts';
import {
  useGetProductSample,
  useUsableProductSamples,
} from 'service/manufacturer/productSample';
import { ECountryTarget } from 'types/manufacturer/productSample';
import { IProductBasicForm } from 'types/manufacturer/product';
import { useGetProduct } from 'service/manufacturer/product';
import history from 'lib/history';
import { EDocumentCode } from 'types/product';

const ProductSampleUseRadioBlock = styled.div`
  padding: 8px 24px;
  background-color: ${palette.gray};
  border-radius: 8px;
`;

const ProductSampleUseRadio = ({
  value,
  onChange,
}: {
  value?: boolean;
  onChange?: (e: RadioChangeEvent) => void;
}) => {
  return (
    <ProductSampleUseRadioBlock>
      <Radio.Group value={value} onChange={onChange}>
        <Row justify="space-around" style={{ flexBasis: '100%' }}>
          <Col style={{ textAlign: 'center' }}>
            <Radio value={false}>샘플 없이 등록</Radio>
          </Col>
          <Col style={{ textAlign: 'center' }}>
            <Radio value={true}>확정 완료 샘플 연결</Radio>
          </Col>
        </Row>
      </Radio.Group>
    </ProductSampleUseRadioBlock>
  );
};

const ProductBasicBlock = styled.div`
  max-width: 520px;
  margin: 0 auto;
`;

const ProductBasic = ({ productId }: { productId?: number }) => {
  const updateMode = typeof productId !== 'undefined';
  const {
    form,
    product,
    getProductLoading,
    submitProduct,
    factories,
    fetchLoading,
  } = useProductBasic(productId);
  const {
    productSamples,
    isLoading: getUsableProductSamplesLoading,
  } = useUsableProductSamples();
  const {
    getProductSample,
    isLoading: getProductSampleLoading,
  } = useGetProductSample();
  const {
    getProduct,
    isLoading: getProductForDuplicateLoading,
  } = useGetProduct();
  const handleSubmitProduct = async (productForm: IProductBasicForm) => {
    const {
      data: { result: duplicatedProduct },
    } = await getProduct({
      labNo: productForm.labNo,
      netWeight: productForm.netWeight,
      netWeightUnit:
        productForm.netWeightUnit !== 'direct'
          ? productForm.netWeightUnit
          : productForm.netWeightUnit,
    });
    if (
      duplicatedProduct &&
      (!product || product.productId !== duplicatedProduct.productId)
    ) {
      if (!updateMode && duplicatedProduct.manufacturerStatus === 'PRD-RDY') {
        Modal.confirm({
          icon: null,
          width: 400,
          closable: true,
          content: (
            <Typography.Text type="secondary" align="center">
              이미 등록 중인 동일한 샘플 랩넘버와 용량이 있습니다.
              <br />
              해당 제품을 이어서 등록하시겠습니까?
            </Typography.Text>
          ),
          okText: '네',
          cancelText: '아니오',
          onOk: () => {
            history.replace(
              `/manufacturer/product/${duplicatedProduct.productId}`,
            );
          },
        });
      } else {
        Modal.info({
          icon: null,
          width: 400,
          closable: true,
          content: (
            <Typography.Text type="secondary" align="center">
              이미 사용된 샘플 랩넘버와 용량이 동일하여
              <br />
              {!updateMode ? '등록' : '수정'}할 수 없으니 확인 후 다시 시도해
              주세요.
            </Typography.Text>
          ),
        });
      }
      return;
    }
    if (
      updateMode &&
      productForm.isUsedProductSample !== product!.isUsedProductSample
    ) {
      Modal.confirm({
        icon: null,
        width: 400,
        content: (
          <Typography.Text type="secondary">
            {productForm.isUsedProductSample ? (
              <>
                샘플 연결하시면 아래 입력 항목이 해당하는 샘플의 내용
                <br />
                으로 입력됩니다. ‘확정 완료 샘플 연결’으로 수정하시겠습니까?
              </>
            ) : (
              <>
                샘플 연결을 해제하시면 아래에 자동 입력된 항목의
                <br /> 내용이 삭제됩니다. ‘샘플 없이 등록’ 으로
                수정하시겠습니까?
              </>
            )}
            <br />
            <br />
            <Typography.Text medium>
              Product Formula
              <br />
              Product Formula Breakdown
              {productForm.isForChina && (
                <>
                  <br />
                  Manufacturing Process (China)
                </>
              )}
            </Typography.Text>
          </Typography.Text>
        ),
        okText: '네',
        cancelText: '아니오',
        onOk: () => {
          submitProduct(productForm);
        },
      });
      return;
    }
    if (
      updateMode &&
      product!.isUsedProductSample &&
      productForm.productSampleId !== product!.productSampleId
    ) {
      Modal.confirm({
        icon: null,
        width: 400,
        content: (
          <Typography.Text type="secondary">
            선택한 샘플을 수정하시면 아래에 자동 입력된 항목의 내용이
            변경됩니다. 샘플을 수정하시겠습니까?
            <br />
            <br />
            <Typography.Text medium>
              Product Formula
              <br />
              Product Formula Breakdown
              {productForm.isForChina && (
                <>
                  <br />
                  Manufacturing Process (China)
                </>
              )}
            </Typography.Text>
          </Typography.Text>
        ),
        okText: '네',
        cancelText: '아니오',
        onOk: () => {
          submitProduct(productForm);
        },
      });
      return;
    }
    submitProduct(productForm);
  };
  return (
    <>
      {updateMode && (
        <UpdateLog productId={productId!} documentCode={EDocumentCode.BASIC} />
      )}
      <ProductBasicBlock>
        <Spin spinning={getProductSampleLoading || getProductLoading}>
          <Form
            form={form}
            colon={false}
            layout="vertical"
            onFinish={handleSubmitProduct}
            scrollToFirstError={scrollToFirstErrorOption}
          >
            <Form.Item
              label="제품 등록 방법"
              required
              name="isUsedProductSample"
              rules={[requireRule]}
            >
              <ProductSampleUseRadio />
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) =>
                prev.isUsedProductSample !== next.isUsedProductSample
              }
            >
              {({ getFieldValue, setFieldsValue }) =>
                getFieldValue('isUsedProductSample') && (
                  <Form.Item
                    label="샘플 선택하기"
                    name="productSampleId"
                    rules={[requireRule]}
                    preserve={false}
                  >
                    <Select
                      placeholder="샘플명, 랩넘버 검색 혹은 선택"
                      loading={getUsableProductSamplesLoading}
                      options={productSamples.map(
                        ({ productSampleId, productSampleName, labNo }) => ({
                          label: `${productSampleName} - ${labNo}`,
                          value: productSampleId,
                        }),
                      )}
                      showSearch
                      filterOption={(searchKeyword, option) =>
                        (option?.label as string)
                          .toLowerCase()
                          .includes(searchKeyword.toLowerCase())
                      }
                      onSelect={(productSampleId: number) => {
                        getProductSample(productSampleId, {
                          onSuccess: (res) => {
                            const {
                              labNo,
                              isSelfDevelop,
                              brandCompany,
                              countryTargets,
                            } = res.data.result;
                            const brandCompanyNameKo = isSelfDevelop
                              ? '자체 개발용'
                              : brandCompany!.companyNameKo;
                            setFieldsValue({
                              brandCompanyNameKo,
                              labNo,
                              isForEurope: countryTargets.includes(
                                ECountryTarget.EUROPE,
                              ),
                              isForChina: countryTargets.includes(
                                ECountryTarget.CHINA,
                              ),
                            });
                          },
                        });
                      }}
                    />
                  </Form.Item>
                )
              }
            </Form.Item>
            <Typography.Title type="secondary" style={{ marginBottom: 8 }}>
              국가
            </Typography.Title>
            <Form.Item noStyle>
              <Row>
                <Col flex="auto">
                  <Checkbox defaultChecked disabled>
                    <Typography.Text
                      type="secondary"
                      inline
                      style={{ color: '#222' }}
                    >
                      글로벌 (유럽연합, 중국 외)
                    </Typography.Text>
                  </Checkbox>
                </Col>
                <Col>
                  <Form.Item
                    noStyle
                    name="isForEurope"
                    valuePropName="checked"
                    initialValue={false}
                  >
                    <Checkbox
                      disabled={
                        (product && product.manufacturerStatus === 'PRD-REG') ||
                        false
                      }
                    >
                      유럽연합
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    noStyle
                    name="isForChina"
                    valuePropName="checked"
                    initialValue={false}
                  >
                    <Checkbox
                      disabled={
                        (product && product.manufacturerStatus === 'PRD-REG') ||
                        false
                      }
                    >
                      중국
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item
              label="브랜드사명 (국문)"
              name="brandCompanyNameKo"
              rules={[requireRule]}
              style={{ marginTop: 16 }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={
                <Row align="middle" gutter={4}>
                  <Col>자체 품목명 (국문)</Col>
                  <Col>
                    <Tip trigger="click">
                      <Typography.Text type="tertiary">
                        정식 명칭이 없는 경우, 제조사 내<br />
                        자체(임시) 품목명을 입력해 주세요.
                      </Typography.Text>
                    </Tip>
                  </Col>
                </Row>
              }
              name="manufacturerItemNameKo"
              rules={[
                requireRule,
                {
                  type: 'string',
                  max: 100,
                  message: '최대 100자 입력',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="품번 (자체관리용)" name="manufacturerItemNo">
              <Input />
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) =>
                prev.isUsedProductSample !== next.isUsedProductSample
              }
            >
              {({ getFieldValue }) => (
                <Form.Item
                  label="샘플 랩넘버"
                  name="labNo"
                  rules={[requireRule]}
                >
                  <Input
                    maxLength={100}
                    disabled={getFieldValue('isUsedProductSample')}
                  />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item
              label="용량"
              required
              shouldUpdate={(prev, next) =>
                prev.netWeightUnit !== next.netWeightUnit
              }
              style={{ marginBottom: 0 }}
            >
              {({ getFieldValue, setFieldsValue }) => {
                return (
                  <Row gutter={8}>
                    <Col>
                      <Form.Item
                        name="netWeight"
                        rules={[requireRule, numbersRule]}
                      >
                        <InputNumber
                          className="hide-handler"
                          style={{ width: '168px' }}
                          placeholder="숫자 입력"
                          keyboard={false}
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item name="netWeightUnit" rules={[requireRule]}>
                        <Select
                          style={{ width: '168px' }}
                          placeholder="단위 선택"
                          onSelect={() =>
                            setFieldsValue({ netWeightUnitDirect: null })
                          }
                        >
                          {netWeightUnitOptions.map((option) => (
                            <Select.Option
                              key={option.value}
                              value={option.value}
                            >
                              {option.text}
                            </Select.Option>
                          ))}
                          <Select.Option key="others" value="others">
                            직접입력
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    {getFieldValue('netWeightUnit') === 'others' && (
                      <Col>
                        <Form.Item
                          name="netWeightUnitDirect"
                          rules={[requireRule]}
                        >
                          <Input
                            autoFocus
                            placeholder="단위 직접 입력"
                            style={{ width: 168 }}
                            onBlur={(e) => {
                              const netWeightUnit = parseWeightUnit(
                                e.target.value,
                              );
                              if (netWeightUnit) {
                                setFieldsValue({
                                  netWeightUnit,
                                  netWeightUnitDirect: null,
                                });
                              }
                            }}
                          />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                );
              }}
            </Form.Item>
            <Form.Item
              label="제조공장 주소 (국문)"
              name="companyFactoryId"
              required
              rules={[requireRule]}
            >
              <Select
                onChange={(companyFactoryId) => {
                  form.setFieldsValue({
                    addressEn: factories.find(
                      (factory) =>
                        factory.companyFactoryId === companyFactoryId,
                    )!.addressEn,
                  });
                }}
              >
                {factories.map(({ companyFactoryId, addressKo }: any) => (
                  <Select.Option
                    key={companyFactoryId}
                    value={companyFactoryId}
                  >
                    {addressKo}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="제조공장 주소 (영문)"
              name="addressEn"
              required
              rules={[requireRule, exceptKoreanRule]}
            >
              <Input disabled />
            </Form.Item>
            <FooterBox>
              <Button
                type="primary"
                htmlType="submit"
                loading={fetchLoading || getProductForDuplicateLoading}
              >
                {!updateMode ? '등록' : '수정'}
              </Button>
            </FooterBox>
          </Form>
        </Spin>
      </ProductBasicBlock>
    </>
  );
};

export default ProductBasic;
