import { Modal, Table, Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { CloseIcon, Typography } from 'components/system';
import { documentNameMap } from 'lib/documentNames';
import palette from 'lib/styles/palette';
import { useProductInputRequest } from 'service/manufacturer/productInputRequest';
import { IProductInputRequestUser } from 'types/manufacturer/product';

// const searchTypeOptions = [
//   { label: '전체', value: 'all' },
//   { label: '부서명', value: 'deptName' },
//   {
//     label: '직급명',
//     value: 'positionName',
//   },
// ];

const ProductInputRequestModal = ({
  productId,
  documentCode,
  productName,
  onClose,
}: {
  productId: number;
  documentCode: string;
  productName: string;
  onClose: () => void;
}) => {
  // const [searchType, setSearchType] = useState('all');
  // const [searchValue, setSearchValue] = useState('');
  const parsedDocumentCode = `${documentCode.toUpperCase()}01`;
  const {
    users,
    getLoading,
    postProductInputRequestLoading,
    postProductInputRequest,
  } = useProductInputRequest({ productId, documentCode: parsedDocumentCode });

  const handleClickInputRequest = (user: IProductInputRequestUser) => {
    const { name, userId: toUserId } = user;
    Modal.confirm({
      width: 420,
      style: { textAlign: 'center', marginTop: 16 },
      icon: null,
      closeIcon: <CloseIcon />,
      content: (
        <p>
          <Typography.Text type="secondary" medium inline>
            {name}
          </Typography.Text>
          님에게{' '}
          <Typography.Text type="secondary" medium inline>
            {productName}
          </Typography.Text>
          의
          <br />
          <Typography.Text type="secondary" medium inline>
            {documentNameMap[parsedDocumentCode]}
          </Typography.Text>{' '}
          입력을 요청하시겠습니까?
        </p>
      ),
      okText: '네',
      cancelText: '취소',
      onOk: () => {
        postProductInputRequest({ toUserId, onSuccess: onClose });
      },
    });
  };

  const columns: ColumnsType<IProductInputRequestUser> = [
    {
      title: '이름',
      align: 'center',
      dataIndex: 'name',
      render: (name) => {
        return (
          <Typography.Text type="secondary" color="black" medium>
            {name}
          </Typography.Text>
        );
      },
    },
    {
      title: '부서명',
      align: 'center',
      dataIndex: 'deptName',
    },
    {
      title: '직급명',
      align: 'center',
      dataIndex: 'positionName',
    },
    {
      width: 80,
      render: (user) => (
        <Button
          style={{
            maxWidth: 72,
            maxHeight: 32,
            padding: '6px 14px 7px',
            color: palette.primary,
            fontSize: 12,
          }}
          onClick={() => handleClickInputRequest(user)}
        >
          입력 요청
        </Button>
      ),
    },
  ];

  return (
    <Modal visible={documentCode.length > 0} onCancel={onClose} footer={null}>
      <Typography.Title>입력 요청 보내기</Typography.Title>
      {/* TODO : 페이징 기능 서버 작업 공수 되면 2.2 버전에 추가 */}
      {/* <Space
        style={{ width: '100%', justifyContent: 'flex-end', marginTop: 32 }}
      >
        <Select
          options={searchTypeOptions}
          value={searchType}
          onChange={setSearchType}
          style={{ width: 100 }}
        />
        <SearchInput
          placeholder="검색"
          defaultValue={searchValue}
          disabled={searchType === 'all'}
          onSearch={() => {}}
          style={{ width: 200 }}
        />
      </Space> */}
      <Table
        style={{ marginTop: 16 }}
        columns={columns}
        rowKey="userId"
        loading={getLoading || postProductInputRequestLoading}
        pagination={false}
        dataSource={users}
      />
    </Modal>
  );
};

export default ProductInputRequestModal;
