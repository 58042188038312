import { Button } from 'antd';
import styled from 'styled-components';

import { useCertificateMode } from 'hook/certificate';
import FileUploadContainer from 'containers/file/FileUploadContainer';
import FooterBox from 'components/FooterBox';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';

const ProductArtworkBlock = styled.div`
  margin-top: 20px;
`;

const ProductArtwork = ({
  updateMode,
  readOnlyMode,
  artworks,
  onUpload,
  fetchLoading,
  onDeleteArtwork,
  onSubmit,
}: any) => {
  const certificateMode = useCertificateMode();
  return (
    <ProductArtworkBlock>
      <FileUploadContainer
        files={artworks}
        readOnly={readOnlyMode}
        onUpload={onUpload}
        onDelete={onDeleteArtwork}
      />
      <FooterBox>
        <ReadOnlyBackButton readOnly={readOnlyMode}>
          <Button type="primary" loading={fetchLoading} onClick={onSubmit}>
            {!updateMode ? '등록' : !certificateMode ? '수정' : '보완 완료'}
          </Button>
        </ReadOnlyBackButton>
      </FooterBox>
    </ProductArtworkBlock>
  );
};

export default ProductArtwork;
