import { useQuery } from 'react-query';
import * as materialCompanyApi from 'lib/api/material/company';

export const useMaterialCompanies = (materialCompaniesParams?: {
  isExcludeUserInserted: boolean;
}) => {
  return useQuery(
    ['pub/material-companies', materialCompaniesParams],
    () => materialCompanyApi.getMaterialCompanies(materialCompaniesParams),
    {
      select: (res) => res.data.result,
    },
  );
};
