import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Form, message, Modal } from 'antd';

import { scrollToFirstErrorOption } from 'lib/consts';
import * as companyApi from 'lib/api/company';
import * as authApi from 'lib/api/auth';
import history from 'lib/history';
import path from 'lib/path';
import { setChangePasswordModalVisible, setUser } from 'modules/auth';
import { useUser } from 'service/auth';
import { useCompany } from 'service/company';
import { getCompany } from 'modules/company';
import { useMaterialCompanies } from './company';

export const useMaterialRegister = () => {
  const dispatch = useDispatch();
  const [tab, setTab] = useState<'company' | 'user'>('company');
  const [form] = Form.useForm();
  const user = useUser();
  const company = useCompany();
  const { isUseOfLogoAgreed } = useSelector(({ auth }: any) => auth.register);
  const { data: materials = [] } = useMaterialCompanies({
    isExcludeUserInserted: true,
  });
  const updateMode = user !== null && company !== null;
  const { data: factories } = useQuery(
    'company/getFactories',
    () => companyApi.getMaterialFactories(company.companyId),
    { select: (res) => res.data.result, enabled: updateMode },
  );
  const { mutate: registerMaterial, isLoading: registerLoading } = useMutation(
    async ({ companyForm, userForm, factories }: any) => {
      try {
        const res = await companyApi.registerCompany({
          ...companyForm,
          ...userForm,
          userType: 'MATERIAL',
          isServiceTermsAgreed: true,
          isPrivacyTermsAgreed: true,
          isUseOfLogoAgreed,
        });
        const companyId = res.data.result.companyId;
        const addingFactories = factories.filter(
          ({ addressKo, addressEn }: any) => addressKo && addressEn,
        );
        if (addingFactories.length > 0) {
          await companyApi.registerMaterialFactory(
            factories.map((factory: any, index: number) => ({
              ...factory,
              companyId,
              seq: index + 1,
            })),
          );
        }
      } catch (error) {
        throw error;
      }
    },
    {
      onSuccess: () => {
        history.push(path.register.success);
      },
    },
  );
  const { mutate: updateMaterial, isLoading: updateLoading } = useMutation(
    async ({ companyForm, userForm, factories: newFactories }: any) => {
      await companyApi.updateCompany(companyForm);
      const sequencedFactories = newFactories.map(
        (newFactory: any, index: number) => ({
          ...newFactory,
          seq: index + 1,
        }),
      );
      const addingFactories = sequencedFactories.filter(
        ({ materialFactoryId, addressKo, addressEn }: any) =>
          !materialFactoryId && addressKo && addressEn,
      );
      if (addingFactories.length > 0) {
        await companyApi.registerMaterialFactory(
          addingFactories.map((factory: any) => ({
            ...factory,
            companyId: company.companyId,
          })),
        );
      }
      const deletingFactoryIds = factories
        .filter(({ materialFactoryId }: any) => {
          const target = sequencedFactories.find(
            (newFactory: any) =>
              newFactory.materialFactoryId !== materialFactoryId,
          );
          return !target || (!target.addressKo && !target.addressEn);
        })
        .map(({ materialFactoryId }: any) => materialFactoryId);
      if (deletingFactoryIds.length > 0) {
        await companyApi.deleteMaterialFactory(deletingFactoryIds);
      }
      const updatingFactories = sequencedFactories.filter(
        ({ materialFactoryId, addressKo, addressEn, seq }: any) => {
          if (!materialFactoryId) return false;
          const correspondingFactory = factories.find(
            (factory: any) => materialFactoryId === factory.materialFactoryId,
          );
          if (
            addressKo === correspondingFactory.addressKo &&
            addressEn === correspondingFactory.addressEn &&
            seq === correspondingFactory.seq
          ) {
            return false;
          }
          return true;
        },
      );
      if (updatingFactories.length) {
        await companyApi.updateMaterialFactory({
          companyId: company.companyId,
          factories: updatingFactories,
        });
      }

      return await authApi.updateUser(userForm);
    },
    {
      onSuccess: (res) => {
        dispatch(getCompany(company.companyId));
        dispatch(setUser(res.data.result));
        message.success('수정되었습니다.');
      },
    },
  );

  const handleClickChangePassword = () => {
    dispatch(setChangePasswordModalVisible(true));
  };

  const handleClickNext = () => {
    form
      .validateFields()
      .then(() => {
        form.setFieldsValue({
          username: form.getFieldValue('bizNumber').replaceAll('-', ''),
        });
        setTab('user');
      })
      .catch((error) => {
        form.scrollToField(
          error.errorFields[0].name[0],
          scrollToFirstErrorOption,
        );
      });
  };

  const handleClickBack = () => {
    setTab('company');
  };

  const handleSubmit = (formData: any) => {
    // 회사 정보
    const material = materials.find(
      ({ companyNameKo }) => companyNameKo === formData.companyNameKo,
    );
    const companyForm = {
      ...(updateMode && { companyId: company.companyId }),
      companyNameKo:
        formData.companyNameKo === 'companyNameKoDirect'
          ? formData.companyNameKoDirect
          : formData.companyNameKo,
      companyNameEn: formData.companyNameEn,
      ...(material?.materialCompanyId && {
        materialCompanyId: material.materialCompanyId,
      }),
      bizNumber: formData.bizNumber,
      ceoNameKo: formData.ceoNameKo,
      ceoNameEn: formData.ceoNameEn,
      addressKo: formData.addressKo,
      addressEn: formData.addressEn,
      tel: formData.tel,
      fax: formData.fax,
      ...(!updateMode
        ? { companyEmail: formData.companyEmail }
        : { email: formData.companyEmail }),
      ...(formData.mainItem && { mainItem: formData.mainItem }),
      ...(formData.introduction && { introduction: formData.introduction }),
      isServiceTermsAgreed: true,
      isPrivacyTermsAgreed: true,
      isManufacturer: false,
      companyType: 'MATERIAL',
      ...(!updateMode && {
        bizLicenseFile: formData.bizLicenseFile,
        bizLicenseEnFile: formData.bizLicenseEnFile,
      }),
    };
    // 공장 정보
    const factories = formData.factories;
    //유저 정보
    const userForm = {
      ...(updateMode && { userId: user.userId }),
      ...(!updateMode && { isSuperUser: true }),
      isManufacturer: true,
      username: formData.bizNumber.replace(/-/g, ''),
      password: formData.password,
      name: formData.name,
      deptName: formData.deptName,
      positionName: formData.positionName,
      email: formData.email,
      mobile: formData.mobile,
      userType: 'MATERIAL',
    };
    if (!updateMode) {
      // 등록
      registerMaterial({ companyForm, userForm, factories });
    } else {
      // 수정
      Modal.confirm({
        title: '정말로 수정하시겠습니까?',
        icon: null,
        onOk: () => {
          updateMaterial({ companyForm, userForm, factories });
        },
      });
    }
  };

  useEffect(() => {
    if (updateMode) {
      form.setFieldsValue({
        ...company,
        companyEmail: company.email,
        ...(company.bizLicenseUrl && {
          bizLicenseFile: { name: 'Uploaded File', url: company.bizLicenseUrl },
        }),
        ...(company.bizLicenseEnUrl && {
          bizLicenseEnFile: {
            name: 'Uploaded File',
            url: company.bizLicenseEnUrl,
          },
        }),
        ...user,
      });
    }
  }, []);

  useEffect(() => {
    if (factories) {
      form.setFieldsValue({
        factories: factories.length > 0 ? factories : [{}],
      });
    }
  }, [factories]);

  useEffect(() => {
    if (!updateMode && process.env.NODE_ENV === 'development') {
      form.setFieldsValue({
        companyNameKo: '둘리나라',
        companyNameEn: 'DooleyWorld',
        addressKo: '서울시 종로구',
        addressEn: 'Jongro-gu, Seoul',
        factoryAddresses: [
          { addressKo: '서울시 종로구', addressEn: 'Jongro-gu, Seoul' },
        ],
        ceoNameKo: '둘리',
        ceoNameEn: 'Dooley',
        tel: '0211112222',
        fax: '0211112222',
        majorProducts: '기타 등등1',
        description: '기타 등등2',
        bizNumber: '111-11-11111',
        password: '1111',
        passwordConfirm: '1111',
        name: '장동혁',
        deptName: '고길동괴롭힘팀',
        positionName: '한량',
        companyEmail: 'flynnCompany@fromom.net',
        email: 'flynn@fromom.net',
        mobile: '01012345678',
        isEmailVerified: false,
        isPhoneVerified: false,
        isSmsAgreed: true,
        isEmailAgreed: true,
      });
    }
  }, []);

  return useMemo(
    () => ({
      tab,
      updateMode,
      materials,
      form,
      bizLicenseEnUrl: company?.bizLicenseEnUrl,
      submitLoading: registerLoading || updateLoading,
      handleClickChangePassword,
      handleClickNext,
      handleClickBack,
      handleSubmit,
    }),
    [tab, materials, updateMode, form, registerLoading, updateLoading],
  );
};
