import { Form, InputNumber, Radio, Row, Col, Select, Input } from 'antd';

import * as S from './Styled';
import { Typography } from 'components/system';
import { requireRule } from 'lib/validate';
import { viscosityUnitOptions } from 'lib/selectOption';

const ViscosityForm = ({
  readOnlyMode,
  onChangeViscosityRadioGroup,
}: {
  readOnlyMode: boolean;
  onChangeViscosityRadioGroup: (isExistedViscosity: boolean) => void;
}) => {
  return (
    <S.StyledFormItem label="Viscosity" required>
      <Form.Item name="isExistedViscosity" noStyle initialValue={true}>
        <Radio.Group
          disabled={readOnlyMode}
          onChange={(e) => onChangeViscosityRadioGroup(e.target.value)}
        >
          <Radio value={true}>입력하기</Radio>
          <Radio value={false}>해당 없음</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        shouldUpdate={(prev, next) =>
          prev.isExistedViscosity !== next.isExistedViscosity ||
          prev.viscosityLimitMin !== next.viscosityLimitMin ||
          prev.viscosityLimitMax !== next.viscosityLimitMax
        }
        noStyle
      >
        {({ getFieldValue }) => {
          const isExistedViscosity = getFieldValue('isExistedViscosity');
          const viscosityLimitMin = getFieldValue('viscosityLimitMin');
          const viscosityLimitMax = getFieldValue('viscosityLimitMax');

          return (
            <>
              <S.StyledRow style={{ marginTop: 8 }}>
                <Typography.Text gutter={{ right: 4 }}>
                  정상 범위
                </Typography.Text>
                {/* HINT : Form의 validation이 reject된 상태에서 disabled가 먹히지 않으므로 
                      Form.Item 렌더링을 disabled된 컴포넌트와 아닌 컴포넌트로 분기 처리 */}
                {isExistedViscosity ? (
                  <S.AntdExplainWrapper>
                    <Form.Item
                      style={{ width: 120 }}
                      dependencies={['viscosityLimitMax']}
                      name="viscosityLimitMin"
                      rules={[
                        requireRule,
                        {
                          validator: (_, viscosityLimitMinValue) => {
                            if (viscosityLimitMinValue > viscosityLimitMax) {
                              return Promise.reject(
                                '좌측 정상 범위는 우측 정상 범위 보다 클 수 없음',
                              );
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <InputNumber
                        maxLength={8}
                        disabled={readOnlyMode}
                        step={100}
                        min={0}
                        style={{ width: 120 }}
                        formatter={(value) => {
                          if (value) {
                            return Number(value).toLocaleString();
                          }
                          return value as any;
                        }}
                      />
                    </Form.Item>
                  </S.AntdExplainWrapper>
                ) : (
                  <Form.Item>
                    <InputNumber disabled style={{ width: 120 }} />
                  </Form.Item>
                )}
                <Typography.Text
                  style={{
                    display: 'inline-block',
                    width: '16px',
                    textAlign: 'center',
                  }}
                >
                  ~
                </Typography.Text>
                {isExistedViscosity ? (
                  <Form.Item name="viscosityLimitMax" rules={[requireRule]}>
                    <InputNumber
                      maxLength={8}
                      step={100}
                      min={0}
                      disabled={readOnlyMode}
                      style={{ width: 120 }}
                      formatter={(value) => {
                        if (value) {
                          return Number(value).toLocaleString();
                        }
                        return value as any;
                      }}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item noStyle>
                    <InputNumber disabled style={{ width: 120 }} />
                  </Form.Item>
                )}
              </S.StyledRow>
              <S.StyledRow>
                <Typography.Text gutter={{ left: 30, right: 4 }}>
                  수치
                </Typography.Text>
                <Form.Item
                  shouldUpdate={(prev, next) =>
                    prev.viscosityUnit !== next.viscosityUnit
                  }
                  style={{ marginBottom: 0 }}
                >
                  {({ getFieldValue, setFieldsValue }) => {
                    return (
                      <Row gutter={8}>
                        <Col style={{ width: '128px' }}>
                          {isExistedViscosity ? (
                            <Form.Item
                              name="viscosity"
                              required
                              dependencies={[
                                'viscosityLimitMin',
                                'viscosityLimitMax',
                              ]}
                              rules={[
                                requireRule,
                                {
                                  validator: (_, viscosity) => {
                                    if (
                                      viscosity < viscosityLimitMin ||
                                      viscosity > viscosityLimitMax
                                    ) {
                                      return Promise.reject(
                                        '정상 범위를 벗어난 수치',
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                },
                              ]}
                            >
                              <InputNumber
                                disabled={readOnlyMode}
                                maxLength={8}
                                step={100}
                                style={{ width: 120 }}
                                formatter={(value) => {
                                  if (value) {
                                    return Number(value).toLocaleString();
                                  }
                                  return value as any;
                                }}
                              />
                            </Form.Item>
                          ) : (
                            <Form.Item noStyle>
                              <InputNumber style={{ width: 120 }} disabled />
                            </Form.Item>
                          )}
                        </Col>
                        <Col style={{ width: 120 }}>
                          {isExistedViscosity ? (
                            <Form.Item
                              name="viscosityUnit"
                              rules={[requireRule]}
                            >
                              <Select
                                placeholder="단위 선택"
                                onSelect={() =>
                                  setFieldsValue({
                                    viscosityUnitDirect: null,
                                  })
                                }
                                disabled={
                                  readOnlyMode ||
                                  isExistedViscosity === 'viscosityNo'
                                }
                              >
                                {viscosityUnitOptions.map((option) => (
                                  <Select.Option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.text}
                                  </Select.Option>
                                ))}
                                <Select.Option key="others" value="others">
                                  직접입력
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          ) : (
                            <Form.Item>
                              <Select disabled />
                            </Form.Item>
                          )}
                        </Col>
                        {getFieldValue('viscosityUnit') === 'others' &&
                          isExistedViscosity && (
                            <Col style={{ width: 120 }}>
                              <Form.Item
                                name={
                                  getFieldValue('viscosityUnit') === 'others'
                                    ? 'viscosityUnitDirect'
                                    : 'viscosityUnit'
                                }
                                rules={[requireRule]}
                              >
                                <Input
                                  placeholder="단위 직접 입력"
                                  disabled={readOnlyMode}
                                />
                              </Form.Item>
                            </Col>
                          )}
                      </Row>
                    );
                  }}
                </Form.Item>
              </S.StyledRow>
            </>
          );
        }}
      </Form.Item>
    </S.StyledFormItem>
  );
};

export default ViscosityForm;
