import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import path from 'lib/path';
import ManagerPage from 'pages/auth/ManagerPage';
import UserInfoPage from 'pages/auth/UserInfoPage';
import BuyerPage from 'pages/buyer/BuyerPage';
import Error404Page from 'pages/error/Error404Page';
import ContractsPage from 'pages/estimate/ContractsPage';
import VCRPAccountPage from 'pages/vcrp/VCRPAccountPage';
import { EUserType } from 'types/auth';

const MyRouter = () => {
  const { authority, userType } = useSelector(({ auth }) => auth.user);
  return (
    <Switch>
      <Route
        path={[`${path.my}/userInfo`, path.my]}
        exact
        component={UserInfoPage}
      />
      {userType === EUserType.BRAND && (
        <Route path={`${path.my}/estimate`} component={ContractsPage} />
      )}
      {authority === 'SUPER_USER' && (
        <Route path={`${path.my}/manager`} component={ManagerPage} />
      )}
      {userType === EUserType.BRAND && (
        <Route path={`${path.my}/buyer`} component={BuyerPage} />
      )}
      {userType === EUserType.BRAND && (
        <Route path={`${path.my}/vcrp`} component={VCRPAccountPage} />
      )}
      <Route path="*" component={Error404Page} />
    </Switch>
  );
};

export default MyRouter;
