import { Route, Switch, useParams } from 'react-router-dom';

import path from 'lib/path';
import ProductBasicPage from 'pages/manufacturer/product/ProductBasicPage';
import ProductReadyListPage from 'pages/manufacturer/product/ProductReadyListPage';
import ProductListPage from 'pages/manufacturer/product/ProductListPage';
import ProductDetailPage from 'pages/manufacturer/product/ProductDetailPage';
import { useSetCurrentProduct } from 'service/brand/product/product';

const ProductRouter = () => {
  const params = useParams<{ productId?: string }>();
  const productId = Number(params.productId) || undefined;
  useSetCurrentProduct({ productId, manuMode: true });
  return (
    <Switch>
      <Route
        path={path.manufacturer.product.readyList}
        component={ProductReadyListPage}
      />
      <Route
        path={path.manufacturer.product.list}
        component={ProductListPage}
      />
      <Route
        path={[
          '/manufacturer/product/:productId/basic',
          '/manufacturer/product/basic',
        ]}
        component={ProductBasicPage}
      />
      <Route
        path="/manufacturer/product/:productId"
        component={ProductDetailPage}
      />
    </Switch>
  );
};

export default ProductRouter;
