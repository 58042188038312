import styled from 'styled-components';

import LoginContainer from 'containers/auth/LoginContainer';
import CompanyBannerCarousel from 'components/main/CompanyBannerCarousel';

const LoginPageBlock = styled.div`
  width: 100%;
  padding-top: 40px;
`;

const LoginPage = () => {
  return (
    <LoginPageBlock>
      <LoginContainer />
      <CompanyBannerCarousel />
    </LoginPageBlock>
  );
};

export default LoginPage;
