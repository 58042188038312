import { Link, useParams } from 'react-router-dom';

import DocumentsUpload from 'components/brand/retailer/retailerApply/DocumentsUpload';
import ProductPageTemplate from 'components/brand/retailer/retailerApply/product/ProductPageTemplate';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import { EDocumentCode } from 'types/brand/retailer/retailerApply';
import { Typography } from 'components/system';
import path from 'lib/path';

const ProductPSRPage = () => {
  const params = useParams<{ productId: string }>();
  const productId = Number(params.productId);
  return (
    <ProductPageTemplate
      productId={productId}
      subtitle="포장공간비율 성적서"
      notices={[
        '2차 포장 대비 1차 포장 또는 종합제품의 포장공간비율 확인이 필요 하다고 판단 될 경우 필요한 성적서입니다.',
        <Typography.Text type="secondary">
          환경부령 제 933호, ‘제품의 종류별 포장방법에 관한 기준’에 의거하여,
          단위제품별 해당 기준에 부합해야 합니다. (해당 기준{' '}
          <Link
            to={`${path.service}/guide`}
            target="_blank"
            style={{ fontWeight: 700, textDecoration: 'underline' }}
          >
            확인하기
          </Link>
          )
        </Typography.Text>,
        <MistakeNoticeItem />,
      ]}
    >
      <DocumentsUpload
        productId={productId}
        documentCode={EDocumentCode.PSR}
        accept="application/pdf"
      />
    </ProductPageTemplate>
  );
};

export default ProductPSRPage;
