import { Tabs } from 'antd';
import { useParams } from 'react-router';
import styled from 'styled-components';

import PostModal from 'components/modal/service/PostModal';
// import FAQContainer from 'containers/service/FAQContainer';
import NoticeContainer from 'containers/service/NoticeContainer';
import UseGuideContainer from 'containers/service/UseGuideContainer';
import { deviceSize } from 'lib/styles';

const ServicePageBlock = styled.div`
  .ant-tabs-nav::before {
    border-bottom: none;
  }

  .ant-tabs-tab {
    width: 80px;
    margin-right: 0;

    .ant-tabs-tab-btn {
      margin: 0 auto;
    }
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 100px;

    @media ${deviceSize.sm} {
      margin-left: 0;
    }
  }
`;

const ServicePage = () => {
  const { tab } = useParams<{ tab?: string }>();
  return (
    <ServicePageBlock>
      <PostModal />
      <Tabs defaultActiveKey={tab || 'notice'} centered destroyInactiveTabPane>
        {/* TODO: 추후에 FAQ 게시물 추가되면 주석 제거 */}
        {/* <Tabs.TabPane tab="FAQ" key="faq">
          <FAQContainer />
        </Tabs.TabPane> */}
        <Tabs.TabPane tab="공지사항" key="notice">
          <NoticeContainer />
        </Tabs.TabPane>
        <Tabs.TabPane tab="이용안내" key="guide">
          <UseGuideContainer />
        </Tabs.TabPane>
      </Tabs>
    </ServicePageBlock>
  );
};

export default ServicePage;
