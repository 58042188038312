import { Link } from 'react-router-dom';
import { Typography } from 'antd';

import MistakeNoticeItem from 'components/MistakeNoticeItem';
import ProductSingleFileContainer from 'containers/product/ProductSingleFileContainer';
import ProductPageTemplate from 'templates/ProductPageTemplate';
import path from 'lib/path';
import { EDocumentCode } from 'types/product';

const notices = [
  <Typography.Text style={{ padding: 0 }}>
    CFS (자유판매증명서) 검토를 위해 “증명서 미리보기”를 클릭하여 DRAFT 파일을
    업로드해 주세요. 발급 방법을 모르실 경우{' '}
    <Link
      to={`${path.service}/guide`}
      target="_blank"
      style={{ textDecoration: 'underline' }}
    >
      여기
    </Link>
    를 클릭해 주세요.
    <br />
    발급 완료 후 스캔하여 올리셨을 때 검토로 인하여 재발급이 필요할 경우, 재발급
    비용에 대해서는 30COS에서 책임지지 않습니다.
  </Typography.Text>,
  <MistakeNoticeItem />,
];

const ProductCFSPage = () => {
  return (
    <ProductPageTemplate subtitle="CFS" notices={notices} exampleImg="CFS.jpg">
      <ProductSingleFileContainer
        documentCode={EDocumentCode.CFS}
        accept=".pdf, image/*"
      />
    </ProductPageTemplate>
  );
};

export default ProductCFSPage;
