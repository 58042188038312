import PostList from 'components/service/PostList';
import { getNotices } from 'modules/service';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as serviceActions from 'modules/service';

const NoticeContainer = () => {
  const dispatch = useDispatch();
  const notices = useSelector(({ service }) => service.notices);
  const getLoading = useSelector(
    ({ loading }) => loading['service/GET_NOTICES'],
  );
  const setPost = (post) => {
    dispatch(serviceActions.setPost(post));
  };
  useEffect(() => {
    dispatch(getNotices());
  }, []);
  return (
    <PostList
      type="notice"
      title="공지사항"
      list={notices}
      loading={getLoading}
      onClick={setPost}
    />
  );
};

export default NoticeContainer;
