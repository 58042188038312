import { all, call, put } from 'redux-saga/effects';
import { startLoading, finishLoading } from '../modules/loading';

export const createRequestActionTypes = (type) => {
  const SUCCESS = `${type}_SUCCESS`;
  const FAILURE = `${type}_FAILURE`;
  return [type, SUCCESS, FAILURE];
};

export function createRequestSaga(
  type,
  request,
  { onSuccess, onFailure } = {},
) {
  const SUCCESS = `${type}_SUCCESS`;
  const FAILURE = `${type}_FAILURE`;

  return function* ({ payload }) {
    yield put(startLoading(type));
    try {
      const response = yield call(request, payload);
      yield put({
        type: SUCCESS,
        payload: response.data.result,
        meta: payload,
      });
      if (onSuccess) {
        yield onSuccess({ payload, result: response.data.result });
      }
    } catch (error) {
      console.error('[SAGA ERROR]', error);
      if (error.code) {
        yield put({
          type: FAILURE,
          payload: error,
        });
      }
      if (onFailure) {
        yield onFailure(error);
      }
    }
    yield put(finishLoading(type));
  };
}

export function createMultipleRequestSaga(
  type,
  requests,
  { onSuccess, onFailure } = {},
) {
  const SUCCESS = `${type}_SUCCESS`;
  const FAILURE = `${type}_FAILURE`;

  return function* ({ payload: payloads = [] }) {
    yield put(startLoading(type));
    try {
      let responses = null;
      if (requests instanceof Array) {
        responses = yield all(
          requests.map((request, i) => call(request, payloads[i])),
        );
      } else {
        responses = yield all(
          payloads.map((payload) => call(requests, payload)),
        );
      }
      const results = responses.map((response) => response.data.result);
      yield put({
        type: SUCCESS,
        payload: results,
        meta: payloads,
      });
      if (onSuccess) {
        yield onSuccess({ payloads, results });
      }
    } catch (error) {
      console.error('[SAGA ERROR]', error);
      if (error.code) {
        yield put({
          type: FAILURE,
          payload: error,
        });
      }
      if (onFailure) {
        yield onFailure(error);
      }
    }
    yield put(finishLoading(type));
  };
}
