import styled from 'styled-components';
import { Form } from 'antd';

export const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0px;
`;

export const StyledRow = styled.div`
  display: flex;
  align-items: baseline;
`;

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AntdExplainWrapper = styled.div`
  .ant-form-item-explain {
    width: 200px;
    position: absolute;
    bottom: -16px;
  }
`;
