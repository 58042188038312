import { Switch, Route } from 'react-router-dom';

import path from 'lib/path';
import RetailerApplyRouter from './RetailerApplyRouter';

const RetailerRouter = () => {
  return (
    <Switch>
      <Route
        path={path.retailer.retailerApply.root}
        component={RetailerApplyRouter}
      />
    </Switch>
  );
};

export default RetailerRouter;
