import { deviceSize } from 'lib/styles';
import palette from 'lib/styles/palette';
import styled from 'styled-components';

const FooterBox = styled.div<{ modal?: boolean }>`
  padding-top: 56px;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    min-width: ${(props) => (props.modal ? '120px' : '200px')};
    height: ${(props) => (props.modal ? '44px' : '56px')};
    font-size: ${(props) => (props.modal ? '14px' : '18px')};
    letter-spacing: -1px;

    @media ${deviceSize.sm} {
      min-width: auto;
      flex: 1 1 100%;
    }

    & + button {
      margin-left: 16px;
    }

    &.ant-btn:not(.ant-btn-primary) {
      border: 1px solid ${palette.primary};
    }
  }
`;
export default FooterBox;
