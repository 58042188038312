import ProductPageTemplate from 'templates/ProductPageTemplate';
import ProductKeepWarning from 'components/product/ProductKeepWarning';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';

const notices = [
  '선택하시는 제품군에 따라, 해당 주의사항이 라벨에 작성되어 발행됩니다. 단, 제품의 카테고리나 배합 성분에 따라 추후 다른 문구가 추가될 수도 있습니다.',
];

const ProductKeepWarningPage = () => {
  const { productId } = useCurrentProduct();
  const countryId = useCountryId();
  return (
    <ProductPageTemplate
      subtitle="사용 보관상의 주의사항 선택"
      notices={notices}
    >
      <ProductKeepWarning productId={productId} countryId={countryId!} />
    </ProductPageTemplate>
  );
};

export default ProductKeepWarningPage;
