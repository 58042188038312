import EstimateList from 'components/estimate/EstimateList';
import EstimateAdditionalsModal from 'components/modal/estimate/EstimateAdditionalsModal';
import EstimateHistoriesModal from 'components/modal/estimate/EstimateHistoriesModal';
import DocumentPreviewModal from 'components/modal/product/DocumentPreviewModal';
import * as estimateActions from 'modules/estimate';
import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

const EstimateListContainer = () => {
  const dispatch = useDispatch();
  const { companyId, estimates, getLoading } = useSelector(
    ({ auth, estimate, loading }) => ({
      companyId: auth.user.companyId,
      estimates: estimate.estimates,
      getLoading: loading['estimate/GET_ESTIMATES'],
    }),
    shallowEqual,
  );
  const [page, setPage] = useState(1);
  const [previewedEstimateUrl, setPreviewedEstimateUrl] = useState(null);
  const [estimateIdForHistories, setEstimateIdForHistories] = useState(null);
  const [estimateAdditionals, setEstimateAdditionals] = useState(null);

  const closePreviewModal = () => {
    setPreviewedEstimateUrl(null);
  };
  const closeEstimateHistoryModal = () => {
    setEstimateIdForHistories(null);
  };
  const closeEstimateAdditionals = () => {
    setEstimateAdditionals(null);
  };
  const getEstimates = (page) => {
    setPage(page);
    dispatch(
      estimateActions.getEstimates({
        companyId,
        pageNo: page,
      }),
    );
  };

  useEffect(() => {
    getEstimates(1);
  }, []);
  return (
    <>
      <DocumentPreviewModal
        documentUrls={previewedEstimateUrl}
        onClose={closePreviewModal}
      />
      <EstimateHistoriesModal
        estimateId={estimateIdForHistories}
        onClose={closeEstimateHistoryModal}
        onPreview={setPreviewedEstimateUrl}
      />
      <EstimateAdditionalsModal
        estimateAdditionals={estimateAdditionals}
        onClose={closeEstimateAdditionals}
        onPreview={setPreviewedEstimateUrl}
      />
      <EstimateList
        estimates={estimates}
        page={page}
        getLoading={getLoading}
        onChangePage={getEstimates}
        onPreview={setPreviewedEstimateUrl}
        onShowEstimateHistories={setEstimateIdForHistories}
        onShowEstimateAdditionals={setEstimateAdditionals}
      />
    </>
  );
};

export default EstimateListContainer;
