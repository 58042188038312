import palette from 'lib/styles/palette';
import styled, { css } from 'styled-components';
interface IBlockProps {
  type:
    | 'default'
    | 'primary'
    | 'success'
    | 'error'
    | 'disabled'
    | 'check'
    | 'gray';
  bordered?: boolean;
  closable: boolean;
  onClick?: React.MouseEventHandler;
}
interface IProps extends Pick<IBlockProps, 'type' | 'bordered' | 'onClick'> {
  children: React.ReactNode;
  closeable?: boolean;
  style?: React.CSSProperties;
}

const LabelBlock = styled.span<IBlockProps>`
  display: inline-block;
  min-width: 72px;
  height: 24px;
  font-size: 12px;
  padding: 4px 4px 3px;
  letter-spacing: -0.4px;
  border-radius: 4px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
      padding: 4px 2px 3px 6px;
    `};

  ${({ closable }) =>
    closable &&
    css`
      padding-right: 4px;
      &:hover::after {
        content: '×';
        display: inline-block;
        padding-left: 4px;
      }
    `};

  color: ${({ type }) => (type === 'default' ? 'inherit' : palette.text[type])};
  border: ${({ type, bordered }) =>
    bordered && type !== 'default' ? `1px solid ${palette[type]}` : 'none'};

  ${({ type, bordered }) => {
    if (bordered) return null;

    switch (type) {
      case 'primary':
        return css`
          background-color: #e6f0ff;
        `;

      case 'success':
        return css`
          background-color: ${palette.lightGreen};
        `;

      case 'disabled':
        return css`
          background-color: #f5f5f5;
        `;
      case 'error':
        return css`
          background-color: #ffe6e6;
        `;
      case 'gray':
        return css`
          background-color: ${palette.gray};
        `;
      case 'check':
        return css`
          color: #fff;
          background-color: ${palette.check};
        `;
    }
  }};

  i.material-icons {
    line-height: 16px;
  }
`;

const Label = ({
  type,
  bordered,
  closeable = false,
  style,
  onClick,
  children,
}: IProps) => {
  return (
    <LabelBlock
      type={type}
      bordered={bordered}
      closable={closeable}
      style={style}
      onClick={onClick}
    >
      {children}
    </LabelBlock>
  );
};

export default Label;
