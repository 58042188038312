import { Button, Col, Modal, Row, Table } from 'antd';
import styled from 'styled-components';
import { useUpdateLog } from 'hook/product';
import TabButton from 'components/TabButton';
import { Typography } from 'components/system';
import { useCallback, useMemo } from 'react';
import { downloadFile, downloadFiles, getFilenameFromUrl } from 'lib/file';
import usePreviewModal from 'hook/usePreviewModal';
import { EDocumentCode } from 'types/product';

const UpdateLogBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;

  .ant-btn-gray {
    height: 32px;
  }
`;

const UpdateLog = ({
  productId,
  countryId,
  documentCode,
  validCodes,
}: {
  productId: number;
  countryId?: number;
  documentCode: EDocumentCode;
  validCodes?: string[];
}) => {
  const {
    fields,
    selectedField,
    setSelectedField,
    documentLogs,
    loading,
  } = useUpdateLog({
    productId,
    countryId,
    documentCode,
    validCodes,
  });

  const { PreviewModal, setUrl } = usePreviewModal();
  const fileMode = useMemo(
    () => documentLogs.length > 0 && documentLogs[0].uploadFileUrl !== null,
    [documentLogs],
  );
  const columns = useMemo(
    () => [
      {
        title: !fileMode ? '내용' : '파일명',
        dataIndex: !fileMode ? 'content' : 'filename',
        align: 'center' as const,
        width: !fileMode ? '60%' : '40%',
        ...(!fileMode && {
          render: (content: string) => (
            <pre style={{ whiteSpace: 'pre-wrap' }}>{content}</pre>
          ),
        }),
      },
      ...(fileMode
        ? [
            {
              title: '업로드 파일',
              align: 'center' as const,
              dataIndex: 'uploadFileUrl',
              render: (uploadFileUrl: string) => (
                <i
                  className="material-icons outlined black cursor"
                  onClick={() => {
                    if (uploadFileUrl.includes(',https://')) {
                      const uploadFileUrls = uploadFileUrl
                        .split(',https://')
                        .map((url, index) =>
                          index === 0 ? url : `https://${url}`,
                        );
                      if (
                        uploadFileUrls.some(
                          (url) =>
                            url.endsWith('.xlsx') ||
                            url.endsWith('.ai') ||
                            url.endsWith('.zip'),
                        )
                      ) {
                        downloadFiles(
                          uploadFileUrls.map((url) => ({
                            name: getFilenameFromUrl(url),
                            url,
                          })),
                        );
                      } else {
                        setUrl(uploadFileUrls);
                      }
                    } else {
                      if (
                        uploadFileUrl.endsWith('.xlsx') ||
                        uploadFileUrl.endsWith('.ai') ||
                        uploadFileUrl.endsWith('.zip')
                      ) {
                        downloadFile(uploadFileUrl);
                      } else {
                        setUrl(uploadFileUrl);
                      }
                    }
                  }}
                >
                  description
                </i>
              ),
            },
          ]
        : []),
      {
        title: '입력자 명',
        align: 'center' as const,
        render: ({
          name,
          companyName,
        }: {
          name: string;
          companyName: string;
        }) => `${name} (${companyName})`,
      },
      {
        title: '입력 일시',
        dataIndex: 'registerDt',
        align: 'center' as const,
        render: (registerDt: string) => registerDt.replace('T', ' '),
      },
    ],
    [fileMode],
  );
  const handleClickShowLog = useCallback(() => {
    setSelectedField(fields[0]);
  }, []);
  const handleClickClose = useCallback(() => {
    setSelectedField(null);
  }, []);

  return (
    <UpdateLogBlock>
      <PreviewModal />
      <Modal
        visible={selectedField !== null}
        width={800}
        footer={null}
        onCancel={handleClickClose}
      >
        <Typography.Title gutter={{ bottom: 24 }}>
          수정 이력 보기
        </Typography.Title>
        {fields && fields.length > 1 && (
          <Row gutter={[4, 4]}>
            {fields.map((field: any) => (
              <Col key={field.code}>
                <TabButton
                  selected={field.code === (selectedField as null | any)?.code}
                  onClick={() => setSelectedField(field)}
                >
                  {field.name}
                </TabButton>
              </Col>
            ))}
          </Row>
        )}
        <Table
          columns={columns}
          loading={loading}
          dataSource={documentLogs}
          rowKey={({ documentChangeLogId }) => {
            return documentChangeLogId;
          }}
          pagination={{ pageSize: 10 }}
          style={{ marginTop: 24 }}
        />
      </Modal>
      {fields && (
        <Button
          className="ant-btn-gray"
          icon={<i className="material-icons outlined md-18 gray">history</i>}
          onClick={handleClickShowLog}
        >
          수정 이력
        </Button>
      )}
    </UpdateLogBlock>
  );
};

export default UpdateLog;
