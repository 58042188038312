import styled from 'styled-components';
import { Button, Form, Input } from 'antd';

import { scrollToFirstErrorOption } from 'lib/consts';
import { useForceUpdate } from 'lib/hook';
import palette from 'lib/styles/palette';
import { exceptKoreanRule, requireRule } from 'lib/validate';
import { useCertificateMode } from 'hook/certificate';
import FooterBox from 'components/FooterBox';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import { Tip, Typography } from 'components/system';

const ProductStabilityTestBlock = styled.div`
  max-width: 520px;
  margin: 0 auto;
`;

const ProductStabilityTest = ({
  updateMode,
  readOnlyMode,
  form,
  submitLoading,
  onSubmit,
}: any) => {
  const forceUpdate = useForceUpdate();
  const certificateMode = useCertificateMode();
  return (
    <ProductStabilityTestBlock>
      <Form
        form={form}
        colon={false}
        layout="vertical"
        scrollToFirstError={scrollToFirstErrorOption}
        onFinish={onSubmit}
        onValuesChange={(changedValues) => {
          if ('temperatureRange2' in changedValues) {
            forceUpdate();
          }
        }}
      >
        <Form.Item
          label="온도 범위1 (고온)"
          name="temperatureRange1"
          normalize={(value) =>
            value.replace(/[^0-9~]/g, '').replace(/~~/g, '~')
          }
          required
          rules={[
            requireRule,
            {
              pattern: /(^0$)|(^[1-9][0-9]?$)|(^[1-9][0-9]?~[1-9][0-9]?$)/,
              message: '올바르지 않은 형식입니다. 예시: 40~50',
            },
          ]}
        >
          <Input
            placeholder="예시: 40~50"
            addonAfter="℃"
            disabled={readOnlyMode}
          />
        </Form.Item>
        <Form.Item
          label="온도 범위2 (상온)"
          name="temperatureRange2"
          required
          rules={[
            requireRule,
            {
              pattern: /(^0$)|(^[1-9][0-9]?$)|(^[1-9][0-9]?~[1-9][0-9]?$)|(^ROOM TEMPERATURE$)|(^RT$)/,
              message:
                '올바르지 않은 형식입니다. 예시: 20~30 or ROOM TEMPERATURE or RT',
            },
          ]}
          normalize={(value) => value.toUpperCase()}
        >
          <Input
            placeholder="예시: 20~30 or ROOM TEMPERATURE or RT"
            addonAfter={
              form.getFieldValue('temperatureRange2') !== 'ROOM TEMPERATURE' &&
              form.getFieldValue('temperatureRange2') !== 'RT'
                ? '℃'
                : ' '
            }
            disabled={readOnlyMode}
          />
        </Form.Item>
        <Form.Item
          label="온도 범위3 (저온)"
          name="temperatureRange3"
          normalize={(value) =>
            value
              .replace(/[^0-9~-]/g, '')
              .replace(/~~/, '~')
              .replace(/--/, '-')
          }
          required
          rules={[
            requireRule,
            {
              pattern: /(^0$)|(^[-]?[1-9][0-9]?$)|(^(0|([-]?[1-9][0-9]?))~(0|([-]?[1-9][0-9]?))$)/,
              message: '올바르지 않은 형식입니다. 예시: -10~10',
            },
          ]}
        >
          <Input
            placeholder="예시: -10~10"
            addonAfter="℃"
            disabled={readOnlyMode}
          />
        </Form.Item>
        <Form.Item
          label={
            <>
              사이클 온도 범위
              <Tip
                trigger="click"
                icon={
                  <Button
                    style={{
                      height: 20,
                      fontSize: 12,
                      padding: '0 4px 0 8px',
                      border: `1px solid ${palette.inactive}`,
                      lineHeight: '18px',
                      letterSpacing: -0.4,
                      color: palette.text.gray,
                    }}
                  >
                    예시 참고
                    <i
                      className="material-icons md-12"
                      style={{
                        color: 'inherit',
                        lineHeight: '18px',
                        marginRight: 0,
                      }}
                    >
                      search
                    </i>
                  </Button>
                }
                style={{ marginLeft: 8 }}
              >
                <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                  ① 45℃ /25℃(RT)/ -10℃
                </Typography.Text>
                <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                  ② CYCLE(5~40℃)
                </Typography.Text>
                <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                  ③ 50℃, 45℃, 25℃, 4℃
                </Typography.Text>
                <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                  ④ 1 cycle from -5℃ to 40℃ cycling in 24 hrs
                </Typography.Text>
                <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                  ⑤ 기타
                </Typography.Text>
              </Tip>
            </>
          }
          name="cycleTemperatureRange"
          required
          rules={[requireRule, exceptKoreanRule]}
        >
          <Input
            placeholder="예시 참고하여 자유롭게 입력"
            disabled={readOnlyMode}
          />
        </Form.Item>
        <FooterBox>
          <ReadOnlyBackButton readOnly={readOnlyMode}>
            <Button type="primary" loading={submitLoading} htmlType="submit">
              {!updateMode ? '등록' : !certificateMode ? '수정' : '보완 완료'}
            </Button>
          </ReadOnlyBackButton>
        </FooterBox>
      </Form>
    </ProductStabilityTestBlock>
  );
};

export default ProductStabilityTest;
