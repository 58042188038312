import { PrinterOutlined } from '@ant-design/icons';
import { Button, Col, message, Modal, Row } from 'antd';
import printJS from 'print-js';
import { useState } from 'react';
import styled from 'styled-components';

import FileList from 'components/file/FileList';
import FileViewer from 'components/file/FileViewer';
import { Typography } from 'components/system';
import palette from 'lib/styles/palette';
import { ISavedFile } from 'types/common';

const Container = styled.div`
  .ant-spin-container {
    height: 556px;
  }
`;

const FileListModal = <T extends ISavedFile>({
  title,
  files,
  onClose,
  onDelete,
}: {
  title: string;
  files: T[] | null;
  onClose: () => void;
  onDelete?: (file: T) => void;
}) => {
  const [previewedFile, setPreviewedFile] = useState<T | null>(null);
  const handleClickPrint = () => {
    if (!previewedFile) {
      message.warning('선택된 파일이 없습니다.');
      return;
    }

    printJS(decodeURIComponent(previewedFile.attachUrl));
  };

  return (
    <Modal
      title={title}
      footer={null}
      width={864}
      visible={files !== null}
      onCancel={onClose}
    >
      <Container>
        <Row gutter={16}>
          <Col flex={'1 1 50%'}>
            <FileList
              header={
                <Typography.Text type="secondary" gutter={{ bottom: 8 }}>
                  업로드 된 파일 목록
                </Typography.Text>
              }
              onFileClick={(file) => setPreviewedFile(file)}
              selectedFile={previewedFile}
              style={{ height: 556 }}
              files={files?.map((file) => ({
                ...file,
                url: file.attachUrl,
                name: file.filename,
              }))}
              onDelete={onDelete}
            />
          </Col>
          <Col flex={'1 1 50%'}>
            <FileViewer
              bodyStyle={{ height: 556 }}
              file={previewedFile?.attachUrl}
            />
          </Col>
        </Row>
        <Row gutter={8} style={{ marginTop: 8 }} justify="end">
          <Col>
            <Button
              icon={<PrinterOutlined style={{ color: palette.text.primary }} />}
              style={{ height: 32 }}
              onClick={handleClickPrint}
            >
              인쇄하기
            </Button>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

export default FileListModal;
