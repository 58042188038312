import ClinicalTrialOngoing from 'components/brand/clinicalTrial/ClinicalTrialOngoing';
import PageTemplate from 'templates/PageTemplate';

const notices = [
  '각각의 진행 단계를 클릭하시면, 해당 제품의 임상 진행 단계를 확인하실 수 있습니다.',
  '진행하시는 임상시험 제품에 대한 견적서는 마이페이지의 계약 보관함에서 확인하실 수 있습니다.',
  '임상시험 진행이 완료된 제품의 시험 레포트는 해당 항목에서 미리보기 또는 다운로드가 가능합니다.',
];

const ClinicalTrialOngoingPage = () => {
  return (
    <PageTemplate title="임상시험 진행 현황" back={false} notices={notices}>
      <ClinicalTrialOngoing />
    </PageTemplate>
  );
};

export default ClinicalTrialOngoingPage;
