import { Col, Row } from 'antd';
import Typography from 'components/system/general/Typography';
import styled from 'styled-components';

const RegisterSuccessPageBlock = styled.div`
  padding-top: 40px;
`;

const RegisterSuccessPage = () => {
  return (
    <RegisterSuccessPageBlock>
      <Row justify="center" align="middle" gutter={[16, 16]}>
        <Col xs={{ flex: '0 0 100%' }} sm={{ flex: 'auto' }}>
          <i className="material-icons outlined primary md-96">check</i>
        </Col>
        <Col xs={{ flex: '0 0 100%' }} sm={{ flex: 'auto' }}>
          <Typography.Headline type="secondary">
            회원가입이 요청되었습니다.
            <br />
            조금만 기다려 주세요.
          </Typography.Headline>
        </Col>
      </Row>
      <Typography.Text
        type="secondary"
        color="gray"
        gutter={{ top: 24 }}
        style={{ maxWidth: 260, marginLeft: 'auto', marginRight: 'auto' }}
      >
        관리자 승인 후, 메일로 아이디를 전달드립니다.
        <br /> 아이디는 사업자등록번호로 부여됩니다.
      </Typography.Text>
    </RegisterSuccessPageBlock>
  );
};

export default RegisterSuccessPage;
