import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

export const useManuMode = () => {
  const menuMode = useSelector(
    ({ company }) => company.company?.isManufacturer,
  );
  return menuMode;
};

export const useRetailerMode = () => {
  const history = useHistory();
  const retailerMode = history.location.pathname.startsWith('/retailer/');

  return retailerMode;
};
