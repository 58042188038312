import { useEffect } from 'react';
import { useState } from 'react';

import FileUpload from 'components/file/FileUpload';

const FileUploadContainer = ({
  files,
  viewerVisible = true,
  accept,
  readOnly = false,
  max,
  style,
  onUpload,
  onDelete,
}: {
  files: any;
  viewerVisible?: boolean;
  accept?: string;
  readOnly?: boolean;
  max?: number;
  style?: React.CSSProperties;
  onUpload: (file: File) => boolean;
  onDelete?: (file: any) => boolean;
}) => {
  const [previewedFile, setPreviewedFile] = useState<any>(null);
  const handleUpload = (file: File) => {
    const success = onUpload(file);
    if (success !== false) {
      setPreviewedFile(file);
    }
  };
  const handleFileClick = (file: any) => {
    setPreviewedFile(file);
  };
  const handleDelete = (file: any) => {
    if (!onDelete) return;
    const result = onDelete(file);
    if (!result && file.name === previewedFile?.name) {
      setPreviewedFile(null);
    }
  };

  useEffect(() => {
    if (!previewedFile) {
      if (Array.isArray(files)) {
        if (files.length > 0) {
          setPreviewedFile(files[0]);
        }
      } else {
        setPreviewedFile(files);
      }
    }
  }, [files]);
  return (
    <FileUpload
      files={files}
      previewedFile={previewedFile}
      viewerVisible={viewerVisible}
      accept={accept}
      readOnly={readOnly}
      max={max}
      style={style}
      onUpload={handleUpload}
      onFileClick={handleFileClick}
      onDelete={handleDelete}
    />
  );
};

export default FileUploadContainer;
