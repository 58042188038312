import { useParams } from 'react-router-dom';

import ProductPageTemplate from 'templates/ProductPageTemplate';
import ProductBasic from 'components/manufacturer/product/ProductBasic';

const notices = [
  '하단의 로트넘버에는 인증을 위한 서류를 발행하기 위한 것이므로, 모든 테스트가 완료된 로트번호를 입력해 주세요.',
  '브랜드사가 인증을 진행하고자 하는 국가를 선택해 주세요. 선택한 국가에 따라 입력해야 하는 필수 서류가 변경될 수 있으며 중복 선택이 가능합니다.',
  "국가의 경우, 제품 목록에서 '등록 완료' 버튼을 누르기 전까지만 수정하실 수 있습니다.",
];

const ProductBasicPage = () => {
  const params = useParams<{ productId: string }>();
  const productId = Number(params.productId) || undefined;
  return (
    <ProductPageTemplate
      subtitle="기본정보"
      notices={notices}
      hasSkip={false}
      back={typeof productId !== 'undefined'}
    >
      <ProductBasic productId={productId} />
    </ProductPageTemplate>
  );
};

export default ProductBasicPage;
