import { createFormData } from 'lib/file';
import { IAPIResponse } from 'types/common';
import {
  ICertificationItemAdd,
  ICertificationItemGet,
  ICertificationItemUpdate,
} from 'types/material/certification';
import client from '../client';

export const getCertificationItems = (materialId: number) =>
  client.get<IAPIResponse<ICertificationItemGet[]>>(
    `/material/cert/${materialId}`,
  );

export const addCertificationItems = ({
  materialId,
  certificationItems,
}: {
  materialId: number;
  certificationItems: ICertificationItemAdd[];
}) =>
  client.post(
    `/material/cert/${materialId}`,
    createFormData({ list: certificationItems }),
  );

export const updateCertificationItems = ({
  materialId,
  certificationItems,
}: {
  materialId: number;
  certificationItems: ICertificationItemUpdate[];
}) =>
  client.patch(
    `/material/cert/${materialId}`,
    createFormData({ certList: certificationItems }),
  );
