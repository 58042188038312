import styled from 'styled-components';
import { Col, Row } from 'antd';

import { Typography } from 'components/system';
import ContractGuide from 'components/contract/ContractGuide';

const EstimatePaymentBlock = styled.div``;

const EstimatePayment = () => {
  return (
    <EstimatePaymentBlock>
      <Row
        justify="center"
        align="middle"
        gutter={[16, 16]}
        style={{ marginBottom: 120 }}
      >
        <Col xs={{ flex: '0 0 100%' }} sm={{ flex: 'auto' }}>
          <i className="material-icons outlined primary md-96">check</i>
        </Col>
        <Col xs={{ flex: '0 0 100%' }} sm={{ flex: 'auto' }}>
          <Typography.Headline type="secondary">
            계약이 체결되었습니다.
            <br />
            결제 진행 및 계약서를 송부해 주세요.
          </Typography.Headline>
        </Col>
      </Row>
      <ContractGuide />
    </EstimatePaymentBlock>
  );
};

export default EstimatePayment;
