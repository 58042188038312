import { Button, ButtonProps } from 'antd';
import palette from 'lib/styles/palette';
import styled, { css } from 'styled-components';

interface Props extends ButtonProps {
  selected: boolean;
}

const StyledTabButton = styled(Button)<{ selected: boolean }>`
  &.ant-btn {
    padding: 10px 16px;
    border-color: ${palette.inactive};
    color: #9195a5;
    height: 40px;
    padding: 9px 16px;
    line-height: 20px;

    &:hover {
      border-color: ${palette.inactive};
      color: #9195a5;
    }

    ${({ selected }) =>
      selected &&
      css`
        /* font-weight: 500; */
        border-color: #f1f4f6;
        background-color: #f1f4f6;
        color: #425968;
        cursor: default;
        /* letter-spacing: -0.85px; */
        -webkit-text-stroke: 0.4px #425968;

        &:hover {
          border-color: #f1f4f6;
          color: #425968;
        }
      `}
  }
`;

const TabButton = (props: Props) => {
  return <StyledTabButton {...props}>{props.children}</StyledTabButton>;
};

export default TabButton;
