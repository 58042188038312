import { useMemo, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, message, Modal } from 'antd';

import {
  addProductChallengeTest,
  getProductChallengeTest,
  initializeProductChallengeTest,
  updateProductChallengeTest,
} from 'modules/product';
import { messages } from 'lib/consts';
import { getUndefinedFilteredObject, getUpdatingObject } from 'lib/form';
import { IProductChallengeTestForm } from 'types/product';
export const useChallengeTest = (productId: number, countryId?: number) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm<IProductChallengeTestForm>();
  const [isMergeBacteria, setIsMergeBacteria] = useState(false);
  const [isMergeYeast, setIsMergeYeast] = useState(false);
  const [isMergeMold, setIsMergeMold] = useState(false);
  const {
    currentProduct,
    getLoading,
    challengeTest,
    fetchLoading,
    updateLoading,
  } = useSelector(({ loading, product }: any) => ({
    currentProduct: product.currentProduct,
    challengeTest: product.challengeTest,
    fetchLoading: loading['product/ADD_PRODUCT_CHALLENGE_TEST'],
    getLoading: loading['product/GET_PRODUCT_CHALLENGE_TEST'],
    updateLoading: loading['product/UPDATE_PRODUCT_CHALLENGE_TEST'],
  }));
  const [checkMap, setCheckMap] = useState({
    isSaureus: true,
    isEcoli: true,
    isPaeruginosa: true,
    isBacillus: false,
    isEnterobacter: false,
  });
  const updateMode = challengeTest !== null;
  const selectedBacteriaCount = useMemo(
    () => Object.values(checkMap).filter((checked) => checked).length,
    [checkMap],
  );
  const toggleIsMergeBacteria = () => setIsMergeBacteria(!isMergeBacteria);
  const toggleIsMergeYeast = () => {
    setIsMergeYeast(!isMergeYeast);
  };
  const toggleIsMergeMold = () => setIsMergeMold(!isMergeMold);
  const toggleCheck = (key: keyof typeof checkMap) => {
    setCheckMap({ ...checkMap, [key]: !checkMap[key] });
  };

  const fetchProductChallengeTest = useCallback(
    (formValues: IProductChallengeTestForm) => {
      if (!updateMode) {
        if (!formValues.isReportExist) {
          dispatch(
            addProductChallengeTest({
              productId: currentProduct.productId,
              isReportExist: formValues.isReportExist,
            }),
          );
        } else {
          dispatch(
            addProductChallengeTest({
              productId: currentProduct.productId,
              ...formValues,
              isMergeBacteria,
              isMergeYeast,
              isMergeMold,
              isSaureus: checkMap.isSaureus,
              isEcoli: checkMap.isEcoli,
              isPaeruginosa: checkMap.isPaeruginosa,
              isBacillus: checkMap.isBacillus,
              isEnterobacter: checkMap.isEnterobacter,
            }),
          );
        }
      } else {
        const { isReportExist } = formValues;
        if (!isReportExist) {
          if (isReportExist !== challengeTest.isReportExist) {
            dispatch(
              updateProductChallengeTest({
                productChallengeTestId: challengeTest.productChallengeTestId,
                isReportExist,
              }),
            );
            return;
          }
        } else {
          const updatingValues = {
            ...getUpdatingObject(
              {
                ...formValues,
                isMergeBacteria,
                isMergeYeast,
                isMergeMold,
                ...checkMap,
              },
              challengeTest,
            ),
          };
          const filteredValues = getUndefinedFilteredObject(updatingValues);
          if (Object.keys(filteredValues).length !== 0) {
            dispatch(
              updateProductChallengeTest({
                productChallengeTestId: challengeTest.productChallengeTestId,
                ...filteredValues,
              }),
            );
            return;
          }
        }
        return message.warn(messages.NO_NEED_TO_UPDATE);
      }
    },
    [
      currentProduct,
      checkMap,
      isMergeBacteria,
      isMergeYeast,
      isMergeMold,
      challengeTest,
    ],
  );

  const showConfirmModal = useCallback(
    (formValues: IProductChallengeTestForm) => {
      Modal.confirm({
        width: 420,
        style: { textAlign: 'center' },
        content: (
          <>
            추후 인증 진행시 위의 기준에 적합한지
            <br />
            판단하기 위한 근거자료를 요청할 수 있습니다.
            <br />
            {!updateMode ? '등록' : '수정'}하시겠습니까?
          </>
        ),
        okText: '등록',
        cancelText: '취소',
        icon: null,
        onOk: () => {
          fetchProductChallengeTest(formValues);
        },
      });
    },
    [updateMode, fetchProductChallengeTest],
  );

  const onSubmit = useCallback(
    (formValues: IProductChallengeTestForm) => {
      if (
        !formValues.isReportExist &&
        formValues.isReportExist !== challengeTest?.isReportExist
      ) {
        showConfirmModal(formValues);
      } else {
        fetchProductChallengeTest(formValues);
      }
    },
    [challengeTest, showConfirmModal, fetchProductChallengeTest],
  );

  useEffect(() => {
    dispatch(
      getProductChallengeTest({
        productId,
        countryId,
      }),
    );
    return () => {
      dispatch(initializeProductChallengeTest());
    };
  }, []);
  useEffect(() => {
    if (updateMode && challengeTest) {
      form.setFieldsValue(challengeTest);
      if (challengeTest.isReportExist) {
        setIsMergeBacteria(challengeTest.isMergeBacteria);
        setIsMergeYeast(challengeTest.isMergeYeast);
        setIsMergeMold(challengeTest.isMergeMold);
        setCheckMap({
          isSaureus: challengeTest.isSaureus,
          isEcoli: challengeTest.isEcoli,
          isPaeruginosa: challengeTest.isPaeruginosa,
          isBacillus: challengeTest.isBacillus,
          isEnterobacter: challengeTest.isEnterobacter,
        });
      }
    }
  }, [updateMode, challengeTest]);

  useEffect(() => {
    if (!updateMode && process.env.NODE_ENV === 'development') {
      form.setFieldsValue({
        isReportExist: false,
        bacteriaTemperature: 33.1,
        yeastTemperature: 24.1,
        moldTemperature: 25.1,
        saureus: 3.11,
        saureusExponent: 2,
        ecoli: 3.12,
        ecoliExponent: 3,
        paeruginosa: 3.13,
        paeruginosaExponent: 4,
        enterobacter: 3.15,
        enterobacterExponent: 6,
        bacillus: 3.14,
        bacillusExponent: 5,
        yeast: 2.16,
        yeastExponent: 7,
        mold: 2.17,
        moldExponent: 8,
      });
    }
  }, []);
  return useMemo(
    () => ({
      updateMode,
      form,
      getLoading,
      fetchLoading,
      updateLoading,
      selectedBacteriaCount,
      checkMap,
      isMergeBacteria,
      isMergeYeast,
      isMergeMold,
      toggleIsMergeBacteria,
      toggleIsMergeYeast,
      toggleIsMergeMold,
      toggleCheck,
      onSubmit,
    }),
    [
      updateMode,
      getLoading,
      fetchLoading,
      updateLoading,
      updateMode,
      checkMap,
      isMergeYeast,
      isMergeBacteria,
      isMergeMold,
    ],
  );
};
