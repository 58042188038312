import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Modal } from 'antd';

import FileUploadContainer from 'containers/file/FileUploadContainer';
import { messages } from 'lib/consts';
import palette from 'lib/styles/palette';
import { ISaleInfo, ISaleInfoAttachUpload } from 'types/brand/vcrp';

const ProductSaleInfoUpload = ({
  value = [],
  onChange,
  saleInfo,
  deleteAttachIds,
  setDeleteAttachIds,
  readOnly,
}: {
  value?: File[];
  onChange?: (e: { target: { value?: File[] } }) => void;
  saleInfo: ISaleInfo | null;
  deleteAttachIds: number[];
  setDeleteAttachIds: Dispatch<SetStateAction<number[]>>;
  readOnly: boolean;
}) => {
  const [visible, setVisible] = useState(false);
  const [files, setFiles] = useState<File[]>(value);

  useEffect(() => {
    if (value?.length || files.length) {
      onChange?.({
        target: { value: files },
      });
    }
  }, [files]);

  const handleUpload = (file: File) => {
    if (value?.find((item) => item.name === file.name)) {
      message.warning(messages.DUPLICATE_FILE_NAME);
      return false;
    }

    setFiles((draft) => draft.concat(file));
    return true;
  };

  const handleDelete = (file: File | ISaleInfoAttachUpload) => {
    if ('productSaleInfoAttachId' in file) {
      setDeleteAttachIds([...deleteAttachIds, file.productSaleInfoAttachId]);
    }

    setFiles((draft) => draft.filter((item) => item !== file));
    return false;
  };

  return (
    <>
      <Button onClick={() => setVisible(true)} style={{ width: 120 }}>
        <UploadOutlined style={{ color: palette.primary }} />
        {saleInfo && !saleInfo.isOnlineSale ? '파일 보기' : '파일 선택'}
      </Button>
      <Modal
        title="미국내 판매 증빙 서류"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        destroyOnClose
        maskClosable={false}
        width={864}
      >
        <FileUploadContainer
          accept=".pdf,.jpg,.jpeg,.zip"
          max={10}
          files={value}
          onUpload={handleUpload}
          onDelete={handleDelete}
          readOnly={readOnly}
        />
      </Modal>
    </>
  );
};

export default ProductSaleInfoUpload;
