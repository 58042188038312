import { useQuery } from 'react-query';

import * as buyerApi from 'lib/api/buyer';
import { useMemo } from 'react';
import { useCompany } from 'service/company';

export const useNotarizationBuyers = (countryCode: string | null) => {
  const { companyId } = useCompany();
  const { data: buyers = [], isFetching: getLoading } = useQuery(
    ['brand/buyer/getNotarizationBuyers', companyId, countryCode],
    () =>
      buyerApi.getBuyersByCountryCode({ companyId, countryCode: countryCode! }),
    { select: (res) => res.data.result, enabled: countryCode !== null },
  );
  return useMemo(() => ({ buyers, getLoading }), [buyers, getLoading]);
};

export const useProductBuyers = (
  params:
    | { productId: number; countryId: number }
    | { companyId: number; buyerId?: number },
) => {
  const { data: productBuyers = [], isFetching: getLoading } = useQuery(
    ['brand/buyer/getProductBuyers', params],
    () =>
      buyerApi.getProductBuyers(
        'companyId' in params
          ? { companyId: params.companyId, buyerId: params.buyerId! }
          : { productId: params.productId, countryId: params.countryId },
      ),
    {
      enabled:
        ('buyerId' in params && typeof params.buyerId !== 'undefined') ||
        'productId' in params,
      select: (res) => res.data.result,
    },
  );

  return useMemo(() => ({ productBuyers, getLoading }), [
    productBuyers,
    getLoading,
  ]);
};
