import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';
import { Spin, message } from 'antd';
import { useState } from 'react';

import { Typography } from 'components/system';
import { RootState } from 'modules';
import {
  ADD_INGREDIENT_TO_BOX,
  deleteIngredientToBox,
} from 'modules/material/ingredient';
import IngredientCompareModal from './IngredientCompareModal';

const StyledBox = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 160px;
  padding: 32px 30px;
  border-radius: 4px;
  background-color: #eff1f8;
`;

const StyledSemiBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 120px;
  padding: 13px 4px 12px 4px;
  border-radius: 4px;
  border: solid 1px #d3d3d3;
  background-color: #fff;

  margin-bottom: 8px;
`;

const StyledCloseOutlined = styled(CloseOutlined)`
  position: absolute;
  top: 4px;
  right: 4px;
`;

const StyledButton = styled.div`
  padding: 10px 16px;
  border-radius: 4px;
  background-color: #00b9e1;
  color: #fff;
  margin-top: 8px;
  cursor: pointer;
  width: 100px;
  text-align: center;
`;

const WaterDropImg = styled.img`
  width: 36px;
  height: 64px;
`;

const IngredientCompareBox = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  const { ingredientBoxs, addLoading } = useSelector(
    ({ ingredient, loading }: RootState) => ({
      ingredientBoxs: ingredient.ingredientOfBoxs,
      addLoading: loading[ADD_INGREDIENT_TO_BOX],
    }),
    shallowEqual,
  );

  const handleCompare = () => {
    if (ingredientBoxs.length === 0) {
      return message.warning('비교할 성분을 1개 이상 담아주세요.');
    }
    setIsOpen(true);
  };

  return (
    <Spin spinning={!!addLoading}>
      <StyledBox>
        <Typography.Text color="slate" medium style={{ marginBottom: 16 }}>
          성분 비교함
        </Typography.Text>
        {Array.from(Array(3), (_, index) => {
          return (
            <StyledSemiBox key={index}>
              {ingredientBoxs[index] && (
                <StyledCloseOutlined
                  onClick={() => {
                    dispatch(deleteIngredientToBox(index));
                  }}
                />
              )}
              <WaterDropImg
                src={`https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/${
                  ingredientBoxs[index] ? 'waterdrop_full' : 'waterdrop_empty'
                }.png`}
                alt="EWG"
              />
              {ingredientBoxs[index] && (
                <Typography.Text
                  type="secondary"
                  style={{
                    width: 84,
                    marginTop: 11,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {ingredientBoxs[index].nameKo}
                </Typography.Text>
              )}
            </StyledSemiBox>
          );
        })}
        <StyledButton onClick={handleCompare}>비교하기</StyledButton>
      </StyledBox>
      <IngredientCompareModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </Spin>
  );
};

export default IngredientCompareBox;
