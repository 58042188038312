import styled from 'styled-components';

import { Typography } from 'components/system';
import { s3AssetDomain } from 'lib/consts';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledImg = styled.img`
  width: 360px;
  height: 260px;
  margin: 0 auto;
`;
const RequestSuccess = () => {
  return (
    <Container>
      <StyledImg
        src={`${s3AssetDomain}/request-success.png`}
        alt="requestSuccess"
      />
      <Typography.Headline
        type="secondary"
        align="center"
        gutter={{ bottom: 16, top: 80 }}
      >
        견적 요청이 완료되었습니다.
        <br />
        조금만 기다려주세요:)
      </Typography.Headline>
      <Typography.Text type="secondary" color="gray">
        영업일 기준 최대 24시간 내로 임상 전문 컨설턴트가 연락드릴 예정입니다.
      </Typography.Text>
    </Container>
  );
};

export default RequestSuccess;
