import { ChangeEvent } from 'react';
import styled from 'styled-components';
import { Button, Space, Upload } from 'antd';

import FileViewer from 'components/file/FileViewer';
import Typography from 'components/system/general/Typography';
import { UploadOutlined } from '@ant-design/icons';

const FileUploadBlock = styled.div``;

const FileUpload = ({
  value,
  onChange,
  readOnly = false,
}: {
  value?: File | { name: string; url: string };
  onChange?: (e: ChangeEvent<any>) => void;
  readOnly: boolean;
}) => {
  return (
    <FileUploadBlock>
      {!readOnly && (
        <Space>
          <Upload
            accept=".pdf"
            itemRender={() => null}
            beforeUpload={(file) => {
              onChange?.({ target: { value: file } } as ChangeEvent<any>);
              return false;
            }}
          >
            <Button icon={<UploadOutlined />} style={{ width: 120 }}>
              파일 선택
            </Button>
          </Upload>
          {value && (
            <Typography.Text type="secondary">{value.name}</Typography.Text>
          )}
        </Space>
      )}
      {value && (
        <FileViewer
          file={value}
          style={{ maxWidth: 520, marginTop: !readOnly ? 16 : 0 }}
        />
      )}
    </FileUploadBlock>
  );
};

export default FileUpload;
