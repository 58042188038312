import { Col, Row, Select, Spin } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';

import { Typography } from 'components/system';
import history from 'lib/history';
import path from 'lib/path';
import { deviceSize } from 'lib/styles';
import palette from 'lib/styles/palette';
import { useIngredient } from 'service/material/ingredient';
import { IIngredientSearchResult } from 'types/material/ingredient';
import { IAPIPageableResult } from 'types/common';

const StyledSelect = styled(Select)<{ width?: number }>`
  .ant-select-selection-placeholder {
    display: flex;
    text-align: center;
  }

  .ant-select-arrow {
    top: 40%;
    font-size: 20px;
    right: 20px;
    color: #a2a2a2;
  }

  width: ${(props) => (props.width ? props.width : '500')}px;
  @media ${deviceSize['sm']} {
    width: 100%;
  }
`;

const ObserveArea = styled.div`
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface IProps {
  width?: number;
  setFirstKeyword?: React.Dispatch<React.SetStateAction<string>>;
  setSecondKeyword?: React.Dispatch<React.SetStateAction<string>>;
  setTableData?: React.Dispatch<
    React.SetStateAction<
      IAPIPageableResult<IIngredientSearchResult[]> | undefined
    >
  >;
  fromDetail?: boolean;
}

const IngredientSelect = ({
  width,
  setFirstKeyword,
  setSecondKeyword,
  setTableData,
  fromDetail,
}: IProps) => {
  const [ingredientResult, setIngredientResult] = useState<
    IAPIPageableResult<IIngredientSearchResult[]>
  >();
  const [page, setPage] = useState(1);
  const [input, setInput] = useState<string>('');
  const [selected, setSelected] = useState<string>();
  const { searchIngredient, searchIngredientLoading } = useIngredient();
  const { ref, inView } = useInView({
    threshold: 0,
  });

  const handleInfiniteScroll = _.debounce(
    ({
      pageNo,
      size,
      keyword,
    }: {
      pageNo: number;
      size: number;
      keyword: string;
    }) => {
      searchIngredient(
        {
          pageNo,
          size,
          keyword,
        },
        {
          onSuccess: (res) => {
            if (ingredientResult?.content) {
              const newContent = [
                ...ingredientResult.content,
                ...res.result.content,
              ];
              const newResult = { ...ingredientResult, content: newContent };
              setIngredientResult(newResult);
              setPage(page + 1);
            }
          },
        },
      );
    },
    200,
  );

  useEffect(() => {
    if (inView && input) {
      handleInfiniteScroll({
        pageNo: page,
        size: 10,
        keyword: input,
      });
    }
  }, [inView]);

  const handleSearch = _.debounce(
    ({ pageNo, keyword }: { pageNo: number; keyword: string }) => {
      if (keyword.trim().length === 0) {
        return null;
      } else {
        searchIngredient(
          {
            pageNo,
            size: 10,
            keyword,
          },
          {
            onSuccess: (res) => {
              setIngredientResult(res.result);
              setPage(2);
              setInput(keyword);
            },
          },
        );
      }
    },
    400,
  );

  const handleSelect = ({
    nameKo,
    kciaIngredientId,
    fromEnter,
  }: {
    nameKo?: string;
    kciaIngredientId?: number;
    fromEnter: boolean;
  }) => {
    if (!fromEnter) {
      setSelected(nameKo);
      history.push(`${path.material.ingredient.detail}/${kciaIngredientId}`);
    } else {
      setSelected(input);
      if (setFirstKeyword) setFirstKeyword(input);
      if (setSecondKeyword) setSecondKeyword('');
      if (setTableData) setTableData(ingredientResult);
    }
  };

  return (
    <StyledSelect
      showSearch
      showArrow
      placeholder={
        <Row justify="space-between" style={{ width: 470 }} align="middle">
          <Typography.Text type="secondary" color="disabled">
            성분명, INCI Name, CAS No., EC No. 검색 가능
          </Typography.Text>
        </Row>
      }
      loading={searchIngredientLoading}
      onSelect={
        fromDetail
          ? (value) => {
              const splitValue = (value as string).split('%');
              const kciaIngredientId = Number(splitValue[0]);
              const nameKo = splitValue[1];
              handleSelect({ kciaIngredientId, nameKo, fromEnter: false });
            }
          : undefined
      }
      onSearch={(value) => handleSearch({ pageNo: 1, keyword: value })}
      filterOption={(keyword, option) => {
        return option?.value?.toLowerCase().includes(keyword.toLowerCase());
      }}
      value={selected}
      listHeight={448}
      width={width}
      onKeyDown={
        !fromDetail
          ? (e) => {
              if (e.keyCode === 13) {
                handleSelect({ fromEnter: true });
              }
            }
          : undefined
      }
    >
      {ingredientResult?.content.map(
        ({ nameKo, kciaIngredientId, nameEn, casNo, ecNo }, index) => (
          <Select.Option
            value={`${kciaIngredientId}%${nameKo}%${nameEn}%${ecNo}%${casNo}`}
            key={kciaIngredientId}
          >
            <div
              onClick={() => {
                handleSelect({ nameKo, kciaIngredientId, fromEnter: false });
              }}
            >
              <Row gutter={8} justify="space-between">
                <Col>
                  <Typography.Text color="slate" medium>
                    {nameKo}
                  </Typography.Text>
                </Col>
                <Col>
                  <Typography.Text
                    style={{
                      marginTop: 4,
                      fontSize: 10,
                      color: palette.text.disabled,
                    }}
                  >
                    바로가기
                  </Typography.Text>
                </Col>
              </Row>
              <Typography.Text type="secondary" color="disabled">
                {nameEn}
              </Typography.Text>
            </div>
            {index === ingredientResult.content.length - 1 &&
              ingredientResult.content.length <
                ingredientResult.totalElements && (
                <ObserveArea ref={ref}>
                  <Spin spinning={true} />
                </ObserveArea>
              )}
          </Select.Option>
        ),
      )}
    </StyledSelect>
  );
};

export default IngredientSelect;
