import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button, Col, Form, Input, Row, Select, Spin } from 'antd';

import { exceptKoreanRule, numbersRule, requireRule } from 'lib/validate';
import palette from 'lib/styles/palette';
import { productSpecificationsOption } from 'lib/consts';
import { PreventEnterInput, Tip, Typography } from 'components/system';
import FooterBox from 'components/FooterBox';
import RouteLeaveGuard from 'components/RouteLeaveGuard';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import UpdateLog from 'components/product/UpdateLog';
import CorrectRequestMessageModal from 'components/CorrectRequestMessageModal';
import { useProductSpec } from 'service/brand/product/specifications';
import { EDocumentCode } from 'types/product';

const productInfoValidCodes = [
  'PS01-CO',
  'PS01-TA',
  'PS01-ST',
  'PS01-AA',
  'PS01-FOU',
  'PS01-POPIM',
  'PS01-APU',
];

const detailDescriptionValidCodes = [
  'PS01-HTU',
  'PS01-W',
  'PS01-DC',
  'PS01-MC',
  'PS01-M',
];

const ProductSpecBlock = styled.div``;

const TitleBullet = styled.span`
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  background-color: ${palette.primary};
  color: #fff;
  text-align: center;
  border-radius: 50%;
  font-size: 18px;
  vertical-align: top;
  line-height: 24px;
`;

const ProductSpec = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) => {
  const {
    form,
    updateMode,
    getLoading,
    saveLoading,
    fetchLoading,
    submitSuccess,
    onSaveDraft,
    onSubmit,
  } = useProductSpec(productId, countryId);
  const readOnlyMode = useSelector(
    ({ certificate }: any) => certificate.readOnlyMode,
  );
  return (
    <ProductSpecBlock>
      <RouteLeaveGuard
        when={!updateMode && !submitSuccess}
        title="이 페이지에서 나가시겠습니까?"
        description={
          '현재 페이지를 벗어나면 입력하신 데이터가 사라집니다.\n데이터가 유지되길 원하시는 경우, ‘계속 입력’ 버튼을 누른 후\n임시 저장 혹은 등록 하시길 바랍니다.'
        }
      />
      <Row justify="end" gutter={8} style={{ marginBottom: 16 }}>
        <Col>
          <CorrectRequestMessageModal documentCode={EDocumentCode.PS} />
        </Col>
      </Row>
      <Spin spinning={getLoading || fetchLoading}>
        <Form form={form} layout="vertical" onFinish={onSubmit}>
          <Row justify="space-between">
            <Col>
              <Typography.Title
                gutter={{ bottom: 24 }}
                style={{ lineHeight: 1, height: 24 }}
              >
                <TitleBullet>1</TitleBullet> 제품 정보 입력
              </Typography.Title>
            </Col>
            {updateMode && (
              <Col>
                <UpdateLog
                  productId={productId}
                  countryId={countryId}
                  documentCode={EDocumentCode.PS}
                  validCodes={productInfoValidCodes}
                />
              </Col>
            )}
          </Row>
          <Row gutter={8}>
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item
                name="countryOrigin"
                label="Country of Origin (원산지(영문))"
                rules={[requireRule, exceptKoreanRule]}
              >
                <Input placeholder="예시: Korea" disabled={readOnlyMode} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item
                required
                shouldUpdate={(prev, curr) =>
                  prev.productPeriodUnit !== curr.productPeriodUnit
                }
                label={
                  <Row gutter={4} align="middle">
                    <Col>Period of product in market (제품 총 유통기한)</Col>
                    <Col>
                      <Tip>
                        <Typography.Text type="secondary">
                          예시 : 30 month(s), 3year(s)
                        </Typography.Text>
                      </Tip>
                    </Col>
                  </Row>
                }
                style={{ marginBottom: 0 }}
              >
                {() => (
                  <Row gutter={8}>
                    <Col span={8}>
                      <Form.Item
                        name="productPeriod"
                        rules={[requireRule, numbersRule]}
                      >
                        <PreventEnterInput
                          maxLength={3}
                          disabled={readOnlyMode}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name={
                          typeof form.getFieldValue('productPeriodUnit') ===
                            'undefined' ||
                          form.getFieldValue('productPeriodUnit') === 'others'
                            ? 'productPeriodUnitDirect'
                            : 'productPeriodUnit'
                        }
                        rules={[requireRule]}
                      >
                        <Input
                          disabled={
                            form.getFieldValue('productPeriodUnit') !==
                              'others' || readOnlyMode
                          }
                          placeholder="단위"
                          maxLength={10}
                          style={
                            !readOnlyMode
                              ? {
                                  color: palette.text.black,
                                  backgroundColor: '#fff',
                                }
                              : undefined
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name="productPeriodUnit" initialValue="others">
                        <Select
                          options={
                            productSpecificationsOption.productPeriodOptions
                          }
                          disabled={readOnlyMode}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col xs={{ span: 24 }} sm={{ span: 8 }}>
              <Form.Item
                name="targetAge"
                label="Target Age (사용 연령)"
                rules={[requireRule, exceptKoreanRule]}
              >
                <Input
                  placeholder="예시: Over 5 / All Ages"
                  disabled={readOnlyMode}
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 8 }}>
              <Form.Item
                name="skinType"
                label="Skin Type (피부 타입)"
                rules={[requireRule]}
              >
                <Select
                  placeholder="피부 타입 선택"
                  options={productSpecificationsOption.skinTypeOptions}
                  disabled={readOnlyMode}
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 8 }}>
              <Form.Item
                name="applicationArea"
                label="Application Area (사용 부위)"
                rules={[requireRule, exceptKoreanRule]}
              >
                <Input disabled={readOnlyMode} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item
                label={
                  <Row gutter={4} align="middle">
                    <Col>
                      Applied per Use (평균 1회 사용량 - 성인 60kg 기준)
                    </Col>
                    <Col>
                      <Tip>
                        <Typography.Text type="secondary">
                          마스크팩과 같이 개봉 후 즉시 사용하는 제품의 경우,
                          <br /> 제품의 표시용량을 무게 단위 (g/mg)로 입력해
                          주세요.
                        </Typography.Text>
                      </Tip>
                    </Col>
                  </Row>
                }
                required
              >
                <Row gutter={8}>
                  <Col span={12}>
                    <Form.Item
                      name="appliedPerUse"
                      rules={[
                        requireRule,
                        {
                          pattern: /^([1-9](\d?)(\d?)|0)(\.\d{1,6})?$|(^100$)/,
                          message: '0~999, 소수점 6째자리 숫자만 입력 가능',
                        },
                      ]}
                    >
                      <PreventEnterInput
                        disabled={readOnlyMode}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="appliedPerUseUnit" rules={[requireRule]}>
                      <Select
                        placeholder="단위 선택"
                        disabled={readOnlyMode}
                        options={
                          productSpecificationsOption.appliedPerUseOptions
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item
                label={
                  <Row gutter={4} align="middle">
                    <Col>Frequency of Use (사용 빈도)</Col>
                    <Col>
                      <Tip>
                        <Typography.Text type="secondary">
                          소수점 셋째 자리 이상일 시, <br />
                          소수점 둘째 자리로 반올림해 주세요.
                        </Typography.Text>
                      </Tip>
                    </Col>
                  </Row>
                }
                required
              >
                <Row gutter={8}>
                  <Col>
                    <Form.Item
                      name="frequencyOfUse"
                      rules={[
                        requireRule,
                        {
                          pattern: /^([1-9](\d?)(\d?))(\.\d{1,2})?$|(^100$)/,
                          message: '0~999, 소수점 둘째 자리 숫자만 입력 가능',
                        },
                      ]}
                    >
                      <PreventEnterInput
                        disabled={readOnlyMode}
                        style={{ width: 216 }}
                        suffix="time(s)"
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Typography.Title
                      type="secondary"
                      style={{ lineHeight: 2.4 }}
                    >
                      /
                    </Typography.Title>
                  </Col>
                  <Col>
                    <Form.Item name="frequencyOfUseUnit" rules={[requireRule]}>
                      <Select
                        style={{ width: 216 }}
                        placeholder="기간 선택"
                        disabled={readOnlyMode}
                        options={
                          productSpecificationsOption.frequencyOfUseOptions
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" style={{ marginTop: 24 }}>
            <Col>
              <Typography.Title
                gutter={{ bottom: 24 }}
                style={{ lineHeight: 1, height: 24 }}
              >
                <TitleBullet>2</TitleBullet> 상세 설명 입력
              </Typography.Title>
            </Col>
            {updateMode && (
              <Col>
                <UpdateLog
                  productId={productId}
                  countryId={countryId}
                  documentCode={EDocumentCode.PS}
                  validCodes={detailDescriptionValidCodes}
                />
              </Col>
            )}
          </Row>
          <Form.Item
            label="How to Use (사용 방법)"
            name="useManual"
            rules={[requireRule, exceptKoreanRule]}
          >
            <Input.TextArea
              rows={3}
              placeholder="예시:  Apply an appropriate amount to face. Wash off after 15 minutes. Recommend to use 2~3 times a week"
              disabled={readOnlyMode}
            />
          </Form.Item>
          <Form.Item
            label="Warnings (주의사항)"
            name="warnings"
            rules={[requireRule, exceptKoreanRule]}
          >
            <Input.TextArea
              rows={3}
              placeholder="예시:  For external use only. Do not use it on damaged skin. Avoid contact with eyes. Discontinue use if signs of irritation appear.  Keep out of reach of children."
              disabled={readOnlyMode}
            />
          </Form.Item>
          <Form.Item
            label="Description &amp; Characteristics (제품 특징)"
            name="productFeature"
            rules={[requireRule, exceptKoreanRule]}
          >
            <Input.TextArea
              rows={3}
              placeholder="예시: Anti-wrinkle, Brightening, Moisturizing, Soothing"
              disabled={readOnlyMode}
            />
          </Form.Item>
          <Form.Item
            label="Marketing Claims (제품 문구)"
            name="marketingClaims"
            rules={[requireRule, exceptKoreanRule]}
          >
            <Input.TextArea
              rows={3}
              placeholder="제품 단상자에 적혀 있는 모든 마케팅 문구를 입력해 주세요."
              disabled={readOnlyMode}
            />
          </Form.Item>
          <Form.Item
            label="Miscellaneous (기타 사항)"
            name="miscellaneous"
            rules={[requireRule, exceptKoreanRule]}
          >
            <Input.TextArea
              rows={3}
              placeholder="브랜드 스토리, 메인 컨셉성분, 특별 기능을 입력해 주세요."
              disabled={readOnlyMode}
            />
          </Form.Item>
          <FooterBox style={{ paddingTop: 40 }}>
            <ReadOnlyBackButton readOnly={readOnlyMode}>
              {!updateMode && (
                <Button loading={saveLoading} onClick={onSaveDraft}>
                  임시 저장
                </Button>
              )}
              <Button loading={fetchLoading} type="primary" htmlType="submit">
                {!updateMode ? '등록' : '보완 완료'}
              </Button>
            </ReadOnlyBackButton>
          </FooterBox>
        </Form>
      </Spin>
    </ProductSpecBlock>
  );
};

export default ProductSpec;
