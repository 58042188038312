import { ERegisterSearchType } from 'types/material/register';
import Olive from 'asset/svg/olive.svg';
import Lala from 'asset/svg/lala.svg';
import Lohbs from 'asset/svg/lohbs.svg';
import Coopang from 'asset/svg/coopang.svg';
import Nh from 'asset/svg/nh.svg';
import Kurly from 'asset/svg/kurly.svg';
import Cm29 from 'asset/svg/cm29.svg';
import Shinsegae from 'asset/svg/shinsegae.svg';
import Gsshop from 'asset/svg/gsshop.svg';
import Lotte from 'asset/svg/lotte.svg';
import Hmall from 'asset/svg/hmall.svg';

export const messages = {
  REQUIRED_FIELD: '필수 항목',
  REQUIRED_FILE: '파일을 업로드해 주세요.',
  REQUIRED_FILES: '하나 이상의 파일을 업로드해 주세요.',
  REQUIRE_ONE_OR_MORE_FILES: '최소 한 개 이상의 파일이 필요합니다.',
  SHOULD_DUP_CHECK: '중복확인 버튼을 눌러주세요.',
  DUPLICATE_FILE_NAME: '같은 이름의 파일은 업로드 할 수 없습니다.',
  INVALID_DATE_FORMAT: '올바르지 않은 날짜 형식',
  INVALID_EMAIL_FORMAT: '올바르지 않은 이메일 형식',
  INVALID_BIZ_NUMBER_FORMAT: '올바르지 않은 사업자등록번호 형식',
  SHOULD_EXCEPT_KOREAN_OR_ALPHABET: '한글 또는 알파벳 입력 불가',
  SHOULD_EXCEPT_KOREAN_OR_NUMBER: '한글 또는 숫자 입력 불가',
  SHOULD_EXCEPT_KOREAN: '한글 입력 불가',
  SHOULD_EXCEPT_ALPHABET: '알파벳 입력 불가',
  SHOULD_NUMBER: '숫자만 입력 가능',
  SHOULD_ALPHABET_OR_SPACE: '알파벳만 입력 가능',
  SHOULD_ALPHABET_OR_NUMBER: '알파벳 또는 숫자만 입력 가능',
  PHONE_FORMAT: '숫자만 입력 가능, 8 ~ 12 글자',
  MOBILE_PHONE_FORMAT: '숫자만 입력 가능, 10 ~ 11 글자',
  CHINA_PHONE_FORMAT: '숫자만 입력 가능, 10 ~ 20 글자',
  NO_PERMISSION: '접근 권한이 없습니다.',
  NO_NEED_TO_UPDATE: '수정할 내역이 없습니다.',
  SHOULD_NOT_DUPLICATE: '중복 불가',
  SHOULD_NUMBER_OR_KOREAN: '숫자 또는 한글 입력 가능',
  SHOULD_KOREAN_OR_ALPHABET: '한글 또는 영문 입력 가능',
};

export const productSpecificationsOption = {
  productTypeOptions: [
    { label: 'Leave-On', value: 'Leave-On' },
    { label: 'Rinse-Off', value: 'Rinse-Off' },
  ],
  skinTypeOptions: [
    { label: 'Dry Skin Type (건성)', value: 'Dry Skin Type' },
    { label: 'Neutral Skin Type (중성)', value: 'Neutral Skin Type' },
    { label: 'Oily Skin Type (지성)', value: 'Oily Skin Type' },
    { label: 'Combination Skin Type (복합성)', value: 'Combination Skin Type' },
    { label: 'Sensitive Skin Type (민감성)', value: 'Sensitive Skin Type' },
    { label: 'All Skin Type (모든 피부 타입)', value: 'All Skin Type ' },
  ],
  productPeriodOptions: [
    { label: 'month(s)', value: 'month(s)' },
    { label: 'year(s)', value: 'year(s)' },
    { label: '직접입력', value: 'others' },
  ],
  packagingUnitOptions: [
    { label: 'CT', value: 'CT' },
    { label: 'PKG', value: 'PKG' },
    { label: '직접입력', value: 'others' },
  ],
  cartonUnitOptions: [
    { label: 'ea', value: 'ea' },
    { label: 'pcs', value: 'pcs' },
    { label: '직접입력', value: 'others' },
  ],
  cartonWeightUnitOptions: [
    { label: 'kg', value: 'kg' },
    { label: '직접입력', value: 'others' },
  ],
  cartonSizeUnitOptions: [
    { label: 'mm', value: 'mm' },
    { label: 'cm', value: 'cm' },
    { label: 'inch', value: 'inch' },
    { label: '직접입력', value: 'others' },
  ],
  appliedPerUseOptions: [
    { label: 'mg', value: 'mg' },
    { label: 'g', value: 'g' },
  ],
  frequencyOfUseOptions: [
    { label: 'day (1일)', value: 'day' },
    { label: 'week (7일)', value: 'week' },
    { label: 'month (30일)', value: 'month' },
    { label: 'year (365일)', value: 'year' },
  ],
};

export const scrollToFirstErrorOption = {
  behavior: (actions: any) => {
    const { el, top }: { el: Element; top: number } = { ...actions?.[0] };
    if (el && el.scrollTop > top) {
      el.scrollTop = top - 120;
    }
  },
};

export const contractDocumentKeyNameMap = {
  BANK_BOOK: '통장 사본',
  CBPR: '사업자등록증 사본',
  ToS: '이용 약관',
  NDA: '비밀유지 계약서',
  STANDARD_CONTRACT: '인증대행 계약서',
};

export const defaultMaterialEfficacies = [
  '가려움 방지',
  '각질 케어',
  '모공 케어',
  '모발 보호',
  '보습',
  '안티에이징',
  '자극 완화',
  '자외선 차단',
  '체취 완화',
  '탈모 완화',
  '트러블 완화',
  '피부 보호',
  '피부 진정',
  '피부 탄력',
  '피지 조절',
  '항균',
  '항산화',
  '항알러지',
  '염증 완화',
  '화이트닝',
  '피부 영양',
];

export const defaultMaterialFormulaPurposes = [
  'pH 조절제',
  '자외선차단제',
  '가용화제',
  '각질 제거제',
  '계면활성제',
  '금속이온봉쇄제',
  '점도조절제',
  '네일',
  '보습제',
  '산화방지제',
  '소포제',
  '수분 방지제',
  '스크럽제',
  '스킨 컨디셔닝',
  '용매',
  '유화제',
  '착색제',
  '피막형성제',
  '필름 형성',
  '향료',
  '헤어 원료',
  '헤어 컨디셔닝',
];

export const rawMaterialSpecialBrandOptions = [
  {
    label: 'Amazon',
    value: 'SB01',
  },
  {
    label: 'Walmart',
    value: 'SB02',
  },
  {
    label: 'Sam’s Club',
    value: 'SB03',
  },
  {
    label: 'LVMH',
    value: 'SB04',
  },
  {
    label: 'L’oreal',
    value: 'SB05',
  },
  {
    label: 'Chanel',
    value: 'SB06',
  },
  {
    label: 'L’Occitane',
    value: 'SB07',
  },
  {
    label: 'Hermès',
    value: 'SB08',
  },
  {
    label: 'Lush',
    value: 'SB09',
  },
  {
    label: 'Gucci',
    value: 'SB10',
  },
  {
    label: 'Prada',
    value: 'SB11',
  },
  {
    label: 'Dolce & Gabbana',
    value: 'SB12',
  },
  {
    label: 'Estée Lauder',
    value: 'SB13',
  },
  {
    label: 'Johnson & Johnson',
    value: 'SB14',
  },
  {
    label: 'P&G',
    value: 'SB15',
  },
  {
    label: 'Aēsop',
    value: 'SB16',
  },
];

interface ICountryCodeMap {
  [key: string]: string;
}

export const countryCodeMap: ICountryCodeMap = {
  EU: 'eu',
  걸프연합: 'gu',
  남아프리카공화국: 'za',
  남아프리카: 'za',
  뉴질랜드: 'nz',
  대만: 'tw',
  러시아: 'ru',
  미국: 'us',
  브라질: 'br',
  싱가포르: 'sg',
  아르헨티나: 'ar',
  아세안: 'aa',
  '아세안(개별국)': 'aa',
  인도: 'in',
  인도네시아: 'id',
  일본: 'jp',
  중국: 'cn',
  카자흐스탄: 'kz',
  캄보디아: 'kh',
  캐나다: 'ca',
  타이: 'th',
  터키: 'tr',
  필리핀: 'ph',
  한국: 'kr',
};

export const materialDocumentCodeWithPathMap = {
  MDI01: 'basic', // 원료 기본 정보
  MCAS01: 'certification', // 인증 및 특이사항
  MCI01: 'composition-information', // Composition Information
  COA01: 'coa', // Certificate Of Analysis
  MSDS01: 'msds', // Material Safety Data Sheet
  ALLE01: 'allergens-list', // Allergens List
  IFRA01: 'ifra-certificate', // IFRA
  MMD01: 'marketing', // 마케팅 자료
  CLOD01: 'etc-data', // 기타 자료 (클라우드)
};

export const coaPhaseExampleMap = {
  ALL: '(모든 성상의 공통 검사 항목을 입력)',
  SOLID: '(왁스, 조각 등)',
  LIQUID: '(겔, 오일, 밤 등)',
  POWDER: '(분말, 가루 등)',
  CUSTOMIZED: '검사 항목 직접 입력',
};

export const COMPOSITION_INFORMATION_COLUMNS = [
  'NO',
  '국문성분명',
  'INCI Name',
  'WT %',
  'CAS NO.',
  'EC No.',
  'Composition Range',
  'Function',
  'Function 2',
  'Function 3',
  '구체적인 추출부위',
  '비고',
];

export const registerManageSearchTypeOptions = [
  { label: '전체', value: ERegisterSearchType.ALL },
  { label: '원료명', value: ERegisterSearchType.MATERIAL_NAME_EN },
  {
    label: '실 생산자명',
    value: ERegisterSearchType.ORIGIN_MANUFACTURE_NAME,
  },
];

export const totalNumOfServiceCountries = 35;

export const retailerImages = [
  Olive,
  Lala,
  Lohbs,
  Coopang,
  Coopang,
  Nh,
  Kurly,
  Cm29,
  Shinsegae,
  Gsshop,
  Lotte,
  Gsshop,
  Hmall,
];

export const s3AssetDomain =
  'https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image';
