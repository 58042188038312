import { useQuery } from 'react-query';
import * as countryApi from 'lib/api/country';
import { useMemo } from 'react';

export const useNotarizationCountries = () => {
  const {
    data: notarizationCountries = [],
    isFetching: getLoading,
  } = useQuery(
    'country/getNotarizationCountries',
    countryApi.getNotarizationCountries,
    { select: (res) => res.data.result },
  );
  return useMemo(() => ({ notarizationCountries, getLoading }), [
    notarizationCountries,
    getLoading,
  ]);
};

export const useCountries = () => {
  const { data: countries = [] } = useQuery(
    'country/getCountries',
    countryApi.getCountries,
    { select: (res) => res.data.result, staleTime: Number.MAX_VALUE },
  );
  return countries;
};

export const useCountryNameKo = (countryId: number) => {
  const countries = useCountries();
  return useMemo(
    () =>
      countries.find((country) => country.countryId === countryId)
        ?.countryNameKo || '',
    [countries, countryId],
  );
};

export const useCountryCode = (countryId: number) => {
  const countries = useCountries();
  return useMemo(
    () =>
      countries.find((country) => country.countryId === countryId)
        ?.countryCode || '',
    [countries, countryId],
  );
};
