const path = {
  main: '/',
  login: '/login',
  register: {
    root: '/register',
    type: '/register/type',
    terms: '/register/terms',
    form: '/register/form',
    success: '/register/success',
  },
  cs: {
    notice: '/cs/notices',
  },
  my: '/my',
  product: {
    root: '/product',
    ready: '/product-ready',
    registered: '/product-registered',
    import: '/product-import',

    basic: '/product/basic',
    list: '/product/list',
    country: '/product/country',
    cat: '/product/category',
    phy: '/product/physical-property',
    che: '/product/chemical-property',
    mic: '/product/microbiological-property',
    formbr: '/product/product-formula-breakdown',
    formbrc: '/product/product-formula-breakdown-china',
    art: '/product/artwork',
    cgmp: '/product/cgmp',
    stab: '/product/stability-test',
    chal: '/product/challenge-test',
    pack: '/product/packing-attestation',
    manuc: '/product/manufacturing-process-china',
    ifra: '/product/ifra',
    alle: '/product/allergens-list',
    rmcoa: '/product/raw-material-coa',
    rmmsds: '/product/raw-material-msds',

    detail: '/product/detail',
    bi: '/product/buyer',
    third: '/product/third-party-test-report',
    cfs: '/product/cfs',
    pcc: '/product/china-certificate-trademark',
    irc: '/product/internal-responsible-company',
    logoai: '/product/logoai',
    cobrm: '/product/cobrm',
    ropqm: '/product/ropqm',
    sld: '/product/sld',
    ps: '/product/spec',
    cv: '/product/country-vendor',
    kw: '/product/kw',
    swi: '/product/separate-waste',
    upsi: '/product/upsi',
    fccad: '/product/fccad',
    vcrpac: '/product/vcrpac',
    vcrpar: '/product/vcrpar',
  },
  estimate: {
    root: '/estimate',
    pick: '/estimate/pick',
    contract: '/estimate/contract',
  },
  certificate: {
    root: '/certificate',
    ongoing: '/certificate/ongoing',
    complete: '/certificate/complete',
  },
  notarization: '/notarization',
  clinicalTrial: {
    root: '/clinicalTrial',
    estimate: {
      root: '/clinicalTrial/estimate',
      request: '/clinicalTrial/estimate/request',
    },
    ongoing: '/clinicalTrial/ongoing',
  },
  retailer: {
    root: '/retailer',
    retailerApply: {
      root: '/retailer/retailer-apply',
      BL: '/retailer/retailer-apply/BL',
      COB: '/retailer/retailer-apply/COB',
      CRCSB: '/retailer/retailer-apply/CRCSB',
      OBD: '/retailer/retailer-apply/OBD',
      product: {
        root: '/retailer/retailer-apply/product',
        basic: '/retailer/retailer-apply/product/basic',
        cat: '/retailer/retailer-apply/product/category',
        list: '/retailer/retailer-apply/product/list',
        CMBC: '/retailer/retailer-apply/product/CMBC',
        PS: '/retailer/retailer-apply/product/PS',
        INGS: '/retailer/retailer-apply/product/INGS',
        BCOA: '/retailer/retailer-apply/product/BCOA',
        COA: '/retailer/retailer-apply/product/COA',
        KART: '/retailer/retailer-apply/product/KART',
        AIMG: '/retailer/retailer-apply/product/AIMG',
        FR: '/retailer/retailer-apply/product/FR',
        CFIRC: '/retailer/retailer-apply/product/CFIRC',
        RHS: '/retailer/retailer-apply/product/RHS',
        RNIS: '/retailer/retailer-apply/product/RNIS',
        HATR: '/retailer/retailer-apply/product/HATR',
        PFA: '/retailer/retailer-apply/product/PFA',
        PSR: '/retailer/retailer-apply/product/PSR',
        PLI: '/retailer/retailer-apply/product/PLI',
        CRC: '/retailer/retailer-apply/product/CRC',
        CPRS: '/retailer/retailer-apply/product/CPRS',
        LPB: '/retailer/retailer-apply/product/LPB',
        CSIG: '/retailer/retailer-apply/product/CSIG',
      },
    },
  },
  service: '/service',
  manufacturer: {
    root: '/manufacturer',
    productSample: {
      root: '/manufacturer/product-sample',
      readyList: '/manufacturer/product-sample/ready-list',
      list: '/manufacturer/product-sample/list',
      detail: '/manufacturer/product-sample/detail',
      basic: '/manufacturer/product-sample/basic',
      recipe: '/manufacturer/product-sample/recipe',
      recipeDetail: '/manufacturer/product-sample/recipe-detail',
      processMap: '/manufacturer/product-sample/process-map',
    },
    product: {
      root: '/manufacturer/product',
      basic: '/manufacturer/product/basic',
      list: '/manufacturer/product/list',
      readyList: '/manufacturer/product/ready-list',
    },
  },
  material: {
    root: '/material',
    manage: {
      root: '/material/manage',
      done: '/material/manage/done',
      ready: '/material/manage/ready',
    },
    rawMaterial: {
      root: '/material/raw-material',
      search: '/material/raw-material/search',
      detail: '/material/raw-material/detail',
      basic: '/material/raw-material/basic',
      certification: '/material/raw-material/certification',
      coa: '/material/raw-material/coa',
      msds: '/material/raw-material/msds',
      ci: '/material/raw-material/composition-information',
      alle: '/material/raw-material/allergens-list',
      ifra: '/material/raw-material/ifra-certificate',
      marketing: '/material/raw-material/marketing',
      etc: '/material/raw-material/etc-data',
    },
    ingredient: {
      root: '/material/ingredient',
      detail: '/material/ingredient/detail',
      dictionary: '/material/ingredient/dictionary',
    },
  },
};

export default path;
