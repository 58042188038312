import { useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';

import client from 'lib/api/client';
import { IAPIPageableResponse, IAPIResponse } from 'types/common';
import {
  EClinicalTrialProcessItemStatus,
  IClinicalTrialProcessItem,
  IClinicalTrialProcessReport,
} from 'types/brand/clinicalTrial/process';

export const useClinicalTrialProcessItems = ({
  page,
  processStatus,
  keyword,
  size = 10,
}: {
  page: number;
  processStatus: EClinicalTrialProcessItemStatus | 'ALL';
  keyword: string;
  size?: number;
}) => {
  const { data: clinicalTrialProcessItemData = null } = useQuery(
    ['clinical-trial/process-items', page, processStatus, keyword, size],
    () =>
      client.get<IAPIPageableResponse<IClinicalTrialProcessItem[]>>(
        '/v1/clinical-trial/process-items',
        {
          params: {
            page,
            ...(processStatus !== 'ALL' && { processStatus }),
            keyword,
            size,
          },
        },
      ),
    {
      select: (res) => res.data.result,
    },
  );

  const { content: clinicalTrialProcessItems = [], totalElements } =
    clinicalTrialProcessItemData || {};

  return useMemo(() => ({ clinicalTrialProcessItems, totalElements }), [
    clinicalTrialProcessItems,
    totalElements,
  ]);
};

export const useClinicalTrialProcessReports = () => {
  const {
    mutate: getClinicalTrialProcessReports,
    isLoading,
  } = useMutation((clinicalTrialProcessItemId: number) =>
    client.get<IAPIResponse<IClinicalTrialProcessReport[]>>(
      `/v1/clinical-trial/process-items/${clinicalTrialProcessItemId}/reports`,
    ),
  );

  return useMemo(
    () => ({
      getClinicalTrialProcessReports,
      isLoading,
    }),
    [getClinicalTrialProcessReports, isLoading],
  );
};
