import { Button, Col, Row, Table } from 'antd';
import { useMemo, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import styled from 'styled-components';

import FooterBox from 'components/FooterBox';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import {
  useVCRPAccount,
  useProductVCRPAccount,
} from 'service/brand/product/vcrp';
import CorrectRequestMessageModal from 'components/CorrectRequestMessageModal';
import { ReactComponent as HideButtonIcon } from 'asset/svg/hide_button.svg';
import { ReactComponent as ShowButtonIcon } from 'asset/svg/show_button.svg';
import VCRPAccountAddModal from 'components/modal/product/VCRPAccountAddModal';
import { IVCRPAccount } from 'types/brand/vcrp';
import { EDocumentCode } from 'types/product';

const ProductVCRPAccountContainer = styled.div`
  .ant-table-content {
    th:first-child,
    td:first-child {
      padding-left: 36px;
    }
  }
`;

const VCRPPasswordContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ProductVCRPAccount = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) => {
  const { vcrpAccount, getVCRPAccountLoading } = useVCRPAccount();
  const {
    addProductVCRPAccount,
    getProductVCRPAccountLoading,
    readOnlyMode,
    updateMode,
  } = useProductVCRPAccount({
    productId,
    countryId,
  });
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const columns: ColumnsType<IVCRPAccount> = useMemo(
    () => [
      {
        title: '사용자명 (ID)',
        width: 360,
        align: 'left',
        dataIndex: 'vcrpAccount',
      },
      {
        title: '비밀번호',
        align: 'left',
        render: ({ vcrpPassword }) => (
          <VCRPPasswordContainer>
            {isPasswordVisible ? (
              <>
                {vcrpPassword}
                <ShowButtonIcon
                  style={{ marginLeft: 72, cursor: 'pointer' }}
                  onClick={() => setIsPasswordVisible(false)}
                />
              </>
            ) : (
              <>
                {'*'.repeat(vcrpPassword.length)}
                <HideButtonIcon
                  style={{ marginLeft: 72, cursor: 'pointer' }}
                  onClick={() => setIsPasswordVisible(true)}
                />
              </>
            )}
          </VCRPPasswordContainer>
        ),
      },
    ],
    [vcrpAccount, isPasswordVisible],
  );

  return (
    <ProductVCRPAccountContainer>
      <VCRPAccountAddModal
        vcrpAccount={vcrpAccount}
        updateMode={updateMode}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
      <Row justify="end" gutter={8}>
        <Col>
          <CorrectRequestMessageModal documentCode={EDocumentCode.VCRPAR} />
        </Col>
      </Row>
      <Table
        rowKey="vcrpAccountId"
        columns={columns}
        dataSource={vcrpAccount ? [vcrpAccount] : []}
        className="secondary"
        loading={getVCRPAccountLoading}
        pagination={false}
      />
      <Row justify="end" style={{ marginTop: 8 }}>
        <Col>
          <Button onClick={() => setIsModalVisible(true)}>
            {!updateMode ? '계정 정보 입력' : '계정 정보 변경'}
          </Button>
        </Col>
      </Row>
      <FooterBox>
        <ReadOnlyBackButton readOnly={readOnlyMode}>
          <Button
            type="primary"
            loading={getProductVCRPAccountLoading}
            onClick={() => addProductVCRPAccount()}
          >
            {!updateMode ? '등록' : '보완 완료'}
          </Button>
        </ReadOnlyBackButton>
      </FooterBox>
    </ProductVCRPAccountContainer>
  );
};

export default ProductVCRPAccount;
