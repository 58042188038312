import { Form, message } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { messages, productSpecificationsOption } from 'lib/consts';
import * as productActions from 'modules/product';
import { getUpdatingObject } from 'lib/form';
import { useProductDocStatus } from 'service/product';
import { EDocumentCode } from 'types/product';

export const useProductSpec = (productId: number, countryId: number) => {
  const dispatch = useDispatch();
  const productDocStatus = useProductDocStatus({
    productId,
    countryId,
    documentCode: EDocumentCode.PS,
  });
  const {
    productSpecifications,
    saveLoading,
    getLoading,
    addLoading,
    updateLoading,
  } = useSelector(
    ({ product, loading }: any) => ({
      productSpecifications: product.productSpecifications,
      getLoading: loading['product/GET_PRODUCT_SPECIFICATIONS'],
      saveLoading: loading['product/SAVE_PRODUCT_SPECIFICATIONS'],
      addLoading: loading['product/ADD_PRODUCT_SPECIFICATIONS'],
      updateLoading: loading['product/UPDATE_PRODUCT_SPECIFICATIONS'],
    }),
    shallowEqual,
  );
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const updateMode = productDocStatus && productDocStatus.status !== 'INP';
  const [form] = Form.useForm();

  const onSaveDraft = useCallback(() => {
    const formValues = form.getFieldsValue();
    onSubmit(formValues, true);
  }, [form]);

  const onSubmit = useCallback(
    (formValues, tempSaved = false) => {
      setSubmitSuccess(true);
      let {
        productPeriod,
        productPeriodUnit,
        productPeriodUnitDirect,
        ...restValues
      } = formValues;
      productPeriodUnit =
        productPeriodUnit !== 'others'
          ? productPeriodUnit
          : productPeriodUnitDirect;
      productPeriod = Number(productPeriod);
      let newProductSpecifications: any = {};
      if (!updateMode) {
        newProductSpecifications = {
          ...restValues,
          productPeriod,
          productPeriodUnit,
          tempSaved,
        };
      } else {
        newProductSpecifications = getUpdatingObject(
          { ...restValues, productPeriod, productPeriodUnit },
          productSpecifications,
        );

        if (
          !newProductSpecifications ||
          Object.keys(newProductSpecifications).length === 0
        ) {
          return message.warn(messages.NO_NEED_TO_UPDATE);
        }
        newProductSpecifications.tempSaved = false;
      }

      dispatch(
        productActions[
          tempSaved
            ? 'saveProductSpecifications'
            : !updateMode
            ? 'addProductSpecifications'
            : 'updateProductSpecifications'
        ]({
          productId,
          countryId,
          productSpecifications: newProductSpecifications,
        }),
      );
    },
    [productSpecifications],
  );

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      form.setFieldsValue({
        countryOrigin: 'KR',
        productPeriod: '12',
        productPeriodUnit: 'year(s)',
        targetAge: 'All Ages',
        skinType: 'Dry Skin Type',
        applicationArea: 'Skin',
        frequencyOfUse: 123.44,
        frequencyOfUseUnit: 'day',
        appliedPerUseUnit: 'mg',
        appliedPerUse: 123.456775,
        productType: 'Rinse-Off',
        useManual: 'Apply an appropriate amount to face blahblah',
        warnings: 'For external use only',
        productFeature: 'Anti-wrinkle',
        marketingClaims: 'marketing claims',
        miscellaneous: 'miscellaneous',
      });
    }
  }, []);

  useEffect(() => {
    dispatch(productActions.getProductSpecifications({ productId, countryId }));
    return () => {
      dispatch(productActions.initializeProductSpecifications());
    };
  }, []);

  useEffect(() => {
    if (productSpecifications) {
      let { productPeriodUnit } = productSpecifications;
      let productPeriodUnitDirect = '';
      // HINT : productPeriodUnitOptionValues에 포함되지 않은 unit이면 '직접입력'(productPeriodUnitDirect)한 값임.
      if (
        !productSpecificationsOption.productPeriodOptions
          .map(({ value }) => value)
          .includes(productPeriodUnit)
      ) {
        productPeriodUnitDirect = productPeriodUnit;
        productPeriodUnit = 'others';
      }
      form.setFieldsValue({
        ...productSpecifications,
        productPeriodUnit,
        productPeriodUnitDirect,
      });
    }
  }, [productSpecifications]);

  return useMemo(
    () => ({
      form,
      updateMode,
      getLoading,
      saveLoading,
      fetchLoading: !!(addLoading || updateLoading),
      submitSuccess,
      onSaveDraft,
      onSubmit,
    }),
    [
      form,
      updateMode,
      getLoading,
      saveLoading,
      addLoading,
      updateLoading,
      submitSuccess,
      onSaveDraft,
      onSubmit,
    ],
  );
};
