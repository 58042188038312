import { Badge, Divider, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import path from 'lib/path';
import styled, { css } from 'styled-components';

const TopSubMenuBlock = styled.div<{ is_inline_mode: 'true' | 'false' }>`
  ${(props) =>
    props.is_inline_mode === 'false' &&
    css`
      .ant-menu-item {
        line-height: 77px;
      }
    `}
`;

const TopSubMenu = ({
  inlineMode,
  newUserNoticeCount,
  isLoggedIn,
  logout,
  toggleDrawer,
  showUserNoticeDrawer,
}: any) => {
  const { pathname } = useLocation();
  return (
    <TopSubMenuBlock is_inline_mode={inlineMode ? 'true' : 'false'}>
      {inlineMode && <Divider style={{ marginTop: 16, marginBottom: 16 }} />}
      <Menu
        mode={inlineMode ? 'inline' : 'horizontal'}
        selectedKeys={[pathname]}
        style={{ borderRight: 'none' }}
        onClick={() => {
          inlineMode && toggleDrawer();
        }}
      >
        {isLoggedIn ? (
          <>
            {!inlineMode && (
              <Menu.Item
                key="userNotice"
                onClick={showUserNoticeDrawer}
                style={{
                  marginLeft: 0,
                  marginRight: 30,
                  position: 'relative',
                  top: 4,
                }}
              >
                <Badge dot={newUserNoticeCount > 0} style={{ top: -4 }}>
                  <i
                    className="material-icons outlined"
                    onClick={showUserNoticeDrawer}
                    style={{ position: 'relative', top: -8, cursor: 'pointer' }}
                  >
                    notifications
                  </i>
                </Badge>
              </Menu.Item>
            )}
            <Menu.Item key={path.my} style={{ margin: 0, marginRight: 18 }}>
              <Link to={path.my} style={{ color: '#7e7e7e' }}>
                마이페이지
              </Link>
            </Menu.Item>
            <Menu.Item
              key="logout"
              onClick={logout}
              style={{ margin: 0, color: '#7e7e7e' }}
            >
              로그아웃
            </Menu.Item>
          </>
        ) : (
          <Menu.Item key="login">
            <Link to={path.login} style={{ color: '#7e7e7e' }}>
              로그인
            </Link>
          </Menu.Item>
        )}
      </Menu>
    </TopSubMenuBlock>
  );
};

export default TopSubMenu;
