import styled from 'styled-components';
import { Button, Col, Form, Input, Radio, Row, Spin, Upload } from 'antd';

import { useForceUpdate } from 'lib/hook';
import { exceptKoreanRule, requireRule } from 'lib/validate';
import { messages, scrollToFirstErrorOption } from 'lib/consts';
import { useCertificateMode, useReadOnlyMode } from 'hook/certificate';
import FileList from 'components/file/FileList';
import FileViewer from 'components/file/FileViewer';
import FooterBox from 'components/FooterBox';
import Typography from 'components/system/general/Typography';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import { useProductPackingAttestation } from 'service/brand/product/packingAttestation';
import { useManuMode } from 'hook/company';

const ProductPackingAttestationBlock = styled.div``;

const FormWrap = styled.div`
  max-width: 540px;
  margin: 0 auto;
`;

const useOptions = [
  { label: '사용함', value: true },
  { label: '사용 안함', value: false },
];

const ProductPackingAttestation = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId?: number;
}) => {
  const forceUpdate = useForceUpdate();
  const certificateMode = useCertificateMode();
  const readOnlyMode = useReadOnlyMode();
  const manuMode = useManuMode();
  const {
    updateMode,
    previewFile,
    form,
    firstPackingFiles,
    secondPackingFiles,
    getLoading,
    fetchLoading,
    updateLoading,
    packingAttestationType,
    uploadFirstPackingFile,
    uploadSecondPackingFile,
    firstPreviewFile,
    secondPreviewFile,
    showFirstPreviewFile,
    showSecondPreviewFile,
    deletePackingFile,
    submit,
    changePackingAttestationType,
    patchPackingAttestationType,
    initSecondPackingFiles,
  } = useProductPackingAttestation(productId, countryId);

  return (
    <ProductPackingAttestationBlock>
      <Spin spinning={getLoading === true}>
        {manuMode && (
          <Form.Item
            label="부자재 구매 유형"
            required
            colon={false}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
          >
            <Radio.Group
              value={packingAttestationType}
              onChange={(e) => changePackingAttestationType(e.target.value)}
            >
              <Radio value="TRK">턴키 (Turn-Key)</Radio>
              <Radio value="ATK">턴키 구매대행</Radio>
              <Radio value="BND">브랜드사 사급</Radio>
            </Radio.Group>
          </Form.Item>
        )}
        {(!manuMode ||
          (packingAttestationType && packingAttestationType !== 'BND')) && (
          <>
            <FormWrap>
              <Form
                form={form}
                onFinish={submit}
                colon={false}
                layout="vertical"
                scrollToFirstError={scrollToFirstErrorOption}
                onValuesChange={(fieldMap) => {
                  if (
                    [
                      'isUseRecyclingContainer',
                      'isUsePackingContainer2',
                    ].includes(Object.keys(fieldMap)[0])
                  ) {
                    forceUpdate();
                  }
                }}
              >
                <Row gutter={8} align="bottom">
                  <Col xs={{ span: 24 }} sm={{ span: 16 }}>
                    <Form.Item
                      label="1차 포장 용기 재질"
                      name="packingContainer1"
                      normalize={(value) => value.toUpperCase()}
                      rules={[requireRule, exceptKoreanRule]}
                    >
                      <Input disabled={readOnlyMode} />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                    <Form.Item
                      label="1차 포장 용기 사진 첨부"
                      required
                      name="packingContainer1Image"
                      valuePropName=""
                      style={{ width: '100%' }}
                      rules={[
                        {
                          validator: () => {
                            if (!firstPackingFiles.length) {
                              return Promise.reject(
                                new Error(messages.REQUIRED_FIELD),
                              );
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Upload
                        id="upload1"
                        accept="image/*"
                        itemRender={() => null}
                        multiple
                        beforeUpload={(file) => {
                          uploadFirstPackingFile(file);
                          return false;
                        }}
                      >
                        <Button
                          id="packingContainer1Image"
                          disabled={readOnlyMode}
                          style={{ width: 120 }}
                        >
                          파일 선택
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  label="2차 포장 용기 사용 여부"
                  name="isUsePackingContainer2"
                  initialValue={true}
                  rules={[requireRule, exceptKoreanRule]}
                >
                  <Radio.Group
                    options={useOptions}
                    disabled={readOnlyMode}
                    onChange={(e) => initSecondPackingFiles(e.target.value)}
                  />
                </Form.Item>
                {form.getFieldValue('isUsePackingContainer2') !== false && (
                  <Row gutter={8} align="bottom">
                    <Col xs={{ span: 24 }} sm={{ span: 16 }}>
                      <Form.Item
                        label="2차 포장 용기 재질"
                        name="packingContainer2"
                        normalize={(value) => value.toUpperCase()}
                        rules={[requireRule, exceptKoreanRule]}
                      >
                        <Input disabled={readOnlyMode} />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                      <Form.Item
                        label="2차 포장 용기 사진 첨부"
                        required
                        name="packingContainer2Image"
                        valuePropName=""
                        rules={[
                          {
                            validator: () => {
                              if (!secondPackingFiles.length) {
                                return Promise.reject(
                                  new Error(messages.REQUIRED_FIELD),
                                );
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Upload
                          id="upload2"
                          accept="image/*"
                          itemRender={() => null}
                          multiple
                          beforeUpload={(file) => {
                            uploadSecondPackingFile(file);
                            return false;
                          }}
                        >
                          <Button
                            id="packingContainer2Image"
                            disabled={readOnlyMode}
                            style={{ width: 120 }}
                          >
                            파일 선택
                          </Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                  </Row>
                )}
                <Form.Item
                  label={
                    <>
                      재활용 용기 사용 여부{' '}
                      <Typography.Text
                        type="secondary"
                        color="gray"
                        gutter={{ left: 4 }}
                        style={{ fontSize: 10 }}
                      >
                        *PCR 용기(Post Consumer Recycled)를 사용했을 경우에만
                        체크
                      </Typography.Text>
                    </>
                  }
                  required
                  name="isUseRecyclingContainer"
                  initialValue={false}
                >
                  <Radio.Group options={useOptions} disabled={readOnlyMode} />
                </Form.Item>
                {form.getFieldValue('isUseRecyclingContainer') && (
                  <Form.Item
                    label="재활용 용기에 색깔 사용 여부"
                    name="isUseColorContainer"
                    required
                    initialValue={false}
                  >
                    <Radio.Group options={useOptions} />
                  </Form.Item>
                )}
              </Form>
            </FormWrap>
            <Row wrap={false} gutter={20} justify="center">
              <Col flex="0 0 400px">
                <FileList
                  header={
                    <Typography.Text type="secondary" gutter={{ bottom: 8 }}>
                      1차 포장 용기 재질
                    </Typography.Text>
                  }
                  selectedFile={firstPreviewFile}
                  files={firstPackingFiles}
                  onFileClick={showFirstPreviewFile}
                  onDelete={!readOnlyMode ? deletePackingFile : undefined}
                  width={undefined}
                  upload={undefined}
                  style={undefined}
                />
                {form.getFieldValue('isUsePackingContainer2') !== false && (
                  <FileList
                    header={
                      <Typography.Text type="secondary" gutter={{ bottom: 8 }}>
                        2차 포장 용기 재질
                      </Typography.Text>
                    }
                    selectedFile={secondPreviewFile}
                    files={secondPackingFiles}
                    onFileClick={showSecondPreviewFile}
                    onDelete={!readOnlyMode ? deletePackingFile : undefined}
                    style={{ marginTop: 16 }}
                    width={undefined}
                    upload={undefined}
                  />
                )}
              </Col>
              <Col flex="auto">
                <FileViewer
                  file={previewFile}
                  bodyStyle={{
                    height: 556,
                  }}
                />
              </Col>
            </Row>
            <FooterBox>
              <ReadOnlyBackButton readOnly={readOnlyMode}>
                <Button
                  type="primary"
                  loading={fetchLoading || updateLoading}
                  onClick={form.submit}
                >
                  {!updateMode
                    ? '등록'
                    : !certificateMode
                    ? '수정'
                    : '보완 완료'}
                </Button>
              </ReadOnlyBackButton>
            </FooterBox>
          </>
        )}
        {manuMode && packingAttestationType === 'BND' && (
          <FooterBox>
            <Button
              loading={fetchLoading}
              type="primary"
              onClick={patchPackingAttestationType}
            >
              {!updateMode ? '확인' : '수정'}
            </Button>
          </FooterBox>
        )}
      </Spin>
    </ProductPackingAttestationBlock>
  );
};

export default ProductPackingAttestation;
