import { Form, Modal } from 'antd';
import ManagerForm from 'components/auth/ManagerForm';
import { useManuMode } from 'hook/company';
import {
  deleteUser,
  updateUser,
  resetPassword,
  updateCompany,
  setChangePasswordModalVisible,
} from 'modules/auth';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useManufacturerCompanies } from 'service/manufacturer/company';

const ManagerUpdateContainer = ({ directMode, manager }) => {
  const dispatch = useDispatch();
  const { data: manufacturers = [] } = useManufacturerCompanies();
  const { companyNameKo, submitLoading, deleteLoading } = useSelector(
    ({ company, loading }) => ({
      companyNameKo: company.company.companyNameKo,
      submitLoading:
        loading['auth/UPDATE_USER'] || loading['auth/UPDATE_COMPANY'],
      deleteLoading: loading['auth/DELETE_USER'],
    }),
  );
  const manuMode = useManuMode();
  const [form] = Form.useForm();
  const onSubmit = (user) => {
    Modal.confirm({
      title: '정말로 수정하시겠습니까?',
      icon: null,
      onOk: () => {
        if (!directMode) {
          dispatch(updateUser({ ...user, userId: manager.userId }));
        } else {
          dispatch(
            updateCompany({ user: { ...user, userId: manager.userId } }),
          );
        }
      },
    });
  };
  const onDelete = () => {
    Modal.confirm({
      title: '정말로 삭제하시겠습니까?',
      icon: null,
      onOk: () => {
        dispatch(deleteUser(manager.userId));
      },
    });
  };
  const onResetPassword = () => {
    if (!directMode) {
      Modal.confirm({
        title: '정말로 초기화하시겠습니까?',
        icon: null,
        onOk: () => {
          dispatch(resetPassword(manager.userId));
        },
      });
    } else {
      dispatch(setChangePasswordModalVisible(true));
    }
  };
  useEffect(() => {
    form.setFieldsValue({ ...manager, ...(directMode && { companyNameKo }) });
  }, [manager]);
  return (
    <ManagerForm
      updateMode
      directMode={directMode}
      manuMode={manuMode}
      form={form}
      manufacturers={manufacturers}
      submitLoading={submitLoading}
      deleteLoading={deleteLoading}
      onSubmit={onSubmit}
      onDelete={onDelete}
      onResetPassword={onResetPassword}
    />
  );
};
export default ManagerUpdateContainer;
