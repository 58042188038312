import { IAPIResponse } from 'types/common';
import {
  ICompositionInformation,
  ICompositionInformationDraft,
} from 'types/material/compositionInformation';
import client from '../client';

export const getCompositionInformation = (materialId: number) =>
  client.get<IAPIResponse<ICompositionInformation[]>>(
    `/materials/${materialId}/composition-info`,
  );

export const uploadCompositionInformation = async ({
  materialId,
  file,
}: {
  materialId: number;
  file: File;
}) =>
  client.post<IAPIResponse<ICompositionInformationDraft>>(
    `/materials/${materialId}/composition-info/upload`,
    {
      file,
    },
  );
