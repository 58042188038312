import { Col, Row, Space, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import CountryProhibitInfosModal from 'components/modal/formula/CountryProhibitInfosModal';
import { Typography } from 'components/system';
import { countryCodeMap } from 'lib/consts';
import palette from 'lib/styles/palette';
import { useState } from 'react';
import {
  useCountryProhibitsUpdatedDate,
  useEwgUpdatedDate,
} from 'service/material/ingredient';
import styled from 'styled-components';
import { ICountryProhibitInfo } from 'types/formula';
import { ICompositionInformation } from 'types/material/rawMaterialDetail';

const TableWrap = styled.div`
  th.ant-table-cell {
    background-color: #f9f9f9 !important;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    color: ${palette.text.slate};
  }
`;

const ProhibitLabelWrap = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;
const CompositionInformations = ({
  compositionInformations,
}: {
  compositionInformations: ICompositionInformation[];
}) => {
  const prohibitDataUpdatedDate = useCountryProhibitsUpdatedDate();
  const ewgUpdatedDate = useEwgUpdatedDate();
  const [selectedCountryName, setSelectedCountryName] = useState<string>('');
  const [selectedCountryInfos, setSelectedCountryInfos] = useState<
    ICountryProhibitInfo[]
  >([]);
  const handleCloseCountryProhibitModal = () => {
    setSelectedCountryInfos([]);
    setSelectedCountryName('');
  };
  const handleClickProhibit = (
    countryProhibits: ICountryProhibitInfo[],
    countryNameKo?: string,
  ) => {
    setSelectedCountryInfos(countryProhibits);
    if (countryNameKo) {
      setSelectedCountryName(countryNameKo);
    }
  };
  const columns: ColumnsType<ICompositionInformation> = [
    {
      title: '국문 성분명',
      dataIndex: 'inciNameKo',
    },
    {
      title: 'INCI Name',
      dataIndex: 'inciNameEn',
    },
    {
      title: 'CAS No.',
      dataIndex: 'casNo',
    },
    {
      title: 'EC No.',
      dataIndex: 'euCasNo',
    },
    {
      title: 'EWG Skin deep Score',
      align: 'center',
      render: (_, { ewgScore }) => (
        <img src={ewgScore} alt="" style={{ width: 20 }} />
      ),
    },
    {
      title: '국가별 성분 규제',
      dataIndex: 'countryNameKo',
      align: 'center',
      width: 160,
      render: (_, { countryProhibits }, index) => {
        const prohibitTypes = Array.from(
          countryProhibits.reduce<Set<string>>((acc, { type }) => {
            acc.add(type === 'limit' ? '제한' : '금지');
            return acc;
          }, new Set()),
        );

        return (
          prohibitTypes.length > 0 && (
            <Space size="middle" style={{ width: '100%' }}>
              {prohibitTypes.length > 0 && (
                <ProhibitLabelWrap>
                  {prohibitTypes.map((prohibitType) => (
                    <Typography.Label
                      key={prohibitType}
                      type={prohibitType === '제한' ? 'error' : 'check'}
                      bordered
                      style={{
                        fontSize: 10,
                        minWidth: 32,
                        padding: '3px 6px',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleClickProhibit(countryProhibits)}
                    >
                      {prohibitType}
                    </Typography.Label>
                  ))}
                </ProhibitLabelWrap>
              )}
              <Row gutter={[2, 2]}>
                {countryProhibits.map(({ countryNameKo }) => (
                  <Col key={`${index}_${countryNameKo}`}>
                    <img
                      src={`https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/flag/${countryCodeMap[countryNameKo]}.png`}
                      alt=""
                      style={{
                        width: 20,
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        handleClickProhibit(countryProhibits, countryNameKo)
                      }
                    />
                  </Col>
                ))}
              </Row>
            </Space>
          )
        );
      },
    },
  ];

  return (
    <>
      <Row
        align="middle"
        gutter={16}
        style={{ marginTop: 36, marginBottom: 8 }}
      >
        <Col>
          <Typography.Title>Composition Information</Typography.Title>
        </Col>
        <Col flex="auto">
          <Typography.Text color="slate" medium>
            배합 성분 정보
          </Typography.Text>
        </Col>
        <Col style={{ paddingTop: 14 }}>
          <Typography.Label
            type="disabled"
            style={{ fontSize: 12, height: 22, lineHeight: '14px' }}
          >
            DB 업데이트
          </Typography.Label>
          <Typography.Text
            type="tertiary"
            medium
            color="slate"
            inline
            gutter={{ left: 8 }}
          >
            [EWG Skin deep]
          </Typography.Text>
          <Typography.Text
            type="tertiary"
            medium
            color="slate"
            inline
            gutter={{ left: 4 }}
          >
            {ewgUpdatedDate}
          </Typography.Text>
          <Typography.Text
            type="tertiary"
            medium
            color="slate"
            inline
            gutter={{ left: 8 }}
          >
            [국가별 성분 규제]
          </Typography.Text>
          <Typography.Text
            type="tertiary"
            medium
            color="slate"
            inline
            gutter={{ left: 4 }}
          >
            {prohibitDataUpdatedDate}
          </Typography.Text>
        </Col>
      </Row>
      <TableWrap>
        <Table
          style={{
            borderTop: `2px solid ${palette.primary}`,
          }}
          columns={columns}
          dataSource={compositionInformations}
          rowKey="materialCompositionInformationId"
          pagination={false}
        />
      </TableWrap>
      <CountryProhibitInfosModal
        defaultCountryName={selectedCountryName}
        countryInfos={selectedCountryInfos}
        onClose={handleCloseCountryProhibitModal}
      />
    </>
  );
};

export default CompositionInformations;
