export function getUpdatingObject<T>(
  newObject: T,
  originObject: any,
): Partial<T> | undefined {
  const updatedObject: Partial<T> = {};
  (Object.keys(newObject) as Array<keyof T>).forEach((key) => {
    const newValue = newObject[key];
    const originValue = originObject[key];
    if (newValue !== originValue) {
      updatedObject[key] = newValue;
    }
  });
  return Object.keys(updatedObject).length > 0 ? updatedObject : undefined;
}

export function getUndefinedFilteredObject<T>(obj: T): Partial<T> {
  const newObj = (Object.keys(obj) as Array<keyof T>).reduce<Partial<T>>(
    (acc, key) => {
      if (typeof obj[key] !== 'undefined') {
        acc[key] = obj[key];
      }
      return acc;
    },
    {},
  );
  return newObj;
}

export const parseWeightUnit = (unit: string): string | undefined => {
  const lowerUnit = unit.toLowerCase();
  if (lowerUnit === 'g') {
    return 'g';
  } else if (lowerUnit === 'ml') {
    return 'mL';
  } else if (lowerUnit === 'oz' || lowerUnit === 'oz.') {
    return 'Oz.';
  } else if (lowerUnit.replace(/\s/gi, '').includes('fl.')) {
    return 'fl. Oz.';
  }
};

export const toNoExponents = (exponents: number) => {
  const data = String(exponents).split(/[eE]/);
  if (data.length === 1) return data[0];

  let z = '',
    sign = exponents < 0 ? '-' : '',
    str = data[0].replace('.', ''),
    mag = Number(data[1]) + 1;

  if (mag < 0) {
    z = sign + '0.';
    while (mag++) z += '0';
    return z + str.replace(/^-/, '');
  }
  mag -= str.length;
  while (mag--) z += '0';
  return str + z;
};
