import ProductReadyList from 'components/product/ProductReadyList';
import PageTemplate from 'templates/PageTemplate';

const ProductReadyPage = () => {
  return (
    <PageTemplate
      title="등록 중 제품 관리"
      description="아래 등록 중인 제품을 관리해보세요."
      back={false}
    >
      <ProductReadyList />
    </PageTemplate>
  );
};

export default ProductReadyPage;
