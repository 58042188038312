import { useLayoutEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Col, Form, message, Row } from 'antd';

import { messages } from 'lib/consts';
import { createFormData } from 'lib/file';
import * as productActions from 'modules/product';
import CorrectRequestMessageModal from 'components/CorrectRequestMessageModal';
import ProductAllergensList from 'components/product/ProductAllergensList';
import UpdateLog from 'components/product/UpdateLog';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';

const ProductAllergensListContainer = () => {
  const params = useParams();
  const { productId } = useCurrentProduct();
  const countryId = useCountryId();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    currentProduct,
    allergensList,
    readOnlyMode,
    fetchLoading,
    submitLoading,
  } = useSelector(
    ({ product, certificate, loading }) => ({
      currentProduct: product.currentProduct,
      allergensList: product.productAllergensList,
      readOnlyMode: certificate.readOnlyMode,
      fetchLoading: loading['product/GET_ALLERGENS_LIST'],
      submitLoading:
        loading['product/ADD_PRODUCT_ALLERGENS_LIST'] ||
        loading['product/UPDATE_PRODUCT_ALLERGENS_LIST'],
    }),
    shallowEqual,
  );
  const updateMode = allergensList !== null;

  const [file, setFile] = useState(null);
  const handleDelete = () => {
    setFile(null);
  };
  const handleSubmit = ({ isOdourless, isNonAllergenOdour }) => {
    if (!isOdourless && !file) {
      return message.warning(messages.REQUIRED_FILE);
    }
    if (!updateMode) {
      dispatch(
        productActions.addAllergensList({
          productId: currentProduct.productId,
          isOdourless,
          ...(!isOdourless && { isNonAllergenOdour }),
          ...(!isOdourless && { documentFile: file }),
        }),
      );
    } else {
      const updateFormValues = {
        ...(allergensList.isOdourless !== isOdourless && { isOdourless }),
        ...(!isOdourless &&
          allergensList.isNonAllergenOdour !== isNonAllergenOdour && {
            isNonAllergenOdour,
          }),
        ...(!isOdourless && !file.url && { documentFile: file }),
      };
      if (Object.keys(updateFormValues).length === 0) {
        return message.warn(messages.NO_NEED_TO_UPDATE);
      }
      dispatch(
        productActions.updateAllergensList(
          createFormData({
            productId,
            ...(typeof params.countryId !== 'undefined' && {
              countryId: params.countryId,
            }),
            ...updateFormValues,
          }),
        ),
      );
    }
  };
  useLayoutEffect(() => {
    dispatch(productActions.getProductAllergensList({ productId, countryId }));
    return () => {
      dispatch(productActions.initializeProductAllergensList());
    };
  }, []);
  useLayoutEffect(() => {
    if (allergensList) {
      form.setFieldsValue({
        isOdourless: allergensList.isOdourless,
        ...(!allergensList.isOdourless && {
          isNonAllergenOdour: allergensList.isNonAllergenOdour,
        }),
      });
      if (!allergensList.isOdourless) {
        setFile({
          name: allergensList.fileName,
          url: allergensList.uploadedFileUrl,
        });
      }
    }
  }, [allergensList]);
  return (
    <>
      <Row justify="end" gutter={8}>
        <Col>
          <CorrectRequestMessageModal documentCode="alle" />
        </Col>
        {updateMode && (
          <Col>
            <UpdateLog
              productId={productId}
              countryId={countryId}
              documentCode="alle"
            />
          </Col>
        )}
      </Row>
      <ProductAllergensList
        updateMode={updateMode}
        readOnlyMode={readOnlyMode}
        form={form}
        file={file}
        fetchLoading={fetchLoading}
        submitLoading={submitLoading}
        onUpload={setFile}
        onDelete={handleDelete}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default ProductAllergensListContainer;
