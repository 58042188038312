import produce from 'immer';
import { createReducer } from 'typesafe-actions';
import { SET_IS_MOBILE } from './actions';

import { DeviceAction, DeviceState } from './types';

const initialState: DeviceState = {
  isMobile: false,
};

export default createReducer<DeviceState, DeviceAction>(initialState, {
  [SET_IS_MOBILE]: (state, { payload: isMobile }) =>
    produce(state, (draft) => {
      draft.isMobile = isMobile;
    }),
});
