import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useDispatch } from 'react-redux';

import { EUserType } from 'types/auth';
import * as navigatorActions from 'modules/navigator';
import path from 'lib/path';
import { screenMax, screenMin } from 'lib/styles';
import { useManuMode } from 'hook/company';
import { useUserType } from 'service/auth';
import palette from 'lib/styles/palette';

const StyledMenu = styled(Menu)<{ is_inline_mode: string }>`
  ${(props) =>
    props.is_inline_mode === 'false' &&
    css`
      display: flex;
      margin: 0 auto;
      justify-content: center;

      &::before,
      &::after {
        display: none;
      }
      @media (min-width: ${screenMin.lg}) {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      .ant-menu-submenu-horizontal:not(.ant-menu-overflowed-submenu),
      .ant-menu-item-only-child:not(.ant-menu-overflowed-submenu) {
        line-height: 77px;
        margin: 0 28px;

        @media (max-width: ${screenMax.md}) {
          margin: 0 8px;
        }
      }

      .ant-menu-submenu-title,
      .ant-menu-item {
        font-size: 18px;
        letter-spacing: -1px;
      }
    `}
`;

const StyledMenuItem = styled(Menu.Item)`
  height: 44px;
  margin-bottom: 0px;
  color: ${palette.text.disabled};

  :hover {
    color: ${palette.text.disabled};
  }

  :active {
    background-color: #fff;
  }
`;

const BrandTopMenu = ({ inlineMode }: { inlineMode: boolean }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const manuMode = useManuMode();
  const toggleDrawer = () => {
    dispatch(navigatorActions.toggleDrawer());
  };
  const userType = useUserType();

  return (
    <StyledMenu
      mode={inlineMode ? 'inline' : 'horizontal'}
      selectedKeys={[pathname]}
      triggerSubMenuAction="click"
      is_inline_mode={inlineMode ? 'true' : 'false'}
      onClick={() => {
        inlineMode && toggleDrawer();
      }}
    >
      {!inlineMode && (
        <Menu.SubMenu title="제품 등록">
          <Menu.Item key={path.product.basic}>
            <Link to={path.product.basic}>신규 제품 등록</Link>
          </Menu.Item>
          {userType === EUserType.BRAND && (
            <Menu.Item key={path.product.import}>
              <Link to={path.product.import}>코드로 제품 등록</Link>
            </Menu.Item>
          )}
          <Menu.Item key={path.product.ready}>
            <Link to={path.product.ready}>등록 중 제품 관리</Link>
          </Menu.Item>
          <Menu.Item key={path.product.registered}>
            <Link to={path.product.registered}>등록 완료 제품 관리</Link>
          </Menu.Item>
        </Menu.SubMenu>
      )}
      {userType === EUserType.BRAND && (
        <>
          <Menu.SubMenu title="해외 인증">
            <Menu.Item key={path.estimate.pick}>
              <Link to={path.estimate.pick}>셀프 견적</Link>
            </Menu.Item>
            <Menu.Item key={path.certificate.ongoing}>
              <Link to={path.certificate.ongoing}>인증 진행 현황</Link>
            </Menu.Item>
            <Menu.Item key={path.certificate.complete}>
              <Link to={path.certificate.complete}>인증 완료 제품 관리</Link>
            </Menu.Item>
            <Menu.Item key={path.notarization}>
              <Link to={path.notarization}>공증 서류 양식 발행</Link>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.SubMenu title="임상시험">
            <Menu.Item key={path.clinicalTrial.estimate.root}>
              <Link to={path.clinicalTrial.estimate.root}>시험 견적 의뢰</Link>
            </Menu.Item>
            <Menu.Item key={path.clinicalTrial.ongoing}>
              <Link to={path.clinicalTrial.ongoing}>임상시험 진행 현황</Link>
            </Menu.Item>
          </Menu.SubMenu>
        </>
      )}
      {!manuMode && (
        <Menu.SubMenu title="유통 플랫폼">
          <Menu.Item key={path.retailer.retailerApply.product.list}>
            <Link to={path.retailer.retailerApply.product.list}>
              국내 입점 서류 관리
            </Link>
          </Menu.Item>
          <StyledMenuItem key="해외 입점 서류 관리">
            해외 입점 서류 관리 (서비스 예정)
          </StyledMenuItem>
        </Menu.SubMenu>
      )}
      <Menu.Item key={path.service}>
        <Link to={path.service}>고객센터</Link>
      </Menu.Item>
    </StyledMenu>
  );
};

export default BrandTopMenu;
