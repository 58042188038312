import styled from 'styled-components';
import { Col, message, Row, Spin } from 'antd';

import history from 'lib/history';
import path from 'lib/path';
import { useMain } from 'service/main';
import { useIsMobile } from 'hook/etc';
import WorldMap from 'components/main/WorldMap';
import Typography from 'components/system/general/Typography';
import CompanyStatsBlock from 'components/main/CompanyStatsBlock';
import CompanyStat from 'components/main/CompanyStat';
import Shortcut from 'components/main/Shortcut';
import { EUserType } from 'types/auth';
import { useUserType } from 'service/auth';

const MainPageBlock = styled.div`
  padding-top: 40px;
  max-width: 1024px !important;
`;

const MainPage = () => {
  const { companyName, stats, getStatsLoading } = useMain();
  const userType = useUserType();
  const isMobile = useIsMobile();
  const {
    productOnCount = 0,
    productFinCount = 0,
    certOnCount = 0,
    certFinCount = 0,
    certFinCountryCount = 0,
  } = {
    ...stats,
  };
  const isManufactureUser = userType === EUserType.MANUFACTURE;

  const handleAddProduct = () => {
    history.push(path.product.basic);
  };
  return (
    <MainPageBlock>
      <Row gutter={[16, 32]} justify="center">
        <Col>
          <CompanyStatsBlock>
            <Typography.Headline
              type="secondary"
              style={{ textAlign: !isManufactureUser ? 'left' : 'center' }}
            >
              <>
                현재 <mark>{companyName}</mark>
                님의
                <br /> 인증 현황입니다.
              </>
            </Typography.Headline>
            <Spin spinning={getStatsLoading}>
              <Row gutter={16} style={{ marginTop: 52 }}>
                <Col>
                  <CompanyStat
                    amount={productOnCount}
                    opacity={0.25}
                    label="등록 중 제품"
                    onClick={() => {
                      if (isMobile) {
                        message.warn('PC 브라우저를 이용해 주세요.');
                      } else {
                        history.push(path.product.ready);
                      }
                    }}
                  />
                </Col>
                <Col>
                  <CompanyStat
                    amount={productFinCount}
                    opacity={0.5}
                    label="등록 완료 제품"
                    onClick={() => {
                      if (isMobile) {
                        message.warn('PC 브라우저를 이용해 주세요.');
                      } else {
                        history.push(path.product.registered);
                      }
                    }}
                  />
                </Col>
                {!isManufactureUser && (
                  <>
                    <Col>
                      <CompanyStat
                        amount={certOnCount}
                        opacity={0.75}
                        label="인증 진행 중"
                        onClick={() => history.push(path.certificate.ongoing)}
                      />
                    </Col>
                    <Col>
                      <CompanyStat
                        amount={certFinCount}
                        opacity={1}
                        label="인증 완료"
                        onClick={() => history.push(path.certificate.complete)}
                      />
                    </Col>
                  </>
                )}
              </Row>
            </Spin>
          </CompanyStatsBlock>
        </Col>
        {!isManufactureUser && (
          <Col flex="auto">
            <WorldMap count={certFinCountryCount} />
          </Col>
        )}
      </Row>
      <Typography.Title
        gutter={{ top: !isManufactureUser ? -40 : 64, bottom: 16 }}
        style={{ textAlign: !isManufactureUser ? 'left' : 'center' }}
      >
        자주 찾는 메뉴
      </Typography.Title>
      <Row gutter={[16, 16]} justify="center">
        <Col xs={{ span: 12 }} sm={{ span: 6 }}>
          <Shortcut
            icon={<i className="material-icons outlined primary">new_label</i>}
            label="신규 제품 등록"
            description="지금 바로 제품을 등록해 보세요."
            onClick={handleAddProduct}
          />
        </Col>
        {!isManufactureUser ? (
          <>
            <Col xs={{ span: 12 }} sm={{ span: 6 }}>
              <Shortcut
                icon={<i className="material-icons outlined primary">edit</i>}
                label="셀프 견적"
                description="셀프로 제품 견적을 내보세요."
                onClick={() => history.push(path.estimate.pick)}
              />
            </Col>
            <Col xs={{ span: 12 }} sm={{ span: 6 }}>
              <Shortcut
                icon={
                  <i className="material-icons outlined primary">
                    checklist_rtl
                  </i>
                }
                label="인증 진행 현황"
                description="현재 인증 상황을 확인해 보세요."
                onClick={() => history.push(path.certificate.ongoing)}
              />
            </Col>
            <Col xs={{ span: 12 }} sm={{ span: 6 }}>
              <Shortcut
                icon={
                  <i className="material-icons outlined primary">list_alt</i>
                }
                label="인증 완료 제품 관리"
                description="인증된 제품들을 관리해 보세요."
                onClick={() => history.push(path.certificate.complete)}
              />
            </Col>
          </>
        ) : (
          <>
            <Col xs={{ span: 12 }} sm={{ span: 6 }}>
              <Shortcut
                icon={
                  <i className="material-icons outlined primary">
                    checklist_rtl
                  </i>
                }
                label="등록 중 제품 관리"
                description="등록 중인 제품들을 이어서 등록해 보세요."
                onClick={() => history.push(path.product.ready)}
              />
            </Col>
            <Col xs={{ span: 12 }} sm={{ span: 6 }}>
              <Shortcut
                icon={
                  <i className="material-icons outlined primary">list_alt</i>
                }
                label="등록 완료 제품 관리"
                description="등록 된 제품들을 관리해보세요."
                onClick={() => history.push(path.product.registered)}
              />
            </Col>
          </>
        )}
      </Row>
    </MainPageBlock>
  );
};

export default MainPage;
