import client from 'lib/api/client';
import { createFormData } from 'lib/file';
import { IAPIResponse } from 'types/common';
import {
  IRawMaterialAdd,
  IRawMaterialUpdate,
  IRawMaterialGet,
  IDocument,
  IRegisteredRawMaterial,
  IMadeCountry,
} from 'types/material/rawMaterial';

export const getDocuments = (materialId: number) =>
  client.get<IAPIResponse<IDocument[]>>(`/materials/${materialId}/pre-list`);

export const rawMaterialNameEnDuplicateCheck = (materialNameEn: string) =>
  client.get(`/material/name/duplicate`, {
    params: {
      materialNameEn,
    },
  });

export const getRawMaterial = (materialId: number) =>
  client.get<IAPIResponse<IRawMaterialGet>>(`/material/${materialId}`);

export const addRawMaterial = (rawMaterial: IRawMaterialAdd) =>
  client.post<IAPIResponse<IRawMaterialGet>>(
    '/material',
    createFormData(rawMaterial),
  );

export const updateRawMaterial = ({
  materialId,
  ...rest
}: IRawMaterialUpdate) =>
  client.patch(`/material/${materialId}`, createFormData(rest));

export const registerRawMaterial = (materialId: number) =>
  client.post(`/material/register/${materialId}`);

export const getRegisteredRawMaterials = () =>
  client.get<IAPIResponse<IRegisteredRawMaterial[]>>('/materials/registered');

export const getMadeCountries = () =>
  client.get<IAPIResponse<IMadeCountry[]>>('/country-list');
