import { useRouteMatch } from 'react-router-dom';

import MaterialReady from 'components/material/registerManage/MaterialReady';
import PageTemplate from 'templates/PageTemplate';
import MaterialDone from 'components/material/registerManage/MaterialDone';

const MaterialRegisterManagePage = () => {
  const {
    params: { status },
  } = useRouteMatch<{ status: string }>();
  const isReady = status === 'ready';

  return (
    <PageTemplate
      title={`등록 ${isReady ? '중' : '완료'} 원료 관리`}
      description={`아래 등록 ${
        isReady ? '중인' : '완료된'
      } 원료를 관리해보세요.`}
      back={false}
    >
      {isReady ? <MaterialReady /> : <MaterialDone />}
    </PageTemplate>
  );
};

export default MaterialRegisterManagePage;
