import { useEffect } from 'react';
import styled from 'styled-components';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import { Spin } from 'antd';

import palette from 'lib/styles/palette';
import { useGetCompanyBanners } from 'service/company';

const CompanyBannerCarouselBlock = styled.div`
  max-width: 920px;
  margin: 56px auto 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .splide__pagination {
    position: relative;
    bottom: 0;
    margin-top: 16px;
  }

  .splide__pagination__page {
    background-color: ${palette.primary};
    opacity: 0.3;
    margin: 0px;
    margin-right: 8px;
  }

  .splide__pagination__page.is-active {
    background-color: ${palette.primary};
    opacity: 1;
    transform: scale(1);
  }

  .splide__slide {
    width: 296px !important;
    border: 1px solid #dfdfdf;
    cursor: pointer;
  }
`;

const CompanyBannerCarousel = () => {
  // HINT : loop의 first, last 배너의 elementID(ex 'splide01-clone06')는 perPage 속성 값 및 .splide__slide에 적용된 width값에 따라 변경될 수 있음.
  const handleClickSlide = (url: string) => {
    window.open(url);
  };

  const { companyBanners, getLoading } = useGetCompanyBanners();
  useEffect(() => {
    const handleClickLastSlideCloneEl = () =>
      handleClickSlide(companyBanners[companyBanners.length - 1].url);
    const handleClickFirstSlideCloneEl = () =>
      handleClickSlide(companyBanners[0].url);
    const lastSlideCloneEl = document.querySelector('[id$=clone06]');
    const firstSlideCloneEl = document.querySelector('[id$=clone07]');
    lastSlideCloneEl?.addEventListener('click', handleClickLastSlideCloneEl);
    firstSlideCloneEl?.addEventListener('click', handleClickFirstSlideCloneEl);

    return () => {
      lastSlideCloneEl?.removeEventListener(
        'click',
        handleClickLastSlideCloneEl,
      );
      firstSlideCloneEl?.removeEventListener(
        'click',
        handleClickFirstSlideCloneEl,
      );
    };
  }, []);

  return (
    <CompanyBannerCarouselBlock>
      <Spin spinning={getLoading}>
        <Splide
          options={{
            autoplay: true,
            interval: 3000,
            type: 'loop',
            perPage: 3,
            perMove: 1,
            focus: 'center',
            gap: '16px',
            arrows: false,
          }}
        >
          {companyBanners.map((companyBanner) => (
            <SplideSlide
              key={companyBanner.name}
              onClick={() => handleClickSlide(companyBanner.url)}
            >
              <img
                src={`https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/banner_${companyBanner.name}.png`}
                alt={companyBanner.name}
              />
            </SplideSlide>
          ))}
        </Splide>
      </Spin>
    </CompanyBannerCarouselBlock>
  );
};

export default CompanyBannerCarousel;
