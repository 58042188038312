import { useParams } from 'react-router-dom';

import DocumentsUpload from 'components/brand/retailer/retailerApply/DocumentsUpload';
import ProductPageTemplate from 'components/brand/retailer/retailerApply/product/ProductPageTemplate';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import { EDocumentCode } from 'types/brand/retailer/retailerApply';
import { Typography } from 'components/system';

const ProductBCOAPage = () => {
  const params = useParams<{ productId: string }>();
  const productId = Number(params.productId);
  return (
    <ProductPageTemplate
      productId={productId}
      subtitle="반제품 성적서"
      notices={[
        '제조사 양식 그대로 업로드해 주세요. PDF 파일의 업로드가 가능합니다.',
        <Typography.Text type="secondary">
          업로드하시는{' '}
          <Typography.Text type="secondary" color="error" bold inline>
            제품표준서 내 반제품의 기준 및 시험방법과 동일
          </Typography.Text>
          해야 합니다.
        </Typography.Text>,
        '초도 입점 제품과 동일한 Lot No.의 반제품 성적서를 업로드해 주세요.',
        <MistakeNoticeItem />,
      ]}
    >
      <DocumentsUpload
        productId={productId}
        documentCode={EDocumentCode.BCOA}
        accept="application/pdf"
      />
    </ProductPageTemplate>
  );
};

export default ProductBCOAPage;
