import { Key, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row, Table, Checkbox } from 'antd';
import styled from 'styled-components';
import { RowSelectionType } from 'antd/lib/table/interface';

import { useBuyers, useProductBuyers } from 'hook/buyer';
import * as buyerActions from 'modules/buyer';
import FooterBox from 'components/FooterBox';
import BuyerAddModal from 'components/modal/buyer/BuyerAddModal';
import { Typography } from 'components/system';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import UpdateLog from 'components/product/UpdateLog';
import CorrectRequestMessageModal from 'components/CorrectRequestMessageModal';
import { useCountryNameKo } from 'service/country';
import { EDocumentCode } from 'types/product';

const ProductBuyersBlock = styled.div`
  .ant-radio-inner::after {
    transition: none;
  }
  .ant-radio-checked .ant-radio-inner::after {
    display: block;
  }
`;

const ProductBuyer = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) => {
  const dispatch = useDispatch();
  const { buyers, loading: getBuyersLoading } = useBuyers(countryId);
  const readOnlyMode = useSelector(
    ({ certificate }: any) => certificate.readOnlyMode,
  );
  const {
    selectedBuyerIds,
    setSelectedBuyerIds,
    productBuyers,
    loading: getProductBuyersLoading,
    addLoading: addProductBuyersLoading,
    addProductBuyers,
  } = useProductBuyers({
    productId,
    countryId,
  });
  const [selectionType, setSelectionType] = useState<RowSelectionType>(
    'checkbox',
  );
  const countryName = useCountryNameKo(countryId);
  useEffect(() => {
    if (countryName === '일본') {
      setSelectionType('radio');
    }
  }, [countryName]);
  const columns = useMemo(
    () => [
      {
        title: '국가',
        render: () => <Typography.Text medium>{countryName}</Typography.Text>,
      },
      {
        title: '바이어',
        render: ({ companyName }: any) => companyName,
      },
      {
        title: '대표',
        render: ({ ceoName }: any) => ceoName,
      },
    ],
    [countryName],
  );
  const handleClickBuyerAdd = () => {
    dispatch(buyerActions.toggleShowAddBuyerModal());
  };
  return (
    <ProductBuyersBlock>
      <Row justify="end" gutter={8}>
        <Col>
          <CorrectRequestMessageModal documentCode={EDocumentCode.BI} />
        </Col>
        <Col>
          {!!productBuyers.length && (
            <UpdateLog
              productId={productId}
              countryId={countryId}
              documentCode={EDocumentCode.BI}
            />
          )}
        </Col>
      </Row>
      <BuyerAddModal countryId={countryId} />
      <Typography.Title gutter={{ bottom: 16 }} style={{ lineHeight: 1 }}>
        바이어 목록
      </Typography.Title>
      <Table
        className="secondary"
        columns={columns}
        dataSource={buyers}
        rowKey="buyerId"
        rowSelection={{
          type: selectionType,
          hideSelectAll: true,
          selectedRowKeys: selectedBuyerIds,
          ...(selectionType === 'checkbox'
            ? {
                renderCell: (checked, buyer) => (
                  <Checkbox
                    checked={checked}
                    disabled={readOnlyMode}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedBuyerIds((draft) =>
                          draft.concat(buyer.buyerId),
                        );
                      } else {
                        setSelectedBuyerIds((draft) =>
                          draft.filter((buyerId) => buyerId !== buyer.buyerId),
                        );
                      }
                    }}
                  />
                ),
              }
            : {
                onChange: (selectedRowKeys: Key[]) => {
                  setSelectedBuyerIds(selectedRowKeys as number[]);
                },
                getCheckboxProps: () => ({
                  disabled: readOnlyMode,
                }),
              }),
        }}
        loading={getBuyersLoading || getProductBuyersLoading}
        pagination={false}
      />
      <Row justify="end" style={{ marginTop: 16 }}>
        <Col>
          <Button onClick={handleClickBuyerAdd} disabled={readOnlyMode}>
            <i className="material-icons primary md-18">add</i>바이어 추가
          </Button>
        </Col>
      </Row>
      <FooterBox style={{ paddingTop: 24 }}>
        <ReadOnlyBackButton readOnly={readOnlyMode}>
          <Button
            type="primary"
            loading={addProductBuyersLoading}
            onClick={addProductBuyers}
          >
            {productBuyers.length ? '보완 완료' : '등록'}
          </Button>
        </ReadOnlyBackButton>
      </FooterBox>
    </ProductBuyersBlock>
  );
};
export default ProductBuyer;
