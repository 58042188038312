import { Col, Row } from 'antd';
import styled, { css } from 'styled-components';

import IngredientCompareBox from 'components/material/ingredient/IngredientCompareBox';
import IngredientSearch from 'components/material/ingredient/IngredientSearch';
import { deviceSize } from 'lib/styles';
import PageTemplate from 'templates/PageTemplate';

const CollapsableCol = styled(Col)<{ collapsedsize: 'sm' | 'md' }>`
  margin-top: 40px;
  ${(props) => css`
    @media ${deviceSize[props.collapsedsize]} {
      display: none;
    }
  `}
`;

const IngredientDictionaryPage = () => {
  return (
    <PageTemplate style={{ paddingBottom: 380 }}>
      <Row>
        <Col span={21}>
          <IngredientSearch />
        </Col>
        <CollapsableCol collapsedsize="sm" span={2} offset={1}>
          <IngredientCompareBox />
        </CollapsableCol>
      </Row>
    </PageTemplate>
  );
};

export default IngredientDictionaryPage;
