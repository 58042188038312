import { Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { useRawMaterialDetail } from 'service/material/rawMaterialDetail';
import styled from 'styled-components';
import CompositionInformations from './CompositionInformations';
import Description from './Description';
import Documents from './Documents';

const RawMaterialDetailBlock = styled.div``;

const RawMaterialDetail = () => {
  const params = useParams<{ materialId: string }>();
  const materialId = Number(params.materialId);
  const { rawMaterialDetail } = useRawMaterialDetail(materialId);
  if (!rawMaterialDetail)
    return (
      <Spin spinning>
        <RawMaterialDetailBlock />
      </Spin>
    );
  const documents = rawMaterialDetail.materialFiles.map(
    ({ documentDisplayName }) => documentDisplayName,
  );
  return (
    <RawMaterialDetailBlock>
      <Description
        materialBasicInformation={rawMaterialDetail.materialBasicInformation}
        compositionInformations={rawMaterialDetail.compositionInformations}
        company={rawMaterialDetail.companyInformation}
      />
      <CompositionInformations
        compositionInformations={rawMaterialDetail.compositionInformations}
      />
      <Documents documents={documents} />
    </RawMaterialDetailBlock>
  );
};

export default RawMaterialDetail;
