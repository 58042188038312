import produce from 'immer';
import { createReducer } from 'typesafe-actions';
import { START_LOADING, FINISH_LOADING } from './actions';

import { LoadingAction, LoadingState } from './types';

const initialState: LoadingState = {};

export default createReducer<LoadingState, LoadingAction>(initialState, {
  [START_LOADING]: (state, { payload: actionType }) =>
    produce(state, (draft) => {
      draft[actionType] = true;
    }),
  [FINISH_LOADING]: (state, { payload: actionType }) =>
    produce(state, (draft) => {
      draft[actionType] = false;
    }),
});
