import MistakeNoticeItem from 'components/MistakeNoticeItem';
import ProductSingleFileContainer from 'containers/product/ProductSingleFileContainer';
import ProductPageTemplate from 'templates/ProductPageTemplate';

const notices = [
  '모든 향료에 대한 IFRA 서류를 영문 PDF 파일로 업로드해 주세요.',
  '원료사에서 제공받은 파일을 그대로 업로드해 주세요.',
  '파일이 여러 개일 경우, 하나의 PDF로 병합하여 업로드해 주세요.',
  <MistakeNoticeItem />,
];

const ProductIFRAPage = () => {
  return (
    <ProductPageTemplate
      subtitle="IFRA Conformity Certificate"
      notices={notices}
      exampleImg="IFRA.jpg"
    >
      <ProductSingleFileContainer documentCode="ifra" />
    </ProductPageTemplate>
  );
};

export default ProductIFRAPage;
