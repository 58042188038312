import MistakeNoticeItem from 'components/MistakeNoticeItem';
import ProductFormulaBreakdown from 'components/product/ProductFormulaBreakdown';
import ProductPageTemplate from 'templates/ProductPageTemplate';

const notices = [
  '하단의 엑셀 서식 다운로드 버튼을 클릭하여 다운로드 받은 양식에 아래 샘플을 참고하여 작성 후, 업로드해 주세요.',
  '브랜드사의 사급원료 또는 INCI, ICID 원료 등재가 안되어 있는 경우 채널톡으로 문의 바랍니다.',
  <>
    업로드 결과화면의 <b>규제 사항</b> 항목은 원료 정보에 대한 참고용이며 인증
    진행 가능 여부와 무관합니다.
  </>,
  <MistakeNoticeItem />,
];

const ProductFormulaBreakdownChinaPage = () => {
  return (
    <ProductPageTemplate
      subtitle="Product Formula Breakdown (China)"
      notices={notices}
      exampleImg="Formula+Breakdown_V3.png"
      exampleImgWidth={920}
    >
      <ProductFormulaBreakdown isChina={true} />
    </ProductPageTemplate>
  );
};

export default ProductFormulaBreakdownChinaPage;
