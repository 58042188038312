import { useParams } from 'react-router-dom';

import ProductDetail from 'components/brand/retailer/retailerApply/product/detail/ProductDetail';
import PageTemplate from 'templates/PageTemplate';

const notices = [
  '유통사별 필요한 서류를 확인하실 수 있으며, 자료를 모두 입력하시면 압축파일로 다운로드 받을 수 있습니다.',
  '각 항목을 클릭하시면, 해당 자료의 상세 화면으로 연결되며 내용을 등록 또는 수정하실 수 있습니다.',
];

const ProductDetailPage = () => {
  const params = useParams<{ productId: string }>();
  const productId = Number(params.productId);

  return (
    <PageTemplate title="국내 플랫폼 입점 서류 관리" notices={notices}>
      <ProductDetail productId={productId} />
    </PageTemplate>
  );
};

export default ProductDetailPage;
