import { Col, Descriptions, Modal, Row } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { Typography } from 'components/system';
import { countryCodeMap } from 'lib/consts';
import { RootState } from 'modules';
import { ADD_INGREDIENT_TO_BOX } from 'modules/material/ingredient';
import CountryProhibitInfosModal from 'components/modal/formula/CountryProhibitInfosModal';
import palette from 'lib/styles/palette';

const Section = styled.div`
  padding: 10px 16px;
  display: flex;
  align-items: center;
  min-height: 100%;
`;

const StyledDescriptions = styled(Descriptions)`
  margin-top: 16px;

  .ant-descriptions-view {
    border-left: none;
    border-right: none;
    border-top: 2px solid ${palette.primary};
    border-radius: 0;
  }
  .ant-descriptions-item-content {
    padding: 0px;
  }

  .ant-descriptions-item-label {
    padding: 10px 16px;
    border-right: none;
  }
`;

const StyledCol = styled(Col)`
  flex: 1 1;
  :not(:last-child) {
    border-right: 1px solid #d8d8d8;
  }
`;

const StyledRow = styled(Row)``;

const StyledDesItem = styled(Descriptions.Item)``;

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}
const IngredientCompareModal = ({ isOpen, onClose }: IProps) => {
  const [selectedCountryName, setSelectedCountryName] = useState('');
  const [selectedCountryInfos, setSelectedCountryInfos] = useState([]);
  const handleCloseCountryInfosModal = useCallback(() => {
    setSelectedCountryName('');
    setSelectedCountryInfos([]);
  }, []);
  const handleClickMore = useCallback((countryInfos, countryNameKo) => {
    setSelectedCountryInfos(countryInfos);
    if (countryNameKo) {
      setSelectedCountryName(countryNameKo);
    }
  }, []);

  const { ingredientBoxs } = useSelector(
    ({ ingredient, loading }: RootState) => ({
      ingredientBoxs: ingredient.ingredientOfBoxs,
      addLoading: loading[ADD_INGREDIENT_TO_BOX],
    }),
    shallowEqual,
  );

  return (
    <Modal visible={isOpen} width={810} footer={null} onCancel={onClose}>
      <Typography.Title>비교하기</Typography.Title>
      <StyledDescriptions column={2} bordered>
        <StyledDesItem style={{ width: 168 }} label="국문명" span={2}>
          <StyledRow justify="space-between">
            {Array.from(Array(3).keys()).map((val) => (
              <StyledCol key={val}>
                <Section>
                  {ingredientBoxs[val] && ingredientBoxs[val].nameKo
                    ? ingredientBoxs[val].nameKo
                    : '-'}
                </Section>
              </StyledCol>
            ))}
          </StyledRow>
        </StyledDesItem>
        <StyledDesItem style={{ width: 168 }} label="INCI Name" span={2}>
          <StyledRow justify="space-between">
            {Array.from(Array(3).keys()).map((val) => (
              <StyledCol key={val}>
                <Section>
                  {ingredientBoxs[val] && ingredientBoxs[val].nameEn
                    ? ingredientBoxs[val].nameEn
                    : '-'}
                </Section>
              </StyledCol>
            ))}
          </StyledRow>
        </StyledDesItem>
        <StyledDesItem style={{ width: 168 }} label="Function" span={2}>
          <StyledRow justify="space-between">
            {[...Array(3).keys()].map((val) => (
              <StyledCol key={val}>
                <Section>
                  {ingredientBoxs[val] && ingredientBoxs[val].functionNames ? (
                    <div style={{ wordBreak: 'break-all' }}>
                      {ingredientBoxs[val].functionNames?.join(', ')}
                    </div>
                  ) : (
                    '-'
                  )}
                </Section>
              </StyledCol>
            ))}
          </StyledRow>
        </StyledDesItem>
        <StyledDesItem
          style={{ width: 168 }}
          label="EWG Skin Deep Score"
          span={2}
        >
          <StyledRow justify="space-between">
            {Array.from(Array(3).keys()).map((val) => (
              <StyledCol key={val}>
                <Section>
                  {ingredientBoxs[val] &&
                  ingredientBoxs[val].scoreRatePicUrl ? (
                    <img
                      src={ingredientBoxs[val].scoreRatePicUrl}
                      alt="ewgScore"
                      style={{
                        width: '20px',
                      }}
                    />
                  ) : (
                    '-'
                  )}
                </Section>
              </StyledCol>
            ))}
          </StyledRow>
        </StyledDesItem>
        <StyledDesItem style={{ width: 168 }} label="국가별 규제 정보" span={2}>
          <StyledRow justify="space-between">
            {Array.from(Array(3).keys()).map((val) => (
              <StyledCol key={val}>
                <Section>
                  {ingredientBoxs[val] &&
                  !!ingredientBoxs[val].countryProhibits?.length ? (
                    <Row gutter={4} justify="center">
                      {ingredientBoxs[val].countryProhibits?.map(
                        ({ countryNameKo }, index) => (
                          <Col key={index}>
                            <img
                              key={index}
                              src={`https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/flag/${countryCodeMap[countryNameKo]}.png`}
                              alt=""
                              onClick={() =>
                                handleClickMore(
                                  ingredientBoxs[val].countryProhibits,
                                  countryNameKo,
                                )
                              }
                              style={{
                                width: '20px',
                                cursor: 'pointer',
                                ...(index % 4 !== 0 && { marginLeft: 2 }),
                              }}
                            />
                          </Col>
                        ),
                      )}
                    </Row>
                  ) : (
                    '-'
                  )}
                </Section>
              </StyledCol>
            ))}
          </StyledRow>
        </StyledDesItem>
      </StyledDescriptions>
      <CountryProhibitInfosModal
        countryInfos={selectedCountryInfos}
        defaultCountryName={selectedCountryName}
        onClose={handleCloseCountryInfosModal}
      />
    </Modal>
  );
};

export default IngredientCompareModal;
