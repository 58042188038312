import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, message, Modal } from 'antd';

import { IMicrobiologicalPropertiesForm } from 'types/product';
import { addProductMicrobiologicalProperty } from 'modules/product';

const microbiologicalProperties = [
  {
    label: `총호기성생균 (세균 + 진균)`,
    name: 'isTotalABacteria',
  },
  {
    label: 'Staphylococcus aureus 황색포도상구균',
    name: 'isSAureus',
  },
  { label: 'Escherichia coli 대장균', name: 'isEColi' },
  { label: 'Pseudomonas aeruginosa 녹농균', name: 'isPAeruginosa' },
];

export const useMicrobiologicalProperties = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm<IMicrobiologicalPropertiesForm>();

  const { fetchLoading, productId } = useSelector(
    ({ loading, product }: any) => ({
      fetchLoading: loading['product/ADD_PRODUCT_MICROBIOLOGICAL_PROPERTY'],
      productId: product.currentProduct.productId,
    }),
  );
  const [checkAll, setCheckAll] = useState(false);
  const handleChangeCheckAll = () => {
    if (!checkAll) {
      Modal.confirm({
        icon: null,
        content: "모든 항목을 '검출되지 않음'으로 변경하시겠습니까?",
        onOk: () => {
          setCheckAll(true);
          form.setFieldsValue(
            Object.fromEntries(
              microbiologicalProperties.map(({ name }) => [name, false]),
            ),
          );
        },
      });
    } else {
      setCheckAll(false);
      form.setFieldsValue(
        Object.fromEntries(
          microbiologicalProperties.map(({ name }) => [name, undefined]),
        ),
      );
    }
  };
  const onSubmit = (formValue: IMicrobiologicalPropertiesForm) => {
    if (Object.values(formValue).includes(true)) {
      return message.warning(
        '기준 이상의 미생물이 검출된 제품의 경우, 인증을 진행하실 수 없습니다.',
      );
    }
    dispatch(addProductMicrobiologicalProperty({ productId, ...formValue }));
  };

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      form.setFieldsValue({
        isTotalABacteria: false,
        isSAureus: false,
        isEColi: false,
        isPAeruginosa: false,
      });
    }
  }, []);

  return useMemo(
    () => ({
      form,
      checkAll,
      microbiologicalProperties,
      fetchLoading,
      onClickCheckAll: handleChangeCheckAll,
      onChangeCheckAll: setCheckAll,
      onSubmit,
    }),
    [fetchLoading, checkAll, handleChangeCheckAll, setCheckAll],
  );
};
