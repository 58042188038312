import { Modal } from 'antd';
import PaymentInfo from 'components/estimate/PaymentInfo';
import FileViewer from 'components/file/FileViewer';

const PaymentInfoModal = ({
  estimateUrl,
  onClose,
}: {
  estimateUrl: string | null;
  onClose: () => void;
}) => {
  return (
    <Modal
      title="입금 정보"
      width={992}
      visible={estimateUrl !== null}
      footer={null}
      onCancel={onClose}
    >
      <FileViewer
        title={undefined}
        style={{ marginLeft: 'auto', marginRight: 'auto', marginBottom: 32 }}
        file={estimateUrl}
      />
      <PaymentInfo />
    </Modal>
  );
};

export default PaymentInfoModal;
