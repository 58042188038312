import { Route, Switch } from 'react-router-dom';

import path from 'lib/path';
import RawMaterialBasicPage from 'pages/material/rawMaterial/RawMaterialBasicPage';
import RawMaterialDocumentsPage from 'pages/material/rawMaterial/RawMaterialDocumentsPage';
import RawMaterialSearchPage from 'pages/material/rawMaterial/RawMaterialSearchPage';
import RawMaterialDetailPage from 'pages/material/rawMaterial/RawMaterialDetailPage';
import RawMaterialCertificationPage from 'pages/material/rawMaterial/RawMaterialCertificationPage';
import RawMaterialCoaPage from 'pages/material/rawMaterial/RawMaterialCoaPage';
import RawMaterialMSDSPage from 'pages/material/rawMaterial/RawMaterialMSDSPage';
import RawMaterialMarketingPage from 'pages/material/rawMaterial/RawMaterialMarketingPage';
import Error404Page from 'pages/error/Error404Page';
import RawMaterialAllergensListPage from 'pages/material/rawMaterial/RawMaterialAllergensListPage';
import RawMaterialCompositionInformationPage from 'pages/material/rawMaterial/RawMaterialCompositionInformationPage';
import RawMaterialIFRACertificatePage from 'pages/material/rawMaterial/RawMaterialIFRACertificatePage';
import RawMaterialEtcDataPage from 'pages/material/rawMaterial/RawMaterialEtcDataPage';

const RawMaterialRouter = () => {
  return (
    <Switch>
      <Route
        path={[
          path.material.rawMaterial.basic,
          `${path.material.rawMaterial.basic}/:materialId`,
        ]}
        exact
        component={RawMaterialBasicPage}
      />
      <Route
        path={path.material.rawMaterial.search}
        exact
        component={RawMaterialSearchPage}
      />
      <Route
        path={`${path.material.rawMaterial.detail}/:materialId`}
        exact
        component={RawMaterialDetailPage}
      />
      <Route
        path={`${path.material.rawMaterial.root}/:materialId`}
        exact
        component={RawMaterialDocumentsPage}
      />
      <Route
        path={[
          path.material.rawMaterial.basic,
          `${path.material.rawMaterial.basic}/:materialId`,
        ]}
        component={RawMaterialBasicPage}
      />
      <Route
        path={[`${path.material.rawMaterial.certification}/:materialId`]}
        component={RawMaterialCertificationPage}
      />
      <Route
        path={`${path.material.rawMaterial.coa}/:materialId`}
        exact
        component={RawMaterialCoaPage}
      />
      <Route
        path={`${path.material.rawMaterial.msds}/:materialId`}
        component={RawMaterialMSDSPage}
      />
      <Route
        path={`${path.material.rawMaterial.marketing}/:materialId`}
        component={RawMaterialMarketingPage}
      />
      <Route
        path={`${path.material.rawMaterial.alle}/:materialId`}
        component={RawMaterialAllergensListPage}
      />
      <Route
        path={`${path.material.rawMaterial.ci}/:materialId`}
        component={RawMaterialCompositionInformationPage}
      />
      <Route
        path={`${path.material.rawMaterial.ifra}/:materialId`}
        component={RawMaterialIFRACertificatePage}
      />
      <Route
        path={`${path.material.rawMaterial.etc}/:materialId`}
        component={RawMaterialEtcDataPage}
      />
      <Route path="*" component={Error404Page} />
    </Switch>
  );
};

export default RawMaterialRouter;
