import { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Form, message } from 'antd';
import { pickBy } from 'lodash';

import ProductInternalResponsibleCompany from 'components/product/ProductInternalResponsibleCompany';
import UpdateLog from 'components/product/UpdateLog';
import { messages } from 'lib/consts';
import { createFormData } from 'lib/file';
import {
  addProductInternalResponsibleCompany,
  getProductInternalResponsibleCompany,
  initializeProductInternalResponsibleCompany,
  updateProductInternalResponsibleCompany,
} from 'modules/product';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';

const ProductInternalResponsibleCompanyContainer = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [file, setFile] = useState(undefined);
  const { productId } = useCurrentProduct();
  const countryId = useCountryId();

  const {
    internalResponsibleCompany,
    documents,
    readOnlyMode,
    getLoading,
    addLoading,
    updateLoading,
  } = useSelector(
    ({ product, certificate, loading }) => ({
      internalResponsibleCompany: product.internalResponsibleCompany,
      documents: certificate.documents,
      readOnlyMode: certificate.readOnlyMode,
      getLoading: loading['product/GET_PRODUCT_INTERNAL_RESPONSIBLE_COMPANY'],
      addLoading: loading['product/ADD_PRODUCT_INTERNAL_RESPONSIBLE_COMPANY'],
      updateLoading:
        loading['product/UPDATE_PRODUCT_INTERNAL_RESPONSIBLE_COMPANY'],
    }),
    shallowEqual,
  );

  const [isVisibleNMPA, setIsVisibleNMPA] = useState(
    internalResponsibleCompany?.isOwnSubsidiary,
  );
  const [isVisibleNMPAInput, setIsVisibleNMPAInput] = useState(
    internalResponsibleCompany?.isCreateNewNMPAAccount,
  );

  const updateMode = useMemo(() => {
    const document = documents.find(
      (document) => document.documentCode === 'irc',
    );
    return document.status !== 'INP';
  }, [documents]);

  useEffect(() => {
    if (updateMode) {
      dispatch(
        getProductInternalResponsibleCompany({
          productId,
          countryId,
        }),
      );
      return () => {
        dispatch(initializeProductInternalResponsibleCompany());
      };
    }
  }, [productId, countryId]);

  useEffect(() => {
    if (internalResponsibleCompany) {
      const {
        companyEmail,
        companyTel,
        isCreateNewNMPAAccount,
        isOwnSubsidiary,
        newNMPAAccountId,
        newNMPAAccountPassword,
      } = internalResponsibleCompany;
      form.setFieldsValue({
        companyEmail,
        companyTel,
        isOwnSubsidiary,
        isCreateNewNMPAAccount,
        newNMPAAccountId,
        newNMPAAccountPassword,
      });

      setIsVisibleNMPA(isOwnSubsidiary);
      setIsVisibleNMPAInput(isCreateNewNMPAAccount);
    }
  }, [internalResponsibleCompany]);

  const onFormFinished = () => {
    if (!updateMode) {
      const filteredFormValues = pickBy(
        {
          productId,
          countryId,
          ...form.getFieldsValue(),
          file,
        },
        (value) => value !== undefined,
      );

      dispatch(
        addProductInternalResponsibleCompany(
          createFormData({
            ...filteredFormValues,
          }),
        ),
      );
    } else {
      const {
        companyEmail,
        companyTel,
        isCreateNewNMPAAccount,
        isOwnSubsidiary,
        newNMPAAccountId,
        newNMPAAccountPassword,
      } = form.getFieldsValue();

      const updateFormValues = {
        ...(companyEmail !== internalResponsibleCompany.companyEmail && {
          companyEmail,
        }),
        ...(companyTel !== internalResponsibleCompany.companyTel && {
          companyTel,
        }),
        ...(isCreateNewNMPAAccount !==
          internalResponsibleCompany.isCreateNewNMPAAccount && {
          isCreateNewNMPAAccount,
        }),
        ...(isOwnSubsidiary !== internalResponsibleCompany.isOwnSubsidiary && {
          isOwnSubsidiary,
        }),
        ...(newNMPAAccountId !==
          internalResponsibleCompany.newNMPAAccountId && {
          newNMPAAccountId,
        }),
        ...(newNMPAAccountPassword !==
          internalResponsibleCompany.newNMPAAccountPassword && {
          newNMPAAccountPassword,
        }),
      };

      const filteredFormValues = pickBy(
        {
          ...updateFormValues,
          file,
        },
        (value) => value !== undefined,
      );

      if (Object.keys(filteredFormValues).length === 0) {
        return message.warn(messages.NO_NEED_TO_UPDATE);
      }

      dispatch(
        updateProductInternalResponsibleCompany(
          createFormData({
            productId,
            countryId,
            internalResponsibleCompanyId:
              internalResponsibleCompany.internalResponsibleCompanyId,
            ...filteredFormValues,
          }),
        ),
      );
    }
  };

  return (
    <>
      {updateMode && (
        <UpdateLog
          productId={productId}
          countryId={countryId}
          documentCode="irc"
        />
      )}
      <ProductInternalResponsibleCompany
        updateMode={updateMode}
        form={form}
        fileName={internalResponsibleCompany?.fileName}
        uploadFileUrl={internalResponsibleCompany?.documentFile}
        onFormFinished={onFormFinished}
        onChangeFile={setFile}
        isOwnSubsidiary={internalResponsibleCompany?.isOwnSubsidiary}
        isCreateNewNMPAAccount={
          internalResponsibleCompany?.isCreateNewNMPAAccount
        }
        bizLicenseFile={file}
        isVisibleNMPA={isVisibleNMPA}
        isVisibleNMPAInput={isVisibleNMPAInput}
        setIsVisibleNMPA={setIsVisibleNMPA}
        setIsVisibleNMPAInput={setIsVisibleNMPAInput}
        readOnlyMode={readOnlyMode}
        getLoading={!!getLoading}
        submitLoading={addLoading || updateLoading}
      />
    </>
  );
};

export default ProductInternalResponsibleCompanyContainer;
