import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Col, message, Row, Form } from 'antd';

import { messages } from 'lib/consts';
import { addProductCGMP, updateProductCGMP } from 'modules/product';
import ProductCGMP from 'components/product/ProductCGMP';
import UpdateLog from 'components/product/UpdateLog';
import CorrectRequestMessageModal from 'components/CorrectRequestMessageModal';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';
import { EDocumentCode } from 'types/product';
import { useProductDocStatus } from 'service/product';

const ProductCGMPContainer = () => {
  const dispatch = useDispatch();
  const { productId } = useCurrentProduct();
  const countryId = useCountryId();
  const productDocStatus = useProductDocStatus({
    productId,
    countryId,
    documentCode: EDocumentCode.CGMP,
  });
  const { currentProduct, readOnlyMode, submitLoading } = useSelector(
    ({ product, certificate, loading }: any) => ({
      currentProduct: product.currentProduct,
      readOnlyMode: certificate.readOnlyMode,
      submitLoading:
        loading['product/ADD_PRODUCT_CGMP'] ||
        loading['product/UPDATE_PRODUCT_CGMP'],
    }),
    shallowEqual,
  );
  const updateMode = productDocStatus !== null;
  const [form] = Form.useForm();
  const [file, setFile] = useState<{ url: string; name: string } | null>(null);
  const handleDelete = () => {
    setFile(null);
  };
  const handleSubmit = ({
    isCGMP,
    ISOExpiredDate,
  }: {
    isCGMP: boolean;
    ISOExpiredDate: string;
  }) => {
    if (!file) {
      return message.warn(messages.REQUIRED_FILE);
    }
    if (!updateMode) {
      dispatch(
        addProductCGMP({
          productId: currentProduct.productId,
          file,
          isCGMP,
          ISOExpiredDate: ISOExpiredDate.replace(/-/g, ''),
        }),
      );
    } else {
      const updateValues = {
        ...(!file.url && { file }),
        ...(ISOExpiredDate.replace(/-/g, '') !==
          currentProduct.productDetail.iso22716ExpireDate && {
          ISOExpiredDate: ISOExpiredDate.replace(/-/g, ''),
        }),
      };
      if (Object.keys(updateValues).length === 0)
        return message.warn(messages.NO_NEED_TO_UPDATE);
      dispatch(
        updateProductCGMP({
          productId,
          ...(countryId && { countryId }),
          ...updateValues,
        }),
      );
    }
  };

  useEffect(() => {
    if (productDocStatus) {
      setFile({
        name: productDocStatus.filename,
        url: productDocStatus.uploadFileUrl,
      });
    }
  }, [productDocStatus]);

  useEffect(() => {
    form.setFieldsValue({
      isCGMP:
        currentProduct.productDetail.iso22716ExpireDate === '' ||
        currentProduct.productDetail.iso22716ExpireDate === null,
      ISOExpiredDate: currentProduct.productDetail.iso22716ExpireDate
        ? currentProduct.productDetail.iso22716ExpireDate.replace(
            /(\d{4})(\d{2})(\d{2})/,
            '$1-$2-$3',
          )
        : '',
    });
  }, []);

  return (
    <>
      <Row justify="end" gutter={8}>
        <Col>
          <CorrectRequestMessageModal documentCode={EDocumentCode.CGMP} />
        </Col>
        {updateMode && (
          <Col>
            <UpdateLog
              productId={productId}
              countryId={countryId}
              documentCode={EDocumentCode.CGMP}
            />
          </Col>
        )}
      </Row>
      <ProductCGMP
        updateMode={updateMode}
        readOnlyMode={readOnlyMode}
        form={form}
        file={file}
        loading={submitLoading}
        onUpload={setFile}
        onDelete={handleDelete}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default ProductCGMPContainer;
