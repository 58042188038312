import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { Form, Modal } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import moment from 'moment';

import path from 'lib/path';
import palette from 'lib/styles/palette';
import ProductBasicForm from 'components/product/ProductBasicForm';
import { useManufacturerCompanies } from 'service/manufacturer/company';
import { IProduct } from 'types/product';
import {
  useAddProduct,
  useGetDuplicatedProduct,
} from 'service/brand/product/product';

const ProductBasic = () => {
  const history = useHistory();
  const [form] = Form.useForm();

  const { data: manufacturers = [] } = useManufacturerCompanies();
  const { user, company } = useSelector(
    ({ auth, company }: any) => ({
      company: company.company,
      user: auth.user,
    }),
    shallowEqual,
  );
  const { companyId } = user;

  const {
    getDuplicatedProduct,
    getDuplicatedProductLoading,
  } = useGetDuplicatedProduct();
  const handleSubmit = () => {
    let {
      productNameEn,
      netWeightUnit,
      netWeight,
      netWeightUnitDirect,
    } = form.getFieldsValue();
    if (netWeightUnit === 'others') {
      netWeightUnit = netWeightUnitDirect;
    }
    getDuplicatedProduct(
      {
        companyId,
        productNameEn,
        netWeight,
        netWeightUnit,
      },
      {
        onSuccess: (response) => {
          const product = response.data.result;
          if (!product) {
            Modal.confirm({
              width: 420,
              content: (
                <>
                  <span style={{ color: palette.primary }}>*</span>
                  화장품제조업자(영문)와{' '}
                  <span style={{ color: palette.primary }}>*</span>
                  화장품제조업자 주소(영문)를 cGMP
                  <br /> 또는 ISO22716에 등록된 영문명과 영문 주소로 동일하게
                  입력하셨습니까?
                </>
              ),
              okText: '네, 동일하게 입력했습니다.',
              cancelText: '아니오',
              icon: null,
              onOk: () => {
                submitProduct();
              },
            });
          } else {
            if (product.status === 'PRD-RDY') {
              Modal.confirm({
                width: 420,
                style: { textAlign: 'center' },
                content: (
                  <p>
                    이미 등록 중인 제품명과 용량입니다.
                    <br />
                    해당 제품을 이어서 등록하시겠습니까?
                  </p>
                ),
                okText: '네',
                cancelText: '아니오',
                icon: null,
                onOk: () => {
                  handleOkProductDupCheckModal(product);
                },
              });
            } else {
              Modal.info({
                width: 420,
                style: { textAlign: 'center' },
                content: (
                  <p>
                    기존에 등록한 제품 중 같은 이름으로
                    <br />
                    등록된 동일 용량의 제품이 있습니다.
                  </p>
                ),
                okText: '확인',
                cancelText: null,
                icon: null,
              });
            }
          }
        },
      },
    );
  };

  const { addProduct, addProductLoading } = useAddProduct();
  const submitProduct = () => {
    let {
      netWeightUnitDirect,
      netWeightUnit,
      ...restFormValues
    } = form.getFieldsValue();
    if (netWeightUnit === 'others') netWeightUnit = netWeightUnitDirect;
    addProduct(
      {
        ...restFormValues,
        netWeightUnit,
        manufacturerNameKo: manufacturers.find(
          ({ manufacturerId }) =>
            manufacturerId === form.getFieldValue('manufacturerId'),
        )?.companyNameKo,
        companyId,
      },
      {
        onSuccess: (response) => {
          const product = response.data.result;
          history.replace(
            `${path.retailer.retailerApply.product.cat}/${product.productId}`,
          );
        },
      },
    );
  };

  const handleOkProductDupCheckModal = (duplicatedProduct: IProduct) => {
    // HINT : 중복된 제품의 카테고리가 입력전이면 카테고리 등록 페이지로 바로 이동
    if (!duplicatedProduct.productCategory) {
      return history.replace(
        `${path.retailer.retailerApply.product.cat}/${duplicatedProduct.productId}`,
      );
    }
    // HINT : 중복된 제품의 입점 상세 관리로 이동.
    history.replace(
      `${path.retailer.retailerApply.product.root}/${duplicatedProduct.productId}`,
    );
  };

  useEffect(() => {
    form.setFieldsValue({
      brandCompanyNameKo: company.companyNameKo,
      brandCompanyNameEn: company.companyNameEn,
      brandCompanyAddressKo: company.addressKo,
      brandCompanyAddressEn: company.addressEn,
    });
  }, [company]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      form.setFieldsValue({
        brandNameEn: 'Dowith',
        productNameKo: '두윗 세럼',
        productNameEn: `Dowith Serum-${moment().format('YY-MM-DD HH:mm')}`,
        manufacturerNameEn: 'LK Systems',
        manufacturerAddressEn:
          '12-11 Doekgogae-gil, Jeonui-myeon, Sejong Metropolitan Autonomous City',
        manufacturerTel: '0212345678',
        manufacturerFax: '0212345679',
        netWeight: 10,
        netWeightUnit: 'mL',
      });
    }
  }, []);

  return (
    <ProductBasicForm
      form={form}
      fetchLoading={getDuplicatedProductLoading || addProductLoading}
      manufacturers={manufacturers}
      onFormFinished={handleSubmit}
    />
  );
};

export default ProductBasic;
