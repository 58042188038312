import { useMemo } from 'react';
import styled from 'styled-components';
import { Button, Col, Row, Statistic, Table } from 'antd';
import { PaperClipOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';

import { downloadFile } from 'lib/file';
import StatisticCard from 'components/StatisticCard';

const CertificateDocumentsBlock = styled.div``;

const CertificateDocuments = ({
  documents,
  certificate,
  getLoading,
  downloadFilesLoading,
  isMobile,
  onPreview,
  onDownload,
}: any) => {
  const {
    certRequestNo,
    manufacturerNameEn,
    certRegisterNo,
    certFinYmd,
    certDocUrl,
    cpsrUploadFileUrl,
    estimateTarget: { productDetails, countryName, netWeight, netWeightUnit },
  } = certificate;
  const columns: ColumnsType<any> = useMemo(
    () => [
      ...(!isMobile
        ? [
            {
              title: 'No.',
              dataIndex: 'no',
              align: 'center' as const,
              width: '20%',
            },
          ]
        : []),
      {
        title: '문서명',
        dataIndex: 'documentName',
      },
      {
        title: '미리보기',
        align: 'center',
        render: (row) => {
          if (row.documentCode === 'rmcoa' || row.documentCode === 'rmmsds') {
            return (
              <PaperClipOutlined
                style={{ fontSize: 18, cursor: 'pointer' }}
                onClick={() => {
                  downloadFile(row.uploadFileUrl, row.documentName);
                }}
              />
            );
          }
          return (
            <i
              className="material-icons outlined black cursor"
              onClick={() => {
                onPreview(row);
              }}
            >
              description
            </i>
          );
        },
      },
    ],
    [],
  );
  return (
    <CertificateDocumentsBlock>
      <StatisticCard>
        <Row gutter={[16, 24]}>
          <Col
            sm={{ span: 7 }}
            xs={{ span: 12 }}
            style={{ textAlign: 'center' }}
          >
            <Statistic title="인증 신청 번호" value={certRequestNo} />
          </Col>
          <Col
            sm={{ span: 10 }}
            xs={{ span: 12 }}
            style={{ textAlign: 'center' }}
          >
            <Statistic
              title="제품명 (용량)"
              value={`${productDetails[0].productNameEn} ${
                netWeight ? `(${netWeight} ${netWeightUnit})` : '( - )'
              }`}
            />
          </Col>
          <Col
            sm={{ span: 7 }}
            xs={{ span: 12 }}
            style={{ textAlign: 'center' }}
          >
            <Statistic title="제조사" value={manufacturerNameEn} />
          </Col>
          <Col
            sm={{ span: 7 }}
            xs={{ span: 12 }}
            style={{ textAlign: 'center' }}
          >
            <Statistic title="국가" value={countryName} />
          </Col>
          <Col
            sm={{ span: 10 }}
            xs={{ span: 12 }}
            style={{ textAlign: 'center' }}
          >
            <Statistic
              title="인증 등록 번호"
              valueRender={() => certRegisterNo || '-'}
            />
          </Col>
          <Col
            sm={{ span: 7 }}
            xs={{ span: 12 }}
            style={{ textAlign: 'center' }}
          >
            <Statistic title="인증 등록 완료일" value={certFinYmd} />
          </Col>
        </Row>
      </StatisticCard>
      <Row justify="center" gutter={[16, 16]} style={{ marginTop: 40 }}>
        {cpsrUploadFileUrl && (
          <Col>
            <Button
              icon={<i className="material-icons outlined md-18">assignment</i>}
              style={{ width: 200 }}
              onClick={() => onPreview({ documentUrl: cpsrUploadFileUrl })}
            >
              CPSR 확인
            </Button>
          </Col>
        )}
        <Col>
          <Button
            icon={<i className="material-icons outlined md-18">assignment</i>}
            style={{ width: 200 }}
            onClick={() => onPreview({ documentUrl: certDocUrl })}
          >
            등록증 확인
          </Button>
        </Col>
        <Col>
          <Button
            loading={downloadFilesLoading}
            icon={
              <i className="material-icons outlined md-18">file_download</i>
            }
            style={{ width: 200 }}
            onClick={onDownload}
          >
            모든 서류 다운로드
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        rowKey={({ documentCode }) => documentCode}
        dataSource={documents}
        pagination={false}
        loading={getLoading}
        style={{ marginTop: 40 }}
      />
    </CertificateDocumentsBlock>
  );
};

export default CertificateDocuments;
