import { useEffect } from 'react';
import { Button, Col, Form, message, Modal, Radio, Row, Spin } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { requireRule } from 'lib/validate';
import { messages } from 'lib/consts';
import { toggleSeriesProductInfoModalVisible } from 'modules/certificate';
import UpdateLog from 'components/product/UpdateLog';
import CorrectRequestMessageModal from 'components/CorrectRequestMessageModal';
import { Tip, Typography } from 'components/system';
import { useSeriesProductInfo } from 'service/brand/certificate/certificateDetail';
import { EDocumentCode } from 'types/product';
import { useCurrentProduct } from 'service/brand/product/product';

const FormWrapper = styled.div`
  .ant-form-item-control-input {
    min-height: 0;
  }
`;

const SeriesProductInfoModal = ({ certTargetId }: { certTargetId: number }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm<{ isSeriesProduct: boolean }>();
  const visible = useSelector(
    ({ certificate }: any) => certificate.seriesProductInfoModalVisible,
  );
  const { productDetail } = useCurrentProduct();
  const { estimateTarget, readOnlyMode } = useSelector(
    ({ certificate }: any) => ({
      estimateTarget: certificate.certificate.estimateTarget,
      readOnlyMode: certificate.readOnlyMode,
    }),
    shallowEqual,
  );
  const { productId, countryId } = estimateTarget;
  const {
    isSeriesProduct,
    getLoading,
    updateLoading,
    updateSeriesInfo,
  } = useSeriesProductInfo(productId, countryId, certTargetId);
  const onClose = () => {
    dispatch(toggleSeriesProductInfoModalVisible());
  };
  const onSubmit = ({ isSeriesProduct }: { isSeriesProduct: boolean }) => {
    const updateValues = {
      ...(isSeriesProduct !== productDetail.isSeriesProduct && {
        isSeriesProduct,
      }),
    };
    if (!Object.keys(updateValues).length) {
      return message.warn(messages.NO_NEED_TO_UPDATE);
    }
    updateSeriesInfo({
      productId,
      countryId,
      isSeriesProduct: updateValues.isSeriesProduct!,
    });
  };
  useEffect(() => {
    if (isSeriesProduct !== null) {
      form.setFieldsValue({
        isSeriesProduct: isSeriesProduct,
      });
    }
  }, [isSeriesProduct]);
  return (
    <Modal visible={visible} footer={null} destroyOnClose onCancel={onClose}>
      <Row align="middle" gutter={8}>
        <Col>
          <Typography.Title>시리즈 제품 여부</Typography.Title>
        </Col>
        <Col>
          <Tip trigger="click">
            <Typography.Text type="tertiary">
              색조화장품과 같이 하나의 품목에 여러 개 호수가 있는 경우,
              <br /> 해당 제품은 시리즈로 등록이 가능합니다.
            </Typography.Text>
          </Tip>
        </Col>
      </Row>
      <Row justify="end" gutter={8}>
        <Col>
          <CorrectRequestMessageModal documentCode={EDocumentCode.SPI} />
        </Col>
        <Col>
          <UpdateLog
            productId={productId}
            countryId={countryId}
            documentCode={EDocumentCode.SPI}
            validCodes={['SI01-ISP']}
          />
        </Col>
      </Row>
      <Spin spinning={getLoading}>
        <FormWrapper>
          <Form
            form={form}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 16,
            }}
            onFinish={onSubmit}
          >
            <Typography.Text>
              해당 제품이 시리즈로 등록 가능한 제품인가요?
            </Typography.Text>
            <Form.Item
              name="isSeriesProduct"
              rules={[requireRule]}
              style={{ marginLeft: 32, marginTop: 12 }}
            >
              <Radio.Group disabled={readOnlyMode}>
                <Radio value={true} style={{ marginRight: 32, lineHeight: 0 }}>
                  네
                </Radio>
                <Radio value={false} style={{ lineHeight: 0 }}>
                  아니요
                </Radio>
              </Radio.Group>
            </Form.Item>
            {!readOnlyMode && (
              <Button
                type="primary"
                loading={updateLoading}
                htmlType="submit"
                style={{ width: 120, marginTop: 12 }}
                disabled={readOnlyMode}
              >
                {!isSeriesProduct !== null ? '등록' : '수정'}
              </Button>
            )}
          </Form>
        </FormWrapper>
      </Spin>
    </Modal>
  );
};

export default SeriesProductInfoModal;
