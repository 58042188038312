import styled from 'styled-components';

import palette from 'lib/styles/palette';
import { Typography } from 'components/system';

interface IProps {
  icon: React.ReactNode;
  label: string;
  description: string;
  onClick: () => void;
}

const ShortcutBlock = styled.div`
  cursor: pointer;
  border: 1px solid ${palette.inactive};
  border-radius: 12px;
  padding: 24px;

  &:hover {
    border-color: ${palette.primary};
    box-shadow: 0 2px 22px 0 rgba(162, 162, 162, 0.23);
  }
`;

const Shortcut = ({ icon, label, description, onClick }: IProps) => {
  return (
    <ShortcutBlock onClick={onClick}>
      {icon}
      <Typography.Title
        type="secondary"
        gutter={{ top: 24 }}
        style={{ lineHeight: 1.33 }}
      >
        {label}
      </Typography.Title>
      <Typography.Text
        type="secondary"
        color="gray"
        style={{ minHeight: 40, lineHeight: 'normal', wordBreak: 'keep-all' }}
      >
        {description}
      </Typography.Text>
    </ShortcutBlock>
  );
};

export default Shortcut;
