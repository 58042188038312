import { useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import * as H from 'history';
import { Button, Modal } from 'antd';

import { Typography } from './system';
import FooterBox from './FooterBox';
import path from 'lib/path';

interface Props {
  when?: boolean;
  shouldBlockNavigation?: (nextLocation: H.Location) => boolean;
  title?: string;
  description?: string;
}

const RouteLeaveGuard = ({
  when = true,
  shouldBlockNavigation = () => true,
  title,
  description = '정말로 페이지를 벗어나시겠습니까?',
}: Props) => {
  const history = useHistory();
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<H.Location | undefined>();
  const [lastAction, setLastAction] = useState<H.Action | undefined>();
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const showModal = (location: H.Location) => {
    setModalVisible(true);
    setLastLocation(location);
  };

  const closeModal = (cb?: () => void) => {
    setModalVisible(false);
    if (cb) {
      cb();
    }
  };

  const handleBlockedNavigation = (
    nextLocation: H.Location,
    action: H.Action,
  ) => {
    if (
      nextLocation.pathname !== path.login &&
      !confirmedNavigation &&
      shouldBlockNavigation(nextLocation)
    ) {
      showModal(nextLocation);
      setLastAction(action);
      return false;
    }
    return true;
  };
  const handleConfirmNavigationClick = () => {
    closeModal(() => {
      if (lastLocation) {
        setConfirmedNavigation(true);
      }
    });
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      if (lastAction === 'PUSH') {
        history.push(lastLocation.pathname);
      } else if (lastAction === 'REPLACE') {
        history.replace(lastLocation.pathname);
      } else if (lastAction === 'POP') {
        history.goBack();
      }
    }
  }, [confirmedNavigation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <Modal
        visible={modalVisible}
        cancelText
        width={720}
        footer={null}
        onCancel={() => closeModal()}
      >
        {title && (
          <Typography.Title
            medium
            style={{ textAlign: 'center' }}
            gutter={{ bottom: 16 }}
          >
            {title}
          </Typography.Title>
        )}
        <Typography.Text
          style={{ textAlign: 'center', whiteSpace: 'pre-line' }}
        >
          {description}
        </Typography.Text>
        <FooterBox modal={true}>
          <Button onClick={handleConfirmNavigationClick}>나가기</Button>
          <Button type="primary" onClick={() => closeModal()}>
            계속 입력
          </Button>
        </FooterBox>
      </Modal>
    </>
  );
};

export default RouteLeaveGuard;
