import { useMemo } from 'react';
import { Modal, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { Typography } from 'components/system';
import { useEstimateHistories } from 'hook/estimate';

const EstimateHistoriesModal = ({ estimateId, onClose, onPreview }: any) => {
  const { loading, estimateHistories } = useEstimateHistories(estimateId);
  const columns: ColumnsType<any> = useMemo(
    () => [
      { title: 'No.', dataIndex: 'no', align: 'center' },
      {
        title: '견적서 번호',
        dataIndex: 'estimateCode',
        align: 'center',
      },
      {
        title: '견적 금액 (부가세 별도)',
        dataIndex: 'totalPrice',
        align: 'center',
        render: (totalPrice: number) => `${totalPrice.toLocaleString()}원`,
      },
      {
        title: '견적서',
        dataIndex: 'fileUrl',
        align: 'center',
        render: (fileUrl: string) => (
          <i
            className="material-icons outlined black cursor"
            onClick={() => onPreview(fileUrl)}
          >
            description
          </i>
        ),
      },
    ],
    [],
  );

  return (
    <Modal visible={estimateId} width={768} footer={null} onCancel={onClose}>
      <Typography.Title gutter={{ bottom: 16 }}>
        견적서 수정 이력
      </Typography.Title>
      <Table
        loading={loading}
        columns={columns}
        dataSource={estimateHistories}
        rowKey={({ estimateHistoryId }) => estimateHistoryId}
        pagination={false}
      />
    </Modal>
  );
};

export default EstimateHistoriesModal;
