import { Empty, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Typography } from 'components/system';
import path from 'lib/path';
import { IProductDocument } from 'types/brand/retailer/retailerApply';
import palette from 'lib/styles/palette';

const Container = styled.div`
  .highlight {
    background: ${palette.lightBlue80};
  }
`;

const PrivateDocumentTable = ({
  privateDocuments,
  productId,
  highlightIds = [],
  hasSelectedId,
}: {
  privateDocuments: IProductDocument[];
  productId: number;
  highlightIds?: number[];
  hasSelectedId: boolean;
}) => {
  const history = useHistory();
  const columns: ColumnsType<IProductDocument> = [
    {
      title: '구분',
      align: 'center',
      width: 70,
      render: (_, __, index) => (
        <Typography.Text type="secondary">{index + 1}</Typography.Text>
      ),
    },
    {
      title: '입력정보',
      align: 'left',
      dataIndex: 'documentName',
      render: (documentName, { documentCode }) => (
        <Typography.Text
          type="secondary"
          color="black"
          style={{ cursor: 'pointer', width: 344 }}
          medium
          onClick={() =>
            history.push(
              `${
                path.retailer.retailerApply.product[
                  documentCode as keyof typeof path.retailer.retailerApply.product
                ]
              }/${productId}`,
            )
          }
        >
          {documentName}
        </Typography.Text>
      ),
    },
    {
      title: '처리현황',
      align: 'center',
      width: 120,
      dataIndex: 'status',
      render: (_, document) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() =>
            history.push(
              `${
                path.retailer.retailerApply.product[
                  document.documentCode as keyof typeof path.retailer.retailerApply.product
                ]
              }/${productId}`,
            )
          }
        >
          {document.retailerApplyDocStatus.status === 'FIN' ? (
            <Typography.Text type="secondary">입력 완료</Typography.Text>
          ) : (
            <Typography.Label type="primary">
              {document.retailerApplyDocStatus.status === 'INP'
                ? '입력 필요'
                : '입력 가능'}
              <i className="material-icons outlined md-12">chevron_right</i>
            </Typography.Label>
          )}
        </div>
      ),
    },
    {
      title: '최근 입력자명',
      align: 'center',
      width: 200,
      render: (_, document) => (
        <Typography.Text type="secondary">
          {document.retailerApplyDocStatus.name
            ? `${document.retailerApplyDocStatus.name}${
                document.retailerApplyDocStatus.companyName
                  ? ` (${document.retailerApplyDocStatus.companyName})`
                  : ''
              }`
            : '-'}
        </Typography.Text>
      ),
    },
    {
      title: '입력일시',
      align: 'center',
      width: 160,
      render: (_, document) => (
        <Typography.Text type="secondary">
          {document.retailerApplyDocStatus.registerDt || '-'}
        </Typography.Text>
      ),
    },
  ];
  return (
    <Container>
      <Table
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                hasSelectedId
                  ? '해당 유통사는 회사 공통 서류만으로 입점이 가능합니다.'
                  : '선택하신 유통사가 없습니다.'
              }
            />
          ),
        }}
        rowClassName={({ retailerApplyDocSeqId }) =>
          highlightIds.includes(retailerApplyDocSeqId) ? 'highlight' : ''
        }
        columns={columns}
        dataSource={privateDocuments}
        pagination={false}
        rowKey="retailerApplyDocSeqId"
      />
    </Container>
  );
};

export default PrivateDocumentTable;
