import styled from 'styled-components';

export const PageTemplateBlock = styled.div`
  position: relative;
  .ant-page-header-heading-left {
    align-items: unset;
  }
  .ant-page-header-back {
    padding-top: 12px;
  }
  .ant-page-header-heading-sub-title {
    padding-top: 8px;
  }
`;

export const NameContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  span.label {
    font-size: 12px;
    padding-top: 3px;
    opacity: 0.5;
    color: #283c54;
    margin-right: 8px;
  }

  p.product-name {
    display: inline-block;
    color: #4e5968;
    font-size: 16px;
    letter-spacing: -0.89px;
    white-space: pre-wrap;
  }
`;
