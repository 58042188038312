import { useParams } from 'react-router-dom';

import DocumentsUpload from 'components/brand/retailer/retailerApply/DocumentsUpload';
import ProductPageTemplate from 'components/brand/retailer/retailerApply/product/ProductPageTemplate';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import { EDocumentCode } from 'types/brand/retailer/retailerApply';

const ProductAIMGPage = () => {
  const params = useParams<{ productId: string }>();
  const productId = Number(params.productId);
  return (
    <ProductPageTemplate
      productId={productId}
      subtitle="실사 이미지"
      notices={[
        '1차, 2차 포장 용기의 필수 표기사항이 확인 가능한 완제품의 실물 사진을 업로드해 주세요.',
        '"1차 포장"이란 화장품 제조 시 내용물과 직접 접촉하는 포장 용기입니다.',
        '"2차 포장"이란 1차 포장을 수용하는 1개 또는 그 이상의 포장과 보호재 및 표시의 목적으로 한 포장(첨부문서 등을 포함)입니다.',
        '이미지 형식(jpg, jpeg, gif 등) 으로 최대 10개의 파일까지 업로드가 가능합니다.',
        <MistakeNoticeItem />,
      ]}
    >
      <DocumentsUpload
        productId={productId}
        documentCode={EDocumentCode.AIMG}
        max={10}
        accept="image/*"
      />
    </ProductPageTemplate>
  );
};

export default ProductAIMGPage;
