import qs from 'qs';

import client from 'lib/api/client';
import { IAPIResponse } from 'types/common';
import {
  ICountryVendor,
  ICountryVendorInfo,
  ICountryVendorInfoAdd,
  ICountryVendorInfoUpdate,
  IProductKeepWarning,
  IProductKeepWarningFetch,
  ISeparateWasteCategory,
  ISeriesProductInfo,
} from 'types/certificate';

export const getCertificates = ({
  page = 1,
  size = 10,
  productNameEn = '',
  countryNameKo = '',
  finishDateLower,
  finishDateUpper,
  companyId,
  buyerId,
  isFinish = false,
}: {
  page: number;
  size: number;
  productNameEn?: string;
  countryNameKo?: string;
  finishDateLower?: string;
  finishDateUpper?: string;
  companyId: number;
  buyerId?: number;
  isFinish: boolean;
}) =>
  client.post(`/cert/all/${page}/${size}`, {
    productNameEn,
    countryNameKo,
    ...(finishDateLower && { lastStatusUpdateDtLower: finishDateLower }),
    ...(finishDateUpper && { lastStatusUpdateDtUpper: finishDateUpper }),
    companyId,
    isFinish,
    buyerId,
  });
export const getCertificate = (certTargetId: number) =>
  client.get(`/cert/${certTargetId}`);
export const getDocuments = (certTargetId: number) =>
  client.get(`/cert/on/detail/${certTargetId}`);
export const getCompletedDocuments = (certTargetId: number) =>
  client.get(`/cert/fin/detail/${certTargetId}`);
export const fixDocument = (productDocStatusId: number) =>
  client.patch(`/doc-status/status/confirm/${productDocStatusId}`);
export const getSeriesInfo = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) =>
  client.get<IAPIResponse<{ isSeriesProduct: boolean }>>(
    `/products/${productId}/series-info?${qs.stringify({ countryId })}`,
  );
export const updateSeriesInfo = ({
  productId,
  countryId,
  isSeriesProduct,
}: ISeriesProductInfo) =>
  client.put(`/products/${productId}/series-info`, {
    countryId,
    isSeriesProduct,
  });

export const getCountryVendor = (countryId: number) =>
  client.get<IAPIResponse<ICountryVendor>>(
    `/partner-vendor?${qs.stringify({ countryId })}`,
  );

export const getCountryVendorInfo = (params: {
  productId: number;
  countryId: number;
}) =>
  client.get<IAPIResponse<ICountryVendorInfo>>(
    `/products/${params.productId}/country-vendor?${qs.stringify({
      countryId: params.countryId,
    })}`,
  );

export const addCountryVendor = (params: ICountryVendorInfoAdd) => {
  const { productId, ...rest } = params;
  return client.post(`/products/${productId}/country-vendor`, rest);
};

export const updateCountryVendor = (params: ICountryVendorInfoUpdate) => {
  const { productId, ...rest } = params;
  return client.patch(`/products/${productId}/country-vendor`, rest);
};

export const getProductKeepWarning = (params: {
  productId: number;
  countryId: number;
}) =>
  client.get<IAPIResponse<IProductKeepWarning>>(
    `/products/${params.productId}/keep-warning?${qs.stringify({
      countryId: params.countryId,
    })}`,
  );
export const getSeparateWasteCategories = () =>
  client.get<IAPIResponse<ISeparateWasteCategory[]>>(
    '/separate-waste/container-materials',
  );

export const getSeparateWasteCategoryIds = (params: {
  productId: number;
  countryId: number;
}) =>
  client.get<
    IAPIResponse<{
      categoryDataIds: number[];
    }>
  >(
    `/products/${params.productId}/separate-waste?${qs.stringify({
      countryId: params.countryId,
    })}`,
  );

export const addProductKeepWarning = (params: IProductKeepWarningFetch) => {
  const { productId, ...rest } = params;
  return client.post(`/products/${productId}/keep-warning`, rest);
};

export const updateProductKeepWarning = (params: IProductKeepWarningFetch) => {
  const { productId, ...rest } = params;
  return client.patch(`/products/${productId}/keep-warning`, rest);
};

export const putSeparateWaste = (params: {
  productId: number;
  countryId: number;
  categoryDataIds: number[];
}) => {
  const { productId, ...rest } = params;
  return client.put(`products/${productId}/separate-waste`, rest);
};
