import { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { message, Typography } from 'antd';

import palette from 'lib/styles/palette';
import * as productActions from 'modules/product';
import ProductDetail from 'components/product/ProductDetail';
import { useCurrentProduct } from 'service/brand/product/product';
import useUpdateEffect from 'hook/useUpdateEffect';

const ProductDetailContainer = () => {
  const { productId } = useCurrentProduct();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    documents,
    currentProduct,
    fetchLoading,
    registerLoading,
  } = useSelector(
    ({ product, loading }: any) => ({
      documents: product.documents,
      currentProduct: product.currentProduct,
      fetchLoading:
        loading['product/GET_DOCUMENTS'] || loading['product/GET_DOC_STATUS'],
      registerLoading: loading['product/REGISTER_PRODUCT'],
    }),
    shallowEqual,
  );
  const registerable = useMemo(
    () =>
      documents.length !== 0 &&
      documents.every(({ status }: any) => status !== 'INP') &&
      currentProduct.status === 'PRD-RDY',
    [documents],
  );
  const handleClickRegister = () => {
    dispatch(productActions.registerProduct({ productId, status: 'PRD-REG' }));
  };
  const handleClickDocument = (document: any) => {
    history.push(
      `/product/${currentProduct.productId}/${document.documentCode}`,
    );
  };
  useEffect(() => {
    dispatch(productActions.getDocuments(productId));
    dispatch(productActions.setSkipMode(false));
  }, []);
  useUpdateEffect(() => {
    if (documents.length && registerable) {
      message.info(
        <>
          모든 정보가 입력 완료되어, 인증을 진행하실 수 있습니다.{' '}
          <Typography.Text style={{ color: palette.primary }}>
            '등록 완료'
          </Typography.Text>{' '}
          버튼을 눌러주세요.
        </>,
      );
    }
  }, [documents]);
  return (
    <ProductDetail
      productId={productId}
      product={currentProduct}
      documents={documents}
      fetchLoading={fetchLoading}
      registerLoading={registerLoading}
      registerable={registerable}
      onClickDocument={handleClickDocument}
      onClickRegister={handleClickRegister}
    />
  );
};

export default ProductDetailContainer;
