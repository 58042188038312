import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Typography } from 'components/system';
import path from 'lib/path';
import {
  EDocumentCode,
  EProductDocumentStatus,
  IProductDocument,
} from 'types/brand/retailer/retailerApply';
import palette from 'lib/styles/palette';

const Container = styled.div<{ highlight: boolean }>`
  ${({ highlight }) =>
    highlight &&
    css`
      .ant-table-wrapper table tr {
        background: ${palette.lightBlue80};
      }
    `}
`;

const SharedDocumentTable = ({
  sharedDocuments,
  productId,
  isNeedOBD,
  highlight = false,
}: {
  sharedDocuments: IProductDocument[];
  productId: number;
  isNeedOBD: boolean;
  highlight?: boolean;
}) => {
  const history = useHistory();
  const getStatusTitle = (isOBD: boolean) => {
    if (isOBD) {
      return isNeedOBD ? '입력 필요' : '입력 가능';
    } else {
      return '입력 필요';
    }
  };

  const columns: ColumnsType<IProductDocument> = [
    {
      title: '구분',
      align: 'center',
      width: 70,
      render: (_, __, index) => (
        <Typography.Text type="secondary">{index + 1}</Typography.Text>
      ),
    },
    {
      title: '입력정보',
      align: 'left',
      dataIndex: 'documentName',
      width: '60%',
      render: (documentName, { documentCode }) => (
        <Typography.Text
          type="secondary"
          color="black"
          style={{ cursor: 'pointer' }}
          medium
          onClick={() =>
            history.push(
              `${
                path.retailer.retailerApply[
                  documentCode as keyof typeof path.retailer.retailerApply
                ]
              }/${productId}`,
            )
          }
        >
          {documentName}
        </Typography.Text>
      ),
    },
    {
      title: '처리현황',
      align: 'center',
      dataIndex: 'status',
      render: (_, document) => {
        return (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() =>
              history.push(
                `${
                  path.retailer.retailerApply[
                    document.documentCode as keyof typeof path.retailer.retailerApply
                  ]
                }/${productId}`,
              )
            }
          >
            {document.retailerApplyDocStatus.status ===
            EProductDocumentStatus.FIN ? (
              <Typography.Text type="secondary">입력 완료</Typography.Text>
            ) : (
              <Typography.Label type="primary">
                {getStatusTitle(document.documentCode === EDocumentCode.OBD)}
                <i className="material-icons outlined md-12">chevron_right</i>
              </Typography.Label>
            )}
          </div>
        );
      },
    },
  ];
  return (
    <Container highlight={highlight}>
      <Table
        columns={columns}
        dataSource={sharedDocuments}
        pagination={false}
        rowKey="retailerApplyDocSeqId"
      />
    </Container>
  );
};

export default SharedDocumentTable;
