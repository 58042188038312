import produce from 'immer';
import { createReducer } from 'typesafe-actions';
import { ADD_INGREDIENT_TO_BOX, DELETE_INGREDIENT_TO_BOX } from './actions';

import { IngredientAction, IngredientState } from './types';

const initialState: IngredientState = {
  ingredientOfBoxs: [],
};

export default createReducer<IngredientState, IngredientAction>(initialState, {
  [ADD_INGREDIENT_TO_BOX]: (state, { payload: ingredientOfBox }) =>
    produce(state, (draft) => {
      draft.ingredientOfBoxs.push(ingredientOfBox);
    }),
  [DELETE_INGREDIENT_TO_BOX]: (state, { payload: index }) =>
    produce(state, (draft) => {
      draft.ingredientOfBoxs.splice(index, 1);
    }),
});
