import qs from 'qs';

import { IAPIResponse } from 'types/common';
import client from 'lib/api/client';
import { IMaterialCompany } from 'types/material/company';

export const getMaterialCompanies = (materialCompaniesParams?: {
  isExcludeUserInserted: boolean;
}) =>
  client.get<IAPIResponse<IMaterialCompany[]>>(
    `/pub/material-companies?${qs.stringify(materialCompaniesParams)}`,
  );
