import ProductStabilityTestContainer from 'containers/product/ProductStabilityTestContainer';
import ProductPageTemplate from 'templates/ProductPageTemplate';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
const notices = [
  'Stability Test 서류의 온도 범위를 하단에 입력해 주세요.',
  <>
    온도 범위 1,2,3 의 경우는 테스트한 온도를 고온 &#8594; 상온 &#8594; 저온
    순서대로 입력해 주세요.
  </>,
  <MistakeNoticeItem />,
];

const ProductStabilityTestPage = () => {
  return (
    <ProductPageTemplate
      subtitle="Stability Test"
      notices={notices}
      exampleImg="Stability_Test_V2.jpg"
    >
      <ProductStabilityTestContainer />
    </ProductPageTemplate>
  );
};

export default ProductStabilityTestPage;
