import { memo } from 'react';
import { Form, Input } from 'antd';
import styled from 'styled-components';

import { requireRule, exceptKoreanRule } from 'lib/validate';
import AlphabetLabel from './AlphabetLabel';

const MSDSStabilityReactivityBlock = styled.div`
  max-width: 536px;
  padding-left: 16px;
  padding-bottom: 32px;
  margin: 0 auto;
`;

const MSDSStabilityReactivity = () => {
  return (
    <MSDSStabilityReactivityBlock>
      <Form.Item
        label={<AlphabetLabel alphabet="A">Chemical stability</AlphabetLabel>}
        name={['stabilityReactivity', 'chemicalStability']}
        rules={[requireRule, exceptKoreanRule]}
        required={false}
        initialValue="Material is stable under normal conditions."
      >
        <Input.TextArea
          placeholder="화학적 안정성"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={500}
          showCount
        />
      </Form.Item>
      <Form.Item
        label={
          <AlphabetLabel alphabet="B">
            Possibility of hazardous reactions
          </AlphabetLabel>
        }
        name={['stabilityReactivity', 'possibilityOfHazardousReactions']}
        rules={[requireRule, exceptKoreanRule]}
        required={false}
        initialValue="Material is stable under normal conditions."
      >
        <Input.TextArea
          placeholder="유해 반응의 가능성"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={500}
          showCount
        />
      </Form.Item>
      <Form.Item
        label={<AlphabetLabel alphabet="C">Conditions to avoid</AlphabetLabel>}
        name={['stabilityReactivity', 'conditionsToAvoid']}
        rules={[requireRule, exceptKoreanRule]}
        required={false}
        initialValue="None"
      >
        <Input.TextArea
          placeholder="피해야 할 조건(정전기 방전, 충격, 진동 등)"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={500}
          showCount
        />
      </Form.Item>
      <Form.Item
        label={
          <AlphabetLabel alphabet="D">Incompatible products</AlphabetLabel>
        }
        name={['stabilityReactivity', 'incompatibleProducts']}
        rules={[requireRule, exceptKoreanRule]}
        required={false}
        initialValue="None"
      >
        <Input.TextArea
          placeholder="피해야 할 물질"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={500}
          showCount
        />
      </Form.Item>
      <Form.Item
        label={
          <AlphabetLabel alphabet="E">
            Hazardous decomposition products
          </AlphabetLabel>
        }
        name={['stabilityReactivity', 'hazardousDecompositionProducts']}
        rules={[requireRule, exceptKoreanRule]}
        required={false}
        initialValue="None"
      >
        <Input.TextArea
          placeholder="분해시 생성되는 유해물질"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={500}
          showCount
        />
      </Form.Item>
      <Form.Item
        label="Thermal decomposition"
        name={['stabilityReactivity', 'thermalDecomposition']}
        rules={[requireRule, exceptKoreanRule]}
        initialValue="None"
      >
        <Input.TextArea
          placeholder="열분해"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={500}
          showCount
        />
      </Form.Item>
    </MSDSStabilityReactivityBlock>
  );
};

export default memo(MSDSStabilityReactivity);
