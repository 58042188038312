import { useState } from 'react';
import styled, { css } from 'styled-components';
import { Button, Col, Row, Upload, Table, Input } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { nanoid } from 'nanoid';

import palette from 'lib/styles/palette';
import FooterBox from 'components/FooterBox';
import { Typography } from 'components/system';
import {
  EProductSampleRecipeDraftInvalidCode,
  EProductSampleRecipeErrorCode,
  IProductSampleRecipe,
  IProductSampleRecipeAdd,
  IProductSampleRecipeDraft,
  IProductSampleRecipeItem,
} from 'types/manufacturer/productSample';
import { StatusIcon } from 'components/system/general/icon';
import {
  useIsProductSampleConfirmed,
  useProductSampleId,
  useProductSampleRecipes,
  useUpdateProductSampleRecipe,
} from 'service/manufacturer/productSample';
import { downloadFile } from 'lib/file';
import { toNoExponents } from 'lib/form';

const RecipeTableBlock = styled.div`
  th.ant-table-cell {
    text-align: center;
  }
  td.ant-table-cell.error {
    outline: 1px solid ${palette.error};
    outline-offset: -1px;
  }
`;

const RecipeTable = ({
  productSampleRecipeId,
  recipeItems,
  isSuccess,
  memo: originalMemo,
  onChangeMemo,
  readOnly = false,
}: {
  productSampleRecipeId?: number;
  recipeItems: IProductSampleRecipeItem[];
  isSuccess?: boolean;
  memo?: string;
  onChangeMemo?: (memo: string) => void;
  readOnly?: boolean;
}) => {
  const productSampleId = useProductSampleId();
  const {
    updateProductSampleRecipe,
    isLoading,
  } = useUpdateProductSampleRecipe();
  const [memo, setMemo] = useState(originalMemo || '');
  const columns: ColumnsType<IProductSampleRecipeItem> = [
    {
      title: 'No.',
      width: '10%',
      align: 'center',
      render: (_, __, index) => index + 1,
      rowSpan: 1,
    },
    {
      title: 'Phase',
      width: '14%',
      align: 'center',
      dataIndex: 'phase',
      onCell: ({ errorCodes }: IProductSampleRecipeItem) => ({
        className: errorCodes?.includes(EProductSampleRecipeErrorCode.PHASE)
          ? 'error'
          : '',
      }),
    },
    {
      title: '원료명',
      width: '25%',
      dataIndex: 'materialName',
      onCell: ({ errorCodes }: IProductSampleRecipeItem) => ({
        className: errorCodes?.includes(
          EProductSampleRecipeErrorCode.MATERIAL_NAME,
        )
          ? 'error'
          : '',
      }),
    },
    {
      title: '원료사',
      dataIndex: 'materialCompanyName',
      onCell: ({ errorCodes }: IProductSampleRecipeItem) => ({
        className: errorCodes?.includes(
          EProductSampleRecipeErrorCode.MATERIAL_COMPANY_NAME,
        )
          ? 'error'
          : '',
      }),
    },
    {
      title: '함량',
      width: '15%',
      align: 'right',
      dataIndex: 'materialPercent',
      onCell: ({ errorCodes }: IProductSampleRecipeItem) => ({
        className: errorCodes?.includes(
          EProductSampleRecipeErrorCode.MATERIAL_PERCENT,
        )
          ? 'material-percent error'
          : 'material-percent',
      }),
      render: (materialPercent: number) => toNoExponents(materialPercent),
    },
    {
      title: '비고',
      width: '15%',
      dataIndex: 'remark',
    },
  ];
  const handleUpdateRecipe = () => {
    updateProductSampleRecipe({
      productSampleId: productSampleId!,
      productSampleRecipe: {
        productSampleRecipeId: productSampleRecipeId!,
        memo,
      },
    });
  };
  return (
    <RecipeTableBlock>
      <Table
        columns={columns}
        dataSource={recipeItems}
        rowKey={() => nanoid()}
        pagination={false}
      />
      {isSuccess !== false && (
        <Input.TextArea
          value={memo}
          onChange={(e) => {
            setMemo(e.target.value);
            onChangeMemo && onChangeMemo(e.target.value);
          }}
          autoFocus={typeof isSuccess !== 'undefined'}
          placeholder="처방에 대한 자유로운 내용을 입력해 주세요."
          maxLength={1000}
          showCount
          autoSize={{ maxRows: 5, minRows: 5 }}
          style={{ marginTop: 8 }}
          disabled={readOnly}
        />
      )}
      {typeof isSuccess === 'undefined' && !readOnly && (
        <Row justify="end" style={{ clear: 'right', marginTop: 8 }}>
          <Col>
            <Button
              type="primary"
              size="small"
              loading={isLoading}
              onClick={handleUpdateRecipe}
            >
              메모 저장
            </Button>
          </Col>
        </Row>
      )}
    </RecipeTableBlock>
  );
};

const RecipeDraftBlock = styled.div<{
  sum_error: 'true' | 'false';
  success: 'true' | 'false';
}>`
  margin-top: 24px;
  ${({ success }) =>
    success === 'false' &&
    css`
      margin-bottom: 36px;
    `}

  .ant-table-wrapper {
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    border-color: ${({ success }) =>
      success === 'true' ? palette.success : palette.error};
  }

  ${({ sum_error }) =>
    sum_error === 'true' &&
    css`
      .material-percent {
        color: ${palette.error} !important;
      }
    `}
`;

const RecipeDraft = ({
  recipeDraft,
  onAddRecipe,
  isLoading,
}: {
  recipeDraft: IProductSampleRecipeDraft;
  onAddRecipe: (productSampleRecipe: IProductSampleRecipeAdd) => void;
  isLoading: boolean;
}) => {
  const { recipeItems, invalidCodes, filename } = recipeDraft;
  const isSuccess = invalidCodes.length === 0;
  const [memo, setMemo] = useState('');

  const handleAddRecipe = () => {
    onAddRecipe({ recipeItems, memo, filename });
  };
  return (
    <RecipeDraftBlock
      success={isSuccess ? 'true' : 'false'}
      sum_error={
        invalidCodes.some(
          ({ code }) =>
            code ===
            EProductSampleRecipeDraftInvalidCode.TOTAL_MATERIAL_PERCENT_INVALID,
        )
          ? 'true'
          : 'false'
      }
    >
      {isSuccess ? (
        <Typography.Text type="secondary" gutter={{ bottom: 8 }}>
          <StatusIcon status="success" />
          정상적인 처방 파일입니다.
        </Typography.Text>
      ) : (
        invalidCodes.map(({ code, message }, index) => (
          <Typography.Text
            key={code}
            type="secondary"
            gutter={{ bottom: index === invalidCodes.length - 1 ? 8 : 4 }}
          >
            <StatusIcon status="error" style={{ marginRight: 8 }} />
            {message}
          </Typography.Text>
        ))
      )}
      <RecipeTable
        recipeItems={recipeItems}
        isSuccess={isSuccess}
        onChangeMemo={setMemo}
      />
      {isSuccess && (
        <FooterBox style={{ clear: 'right', paddingTop: 36 }}>
          <Button type="primary" onClick={handleAddRecipe} loading={isLoading}>
            처방 저장
          </Button>
        </FooterBox>
      )}
    </RecipeDraftBlock>
  );
};

const ProductSampleRecipeBlock = styled.div`
  th.ant-table-row-expand-icon-cell::after {
    content: '처방 보기';
  }

  .ant-table-expanded-row > .ant-table-cell {
    position: relative;
    top: -1px;
    background-color: #fff;
    padding: 16px 0 !important;
  }

  .ant-table-row.new:first-child > .ant-table-cell {
    background-color: ${palette.lightBlue80};
  }
`;

const ProductSampleRecipe = () => {
  const productSampleId = useProductSampleId();
  const {
    validateProductSampleRecipe,
    validateLoading,
    recipeDraft,
    hasNewRecipe,
    productSampleRecipes,
    getLoading,
    addProductSampleRecipe,
    addLoading,
  } = useProductSampleRecipes(productSampleId!);
  const isProductSampleConfirmed = useIsProductSampleConfirmed(productSampleId);
  const columns: ColumnsType<IProductSampleRecipe> = [
    {
      title: 'No.',
      align: 'center',
      render: (_, __, index) => productSampleRecipes.length - index,
    },
    { title: '파일명', width: '50%', dataIndex: 'filename', align: 'left' },
    { title: '등록 날짜', dataIndex: 'registerDt', align: 'center' },
  ];
  return (
    <ProductSampleRecipeBlock>
      {!isProductSampleConfirmed && (
        <Row justify="center" gutter={16} style={{ marginBottom: 36 }}>
          <Col>
            <Button
              type="dashed"
              icon={
                <i className="material-icons outlined primary md-18">
                  file_download
                </i>
              }
              onClick={() =>
                downloadFile(
                  'https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/sample/Recipe_V3.xlsx',
                )
              }
            >
              엑셀 서식 다운로드
            </Button>
          </Col>
          <Col>
            <Upload
              accept=".xlsx"
              beforeUpload={(file) => {
                validateProductSampleRecipe(file);
                return false;
              }}
              itemRender={() => null}
            >
              <Button
                icon={
                  <i className="material-icons outlined primary md-18">
                    file_upload
                  </i>
                }
                loading={validateLoading}
              >
                파일 업로드
              </Button>
            </Upload>
          </Col>
        </Row>
      )}
      {recipeDraft && (
        <RecipeDraft
          recipeDraft={recipeDraft}
          onAddRecipe={addProductSampleRecipe}
          isLoading={addLoading}
        />
      )}
      <Typography.Title gutter={{ bottom: 14 }}>처방 히스토리</Typography.Title>
      <Table
        columns={columns}
        dataSource={productSampleRecipes}
        loading={getLoading}
        className="tertiary"
        rowKey="productSampleRecipeId"
        rowClassName={(_, index) => (hasNewRecipe && index === 0 ? 'new' : '')}
        pagination={false}
        expandable={{
          columnWidth: 120,
          expandedRowRender: ({ productSampleRecipeId, recipeItems, memo }) => (
            <RecipeTable
              productSampleRecipeId={productSampleRecipeId}
              recipeItems={recipeItems}
              memo={memo}
              readOnly={isProductSampleConfirmed}
            />
          ),
          expandIconColumnIndex: 4,
          expandIcon: ({ expanded, record, onExpand }) => (
            <i
              className="material-icons primary cursor"
              style={{ userSelect: 'none' }}
              onClick={(e) => onExpand(record, e)}
            >
              {expanded ? 'expand_less' : 'expand_more'}
            </i>
          ),
        }}
      />
    </ProductSampleRecipeBlock>
  );
};

export default ProductSampleRecipe;
