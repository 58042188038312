import client from 'lib/api/client';
import { ISaveClinicalTrialParams } from 'types/brand/clinicalTrial/estimate';
import { IAPIResponse } from 'types/common';

export const postSaveClinicalTrial = (
  saveClinicalTrialParams: ISaveClinicalTrialParams[],
) =>
  client.post<IAPIResponse<null>>(
    '/clinical-trial/estimates/basket',
    saveClinicalTrialParams,
  );
