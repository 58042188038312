import styled, { css } from 'styled-components';

import { CloseCircleIcon } from 'components/system';

const MSDSPictogramBlock = styled.div<{ removable: boolean }>`
  width: 56px;
  height: 56px;
  cursor: pointer;

  ${({ removable }) =>
    removable &&
    css`
      &:hover {
        img {
          opacity: 0.2;
        }

        .close-circle-icon {
          display: block;
        }
      }
    `}

  img {
    width: 100%;
    height: 100%;
  }

  .close-circle-icon {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const MSDSPictogram = ({
  src,
  removable = false,
  onClick,
}: {
  src?: string;
  removable?: boolean;
  onClick?: () => void;
}) => {
  return (
    <MSDSPictogramBlock onClick={onClick} removable={removable}>
      <img src={src} alt="pictogram" />
      <CloseCircleIcon />
    </MSDSPictogramBlock>
  );
};

export default MSDSPictogram;
