import { Col, Row, Statistic, Table } from 'antd';
import { useMemo } from 'react';
import styled from 'styled-components';
import { ColumnsType } from 'antd/lib/table/interface';

import StatisticCard from 'components/StatisticCard';
import Typography from 'components/system/general/Typography';
import palette from 'lib/styles/palette';

const CertificateDetailBlock = styled.div``;

const CertificateDetail = ({
  certificate,
  documents,
  fixingDocumentIndex,
  fetchLoading,
  fixDocumentLoading,
  onClickDocument,
}: any) => {
  const {
    certRequestNo,
    estimateTarget: {
      countryName,
      countryId,
      productDetails,
      netWeight,
      netWeightUnit,
    },
  } = certificate;
  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: 'No.',
        align: 'center',
        width: '10%',
        render: (_, __, index) => {
          return index + 1;
        },
      },
      {
        title: '입력 정보',
        render: (document) => (
          <Typography.Text
            type="secondary"
            underline
            onClick={() => {
              onClickDocument(document);
            }}
            style={{
              cursor: 'pointer',
              fontWeight: 500,
              color: palette.text.black,
            }}
          >
            {document.documentName}
          </Typography.Text>
        ),
      },
      {
        title: '처리현황',
        align: 'center',
        render: (document) => {
          const { status, statusString } = document;
          return (
            <Row justify="center" align="middle" gutter={8}>
              <Col>
                <Typography.Label
                  type={
                    status === 'INP' || status === 'MOD' || status === 'OPT'
                      ? 'primary'
                      : 'disabled'
                  }
                  onClick={
                    status === 'INP' || status === 'MOD' || status === 'OPT'
                      ? () => onClickDocument(document)
                      : undefined
                  }
                  style={{ minWidth: 66 }}
                >
                  {statusString}
                  {(status === 'INP' ||
                    status === 'MOD' ||
                    status === 'OPT') && (
                    <i className="material-icons outlined md-12">
                      chevron_right
                    </i>
                  )}
                </Typography.Label>
              </Col>
            </Row>
          );
        },
      },
      {
        title: '최근 입력자 명',
        align: 'center',
        render: ({ modifierName, modifierCompanyName }) =>
          modifierName ? `${modifierName} (${modifierCompanyName})` : '',
      },
      { title: '입력 일시', dataIndex: 'updateDate', align: 'center' },
    ],
    [
      onClickDocument,
      fixingDocumentIndex,
      fixDocumentLoading,
      certificate,
      countryId,
    ],
  );

  return (
    <CertificateDetailBlock>
      <StatisticCard>
        <Row wrap={false} gutter={16}>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 6 }}
            style={{ textAlign: 'center' }}
          >
            <Statistic title="인증 신청 번호" value={certRequestNo} />
          </Col>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 8 }}
            style={{ textAlign: 'center' }}
          >
            <Statistic
              title="제품명 (용량)"
              value={`${productDetails[0].productNameEn} ${
                netWeight ? `(${netWeight} ${netWeightUnit})` : '( - )'
              }`}
            />
          </Col>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 5 }}
            style={{ textAlign: 'center' }}
          >
            <Statistic title="국가" value={countryName} />
          </Col>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 5 }}
            style={{ textAlign: 'center' }}
          >
            <Statistic
              title="제조사"
              value={productDetails[0]?.manufacturerNameKo}
            />
          </Col>
        </Row>
      </StatisticCard>
      <Table
        columns={columns}
        rowKey={({ documentCode }) => documentCode}
        dataSource={documents}
        pagination={false}
        loading={fetchLoading}
        style={{ marginTop: 24 }}
        scroll={{ x: 576 }}
      />
    </CertificateDetailBlock>
  );
};

export default CertificateDetail;
