import { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button, Form, Input, Modal } from 'antd';

import * as estimateAction from 'modules/estimate';
import { Typography } from 'components/system';
import NoticeCard from 'components/system/data/NoticeCard';
import FooterBox from 'components/FooterBox';
import ContractAccount from 'components/contract/ContractAccount';
import ContractDocuments from 'components/contract/ContractDocuments';

const EstimateContractBlock = styled.div``;

const EstimateContract = () => {
  const dispatch = useDispatch();
  const { estimate, company, submitLoading } = useSelector(
    ({ estimate, company, loading }: any) => ({
      estimate: estimate.estimate,
      company: company.company,
      submitLoading: loading['estimate/CONTRACT'],
    }),
    shallowEqual,
  );
  const [address, setAddress] = useState(company.addressKo);
  const handleContract = () => {
    Modal.confirm({
      icon: null,
      content: (
        <Typography.Text type="secondary">
          '계약 체결'버튼을 클릭하시면 인증신청번호가 부여되고, 입금 및 계약서
          수령단계로 넘어갑니다.
          <br />
          <br />
          계약을 진행하시겠습니까?
        </Typography.Text>
      ),
      okText: '계약 체결',
      onOk: () => {
        dispatch(
          estimateAction.contract({ estimateId: estimate.estimateId, address }),
        );
      },
    });
  };
  return (
    <EstimateContractBlock>
      <ContractDocuments />
      <ContractAccount />
      <NoticeCard
        title="계약서 받으실 주소"
        titleStyle={{ marginBottom: 16 }}
        style={{ marginTop: 24 }}
        bodyStyle={{ paddingLeft: 24 }}
      >
        <ul>
          <li>
            <Typography.Text type="secondary">
              송부해 주실 계약서 2부 중 1부를 30COS에서 확인하고 날인한 뒤,
              귀사로 다시 송부해 드릴 예정이오니 계약서 받으실 주소를 입력해
              주세요.
            </Typography.Text>
          </li>
          <li>
            <Typography.Text type="secondary">
              가입 시 입력한 사업장 소재지 주소가 기본으로 입력되어 있으니, 다른
              곳으로 받으실 경우 하단의 주소를 수정한 후 ‘계약 체결’ 버튼을
              눌러주세요.
            </Typography.Text>
          </li>
          <Form.Item
            colon={false}
            label={<Typography.Title type="secondary">주소</Typography.Title>}
            style={{
              marginTop: 16,
              marginBottom: 8,
              position: 'relative',
              left: -12,
            }}
          >
            <Input
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </Form.Item>
        </ul>
      </NoticeCard>
      <FooterBox>
        <Button
          key="contract"
          type="primary"
          size="large"
          loading={submitLoading}
          onClick={handleContract}
        >
          계약 체결
        </Button>
      </FooterBox>
    </EstimateContractBlock>
  );
};

export default EstimateContract;
