import { Button, Radio, Form } from 'antd';
import styled from 'styled-components';

import { noPastDateRule, normalizeDate, requireRule } from 'lib/validate';
import { useCertificateMode } from 'hook/certificate';
import FileUploadContainer from 'containers/file/FileUploadContainer';
import FooterBox from 'components/FooterBox';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import DateInput from 'components/system/form/DateInput';
import { Typography } from 'components/system';

const ProductCGMPBlock = styled.div``;

const StyledFormItem = styled(Form.Item)`
  height: 32px;

  .ant-form-item-control-input {
    min-height: 0;
  }

  .ant-form-item-label > label {
    height: 32px;
  }
`;

const ProductCGMP = ({
  updateMode,
  manuMode,
  readOnlyMode,
  form,
  file,
  loading,
  onUpload,
  onDelete,
  onSubmit,
}: any) => {
  const certificateMode = useCertificateMode();
  return (
    <ProductCGMPBlock>
      <Form
        form={form}
        layout="inline"
        colon={false}
        style={{ justifyContent: 'center', alignItems: 'center' }}
        onFinish={onSubmit}
      >
        <Form.Item name="isCGMP">
          <Radio.Group
            disabled={manuMode || readOnlyMode}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
            onChange={(e) => {
              if (e.target.value && form.getFieldValue('ISOExpiredDate')) {
                form.setFieldsValue({ ISOExpiredDate: '' });
              }
            }}
          >
            <Radio value={true}>cGMP</Radio>
            <Radio value={false}>ISO22716</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => prev.isCGMP !== next.isCGMP}
        >
          {({ getFieldValue }) => {
            const isCGMP = getFieldValue('isCGMP');
            return (
              <StyledFormItem
                label={
                  <Typography.Text type="secondary">유효기간</Typography.Text>
                }
                name="ISOExpiredDate"
                normalize={normalizeDate}
                required={false}
                rules={[noPastDateRule, ...(!isCGMP ? [requireRule] : [])]}
              >
                <DateInput
                  style={{ width: 140, height: 32 }}
                  disabled={isCGMP || manuMode || readOnlyMode}
                />
              </StyledFormItem>
            );
          }}
        </Form.Item>
      </Form>
      <FileUploadContainer
        files={file}
        viewerVisible
        readOnly={manuMode || readOnlyMode}
        style={{ marginTop: '32px' }}
        onUpload={onUpload}
        onDelete={onDelete}
      />
      {!manuMode && (
        <FooterBox>
          <ReadOnlyBackButton readOnly={readOnlyMode}>
            <Button type="primary" loading={loading} onClick={form.submit}>
              {!updateMode ? '등록' : !certificateMode ? '수정' : '보완 완료'}
            </Button>
          </ReadOnlyBackButton>
        </FooterBox>
      )}
    </ProductCGMPBlock>
  );
};

export default ProductCGMP;
