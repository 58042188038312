import { useState } from 'react';

import RawMaterialPageTemplate from 'templates/material/RawMaterialPageTemplate';
import RawMaterialDocuments from 'components/material/rawMaterial/RawMaterialDocuments';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import RawMaterialRegisterProgress from './RawMaterialRegisterProgress';

const RawMaterialDocumentsPage = () => {
  const notices = [
    '각 항목을 클릭하시면, 해당 자료를 등록하셨던 화면으로 연결되며 내용을 수정하실 수 있습니다.',
    '저장 버튼을 눌러 입력 완료 처리된 입력 정보는 현재 페이지를 벗어나시는 경우에도 자동으로 저장됩니다.',
    <MistakeNoticeItem />,
  ];
  const [isRegisterSuccess, setIsRegisterSuccess] = useState(false);
  const handleRegisterSuccess = () => {
    setIsRegisterSuccess(true);
  };

  if (isRegisterSuccess) {
    return <RawMaterialRegisterProgress />;
  }
  return (
    <RawMaterialPageTemplate title="원료 입력 정보" notices={notices}>
      <RawMaterialDocuments onRegisterSuccess={handleRegisterSuccess} />
    </RawMaterialPageTemplate>
  );
};

export default RawMaterialDocumentsPage;
