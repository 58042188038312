import { useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { Select, Space, Table, Row, Col } from 'antd';

import path from 'lib/path';
import palette from 'lib/styles/palette';
import { useCertificates } from 'hook/certificate';
import CertificateProgressModal from 'components/modal/certificate/CertificateProgressModal';
import SearchInput from 'components/system/form/SearchInput';
import Typography from 'components/system/general/Typography';
import { ColumnsType } from 'antd/lib/table';

const CertificateOngoingBlock = styled.div`
  margin-top: 24px;
  .ant-table-thead > tr > th {
    padding: 10px 8px;
  }
  .ant-table-tbody > tr > td {
    padding: 10px 8px;
  }
`;

const CertificateOngoing = () => {
  const history = useHistory();
  const [step, setStep] = useState(null);
  const {
    certificates,
    loading,
    page,
    setPage,
    searchType,
    setSearchType,
    searchValue,
    searchCertificates,
  } = useCertificates(false);
  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: '인증신청번호 / 접수일',
        align: 'center',
        render: (row) => (
          <Row style={{ flexDirection: 'column', width: 132 }} align="middle">
            <Typography.Text medium type="secondary" color="black">
              {row.certRequestNo}
            </Typography.Text>
            <Typography.Text type="secondary" color="gray">
              {`(${row.registerDt.slice(0, 10)})`}
            </Typography.Text>
          </Row>
        ),
      },
      {
        title: '제품명 (용량)',
        align: 'center',
        render: (row) => (
          <Typography.Text
            type="secondary"
            onClick={() => showCertificate(row)}
            style={{
              width: 300,
              cursor: 'pointer',
              fontWeight: 500,
              color: palette.text.black,
            }}
          >
            {`${row.estimateTarget.productDetails[0].productNameEn} ${
              row.estimateTarget.netWeight
                ? `(${row.estimateTarget.netWeight} ${row.estimateTarget.netWeightUnit})`
                : '( - )'
            }`}
          </Typography.Text>
        ),
      },
      {
        title: '국가',
        align: 'center',
        render: (row) => (
          <Typography.Text
            type="secondary"
            color="gray"
            style={{
              width: 96,
            }}
          >
            {row.estimateTarget.countryName}
          </Typography.Text>
        ),
        onFilter: (value, record) =>
          record.estimateTarget.countryName.indexOf(value) === 0,
      },
      {
        title: '진행 단계',
        align: 'center',
        render: ({ status, statusString }) => (
          <Typography.Text
            type="secondary"
            style={{
              width: 80,
              cursor: status !== 'CER-CAN' ? 'pointer' : 'default',
              fontWeight: 500,
              color: palette.text.black,
            }}
            onClick={() => status !== 'CER-CAN' && setStep(status)}
          >
            {statusString}
          </Typography.Text>
        ),
        onFilter: (value, record) => record.status.indexOf(value) === 0,
      },
      {
        title: '입금 / 계약서',
        align: 'center',
        render: ({
          estimate: { isDepositFinish, isReceiveContractFinish },
        }) => (
          <Row
            style={{ flexDirection: 'column' }}
            align="middle"
            gutter={[4, 4]}
          >
            <Col>
              <Typography.Label
                type={isDepositFinish ? 'success' : 'disabled'}
                style={{ width: 88 }}
              >
                {isDepositFinish ? '입금 확인 완료' : '입금 확인 중'}
              </Typography.Label>
            </Col>
            <Col>
              <Typography.Label
                type={isReceiveContractFinish ? 'success' : 'disabled'}
                style={{ width: 88 }}
              >
                {isReceiveContractFinish
                  ? '계약서 수령 완료'
                  : '계약서 수령 전'}
              </Typography.Label>
            </Col>
          </Row>
        ),
      },
      {
        title: '처리 필요',
        align: 'center',
        render: (row, { estimate: { estimateAdditionals } }) => (
          <Row
            style={{ flexDirection: 'column', width: 88 }}
            align="middle"
            gutter={[4, 4]}
          >
            {row.etcStatus && (
              <Col>
                <Typography.Label
                  type="primary"
                  onClick={() => showCertificate(row)}
                >
                  입력·보완
                  <i className="material-icons outlined md-12">chevron_right</i>
                </Typography.Label>
              </Col>
            )}
            {estimateAdditionals && (
              <Col>
                <Typography.Label
                  type="primary"
                  onClick={() => history.push(`${path.my}/estimate`)}
                >
                  추가 견적
                  <i className="material-icons outlined md-12">chevron_right</i>
                </Typography.Label>
              </Col>
            )}
          </Row>
        ),
      },
    ],
    [],
  );

  const searchTypeOptions = useMemo(
    () => [
      { label: '전체', value: 'all' },
      { label: '제품명', value: 'productNameEn' },
      {
        label: '국가명',
        value: 'countryNameKo',
      },
    ],
    [],
  );
  const showCertificate = (certificate: any) => {
    const { certTargetId } = certificate;
    history.push(`${path.certificate.ongoing}/${certTargetId}`);
  };
  const closeModal = () => {
    setStep(null);
  };

  return (
    <CertificateOngoingBlock>
      <CertificateProgressModal currentStep={step} onClose={closeModal} />
      <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
        <Select
          options={searchTypeOptions}
          value={searchType}
          onChange={setSearchType}
          style={{ width: 100 }}
        />
        <SearchInput
          placeholder="검색"
          defaultValue={searchValue}
          disabled={searchType === 'all'}
          onSearch={searchCertificates}
          style={{ width: 200 }}
        />
      </Space>
      <Table
        columns={columns}
        dataSource={certificates.content}
        rowKey={(row) => row.certTargetId}
        pagination={{
          current: page,
          pageSize: certificates.size,
          total: certificates.totalElements,
          onChange: setPage,
        }}
        loading={loading}
        style={{ marginTop: 24 }}
        scroll={{ x: 768 }}
      />
    </CertificateOngoingBlock>
  );
};

export default CertificateOngoing;
