import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Input, message, Modal, Row } from 'antd';

import CorrectRequestMessageModal from 'components/CorrectRequestMessageModal';
import FooterBox from 'components/FooterBox';
import UpdateLog from 'components/product/UpdateLog';
import { messages } from 'lib/consts';
import { requireRule } from 'lib/validate';
import { toggleProductNameModalVisible } from 'modules/certificate';
import { updateProductName } from 'modules/product';
import { EDocumentCode } from 'types/product';
import { useCurrentProduct } from 'service/brand/product/product';

const ProductNameModal = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const visible = useSelector(
    ({ certificate }: any) => certificate.productNameModalVisible,
  );
  const { productDetail } = useCurrentProduct();
  const { estimateTarget, readOnlyMode } = useSelector(
    ({ certificate }: any) => ({
      estimateTarget: certificate.certificate.estimateTarget,
      readOnlyMode: certificate.readOnlyMode,
    }),
    shallowEqual,
  );
  const { productId, countryId } = estimateTarget;
  const submitLoading = useSelector(
    ({ loading }: any) => loading['product/UPDATE_PRODUCT_NAME'],
  );
  const onClose = () => {
    dispatch(toggleProductNameModalVisible());
  };
  const onSubmit = ({ productNameEn }: any) => {
    if (productDetail.productNameEn === productNameEn) {
      return message.warn(messages.NO_NEED_TO_UPDATE);
    }
    dispatch(
      updateProductName({
        productId,
        countryId,
        productNameEn,
      }),
    );
  };

  return (
    <Modal
      visible={visible}
      title="제품명 관리"
      footer={null}
      destroyOnClose={true}
      onCancel={onClose}
    >
      <Row justify="end" gutter={8}>
        <Col>
          <CorrectRequestMessageModal documentCode={EDocumentCode.PN} />
        </Col>
        <Col>
          <UpdateLog
            productId={productId}
            countryId={countryId}
            documentCode={EDocumentCode.BASIC}
            validCodes={['BASIC01-PNE']}
          />
        </Col>
      </Row>
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Form.Item
          label="제품명"
          name="productNameEn"
          initialValue={productDetail?.productNameEn}
          rules={[requireRule]}
        >
          <Input disabled={readOnlyMode} />
        </Form.Item>
        {!readOnlyMode && (
          <FooterBox>
            <Button type="primary" loading={submitLoading} htmlType="submit">
              보완 완료
            </Button>
          </FooterBox>
        )}
      </Form>
    </Modal>
  );
};

export default ProductNameModal;
