import { IAPIResponse } from 'types/common';
import {
  IIFRA,
  IIFRAAdd,
  IIFRADefault,
  IIFRAUpdate,
} from 'types/material/ifra';
import client from '../client';

export const getIFRADefault = async () => {
  const res = await client.get<IAPIResponse<IIFRADefault[]>>(
    `/material/allergen/tests`,
  );
  return res.data;
};

export const getIFRAs = async (materialId: number) => {
  const res = await client.get<IAPIResponse<IIFRA[]>>(
    `/material/IFRA?materialId=${materialId}`,
  );
  return res.data;
};

export const getIFRAsByMaterialAllergenListId = async (
  materialAllergenListId: number,
) => {
  const res = await client.get<IAPIResponse<IIFRA[]>>(
    `/material/allergen/${materialAllergenListId}/IFRA`,
  );
  return res.data;
};

export const addIFRAs = async (params: IIFRAAdd) => {
  const { materialAllergenListId, ...rest } = params;
  const res = await client.post<IAPIResponse<null>>(
    `/material/allergen/${materialAllergenListId}/IFRA`,
    rest.ingredients,
  );
  return res.data;
};

export const updateIFRAs = async (params: IIFRAUpdate) => {
  const { materialAllergenListId, ...rest } = params;
  const res = await client.patch<IAPIResponse<null>>(
    `/material/allergen/${materialAllergenListId}/IFRA`,
    rest.ingredients,
  );
  return res.data;
};
