import { Button, Col, Row, Statistic, Table, Typography as Typo } from 'antd';
import styled from 'styled-components';
import { useMemo } from 'react';
import { ColumnsType } from 'antd/lib/table';

import FooterBox from 'components/FooterBox';
import palette from 'lib/styles/palette';
import { Typography } from 'components/system';
import StatisticCard from 'components/StatisticCard';

const ProductDetailBlock = styled.div``;

const ProductDetail = ({
  manuMode,
  product,
  documents,
  fetchLoading,
  registerLoading,
  registerable,
  onClickRegister,
  onClickDocument,
}: any) => {
  const columns: ColumnsType<any> = useMemo(
    () => [
      { title: '구분', dataIndex: 'no', align: 'center' },
      {
        title: '입력정보',
        render: (document) => (
          <Typography.Text
            type="secondary"
            style={{
              fontWeight: 500,
              cursor: 'pointer',
              color: palette.text.black,
            }}
            onClick={() => onClickDocument(document)}
          >
            {document.documentName}
          </Typography.Text>
        ),
      },
      {
        title: '처리현황',
        align: 'center',
        render: (document) =>
          document.status === 'INP' ? (
            <Typography.Label
              type="primary"
              onClick={() => {
                onClickDocument(document);
              }}
            >
              입력 필요
              <i className="material-icons outlined md-12">chevron_right</i>
            </Typography.Label>
          ) : (
            <Typo.Text>{document.statusString}</Typo.Text>
          ),
      },
      {
        title: '최근 입력자 명',
        align: 'center',
        render: (document) =>
          document.modifierName
            ? `${document.modifierName} (${document.modifierCompanyName})`
            : '',
      },
      {
        title: '입력 일시',
        align: 'center',
        render: (document) =>
          document.modifierName && document.updateDate
            ? document.updateDate
            : '',
      },
    ],
    [],
  );
  return (
    <ProductDetailBlock>
      {!manuMode && (
        <StatisticCard>
          <Row wrap={false} gutter={24}>
            <Col span={9}>
              <Statistic
                title="제품명 (용량)"
                value={`${product.productDetail.productNameEn || '-'} ${
                  product.netWeight
                    ? `(${product.netWeight} ${product.netWeightUnit})`
                    : '( - )'
                }`}
              />
            </Col>
            <Col span={8}>
              <Statistic
                title="국문 제품명"
                value={product.productDetail.productNameKo || '-'}
              />
            </Col>
            <Col span={7}>
              <Statistic
                title="제조사"
                value={product.productDetail.manufacturerNameKo}
              />
            </Col>
          </Row>
        </StatisticCard>
      )}
      <Table
        size="small"
        columns={columns}
        rowKey={(row) => row.documentCode}
        dataSource={documents}
        pagination={false}
        loading={fetchLoading}
        style={{ marginTop: '30px' }}
      />
      {registerable && (
        <FooterBox>
          <Button
            type="primary"
            loading={registerLoading}
            onClick={onClickRegister}
          >
            등록 완료
          </Button>
        </FooterBox>
      )}
    </ProductDetailBlock>
  );
};

export default ProductDetail;
