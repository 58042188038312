import styled from 'styled-components';
import { Button, Col, Row } from 'antd';
import { DownloadOutlined, PrinterOutlined } from '@ant-design/icons';

import FileViewer from 'components/file/FileViewer';
import { useDocuments } from 'service/brand/retailer/retailerApply';
import { EDocumentCode } from 'types/brand/retailer/retailerApply';
import printJS from 'print-js';
import { downloadFile } from 'lib/file';
import palette from 'lib/styles/palette';

const Container = styled.div`
  Button {
    height: 32px;
  }
`;

const BL = () => {
  const {
    documents: [businessLicense],
  } = useDocuments({ documentCode: EDocumentCode.BL });
  if (!businessLicense) {
    return null;
  }
  const { filename, url } = businessLicense;

  return (
    <Container>
      <Row gutter={8} justify="end" style={{ marginBottom: 8 }}>
        <Col>
          <Button
            icon={<DownloadOutlined style={{ color: palette.text.primary }} />}
            onClick={() => downloadFile(url, filename)}
          >
            다운로드
          </Button>
        </Col>
        <Col>
          <Button
            icon={<PrinterOutlined style={{ color: palette.text.primary }} />}
            onClick={() => printJS(decodeURIComponent(url))}
          >
            인쇄하기
          </Button>
        </Col>
      </Row>
      <FileViewer title="" file={url} />
    </Container>
  );
};

export default BL;
