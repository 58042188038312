import { useMutation, useQuery } from 'react-query';
import * as ingredientApi from 'lib/api/material/ingredient';
import React, { useMemo } from 'react';
import _ from 'lodash';
import { IAPIPageableResult } from 'types/common';
import { IIngredientSearchResult } from 'types/material/ingredient';

export const useIngredient = (kciaIngredientId?: number) => {
  const {
    data: ingredientDetail = null,
    isFetching: ingredientDetailLoading,
  } = useQuery(
    [`ingredient/detail`, kciaIngredientId],
    () => ingredientApi.getIngredientDetail(kciaIngredientId!),
    {
      enabled: typeof kciaIngredientId !== 'undefined',
      select: (res) => res.result,
    },
  );

  const {
    mutate: searchIngredient,
    isLoading: searchIngredientLoading,
  } = useMutation(
    ({
      pageNo,
      size,
      keyword,
    }: {
      pageNo: number;
      size: number;
      keyword: string;
    }) => ingredientApi.searchIngredient({ pageNo, size, keyword }),
  );

  const {
    mutate: searchAgainIngredient,
    isLoading: searchAgainIngredientLoading,
  } = useMutation(
    ({
      pageNo,
      size,
      newKeyword,
      preKeyword,
    }: {
      pageNo: number;
      size: number;
      newKeyword: string;
      preKeyword: string;
    }) =>
      ingredientApi.searchAgainIngredient({
        pageNo,
        size,
        newKeyword,
        preKeyword,
      }),
  );

  const handleAgainSearch = _.debounce(
    ({
      pageNo,
      newKeyword,
      preKeyword,
      afterSuccess,
    }: {
      pageNo: number;
      newKeyword: string;
      preKeyword: string;
      afterSuccess?: React.Dispatch<
        React.SetStateAction<
          IAPIPageableResult<IIngredientSearchResult[]> | undefined
        >
      >;
      wait?: number;
    }) => {
      searchAgainIngredient(
        {
          pageNo,
          size: 10,
          newKeyword,
          preKeyword,
        },
        {
          onSuccess: (res) => {
            if (afterSuccess) {
              afterSuccess(res.result);
            }
          },
        },
      );
    },
    200,
  );

  return useMemo(
    () => ({
      ingredientDetail,
      ingredientDetailLoading,
      searchIngredient,
      searchIngredientLoading,
      onAgainSearch: handleAgainSearch,
      searchAgainIngredient,
      searchAgainIngredientLoading,
    }),
    [
      kciaIngredientId,
      ingredientDetail,
      ingredientDetailLoading,
      searchIngredientLoading,
    ],
  );
};

export const useCountryProhibitsUpdatedDate = () => {
  const {
    data: countryProhibitUpdatedDate,
  } = useQuery(
    'material/ingredient/getCountryProhibitsUpdatedDate',
    ingredientApi.getCountryProhibitsUpdatedDate,
    { select: (res) => res.data.result },
  );

  return countryProhibitUpdatedDate;
};

export const useEwgUpdatedDate = () => {
  const {
    data: countryProhibitUpdatedDate,
  } = useQuery(
    'material/ingredient/getEwgUpdatedDate',
    ingredientApi.getEwgUpdatedDate,
    { select: (res) => res.data.result },
  );

  return countryProhibitUpdatedDate;
};
