import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Form, FormInstance, Input, Row } from 'antd';
import { Tip } from 'components/system';
import PasswordInput from 'components/system/form/PasswordInput';
import Typography from 'components/system/general/Typography';
import palette from 'lib/styles/palette';
import { emailRule, mobilePhoneRule, requireRule } from 'lib/validate';
import styled from 'styled-components';

interface IProps {
  form: FormInstance;
  updateMode: boolean;
  onClickChangePassword: () => void;
  onClickBack: () => void;
}

const SuperUserFormBlock = styled.div``;

const TipBlock = styled.div`
  padding-left: 12px;
  span {
    font-size: 10px;
    line-height: 1.6;
    letter-spacing: -0.36px;

    &::before {
      position: relative;
      top: -3px;
      left: -4px;
      content: '';
      display: inline-block;
      width: 2px;
      height: 2px;
      background-color: ${palette.primary};
    }
  }
`;

const SuperUserForm = ({
  form,
  updateMode,
  onClickChangePassword,
  onClickBack,
}: IProps) => {
  return (
    <SuperUserFormBlock>
      <Row align="middle" gutter={8} style={{ marginTop: updateMode ? 32 : 0 }}>
        <Col>
          <Typography.Title>
            {!updateMode && (
              <ArrowLeftOutlined
                style={{
                  position: 'relative',
                  top: -2,
                  cursor: 'pointer',
                  fontSize: 16,
                  marginRight: 16,
                }}
                onClick={onClickBack}
              />
            )}
            2. 슈퍼유저 정보
          </Typography.Title>
        </Col>
        <Col>
          <Tip trigger="click" bodyStyle={{ width: 306 }}>
            <TipBlock>
              <Typography.Text type="secondary">
                슈퍼유저 계정은 회사 당 1개의 계정만 소유 가능 합니다.
              </Typography.Text>
              <Typography.Text type="secondary">
                슈퍼유저는 모든 영역에서 최고 권한을 가진 계정입니다.
              </Typography.Text>
              <Typography.Text type="secondary">
                슈퍼유저는 하위 담당자를 추가하거나 삭제할 수 있습니다.
              </Typography.Text>
            </TipBlock>
          </Tip>
        </Col>
      </Row>
      <Form.Item
        label="아이디 (사업자등록번호)"
        name="username"
        required
        style={{ marginTop: 16 }}
      >
        <Input disabled />
      </Form.Item>
      {!updateMode ? (
        <Row gutter={8} align="bottom">
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item label="비밀번호" name="password" rules={[requireRule]}>
              <PasswordInput
                type="password"
                placeholder="비밀번호"
                autoComplete="new-password"
                onChange={() => form.validateFields(['passwordConfirm'])}
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              name="passwordConfirm"
              rules={[
                requireRule,
                ({ getFieldValue }) => ({
                  validator: (_, value) => {
                    const password = getFieldValue('password');
                    if (!value || !password) {
                      return Promise.resolve();
                    }
                    if (value !== getFieldValue('password')) {
                      return Promise.reject(
                        new Error('비밀번호가 일치하지 않습니다.'),
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <PasswordInput
                type="password"
                placeholder="비밀번호 확인"
                autoComplete="new-password"
              />
            </Form.Item>
          </Col>
        </Row>
      ) : (
        <Form.Item label="비밀번호">
          <Button onClick={onClickChangePassword}>비밀번호 변경</Button>
        </Form.Item>
      )}
      <Row gutter={8} align="bottom">
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item label="이름" name="name" required>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item label="부서명" name="deptName" required>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8} align="bottom">
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item label="직급" name="positionName" required>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item
            label="휴대폰번호"
            name="mobile"
            rules={[requireRule, mobilePhoneRule]}
            required
          >
            <Input placeholder="숫자만 입력 가능" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label="이메일"
        name="email"
        rules={[requireRule, emailRule]}
        required
      >
        <Input placeholder="가입 승인이 전달되므로, 실제 사용 중인 메일주소를 입력" />
      </Form.Item>
    </SuperUserFormBlock>
  );
};

export default SuperUserForm;
