export enum ERawMaterialSearchType {
  ALL = 'ALL',
  NAME = 'M_NAME',
  COMPANY = 'M_C_NAME',
  INCI = 'INCI',
  MARKETING = 'MARKETING',
}

export interface IEfficacy {
  efficacyCategoryId: number;
  efficacyName: string;
  materialCount: number;
  isDefault: boolean;
}

export interface IFormulaPurpose {
  formulaPurposeCategoryId: number;
  formulaPurposeName: string;
  materialCount: number;
  isDefault: boolean;
}

export interface IEfficaciesAndFormulaPurposes {
  allEfficacies: IEfficacy[];
  allFormulaPurposes: IFormulaPurpose[];
}

export interface IRawMaterial {
  materialId: number;
  materialNameEn: string;
  companyNameKo: string;
  inciNames: { inciNameEn: string }[];
  efficacies: { efficacyName: string }[];
  formulaPurposes: { formulaPurposeName: string }[];
  marketingKeywords: { keyword: string }[];
  isRegisterPatent: boolean;
  marketingDisplayType: 'PUBLIC' | 'LIMIT' | 'BOTH';
}
