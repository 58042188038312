import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Radio } from 'antd';

import path from 'lib/path';
import palette from 'lib/styles/palette';
import MyRouter from 'routers/MyRouter';
import { Typography } from 'components/system';
import { useCompanyType } from 'service/company';
import { useAuthority, useUserType } from 'service/auth';
import { ECompanyType } from 'types/company';
import { EUserType } from 'types/auth';

const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: flex-end;
  border-bottom: 1px solid ${palette.primary};
  margin-bottom: 56px;

  .ant-radio-button-wrapper-checked + .ant-radio-button-wrapper {
    border-left: none;
  }

  .ant-radio-button-wrapper {
    position: relative;
    bottom: -1px;
    flex: 0 1 165px;
    height: 44px;
    padding-top: 1px;
    background-color: #fff !important;
    border-left: 1px solid rgb(217, 217, 217);
    border-bottom: 1px solid ${palette.primary};
    box-shadow: none !important;
    transition: none;

    &::before {
      content: '';
      position: absolute;
      left: -4px;
      top: -1px;
      display: block;
      width: calc(100% + 8px);
      height: 44px !important;
      background-color: transparent !important;
      border-top: 1px solid rgb(217, 217, 217);
    }

    &:last-child {
      border-radius: 0 4px 0 0;

      &::before {
        width: calc(100% - 8px);
      }
    }
    &:first-child {
      border-radius: 4px 0 0 0;

      &::before {
        left: 4px;
      }
    }
    &:not(:first-child) {
      /* border-left: none; */
    }
    &:not(:last-child) {
      border-right: none;
    }
    &:first-child:last-child {
      border-radius: 4px 4px 0 0;
    }
    &.ant-radio-button-wrapper-checked {
      border-radius: 4px 4px 0 0;
      border-left: 1px solid ${palette.primary};
      border-right: 1px solid ${palette.primary};
      border-bottom: 1px solid #fff;

      &::before {
        border-top: none;
      }
      &:hover {
        border-bottom-color: #fff;
      }
    }

    & > span {
      font-size: 16px;
      letter-spacing: -1px;
      color: ${palette.text.disabled};
    }
  }
  .ant-radio-button-wrapper-checked {
    & > span {
      color: ${palette.text.primary} !important;
    }
  }
`;

const MyPageBlock = styled.div``;

const MyPage = () => {
  const { tab = 'userInfo' } = useParams();
  const history = useHistory();
  const companyType = useCompanyType();
  const userType = useUserType();
  const authority = useAuthority();
  const handleChangeTab = (e) => {
    history.push(`${path.my}/${e.target.value}`);
  };
  return (
    <MyPageBlock>
      <Typography.Headline type="secondary" gutter={{ bottom: 40 }}>
        마이페이지
      </Typography.Headline>
      <StyledRadioGroup
        buttonStyle="solid"
        value={tab}
        onChange={handleChangeTab}
      >
        <Radio.Button value="userInfo">회원정보 수정</Radio.Button>
        {companyType === ECompanyType.BRAND && userType === EUserType.BRAND && (
          <Radio.Button value="estimate">계약 보관함</Radio.Button>
        )}
        {authority === 'SUPER_USER' && (
          <Radio.Button value="manager">담당자 관리</Radio.Button>
        )}
        {companyType === ECompanyType.BRAND && userType === EUserType.BRAND && (
          <Radio.Button value="buyer">바이어 관리</Radio.Button>
        )}
        {companyType === ECompanyType.BRAND && userType === EUserType.BRAND && (
          <Radio.Button value="vcrp">VCRP 계정</Radio.Button>
        )}
      </StyledRadioGroup>
      <MyRouter />
    </MyPageBlock>
  );
};

export default MyPage;
