import { useMemo, useRef, useState } from 'react';
import { Card, Col, Input, List, Row, Spin } from 'antd';
import styled from 'styled-components';

import { Typography } from 'components/system';
import useUpdateEffect from 'hook/useUpdateEffect';

const SearchableListBlock = styled.div``;
const ListWrap = styled.div`
  height: 400px;
  overflow: auto;
`;

const SearchableList = ({
  defaultKeyword = '',
  title,
  placeholder,
  list,
  filterList,
  renderItem,
  loading = false,
  top,
  action,
  emptyText,
}: {
  defaultKeyword?: string;
  title: string;
  placeholder?: string;
  list: any[];
  filterList: (item: any, keyword: string) => boolean;
  renderItem?: (item: any, index: number) => React.ReactNode;
  loading?: boolean;
  top?: any;
  action?: React.ReactNode;
  emptyText?: React.ReactNode;
}) => {
  const listRef = useRef<HTMLDivElement>(null);
  const [keyword, setKeyword] = useState(defaultKeyword);
  const filteredList = useMemo(
    () => list.filter((item) => filterList(item, keyword)),
    [list, keyword],
  );
  useUpdateEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = 0;
    }
  }, [top]);
  return (
    <SearchableListBlock style={{ position: 'relative' }}>
      <Row
        justify="space-between"
        align="bottom"
        style={{
          marginBottom: '8px',
          height: 32,
        }}
      >
        <Col>
          <Typography.Title type="secondary">{title}</Typography.Title>
        </Col>
        {action && <Col>{action}</Col>}
      </Row>
      <Input
        value={keyword}
        placeholder={placeholder || '검색어를 입력해 주세요.'}
        onChange={(e) => setKeyword(e.target.value)}
      />
      <Card size="small" style={{ marginTop: '16px' }}>
        <Spin spinning={loading}>
          <ListWrap ref={listRef}>
            <List
              size="small"
              dataSource={filteredList}
              renderItem={renderItem}
              locale={{ emptyText }}
            />
          </ListWrap>
        </Spin>
      </Card>
    </SearchableListBlock>
  );
};

export default SearchableList;
