import ProductSampleList from 'components/manufacturer/productSample/ProductSampleList';
import PageTemplate from 'templates/PageTemplate';

const notices = [
  '확정 완료 샘플은 샘플의 확정이 이루어져 추후 제품 등록에 사용할 수 있는 샘플 목록입니다.',
  '확정 완료샘플은 수정 및 삭제가 불가능 합니다. (단, 기본 정보의 처방 상세 공개 설정은 수정이 가능)',
  '완제품 등록 샘플은 샘플 확정 이후 제품 등록에 사용된 샘플 목록입니다.',
];

const ProductSampleListPage = () => {
  return (
    <PageTemplate title="확정 완료 샘플 관리" back={false} notices={notices}>
      <ProductSampleList />
    </PageTemplate>
  );
};

export default ProductSampleListPage;
