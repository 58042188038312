import { ChangeEvent, ReactNode } from 'react';
import styled from 'styled-components';

import { Typography } from 'components/system';
import palette from 'lib/styles/palette';

const Container = styled.div<{ open: boolean; disabled?: boolean }>`
  display: flex;
  align-items: center;
  width: 130px;
  height: 24px;
  padding: 3px;
  padding-left: 8px;
  border-radius: 4px;
  border: solid 1px
    ${(props) => (props.open ? palette.primary : palette.inactive)};
  background-color: ${(props) => (props.open ? palette.paleGray : '#f5f5f5')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};

  .material-icons {
    color: ${(props) => (props.open ? palette.primary : palette.text.disabled)};
  }
`;

const LockButton = ({
  open = true,
  style,
  onChange,
  content,
  onGenerateContent,
  disabled = false,
}: {
  open?: boolean;
  style?: React.CSSProperties;
  children?: ReactNode;
  onChange?: (e: ChangeEvent<any>) => void;
  content?: string;
  onGenerateContent?: (open: boolean) => string;
  disabled?: boolean;
}) => {
  const handleClick = () => {
    if (onChange) {
      onChange({
        target: { open: !open },
      } as ChangeEvent<any>);
    }
  };

  return (
    <Container
      open={open}
      onClick={() => !disabled && handleClick()}
      style={style}
      disabled={disabled}
    >
      <i className="material-icons outlined md-18">
        {open ? 'lock_open' : 'lock'}
      </i>
      <Typography.Text
        type="tertiary"
        gutter={{ left: 4, top: 2 }}
        color={open ? 'black' : 'disabled'}
        inline
      >
        {onGenerateContent ? onGenerateContent(open) : content}
      </Typography.Text>
    </Container>
  );
};

export default LockButton;
