import { Switch, Route } from 'react-router-dom';
import { nanoid } from 'nanoid';

import path from 'lib/path';
import { useCheckManufacturer } from 'lib/hook';
import CertificateOngoingPage from 'pages/certificate/CertificateOngoingPage';
import CertificateCompletePage from 'pages/certificate/CertificateCompletePage';
import CertificateDetailPage from 'pages/certificate/CertificateDetailPage';
import CertificateDocumentsPage from 'pages/certificate/CertificateDocumentsPage';
import Error404Page from 'pages/error/Error404Page';

const CertificateRouter = () => {
  useCheckManufacturer();

  return (
    <Switch>
      <Route
        path={path.certificate.ongoing}
        exact
        render={(props) => <CertificateOngoingPage {...props} key={nanoid()} />}
      />
      <Route
        path={path.certificate.complete}
        exact
        render={(props) => (
          <CertificateCompletePage {...props} key={nanoid()} />
        )}
      />
      <Route
        path={`${path.certificate.ongoing}/:certTargetId`}
        component={CertificateDetailPage}
      />
      <Route
        path={`${path.certificate.complete}/:certTargetId`}
        component={CertificateDocumentsPage}
      />
      <Route path={path.certificate.root} component={Error404Page} />
    </Switch>
  );
};

export default CertificateRouter;
