import BuyerListContainer from 'containers/buyer/BuyerListContainer';
import PageTemplate from 'templates/PageTemplate';

const notices = [
  '인증 진행 현황에서 입력하신 바이어를 확인하실 수 있는 페이지입니다.',
  '이 페이지에서 바이어를 추가해놓으시면, 인증 진행 현황에서도 편리하게 선택하실 수 있습니다.',
];

const BuyerPage = () => {
  return (
    <PageTemplate back={false} notices={notices}>
      <BuyerListContainer />
    </PageTemplate>
  );
};

export default BuyerPage;
