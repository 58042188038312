import palette from 'lib/styles/palette';
import styled, { css } from 'styled-components';
import { ITypographyBlockProps, ITypographyProps } from '.';

interface ITextProps extends Omit<ITypographyProps, 'type'> {
  type?: ITypographyProps['type'] | 'quaternary';
  asterisk?: boolean;
}
interface ITextBlockProps extends Omit<ITypographyBlockProps, 'type'> {
  type?: ITypographyProps['type'] | 'quaternary';
  asterisk: boolean;
}

const TextBlock = styled.span<ITextBlockProps>`
  display: block;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;

  ${({ type }) =>
    type === 'primary' &&
    css`
      font-size: 16px;
      letter-spacing: -1px;
    `}
  ${({ type }) =>
    type === 'secondary' &&
    css`
      font-size: 14px;
      letter-spacing: -0.5px;
    `}
  ${({ type }) =>
    type === 'tertiary' &&
    css`
      font-size: 12px;
      letter-spacing: -0.5px;
    `}
  ${({ type }) =>
    type === 'quaternary' &&
    css`
      font-size: 10px;
      letter-spacing: -0.5px;
    `}

  ${({ gutter }) => {
    if (typeof gutter === 'number') {
      return css`
        margin: ${gutter}px;
      `;
    }
    if (typeof gutter === 'object') {
      return css`
        margin-top: ${gutter.top ? `${gutter.top}px` : 0};
        margin-bottom: ${gutter.bottom ? `${gutter.bottom}px` : 0};
        margin-left: ${gutter.left ? `${gutter.left}px` : 0};
        margin-right: ${gutter.right ? `${gutter.right}px` : 0};
      `;
    }
  }}

  ${({ align }) =>
    align &&
    css`
      text-align: ${align};
    `}

  ${({ bold }) =>
    bold &&
    css`
      font-weight: 700;
    `}

  ${({ medium }) =>
    medium &&
    css`
      font-weight: 500;
    `}
    
  ${({ inline }) =>
    inline &&
    css`
      display: inline;
    `}

  ${({ asterisk }) =>
    asterisk &&
    css`
      &::before {
        content: '*';
        color: ${palette.primary};
      }
    `}
    
  ${({ color }) =>
    color &&
    css`
      color: ${palette.text[color]};
    `}

    span.primary {
    color: ${palette.text.primary};
  }

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
`;

const Text = ({
  children,
  type = 'primary',
  gutter,
  align,
  color,
  bold,
  medium,
  inline,
  asterisk = false,
  className,
  style,
  onClick,
}: ITextProps) => {
  return (
    <TextBlock
      type={type}
      gutter={gutter}
      align={align}
      color={color}
      bold={bold}
      medium={medium}
      inline={inline}
      asterisk={asterisk}
      className={className}
      style={style}
      onClick={onClick}
    >
      {children}
    </TextBlock>
  );
};

export default Text;
