import styled from 'styled-components';

import palette from 'lib/styles/palette';

interface IProps {
  amount: number;
  opacity: number;
  label: string;
  onClick: () => void;
}

const StatBlock = styled.div<{ opacity: number }>`
  width: 128px;
  cursor: pointer;

  padding: 12px 0 14px;

  .company-stat {
    font-size: 56px;
    line-height: 0.8;
    letter-spacing: -1.65px;
    text-align: center;
    font-weight: 500;
  }

  .label-wrap {
    margin-top: 10px;
    text-align: center;

    .label {
      letter-spacing: -0.5px;
    }
  }

  .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: ${palette.primary};
    opacity: ${(props) => props.opacity};
    border-radius: 50%;
    margin-right: 8px;
  }
`;

const CompanyStat = ({ amount, opacity, label, onClick }: IProps) => {
  return (
    <StatBlock opacity={opacity} onClick={onClick}>
      <div className="company-stat">{amount}</div>
      <div className="label-wrap">
        <span className="dot" />
        <span>{label}</span>
      </div>
    </StatBlock>
  );
};

export default CompanyStat;
