// Product Basic

import { ICountryProhibitInfo } from './formula';

export enum EDocStatus {
  INP = 'INP', //입력 필요
  OPT = 'OPT', // 입력 가능
  MOD = 'MOD', // 보완 필요
  ONG = 'ONG', // 검토 중
  FIN = 'FIN', // 검토 완료
}

export enum EDocumentCode {
  BASIC = 'basic',
  CI = 'ci',
  PN = 'pn',
  CAT = 'cat',
  COUNTRY = 'country',
  PHY = 'phy',
  CHE = 'che',
  MIC = 'mic',
  FORMBR = 'formbr',
  FORMBRC = 'formbrc',
  ART = 'art',
  CGMP = 'cgmp',
  STAB = 'stab',
  CHAL = 'chal',
  PACK = 'pack',
  MANUC = 'manuc',
  IFRA = 'ifra',
  ALLE = 'alle',
  RMCOA = 'rmcoa',
  RMMSDS = 'rmmsds',
  BI = 'bi',
  THIRD = 'third',
  CFS = 'cfs',
  PCC = 'pcc',
  IRC = 'irc',
  LOGOAI = 'logoai',
  COBRM = 'cobrm',
  ROPQM = 'ropqm',
  SLD = 'sld',
  PS = 'ps',
  CV = 'cv',
  KW = 'kw',
  SWI = 'swi',
  SPI = 'spi',
  UPSI = 'upsi',
  FCCAD = 'fccad',
  VCRPAR = 'vcrpar',
  VCRPAC = 'vcrpac',
}

export enum EProductSearchType {
  ALL = 'ALL',
  PRODUCT_NAME = 'PRODUCT_NAME',
  COUNTRY_NAME = 'COUNTRY_NAME',
  MANUFACTURER_NAME = 'MANUFACTURER_NAME',
}

export interface IProductBasic {
  productId: number;
  countryId: number;
  status: EProductStatus;
  manufacturerId: number;
  netWeight: number;
  netWeightUnit: string;
  productDetail: IProductDetail;
  productDetails: IProductDetail[];
}

export interface IProductDetail {
  productId: number;
  countryId: number | null;
  brandNameEn: string;
  countryBrandName?: string;
  productNameEn: string;
  productNameKo: string;
  netWeight: string;
  netWeightUnit: string;
  manufacturerNameKo: string;
  manufacturerNameEn: string;
  manufacturerAddressEn: string;
  manufacturerTel: string;
  manufacturerFax: string;
  countryProductName: string;
  isSeriesProduct: boolean;
}

export interface IProductAddParams {
  companyId: number;
  brandNameEn: string;
  productNameKo: string;
  productNameEn: string;
  manufacturerId: number;
  manufacturerNameKo: string;
  manufacturerNameEn: string;
  manufacturerAddressEn: string;
  manufacturerTel: string;
  manufacturerFax: string;
  netWeight: number;
  netWeightUnit: string;
}

export interface IProductUpdateParams extends Partial<IProductAddParams> {
  productId: number;
  countryId?: number;
  countryBrandName?: string;
  countryProductName?: string;
  iso22716ExpireDate?: string;
  productExpireMonths?: number;
  status?: EProductStatus;
}
export interface IProduct extends IProductBasic {
  manufacturerStatus?: string;
  netWeight: number;
  netWeightUnit: string;
  registerDt: string;
  updateDt?: string;
  manufacturerItemNo?: number;
  manufacturerItemNameKo?: string;
  brandCompanyNameKo?: string;
  companyFactoryId?: number;
  authCode: any;
  authenticatedBrandCompanyId?: number;
  isUsePackingAttestation?: boolean;
  packingAttestationType?: string;
  productCountries: IProductCountry[];
  productLotNos: any;
  productLabNos: any;
  productCategory: IProductCategory;
}

export interface IProductItem {
  productId: number;
  status: EProductStatus;
  netWeight: number;
  netWightUnit: string;
  productDetail: {
    productDetailId: number;
    productNameEn: string;
    manufacturerNameKo: string;
  };
  productCategory: IProductCategory | null;
  productCountries: IProductCountry[] | null;
  registerDt: string;
  updateDt: string | null;
}
export interface IProductDocStatus {
  productDocStatusId: number;
  documentSeqId: number;
  documentCode: EDocumentCode;
  uploadFileUrl: string;
  documentUrl: string;
  filename: string;
  status: EDocStatus;
  correctRequestMessage: string | null;
}

export interface IProductCategory {
  productCategoryId: number;
  productId: number;
  cosmeticCategoryIdDepth1: number;
  cosmeticCategoryIdDepth2: number;
  isFunctional: boolean;
  functionalCategoryId: number | null;
}

export interface ICosmeticCategory {
  cosmeticCategoryId: number;
  depth: number;
  europeCategory: string | null;
  example: string | null;
  indiaCategory1: string | null;
  indiaCategory2: string | null;
  indiaCategory3: string | null;
  name: string;
  parentId: number | null;
}

export interface IFunctionalCategory {
  functionalCategoryId: number;
  name: string;
}

export interface IProductCategoryAddParams {
  productId: number;
  cosmeticCategoryIdDepth1: number;
  cosmeticCategoryIdDepth2: number;
  isFunctional: boolean;
  functionalCategoryId?: number | null;
}

export interface IProductCountry {
  productCountryId: number;
  productId: number;
  countryId: number;
  country: ICountry;
  productNameKo?: string;
  productNameEn?: string;
  registerDt: string;
  updateDt?: string;
}

export interface ICountry {
  countryId: number;
  countryCode: string;
  countryNameKo: string;
  countryNameEn: string;
  programCost: any;
  isHaveChecklist: boolean;
  functionalCertAuthority: any;
  nonFunctionalCertAuthority: any;
}

export enum EProductStatus {
  PRD_RDY = 'PRD-RDY',
  PRD_REG = 'PRD-REG',
  PRD_DEL = 'PRD-DEL',
}
// Challenge Test
export interface IProductChallengeTest {
  productChallengeTestId: number;
  productId: number;
  isReportExist: boolean | null;
  bacteriaTemperature: number | null;
  yeastTemperature: number | null;
  moldTemperature: number | null;
  isBacteriaTemperatureNone: boolean | null;
  isYeastTemperatureNone: boolean | null;
  isMoldTemperatureNone: boolean | null;
  isMergeBacteria: boolean | null;
  isMergeYeast: boolean | null;
  isMergeMold: boolean | null;
  saureus: number | null;
  saureusExponent: number | null;
  isSaureusTntc: boolean | null;
  ecoli: number | null;
  ecoliExponent: number | null;
  isEcoliTntc: boolean | null;
  paeruginosa: number | null;
  paeruginosaExponent: number | null;
  isPaeruginosaTntc: boolean | null;
  bacillus: number | null;
  bacillusExponent: number | null;
  isBacillusTntc: boolean | null;
  enterobacter: number | null;
  enterobacterExponent: number | null;
  isEnterobacterTntc: boolean | null;
  yeastName: string | null;
  yeast: number | null;
  yeastExponent: number | null;
  isYeastTntc: boolean | null;
  moldName: string | null;
  mold: number | null;
  moldExponent: number | null;
  isMoldTntc: boolean | null;
  mergeBacteria: number | null;
  mergeBacteriaExponent: number | null;
  isMergeBacteriaTntc: boolean | null;
  mergeYeast: number | null;
  mergeYeastExponent: number | null;
  isMergeYeastTntc: boolean | null;
  mergeMold: number | null;
  mergeMoldExponent: number | null;
  isMergeMoldTntc: boolean | null;
  countryId: number | null;
  isSaureus: boolean | null;
  isEcoli: boolean | null;
  isPaeruginosa: boolean | null;
  isBacillus: boolean | null;
  isEnterobacter: boolean | null;
}
export interface IProductChallengeTestAddParam extends IProductChallengeTest {
  productId: number;
}
export interface IProductChallengeTestUpdateParam
  extends Partial<IProductChallengeTest> {
  productChallengeTestId: number;
}

export interface IProductChallengeTestForm
  extends Partial<IProductChallengeTest> {}

// Packing Attestation
export interface IPackingAttestationBasic {
  packingContainer1: string;
  packingContainer2: string;
  isUseRecyclingContainer: boolean;
  isUseColorContainer: boolean;
  isUsePackingContainer2: boolean;
  countryId: number | null;
}

export interface IPackingAttestation extends IPackingAttestationBasic {
  productId: number;
  productPackingAttestationId: number;
  productPackingAttestationAttaches: IProductPackingAttestationAttach[];
}

export interface IProductPackingAttestationAttach {
  productPackingAttestationAttachId: number;
  productPackingAttestationId: number;
  attachType: '1ST' | '2ST';
  attachUrl: string;
  filename: string;
}

export interface IPackingAttestationAddParam extends IPackingAttestationBasic {
  productId: number;
}
export interface IPackingAttestationUpdateParam
  extends IPackingAttestationBasic {
  productPackingAttestationId: number;
}

export enum EAttachType {
  FIRST = '1ST',
  SECOND = '2ST',
}
// Product specification
export interface IProductSpecification {
  countryOrigin: string;
  bestBefore: string;
  productType: string;
  targetAge: string;
  skinType: string;
  applicationArea: string;
  useFrequency: string;
  productPeriod: number;
  productPeriodUnit: string;
  packaging: number;
  packagingUnit: string;
  carton: number;
  cartonUnit: string;
  cartonWeight: number;
  cartonWeightUnit: string;
  cartonSize_W: number;
  cartonSize_D: number;
  cartonSize_H: number;
  cartonSizeUnit: string;
  useManual: string;
  warning: string;
  productFeature: string;
  marketingClaims: string;
  miscellaneous: string;
  inciList: string;
  tempSaved: boolean;
}

export interface IShelfLifeDeclaration {
  productExpireMonths: number;
}

// Microbiological Properties
export interface IMicrobiologicalPropertiesForm {
  isTotalABacteria: boolean;
  isSAureus: boolean;
  isEColi: boolean;
  isPAeruginosa: boolean;
}

export interface IProductDuplicatedCheckParams {
  companyId: number;
  productNameEn: string;
  netWeight: number;
  netWeightUnit: string;
}

// Formula
export interface IProductFormulaBreakdownItem {
  no: number;
  noRowSpan: number;
  inciName: string;
  formulaWt: string;
  formulaWtRowSpan: number;
  formulaRawMaterialPercent: string;
  formulaSubstancePercent: string;
  formulaFunction: string;
  formulaCasNo: string;
  bigo: string;
  useDescription: string | null;
  limitedFormulaCountryInfos: ICountryProhibitInfo[];
  isJapanCitesProhibited: boolean | null;
  isJapanProhibited: boolean | null;
}

export interface IProductFormulaBreakdownDraft {
  list: IProductFormulaBreakdownItem[];
  isWtSumError: boolean;
  isSubstanceSumError: boolean;
  uploadedExcelUrl: string | null;
}

// Product Document Status
export interface IProductDocumentSeq {
  documentCode: string;
  documentName: string;
  documentSeqId: number;
  isForChina: boolean | null;
  isForEu: boolean | null;
  isIncludeManufacturerList: boolean | null;
  isRequireUpload: boolean;
  uploadType: 'PRE' | 'AFT';
}
export interface IProductDocumentStatus {
  correctRequestMessage: string | null;
  countryId: number | null;
  documentCode: string | null;
  documentSeqId: number;
  documentUrl: string | null;
  filename: string | null;
  formType: 'C' | 'M' | 'B' | null;
  isDetached: boolean;
  isDisplayed: boolean;
  isSkip: boolean;
  modifierCompanyName: string | null;
  modifierId: number | null;
  modifierName: string | null;
  productDocStatusId: number;
  productId: number;
  registerDt: string;
  status: EDocStatus;
  updateDt: string | null;
  uploadFileUrl: string | null;
  documentSeq: IProductDocumentSeq;
}
