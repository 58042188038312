import { Route, Switch } from 'react-router';

import path from 'lib/path';
import ProductSampleBasicPage from 'pages/manufacturer/productSample/ProductSampleBasicPage';
import ProductSampleRecipePage from 'pages/manufacturer/productSample/ProductSampleRecipePage';
import ProductSampleRecipeDetailPage from 'pages/manufacturer/productSample/ProductSampleRecipeDetailPage';
import ProductSampleProcessMapPage from 'pages/manufacturer/productSample/ProductSampleProcessMapPage';
import Error404Page from 'pages/error/Error404Page';
import ProductSampleReadyListPage from 'pages/manufacturer/productSample/ProductSampleReadyListPage';
import ProductSampleListPage from 'pages/manufacturer/productSample/ProductSampleListPage';
import ProductSampleDetailPage from 'pages/manufacturer/productSample/ProductSampleDetailPage';

const ProductSampleRouter = () => {
  return (
    <Switch>
      <Route
        path={path.manufacturer.productSample.readyList}
        component={ProductSampleReadyListPage}
      />
      <Route
        path={path.manufacturer.productSample.list}
        component={ProductSampleListPage}
      />
      <Route
        path={`${path.manufacturer.productSample.detail}/:productSampleId`}
        component={ProductSampleDetailPage}
      />
      <Route
        path={[
          `${path.manufacturer.productSample.basic}/:productSampleId`,
          path.manufacturer.productSample.basic,
        ]}
        component={ProductSampleBasicPage}
      />
      <Route
        path={`${path.manufacturer.productSample.recipe}/:productSampleId`}
        component={ProductSampleRecipePage}
      />
      <Route
        path={`${path.manufacturer.productSample.recipeDetail}/:productSampleId`}
        component={ProductSampleRecipeDetailPage}
      />
      <Route
        path={`${path.manufacturer.productSample.processMap}/:productSampleId`}
        component={ProductSampleProcessMapPage}
      />
      <Route path="*" component={Error404Page} />
    </Switch>
  );
};

export default ProductSampleRouter;
