// source: https://yeun.github.io/open-color/

const palette = {
  primary: '#3785f7',
  primaryLight: '#dcf3ff',
  primaryWeek: '#f2fbff',
  secondary: '#00B9E1',
  main: ['#3785f7', '#dcf3ff', '#f2fbff'],
  success: '#29bf5f',
  error: ['#ff4d4f'],
  inactive: '#d3d3d3',
  disabled: '#e7e7e7',
  gray: '#f6f7f9',
  paleGray: '#f5f9ff',
  paleGray10: '#f6f7f9',
  warmGray: '#a2a2a2',
  slate: '#4e5968',
  slategray10: '#eff1f8',
  slategray50: '#9195A5',
  warmGrayTwo: '#7e7e7e',
  check: '#03cba4',
  lightBlue80: '#dcf3ff',
  lightBlue10: '#f1f6ff',
  darkNavy: '#4e5968',
  lightBrown: '#f9f9f9',
  lightGreen: '#e3fbe7',
  warning: '#fad716',
  text: {
    black: '#222',
    gray: '#7e7e7e',
    warmGray: '#a2a2a2',
    primary: '#3785f7',
    success: '#29bf5f',
    lightGreen: '#e3fbe7',
    disabled: '#a9a6a6',
    error: '#ff5f5f',
    check: '#03cba4',
    slate: '#4e5968',
    slategray50: '#9195A5',
  },
};

export default palette;
