import { useQuery } from 'react-query';

import * as productApi from 'lib/api/product';
import { useMemo } from 'react';

export const useShelfLifeDeclaration = ({
  productId,
  countryId,
  enabled,
}: {
  productId: number;
  countryId: number;
  enabled: boolean;
}) => {
  const {
    data: { productExpireMonths = null } = {},
    isFetching: getLoading,
  } = useQuery(
    ['brand/product/productExpireMonths', productId, countryId],
    () => productApi.getShelfLifeDeclaration({ productId, countryId }),
    { select: (res) => res.data.result, enabled },
  );

  return useMemo(() => ({ productExpireMonths, getLoading }), [
    productExpireMonths,
    getLoading,
  ]);
};
