import PageTemplate from 'templates/PageTemplate';
import { ProductList } from 'components/manufacturer/product/ProductList';

const ProductListPage = () => {
  return (
    <PageTemplate
      title="등록 완료 제품 관리"
      description="아래 등록 완료된 제품을 관리해보세요."
      back={false}
    >
      <ProductList />
    </PageTemplate>
  );
};

export default ProductListPage;
