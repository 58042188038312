import React, { useState } from 'react';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import styled from 'styled-components';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import FooterBox from 'components/FooterBox';
import { alphabetOrNumberRule, requireRule } from 'lib/validate';
import { validateAuthCode } from 'lib/api/product';

const ProductImportBlock = styled.div`
  max-width: 520px;
  margin: 0 auto;
`;

const ProductImport = ({
  manufacturers,
  form,
  submitLoading,
  onSubmit,
}: any) => {
  const [searchValue, setSearchValue] = useState('');
  const handleSelectSearch = (value: string) => {
    setSearchValue(value.replace(/\s/gi, ''));
  };
  return (
    <ProductImportBlock>
      <Form
        layout="vertical"
        colon={false}
        validateTrigger="onChange"
        form={form}
        onFinish={onSubmit}
      >
        <Form.Item label="제조사명" rules={[requireRule]} name="manufacturerId">
          <Select
            searchValue={searchValue}
            onSearch={handleSelectSearch}
            placeholder="제조사 검색"
            showSearch
            filterOption={(keyword, option) =>
              (option?.label as string).includes(keyword)
            }
            onChange={() => {
              const authCodes = form.getFieldValue('authCodes');
              form.validateFields(
                authCodes.map((authCode: string, index: number) =>
                  authCode ? ['authCodes', index] : undefined,
                ),
              );
            }}
            options={manufacturers.map(
              ({
                manufacturerId,
                companyNameKo,
              }: {
                manufacturerId: number;
                companyNameKo: string;
              }) => ({
                label: companyNameKo,
                value: manufacturerId,
              }),
            )}
          />
        </Form.Item>

        <Form.Item label="제품 코드" required>
          <Form.List initialValue={['']} name="authCodes">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <Form.Item key={field.key} style={{ marginBottom: 0 }}>
                    <Row gutter={8} wrap={false}>
                      <Col flex="1 1 100%">
                        <Form.Item
                          {...field}
                          name={[field.name]}
                          fieldKey={[field.fieldKey]}
                          hasFeedback
                          rules={[
                            requireRule,
                            alphabetOrNumberRule,
                            {
                              pattern: /^.{16}$/,
                              message: '16자리 모두 입력 필수',
                            },
                            ({ getFieldValue }) => ({
                              validator: async (_, authCode) => {
                                const manufacturerId = getFieldValue(
                                  'manufacturerId',
                                );
                                if (!/^.{16}$/.test(authCode)) {
                                  return;
                                }
                                if (!manufacturerId) {
                                  throw Error('제조사를 선택해 주세요.');
                                }
                                const authCodes = getFieldValue('authCodes');
                                if (
                                  authCodes.filter(
                                    (code: string) => code === authCode,
                                  ).length > 1
                                ) {
                                  throw Error('이미 입력한 제품 코드 입니다.');
                                }
                                const {
                                  data: { errors },
                                } = await validateAuthCode({
                                  manufacturerId,
                                  authCode,
                                });
                                if (errors[0].code !== '0') {
                                  throw Error(errors[0].message);
                                }
                              },
                            }),
                          ]}
                          normalize={(value) => value.toUpperCase()}
                        >
                          <Input
                            maxLength={16}
                            placeholder="16자리, 알파벳 + 숫자"
                          />
                        </Form.Item>
                      </Col>
                      {fields.length > 1 && (
                        <Col flex="0 0 auto">
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={() => remove(field.name)}
                            style={{ marginTop: 16 }}
                          />
                        </Col>
                      )}
                    </Row>
                  </Form.Item>
                ))}
                <Button
                  type="dashed"
                  onClick={() => add('')}
                  block
                  icon={<PlusOutlined />}
                >
                  추가
                </Button>
              </>
            )}
          </Form.List>
        </Form.Item>
        <FooterBox>
          <Button loading={submitLoading} type="primary" htmlType="submit">
            등록
          </Button>
        </FooterBox>
      </Form>
    </ProductImportBlock>
  );
};

export default React.memo(ProductImport);
