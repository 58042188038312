import qs from 'qs';

import client from 'lib/api/client';
import { IProduct, IProductDuplicatedCheckParams } from 'types/product';
import { IAPIResponse } from 'types/common';

export const getDuplicatedProduct = (params: IProductDuplicatedCheckParams) => {
  const { companyId, ...rest } = params;
  return client.get<IAPIResponse<IProduct | null>>(
    `/companies/${companyId}/product?${qs.stringify(rest)}`,
  );
};
