import { useState } from 'react';
import styled from 'styled-components';
import { Button, Card, Col, Row } from 'antd';
import { nanoid } from 'nanoid';
import cx from 'classnames';

import {
  EProductSampleRecipeDetailErrorCode,
  IProductSampleRecipeDetailItem,
} from 'types/manufacturer/productSample';
import Table from 'components/Table';
import { Typography } from 'components/system';
import { countryCodeMap } from 'lib/consts';
import { downloadFile } from 'lib/file';
import { toNoExponents } from 'lib/form';
import CountryProhibitInfosModal from 'components/modal/formula/CountryProhibitInfosModal';
import { ICountryProhibitInfo } from 'types/formula';
import palette from 'lib/styles/palette';

const RecipeDetailTableBlock = styled.div`
  .ant-table-cell {
    font-size: 12px;

    th {
      text-align: center;
    }
  }
  td.ant-table-cell {
    word-break: break-all;
  }
`;

const FormulaDownloadCard = styled(Card)`
  margin-top: 24px;
  border: 1px solid #d7e7fe;
  background-color: ${palette.lightBlue10};

  .ant-card-body {
    padding: 16px;
  }
`;

const RecipeDetailTable = ({
  recipeDetailItems,
  formulaExcelUrl,
  formulaCountryVisible = true,
}: {
  recipeDetailItems: IProductSampleRecipeDetailItem[];
  formulaExcelUrl: string | null;
  formulaCountryVisible?: boolean;
}) => {
  const [limitedFormulaCountryInfos, setLimitedFormulaCountryInfos] = useState<
    ICountryProhibitInfo[] | null
  >(null);
  const [
    limitedFormulaDefaultCountryName,
    setLimitedFormulaDefaultCountryName,
  ] = useState<string | null>(null);
  const handleClickLimitedFormula = (
    limitedFormulaCountryInfos: ICountryProhibitInfo[],
    limitedFormulaDefaultCountryName?: string,
  ) => {
    setLimitedFormulaCountryInfos(limitedFormulaCountryInfos);
    if (limitedFormulaDefaultCountryName) {
      setLimitedFormulaDefaultCountryName(limitedFormulaDefaultCountryName);
    }
  };
  return (
    <RecipeDetailTableBlock>
      <CountryProhibitInfosModal
        defaultCountryName={limitedFormulaDefaultCountryName || undefined}
        countryInfos={limitedFormulaCountryInfos || []}
        onClose={() => setLimitedFormulaCountryInfos(null)}
      />
      <Table bordered>
        <Table.Thead>
          <Table.Tr>
            <Table.Th width="40" style={{ textAlign: 'center' }}>
              Phase
            </Table.Th>
            <Table.Th width="88">원료명</Table.Th>
            <Table.Th width="80">원료사</Table.Th>
            <Table.Th width="188">INCI Name</Table.Th>
            <Table.Th width="64">
              RM or Ingredient
              <br />% in fla
            </Table.Th>
            <Table.Th width="72">
              Ingredients <br />% in RM
            </Table.Th>
            <Table.Th width="64">Actual WT%</Table.Th>
            <Table.Th width="72">
              Ingredient
              <br />
              function
            </Table.Th>
            <Table.Th width="64">CAS No.</Table.Th>
            {formulaCountryVisible && (
              <>
                <Table.Th width="64">비고</Table.Th>
                <Table.Th colSpan={2} width="132">
                  국가별 성분 규제
                </Table.Th>
              </>
            )}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {recipeDetailItems.map(
            ({
              phase,
              phaseRowSpan,
              materialName,
              materialNameRowSpan,
              materialCompanyName,
              materialCompanyNameRowSpan,
              inciName,
              flaIngredientPercent,
              flaIngredientPercentRowSpan,
              rmIngredientPercent,
              actualWt,
              ingredientFunction,
              casNo,
              remark,
              errorCodes,
              limitedFormulaCountryInfos,
            }) => {
              return (
                <Table.Tr key={nanoid()}>
                  {phase && (
                    <Table.Td
                      rowSpan={phaseRowSpan}
                      style={{ textAlign: 'center' }}
                    >
                      {phase}
                    </Table.Td>
                  )}
                  {materialName && (
                    <Table.Td rowSpan={materialNameRowSpan}>
                      {materialName}
                    </Table.Td>
                  )}
                  {materialCompanyName && (
                    <Table.Td rowSpan={materialCompanyNameRowSpan}>
                      {materialCompanyName}
                    </Table.Td>
                  )}
                  <Table.Td
                    className={cx({
                      error:
                        errorCodes &&
                        errorCodes.includes(
                          EProductSampleRecipeDetailErrorCode.INCI_NAME,
                        ),
                    })}
                  >
                    {inciName}
                  </Table.Td>
                  {flaIngredientPercent && (
                    <Table.Td
                      className="rm-ingredient-in-fla-percent"
                      rowSpan={flaIngredientPercentRowSpan}
                      style={{ textAlign: 'right' }}
                    >
                      {toNoExponents(flaIngredientPercent)}
                    </Table.Td>
                  )}
                  <Table.Td style={{ textAlign: 'right' }}>
                    {toNoExponents(rmIngredientPercent)}
                  </Table.Td>
                  <Table.Td
                    className={cx('actual-percent', {
                      error:
                        errorCodes &&
                        errorCodes.includes(
                          EProductSampleRecipeDetailErrorCode.ACTUAL_WT,
                        ),
                    })}
                    style={{ textAlign: 'right' }}
                  >
                    {toNoExponents(actualWt)}
                  </Table.Td>
                  <Table.Td>{ingredientFunction}</Table.Td>
                  <Table.Td>{casNo}</Table.Td>
                  {formulaCountryVisible && (
                    <>
                      <Table.Td className="remark">{remark}</Table.Td>
                      <Table.Td
                        style={{ textAlign: 'center' }}
                        className="formulaTypeList"
                        key="limit"
                        width="48"
                      >
                        {Array.from(
                          new Set(
                            limitedFormulaCountryInfos.map(({ type }) => type),
                          ),
                        )
                          .sort()
                          .map((type) => (
                            <Typography.Label
                              key={type}
                              type={type === 'limit' ? 'check' : 'error'}
                              bordered
                              style={{
                                fontSize: 10,
                                minWidth: 32,
                                height: 18,
                                lineHeight: '10px',
                                paddingLeft: 7,
                                paddingRight: 7,
                              }}
                              onClick={() =>
                                handleClickLimitedFormula(
                                  limitedFormulaCountryInfos,
                                )
                              }
                            >
                              {type === 'limit' ? '제한' : '금지'}
                            </Typography.Label>
                          ))}
                      </Table.Td>
                      <Table.Td style={{ textAlign: 'center' }} width="84">
                        <Row gutter={[2, 2]} justify="center">
                          {limitedFormulaCountryInfos.map(
                            ({ countryNameKo }, index) => (
                              <Col key={countryNameKo}>
                                <img
                                  key={index}
                                  src={`https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/flag/${countryCodeMap[countryNameKo]}.png`}
                                  alt=""
                                  style={{
                                    width: '20px',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() =>
                                    handleClickLimitedFormula(
                                      limitedFormulaCountryInfos,
                                      countryNameKo,
                                    )
                                  }
                                />
                              </Col>
                            ),
                          )}
                        </Row>
                      </Table.Td>
                    </>
                  )}
                </Table.Tr>
              );
            },
          )}
        </Table.Tbody>
      </Table>
      {formulaExcelUrl && (
        <FormulaDownloadCard>
          <Row align="middle" justify="center">
            <Col>
              <Typography.Text inline gutter={{ right: 16 }}>
                업로드된 처방 상세를 기준으로 생성된 단일 성분표를 다운로드해
                보세요.
              </Typography.Text>
            </Col>
            <Col>
              <Button
                size="small"
                icon={
                  <i className="material-icons outlined primary md-18">
                    file_download
                  </i>
                }
                style={{
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
                onClick={() =>
                  downloadFile(formulaExcelUrl, 'SingleFormula.xlsx')
                }
              >
                <Typography.Text type="tertiary" color="primary" medium>
                  Formula Single
                </Typography.Text>
              </Button>
            </Col>
          </Row>
        </FormulaDownloadCard>
      )}
    </RecipeDetailTableBlock>
  );
};

export default RecipeDetailTable;
