import { Select } from 'antd';
import styled from 'styled-components';

import { coaPhaseExampleMap } from 'lib/consts';
import { useCoaPhases, useGetRegisteredCoa } from 'service/material/coa';
import { useRegisteredRawMaterials } from 'service/material/rawMaterial';
import { Typography } from 'components/system';

interface IProps {
  isImportWay: boolean;
  materialCategoryId?: number;
  onChangeMaterialCategoryId: (materialCategoryId: number) => void;
  onSelectRegisteredMaterial: (material: any) => void;
}

const PhaseSelectBlock = styled.div`
  width: 500px;
  margin: 40px auto 0;
`;

const PhaseSelect = ({
  isImportWay,
  materialCategoryId,
  onChangeMaterialCategoryId,
  onSelectRegisteredMaterial,
}: IProps) => {
  const { coaPhases, getCoaPhasesLoading } = useCoaPhases();
  const phaseOptions = coaPhases.map(({ materialCategoryId, code, name }) => ({
    label:
      code === 'CUSTOMIZED'
        ? coaPhaseExampleMap[code]
        : `${name}${coaPhaseExampleMap[code]}`,
    value: materialCategoryId,
  }));
  const { registeredRawMaterials } = useRegisteredRawMaterials();
  const materialOptions = registeredRawMaterials.map(
    ({ materialId, materialNameEn }) => ({
      label: materialNameEn,
      value: materialId,
    }),
  );
  const getRegisteredCoa = useGetRegisteredCoa();
  const handleSelectRawMaterial = (materialId: number) => {
    getRegisteredCoa(materialId, {
      onSuccess: (res) => onSelectRegisteredMaterial(res.data.result),
    });
  };

  return (
    <PhaseSelectBlock>
      <Typography.Headline
        type="secondary"
        align="center"
        gutter={{ bottom: 32 }}
      >
        {!isImportWay
          ? '성상을 선택해 주세요.'
          : '불러오실 원료를 선택해 주세요.'}
      </Typography.Headline>
      {!isImportWay ? (
        <Select
          loading={getCoaPhasesLoading}
          placeholder="성상 선택"
          options={phaseOptions}
          style={{ width: '100%' }}
          value={materialCategoryId}
          onChange={onChangeMaterialCategoryId}
        />
      ) : (
        <Select
          placeholder="원료명 입력 또는 선택"
          options={materialOptions}
          style={{ width: '100%' }}
          onChange={handleSelectRawMaterial}
        />
      )}
    </PhaseSelectBlock>
  );
};

export default PhaseSelect;
