import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, message } from 'antd';

import { messages } from 'lib/consts';
import {
  colorOptions,
  formOptions,
  hardnessUnitOptions,
  odourOptions,
  viscosityUnitOptions,
} from 'lib/selectOption';
import { capitalize } from 'lib/validate';
import {
  addProductPhysicalProperty,
  getProductPhysicalProperty,
  initializeProductPhysicalProperty,
  updateProductPhysicalProperty,
} from 'modules/product';
import { getUndefinedFilteredObject } from 'lib/form';

export const usePhysicalProperties = (
  productId?: number,
  countryId?: number,
) => {
  const dispatch = useDispatch();
  const {
    currentProduct,
    productPhysicalProperty,
    readOnlyMode,
    addProductPhysicalPropertyLoading,
    updateProductPhysicalPropertyLoading,
    getProductPhysicalPropertyLoading,
  } = useSelector(({ loading, product, certificate }: any) => ({
    currentProduct: product.currentProduct,
    productPhysicalProperty: product.productPhysicalProperty,
    readOnlyMode: certificate.readOnlyMode,
    addProductPhysicalPropertyLoading:
      loading['product/ADD_PRODUCT_PHYSICAL_PROPERTY'],
    updateProductPhysicalPropertyLoading:
      loading['product/UPDATE_PRODUCT_PHYSICAL_PROPERTY'],
    getProductPhysicalPropertyLoading:
      loading['product/GET_PRODUCT_PHYSICAL_PROPERTY'],
  }));
  const updateMode = productPhysicalProperty !== null;
  const [form] = Form.useForm();

  const handleChangePhRadioGroup = (isExistedPh: boolean) => {
    if (!isExistedPh) {
      form.setFieldsValue({ phLimitMin: null });
      form.setFieldsValue({ phLimitMax: null });
      form.setFieldsValue({ ph: null });
    }
  };

  const handleChangeSpecificGravityRadioGroup = (
    isExistedSpecificGravity: boolean,
  ) => {
    if (!isExistedSpecificGravity) {
      if (!isExistedSpecificGravity) {
        form.setFieldsValue({ specificGravityLimitMin: null });
        form.setFieldsValue({ specificGravityLimitMax: null });
        form.setFieldsValue({ specificGravity: null });
      }
    }
  };

  const handleChangeViscosityRadioGroup = (isExistedViscosity: boolean) => {
    if (!isExistedViscosity) {
      form.setFieldsValue({ viscosityLimitMin: null });
      form.setFieldsValue({ viscosityLimitMax: null });
      form.setFieldsValue({ viscosity: null });
      form.setFieldsValue({ viscosityUnit: null });
    }
  };

  const handleChangeHardnessRadioGroup = (isExistedHardness: boolean) => {
    if (!isExistedHardness) {
      form.setFieldsValue({ hardnessLimitMax: null });
      form.setFieldsValue({ hardnessLimitMin: null });
      form.setFieldsValue({ hardness: null });
      form.setFieldsValue({ hardnessUnit: null });
    }
  };

  const onSubmit = (formValues: any) => {
    const {
      viscosityUnitDirect,
      aerosolOrEthanol,
      hardnessUnitDirect,
      ...requireValues
    } = formValues;
    if (!updateMode) {
      dispatch(
        addProductPhysicalProperty({
          productId: currentProduct.productId,
          ...requireValues,
          isAerosolProduct: formValues.aerosolOrEthanol === 'aerosol',
          isEthanolProduct: formValues.aerosolOrEthanol === 'ethanol',
          form:
            formValues.form === 'others'
              ? formValues.formDirect
              : formValues.form,
          color:
            formValues.color === 'others'
              ? formValues.colorDirect
              : formValues.color,
          odour:
            formValues.odour === 'others'
              ? formValues.odourDirect
              : formValues.odour,
          ...(formValues.isExistedViscosity && {
            viscosityLimitMin: formValues.viscosityLimitMin,
            viscosityLimitMax: formValues.viscosityLimitMax,
            viscosity: formValues.viscosity,
            viscosityUnit:
              formValues.viscosityUnit === 'others'
                ? formValues.viscosityUnitDirect
                : formValues.viscosityUnit,
          }),
          ...(formValues.isExistedHardness && {
            hardnessLimitMin: formValues.hardnessLimitMin,
            hardnessLimitMax: formValues.hardnessLimitMax,
            hardness: formValues.hardness,
            hardnessUnit:
              formValues.hardnessUnit === 'others'
                ? formValues.hardnessUnitDirect
                : formValues.hardnessUnit,
          }),
        }),
      );
    } else {
      let {
        productType,
        form,
        formDirect,
        color,
        colorDirect,
        odour,
        odourDirect,
        isExistedPh,
        phLimitMin,
        phLimitMax,
        ph,
        isExistedSpecificGravity,
        specificGravityLimitMin,
        specificGravityLimitMax,
        specificGravity,
        netWeightUnit,
        netWeightUnitDirect,
        lotNo,
        manufacturingDate,
        aerosolOrEthanol,
        expiryDate,
        isExistedViscosity,
        viscosity,
        viscosityLimitMax,
        viscosityLimitMin,
        viscosityUnit,
        viscosityUnitDirect,
        isExistedHardness,
        hardness,
        hardnessLimitMax,
        hardnessLimitMin,
        hardnessUnit,
        hardnessUnitDirect,
      } = formValues;
      if (form === 'others') form = formDirect;
      if (color === 'others') color = colorDirect;
      if (odour === 'others') odour = odourDirect;
      if (viscosityUnit === 'others') viscosityUnit = viscosityUnitDirect;
      if (netWeightUnit === 'others') netWeightUnit = netWeightUnitDirect;
      if (hardnessUnit === 'others') hardnessUnit = hardnessUnitDirect;
      const updateFormValues = {
        ...(productPhysicalProperty.productType !== productType && {
          productType,
        }),
        ...(productPhysicalProperty.isAerosolProduct !==
          (aerosolOrEthanol === 'aerosol') && {
          isAerosolProduct: aerosolOrEthanol === 'aerosol',
        }),
        ...(productPhysicalProperty.isEthanolProduct !==
          (aerosolOrEthanol === 'ethanol') && {
          isEthanolProduct: aerosolOrEthanol === 'ethanol',
        }),
        ...(productPhysicalProperty.form.toLowerCase() !==
          form.toLowerCase() && { form }),
        ...(productPhysicalProperty.color.toLowerCase() !==
          color.toLowerCase() && { color }),
        ...(productPhysicalProperty.odour.toLowerCase() !==
          odour.toLowerCase() && { odour }),
        ...(productPhysicalProperty.lotNo !== lotNo && { lotNo }),
        ...(productPhysicalProperty.manufacturingDate !== manufacturingDate && {
          manufacturingDate,
        }),
        ...(productPhysicalProperty.expiryDate !== expiryDate && {
          expiryDate,
        }),
        // pH
        ...(productPhysicalProperty.isExistedPh !== isExistedPh && {
          isExistedPh,
        }),
        ...(productPhysicalProperty.phLimitMin !== phLimitMin && {
          phLimitMin,
        }),
        ...(productPhysicalProperty.phLimitMax !== phLimitMax && {
          phLimitMax,
        }),
        ...(productPhysicalProperty.ph !== ph && { ph }),
        // Specific gravity
        ...(productPhysicalProperty.isExistedSpecificGravity !==
          isExistedSpecificGravity && { isExistedSpecificGravity }),
        ...(productPhysicalProperty.specificGravityLimitMin !==
          specificGravityLimitMin && { specificGravityLimitMin }),
        ...(productPhysicalProperty.specificGravityLimitMax !==
          specificGravityLimitMax && { specificGravityLimitMax }),
        ...(productPhysicalProperty.specificGravity !== specificGravity && {
          specificGravity,
        }),
        // Viscosity
        ...(productPhysicalProperty.isExistedViscosity !==
          isExistedViscosity && {
          isExistedViscosity,
        }),
        ...(viscosityLimitMin &&
          productPhysicalProperty.viscosityLimitMin !== viscosityLimitMin && {
            viscosityLimitMin,
          }),
        ...(viscosityLimitMax &&
          productPhysicalProperty.viscosityLimitMax !== viscosityLimitMax && {
            viscosityLimitMax,
          }),
        ...(viscosityUnit &&
          productPhysicalProperty.viscosityUnit !== viscosityUnit && {
            viscosityUnit,
          }),
        ...(viscosity &&
          productPhysicalProperty.viscosity !== viscosity && { viscosity }),
        // Hardness
        ...(productPhysicalProperty.isExistedHardness !== isExistedHardness && {
          isExistedHardness,
        }),
        ...(hardnessLimitMin &&
          productPhysicalProperty.hardnessLimitMin !== hardnessLimitMin && {
            hardnessLimitMin,
          }),
        ...(hardnessLimitMax &&
          productPhysicalProperty.hardnessLimitMax !== hardnessLimitMax && {
            hardnessLimitMax,
          }),
        ...(hardnessUnit &&
          productPhysicalProperty.hardnessUnit !== hardnessUnit && {
            hardnessUnit,
          }),
        ...(hardness &&
          productPhysicalProperty.hardness !== hardness && { hardness }),
      };
      if (
        Object.keys(getUndefinedFilteredObject(updateFormValues)).length === 0
      ) {
        return message.warn(messages.NO_NEED_TO_UPDATE);
      }
      dispatch(
        updateProductPhysicalProperty({
          productPhysicalPropertyId:
            productPhysicalProperty.productPhysicalPropertyId,
          ...updateFormValues,
        }),
      );
    }
  };

  useEffect(() => {
    dispatch(
      getProductPhysicalProperty({
        productId,
        countryId,
      }),
    );
    return () => {
      dispatch(initializeProductPhysicalProperty());
    };
  }, [productId, countryId]);

  useEffect(() => {
    if (updateMode && productPhysicalProperty) {
      form.setFieldsValue({
        ...productPhysicalProperty,
        form: formOptions
          .map(({ value }) => value)
          .includes(capitalize(productPhysicalProperty.form))
          ? capitalize(productPhysicalProperty.form)
          : 'others',
        formDirect: formOptions
          .map(({ value }) => value)
          .includes(capitalize(productPhysicalProperty.form))
          ? ''
          : capitalize(productPhysicalProperty.form),
        color: colorOptions
          .map(({ value }) => value)
          .includes(capitalize(productPhysicalProperty.color))
          ? capitalize(productPhysicalProperty.color)
          : 'others',
        colorDirect: colorOptions
          .map(({ value }) => value)
          .includes(capitalize(productPhysicalProperty.color))
          ? ''
          : capitalize(productPhysicalProperty.color),
        viscosityUnitDirect: viscosityUnitOptions
          .map(({ value }) => value)
          .includes(productPhysicalProperty.viscosityUnit)
          ? productPhysicalProperty.viscosityUnit
          : 'others',
        hardnessUnitDirect: hardnessUnitOptions
          .map(({ value }) => value)
          .includes(productPhysicalProperty.hardnessUnit)
          ? productPhysicalProperty.hardnessUnit
          : 'others',
        odour: odourOptions
          .map(({ value }) => value)
          .includes(capitalize(productPhysicalProperty.odour))
          ? capitalize(productPhysicalProperty.odour)
          : 'others',
        odourDirect: odourOptions
          .map(({ value }) => value)
          .includes(capitalize(productPhysicalProperty.odour))
          ? ''
          : capitalize(productPhysicalProperty.odour),
        aerosolOrEthanol: productPhysicalProperty.isAerosolProduct
          ? 'aerosol'
          : productPhysicalProperty.isEthanolProduct
          ? 'ethanol'
          : 'nothing',
      });
    }
  }, [productPhysicalProperty]);

  useEffect(() => {
    if (!updateMode && process.env.NODE_ENV === 'development') {
      form.setFieldsValue({
        productType: 'LEAVE_ON',
        form: 'solid',
        color: 'red',
        odour: 'odourless',
        lotNo: 'apple10',
        ph: 7.0,
        specificGravity: 1.0,
        manufacturingDate: '2021-01-01',
        expiryDate: '2023-01-01',
        viscosity: 13,
        viscosityLimitMax: 15,
        viscosityLimitMin: 13,
        viscosityUnit: 'others',
        viscosityUnitDirect: 'sd',
        hardness: 24.1,
        hardnessLimitMax: 26,
        hardnessLimitMin: 23,
        hardnessUnit: 'kg',
      });
    }
  }, [updateMode]);

  return useMemo(
    () => ({
      form,
      currentProduct,
      productPhysicalProperty,
      fetchLoading:
        addProductPhysicalPropertyLoading ||
        updateProductPhysicalPropertyLoading,
      updateMode,
      readOnlyMode,
      handleChangePhRadioGroup,
      handleChangeSpecificGravityRadioGroup,
      handleChangeViscosityRadioGroup,
      handleChangeHardnessRadioGroup,
      onSubmit,
    }),
    [
      form,
      addProductPhysicalPropertyLoading,
      updateProductPhysicalPropertyLoading,
      getProductPhysicalPropertyLoading,
      updateMode,
      readOnlyMode,
    ],
  );
};
