import styled from 'styled-components';
import { Form, Input, Row, Col, InputNumber } from 'antd';

import palette from 'lib/styles/palette';
import {
  checkIsFutureDate,
  checkIsPastDate,
  exceptKoreanRule,
  noPastDateRule,
  normalizeDate,
  noFutureDateRule,
  requireRule,
} from 'lib/validate';
import { Tip, Typography } from 'components/system';
import DateInput from 'components/system/form/DateInput';

const CoaFormBlock = styled.div`
  margin-top: 36px;
`;

const FormBlock = styled.div`
  border-top: 2px solid ${palette.primary};
  padding-top: 16px;
`;

const FormWrap = styled.div`
  max-width: 520px;
  margin: 0 auto;
`;

const InputNumberWithUnit = styled(InputNumber)`
  .ant-input-number-input-wrap {
    position: relative;

    &::after {
      position: absolute;
      right: 16px;
      top: 8px;
      content: 'month(s)';
      font-size: 16px;
      letter-spacing: -1px;
      color: ${palette.text.disabled};
    }
  }
  input {
    padding-right: 76px;
    text-align: right;
  }
`;

const CoaForm = () => {
  return (
    <CoaFormBlock>
      <Typography.Title gutter={{ bottom: 16 }}>
        CoA 기본 정보 입력
      </Typography.Title>
      <FormBlock>
        <FormWrap>
          <Row gutter={8} align="bottom">
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item
                label="Lot No."
                name="lotNo"
                rules={[requireRule, exceptKoreanRule]}
              >
                <Input placeholder="최신 Lot No. 입력" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item
                label="Shelf life"
                name="shelfLife"
                rules={[requireRule]}
              >
                <InputNumberWithUnit
                  style={{ width: '100%' }}
                  className="hide-handler"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8} align="bottom">
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item
                label={
                  <Row gutter={8}>
                    <Col>
                      <Typography.Text>Manufacturing Date</Typography.Text>
                    </Col>
                    <Col>
                      <Tip trigger="click">
                        <Typography.Text type="tertiary">
                          입력하신 Lot No.에 해당하는 생산일을 기입해 주세요.
                        </Typography.Text>
                      </Tip>
                    </Col>
                  </Row>
                }
                name="manufacturingDate"
                rules={[requireRule, noFutureDateRule]}
                normalize={normalizeDate}
              >
                <DateInput checkIsValidDate={checkIsPastDate} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item
                label={
                  <Row gutter={8}>
                    <Col>
                      <Typography.Text>Expire Date</Typography.Text>
                    </Col>
                    <Col>
                      <Tip trigger="click">
                        <Typography.Text type="tertiary">
                          입력하신 Lot No.에 해당하는 유효기간을 기입해 주세요.
                        </Typography.Text>
                      </Tip>
                    </Col>
                  </Row>
                }
                name="expiryDate"
                rules={[requireRule, noPastDateRule]}
                normalize={normalizeDate}
              >
                <DateInput checkIsValidDate={checkIsFutureDate} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Storage condition"
            name="storageCondition"
            rules={[requireRule, exceptKoreanRule]}
          >
            <Input.TextArea
              placeholder="저장 조건 입력 (영문)"
              maxLength={200}
              autoSize={{ minRows: 3, maxRows: 3 }}
              showCount
            />
          </Form.Item>
        </FormWrap>
      </FormBlock>
    </CoaFormBlock>
  );
};

export default CoaForm;
