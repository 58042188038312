import { useState } from 'react';
import styled, { css } from 'styled-components';
import { Button, Col, Modal, Row, Spin, Upload } from 'antd';

import {
  useIsProductSampleConfirmed,
  useProductSampleId,
  useProductSampleRecipeDetail,
} from 'service/manufacturer/productSample';
import { useCountryProhibitsUpdatedDate } from 'service/material/ingredient';
import { IProductSampleRecipeDetailDraft } from 'types/manufacturer/productSample';
import palette from 'lib/styles/palette';
import { Typography } from 'components/system';
import { StatusIcon } from 'components/system/general/icon';
import FooterBox from 'components/FooterBox';
import useUpdateMode from 'hook/useUpdateMode';
import RecipeDetailTable from './RecipeDetailTable';
import { downloadFile } from 'lib/file';

const RecipeDetailDraftBlock = styled.div<{
  success: 'true' | 'false';
  sum_error_class: string;
}>`
  td.ant-table-cell.error {
    outline: 1px solid ${palette.error};
    outline-offset: -1px;
  }

  ${({ success }) =>
    css`
      .ant-table-wrapper {
        border-radius: 4px;
        border-width: 1px;
        border-style: solid;
        border-color: ${success === 'true' ? palette.success : palette.error};
      }
    `}

  td.ant-table-cell.remark {
    color: ${palette.error} !important;
  }
  ${({ sum_error_class }) =>
    sum_error_class &&
    css`
      ${sum_error_class} {
        color: ${palette.error} !important;
      }
    `}
`;

const RecipeDetailDraft = ({
  recipeDraft,
}: {
  recipeDraft: IProductSampleRecipeDetailDraft;
}) => {
  const {
    recipeDetailItems,
    invalidCodes,
    formulaExcelUrl,
    errorFileUrl,
  } = recipeDraft;
  const isSuccess = invalidCodes.length === 0;
  const prohibitDataUpdatedDate = useCountryProhibitsUpdatedDate();
  return (
    <RecipeDetailDraftBlock
      success={isSuccess ? 'true' : 'false'}
      sum_error_class={invalidCodes
        .filter(({ code }) => code.startsWith('INVALID_TOTAL'))
        .map(
          ({ code }) =>
            `.${code
              .replace('INVALID_TOTAL_', '')
              .replace(/_/g, '-')
              .toLocaleLowerCase()}`,
        )
        .join(', ')}
    >
      <Row justify="space-between" align="bottom">
        <Col>
          {isSuccess ? (
            <Typography.Text type="secondary" gutter={{ bottom: 8 }}>
              <StatusIcon status="success" style={{ marginRight: 8 }} />
              정상적으로 업로드 되었습니다.
            </Typography.Text>
          ) : (
            invalidCodes.map(({ code, message }, index) => (
              <Typography.Text
                type="secondary"
                key={code}
                gutter={{ bottom: index === invalidCodes.length - 1 ? 8 : 4 }}
              >
                <StatusIcon status="error" style={{ marginRight: 8 }} />
                {code.startsWith('INVALID_TOTAL') ? (
                  message
                ) : (
                  <>
                    업로드한 파일에 오류가 있습니다.{' '}
                    <a
                      href={errorFileUrl!}
                      style={{
                        textDecoration: 'underline',
                        color: palette.text.black,
                      }}
                    >
                      여기
                    </a>
                    를 클릭하여 다운로드 후 수정하여 업로드해 주세요.
                  </>
                )}
              </Typography.Text>
            ))
          )}
        </Col>
        <Col style={{ paddingBottom: 8 }}>
          <Typography.Label
            type="disabled"
            style={{ fontSize: 12, height: 22, lineHeight: '14px' }}
          >
            DB 업데이트
          </Typography.Label>
          <Typography.Text
            type="tertiary"
            medium
            color="slate"
            inline
            gutter={{ left: 8 }}
          >
            [국가별 성분 규제]
          </Typography.Text>
          <Typography.Text
            type="tertiary"
            medium
            color="slate"
            inline
            gutter={{ left: 4 }}
          >
            {prohibitDataUpdatedDate}
          </Typography.Text>
        </Col>
      </Row>
      <RecipeDetailTable
        recipeDetailItems={recipeDetailItems}
        formulaExcelUrl={formulaExcelUrl}
      />
    </RecipeDetailDraftBlock>
  );
};

const ProductSampleRecipeDetailBlock = styled.div``;

const RecipeDetailTableWrap = styled.div``;

const ProductSampleRecipeDetail = () => {
  const productSampleId = useProductSampleId();
  const isProductSampleConfirmed = useIsProductSampleConfirmed(productSampleId);
  const updateMode = useUpdateMode();
  const [file, setFile] = useState<File | null>(null);
  const {
    validateProductSampleRecipeDetail,
    validateLoading,
    recipeDetailDraft,
    addProductSampleRecipeDetail,
    addLoading,
    productSampleRecipeDetail,
    getLoading,
  } = useProductSampleRecipeDetail(productSampleId!, updateMode);
  const handleAddProductSampleRecipeDetail = () => {
    if (!updateMode) {
      addProductSampleRecipeDetail({
        productSampleId: productSampleId!,
        file: file!,
      });
    } else {
      Modal.confirm({
        icon: null,
        closable: true,
        width: 400,
        bodyStyle: { paddingTop: 16 },
        content: (
          <Typography.Text type="secondary" align="center">
            처방 상세를 수정하는 경우,
            <br />
            공정도 페이지의 입력이 초기화되며
            <br />
            새로 입력이 필요합니다. 수정하시겠습니까?
          </Typography.Text>
        ),
        onOk: () => {
          addProductSampleRecipeDetail({
            productSampleId: productSampleId!,
            file: file!,
          });
        },
      });
    }
  };

  return (
    <ProductSampleRecipeDetailBlock>
      <Spin spinning={getLoading}>
        {!isProductSampleConfirmed && (
          <Row justify="center" gutter={16} style={{ marginBottom: 24 }}>
            <Col>
              <Button
                type="dashed"
                icon={
                  <i className="material-icons outlined primary md-18">
                    file_download
                  </i>
                }
                onClick={() =>
                  downloadFile(
                    'https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/sample/Recipe Details_V3.xlsx',
                  )
                }
              >
                엑셀 서식 다운로드
              </Button>
            </Col>
            <Col>
              <Upload
                accept=".xlsx"
                beforeUpload={(file) => {
                  validateProductSampleRecipeDetail(file, {
                    onSuccess: () => {
                      setFile(file);
                    },
                  });
                  return false;
                }}
                itemRender={() => null}
              >
                <Button
                  loading={validateLoading}
                  icon={
                    <i className="material-icons outlined primary md-18">
                      file_upload
                    </i>
                  }
                >
                  파일 업로드
                </Button>
              </Upload>
            </Col>
          </Row>
        )}
        {recipeDetailDraft && (
          <RecipeDetailDraft recipeDraft={recipeDetailDraft} />
        )}
        {productSampleRecipeDetail && !recipeDetailDraft && (
          <RecipeDetailTableWrap>
            <RecipeDetailTable
              recipeDetailItems={productSampleRecipeDetail.recipeDetailItems}
              formulaExcelUrl={productSampleRecipeDetail.formulaExcelUrl}
            />
          </RecipeDetailTableWrap>
        )}

        <FooterBox>
          {file &&
            recipeDetailDraft &&
            recipeDetailDraft.invalidCodes.length === 0 && (
              <Button
                type="primary"
                loading={addLoading}
                onClick={handleAddProductSampleRecipeDetail}
              >
                {!updateMode ? '등록' : '수정'}
              </Button>
            )}
        </FooterBox>
      </Spin>
    </ProductSampleRecipeDetailBlock>
  );
};

export default ProductSampleRecipeDetail;
