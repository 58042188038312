import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { message } from 'antd';
import produce from 'immer';

import client from 'lib/api/client';
import { IAPIPageableResponse, IAPIResponse } from 'types/common';
import {
  IClinicalTrialEstimateBasket,
  IClinicalTrialEstimateItem,
  IMyClinicalTrialEstimate,
  IRequestEstimateParams,
  IUpdateClinicalTrialParams,
} from 'types/brand/clinicalTrial/estimate';
import { IClinicalTrialRecommendation } from 'types/brand/clinicalTrial/clinicalTrial';
import Storage from 'lib/storage';
import { postSaveClinicalTrial } from 'lib/api/brand/clinicalTrial/estimate';

const useSavedClinicalTrialEstimateItemsKey = 'clinical-trial/estimates/basket';

export const useSavedClinicalTrialEstimateItems = (enabled: boolean) => {
  const { data: savedClinicalTrialEstimateItems = [], isFetching } = useQuery(
    [useSavedClinicalTrialEstimateItemsKey],
    () =>
      client.get<IAPIResponse<IClinicalTrialEstimateBasket[]>>(
        '/clinical-trial/estimates/basket',
      ),
    {
      select: (res) => res.data.result,
      enabled,
    },
  );

  return useMemo(
    () => ({
      savedClinicalTrialEstimateItems,
      isFetching,
    }),
    [savedClinicalTrialEstimateItems, isFetching],
  );
};

export const useSaveClinicalTrial = () => {
  const queryClient = useQueryClient();
  const { mutate: saveClinicalTrial, isLoading } = useMutation(
    postSaveClinicalTrial,
    {
      onSuccess: () => {
        queryClient.refetchQueries(useSavedClinicalTrialEstimateItemsKey);
      },
    },
  );

  return useMemo(
    () => ({
      saveClinicalTrial,
      isLoading,
    }),
    [saveClinicalTrial, isLoading],
  );
};

export const useUnSaveClinicalTrial = () => {
  const queryClient = useQueryClient();
  const { mutate: unSaveClinicalTrial, isLoading } = useMutation(
    (clinicalTrialEstimateBasketIds: number[]) =>
      client.delete<IAPIResponse<null>>('/clinical-trial/estimates/basket', {
        data: clinicalTrialEstimateBasketIds,
      }),
    {
      onSuccess: () => {
        message.success('임상 견적 리스트에 시험 항목이 삭제되었습니다.');
        queryClient.refetchQueries(useSavedClinicalTrialEstimateItemsKey);
      },
    },
  );

  return useMemo(
    () => ({
      unSaveClinicalTrial,
      isLoading,
    }),
    [unSaveClinicalTrial, isLoading],
  );
};

export const useUpdateSavedClinicalTrial = () => {
  const queryClient = useQueryClient();
  const { mutate: updateSavedClinicalTrial, isLoading } = useMutation(
    (updateClinicalTrialParams: IUpdateClinicalTrialParams) =>
      client.patch<IAPIResponse<null>>(
        '/clinical-trial/estimates/basket',
        updateClinicalTrialParams,
      ),
    {
      onSuccess: () => {
        queryClient.refetchQueries(useSavedClinicalTrialEstimateItemsKey);
      },
    },
  );

  return useMemo(
    () => ({
      updateSavedClinicalTrial,
      isLoading,
    }),
    [updateSavedClinicalTrial, isLoading],
  );
};

export const useEstimateRequest = (isLoggedIn: boolean) => {
  const [clinicalTrialEstimateItems, setClinicalTrialEstimateItems] = useState<
    IClinicalTrialEstimateItem[]
  >([]);
  const { data: savedClinicalTrialEstimateItems = [], isFetching } = useQuery(
    [useSavedClinicalTrialEstimateItemsKey],
    () =>
      client.get<IAPIResponse<IClinicalTrialEstimateBasket[]>>(
        '/clinical-trial/estimates/basket',
      ),
    {
      select: (res) => res.data.result,
      enabled: isLoggedIn,
    },
  );
  const { saveClinicalTrial } = useSaveClinicalTrial();

  useEffect(() => {
    const localSavedClinicalTrialEstimateItems = Storage.getItem(
      'savedClinicalTrialEstimateItems',
    );
    if (localSavedClinicalTrialEstimateItems) {
      if (!isLoggedIn) {
        setClinicalTrialEstimateItems(localSavedClinicalTrialEstimateItems);
      } else {
        // HINT : 임상시험견적 로컬스토리지 -> 서버
        saveClinicalTrial(
          (localSavedClinicalTrialEstimateItems as IClinicalTrialEstimateItem[]).map(
            ({ categoryDataId, itemQuantity, clinicalTrialId, isCheck }) => ({
              categoryDataId,
              clinicalTrialId,
              itemQuantity,
              isCheck,
            }),
          ),
          {
            onSuccess: () =>
              Storage.removeItem('savedClinicalTrialEstimateItems'),
          },
        );
      }
    }
  }, [isLoggedIn]);

  useEffect(() => {
    Storage.setItem(
      'savedClinicalTrialEstimateItems',
      clinicalTrialEstimateItems,
    );
  }, [clinicalTrialEstimateItems]);

  const items = useMemo(() => {
    if (isLoggedIn) {
      return savedClinicalTrialEstimateItems;
    }
    return clinicalTrialEstimateItems;
  }, [isLoggedIn, savedClinicalTrialEstimateItems, clinicalTrialEstimateItems]);

  const savedClinicalTrialMap = useMemo(() => {
    return items.reduce((map, curr) => {
      const value = map.get(curr.categoryDataName);
      return map.set(curr.categoryDataName, value ? [...value, curr] : [curr]);
    }, new Map<string, IClinicalTrialEstimateItem[]>());
  }, [items]);

  const { data: clinicalTrialRecommendations = [] } = useQuery(
    ['clinical-trials/recommendations'],
    () =>
      client.get<IAPIResponse<IClinicalTrialRecommendation[]>>(
        '/pub/clinical-trials/recommendations',
      ),
    {
      select: (res) => res.data.result,
      staleTime: Number.MAX_VALUE,
    },
  );

  const categoryRecommendMap = useMemo(() => {
    return clinicalTrialRecommendations.reduce(
      (prev, { categoryDataId, clinicalTrialIds }) =>
        prev.set(categoryDataId, clinicalTrialIds),
      new Map<number, number[]>(),
    );
  }, [clinicalTrialRecommendations]);

  const { updateSavedClinicalTrial } = useUpdateSavedClinicalTrial();
  const onChangeCheck = useCallback(
    ({
      isCheck,
      categoryDataId,
      clinicalTrialId,
      clinicalTrialEstimateBasketId,
    }: {
      isCheck: boolean;
      categoryDataId: number;
      clinicalTrialId: number;
      clinicalTrialEstimateBasketId: number | string;
    }) => {
      if (isLoggedIn) {
        updateSavedClinicalTrial({
          categoryDataId,
          clinicalTrialId,
          isCheck,
        });
      } else {
        const index = clinicalTrialEstimateItems.findIndex(
          (clinicalTrial) =>
            clinicalTrial.clinicalTrialEstimateBasketId ===
            clinicalTrialEstimateBasketId,
        );
        const updatedClinicalTrials = produce(
          clinicalTrialEstimateItems,
          (draft) => {
            draft[index]['isCheck'] = isCheck;
          },
        );
        setClinicalTrialEstimateItems(updatedClinicalTrials);
      }
    },
    [
      isLoggedIn,
      clinicalTrialEstimateItems,
      setClinicalTrialEstimateItems,
      updateSavedClinicalTrial,
    ],
  );

  const updateItemQuantity = useCallback(
    (
      plus: boolean,
      count: number,
      categoryDataId: number,
      clinicalTrialId: number,
      clinicalTrialEstimateBasketId: string | number,
    ) => {
      if (isLoggedIn) {
        updateSavedClinicalTrial({
          categoryDataId,
          clinicalTrialId,
          itemQuantity: count + (plus ? 1 : -1),
        });
      } else {
        const index = clinicalTrialEstimateItems.findIndex(
          (clinicalTrial) =>
            clinicalTrial.clinicalTrialEstimateBasketId ===
            clinicalTrialEstimateBasketId,
        );
        const updatedClinicalTrials = produce(
          clinicalTrialEstimateItems,
          (draft) => {
            draft[index].itemQuantity =
              draft[index].itemQuantity + (plus ? 1 : -1);
          },
        );
        setClinicalTrialEstimateItems(updatedClinicalTrials);
      }
    },
    [
      clinicalTrialEstimateItems,
      setClinicalTrialEstimateItems,
      isLoggedIn,
      updateSavedClinicalTrial,
    ],
  );

  return useMemo(
    () => ({
      items,
      savedClinicalTrialEstimateItems,
      savedClinicalTrialMap,
      clinicalTrialRecommendations,
      categoryRecommendMap,
      clinicalTrialEstimateItems,
      setClinicalTrialEstimateItems,
      isFetching,
      onChangeCheck,
      updateItemQuantity,
    }),
    [
      items,
      savedClinicalTrialEstimateItems,
      savedClinicalTrialMap,
      clinicalTrialRecommendations,
      categoryRecommendMap,
      clinicalTrialEstimateItems,
      setClinicalTrialEstimateItems,
      isFetching,
      onChangeCheck,
      updateItemQuantity,
    ],
  );
};

export const useRequestEstimate = () => {
  const {
    mutate: requestEstimate,
    isLoading,
  } = useMutation((params: IRequestEstimateParams) =>
    client.post<IAPIResponse<null>>(
      '/pub/clinical-trial/estimates/request-estimate',
      params,
    ),
  );

  return useMemo(
    () => ({
      requestEstimate,
      isLoading,
    }),
    [requestEstimate, isLoading],
  );
};

export const useMyClinicalTrialEstimates = ({
  page,
  size = 10,
}: {
  page: number;
  size?: number;
}) => {
  const { data = null } = useQuery(
    ['clinical-trial/process-items/estimates', page, size],
    () =>
      client.get<IAPIPageableResponse<IMyClinicalTrialEstimate[]>>(
        '/v1/clinical-trial/process-items/estimates',
        {
          params: {
            page,
            size,
          },
        },
      ),
    {
      select: (res) => res.data.result,
    },
  );

  const { content: myClinicalTrialEstimates = [], totalElements } = data || {};

  return useMemo(() => ({ myClinicalTrialEstimates, totalElements }), [
    myClinicalTrialEstimates,
    totalElements,
  ]);
};
