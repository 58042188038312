import { IBrandCompany } from 'types/brand/company';
import { ICountryProhibitInfo } from 'types/formula';

export enum EProductSampleSearchType {
  ALL = 'ALL',
  PRODUCT_SAMPLE_NAME = 'PRODUCT_SAMPLE_NAME',
  LAB_NO = 'LAB_NO',
  REGISTER_USER_NAME = 'REGISTER_USER_NAME',
  IS_SELF_DEVELOP = 'IS_SELF_DEVELOP',
  VENDOR_NAME = 'VENDOR_NAME',
  DEPT_NAME = 'DEPT_NAME',
}

export enum EProductSampleSearchStatus {
  REGISTERING = 'REGISTERING',
  CONFIRMED = 'CONFIRMED',
  BE_USED = 'BE_USED',
}

export interface IProductSampleSearchParams {
  status: EProductSampleSearchStatus;
  isMyProductSample: boolean;
  page: number;
  size: 10 | 30 | 50;
  searchType: EProductSampleSearchType;
  searchKeyword: string | null;
}
export interface IProductSample {
  productSampleId: number;
  productSampleName: string;
  labNo: string;
  registerUserId: number;
  registerUserName: string;
  registerDeptName: string;
  isSelfDevelop: boolean;
  vendorName: string;
  registerDt: string;
  isInternalDisclosure: boolean;
  isConfirmable: boolean;
  formulaExcelUrl: string | null;
  formulaBreakdownExcelUrl: string | null;
  formulaInciNameList: string | null;
  recipeDetailItems: IProductSampleRecipeDetailItem[] | null;
  recipeDetailUploadFileUrl: string | null;
  isAccessibleToRecipeDetail: boolean;
}
export interface IUsableProductSample
  extends Pick<
    IProductSample,
    'productSampleId' | 'productSampleName' | 'labNo'
  > {}

export enum EProductSampleDocumentCode {
  BASIC = 'BASIC01',
  RECIPE = 'RECIPE01',
  RDI = 'RDI01',
  PC = 'PC01',
}

export enum EProductSampleDocumentStatus {
  INP = 'INP',
  FIN = 'FIN',
  OPT = 'OPT',
}
export interface IProductSampleDocument {
  productSampleDocId: number;
  documentSeq: {
    documentName: string;
    documentCode: EProductSampleDocumentCode;
  };
  isOptional: boolean;
  status: EProductSampleDocumentStatus;
  registerDt: string;
}

export enum EProductSampleProductType {
  LEAVE_ON = 'LEAVE_ON',
  RINSE_OFF = 'RINSE_OFF',
}

export enum ECountryTarget {
  EUROPE = 'EUROPE',
  CHINA = 'CHINA',
}
export interface IProductSampleBasicForm {
  productSampleName: string;
  labNo: string;
  vendorId: number;
  isInternalDisclosure: boolean;
  productType: EProductSampleProductType;
  countryTargets: ECountryTarget[];
}
export interface IProductSampleBasicAdd
  extends Omit<IProductSampleBasicForm, 'vendorId'> {
  isSelfDevelop: boolean;
  vendorId: number | null;
}
export enum EProductSampleStatus {
  RDY = 'RDY',
  REG = 'REG',
  CFM = 'CFM',
  DEL = 'DEL',
}
export interface IProductSampleBasicGet extends IProductSampleBasicAdd {
  productSampleId: number;
  brandCompany: IBrandCompany;
  status: EProductSampleStatus;
}
export interface IProductSampleBasicUpdate
  extends Partial<IProductSampleBasicAdd> {
  productSampleId: number;
}
export enum EProductSampleRecipeErrorCode {
  PHASE = 'PHASE',
  MATERIAL_NAME = 'MATERIAL_NAME',
  MATERIAL_COMPANY_NAME = 'MATERIAL_COMPANY_NAME',
  MATERIAL_PERCENT = 'MATERIAL_PERCENT',
  REMARK = 'REMARK',
}
interface IProductSampleRecipeItemBase {
  phase: string;
  materialName: string;
  materialCompanyName: string;
  remark: string;
}
export interface IProductSampleRecipeItem extends IProductSampleRecipeItemBase {
  materialPercent: number;
  errorCodes?: EProductSampleRecipeErrorCode[];
}

export interface IProductSampleRecipeAdd {
  filename: string;
  memo: string;
  recipeItems: IProductSampleRecipeItem[];
}
export interface IProductSampleRecipe
  extends Omit<IProductSampleRecipeAdd, 'productSampleId'> {
  productSampleRecipeId: number;
  registerDt: string;
}
export interface IProductSampleRecipeUpdate {
  productSampleRecipeId: number;
  memo: string;
}
export enum EProductSampleRecipeDraftInvalidCode {
  TOTAL_MATERIAL_PERCENT_INVALID = 'TOTAL_MATERIAL_PERCENT_INVALID',
  EXCEL_INVALID = 'EXCEL_INVALID',
}
export interface IProductSampleRecipeDraft
  extends Pick<IProductSampleRecipe, 'filename' | 'recipeItems'> {
  invalidCodes: {
    code: EProductSampleRecipeDraftInvalidCode;
    message: string;
  }[];
}
export enum EProductSampleRecipeDetailErrorCode {
  PHASE = 'PHASE',
  MATERIAL_NAME = 'MATERIAL_NAME',
  MATERIAL_COMPANY_NAME = 'MATERIAL_COMPANY_NAME',
  INCI_NAME = 'INCI_NAME',
  FLA_INGREDIENT_PERCENT = 'FLA_INGREDIENT_PERCENT',
  RM_INGREDIENT_PERCENT = 'RM_INGREDIENT_PERCENT',
  ACTUAL_WT = 'ACTUAL_WT',
  INGREDIENT_FUNCTION = 'INGREDIENT_FUNCTION',
  CAS_NO = 'CAS_NO',
  REMARK = 'REMARK',
}
export interface ILimitedFormulaCountryInfo {
  type: 'prohibit' | 'limit';
  countryNameKo: string;
  inciName: string;
  notifiedIngredientName: string;
  limitedMatter: string;
}
export interface IProductSampleRecipeDetailItem {
  phase: string | null;
  phaseRowSpan: number;
  materialName: string | null;
  materialNameRowSpan: number;
  materialCompanyName: string | null;
  materialCompanyNameRowSpan: number;
  inciName: string;
  flaIngredientPercent: number | null;
  flaIngredientPercentRowSpan: number;
  rmIngredientPercent: number;
  actualWt: number;
  ingredientFunction: string;
  casNo: string;
  remark: string;
  errorCodes?: EProductSampleRecipeDetailErrorCode[];
  limitedFormulaCountryInfos: ICountryProhibitInfo[];
}

export interface IProductSampleRecipeDetail {
  formulaExcelUrl: string;
  recipeDetailItems: IProductSampleRecipeDetailItem[];
}

export interface IProductSampleRecipeDetailAdd {
  productSampleId: number;
  file: File;
}

export enum EProductSampleRecipeDetailDraftInvalidCode {
  INVALID_TOTAL_ACTUAL_PERCENT = 'INVALID_TOTAL_ACTUAL_PERCENT',
  INVALID_TOTAL_RM_INGREDIENT_IN_FLA_PERCENT = 'INVALID_TOTAL_RM_INGREDIENT_IN_FLA_PERCENT',
  INVALID_CELL = 'INVALID_CELL',
  INVALID_EXCEL = 'INVALID_EXCEL',
}
export interface IProductSampleRecipeDetailDraft
  extends Pick<IProductSampleRecipeDetail, 'recipeDetailItems'> {
  errorFileUrl: string | null;
  formulaExcelUrl: string | null;
  invalidCodes: {
    code: EProductSampleRecipeDetailDraftInvalidCode;
    message: string;
  }[];
}

export enum EProductSampleProcessMapProcessType {
  DISSOLVING = 'DISSOLVING', // 별도 용해
  MIXING = 'MIXING', // 혼합 (단일)
  MULTIPLE_MIXING = 'MULTIPLE_MIXING', // 혼합 (다중)
  EMULSIONING = 'EMULSIONING', // 유화
  MULTIPLE_EMULSIONING = 'MULTIPLE_EMULSIONING', // 다중 유화
  SOLUBILIZING = 'SOLUBILIZING', // 가용화
  COOLING = 'COOLING', // 냉각
  NEUTRALIZING = 'NEUTRALIZING', // 중화
  ROLLING = 'ROLLING', // 롤링
  DEGASSING = 'DEGASSING', // 탈포
  FILTERING = 'FILTERING', // 필터링
  DISPERING = 'DISPERING', // 분산
  DIRECT_INPUT = 'DIRECT_INPUT', // 직접 입력
}
export interface IProductSampleProcessMapItemForm {
  phase: string;
  processType?: EProductSampleProcessMapProcessType;
  temperature?: string;
  isNotApplicableTemperature?: boolean;
  rpm?: string;
  isNotApplicableRpm?: boolean;
  processTime?: string;
  isUntilProcessCompletion?: boolean;
  directInputText?: string;
  mixedDatas?: (string | undefined)[];
}
export interface IProductSampleProcessMapItemGet
  extends IProductSampleProcessMapItemForm {
  productSampleProcessMapItemId: number;
}
export interface IProductSampleProcessMapItemUpdate {
  productSampleProcessMapItemId: number;
  processType?: EProductSampleProcessMapProcessType | null;
  temperature?: string | null;
  isNotApplicableTemperature?: boolean | null;
  rpm?: string | null;
  isNotApplicableRpm?: boolean | null;
  processTime?: string | null;
  isUntilProcessCompletion?: boolean | null;
  directInputText?: string | null;
  mixedDatas?: (string | undefined)[] | null;
}
