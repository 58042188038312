import { Button, Col, Form, Input, Modal, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { exceptKoreanRule, requireRule } from 'lib/validate';
import { toggleJapaneseProductNameModalVisible } from 'modules/certificate';
import UpdateLog from 'components/product/UpdateLog';
import CorrectRequestMessageModal from 'components/CorrectRequestMessageModal';
import FooterBox from 'components/FooterBox';
import { Typography } from 'components/system';
import { useCountryProductName } from 'service/brand/certificate/certificateDetail';
import { updateJapaneseProductName } from 'modules/product';
import { EDocumentCode } from 'types/product';

const JapaneseProductNameModal = () => {
  const dispatch = useDispatch();
  const visible = useSelector(
    ({ certificate }: any) => certificate.japaneseProductNameModalVisible,
  );
  const onClose = () => {
    dispatch(toggleJapaneseProductNameModalVisible());
  };
  const {
    form,
    status,
    productId,
    countryId,
    readOnlyMode,
    updateLoading,
    onSubmit,
  } = useCountryProductName(updateJapaneseProductName);
  return (
    <Modal visible={visible} footer={null} destroyOnClose onCancel={onClose}>
      <Typography.Title>일본 제품명 관리</Typography.Title>
      <Typography.Text type="secondary" style={{ paddingLeft: 4 }}>
        일본 브랜드명과 제품명이 없으신 경우, 30COS에서 추천해 드리고 있습니다.
        <br /> 우측 하단 채널톡으로 문의해 주세요.
      </Typography.Text>
      <Row justify="end" gutter={8}>
        <Col>
          <CorrectRequestMessageModal documentCode={EDocumentCode.PN} />
        </Col>
        <Col>
          <UpdateLog
            productId={productId}
            countryId={countryId}
            documentCode={EDocumentCode.BASIC}
            validCodes={['BASIC01-BNJP', 'BASIC01-PNE', 'BASIC01-PNJP']}
          />
        </Col>
      </Row>

      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Form.Item
          label="일본 브랜드명"
          name="countryBrandName"
          rules={[
            requireRule,
            exceptKoreanRule,
            {
              pattern: /^.{1,20}$/i,
              message: '최대 20자',
            },
          ]}
        >
          <Input disabled={readOnlyMode} />
        </Form.Item>
        <Form.Item
          label="제품명 (영문)"
          name="productNameEn"
          rules={[requireRule, exceptKoreanRule]}
        >
          {/* HINT : 입력 정보가 입력 필요(INP)인 경우는 제품명 (영문) disabled. */}
          <Input disabled={readOnlyMode || status === 'INP'} />
        </Form.Item>
        <Form.Item
          label="제품명 (일본어)"
          name="countryProductName"
          rules={[
            requireRule,
            exceptKoreanRule,
            {
              pattern: /^.{1,20}$/i,
              message: '최대 20자',
            },
          ]}
        >
          <Input disabled={readOnlyMode} />
        </Form.Item>
        {!readOnlyMode && (
          <FooterBox>
            <Button type="primary" loading={updateLoading} htmlType="submit">
              수정
            </Button>
          </FooterBox>
        )}
      </Form>
    </Modal>
  );
};

export default JapaneseProductNameModal;
