import { Col, Divider, Row } from 'antd';

import { deviceSize, size } from 'lib/styles';
import styled from 'styled-components';
import Typography from 'components/system/general/Typography';
import Logo from 'components/system/general/icon/Logo';
import { Link } from 'react-router-dom';
import palette from 'lib/styles/palette';

const VerticalDivider = ({
  color = 'black',
}: {
  color?: keyof typeof palette.text;
}) => {
  return (
    <Divider
      type="vertical"
      style={{
        borderLeft: `1px solid ${palette.text[color]}`,
        height: 16,
        margin: 0,
      }}
    />
  );
};

const FooterBlock = styled.div`
  max-width: ${size.layout.footerMax};
  margin: 0 auto;
`;

const CollapsibleCol = styled(Col)`
  @media ${deviceSize.sm} {
    display: none;
  }
`;

const MarginCol = styled(Col)`
  @media ${deviceSize.sm} {
    margin-top: 4px;
  }
`;

const Footer = () => {
  return (
    <FooterBlock>
      <Row gutter={[122, 32]} align="middle">
        <Col xs={{ span: 24 }} sm={{ span: 6 }}>
          <Logo isGrayscale />
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 18 }}>
          <Row gutter={16}>
            <Col>
              <a href="https://www.cdri.pro" target="_blank" rel="noreferrer">
                <Typography.Text type="secondary" color="black">
                  회사소개
                </Typography.Text>
              </a>
            </Col>
            <Col>
              <VerticalDivider />
            </Col>
            <Col>
              <Link to="/service">
                <Typography.Text type="secondary" color="black">
                  고객센터
                </Typography.Text>
              </Link>
            </Col>
          </Row>
          <Row gutter={6} style={{ marginTop: 16 }} align="middle">
            <Col>
              <Typography.Text type="secondary" color="slate">
                주식회사 씨디알아이
              </Typography.Text>
            </Col>
            <Col>
              <VerticalDivider color="slate" />
            </Col>
            <Col>
              <Typography.Text type="secondary" color="slate">
                대표: 김광일
              </Typography.Text>
            </Col>
            <Col>
              <VerticalDivider color="slate" />
            </Col>
            <Col>
              <Typography.Text type="secondary" color="slate">
                사업자 등록번호: 673-86-01908
              </Typography.Text>
            </Col>
            <CollapsibleCol>
              <VerticalDivider color="slate" />
            </CollapsibleCol>
            <MarginCol xs={{ flex: '0 0 100%' }} sm={{ flex: '0 0 auto' }}>
              <Row gutter={6}>
                <Col>
                  <Typography.Text type="secondary" color="slate">
                    통신판매업신고번호: 제2020-충북청주-2866호
                  </Typography.Text>
                </Col>
                <Col>
                  <VerticalDivider color="slate" />
                </Col>
                <Col>
                  <Typography.Text type="secondary" color="slate">
                    대표 번호: 02-6225-5253
                  </Typography.Text>
                </Col>
              </Row>
            </MarginCol>
          </Row>
          <Row gutter={6} style={{ marginTop: 4 }} align="middle">
            <Col>
              <Typography.Text type="secondary" color="slate">
                본사: 충북 청주시 흥덕구 오송읍 오송생명 1로 194-25, SB플라자
                3층 A-13호 ( 28160 )
              </Typography.Text>
            </Col>
            <CollapsibleCol>
              <VerticalDivider color="slate" />
            </CollapsibleCol>
            <MarginCol xs={{ flex: '0 0 100%' }} sm={{ flex: '0 0 auto' }}>
              <Typography.Text type="secondary" color="slate">
                지사: 서울 강남구 논현로 417 화원빌딩 3층
              </Typography.Text>
            </MarginCol>
          </Row>
          <Typography.Text type="secondary" color="slate" gutter={{ top: 4 }}>
            Copyright © CDRI CO., LTD. All Rights Reserved.
          </Typography.Text>
        </Col>
      </Row>
    </FooterBlock>
  );
};

export default Footer;
