import qs from 'qs';

import client from 'lib/api/client';
import { IAPIPageableResponse } from 'types/common';
import {
  IProduct,
  IProductBasicAdd,
  IProductBasicUpdate,
  IProductSearchParams,
  IProductInputRequest,
  IProductInputRequestUser,
  IManufacturerProduct,
} from 'types/manufacturer/product';
import { IAPIResponse } from 'types/common';

export const getProduct = (productId: number) =>
  client.get<IAPIResponse<IManufacturerProduct>>(
    `/manufacturer/products/${productId}`,
  );

export const getProductForCheckDuplicate = (params: {
  labNo: string;
  netWeight: number;
  netWeightUnit: string;
}) =>
  client.get<IAPIResponse<IProduct>>(
    `/manufacturer/product?${qs.stringify(params)}`,
  );

export const addProductBasic = (productBasic: IProductBasicAdd) =>
  client.post('/manufacturer/product', productBasic);

export const updateProductBasic = (productBasic: IProductBasicUpdate) => {
  const { productId, ...rest } = productBasic;
  return client.patch(`/manufacturer/product/${productId}`, rest);
};

export const getProducts = ({
  page,
  searchType,
  searchKeyword,
  status,
}: IProductSearchParams) =>
  client.post<IAPIPageableResponse<any>>(
    `/manufacturer/product/all/${page}/10`,
    {
      [searchType]: searchKeyword,
      status,
    },
  );

export const issueAuthCode = (productId: number) =>
  client.patch(`/manufacturer/product/issue/authcode/${productId}`);

export const getProductInputRequestableUsers = ({
  productId,
  documentCode,
}: {
  productId: number;
  documentCode: string;
}) =>
  client.get<IAPIResponse<IProductInputRequestUser[]>>(
    `/manufacturer/products/${productId}/input-request/users?${qs.stringify({
      documentCode,
    })}`,
  );

export const postProductInputRequest = ({
  productId,
  documentCode,
  toUserId,
}: {
  productId: number;
  documentCode: string;
  toUserId: number;
}) =>
  client.post<IAPIResponse<null>>(
    `/manufacturer/products/${productId}/input-requests`,
    {
      documentCode,
      toUserId,
    },
  );
export const updateProductInputRequest = ({
  inputRequestId,
  status,
}: {
  inputRequestId: number;
  status: 'CANCEL' | 'REFUSE';
}) =>
  client.patch<IAPIResponse<null>>(
    `/manufacturer/input-requests/${inputRequestId}`,
    { status },
  );
export const getProductInputRequest = (inputRequestId: number) =>
  client.get<IAPIResponse<IProductInputRequest>>(
    `/manufacturer/input-requests/${inputRequestId}`,
  );
