import { Button, Col, Row, Table } from 'antd';
import { useMemo, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import styled from 'styled-components';

import { useVCRPAccount } from 'service/brand/product/vcrp';
import { ReactComponent as HideButtonIcon } from 'asset/svg/hide_button.svg';
import { ReactComponent as ShowButtonIcon } from 'asset/svg/show_button.svg';
import VCRPAccountAddModal from 'components/modal/product/VCRPAccountAddModal';
import { IVCRPAccount } from 'types/brand/vcrp';

const VCRPAccountContainer = styled.div`
  .ant-table-content {
    th:first-child,
    td:first-child {
      padding-left: 36px;
    }
  }
`;

const VCRPPasswordContainer = styled.div`
  display: flex;
  align-items: center;
`;

const VCRPAccount = () => {
  const { vcrpAccount, getVCRPAccountLoading } = useVCRPAccount();

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const columns: ColumnsType<IVCRPAccount> = useMemo(
    () => [
      {
        title: '사용자명 (ID)',
        width: '360px',
        align: 'left',
        render: ({ vcrpAccount }) => vcrpAccount,
      },
      {
        title: '비밀번호',
        align: 'left',
        render: ({ vcrpPassword }) => (
          <VCRPPasswordContainer>
            {isPasswordVisible ? (
              <>
                {vcrpPassword}
                <ShowButtonIcon
                  style={{ marginLeft: 72, cursor: 'pointer' }}
                  onClick={() => setIsPasswordVisible(false)}
                />
              </>
            ) : (
              <>
                {'*'.repeat(vcrpPassword.length)}
                <HideButtonIcon
                  style={{ marginLeft: 72, cursor: 'pointer' }}
                  onClick={() => setIsPasswordVisible(true)}
                />
              </>
            )}
          </VCRPPasswordContainer>
        ),
      },
    ],
    [vcrpAccount, isPasswordVisible],
  );

  return (
    <VCRPAccountContainer>
      <VCRPAccountAddModal
        vcrpAccount={vcrpAccount}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        updateMode={vcrpAccount !== null}
      />
      <Table
        rowKey="vcrpAccountId"
        columns={columns}
        dataSource={vcrpAccount ? [vcrpAccount] : []}
        className="secondary"
        loading={getVCRPAccountLoading}
        pagination={false}
      />
      {vcrpAccount !== null && (
        <Row justify="end" style={{ marginTop: 8 }}>
          <Col>
            <Button onClick={() => setIsModalVisible(true)}>
              계정 정보 변경
            </Button>
          </Col>
        </Row>
      )}
    </VCRPAccountContainer>
  );
};

export default VCRPAccount;
