import DocumentsUpload from 'components/brand/retailer/retailerApply/DocumentsUpload';
import ProductPageTemplate from 'components/brand/retailer/retailerApply/product/ProductPageTemplate';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import { useParams } from 'react-router-dom';
import { EDocumentCode } from 'types/brand/retailer/retailerApply';

const ProductFRPage = () => {
  const params = useParams<{ productId: string }>();
  const productId = Number(params.productId);
  return (
    <ProductPageTemplate
      productId={productId}
      subtitle="기능성 보고서"
      notices={[
        '해당 서류는 기능성 화장품에 한해서만 필요한 서류입니다.',
        '1~3호 기능성 보고서에는 반드시 직인이 날인되어 있어야 하며, 별첨 부분까지 포함한 파일로 업로드해 주세요.',
        <MistakeNoticeItem />,
      ]}
    >
      <DocumentsUpload
        productId={productId}
        documentCode={EDocumentCode.FR}
        accept="application/pdf"
      />
    </ProductPageTemplate>
  );
};

export default ProductFRPage;
