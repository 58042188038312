import { Link } from 'react-router-dom';

import DocumentsUpload from 'components/brand/retailer/retailerApply/DocumentsUpload';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import { Typography } from 'components/system';
import path from 'lib/path';
import PageTemplate from 'templates/PageTemplate';
import { EDocumentCode } from 'types/brand/retailer/retailerApply';

const OBDPage = () => {
  return (
    <PageTemplate
      title="국내 입점 서류 관리"
      subtitle="통신판매업 신고증 (온라인 전용)"
      notices={[
        <Typography.Text type="secondary">
          온라인 입점 전 미리 해당 서류를 준비해주세요. 통신판매업 신고증이 없는
          경우,{' '}
          <Link
            to={`${path.service}/guide`}
            target="_blank"
            style={{ fontWeight: 700, textDecoration: 'underline' }}
          >
            여기
          </Link>
          를 클릭하여 신청 방법을 확인할 수 있습니다.
        </Typography.Text>,
        '실제 입점시 신고증 파일이 아닌 신고번호로만 입점 신청이 가능한 경우도 있지만, 30COS에서는 서류의 관리를 위해 해당 페이지를 제공드림을 알려드립니다.',
        '해당 서류는 모든 온라인 유통사에 기본적으로 포함되는 서류로, 한 번만 업로드하시면 온라인 유통사별 서류 다운로드 시 함께 받아보실 수 있습니다. ',
        <MistakeNoticeItem />,
      ]}
      exampleImg="OBD_V1.png"
    >
      <DocumentsUpload documentCode={EDocumentCode.OBD} />
    </PageTemplate>
  );
};

export default OBDPage;
