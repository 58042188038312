import BL from 'components/brand/retailer/retailerApply/BL';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import PageTemplate from 'templates/PageTemplate';

const BLPage = () => {
  return (
    <PageTemplate
      title="국내 입점 서류 관리"
      subtitle="사업자등록증"
      notices={[
        '회원가입시 등록하신 사업자등록증입니다.',
        '사업자등록증의 내용이 기존과 달라졌을 경우, 반드시 수정이 필요합니다.',
        '수정을 원하시면, 우측 하단 채널톡으로 문의바랍니다. ',
        <MistakeNoticeItem />,
      ]}
    >
      <BL />
    </PageTemplate>
  );
};

export default BLPage;
