import { useState } from 'react';
import { Table, Row, Col, Select } from 'antd';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import palette from 'lib/styles/palette';
import Typography from 'components/system/general/Typography';
import { SearchInput } from 'components/system';
import { EProductSearchType, EProductStatus } from 'types/product';
import { useProducts } from 'service/product';

const searchTypeOptions = [
  { label: '전체', value: EProductSearchType.ALL },
  { label: '제품명', value: EProductSearchType.PRODUCT_NAME },
  { label: '국가', value: EProductSearchType.COUNTRY_NAME },
  { label: '제조사', value: EProductSearchType.MANUFACTURER_NAME },
];

const ProductReadyListBlock = styled.div`
  .ant-table-wrapper .ant-table-thead > tr > th {
    padding: 10px 16px;
  }
  .ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 10px 16px;
  }
`;

const ProductReadyList = () => {
  const {
    state: historyState = {
      page: 1,
      searchType: EProductSearchType.ALL,
      searchKeyword: '',
    },
  } = useLocation<{
    page?: number;
    searchType?: EProductSearchType;
    searchKeyword?: string;
  }>();
  const [page, setPage] = useState(historyState.page || 1);
  const [searchType, setSearchType] = useState(
    historyState.searchType || EProductSearchType.ALL,
  );
  const [searchKeyword, setSearchKeyword] = useState(
    historyState.searchKeyword || '',
  );
  const {
    products,
    totalElements,
    getLoading,
    deleteProduct,
    showProduct,
  } = useProducts({
    status: EProductStatus.PRD_RDY,
    page,
    searchType,
    searchKeyword,
  });

  return (
    <ProductReadyListBlock>
      <Row gutter={8} justify="end">
        <Col>
          <Select
            defaultValue={searchType}
            options={searchTypeOptions}
            style={{ width: 164 }}
            onChange={(searchTypeBuffer) => setSearchType(searchTypeBuffer)}
          />
        </Col>
        <Col>
          <SearchInput
            defaultValue={searchKeyword}
            onSearch={(searchKeyword: string) =>
              setSearchKeyword(searchKeyword)
            }
            style={{ width: 250 }}
          />
        </Col>
      </Row>
      <Table
        columns={[
          {
            title: '제품명 (용량)',
            render: (product) => (
              <Typography.Text
                type="secondary"
                color="black"
                style={{ maxWidth: 296, fontWeight: 500, cursor: 'pointer' }}
                onClick={() => showProduct(product)}
              >
                {`${
                  product.productDetail.productNameEn ||
                  `(임시) ${product.manufacturerItemNameKo}`
                } ${
                  product.netWeight
                    ? `(${product.netWeight} ${product.netWeightUnit})`
                    : '( - )'
                }`}
              </Typography.Text>
            ),
          },
          {
            title: '국가',
            dataIndex: 'productCountries',
            render: (productCountries) =>
              productCountries ? (
                <Typography.Text
                  type="secondary"
                  color="gray"
                  style={{ maxWidth: 168 }}
                >
                  {`${productCountries[0].country.countryNameKo}${
                    productCountries.length > 1
                      ? ` 외 ${productCountries.length - 1}개 국가`
                      : ''
                  }
                  `}
                </Typography.Text>
              ) : (
                '-'
              ),
          },
          {
            title: '제조사',
            render: ({ productDetail }) => (
              <Typography.Text
                type="secondary"
                color="gray"
                style={{ maxWidth: 160 }}
              >
                {productDetail.manufacturerNameKo}
              </Typography.Text>
            ),
          },
          {
            title: '최근 수정 일시',
            render: ({ registerDt, updateDt }) => (
              <Typography.Text
                type="secondary"
                color="gray"
                style={{ maxWidth: 128 }}
              >
                {updateDt
                  ? updateDt.replace('T', ' ')
                  : registerDt.replace('T', ' ')}
              </Typography.Text>
            ),
          },
          {
            render: (product) => (
              <i
                className="material-icons outlined cursor"
                style={{ color: palette.warmGray, width: 40 }}
                onClick={() => deleteProduct(product.productId)}
              >
                delete
              </i>
            ),
          },
        ]}
        dataSource={products}
        rowKey="productId"
        loading={getLoading}
        pagination={{
          current: page,
          onChange: setPage,
          total: totalElements,
        }}
        style={{ marginTop: 8 }}
      />
    </ProductReadyListBlock>
  );
};

export default ProductReadyList;
