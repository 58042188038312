import axios from 'axios';
import client from 'lib/api/client';
import { createFormData } from 'lib/file';
import qs from 'qs';
import { IAPIResponse } from 'types/common';
import {
  IMaterialCompanyFactory,
  ICompanyRegister,
  ICompanyUpdate,
  IManufactureCompanyFactory,
  IManufactureCompanyFactoryUpdate,
  ICompanyBanner,
} from 'types/company';

export const getCompany = (companyId: number) =>
  client.get(`/company/${companyId}`);
export const registerCompany = (company: ICompanyRegister) =>
  client.post('/auth/company/register', company);
export const updateCompany = (company: ICompanyUpdate) =>
  client.patch('/company/patch', company);
export const getCompanyStats = (companyId: number) =>
  client.get(`/company-stats/${companyId}`);
export const registerFactory = (factory: IManufactureCompanyFactory) =>
  client.post('/pub/company/factory/register', factory);
export const getFactories = (companyId: number) =>
  client.get(`/company/factories/${companyId}`);
export const updateFactory = (factory: IManufactureCompanyFactoryUpdate) =>
  client.post(
    `/company/factory/patch/${factory.companyId}/${factory.companyFactoryId}`,
    createFormData(factory),
  );
export const deleteFactory = (companyFactoryId: number) =>
  client.delete(`/company/factory?companyFactoryId=${companyFactoryId}`);

// Material
export const getMaterialFactories = (companyId: number) =>
  client.get(`/v2/company/factories/${companyId}`);
export const registerMaterialFactory = (
  materialFactory: IMaterialCompanyFactory[],
) => client.post('/v2/pub/company/factory/register', materialFactory);
export const updateMaterialFactory = ({
  companyId,
  factories,
}: {
  companyId: number;
  factories: IMaterialCompanyFactory[];
}) => client.patch(`/v2/company/${companyId}/factories/patch`, factories);
export const deleteMaterialFactory = (materialFactoryIds: number[]) =>
  client.delete(
    `/v2/company/factory?${qs.stringify(
      { materialFactoryIds },
      { indices: false },
    )}`,
  );
export const getManufacturerBizLicenseEnUrl = (productId: number) =>
  client.get<IAPIResponse<string>>(`/company/biz-license/en/${productId}`);

// HINT : client를 쓸 경우 response 값에 errors가 없어서 error 발생 따라서 axios이용.
export const getCompanyBanners = () =>
  axios.get<ICompanyBanner[]>(
    'https://30cos.s3.ap-northeast-2.amazonaws.com/temp/banners.json',
  );
