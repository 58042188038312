import { useEffect, useRef } from 'react';

const useUpdateEffect = (effectFunction: React.EffectCallback, deps: any[]) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
      return;
    }
    const destructor = effectFunction();
    if (destructor) return destructor;
  }, deps);
};

export default useUpdateEffect;
