import { checkMobile } from 'lib/device';
import { createGlobalStyle, css } from 'styled-components';
import palette from './palette';

const isMobile = checkMobile();

const GlobalStyle = createGlobalStyle`

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    min-height: 100%;
    overscroll-behavior: none;
    touch-action: pan-x pan-y;
    color: ${palette.text.black};
  }

  #root {
    min-height: 100%;
  }

  a {
    text-decoration: none;
  }

  pre {
    margin-bottom: 0 !important;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  input::-webkit-calendar-picker-indicator {
    opacity: 100;
  }


  ${
    isMobile &&
    css`
      .ant-modal {
        top: 20px;
      }
    `
  }

  @media screen and (-webkit-min-device-pixel-ratio:0) { 
    select,
    textarea,
    input {
      font-size: 16px;
    }
  }

`;

export default GlobalStyle;
