import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Table, Modal, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import {
  EDocumentCode,
  EDocumentStatus,
  IDocument,
} from 'types/material/rawMaterial';
import {
  useRawMaterial,
  useRawMaterialDocuments,
} from 'service/material/rawMaterial';
import { Typography } from 'components/system';
import history from 'lib/history';
import path from 'lib/path';
import { materialDocumentCodeWithPathMap } from 'lib/consts';
import FooterBox from 'components/FooterBox';

const RawMaterialDocumentsBlock = styled.div``;

const documentStatusMap = {
  [EDocumentStatus.INP]: '입력 필요',
  [EDocumentStatus.IPS]: '입력 가능',
  [EDocumentStatus.UPD]: '업로드 필요',
  [EDocumentStatus.FIN]: '입력 완료',
};

const RawMaterialDocuments = ({
  onRegisterSuccess,
}: {
  onRegisterSuccess: () => void;
}) => {
  const params = useParams<{ materialId: string }>();
  const materialId = Number(params.materialId);
  const {
    rawMaterial,
    getLoading: getRawMaterialLoading,
    registerRawMaterial,
    registerLoading,
  } = useRawMaterial(materialId);
  const { documents, getLoading } = useRawMaterialDocuments(materialId);
  const registerable =
    documents.length > 0 &&
    documents.every(({ status }) => status !== EDocumentStatus.INP);

  const handleMoveToDocumentPage = (
    documentCode: EDocumentCode,
    status: EDocumentStatus,
  ) => {
    if (documentCode === EDocumentCode.MSDS) {
      const ciDocument = documents.find(
        ({ documentSeq: { documentCode } }) =>
          documentCode === EDocumentCode.MCI,
      );
      if (!ciDocument) throw new Error('Invalid ciDocument');
      if (ciDocument.status !== EDocumentStatus.FIN) {
        return message.warn(
          "'구성 성분의 명칭 및 함유량 정보'에 기입될 Composition 정보가 없습니다.\nComposition Information 입력 후에 Material Safety Data Sheet 입력이 가능합니다.",
        );
      }
    }
    history.push(
      `${path.material.rawMaterial.root}/${
        materialDocumentCodeWithPathMap[documentCode]
      }/${materialId}${
        documentCode !== EDocumentCode.MDI &&
        status !== EDocumentStatus.INP &&
        status !== EDocumentStatus.IPS
          ? '?updateMode=true'
          : ''
      }`,
    );
  };

  const columns: ColumnsType<IDocument> = [
    {
      title: '구분',
      width: 128,
      align: 'center',
      render: (_, __, index) => index + 1,
    },
    {
      title: '입력 정보',
      render: (_, { documentSeq: { documentCode, documentName }, status }) => (
        <Typography.Text
          type="secondary"
          medium
          color="black"
          style={{ cursor: 'pointer' }}
          onClick={() => handleMoveToDocumentPage(documentCode, status)}
        >
          {documentName}
        </Typography.Text>
      ),
    },
    {
      title: '처리 현황',
      width: 208,
      align: 'center',
      render: (_, { documentSeq: { documentCode }, status }) =>
        status !== EDocumentStatus.FIN ? (
          <Typography.Label
            type={status === EDocumentStatus.UPD ? 'error' : 'primary'}
            onClick={() => handleMoveToDocumentPage(documentCode, status)}
          >
            {documentStatusMap[status]}
            <i className="material-icons outlined md-12">chevron_right</i>
          </Typography.Label>
        ) : (
          <Typography.Text type="secondary">
            {documentStatusMap[status]}
          </Typography.Text>
        ),
    },
    {
      title: '입력 일시',
      width: 168,
      align: 'center',
      dataIndex: 'registerDt',
      render: (_, { registerDt, updateDt }) => (
        <Typography.Text type="secondary">
          {registerDt || updateDt}
        </Typography.Text>
      ),
    },
  ];

  const handleRegisterRawMaterial = () => {
    Modal.confirm({
      width: 480,
      title: '원료를 등록하시겠습니까?',
      icon: null,
      closable: true,
      content: (
        <Typography.Text type="secondary">
          등록하시면 해당 원료를 제조사에서 검색하여 조회할 수 있게 됩니다.
          <br />
          처리현황에 ‘업로드 필요’ 항목이 있다면 14일 이내로 업로드해 주세요.
          <br />
          (14일 경과 시 업로드 안된 인증은 목록에서 제외됩니다)
        </Typography.Text>
      ),
      okText: '원료 등록',
      onOk: () => {
        registerRawMaterial(materialId, {
          onSuccess: onRegisterSuccess,
        });
      },
    });
  };

  return (
    <RawMaterialDocumentsBlock>
      <Table
        columns={columns}
        dataSource={documents}
        loading={getLoading || getRawMaterialLoading}
        rowKey={({ documentSeq }) => documentSeq.documentSeqId}
        pagination={false}
      />
      {registerable && rawMaterial && rawMaterial.status === 'RM_RDY' && (
        <FooterBox>
          <Button
            type="primary"
            onClick={handleRegisterRawMaterial}
            loading={registerLoading}
          >
            원료 등록
          </Button>
        </FooterBox>
      )}
    </RawMaterialDocumentsBlock>
  );
};

export default RawMaterialDocuments;
