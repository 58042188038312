import { Col, Input, Row } from 'antd';
import styled from 'styled-components';
import { useState } from 'react';

import { Typography } from 'components/system';
import { deviceSize } from 'lib/styles';
import palette from 'lib/styles/palette';
import IngredientSelect from './IngredientSelect';
import IngredientSearchResultTable from './IngredientSearchResultTable';
import { IIngredientSearchResult } from 'types/material/ingredient';
import { IAPIPageableResult } from 'types/common';
import { useIngredient } from 'service/material/ingredient';

const IngredientSearchRow = styled(Row)`
  flex-direction: column;
  align-items: center;
`;

const StyledInput = styled(Input)`
  width: 383px;
  height: 28px !important;
  background-color: rgb(216, 216, 216, 0.2);
  font-size: 14px;

  ::placeholder {
    color: ${palette.darkNavy};
    text-align: center;
    font-size: 14px;
  }

  @media ${deviceSize['sm']} {
    width: 100%;
  }
`;

const IngredientSearch = () => {
  const [ingredientResult, setIngredientResult] = useState<
    IAPIPageableResult<IIngredientSearchResult[]>
  >();
  const { onAgainSearch } = useIngredient();
  const [firstKeyword, setFirstKeyword] = useState('');
  const [secondKeyword, setSecondKeyword] = useState('');

  return (
    <IngredientSearchRow>
      <Col style={{ marginBottom: 32 }}>
        <Typography.Headline type="secondary" style={{ textAlign: 'center' }}>
          원하는 성분을 찾아보고
          <br />
          비교함에 담아 성분들을 비교해 보세요.
        </Typography.Headline>
      </Col>
      <Col style={{ marginBottom: 8 }}>
        <IngredientSelect
          setTableData={setIngredientResult}
          setFirstKeyword={setFirstKeyword}
          setSecondKeyword={setSecondKeyword}
        />
      </Col>
      {firstKeyword && (
        <>
          <Col style={{ marginBottom: 12 }}>
            <StyledInput
              value={secondKeyword}
              placeholder="결과 내 재검색"
              onChange={(e) => {
                onAgainSearch({
                  pageNo: 1,
                  preKeyword: firstKeyword,
                  newKeyword: e.target.value,
                  afterSuccess: setIngredientResult,
                });
                setSecondKeyword(e.target.value);
              }}
            />
          </Col>
          <Col style={{ width: '100%' }}>
            <IngredientSearchResultTable
              data={ingredientResult || undefined}
              firstKeyword={firstKeyword}
              secondKeyword={secondKeyword}
            />
          </Col>
        </>
      )}
    </IngredientSearchRow>
  );
};

export default IngredientSearch;
