import { useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';

import * as registerManageApi from 'lib/api/material/registerManage';
import { IMaterialRegisterGet } from 'types/material/register';

export const useMaterialRegisterManage = (params: IMaterialRegisterGet) => {
  const {
    mutate: deleteMaterials,
    isLoading: deleteMaterialsLoading,
  } = useMutation((materialIds: number[]) =>
    registerManageApi.deleteMaterials(materialIds),
  );

  const {
    mutate: discontinueMaterials,
    isLoading: discontinueMaterialsLoading,
  } = useMutation((materialIds: number[]) =>
    registerManageApi.discontinueMaterials(materialIds),
  );

  const {
    data: materialRegisters = null,
    isFetching: getMaterialRegistersLoading,
    refetch: refetchRegisters,
  } = useQuery(
    [`materials`, deleteMaterialsLoading, discontinueMaterialsLoading],
    () => registerManageApi.getRegisters(params),
    {
      select: (res) => res.result,
    },
  );

  return useMemo(
    () => ({
      materialRegisters,
      getMaterialRegistersLoading,
      refetchRegisters,
      discontinueMaterials,
      discontinueMaterialsLoading,
      deleteMaterials,
      deleteMaterialsLoading,
    }),
    [
      getMaterialRegistersLoading,
      discontinueMaterialsLoading,
      deleteMaterialsLoading,
    ],
  );
};
