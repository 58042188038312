import qs from 'qs';

import client from 'lib/api/client';
import { IAPIResponse } from 'types/common';
import { IManufacturerCompany } from 'types/manufacturer/company';

export const getManufacturerCompanies = (manufacturerCompaniesParams?: {
  isExcludeUserInserted: boolean;
}) =>
  client.get<IAPIResponse<IManufacturerCompany[]>>(
    `/pub/manufacturers?${qs.stringify(manufacturerCompaniesParams)}`,
  );
