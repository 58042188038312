import { IAPIResponse } from 'types/common';
import {
  IClassification,
  IMsdsAdd,
  IMsdsDefaultInfo,
  IMsdsGet,
  IMsdsUpdate,
  IPictogram,
  IStatementMap,
} from 'types/material/msds';
import client from 'lib/api/client';

export const getClassifications = () =>
  client.get<IAPIResponse<IClassification[]>>('/material/msds/GHSs');

export const getStatementMap = (materialCategoryIds: number[]) =>
  client.get<IAPIResponse<IStatementMap>>(
    `/material/msds/GHSs/statements?materialCategoryIds=${materialCategoryIds.join(
      ',+',
    )}`,
  );

export const getMsdsDefaultInfo = (materialId: number) =>
  client.get<IAPIResponse<IMsdsDefaultInfo>>(
    `/materials/${materialId}/msds/default-info`,
  );

export const getMsds = (materialId: number) =>
  client.get<IAPIResponse<IMsdsGet>>(`/material/msds?materialId=${materialId}`);

export const addMsds = (msds: IMsdsAdd) =>
  client.post('/material/msds', {
    ...msds,
  });

export const updateMsds = ({ materialMsdsBasicInfoId, ...rest }: IMsdsUpdate) =>
  client.patch(`/material/msds/${materialMsdsBasicInfoId}`, rest);

export const getAllPictograms = () =>
  client.get<IAPIResponse<IPictogram[]>>('/material/msds/pictograms');
