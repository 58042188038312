import { Form, message } from 'antd';
import { useCallback, useEffect, useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';

import * as certificateActions from 'modules/certificate';
import * as certApi from 'lib/api/certificate';
import history from 'lib/history';
import { messages } from 'lib/consts';
import { useProductDocStatus } from 'service/product';
import { EDocumentCode } from 'types/product';

export const useProductSeparateWaste = (
  productId: number,
  countryId?: number,
) => {
  const {
    data: separateWasteCategories = null,
    isLoading: getCategoriesLoading,
  } = useQuery('separate-waste', certApi.getSeparateWasteCategories, {
    select: (res) => res.data.result,
    staleTime: Number.MAX_VALUE,
  });
  const [
    plasticOptions,
    paperOptions,
    plasticCategoryIds,
    paperCategoryIds,
  ] = useMemo(() => {
    if (separateWasteCategories) {
      return [
        separateWasteCategories[0].child!.map(({ nameKo, categoryDataId }) => ({
          label: nameKo,
          value: categoryDataId,
        })),
        separateWasteCategories[1].child!.map(({ nameKo, categoryDataId }) => ({
          label: nameKo,
          value: categoryDataId,
        })),
        separateWasteCategories[0].child!.map(
          ({ categoryDataId }) => categoryDataId,
        ),
        separateWasteCategories[1].child!.map(
          ({ categoryDataId }) => categoryDataId,
        ),
      ];
    } else {
      return [];
    }
  }, [separateWasteCategories]);

  const {
    mutate: putSeparateWaste,
    isLoading: putLoading,
  } = useMutation(
    (params: {
      productId: number;
      countryId: number;
      categoryDataIds: number[];
    }) => certApi.putSeparateWaste(params),
  );
  const productDocStatus = useProductDocStatus({
    productId,
    countryId,
    documentCode: EDocumentCode.SWI,
  });
  const updateMode = productDocStatus
    ? productDocStatus.status !== 'INP'
    : false;
  const {
    data: separateWasteCategoryIds = null,
    isLoading: getCategoryIdsLoading,
  } = useQuery(
    ['separate-waste', productId, countryId],
    () =>
      certApi.getSeparateWasteCategoryIds({ productId, countryId: countryId! }),
    {
      select: (res) => res.data.result.categoryDataIds,
      enabled: updateMode,
    },
  );

  const dispatch = useDispatch();
  const onSubmit = useCallback(
    (formValue) => {
      const { plasticIds, paperIds } = formValue;
      const categoryDataIds = [...plasticIds, ...paperIds];
      if (categoryDataIds.length === 0) {
        message.warning('최소 1개 이상의 세부 항목을 선택해 주세요.');
        return;
      }
      if (
        separateWasteCategoryIds &&
        separateWasteCategoryIds.length === categoryDataIds.length &&
        categoryDataIds.every((categoryDataId) =>
          separateWasteCategoryIds.includes(categoryDataId),
        )
      ) {
        message.warning(messages.NO_NEED_TO_UPDATE);
        return;
      }

      putSeparateWaste(
        {
          productId,
          countryId: countryId!,
          categoryDataIds,
        },
        {
          onSuccess: updateMode
            ? () => {
                dispatch(
                  certificateActions.fixDocument(
                    productDocStatus!.productDocStatusId,
                  ),
                );
                message.success('보완 완료 되었습니다.');
                history.goBack();
              }
            : () => {
                message.success('입력되었습니다.');
                history.goBack();
              },
        },
      );
    },
    [updateMode, productDocStatus],
  );

  const [form] = Form.useForm<{ plasticIds: number[]; paperIds: number[] }>();
  useEffect(() => {
    if (plasticCategoryIds && paperCategoryIds && separateWasteCategoryIds) {
      const plasticIds = separateWasteCategoryIds.filter((categoryId) =>
        plasticCategoryIds.includes(categoryId),
      );
      const paperIds = separateWasteCategoryIds.filter((categoryId) =>
        paperCategoryIds.includes(categoryId),
      );
      form.setFieldsValue({
        plasticIds,
        paperIds,
      });
    }
  }, [plasticCategoryIds, paperCategoryIds, separateWasteCategoryIds]);

  return useMemo(
    () => ({
      form,
      plasticOptions,
      paperOptions,
      updateMode,
      onSubmit,
      getLoading: getCategoryIdsLoading || getCategoriesLoading,
      putLoading,
    }),
    [
      form,
      updateMode,
      onSubmit,
      getCategoriesLoading,
      getCategoryIdsLoading,
      putLoading,
    ],
  );
};
