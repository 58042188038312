import styled from 'styled-components';
import { Input, Form, Button, Radio, Row, Col } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import Storage from 'lib/storage';
import Typography from 'components/system/general/Typography';
import PasswordInput from 'components/system/form/PasswordInput';
import palette from 'lib/styles/palette';
import path from 'lib/path';

const LoginFormBlock = styled.div`
  margin: 0 auto;
  border: none;
  max-width: 400px;
  width: 100%;

  .ant-input,
  .ant-input-affix-wrapper {
    height: 48px;
    font-size: 16px;

    &::placeholder {
      font-size: 14px;
    }
  }
`;

const StyledRadioGroup = styled(Radio.Group)<{ isOnlyBrand: boolean }>`
  display: flex;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid ${palette.primary};

  .ant-radio-button-wrapper {
    flex: 0 0 33.3333%;
    padding-top: 8px;
    height: 56px;
    background-color: #fff !important;
    box-shadow: none !important;
    position: relative;
    border-bottom: 1px solid ${palette.primary};
    bottom: -1px;

    &::before {
      height: 54px;
    }

    &:first-child {
      border-radius: ${({ isOnlyBrand }) =>
        !isOnlyBrand ? '4px 0 0 0' : '4px 4px 0 0'};
    }
    &:last-child {
      border-radius: 0 4px 0 0;
    }

    &.ant-radio-button-wrapper-checked {
      border-bottom: 1px solid #fff;
    }

    & > span {
      font-size: 18px;
      line-height: 1.33;
      color: ${palette.text.disabled};
    }
  }
  .ant-radio-button-wrapper-checked > span {
    color: ${palette.text.primary} !important;
  }
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 12px;

  &.ant-form-item-with-help {
    margin-bottom: 0px;
  }
`;

const LoginForm = ({ form, loginLoading, onLogin, onFindPassword }: any) => {
  const { pathname } = useLocation();
  const isOnlyBrand = pathname.includes('/brand');
  return (
    <LoginFormBlock>
      <Typography.Headline type="secondary">반갑습니다.</Typography.Headline>
      <Typography.Text
        type="secondary"
        gutter={{ top: 4, bottom: 56 }}
        color="gray"
      >
        로그인이 필요한 서비스 입니다.
      </Typography.Text>
      <Form form={form} colon={false} onFinish={onLogin}>
        <Form.Item
          initialValue={
            isOnlyBrand ? 'BRAND' : Storage.getItem('userType') || 'BRAND'
          }
          name="userType"
          style={{ marginBottom: 24 }}
        >
          <StyledRadioGroup buttonStyle="solid" isOnlyBrand={isOnlyBrand}>
            <Radio.Button value="BRAND">브랜드사</Radio.Button>
            {!isOnlyBrand && (
              <>
                <Radio.Button value="MANUFACTURE">제조사</Radio.Button>
                <Radio.Button value="MATERIAL">원료사</Radio.Button>
              </>
            )}
          </StyledRadioGroup>
        </Form.Item>
        <StyledFormItem
          name="username"
          rules={[{ required: true, message: '아이디를 입력해 주세요' }]}
        >
          <Input name="id" placeholder="아이디 또는 사업자 번호" />
        </StyledFormItem>
        <StyledFormItem
          name="password"
          rules={[{ required: true, message: '비밀번호를 입력해 주세요' }]}
        >
          <PasswordInput placeholder="비밀번호" />
        </StyledFormItem>
        <Typography.Text type="secondary" gutter={{ bottom: 16 }}>
          <Typography.Text
            type="secondary"
            inline
            style={{ cursor: 'pointer' }}
            onClick={onFindPassword}
          >
            비밀번호 찾기
          </Typography.Text>
        </Typography.Text>
        <Button
          type="primary"
          loading={loginLoading}
          htmlType="submit"
          block
          style={{ height: 56, fontSize: 18 }}
        >
          로그인
        </Button>
        <Row gutter={12}>
          <Col>
            <Typography.Title type="secondary" gutter={{ top: 12 }}>
              아직 계정이 없으신가요?
            </Typography.Title>
          </Col>
          <Col>
            <Link to={path.register.type}>
              <Typography.Title
                type="secondary"
                gutter={{ top: 12 }}
                color="primary"
              >
                회원가입
              </Typography.Title>
            </Link>
          </Col>
        </Row>
      </Form>
    </LoginFormBlock>
  );
};

export default LoginForm;
