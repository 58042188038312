import { useQuery } from 'react-query';

import * as rawMaterialDetailApi from 'lib/api/material/rawMaterialDetail';
import { useMemo } from 'react';

export const useRawMaterialDetail = (materialId: number) => {
  const {
    data: rawMaterialDetail,
    isFetching: getLoading,
  } = useQuery(
    'material/rawMaterialDetail/getRawMaterialDetail',
    () => rawMaterialDetailApi.getRawMaterialDetail(materialId),
    { select: (res) => res.data.result },
  );

  return useMemo(
    () => ({
      rawMaterialDetail,
      getLoading,
    }),
    [rawMaterialDetail, getLoading],
  );
};
