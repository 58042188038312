import SamplePageTemplate from 'templates/manufacturer/ProductSamplePageTemplate';
import ProductSampleProcessMap from 'components/manufacturer/productSample/ProductSampleProcessMap';
import { Typography } from 'components/system';

const notices = [
  '제품 해외 인증을 위한 영문 공정도 작성 페이지이므로, 실제 생산에 활용하실 수 없습니다.',
  '상세 공정이 없는 경우 포괄적인 기준에서 공정을 선택하여 입력해 주세요.',
  '영문 공정도 미리보기를 통하여 작성되는 공정도를 확인해 보실 수 있습니다.',
  <Typography.Text type="secondary" medium>
    영문 공정도 작성이나 포괄적 기준에서의 공정 선택이 어려운 경우 하단
    채널톡으로 문의해 주세요.
  </Typography.Text>,
];

const ProductSampleProcessMapPage = () => {
  return (
    <SamplePageTemplate notices={notices} subtitle="공정도">
      <ProductSampleProcessMap />
    </SamplePageTemplate>
  );
};

export default ProductSampleProcessMapPage;
