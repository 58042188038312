import styled, { css } from 'styled-components';
import { Button, Col, Row } from 'antd';

import { Typography } from 'components/system';
import palette from 'lib/styles/palette';

interface IProps {
  isImportWay?: boolean;
  onChangeIsImportWay: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >;
}

const CoaWayChoiceBlock = styled.div``;

const WayButton = styled(Button)<{ $activated: boolean }>`
  width: 176px;
  height: 200px;
  font-size: 18px;
  letter-spacing: -1px;
  line-height: 1.33;
  background-color: #fff;
  color: ${palette.text.black};
  border: 1px solid ${palette.inactive};
  border-radius: 8px;

  ${({ $activated }) =>
    $activated &&
    css`
      background-color: #fff;
      color: ${palette.text.black};
      border-color: ${palette.primary};
      box-shadow: 0 2px 22px 0 rgba(162, 162, 162, 0.23);
    `}

  &:hover,
  &:focus {
    background-color: #fff;
    color: ${palette.text.black};
    border-color: ${palette.primary};
    box-shadow: 0 2px 22px 0 rgba(162, 162, 162, 0.23);
  }
`;

const CoaWayChoice = ({ isImportWay, onChangeIsImportWay }: IProps) => {
  return (
    <CoaWayChoiceBlock>
      <Typography.Headline
        type="secondary"
        align="center"
        gutter={{ top: 20, bottom: 56 }}
      >
        CoA 정보를 입력할 방법을 선택해 주세요.
      </Typography.Headline>
      <Row
        gutter={16}
        justify="space-between"
        style={{ width: 384, marginLeft: 'auto', marginRight: 'auto' }}
      >
        <Col>
          <WayButton
            type="primary"
            $activated={isImportWay === false}
            onClick={() => onChangeIsImportWay(false)}
          >
            처음부터
            <br />
            입력하기
          </WayButton>
        </Col>
        <Col>
          <WayButton
            type="primary"
            $activated={isImportWay === true}
            onClick={() => onChangeIsImportWay(true)}
          >
            등록 완료된
            <br />
            원료 불러오기
          </WayButton>
          <Typography.Text
            type="secondary"
            gutter={{ top: 8, left: 8 }}
            style={{ fontSize: 10 }}
          >
            <span style={{ color: palette.text.primary }}>*</span> 기존 등록하신
            원료의 데이터를 불러와서
            <br /> 입력하실 수 있습니다.
          </Typography.Text>
        </Col>
      </Row>
    </CoaWayChoiceBlock>
  );
};

export default CoaWayChoice;
