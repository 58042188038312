import { Button, Card, Col, Input, message, Row } from 'antd';
import styled from 'styled-components';

import { Typography } from 'components/system';
import { downloadFile } from 'lib/file';
import palette from 'lib/styles/palette';
import { IProductSampleRecipeDetailItem } from 'types/manufacturer/productSample';
import RecipeDetailTable from './RecipeDetailTable';

const ProductSampleRecipeDetailModalContentBlock = styled.div`
  .ant-input:hover {
    border-color: #d9d9d9;
  }
`;

const FormulaDownloadCard = styled(Card)`
  margin-top: 16px;
  border: 1px solid #d7e7fe;
  background-color: ${palette.lightBlue10};

  .ant-card-body {
    padding-top: 16px;
    padding-bottom: 20px;

    .ant-btn {
      width: 168px;
      height: 32px;
      color: ${palette.primary};
    }
  }
`;

const ProductSampleRecipeDetailModalContent = ({
  recipeDetailItems,
  recipeDetailUploadFileUrl,
  formulaExcelUrl,
  formulaBreakdownExcelUrl,
  formulaInciNameList,
}: {
  recipeDetailItems: IProductSampleRecipeDetailItem[];
  recipeDetailUploadFileUrl?: string;
  formulaExcelUrl?: string;
  formulaBreakdownExcelUrl?: string;
  formulaInciNameList?: string;
}) => {
  const handleCopyInciNames = () => {
    navigator.clipboard.writeText(formulaInciNameList!).then(() => {
      message.info(
        '전성분이 복사되었습니다. 원하는 곳에 붙여넣기(Ctrl+V)해주세요.',
      );
    });
  };
  return (
    <ProductSampleRecipeDetailModalContentBlock>
      <Typography.Title gutter={{ bottom: 16 }}>
        {recipeDetailUploadFileUrl ? '처방 상세' : '확정 처방 상세'}
      </Typography.Title>
      <RecipeDetailTable
        recipeDetailItems={recipeDetailItems}
        formulaExcelUrl={null}
        formulaCountryVisible={false}
      />
      {recipeDetailUploadFileUrl ? (
        <Row justify="center" style={{ marginTop: 16 }}>
          <Col>
            <Button
              size="small"
              icon={
                <i className="material-icons outlined md-14">file_download</i>
              }
              style={{ width: 112 }}
              onClick={() => downloadFile(recipeDetailUploadFileUrl)}
            >
              다운로드
            </Button>
          </Col>
        </Row>
      ) : (
        <>
          <Row style={{ marginTop: 16, marginBottom: 8 }} gutter={8}>
            <Col>
              <Typography.Text>전성분 (영문)</Typography.Text>
            </Col>
            <Col>
              <Button
                className="copy"
                icon={
                  <i className="material-icons outlined md-14">content_copy</i>
                }
                size="small"
                style={{ height: 24, fontSize: 12 }}
                onClick={handleCopyInciNames}
              >
                전성분 복사
              </Button>
            </Col>
          </Row>
          <Input.TextArea
            value={formulaInciNameList}
            autoSize={{ minRows: 2 }}
            readOnly
          />
          <FormulaDownloadCard>
            <Typography.Text type="secondary" align="center">
              상단의 확정 처방 상세를 기준으로 생성된 단일/복합 성분표를
              다운로드해 보세요.
            </Typography.Text>
            <Row gutter={8} justify="center" style={{ marginTop: 16 }}>
              <Col>
                <Button
                  size="small"
                  icon={
                    <i className="material-icons outlined md-14">
                      file_download
                    </i>
                  }
                  onClick={() => downloadFile(formulaExcelUrl)}
                >
                  Formula Single
                </Button>
              </Col>
              <Col>
                <Button
                  size="small"
                  icon={
                    <i className="material-icons outlined md-14">
                      file_download
                    </i>
                  }
                  onClick={() => downloadFile(formulaBreakdownExcelUrl)}
                >
                  Formula Breakdown
                </Button>
              </Col>
            </Row>
          </FormulaDownloadCard>
        </>
      )}
    </ProductSampleRecipeDetailModalContentBlock>
  );
};

export default ProductSampleRecipeDetailModalContent;
