import { Input } from 'antd';
import { PasswordProps } from 'antd/lib/input';

const PasswordInput = (props: PasswordProps) => {
  return (
    <Input.Password
      {...props}
      iconRender={(visible) =>
        visible ? (
          <span>
            <i className="material-icons outlined primary">visibility</i>
          </span>
        ) : (
          <span>
            <i className="material-icons outlined" style={{ color: '#aaa' }}>
              visibility_off
            </i>
          </span>
        )
      }
    />
  );
};

export default PasswordInput;
