import styled from 'styled-components';
import { Button, Col, Progress, Row, Upload } from 'antd';

import { useEtcDatas } from 'service/material/etcData';
import { useUpdateMode } from 'hook/material';
import { Typography } from 'components/system';
import EtcDataTable from './EtcDataTable';
import palette from 'lib/styles/palette';
import FooterBox from 'components/FooterBox';
import { getReadableFileSize } from 'lib/file';

const RawMaterialEtcDataBlock = styled.div`
  .ant-progress-bg::before {
    display: none;
  }
`;

const StyledUploadDragger = styled(Upload.Dragger)`
  display: block;
  width: 100%;
  height: 222px !important;
  border: 1px solid ${palette.inactive} !important;
  border-radius: 0 !important;
  background-color: #fff !important;

  .ant-btn {
    width: 104px;
    height: 104px;
    border: 1px dashed rgba(0, 0, 0, 0.15);
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 0;
    color: ${palette.text.gray};

    .material-icons {
      margin-right: 0;
      color: ${palette.warmGray};
    }
  }

  .ant-upload p {
    padding-top: 16px;
    font-size: 10px;
    color: ${palette.text.gray};
  }
`;

const RawMaterialEtcData = ({ materialId }: { materialId: number }) => {
  const updateMode = useUpdateMode();
  const {
    etcDatas,
    getLoading,
    totalFileSize,
    isFileSizeExceed,
    addEtcData,
    deleteFile,
    saveEtcDatas,
    saveLoading,
  } = useEtcDatas(materialId, updateMode);

  return (
    <RawMaterialEtcDataBlock>
      <EtcDataTable
        etcDatas={etcDatas}
        loading={getLoading}
        onDeleteFile={deleteFile}
      />
      <Row justify="end">
        <Col>
          <Progress
            percent={(totalFileSize * 100) / (500 * 10 ** 6)}
            showInfo={false}
            status={isFileSizeExceed ? 'exception' : 'active'}
            style={{ width: 240 }}
          />
          <Typography.Text align="right" medium>
            <Typography.Text
              inline
              color={isFileSizeExceed ? 'error' : 'primary'}
              medium
            >
              {getReadableFileSize(totalFileSize)}
            </Typography.Text>{' '}
            / 500 MB
          </Typography.Text>
          <Typography.Text type="quaternary" align="right">
            (사용된 용량 / 회사 할당 용량)
          </Typography.Text>
        </Col>
      </Row>
      <Typography.Text type="secondary" gutter={{ top: 16, bottom: 8 }}>
        파일 업로드
      </Typography.Text>
      <StyledUploadDragger
        multiple
        beforeUpload={(file) => {
          addEtcData(file);
          return false;
        }}
        itemRender={() => null}
      >
        <Button>
          <i className="material-icons">add</i>
          <br />
          파일 선택
        </Button>
        <Typography.Text type="secondary" gutter={{ top: 4 }} color="gray">
          버튼 클릭 혹은 드래그하여
          <br />
          파일을 첨부하세요.
        </Typography.Text>
      </StyledUploadDragger>
      <FooterBox>
        <Button
          type="primary"
          onClick={() => saveEtcDatas()}
          loading={saveLoading}
        >
          {!updateMode ? '저장' : '수정'}
        </Button>
      </FooterBox>
    </RawMaterialEtcDataBlock>
  );
};

export default RawMaterialEtcData;
