import styled from 'styled-components';
import { useEffect } from 'react';
import { Button } from 'antd';

import { Typography } from 'components/system';

const ClinicalTrialModalMask = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background-color: #00000050;
`;

const ClinicalTrialModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .close-icon {
    position: absolute;
    right: 16px;
    top: 16px;
  }
`;

const ClinicalTrialModalContainer = styled.div`
  width: 360px;
  padding: 32px 0;
  background-color: #f5f8fb;
  border-radius: 20px;
  overflow: auto;

  .ant-form-item-label {
    padding-bottom: 4px;
  }

  .ant-input-lg {
    height: 44px;
  }
`;

const ClinicalTrialModal = ({
  content,
  okText,
  closeText,
  onOk,
  onClose,
  closeOnEsc,
  style,
}: {
  content: string;
  okText: string;
  closeText: string;
  onOk: () => void;
  onClose?: () => void;
  closeOnEsc?: boolean;
  style?: React.CSSProperties;
}) => {
  useEffect(() => {
    if (closeOnEsc && onClose) {
      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.keyCode === 27) {
          onClose();
        }
      };
      window.addEventListener('keydown', handleKeyDown);
      return () => window.removeEventListener('keydown', handleKeyDown);
    }
  }, [onClose, closeOnEsc]);

  return (
    <ClinicalTrialModalMask>
      <ClinicalTrialModalWrapper>
        <ClinicalTrialModalContainer style={style}>
          <Typography.Title
            type="secondary"
            color="slate"
            align="center"
            medium
            gutter={{ bottom: 68 }}
          >
            {content}
          </Typography.Title>
          <Button
            type="primary"
            block
            size="large"
            onClick={onOk}
            style={{ height: 56 }}
          >
            <Typography.Title type="secondary" medium inline>
              {okText}
            </Typography.Title>
          </Button>
          <Typography.Title
            type="secondary"
            color="slate"
            align="center"
            medium
            gutter={{ top: 24 }}
            style={{ cursor: 'pointer' }}
            onClick={onClose}
          >
            {closeText}
          </Typography.Title>
        </ClinicalTrialModalContainer>
      </ClinicalTrialModalWrapper>
    </ClinicalTrialModalMask>
  );
};

export default ClinicalTrialModal;
