import { useCallback, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Form, message } from 'antd';
import { useMutation, useQuery } from 'react-query';

import { messages } from 'lib/consts';
import { ICountryProductName, ISeriesProductInfo } from 'types/certificate';
import { getUpdatingObject } from 'lib/form';
import * as certificateApi from 'lib/api/certificate';
import * as certificateActions from 'modules/certificate';
import { useCurrentProduct } from '../product/product';

export const useCountryProductName = (updateProductName: any) => {
  const [form] = Form.useForm<ICountryProductName>();
  const { productDetail } = useCurrentProduct();
  const { estimateTarget, readOnlyMode, updateLoading, status } = useSelector(
    ({ certificate, loading }: any) => ({
      estimateTarget: certificate.certificate.estimateTarget,
      readOnlyMode: certificate.readOnlyMode,
      updateLoading: loading[updateProductName().type],
      status: certificate.documents.find(
        (doc: any) => doc.documentCode === 'pn',
      )?.status,
    }),
    shallowEqual,
  );

  const { productId, countryId } = estimateTarget;
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    (formValues: ICountryProductName) => {
      const updatingObject = getUpdatingObject(formValues, productDetail);
      if (!updatingObject) {
        return message.warn(messages.NO_NEED_TO_UPDATE);
      }
      dispatch(updateProductName({ productId, countryId, ...updatingObject }));
    },
    [productDetail, updateProductName],
  );
  useEffect(() => {
    if (productDetail) {
      form.setFieldsValue({
        countryBrandName: productDetail.countryBrandName,
        productNameEn: productDetail.productNameEn,
        countryProductName: productDetail.countryProductName,
      });
    }
  }, [productDetail]);

  return useMemo(
    () => ({
      form,
      status,
      productId,
      countryId,
      readOnlyMode,
      updateLoading,
      onSubmit,
    }),
    [form, readOnlyMode, updateLoading, readOnlyMode, status, onSubmit],
  );
};

export const useSeriesProductInfo = (
  productId: number,
  countryId: number,
  certTargetId: number,
) => {
  const dispatch = useDispatch();

  const currentDocStatus = useSelector(
    ({ product }: any) => product.currentDocStatus,
  );
  const { data: isSeriesProduct = null, isFetching: getLoading } = useQuery(
    [`products/${productId}/series-info`, countryId],
    () => certificateApi.getSeriesInfo({ productId, countryId }),
    {
      select: (res) => res.data.result.isSeriesProduct,
      enabled: productId !== undefined,
    },
  );
  const { mutate: updateSeriesInfo, isLoading: updateLoading } = useMutation(
    (seriesProduct: ISeriesProductInfo) =>
      certificateApi.updateSeriesInfo(seriesProduct),
    {
      onSuccess: () => {
        dispatch(certificateActions.toggleSeriesProductInfoModalVisible());

        if (currentDocStatus && currentDocStatus.status === 'MOD') {
          dispatch(
            certificateActions.fixDocument(currentDocStatus.productDocStatusId),
          );
          message.success('보완 완료 되었습니다.');
        } else {
          dispatch(certificateActions.getDocuments(certTargetId));
          message.success('등록되었습니다.');
        }
      },
    },
  );

  return useMemo(
    () => ({
      isSeriesProduct,
      getLoading,
      updateLoading,
      updateSeriesInfo,
    }),
    [isSeriesProduct, getLoading, updateLoading],
  );
};
