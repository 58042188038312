import palette from 'lib/styles/palette';

const Logo = ({ isGrayscale = false }: { isGrayscale?: boolean }) => {
  return (
    <svg
      width="166"
      height="32"
      viewBox="0 0 166 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        display: 'block',
      }}
    >
      <g clip-path="url(#clip0_2_282)">
        <path
          d="M88.2599 7.58333V1.52066H91.5253V7.11186H95.9589V10.1067H91.5253V24.3835H88.2599V7.58333Z"
          fill={isGrayscale ? palette.warmGray : '#231F20'}
        />
        <path
          d="M101.468 7.10519H98.3284V24.3768H101.468V7.10519Z"
          fill={isGrayscale ? palette.warmGray : '#231F20'}
        />
        <path
          d="M101.468 1.51401H98.3284V4.97365H101.468V1.51401Z"
          fill={isGrayscale ? palette.warmGray : '#231F20'}
        />
        <path
          d="M70.6319 19.3634C69.9951 20.2378 69.1236 20.9138 68.1185 21.313C67.1134 21.7121 66.0158 21.8181 64.953 21.6187C63.8902 21.4192 62.9056 20.9224 62.1134 20.186C61.3212 19.4495 60.7539 18.5035 60.4771 17.4576H74.8995V15.8838C74.8932 13.6636 74.0631 11.5248 72.5701 9.88228C71.077 8.23972 69.0275 7.21041 66.819 6.99395C64.6105 6.7775 62.4003 7.38935 60.6171 8.71081C58.8338 10.0323 57.6046 11.9692 57.1678 14.146C56.7311 16.3228 57.1178 18.5843 58.2531 20.4919C59.3884 22.3996 61.1913 23.8175 63.3122 24.4706C65.4331 25.1238 67.7208 24.9657 69.7318 24.0269C71.7428 23.0882 73.3337 21.4357 74.196 19.3899L70.6319 19.3634ZM66.0257 10.2262C67.2586 10.2211 68.4603 10.6137 69.4526 11.3457C70.4449 12.0777 71.1749 13.1102 71.5345 14.2901H60.5435C60.9036 13.1157 61.6302 12.0875 62.6167 11.3562C63.6032 10.6249 64.7979 10.2289 66.0257 10.2262Z"
          fill={isGrayscale ? palette.warmGray : '#231F20'}
        />
        <path
          d="M132.284 24.8416C130.529 24.8416 128.813 24.3209 127.354 23.3454C125.894 22.3699 124.757 20.9833 124.085 19.361C123.414 17.7387 123.238 15.9536 123.58 14.2314C123.923 12.5092 124.768 10.9273 126.009 9.68563C127.25 8.44399 128.831 7.59843 130.552 7.25586C132.274 6.91329 134.058 7.08911 135.68 7.76108C137.301 8.43305 138.687 9.57099 139.662 11.031C140.637 12.491 141.157 14.2075 141.157 15.9635C141.156 18.3176 140.22 20.5747 138.556 22.2393C136.893 23.9039 134.637 24.8399 132.284 24.8416ZM132.284 10.2328C131.148 10.2315 130.037 10.5674 129.092 11.1981C128.146 11.8287 127.41 12.7257 126.974 13.7756C126.539 14.8254 126.425 15.9809 126.646 17.0957C126.867 18.2105 127.414 19.2346 128.218 20.0383C129.021 20.842 130.044 21.3892 131.159 21.6106C132.273 21.832 133.428 21.7177 134.477 21.2822C135.526 20.8466 136.423 20.1094 137.053 19.1638C137.684 18.2182 138.019 17.1067 138.018 15.9701C138.016 14.4484 137.412 12.9894 136.337 11.9128C135.262 10.8362 133.805 10.2297 132.284 10.2262V10.2328Z"
          fill={isGrayscale ? palette.warmGray : '#231F20'}
        />
        <path
          d="M118.001 18.6661C117.399 19.7972 116.436 20.6944 115.266 21.2151C114.096 21.7358 112.785 21.8501 111.543 21.5397C110.3 21.2294 109.197 20.5123 108.408 19.5025C107.62 18.4927 107.192 17.2481 107.192 15.9668C107.192 14.6855 107.62 13.4409 108.408 12.4311C109.197 11.4213 110.3 10.7042 111.543 10.3939C112.785 10.0835 114.096 10.1978 115.266 10.7185C116.436 11.2392 117.399 12.1364 118.001 13.2675H121.425C120.782 11.2498 119.438 9.52883 117.638 8.41515C115.837 7.30147 113.698 6.86847 111.606 7.19432C109.515 7.52016 107.608 8.58338 106.232 10.1921C104.855 11.8007 104.098 13.8489 104.098 15.9668C104.098 18.0847 104.855 20.1329 106.232 21.7415C107.608 23.3502 109.515 24.4134 111.606 24.7393C113.698 25.0651 115.837 24.6321 117.638 23.5184C119.438 22.4048 120.782 20.6838 121.425 18.6661H118.001Z"
          fill={isGrayscale ? palette.warmGray : '#231F20'}
        />
        <path
          d="M52.0082 18.6661C51.4063 19.7972 50.4438 20.6944 49.2736 21.2151C48.1034 21.7358 46.7928 21.8501 45.5502 21.5397C44.3076 21.2294 43.2044 20.5123 42.4161 19.5025C41.6277 18.4927 41.1995 17.2481 41.1995 15.9668C41.1995 14.6855 41.6277 13.4409 42.4161 12.4311C43.2044 11.4213 44.3076 10.7042 45.5502 10.3939C46.7928 10.0835 48.1034 10.1978 49.2736 10.7185C50.4438 11.2392 51.4063 12.1364 52.0082 13.2675H55.4329C54.7893 11.2498 53.446 9.52883 51.6454 8.41515C49.8448 7.30147 47.7057 6.86847 45.614 7.19432C43.5223 7.52016 41.616 8.58338 40.2391 10.1921C38.8622 11.8007 38.1055 13.8489 38.1055 15.9668C38.1055 18.0847 38.8622 20.1329 40.2391 21.7415C41.616 23.3502 43.5223 24.4134 45.614 24.7393C47.7057 25.0651 49.8448 24.6321 51.6454 23.5184C53.446 22.4048 54.7893 20.6838 55.4329 18.6661H52.0082Z"
          fill={isGrayscale ? palette.warmGray : '#231F20'}
        />
        <path
          d="M80.7069 24.3769H77.5675V13.9647C77.5675 9.98051 80.7865 7.01889 85.0541 7.01889H85.5121V10.1532H85.0541C82.4922 10.1532 80.7069 11.7203 80.7069 13.9581V24.3769Z"
          fill={isGrayscale ? palette.warmGray : '#231F20'}
        />
        <path
          d="M160.624 14.2901H146.686C147.021 13.1214 147.727 12.0933 148.696 11.3604C149.666 10.6274 150.847 10.2294 152.062 10.2262C152.833 10.23 153.594 10.3963 154.296 10.7144C154.999 11.0325 155.626 11.4952 156.137 12.0722H159.887C159.048 10.2645 157.615 8.79941 155.827 7.92174C154.038 7.04407 152.003 6.80695 150.061 7.24999C148.118 7.69303 146.387 8.7894 145.156 10.356C143.924 11.9225 143.267 13.8644 143.295 15.8572V17.4576H157.445C157.142 18.6695 156.446 19.7468 155.466 20.5206C154.486 21.2945 153.277 21.7212 152.029 21.734C151.161 21.7263 150.307 21.5157 149.535 21.1191C148.763 20.7225 148.094 20.1508 147.582 19.4497H144.005C144.868 21.4146 146.424 22.9932 148.376 23.8847C150.327 24.7762 152.539 24.9185 154.588 24.2844C156.638 23.6503 158.383 22.2842 159.491 20.4461C160.599 18.608 160.993 16.4264 160.597 14.3167L160.624 14.2901Z"
          fill={isGrayscale ? palette.warmGray : '#231F20'}
        />
        <path
          d="M162.755 6.94584H163.876C164.085 6.93045 164.293 6.99182 164.46 7.11848C164.526 7.17612 164.577 7.24798 164.61 7.32855C164.644 7.40912 164.658 7.49628 164.653 7.58332C164.657 7.67854 164.642 7.77366 164.606 7.86221C164.577 7.92697 164.539 7.98733 164.493 8.04151C164.453 8.07755 164.408 8.10875 164.361 8.13447L164.268 8.17431L164.381 8.20752C164.424 8.22815 164.462 8.25754 164.493 8.29384C164.531 8.33654 164.561 8.38614 164.58 8.43992C164.602 8.51529 164.614 8.59364 164.613 8.67234C164.613 8.79924 164.624 8.92588 164.646 9.05084C164.655 9.13864 164.689 9.22185 164.746 9.28989H164.301C164.272 9.2386 164.254 9.18218 164.248 9.12388C164.245 9.06417 164.245 9.00431 164.248 8.94459C164.255 8.85178 164.255 8.75851 164.248 8.6657C164.236 8.59749 164.211 8.53216 164.175 8.47313C164.139 8.42163 164.088 8.38228 164.029 8.36024C163.955 8.3332 163.875 8.32189 163.797 8.32704H163.193V9.28989H162.755V6.94584ZM163.166 8.02158H163.83C163.884 8.02577 163.938 8.01912 163.989 8.00201C164.04 7.9849 164.088 7.95769 164.128 7.92198C164.164 7.88526 164.191 7.84139 164.208 7.79329C164.225 7.7452 164.232 7.69398 164.228 7.64308C164.232 7.57935 164.22 7.51565 164.195 7.45715C164.175 7.41511 164.146 7.3786 164.108 7.3509C164.069 7.32376 164.023 7.30563 163.976 7.29778H163.823H163.159L163.166 8.02158Z"
          fill={isGrayscale ? palette.warmGray : '#231F20'}
        />
        <path
          d="M163.677 10.4453C163.218 10.4453 162.768 10.309 162.386 10.0536C162.004 9.79826 161.707 9.43528 161.531 9.0106C161.355 8.58592 161.309 8.11862 161.399 7.66778C161.488 7.21694 161.71 6.80282 162.034 6.47779C162.359 6.15275 162.773 5.93139 163.224 5.84172C163.674 5.75204 164.141 5.79807 164.566 5.97397C164.99 6.14988 165.353 6.44777 165.608 6.82997C165.864 7.21217 166 7.66153 166 8.1212C166 8.73759 165.755 9.32874 165.32 9.7646C164.884 10.2005 164.293 10.4453 163.677 10.4453ZM163.677 6.13572C163.283 6.13572 162.898 6.25256 162.571 6.47146C162.243 6.69035 161.988 7.00148 161.837 7.36549C161.687 7.7295 161.647 8.13005 161.724 8.51648C161.801 8.90291 161.991 9.25787 162.269 9.53647C162.548 9.81507 162.902 10.0048 163.289 10.0817C163.675 10.1585 164.075 10.1191 164.439 9.96831C164.803 9.81753 165.114 9.5622 165.333 9.2346C165.551 8.907 165.668 8.52184 165.668 8.12783C165.659 7.60527 165.446 7.10706 165.073 6.74061C164.701 6.37417 164.199 6.16885 163.677 6.16892V6.13572Z"
          fill={isGrayscale ? palette.warmGray : '#231F20'}
        />
        <path
          d="M15.9888 32C12.8257 31.996 9.7349 31.054 7.10695 29.2928C4.47901 27.5317 2.43195 25.0305 1.22455 22.1055C0.017143 19.1806 -0.296391 15.9631 0.323579 12.8599C0.943548 9.75673 2.46918 6.90708 4.70763 4.67124C6.94608 2.43539 9.79683 0.913719 12.8995 0.29859C16.0022 -0.316538 19.2175 0.00249666 22.139 1.21535C25.0605 2.42821 27.557 4.48043 29.3129 7.1126C31.0688 9.74477 32.0053 12.8387 32.004 16.0033C31.9988 20.2489 30.3089 24.3187 27.3058 27.3182C24.3028 30.3178 20.2322 32.0018 15.9888 32ZM20.721 24.6159C21.4551 24.6172 22.173 24.4006 22.784 23.9935C23.395 23.5865 23.8716 23.0072 24.1534 22.329C24.4353 21.6509 24.5097 20.9043 24.3673 20.1838C24.2249 19.4633 23.872 18.8013 23.3534 18.2815C22.8348 17.7617 22.1738 17.4075 21.4539 17.2638C20.734 17.12 19.9877 17.1931 19.3094 17.4739C18.631 17.7546 18.0512 18.2304 17.6432 18.841C17.2353 19.4515 17.0175 20.1695 17.0175 20.9039C17.0175 21.8872 17.4075 22.8304 18.1018 23.5263C18.7962 24.2223 19.7382 24.6141 20.721 24.6159ZM24.0395 7.62314H17.4025V14.2635H24.0395V7.62314ZM14.4954 7.62314H7.7986V24.3768H14.4888L14.4954 7.62314Z"
          fill={isGrayscale ? palette.warmGray : '#DA2128'}
        />
      </g>
      <defs>
        <clipPath id="clip0_2_282">
          <rect width="166" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;
