import { useLocation } from 'react-router';
import qs from 'qs';

const useUpdateMode = () => {
  const location = useLocation();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  return query.updateMode === 'true';
};

export default useUpdateMode;
