import { Button, Divider, Modal, Space, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import SunEditor from 'suneditor-react';
import { PaperClipOutlined } from '@ant-design/icons';

import * as serviceActions from 'modules/service';
import 'suneditor/dist/css/suneditor.min.css';
import { downloadFile } from 'lib/file';

const editorOptions = {
  width: 'auto',
  height: 'auto',
  minHeight: '300px',
  maxHeight: '500px',
};

const PostModal = () => {
  const dispatch = useDispatch();
  const post = useSelector(({ service }: any) => service.post);
  const { attaches, content } = post || {};
  const onClose = () => {
    dispatch(serviceActions.setPost(null));
  };
  return (
    <Modal
      visible={post !== null}
      title={post?.title}
      width={1024}
      bodyStyle={{ minHeight: 400 }}
      footer={null}
      destroyOnClose
      onCancel={onClose}
    >
      <SunEditor
        setDefaultStyle="userSelect: text;"
        lang="ko"
        setOptions={editorOptions}
        showToolbar={false}
        defaultValue={content}
        disable
      />
      {attaches && attaches.length > 0 && (
        <>
          <Divider />
          <Typography.Paragraph
            strong
            style={{ marginBottom: 4, paddingLeft: 8 }}
          >
            첨부 파일
          </Typography.Paragraph>
          <Space>
            {attaches.map(
              ({
                faqAttachId,
                attachUrl,
                filename,
              }: {
                faqAttachId: number;
                attachUrl: string;
                filename: string;
              }) => (
                <Button
                  key={faqAttachId}
                  type="link"
                  icon={<PaperClipOutlined style={{ color: '#333' }} />}
                  style={{ padding: '0px 4px' }}
                  onClick={() => downloadFile(attachUrl, filename)}
                >
                  {filename}
                </Button>
              ),
            )}
          </Space>
        </>
      )}
    </Modal>
  );
};

export default PostModal;
