import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import * as estimateApi from 'lib/api/estimate';
import { setSelectedEstimateTargetIds } from 'modules/estimate';

export const useEstimateTargets = (companyId: number) => {
  const { data = [], isFetching } = useQuery(
    ['estimate/getEstimateTargets', companyId],
    () => estimateApi.getEstimateTargets(companyId),
    { select: (res) => res.data.result },
  );

  return useMemo(
    () => ({
      estimateTargets: data,
      isLoading: isFetching,
    }),
    [data, isFetching],
  );
};

export const useAddEstimateTargets = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const selectedEstimateTargetIds = useSelector(
    ({ estimate }: any) => estimate.selectedEstimateTargetIds as number[],
  );
  const { mutate: addEstimateTargets, isLoading: addLoading } = useMutation(
    ({
      productId,
      countryCodes,
    }: {
      productId: number;
      countryCodes: string[];
    }) => estimateApi.addEstimateTargets({ productId, countryCodes }),
    {
      onSuccess: (res) => {
        const estimateTargets = res.data.result;
        queryClient.refetchQueries('estimate/getEstimateTargets');
        const newEstimateTargetIds = estimateTargets.map(
          ({ estimateTargetId }) => estimateTargetId,
        );
        dispatch(
          setSelectedEstimateTargetIds(
            selectedEstimateTargetIds.concat(newEstimateTargetIds),
          ),
        );
      },
    },
  );
  return useMemo(() => ({ addEstimateTargets, addLoading }), [
    addEstimateTargets,
    addLoading,
  ]);
};

export const useDeleteEstimateTargets = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const {
    mutate: deleteEstimateTargets,
    isLoading: deleteLoading,
  } = useMutation(
    (estimateTargetIds: number[]) =>
      estimateApi.deleteEstimateTargets(estimateTargetIds),
    {
      onSuccess: () => {
        queryClient.refetchQueries('estimate/getEstimateTargets');
        dispatch(setSelectedEstimateTargetIds([]));
      },
    },
  );
  return useMemo(() => ({ deleteEstimateTargets, deleteLoading }), [
    deleteEstimateTargets,
    deleteLoading,
  ]);
};
