import { Button, Col, Row, Upload } from 'antd';
import styled from 'styled-components';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';

import FooterBox from 'components/FooterBox';
import history from 'lib/history';
import { useCompositionInformation } from 'service/material/compositionInformation';
import CompositionInformationExcelViewerTable from 'components/material/rawMaterial/compositionInformation/CompositionInformationExcelViewerTable';
import { useRawMaterial } from 'service/material/rawMaterial';
import { useState } from 'react';
import { ICompositionInformationDraft } from 'types/material/compositionInformation';

const CompositionInformationBlock = styled.div``;

const CompositionInformation = () => {
  const params = useParams<{
    materialId: string;
  }>();
  const materialId = Number(params.materialId)!;
  const {
    compositionInformations,
    getLoading,
    uploadCompositionInformation,
    uploadLoading,
  } = useCompositionInformation(materialId);
  const [
    compositionInformationDraft,
    setCompositionInformationDraft,
  ] = useState<ICompositionInformationDraft | null>(null);
  const { rawMaterial } = useRawMaterial(materialId);

  const fetchLoading = getLoading || uploadLoading;
  const handleUpload = (file: File) => {
    uploadCompositionInformation(
      { materialId, file },
      {
        onSuccess: (res) => {
          setCompositionInformationDraft(res.data.result);
        },
        onError: () => {
          setCompositionInformationDraft(null);
        },
      },
    );
  };

  return (
    <CompositionInformationBlock>
      <Row gutter={8} justify="center" align="middle">
        <Col>
          <Button
            disabled={rawMaterial?.isDiscontinued}
            type="dashed"
            icon={<DownloadOutlined />}
            href="https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/sample/Material_Composition_Information_V2.xlsx"
          >
            엑셀 서식 다운로드
          </Button>
        </Col>
        <Col>
          <Upload
            disabled={rawMaterial?.isDiscontinued}
            accept=".xlsx"
            beforeUpload={(file) => {
              handleUpload(file);
              return false;
            }}
            itemRender={() => null}
          >
            <Button
              disabled={rawMaterial?.isDiscontinued}
              loading={fetchLoading}
              icon={<UploadOutlined />}
              style={{ width: 122 }}
              onDrop={(e) => e.stopPropagation()}
            >
              파일 업로드
            </Button>
          </Upload>
        </Col>
      </Row>
      <CompositionInformationExcelViewerTable
        fetchLoading={fetchLoading}
        dataSource={
          compositionInformationDraft?.list || compositionInformations
        }
        errorCodes={compositionInformationDraft?.invalidCodes || null}
        invalidURL={compositionInformationDraft?.invalidExcelUrl}
      />
      {rawMaterial &&
        !rawMaterial?.isDiscontinued &&
        ((compositionInformationDraft &&
          compositionInformationDraft.invalidCodes.length === 0) ||
          compositionInformations.length > 0) && (
          <FooterBox>
            <Button type="primary" onClick={history.goBack}>
              확인
            </Button>
          </FooterBox>
        )}
    </CompositionInformationBlock>
  );
};

export default CompositionInformation;
