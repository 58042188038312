import { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import * as productActions from 'modules/product';

export const useUpdateLog = ({
  productId,
  countryId,
  documentCode,
  validCodes,
}) => {
  const dispatch = useDispatch();
  const { documentFieldMap, documentLogs, loading } = useSelector(
    ({ product, loading }) => ({
      documentFieldMap: product.documentFieldMap,
      documentLogs: product.documentLogs,
      loading: loading['product/GET_DOCUMENT_LOGS'],
    }),
    shallowEqual,
  );

  const fields = useMemo(() => {
    if (validCodes) {
      return documentFieldMap[documentCode].filter(({ code }) =>
        validCodes.includes(code),
      );
    }
    return documentFieldMap[documentCode];
  }, [documentCode, validCodes]);

  const [selectedField, setSelectedField] = useState(null);

  useEffect(() => {
    if (selectedField) {
      dispatch(
        productActions.getDocumentLogs({
          productId,
          countryId,
          documentSeqId: selectedField.documentSeqId,
          documentFieldId: selectedField.documentFieldId,
        }),
      );
      return () => dispatch(productActions.initializeDocumentLogs());
    }
  }, [selectedField]);

  return useMemo(
    () => ({
      fields,
      selectedField,
      setSelectedField,
      documentLogs,
      loading,
    }),
    [fields, selectedField, documentLogs, loading],
  );
};

export const useHasCountry = (codes) => {
  const productCountries = useSelector(
    ({ product }) => product.currentProduct?.productCountries,
  );
  if (!productCountries) return false;
  const countryCodes = productCountries.map(
    ({ country: { countryCode } }) => countryCode,
  );
  if (Array.isArray(codes)) {
    return codes.every((code) => countryCodes.includes(code));
  } else {
    return countryCodes.includes(codes);
  }
};
