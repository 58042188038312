import { Col, Modal, Row } from 'antd';
import { useMemo, useState } from 'react';

import TabButton from 'components/TabButton';
import { Typography } from 'components/system';
import Table from 'components/Table';
import palette from 'lib/styles/palette';
import { useEffect } from 'react';
import { ICountryProhibitInfo } from 'types/formula';

const CountryProhibitInfosModal = ({
  defaultCountryName,
  countryInfos,
  onClose,
}: {
  defaultCountryName?: string;
  countryInfos: ICountryProhibitInfo[];
  onClose: () => void;
}) => {
  const [selectedCountryName, setSelectedCountryName] = useState(
    defaultCountryName,
  );
  const {
    type,
    notifiedIngredientName,
    limitedMatter,
    fdaAnnouncedColorName,
  } = useMemo(
    () => ({
      ...countryInfos?.find(
        ({ countryNameKo }) => countryNameKo === selectedCountryName,
      ),
    }),
    [selectedCountryName, countryInfos],
  );
  useEffect(() => {
    if (countryInfos.length > 0) {
      setSelectedCountryName(
        defaultCountryName || countryInfos[0].countryNameKo,
      );
    }
  }, [countryInfos, defaultCountryName]);

  return (
    <Modal
      visible={countryInfos.length > 0}
      width={900}
      onCancel={onClose}
      footer={null}
    >
      <Typography.Title
        type="secondary"
        style={{ fontSize: 22 }}
        gutter={{ bottom: 22 }}
      >
        국가별 배합 구분
      </Typography.Title>
      <Row wrap gutter={[4, 4]}>
        {countryInfos.map(({ countryNameKo }) => (
          <Col key={countryNameKo}>
            <TabButton
              selected={countryNameKo === selectedCountryName}
              onClick={() =>
                countryNameKo !== selectedCountryName &&
                setSelectedCountryName(countryNameKo)
              }
            >
              {countryNameKo}
            </TabButton>
          </Col>
        ))}
      </Row>
      <Table
        bordered
        size="small"
        className="horizontal"
        style={{
          marginTop: 16,
          border: `1px solid ${palette.primary}`,
          borderRadius: 0,
        }}
      >
        <Table.Tbody>
          <Table.Tr>
            <Table.Th
              style={{
                width: 200,
              }}
            >
              구분
            </Table.Th>
            <Table.Td>{type === 'limit' ? '제한' : '금지'}</Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Th>고시원료명</Table.Th>
            <Table.Td>{notifiedIngredientName}</Table.Td>
          </Table.Tr>
          {fdaAnnouncedColorName && (
            <Table.Tr>
              <Table.Th>FDA 허용 색소명</Table.Th>
              <Table.Td>{fdaAnnouncedColorName}</Table.Td>
            </Table.Tr>
          )}
          <Table.Tr>
            <Table.Th>제한사항</Table.Th>
            <Table.Td style={{ whiteSpace: 'pre-line' }}>
              {limitedMatter}
            </Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>
    </Modal>
  );
};

export default CountryProhibitInfosModal;
