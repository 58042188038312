import produce from 'immer';
import { createReducer } from 'typesafe-actions';
import { TOGGLE_DRAWER } from './actions';

import { NavigatorAction, NavigatorState } from './types';

const initialState: NavigatorState = {
  drawer: {
    visible: false,
  },
};

export default createReducer<NavigatorState, NavigatorAction>(initialState, {
  [TOGGLE_DRAWER]: (state) =>
    produce(state, (draft) => {
      draft.drawer.visible = !draft.drawer.visible;
    }),
});
