import qs from 'qs';

import { IAPIPageableResponse, IAPIResponse } from 'types/common';
import {
  ERawMaterialSearchType,
  IEfficaciesAndFormulaPurposes,
  IRawMaterial,
} from 'types/material/rawMaterialSearch';
import client from 'lib/api/client';

export const getEfficaciesAndFormulaPurposes = () =>
  client.get<IAPIResponse<IEfficaciesAndFormulaPurposes>>(
    '/material/search/all',
  );

export const searchRawMaterials = ({
  page,
  efficacyIds,
  purposeIds,
  keyword,
  searchType,
  isExact,
}: {
  page: number;
  efficacyIds: number[];
  purposeIds: number[];
  keyword: string;
  searchType: ERawMaterialSearchType;
  isExact: boolean;
}) =>
  client.get<IAPIPageableResponse<IRawMaterial[]>>(
    `/material/search/${page}/10?${qs.stringify(
      { efficacyIds, purposeIds, keyword, searchType, isExact },
      { indices: false },
    )}`,
  );
