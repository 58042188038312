import { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import RegisterTypePage from 'pages/auth/RegisterTypePage';
import { useLocation } from 'react-router-dom';

import path from 'lib/path';
import RegisterTermsPage from 'pages/auth/RegisterTermsPage';
import BrandRegisterFormPage from 'pages/auth/brand/BrandRegisterFormPage';
import ManuRegisterFormPage from 'pages/auth/manu/ManuRegisterFormPage';
import MaterialRegisterFormPage from 'pages/auth/material/MaterialRegisterFormPage';
import RegisterSuccessPage from 'pages/auth/RegisterSuccessPage';
import Error404Page from 'pages/error/Error404Page';
import history from 'lib/history';

const RegisterRouter = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.startsWith(path.register.form)) {
      history.replace(path.register.type);
    }
  }, []);

  return (
    <Switch>
      <Route path={path.register.type} component={RegisterTypePage} />
      <Route
        path={`${path.register.terms}/:companyType`}
        component={RegisterTermsPage}
      />
      <Route
        path={`${path.register.form}/brand`}
        component={BrandRegisterFormPage}
      />
      <Route
        path={`${path.register.form}/manu`}
        component={ManuRegisterFormPage}
      />
      <Route
        path={`${path.register.form}/material`}
        component={MaterialRegisterFormPage}
      />
      <Route path={path.register.success} component={RegisterSuccessPage} />
      <Route path="*" component={Error404Page} />
    </Switch>
  );
};

export default RegisterRouter;
