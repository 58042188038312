import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button, Col, Row } from 'antd';

import { contractDocumentKeyNameMap, s3AssetDomain } from 'lib/consts';
import { downloadFile } from 'lib/file';
import palette from 'lib/styles/palette';
import { Typography } from 'components/system';

const ContractSendGuideBlock = styled.div`
  margin-top: 24px;
`;

const ContractSendGuideWrap = styled.div`
  padding: 44px 0 36px;
  border-radius: 8px;
  background-color: ${palette.gray};
`;

const ContractSendGuideBody = styled.div`
  max-width: 400px;
  margin: 0 auto;

  img {
    position: relative;
    left: 10px;
    max-width: 400px;
    margin: 0 auto;
  }
`;

const BulletNumber = styled.span`
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: ${palette.primary};
  font-size: 12px;
  text-align: center;
  color: #fff;
  vertical-align: text-top;
`;

const ContractSendGuide = () => {
  const contractDocuments = useSelector(({ estimate }: any) =>
    estimate.contractDocuments.filter(
      ({ fileKey }: any) =>
        fileKey === 'NDA' || fileKey === 'STANDARD_CONTRACT',
    ),
  );
  return (
    <ContractSendGuideBlock>
      <Typography.Title gutter={{ bottom: 16 }}>
        계약서 송부 가이드
      </Typography.Title>
      <ContractSendGuideWrap>
        <ContractSendGuideBody>
          <Typography.Title type="secondary" gutter={{ bottom: 16 }}>
            <BulletNumber>1</BulletNumber>인증대행 계약서와 비밀유지 계약서
            다운로드
          </Typography.Title>
          <Row gutter={16} justify="center">
            {contractDocuments.map(({ fileKey, filename, attachUrl }: any) => (
              <Col flex="0 0 20%" key={fileKey}>
                <Button
                  block
                  icon={
                    <i className="material-icons outlined md-18 primary">
                      file_download
                    </i>
                  }
                  onClick={() => downloadFile(attachUrl, filename)}
                >
                  {
                    contractDocumentKeyNameMap[
                      fileKey as 'NDA' | 'STANDARD_CONTRACT'
                    ]
                  }
                </Button>
              </Col>
            ))}
          </Row>
          <Typography.Title type="secondary" gutter={{ top: 32, bottom: 16 }}>
            <BulletNumber>2</BulletNumber>각 2부씩 날인
          </Typography.Title>
          <img
            src={`${s3AssetDomain}/doc-contract_V1.png`}
            alt="doc-contract"
          />
          <Typography.Title type="secondary" gutter={{ top: 12, bottom: 16 }}>
            <BulletNumber>3</BulletNumber>30COS 본사 주소로 계약서 송부
          </Typography.Title>
          <Typography.Text
            type="secondary"
            style={{ position: 'relative', left: 24 }}
          >
            충북 청주시 흥덕구 오송읍 오송생명1로 194-25 SB플라자 3층 A-13호
          </Typography.Text>
        </ContractSendGuideBody>
      </ContractSendGuideWrap>
    </ContractSendGuideBlock>
  );
};

export default ContractSendGuide;
