import styled from 'styled-components';

const FormLabelBlock = styled.div`
  display: block;
`;

const FormLabel = ({
  required = true,
  children,
}: {
  required?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <FormLabelBlock
      className="ant-form-item-label"
      style={{ display: 'block' }}
    >
      <label className={required ? 'ant-form-item-required' : ''}>
        {children}
      </label>
    </FormLabelBlock>
  );
};

export default FormLabel;
