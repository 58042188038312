import MistakeNoticeItem from 'components/MistakeNoticeItem';
import ProductSingleFileContainer from 'containers/product/ProductSingleFileContainer';
import ProductPageTemplate from 'templates/ProductPageTemplate';

const notices = [
  '해당 제품 브랜드에 대한 중국 상표등록증을 PDF 파일로 업로드해 주세요.',
  <MistakeNoticeItem />,
];

const ProductChinaCertificateTrademarkPage = () => {
  return (
    <ProductPageTemplate
      subtitle="중국 상표등록증"
      notices={notices}
      exampleImg={'PCC.jpg'}
    >
      <ProductSingleFileContainer documentCode="pcc" accept=".pdf" />
    </ProductPageTemplate>
  );
};

export default ProductChinaCertificateTrademarkPage;
