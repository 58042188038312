import styled, { css } from 'styled-components';
import { Menu } from 'antd';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import path from 'lib/path';
import { screenMax, screenMin } from 'lib/styles';
import { useToggleDrawer } from 'service/navigator';

const StyledMenu = styled(Menu)`
  ${(props) =>
    props.mode !== 'inline' &&
    css`
      display: flex;
      margin: 0 auto;
      justify-content: center;

      &::before,
      &::after {
        display: none;
      }
      @media (min-width: ${screenMin.lg}) {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      .ant-menu-submenu-horizontal:not(.ant-menu-overflowed-submenu),
      .ant-menu-item-only-child:not(.ant-menu-overflowed-submenu) {
        line-height: 77px;
        margin: 0 40px;

        @media (max-width: ${screenMax.md}) {
          margin: 0 8px;
        }
      }

      .ant-menu-submenu-title,
      .ant-menu-item {
        font-size: 18px;
        letter-spacing: -1px;
      }
    `}
`;

const TopMenu = ({ inlineMode }: { inlineMode: boolean }) => {
  const { pathname } = useLocation();
  const toggleDrawer = useToggleDrawer();
  return (
    <StyledMenu
      mode={inlineMode ? 'inline' : 'horizontal'}
      selectedKeys={[pathname]}
      triggerSubMenuAction="click"
      onClick={() => {
        if (inlineMode) {
          toggleDrawer();
        }
      }}
    >
      <Menu.SubMenu title="원료·성분">
        <Menu.Item key={path.material.rawMaterial.search}>
          <Link to={path.material.rawMaterial.search}>원료 검색</Link>
        </Menu.Item>
        <Menu.Item key={path.material.ingredient.dictionary}>
          <Link to={path.material.ingredient.dictionary}>성분 사전</Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu title="샘플 처방">
        <Menu.Item key={path.manufacturer.productSample.basic}>
          <Link to={path.manufacturer.productSample.basic}>신규 샘플 등록</Link>
        </Menu.Item>
        <Menu.Item key={path.manufacturer.productSample.readyList}>
          <Link to={path.manufacturer.productSample.readyList}>
            등록 중 샘플 관리
          </Link>
        </Menu.Item>
        <Menu.Item key={path.manufacturer.productSample.list}>
          <Link to={path.manufacturer.productSample.list}>
            확정 완료 샘플 관리
          </Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu title="제품 등록">
        <Menu.Item key={path.product.basic}>
          <Link to={path.manufacturer.product.basic}>신규 제품 등록</Link>
        </Menu.Item>
        <Menu.Item key={path.manufacturer.product.readyList}>
          <Link to={path.manufacturer.product.readyList}>
            등록 중 제품 관리
          </Link>
        </Menu.Item>
        <Menu.Item key={path.manufacturer.product.list}>
          <Link to={path.manufacturer.product.list}>등록 완료 제품 관리</Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.Item key={path.service}>
        <Link to={path.service}>고객센터</Link>
      </Menu.Item>
    </StyledMenu>
  );
};

export default TopMenu;
