export interface ISaleInfo {
  productSaleInfoId: number;
  isOnlineSale: boolean;
  saleWebUrl: string | null;
  attaches: ISaleInfoAttach[];
}

export interface ISaleInfoAttach {
  attachUrl?: string;
  filename?: string;
  productSaleInfoAttachId?: number;
}

export interface ISaleInfoAttachUpload {
  url: string;
  name: string;
  productSaleInfoAttachId: number;
}

export enum EManagerPositionName {
  OWNER = 'OWNER',
  PRESIDENT = 'PRESIDENT',
  MANAGER = 'MANAGER (담당자)',
}

export interface IFDACertificatedColor {
  productFdaCertificatedColorAdditivesDocumentId: number;
  filename: string;
  attachUrl: string;
}

export interface IFDACertificatedColorAttachUpload {
  productFdaCertificatedColorAdditivesDocumentId: number;
  name: string;
  url: string;
}

export interface IVCRPAccount {
  vcrpAccountId: number;
  vcrpAccount: string;
  vcrpPassword: string;
}

export interface IVCRPAccountRequest {
  companyNameEn: string;
  managerLastName: string;
  managerFirstName: string;
  vcrpAccount: string;
  managerPositionName: EManagerPositionName;
  managerMobile: string;
  managerEmail: string;
}
