import { useCallback, useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { message, Modal } from 'antd';
import { useDispatch } from 'react-redux';

import * as productApi from 'lib/api/manufacturer/product';
import * as productActions from 'modules/product';
import OngoingRequestModalContent from './OngoingRequestModalContent';
import { Typography } from 'components/system';
import { useUser } from 'service/auth';

export const useProductInputRequest = ({
  productId,
  documentCode,
  inputRequestId,
}: {
  productId?: number;
  documentCode?: string;
  inputRequestId?: number;
}) => {
  const { data: users = [], isFetching: getLoading, refetch } = useQuery(
    ['manufacturer/getProductInputRequestableUsers', productId, documentCode],
    () =>
      productApi.getProductInputRequestableUsers({
        productId: productId!,
        documentCode: documentCode!,
      }),
    {
      select: (res) =>
        res.data.result.filter(({ isAlreadyRequest }) => !isAlreadyRequest),
      enabled: !!documentCode && productId !== undefined,
    },
  );

  const {
    data: productInputRequest,
    isFetching: productInputRequestLoading,
  } = useQuery(
    ['manufacturer/getProductInputRequest', inputRequestId],
    () => productApi.getProductInputRequest(inputRequestId!),
    {
      select: (res) => res.data.result,
      enabled: inputRequestId !== undefined,
    },
  );
  const { name: currentUserName } = useUser();
  const dispatch = useDispatch();
  const {
    mutate: postProductInputRequestMutate,
    isLoading: postProductInputRequestLoading,
  } = useMutation(productApi.postProductInputRequest);
  const postProductInputRequest = useCallback(
    ({ toUserId, onSuccess }: { toUserId: number; onSuccess?: () => void }) => {
      postProductInputRequestMutate(
        {
          productId: productId!,
          documentCode: documentCode!,
          toUserId,
        },
        {
          onSuccess: () => {
            refetch();
            dispatch(productActions.manuGetDocuments(productId));
            message.success('입력이 요청되었습니다.');
            if (onSuccess) {
              onSuccess();
            }
          },
        },
      );
    },
    [productId, documentCode, refetch],
  );

  const {
    mutate: updateProductInputRequestMutate,
    isLoading: updateProductInputRequestLoading,
  } = useMutation(productApi.updateProductInputRequest);
  const updateProductInputRequest = useCallback(
    ({
      inputRequestId,
      status,
      onSuccess,
    }: {
      inputRequestId: number;
      status: 'CANCEL' | 'REFUSE';
      onSuccess?: () => void;
    }) => {
      updateProductInputRequestMutate(
        {
          inputRequestId,
          status,
        },
        {
          onSuccess: () => {
            dispatch(productActions.manuGetDocuments(productId));
            message.success(
              status === 'CANCEL'
                ? '입력 요청이 취소되었습니다.'
                : '요청을 거절했습니다.',
            );
            if (onSuccess) {
              onSuccess();
            }
          },
        },
      );
    },
    [productId],
  );

  const showOngoingRequestModal = (document: any) => {
    const { fromUserName, toUserName, inputRequestId } = document.inputRequest;
    const modal = Modal.confirm({
      width: 400,
      style: { textAlign: 'center' },
      icon: null,
      closable: true,
      content: (
        <OngoingRequestModalContent
          fromUserName={fromUserName}
          toUserName={toUserName}
        />
      ),
      cancelText: '요청 취소',
      okButtonProps: {
        style: {
          maxWidth: 64,
          fontSize: 12,
          textAlign: 'center',
          padding: '9px 21px',
        },
      },
      cancelButtonProps:
        currentUserName !== fromUserName
          ? {
              hidden: true,
            }
          : {
              onClick: () =>
                updateProductInputRequest({
                  inputRequestId,
                  status: 'CANCEL',
                  onSuccess: modal.destroy,
                }),
              style: {
                maxWidth: 64,
                fontSize: 12,
                textAlign: 'center',
                padding: '9px 10px',
              },
            },
    });
  };
  const showRequestModal = (document: any) => {
    const { fromUserName, inputRequestId } = document.inputRequest;
    const modal = Modal.confirm({
      width: 400,
      style: { textAlign: 'center' },
      icon: null,
      closable: true,
      bodyStyle: {
        marginTop: 24,
      },
      cancelText: '거절',
      okButtonProps: {
        style: {
          maxWidth: 64,
          fontSize: 12,
          textAlign: 'center',
          padding: '9px 21px',
        },
      },
      content: (
        <>
          <Typography.Text type="secondary" medium inline>
            {fromUserName}
          </Typography.Text>
          님이{' '}
          <Typography.Text type="secondary" medium inline>
            {document.documentName}
          </Typography.Text>{' '}
          입력 요청을 했습니다.
          <br />
          <Typography.Text type="secondary" medium inline>
            확인
          </Typography.Text>{' '}
          클릭 후 입력 정보의 입력을 완료해 주세요.
        </>
      ),
      cancelButtonProps: {
        onClick: () =>
          updateProductInputRequest({
            inputRequestId,
            status: 'REFUSE',
            onSuccess: modal.destroy,
          }),
        style: {
          maxWidth: 64,
          fontSize: 12,
          textAlign: 'center',
          padding: '9px 21px',
        },
      },
    });
  };

  return useMemo(
    () => ({
      users,
      getLoading,
      productInputRequest,
      productInputRequestLoading,
      postProductInputRequestLoading,
      updateProductInputRequestLoading,
      postProductInputRequest,
      updateProductInputRequest,
      showOngoingRequestModal,
      showRequestModal,
    }),
    [
      users,
      getLoading,
      productInputRequestLoading,
      postProductInputRequestLoading,
      updateProductInputRequestLoading,
      postProductInputRequest,
      updateProductInputRequest,
      showOngoingRequestModal,
      showRequestModal,
    ],
  );
};
