import { useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { downloadFiles } from 'lib/file';
import * as certificateActions from 'modules/certificate';
import usePreviewModal from 'hook/usePreviewModal';
import CertificateDocuments from 'components/certificate/CertificateDocuments';

const CertificateDocumentsContainer = () => {
  const {
    params: { certTargetId },
  } = useRouteMatch();
  const dispatch = useDispatch();
  const { certificate, documents, getLoading, isMobile } = useSelector(
    ({ certificate, loading, device }) => ({
      certificate: certificate.certificate,
      documents: certificate.completedDocuments,
      getLoading: loading['certificate/GET_COMPLETED_DOCUMENTS'],
      isMobile: device.isMobile,
    }),
    shallowEqual,
  );
  const [downloadFilesLoading, setDownloadFilesLoading] = useState(false);
  const { PreviewModal, setUrl } = usePreviewModal();
  const previewDocument = ({ documentUrl, documentUrls }) => {
    setUrl(documentUrls || documentUrl);
  };
  const downloadDocuments = async () => {
    const {
      certRegisterNo,
      lastStatusUpdateDt,
      estimateTarget: { productDetails, countryName },
    } = certificate;
    setDownloadFilesLoading(true);
    await downloadFiles(
      documents.reduce(
        (
          documentFiles,
          {
            documentCode,
            documentName,
            documentUrl,
            documentUrls,
            uploadFileUrl,
          },
        ) =>
          documentFiles.concat(
            documentUrls
              ? documentUrls.map((documentUrl, index) => ({
                  name: `${documentName}_${index + 1}`,
                  url: documentUrl,
                }))
              : {
                  name: documentName,
                  url:
                    documentCode === 'rmcoa' || documentCode === 'rmmsds'
                      ? uploadFileUrl
                      : documentUrl,
                },
          ),
        [],
      ),
      `${certRegisterNo}_${
        productDetails[0].productNameEn
      }_${countryName}_${lastStatusUpdateDt.slice(0, 10)}.zip`,
    );
    setDownloadFilesLoading(false);
  };

  useEffect(() => {
    dispatch(certificateActions.getCertificate(certTargetId));
  }, [certTargetId]);

  useEffect(() => {
    if (certificate) {
      dispatch(
        certificateActions.getCompletedDocuments({
          certTargetId,
          productId: certificate.estimateTarget.productId,
          countryId: certificate.estimateTarget.countryId,
        }),
      );
    }
  }, [certificate]);

  if (!certificate) return null;
  return (
    <>
      <PreviewModal />
      <CertificateDocuments
        certificate={certificate}
        documents={documents}
        getLoading={getLoading}
        downloadFilesLoading={downloadFilesLoading}
        isMobile={isMobile}
        onPreview={previewDocument}
        onDownload={downloadDocuments}
      />
    </>
  );
};

export default CertificateDocumentsContainer;
