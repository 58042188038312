export enum EMarketingFileType {
  UPLOAD = 'UPLOAD',
  TEMPLATE = 'TEMPLATE',
}

export enum EMarketingDisplayType {
  PUBLIC = 'PUBLIC',
  LIMIT = 'LIMIT',
}

export enum EMarketingTemplateStatus {
  TMP = 'TMP',
  COM = 'COM',
  DO = 'DO',
}

export interface IMarketingFile {
  materialMarketingDataId: number;
  formType: string;
  displayType: string;
  status: EMarketingTemplateStatus;
  attachUrl: string;
  filename: string;
}

export interface IItem {
  materialMarketingDataItemId?: number;
  dataTitle?: string;
  itemType?: string;
  attachUrl?: string;
  filename?: string;
  shortDescription?: string;
  description?: string;
  displayType?: EMarketingDisplayType;
  templateType?: string;
  uploadFile?: File;
}

export interface IPage {
  displayOrder: number;
  templateType: string;
  items: IItem[];
}

export interface IMarketingTemplateGet {
  files: IMarketingFile[];
  publicPages: IPage[];
  limitedPages: IPage[];
}
export interface IUploadFileBasic {
  materialMarketingDataId: number;
  name: string;
  url: string;
}

export interface IUploadType {
  materialMarketingDataId: number;
  formType: string;
  displayType: EMarketingDisplayType;
  status: string;
  attachUrl: string;
  filename: string;
}

export interface IMarketingUploadGet {
  uploadTypes: IUploadType[];
}

export interface IMarketingFormDataBase {
  pages: IPage[];
}

export interface IMarketingTemplateFormData extends IMarketingFormDataBase {
  displayType: EMarketingDisplayType;
  status: EMarketingTemplateStatus;
}

export type MarketingTemplateType = 'A' | 'B' | 'C';

export type MarketingUploadType = File | IUploadFileBasic | number | null;
