import produce from 'immer';
import { createReducer } from 'typesafe-actions';

import { OPEN_LOGIN_MODAL, CLOSE_LOGIN_MODAL } from './actions';
import { modalAction, modalState } from './types';

const initialState: modalState = {
  isOpenLoginModal: false,
};

export default createReducer<modalState, modalAction>(initialState, {
  [OPEN_LOGIN_MODAL]: (state) =>
    produce(state, (draft) => {
      draft.isOpenLoginModal = true;
    }),
  [CLOSE_LOGIN_MODAL]: (state) =>
    produce(state, (draft) => {
      draft.isOpenLoginModal = false;
    }),
});
