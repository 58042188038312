import RawMaterialEtcData from 'components/material/etcData/RawMaterialEtcData';
import { useParams } from 'react-router-dom';
import RawMaterialPageTemplate from 'templates/material/RawMaterialPageTemplate';

const RawMaterialBasicPage = () => {
  const notices = [
    '현재 페이지는 슈퍼 유저와 하위 담당자에게만 공개되며, 자료를 직접 업로드하여 자유롭게 관리할 수 있습니다.',
    '외부에 노출되지 않으며 화장품 제조사 및 책임판매사에게 전달되지 않으므로, 안전하게 사용할 수 있습니다.',
    '파일 하나의 용량은 200 MB를 초과할 수 없습니다.',
  ];
  const params = useParams<{ materialId: string }>();
  const materialId = Number(params.materialId);
  return (
    <RawMaterialPageTemplate
      documentName="기타 자료 (클라우드)"
      notices={notices}
      back={typeof materialId !== 'undefined'}
    >
      <RawMaterialEtcData materialId={materialId} />
    </RawMaterialPageTemplate>
  );
};

export default RawMaterialBasicPage;
