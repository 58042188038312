import { useQuery } from 'react-query';
import * as manuCompanyApi from 'lib/api/manufacturer/company';

export const useManufacturerCompanies = (manufacturerCompaniesParams?: {
  isExcludeUserInserted: boolean;
}) => {
  return useQuery(
    ['pub/manufacturer/name', manufacturerCompaniesParams],
    () => manuCompanyApi.getManufacturerCompanies(manufacturerCompaniesParams),
    {
      select: (res) => res.data.result,
    },
  );
};
