import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { setUserNoticeDrawerVisible } from 'modules/service';
import Navigator from 'components/Navigator';
import { toggleDrawer } from 'modules/navigator';
import { useCompanyType } from 'service/company';

const NavigatorContainer = () => {
  const dispatch = useDispatch();
  const { drawerVisible, newUserNoticeCount } = useSelector(
    ({ navigator, service }) => ({
      drawerVisible: navigator.drawer.visible,
      newUserNoticeCount: service.userNotices.reduce(
        (acc, curr) => (curr.isConfirm ? acc : acc + 1),
        0,
      ),
    }),
    shallowEqual,
  );
  const handleToggleDrawer = () => {
    dispatch(toggleDrawer());
  };
  const handleClickUserNotice = () => {
    dispatch(setUserNoticeDrawerVisible(true));
  };
  const companyType = useCompanyType();
  return (
    <Navigator
      drawerVisible={drawerVisible}
      newUserNoticeCount={newUserNoticeCount}
      companyType={companyType}
      onToggleDrawer={handleToggleDrawer}
      onClickUserNotice={handleClickUserNotice}
    />
  );
};

export default NavigatorContainer;
