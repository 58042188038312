import { useMemo } from 'react';

import MistakeNoticeItem from 'components/MistakeNoticeItem';
import ProductFormulaBreakdown from 'components/product/ProductFormulaBreakdown';
import { useHasCountry } from 'hook/product';
import ProductPageTemplate from 'templates/ProductPageTemplate';
import { Typography } from 'components/system';

const ProductFormulaBreakdownPage = () => {
  const hasEU = useHasCountry('EU');
  const hasGB = useHasCountry('GB');
  const hasUS = useHasCountry('US');
  const notices = useMemo(
    () => [
      '하단의 엑셀 서식 다운로드 버튼을 클릭하여 다운로드 받은 양식에 아래 샘플을 참고하여 작성 후, 업로드해 주세요.',
      '브랜드사의 사급원료 또는 INCI, ICID 원료 등재가 안되어 있는 경우 채널톡으로 문의 바랍니다.',
      <>
        업로드 결과화면의{' '}
        <Typography.Text type="secondary" medium inline>
          국가별 성분 규제
        </Typography.Text>{' '}
        항목은 원료 정보에 대한 참고용이며 인증 진행 가능 여부와 무관합니다.
      </>,
      ...(hasEU && !hasGB
        ? [
            '유럽연합 CPNP의 경우 유럽연합 기준의 전성분 명칭으로 기재되어야 하기 때문에, WATER → AQUA,  FRAGRANCE → PARFUM 으로 자동 변경되어 인증이 진행됩니다.',
          ]
        : []),
      ...(!hasEU && hasGB
        ? [
            '영국 SCPN의 경우 영국 기준의 전성분 명칭으로 기재되어야 하기 때문에, WATER → AQUA,  FRAGRANCE → PARFUM 으로 자동 변경되어 인증이 진행됩니다.',
          ]
        : []),
      ...(hasEU && hasGB
        ? [
            '유럽연합 CPNP와 영국 SCPN의 경우 유럽연합 및 영국 기준의 전성분 명칭으로 기재되어야 하기 때문에, WATER → AQUA,  FRAGRANCE → PARFUM 으로 자동 변경되어 인증이 진행됩니다.',
          ]
        : []),
      ...(hasUS
        ? [
            <>
              미국{' '}
              <Typography.Text type="secondary" medium inline>
                VCRP
              </Typography.Text>
              의 경우 색소 성분은{' '}
              <Typography.Text type="secondary" medium inline>
                FDA
              </Typography.Text>{' '}
              고시 색소명으로 기재되어야 하기 때문에,{' '}
              <Typography.Text type="secondary" medium inline>
                INCI name
              </Typography.Text>
              이{' '}
              <Typography.Text type="secondary" medium inline>
                FDA
              </Typography.Text>{' '}
              고시 색소명으로 자동으로 변경되어 등록이 진행됩니다.
            </>,
            <>
              미국{' '}
              <Typography.Text type="secondary" medium inline>
                OTC drug
              </Typography.Text>{' '}
              등록의 경우 채널톡으로 별도 문의 부탁드리며, 국가별 성분 규제를
              확인하시고{' '}
              <Typography.Text type="secondary" medium inline>
                OTC drug
              </Typography.Text>
              에 해당하는지 확인해 주세요.
            </>,
            <>
              업로드 하시는 성분이 화장품 성분이 아닌 경우, "[
              <Typography.Text type="secondary" medium inline>
                INCI Name
              </Typography.Text>
              ] : 표준명으로 변경필요." 문구가 표시될 수 있습니다.
            </>,
          ]
        : []),
      <MistakeNoticeItem />,
    ],
    [hasEU, hasGB, hasUS],
  );

  return (
    <ProductPageTemplate
      subtitle="Product Formula Breakdown"
      notices={notices}
      exampleImg="Formula+Breakdown_V3.png"
      exampleImgWidth={920}
    >
      <ProductFormulaBreakdown isChina={false} />
    </ProductPageTemplate>
  );
};

export default ProductFormulaBreakdownPage;
