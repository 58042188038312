import styled from 'styled-components';

import ContractAccount from 'components/contract/ContractAccount';
import ContractAddress from 'components/contract/ContractAddress';
import ContractSendGuide from 'components/contract/ContractSendGuide';
import ContractDocuments from './ContractDocuments';

const ContractGuideBlock = styled.div``;

const ContractGuide = () => {
  return (
    <ContractGuideBlock>
      <ContractDocuments />
      <ContractAccount />
      <ContractAddress />
      <ContractSendGuide />
    </ContractGuideBlock>
  );
};

export default ContractGuide;
