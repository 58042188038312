import { Switch, Route, useParams } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { Suspense } from 'react';

import path from 'lib/path';
import FullLoading from 'components/FullLoading';
import ProductReadyPage from 'pages/product/ProductReadyPage';
import ProductBasicPage from 'pages/product/ProductBasicPage';
import ProductCountryPage from 'pages/product/ProductCountryPage';
import ProductCategoryPage from 'pages/product/ProductCategoryPage';
import ProductPhysicalPropertyPage from 'pages/product/ProductPhysicalPropertyPage';
import ProductChemicalPropertyPage from 'pages/product/ProductChemicalPropertyPage';
import ProductMicrobiologicalPropertyPage from 'pages/product/ProductMicrobiologicalPropertyPage';
import ProductArtworkPage from 'pages/product/ProductArtworkPage';
import ProductCGMPPage from 'pages/product/ProductCGMPPage';
import ProductStabilityTestPage from 'pages/product/ProductStabilityTestPage';
import ProductChallengeTestPage from 'pages/product/ProductChallengeTestPage';
import ProductPackingAttestationPage from 'pages/product/ProductPackingAttestationPage';
import ProductManufacturingProcessChinaPage from 'pages/product/ProductManufacturingProcessChinaPage';
import ProductFormulaBreakdownPage from 'pages/product/ProductFormulaBreakdownPage';
import ProductFormulaBreakdownChinaPage from 'pages/product/ProductFormulaBreakdownChinaPage';
import ProductIFRAPage from 'pages/product/ProductIFRAPage';
import ProductAllergensListPage from 'pages/product/ProductAllergensListPage';
import ProductThirdPartyTestReportPage from 'pages/product/ProductThirdPartyTestReportPage';
import ProductRawMaterialCoAPage from 'pages/product/ProductRawMaterialCoAPage';
import ProductRawMaterialMSDSPage from 'pages/product/ProductRawMaterialMSDSPage';
import ProductRegisteredPage from 'pages/product/ProductRegisteredPage';
import ProductDetailPage from 'pages/product/ProductDetailPage';
import Error404Page from 'pages/error/Error404Page';
import ProductCFSPage from 'pages/product/ProductCFSPage';
import ProductChinaCertificateTrademarkPage from 'pages/product/ProductChinaCertificateTrademarkPage';
import ProductInternalResponsibleCompanyPage from 'pages/product/ProductInternalResponsibleCompanyPage';
import ProductLogoPage from 'pages/product/ProductLogoPage';
import ProductCOBRMPage from 'pages/product/ProductCOBRMPage';
import ProductROPQMPage from 'pages/product/ProductROPQMPage';
import ProductImportPage from 'pages/product/ProductImportPage';
import { useManuMode } from 'hook/company';
import PrivateRoute from 'components/PrivateRoute';
import { messages } from 'lib/consts';
import ProductBuyersPage from 'pages/product/ProductBuyersPage';
import ProductSpecPage from 'pages/product/ProductSpecPage';
import ProductCountryVendorPage from 'pages/product/ProductCountryVendorPage';
import ProductKeepWarningPage from 'pages/product/ProductKeepWarningPage';
import ProductSeparateWastePage from 'pages/product/ProductSeparateWastePage';
import {
  useCountryId,
  useSetCurrentProduct,
  useSetCurrentProductDocStatus,
} from 'service/brand/product/product';
import { EDocumentCode } from 'types/product';
import ProductSaleInfoPage from 'pages/product/ProductSaleInfoPage';
import ProductFDACertificatedColorPage from 'pages/product/ProductFDACertificatedColorPage';
import ProductVCRPAccountPage from 'pages/product/ProductVCRPAccountPage';
import ProductVCRPAccountRequestPage from 'pages/product/ProductVCRPAccountRequestPage';

const ProductRouter = () => {
  const manuMode = useManuMode();
  const params = useParams<{
    productId?: string;
    documentCode?: EDocumentCode;
  }>();
  const productId = Number(params.productId) || undefined;
  const { documentCode } = params;
  const countryId = useCountryId();
  useSetCurrentProduct({ productId });
  useSetCurrentProductDocStatus({ productId, countryId, documentCode });
  return (
    <Switch>
      <PrivateRoute
        key={nanoid()}
        path={path.product.ready}
        component={ProductReadyPage}
        check={!manuMode}
        redirect={path.main}
        message={messages.NO_PERMISSION}
      />
      <PrivateRoute
        key={nanoid()}
        path={path.product.registered}
        component={ProductRegisteredPage}
        check={!manuMode}
        redirect={path.main}
        message={messages.NO_PERMISSION}
      />
      <PrivateRoute
        path={path.product.import}
        component={ProductImportPage}
        check={!manuMode}
        redirect={path.main}
        message={messages.NO_PERMISSION}
      />
      <Route
        path={[
          `/product/:productId/${EDocumentCode.BASIC}`,
          `/product/${EDocumentCode.BASIC}`,
        ]}
        component={ProductBasicPage}
      />
      <Route
        path={`/product/:productId/${EDocumentCode.COUNTRY}`}
        component={ProductCountryPage}
      />
      <Route
        path={`/product/:productId/${EDocumentCode.CAT}`}
        component={ProductCategoryPage}
      />
      <Route
        path={`/product/:productId/${EDocumentCode.PHY}`}
        component={ProductPhysicalPropertyPage}
      />
      <Route
        path={`/product/:productId/${EDocumentCode.CHE}`}
        component={ProductChemicalPropertyPage}
      />
      <Route
        path={`/product/:productId/${EDocumentCode.MIC}`}
        component={ProductMicrobiologicalPropertyPage}
      />
      <Route
        path={`/product/:productId/${EDocumentCode.FORMBR}`}
        component={ProductFormulaBreakdownPage}
      />
      <Route
        path={`/product/:productId/${EDocumentCode.FORMBRC}`}
        component={ProductFormulaBreakdownChinaPage}
      />
      <Route
        path={`/product/:productId/${EDocumentCode.ART}`}
        component={ProductArtworkPage}
      />
      <Route
        path={`/product/:productId/${EDocumentCode.CGMP}`}
        component={ProductCGMPPage}
      />
      <Route
        path={`/product/:productId/${EDocumentCode.STAB}`}
        component={ProductStabilityTestPage}
      />
      <Route
        path={`/product/:productId/${EDocumentCode.CHAL}`}
        component={ProductChallengeTestPage}
      />
      <Route
        path={`/product/:productId/${EDocumentCode.PACK}`}
        component={ProductPackingAttestationPage}
      />
      <Route
        path={`/product/:productId/${EDocumentCode.MANUC}`}
        component={ProductManufacturingProcessChinaPage}
      />
      <Route
        path={`/product/:productId/${EDocumentCode.ALLE}`}
        component={ProductAllergensListPage}
      />
      <Route
        path={`/product/:productId/${EDocumentCode.IFRA}`}
        component={ProductIFRAPage}
      />
      <Route
        path={`/product/:productId/${EDocumentCode.RMCOA}`}
        component={ProductRawMaterialCoAPage}
      />
      <Route
        path={`/product/:productId/${EDocumentCode.RMMSDS}`}
        component={ProductRawMaterialMSDSPage}
      />
      {/* 인증 단계에서 받는 추가 서류 */}
      <Route
        path={`/product/:productId/${EDocumentCode.BI}`}
        component={ProductBuyersPage}
      />
      <Route
        path={`/product/:productId/${EDocumentCode.THIRD}`}
        component={ProductThirdPartyTestReportPage}
      />
      <Route
        path={`/product/:productId/${EDocumentCode.CFS}`}
        component={ProductCFSPage}
      />
      <Route
        path={`/product/:productId/${EDocumentCode.PCC}`}
        component={ProductChinaCertificateTrademarkPage}
      />
      <Route
        path={`/product/:productId/${EDocumentCode.IRC}`}
        component={ProductInternalResponsibleCompanyPage}
      />
      <Route
        path={`/product/:productId/${EDocumentCode.LOGOAI}`}
        component={ProductLogoPage}
      />
      <Route
        path={`/product/:productId/${EDocumentCode.COBRM}`}
        component={ProductCOBRMPage}
      />
      <Route
        path={`/product/:productId/${EDocumentCode.ROPQM}`}
        component={ProductROPQMPage}
      />
      <Route
        path={`/product/:productId/${EDocumentCode.PS}`}
        component={ProductSpecPage}
      />
      <Route
        path={`/product/:productId/${EDocumentCode.CV}`}
        component={ProductCountryVendorPage}
      />
      <Route
        path={`/product/:productId/${EDocumentCode.KW}`}
        component={ProductKeepWarningPage}
      />
      <Route
        path={`/product/:productId/${EDocumentCode.SWI}`}
        component={ProductSeparateWastePage}
      />
      <Route
        path={`/product/:productId/${EDocumentCode.UPSI}`}
        component={ProductSaleInfoPage}
      />
      <Route
        path={`/product/:productId/${EDocumentCode.FCCAD}`}
        component={ProductFDACertificatedColorPage}
      />
      <Route
        path={`/product/:productId/${EDocumentCode.VCRPAR}`}
        component={ProductVCRPAccountPage}
      />
      <Route
        path={`/product/:productId/${EDocumentCode.VCRPAC}`}
        component={ProductVCRPAccountRequestPage}
      />
      <PrivateRoute
        path="/product/:productId"
        component={ProductDetailPage}
        check={!manuMode}
        redirect={path.main}
        message={messages.NO_PERMISSION}
      />
      <Route path={path.product.root} component={Error404Page} />
    </Switch>
  );
};

const SuspendedProductRouter = (props: any) => {
  return (
    <Suspense fallback={<FullLoading />}>
      <ProductRouter {...props} />
    </Suspense>
  );
};

export default SuspendedProductRouter;
