import CertificateComplete from 'components/certificate/CertificateComplete';
import PageTemplate from 'templates/PageTemplate';

const CertificateCompletePage = () => {
  return (
    <PageTemplate title="인증 완료 제품 관리" back={false}>
      <CertificateComplete />
    </PageTemplate>
  );
};

export default CertificateCompletePage;
