import { useState, useEffect } from 'react';
import { Transfer, Modal, Button, Row, Col } from 'antd';
import { useManufacturerCompanies } from 'service/manufacturer/company';

const DisclosureManufacturerModal = ({
  visible,
  onClose,
  manufacturerIds,
  onChange,
}: {
  visible: boolean;
  onClose: () => void;
  manufacturerIds: number[];
  onChange: (manufacturerIds: number[]) => void;
}) => {
  const { data: manufacturers = [] } = useManufacturerCompanies();
  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const handleClickSelectAll = () => {
    setSelectedKeys([]);
    setTargetKeys(
      manufacturers.map(({ manufacturerId }: any) => manufacturerId.toString()),
    );
  };
  const handleClickDeselectAll = () => {
    setSelectedKeys([]);
    setTargetKeys([]);
  };

  const handleClickSubmit = () => {
    onChange(targetKeys.map((key) => Number(key)));
    onClose();
  };

  useEffect(() => {
    if (visible) {
      setTargetKeys(manufacturerIds.map((id) => id.toString()));
    }
  }, [visible]);

  return (
    <Modal
      title="제조사 지정"
      visible={visible}
      width={800}
      footer={null}
      onCancel={onClose}
    >
      <Transfer
        style={{ width: '100%' }}
        listStyle={{ flex: '1 1 auto' }}
        showSearch
        titles={[
          <Button
            style={{ height: 32 }}
            type="link"
            onClick={handleClickSelectAll}
          >
            모두 선택
          </Button>,
          <Button
            style={{ height: 32 }}
            type="link"
            onClick={handleClickDeselectAll}
          >
            모두 선택 해제
          </Button>,
        ]}
        targetKeys={targetKeys}
        selectedKeys={selectedKeys}
        onChange={setTargetKeys}
        onSelectChange={(selectedKeys, targetKeys) =>
          setSelectedKeys([...selectedKeys, ...targetKeys])
        }
        dataSource={manufacturers}
        rowKey={({ manufacturerId }) => manufacturerId.toString()}
        render={(manufacturer) => manufacturer.companyNameKo}
        pagination
        operations={['공개', '비공개']}
      />
      <Row justify="center" style={{ marginTop: 24 }}>
        <Col>
          <Button type="primary" onClick={handleClickSubmit}>
            선택 완료
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default DisclosureManufacturerModal;
