import RawMaterialCertification from 'components/material/certification/RawMaterialCertification';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import RawMaterialPageTemplate from 'templates/material/RawMaterialPageTemplate';

const RawMaterialCertificationPage = () => {
  const notices = [
    '원료의 인증 정보 및 그 외 특이사항을 선택합니다. 항목 선택 시 관련 증빙자료에 대한 업로드 하실 수 있습니다.',
    '원료 최초 등록시 서류를 보유하지 않으셨다면 ‘추후 서류 업로드’ 체크 후 등록하실 수 있습니다. (단, 14일 이내 서류를 업로드해 주시기 바랍니다.)',
    '서류의 유효기간이 있는 경우에는 유효기간을 입력해 주세요.',
    <MistakeNoticeItem />,
  ];
  return (
    <RawMaterialPageTemplate documentName="인증 및 특이사항" notices={notices}>
      <RawMaterialCertification />
    </RawMaterialPageTemplate>
  );
};

export default RawMaterialCertificationPage;
