import MistakeNoticeItem from 'components/MistakeNoticeItem';
import ProductThirdPartyTestReportContainer from 'containers/product/ProductThirdPartyTestReportContainer';
import { breakpoint } from 'lib/styles';
import ProductPageTemplate from 'templates/ProductPageTemplate';

const notices = [
  'Third Party Test Report : 화장품 표시ㆍ광고 실증자료 (Marketing Claim)',
  '화장품제조업자나 화장품책임판매업자 외의 공인된 시험업체에서 테스트 받은 성적서가 있다면 영문레포트를 PDF로 올려주세요.',
  '국문 테스트 레포트의 경우는 전문 번역사 (번역사 이력 필요)에 의해 번역된 자료만 인정될 수 있습니다.',
  '예시) 첩포테스트, 수분도측정테스트, 여드름사용적합성테스트, 임상시험 자료, 기타 효능효과 증빙 자료, 영문 논문 자료(abstract 포함/해당 효능효과 내용에 하이라이트 된), 특허증 등',
  <MistakeNoticeItem />,
];

const ProductThirdPartyTestReportPage = () => {
  return (
    <ProductPageTemplate
      subtitle="Third Party Test Report"
      notices={notices}
      exampleImg={'Third_Party_Test_Report_V2.jpg'}
      exampleImgWidth={breakpoint.md}
    >
      <ProductThirdPartyTestReportContainer />
    </ProductPageTemplate>
  );
};

export default ProductThirdPartyTestReportPage;
