import styled from 'styled-components';
import {
  Button,
  Col,
  Form,
  Input,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
} from 'antd';
import { useEffect } from 'react';

import { scrollToFirstErrorOption } from 'lib/consts';
import { useForceUpdate } from 'lib/hook';
import palette from 'lib/styles/palette';
import {
  alphabetOrSpaceRule,
  normalizeDate,
  requireRule,
  capitalize,
  noFutureDateRule,
  checkIsPastDate,
  exceptKoreanRule,
  noPastDateRule,
  checkIsFutureDate,
} from 'lib/validate';
import { useCertificateMode } from 'hook/certificate';
import { colorOptions, formOptions, odourOptions } from 'lib/selectOption';
import FooterBox from 'components/FooterBox';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import DateInput from 'components/system/form/DateInput';
import { Tip, Typography } from 'components/system';
import PHForm from './PHForm';
import SpecificGravityForm from './SpecificGravityForm';
import ViscosityForm from './ViscosityForm';
import HardnessForm from './HardnessForm';
import CorrectRequestMessageModal from 'components/CorrectRequestMessageModal';
import UpdateLog from 'components/product/UpdateLog';
import { usePhysicalProperties } from 'service/brand/product/physicalProperties';
import { useProductSample } from 'service/manufacturer/productSample';
import { EDocumentCode } from 'types/product';

const ProductPhysicalPropertyBlock = styled.div`
  width: 520px;
  margin: 0 auto;

  .ant-radio-wrapper {
    line-height: 0px;
  }
`;

const TypeOfProductForm = styled(Form.Item)`
  .ant-form-item-control-input {
    min-height: 0px;
  }
  .ant-radio-wrapper:first-child {
    margin-right: 36px;
  }
`;

const RadioBlock = styled.div`
  padding: 20px 24px;
  background-color: ${palette.gray};
  border-radius: 8px;
  height: 56px;
`;

const TypeOfProductRadio = ({
  value,
  onChange,
  readOnlyMode,
}: {
  value?: boolean;
  onChange?: (e: RadioChangeEvent) => void;
  readOnlyMode: boolean;
}) => {
  return (
    <RadioBlock>
      <Radio.Group value={value} onChange={onChange} disabled={readOnlyMode}>
        <Row justify="space-around" style={{ flexBasis: '100%' }}>
          <Col style={{ textAlign: 'center' }}>
            <Radio
              value="LEAVE_ON"
              style={{ color: palette.darkNavy, fontWeight: 500 }}
            >
              Leave-On
            </Radio>
          </Col>
          <Col style={{ textAlign: 'center' }}>
            <Radio
              value="RINSE_OFF"
              style={{ color: palette.darkNavy, fontWeight: 500 }}
            >
              Rinse-Off
            </Radio>
          </Col>
        </Row>
      </Radio.Group>
    </RadioBlock>
  );
};

const directInputSelectNames = ['form', 'color', 'odour', 'netWeightUnit'];

const ProductPhysicalProperty = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId?: number;
}) => {
  const forceUpdate = useForceUpdate();
  const certificateMode = useCertificateMode();
  const {
    form,
    currentProduct,
    fetchLoading,
    updateMode,
    readOnlyMode,
    handleChangePhRadioGroup,
    handleChangeSpecificGravityRadioGroup,
    handleChangeViscosityRadioGroup,
    handleChangeHardnessRadioGroup,
    onSubmit,
  } = usePhysicalProperties(productId, countryId);
  const { productSample } = useProductSample(
    currentProduct.productSampleId || undefined,
  );

  useEffect(() => {
    if (!updateMode && productSample) {
      form.setFieldsValue({ productType: productSample.productType });
    }
  }, [updateMode, productSample]);
  return (
    <>
      <Row justify="end" gutter={8}>
        <Col>
          <CorrectRequestMessageModal documentCode={EDocumentCode.PHY} />
        </Col>
        {updateMode && productId && (
          <Col>
            <UpdateLog
              productId={productId}
              countryId={countryId}
              documentCode={EDocumentCode.PHY}
            />
          </Col>
        )}
      </Row>
      <ProductPhysicalPropertyBlock>
        <Form
          form={form}
          colon={false}
          layout="vertical"
          scrollToFirstError={scrollToFirstErrorOption}
          style={{ margin: '20px 0' }}
          onFinish={onSubmit}
          onValuesChange={(changedValues) => {
            if (
              Object.keys(changedValues).filter((key) =>
                directInputSelectNames.includes(key),
              ).length
            ) {
              forceUpdate();
            }
          }}
        >
          <TypeOfProductForm
            label="Type of Product"
            required
            name="productType"
            rules={[requireRule]}
          >
            <TypeOfProductRadio readOnlyMode={readOnlyMode} />
          </TypeOfProductForm>
          <Form.Item
            label="해당하는 항목 선택"
            required
            name="aerosolOrEthanol"
            rules={[requireRule]}
          >
            <Radio.Group disabled={readOnlyMode}>
              <Radio.Button value="aerosol">에어로졸 제품</Radio.Button>
              <Radio.Button value="ethanol">
                에탄올이 10% 이상 함유
              </Radio.Button>
              <Radio.Button value="nothing">둘 다 해당 없음</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Appearance - Form" required>
            <Row gutter={8} wrap={false}>
              <Col flex="auto">
                <Form.Item
                  noStyle
                  name={
                    form.getFieldValue('form') !== 'others'
                      ? 'form'
                      : 'formDirect'
                  }
                  rules={[requireRule, alphabetOrSpaceRule]}
                  normalize={capitalize}
                >
                  <Input
                    maxLength={30}
                    disabled={
                      form.getFieldValue('form') !== 'others' || readOnlyMode
                    }
                    style={
                      !readOnlyMode
                        ? {
                            color: palette.text.black,
                            backgroundColor: '#fff',
                          }
                        : undefined
                    }
                  />
                </Form.Item>
              </Col>
              <Col flex="0 0 210px">
                <Form.Item
                  noStyle
                  initialValue="others"
                  name="form"
                  rules={[requireRule]}
                >
                  <Select disabled={readOnlyMode}>
                    <Select.Option key="others" value="others">
                      직접입력
                    </Select.Option>
                    {formOptions.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.text}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item label="Appearance - Color" required>
            <Row gutter={8} wrap={false}>
              <Col flex="auto">
                <Form.Item
                  noStyle
                  name={
                    form.getFieldValue('color') !== 'others'
                      ? 'color'
                      : 'colorDirect'
                  }
                  rules={[requireRule, alphabetOrSpaceRule]}
                  normalize={capitalize}
                >
                  <Input
                    maxLength={30}
                    disabled={
                      form.getFieldValue('color') !== 'others' || readOnlyMode
                    }
                    style={
                      !readOnlyMode
                        ? {
                            color: palette.text.black,
                            backgroundColor: '#fff',
                          }
                        : undefined
                    }
                  />
                </Form.Item>
              </Col>
              <Col flex="0 0 210px">
                <Form.Item
                  noStyle
                  name="color"
                  initialValue="others"
                  rules={[requireRule]}
                >
                  <Select disabled={readOnlyMode}>
                    <Select.Option key="others" value="others">
                      직접입력
                    </Select.Option>
                    {colorOptions.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.text}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item label="Odour" required>
            <Row gutter={8} wrap={false}>
              <Col flex="auto">
                <Form.Item
                  noStyle
                  name={
                    form.getFieldValue('odour') !== 'others'
                      ? 'odour'
                      : 'odourDirect'
                  }
                  rules={[requireRule, alphabetOrSpaceRule]}
                  normalize={capitalize}
                >
                  <Input
                    maxLength={30}
                    disabled={
                      form.getFieldValue('odour') !== 'others' || readOnlyMode
                    }
                    style={
                      !readOnlyMode
                        ? {
                            color: palette.text.black,
                            backgroundColor: '#fff',
                          }
                        : undefined
                    }
                  />
                </Form.Item>
              </Col>
              <Col flex="0 0 210px">
                <Form.Item
                  noStyle
                  initialValue="others"
                  name="odour"
                  rules={[requireRule]}
                >
                  <Select disabled={readOnlyMode}>
                    <Select.Option key="others" value="others">
                      직접입력
                    </Select.Option>
                    {odourOptions.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.text}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            label={
              <>
                Net weight or Net volume (용량)
                <Tip style={{ marginLeft: 8 }} trigger="click">
                  <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                    용량은 기본정보에서 수정하실 수 있습니다.
                  </Typography.Text>
                </Tip>
              </>
            }
            required
          >
            <Row gutter={8}>
              <Col flex="1 1 50%">
                <Form.Item noStyle>
                  <Input disabled value={currentProduct?.netWeight} />
                </Form.Item>
              </Col>
              <Col flex="1 1 50%">
                <Form.Item noStyle>
                  <Input disabled value={currentProduct?.netWeightUnit} />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            label="Lot No. (Batch number)"
            name="lotNo"
            required
            rules={[requireRule, exceptKoreanRule]}
          >
            <Input
              maxLength={10}
              placeholder="유통화장품법 기준으로 테스트를 완료한 제품의 Lot No."
              disabled={readOnlyMode}
            />
          </Form.Item>
          <Form.Item
            label="Manufacturing Date"
            name="manufacturingDate"
            required
            normalize={normalizeDate}
            rules={[requireRule, noFutureDateRule]}
          >
            <DateInput
              disabled={readOnlyMode}
              checkIsValidDate={checkIsPastDate}
            />
          </Form.Item>
          <Form.Item
            label="Expiry Date"
            name="expiryDate"
            required
            normalize={normalizeDate}
            rules={[requireRule, noPastDateRule]}
          >
            <DateInput
              disabled={readOnlyMode}
              checkIsValidDate={checkIsFutureDate}
            />
          </Form.Item>
          <PHForm
            readOnlyMode={readOnlyMode}
            onChangePhRadioGroup={handleChangePhRadioGroup}
          />
          <SpecificGravityForm
            readOnlyMode={readOnlyMode}
            onChangeSpecificGravityRadioGroup={
              handleChangeSpecificGravityRadioGroup
            }
          />
          <ViscosityForm
            readOnlyMode={readOnlyMode}
            onChangeViscosityRadioGroup={handleChangeViscosityRadioGroup}
          />
          <HardnessForm
            readOnlyMode={readOnlyMode}
            onChangeHardnessRadioGroup={handleChangeHardnessRadioGroup}
          />
          <FooterBox>
            <ReadOnlyBackButton readOnly={readOnlyMode}>
              <Button type="primary" loading={fetchLoading} htmlType="submit">
                {!updateMode ? '등록' : !certificateMode ? '수정' : '보완 완료'}
              </Button>
            </ReadOnlyBackButton>
          </FooterBox>
        </Form>
      </ProductPhysicalPropertyBlock>
    </>
  );
};

export default ProductPhysicalProperty;
