import styled from 'styled-components';
import { Tabs } from 'antd';

import palette from 'lib/styles/palette';
import EstimateListContainer from 'containers/estimate/EstimateListContainer';
import ContractGuide from 'components/contract/ContractGuide';
import EstimateList from 'components/brand/clinicalTrial/estimate/EstimateList';

const ContractsPageBlock = styled.div``;

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 36px;

    .ant-tabs-tab {
      width: 160px;
      justify-content: center;
      margin-right: 0;

      .ant-tabs-tab-btn {
        font-size: 16px;
        letter-spacing: -1px;
        color: ${palette.primary};
        font-weight: 400;
      }
    }
  }
  .ant-tabs-ink-bar {
    height: 4px !important;
  }
`;

const ContractsPage = () => {
  return (
    <ContractsPageBlock>
      <StyledTabs>
        <Tabs.TabPane key="estimate-list" tab="인증 대행 견적서">
          <EstimateListContainer />
        </Tabs.TabPane>
        <Tabs.TabPane key="clinicalTrial-estimate-list" tab="임상시험 견적서">
          <EstimateList />
        </Tabs.TabPane>
        <Tabs.TabPane key="contract-guide" tab="결제 / 계약서 송부 가이드">
          <ContractGuide />
        </Tabs.TabPane>
      </StyledTabs>
    </ContractsPageBlock>
  );
};

export default ContractsPage;
