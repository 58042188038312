import ProductSampleDetail from 'components/manufacturer/productSample/ProductSampleDetail';
import { useProductSampleId } from 'service/manufacturer/productSample';
import PageTemplate from 'templates/PageTemplate';

const ProductSampleDetailPage = () => {
  const notices = [
    '각 입력 정보는 샘플 확정 전까지 자유롭게 입력하실 수 있습니다. (샘플 확정 처리 전까지 각 입력 정보는 필수 사항이 아닙니다.)',
    '선택 항목인 ‘처방’을 제외하고, 나머지 항목의 처리 현황이 ‘입력 완료’인 상태일 때 샘플 확정을 진행하실 수 있습니다.',
    '확정된 샘플은 수정이 불가하니, 완제품 기준의 확정된 데이터를 입력해 주세요.',
  ];
  const productSampleId = useProductSampleId();
  return (
    <PageTemplate title="샘플 입력 정보" notices={notices}>
      <ProductSampleDetail productSampleId={productSampleId!} />
    </PageTemplate>
  );
};

export default ProductSampleDetailPage;
