import qs from 'qs';

import client from 'lib/api/client';
import { IAPIPageableResponse, IAPIResponse } from 'types/common';
import {
  IProductSampleBasicAdd,
  IProductSampleBasicGet,
  IProductSampleBasicUpdate,
  IProductSampleRecipe,
  IProductSampleRecipeAdd,
  IProductSampleRecipeDetail,
  IProductSampleRecipeDetailAdd,
  IProductSampleRecipeDetailDraft,
  IProductSampleRecipeDraft,
  IProductSampleRecipeUpdate,
  IProductSampleProcessMapItemForm,
  IProductSampleProcessMapItemGet,
  IProductSampleProcessMapItemUpdate,
  IProductSample,
  IProductSampleSearchParams,
  IProductSampleDocument,
  IUsableProductSample,
} from 'types/manufacturer/productSample';

export const checkProductSampleIsDuplicated = (labNo: string) =>
  client.get<IAPIResponse<{ isDuplicated: boolean }>>(
    `/manufacturer/product-sample/duplication?labNo=${labNo}`,
  );

export const getProductSamples = ({
  page,
  searchType,
  ...restParams
}: IProductSampleSearchParams) =>
  client.get<IAPIPageableResponse<IProductSample[]>>(
    `/manufacturer/product-samples?${qs.stringify({
      pageNo: page,
      searchKey: searchType,
      ...restParams,
    })}`,
  );

export const confirmProductSamples = (productSampleIds: number[]) =>
  client.patch('/manufacturer/product-samples/confirmation', {
    productSampleIds,
  });

export const getProductSampleDocuments = (productSampleId: number) =>
  client.get<IAPIResponse<IProductSampleDocument[]>>(
    `/manufacturer/product-samples/${productSampleId}/pre-list`,
  );

export const addProductSample = (productSample: IProductSampleBasicAdd) =>
  client.post<IAPIResponse<IProductSampleBasicGet>>(
    '/manufacturer/product-samples',
    productSample,
  );

export const getProductSample = (productSampleId: number) =>
  client.get<IAPIResponse<IProductSampleBasicGet>>(
    `/manufacturer/product-samples/${productSampleId}`,
  );

export const updateProductSample = ({
  productSampleId,
  ...rest
}: IProductSampleBasicUpdate) =>
  client.patch(`/manufacturer/product-samples/${productSampleId}`, rest);

export const validateProductSampleRecipe = (file: File) =>
  client.post<IAPIResponse<IProductSampleRecipeDraft>>(
    '/manufacturer/product-sample/recipe/excel-validation',
    { file },
  );

export const addProductSampleRecipe = (
  productSampleId: number,
  productSampleRecipe: IProductSampleRecipeAdd,
) =>
  client.post(
    `/manufacturer/product-samples/${productSampleId}/recipe`,
    productSampleRecipe,
  );

export const getProductSampleRecipes = (productSampleId: number) =>
  client.get<IAPIResponse<IProductSampleRecipe[]>>(
    `/manufacturer/product-samples/${productSampleId}/recipes`,
  );

export const updateProductSampleRecipe = (
  productSampleId: number,
  { productSampleRecipeId, memo }: IProductSampleRecipeUpdate,
) =>
  client.patch(
    `/manufacturer/product-samples/${productSampleId}/recipes/${productSampleRecipeId}`,
    { memo },
  );

export const validateProductSampleRecipeDetail = (file: File) =>
  client.post<IAPIResponse<IProductSampleRecipeDetailDraft>>(
    '/manufacturer/product-sample/recipe-detail/excel-validation',
    { file },
  );

export const addProductSampleRecipeDetail = ({
  productSampleId,
  file,
}: IProductSampleRecipeDetailAdd) =>
  client.post<IAPIResponse<IProductSampleRecipeDetailDraft>>(
    `/manufacturer/product-samples/${productSampleId}/recipe-detail`,
    { file },
  );

export const getProductSampleRecipeDetail = (productSampleId: number) =>
  client.get<IAPIResponse<IProductSampleRecipeDetail>>(
    `/manufacturer/product-samples/${productSampleId}/recipe-detail`,
  );
export const getProductSampleProcessMapPhases = (productSampleId: number) =>
  client.get<IAPIResponse<string[]>>(
    `/manufacturer/product-samples/${productSampleId}/process-map/phases`,
  );

export const getProductSampleProcessMap = (productSampleId: number) =>
  client.get<
    IAPIResponse<{ processMapItems: IProductSampleProcessMapItemGet[] }>
  >(`/manufacturer/product-samples/${productSampleId}/process-map`);

export const addProductSampleProcessMap = ({
  productSampleId,
  processMapItems,
}: {
  productSampleId: number;
  processMapItems: IProductSampleProcessMapItemForm[];
}) =>
  client.post(`/manufacturer/product-samples/${productSampleId}/process-map`, {
    processMapItems,
  });

export const updateProductSampleProcessMap = ({
  productSampleId,
  processMapItems,
}: {
  productSampleId: number;
  processMapItems: IProductSampleProcessMapItemUpdate[];
}) =>
  client.patch(`/manufacturer/product-samples/${productSampleId}/process-map`, {
    processMapItems,
  });

export const publishProcessMapPreview = ({
  productSampleId,
  processMapItems,
}: {
  productSampleId: number;
  processMapItems: IProductSampleProcessMapItemForm[];
}) =>
  client.post(
    `/manufacturer/product-samples/${productSampleId}/process-map/preview`,
    {
      processMapItems,
    },
  );

export const getUsableProductSamples = () =>
  client.get<IAPIResponse<IUsableProductSample[]>>(
    '/manufacturer/product-samples/usable-targets',
  );
