import styled from 'styled-components';
import { Button, Col, Form, Input, InputNumber, Row, Select } from 'antd';

import { messages } from 'lib/consts';
import {
  exceptKoreanRule,
  numbersRule,
  phoneRule,
  requireRule,
} from 'lib/validate';
import FooterBox from 'components/FooterBox';
import { Tip, Typography } from 'components/system';
import { netWeightUnitOptions } from 'lib/selectOption';
import { parseWeightUnit } from 'lib/form';

const ProductBasicFormBlock = styled.div`
  max-width: 520px;
  margin: 0 auto;
`;

const ProductBasicForm = ({
  updateMode = false,
  form,
  manufacturers,
  isManufacturer = false,
  onFormFinished,
  fetchLoading,
}: any) => {
  return (
    <ProductBasicFormBlock>
      <Form
        form={form}
        colon={false}
        onFinish={onFormFinished}
        layout="vertical"
        style={{ marginTop: 4 }}
      >
        <Form.Item
          label="브랜드명 (영문)"
          name="brandNameEn"
          rules={[requireRule, exceptKoreanRule]}
        >
          <Input />
        </Form.Item>
        <Row gutter={8} align="bottom">
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              label={
                <>
                  제품명 (국문/영문){' '}
                  <Tip style={{ marginLeft: 8 }} trigger="click">
                    <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                      제품명에 브랜드명이 포함되길 원하는 경우
                    </Typography.Text>
                    <Typography.Text type="secondary" style={{ fontSize: 10 }}>
                      예시 ① 브랜드명 <span className="primary">30COS</span>{' '}
                      제품명 <span className="primary">soothing cream</span> 인
                      경우 → <span className="primary">'soothing cream'</span>{' '}
                      으로 인증
                    </Typography.Text>
                    <Typography.Text type="secondary" style={{ fontSize: 10 }}>
                      예시 ② 브랜드명 <span className="primary">30COS</span>{' '}
                      제품명{' '}
                      <span className="primary">30COS soothing cream</span> 인
                      경우 →{' '}
                      <span className="primary">'30COS soothing cream'</span>{' '}
                      으로 인증
                    </Typography.Text>
                  </Tip>
                </>
              }
              name="productNameKo"
              rules={[requireRule]}
            >
              <Input placeholder="국문" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              name="productNameEn"
              rules={[
                requireRule,
                exceptKoreanRule,
                {
                  type: 'string',
                  max: 100,
                  message: '최대 100자 입력',
                },
              ]}
            >
              <Input placeholder="영문 (인증서에 등록될 영문 입력)" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="용량"
          required
          shouldUpdate={(prev, next) =>
            prev.netWeightUnit !== next.netWeightUnit
          }
          style={{ marginBottom: 0 }}
        >
          {({ getFieldValue, setFieldsValue }) => {
            return (
              <Row gutter={8}>
                <Col>
                  <Form.Item
                    name="netWeight"
                    rules={[
                      requireRule,
                      numbersRule,
                      {
                        type: 'number',
                        min: 0,
                        message: '숫자만 입력 가능, 1 ~ 10 글자',
                      },
                    ]}
                  >
                    <InputNumber
                      className="hide-handler"
                      style={{ width: '168px' }}
                      placeholder="숫자 입력"
                      maxLength={10}
                      keyboard={false}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="netWeightUnit" rules={[requireRule]}>
                    <Select
                      style={{ width: '168px' }}
                      placeholder="단위 선택"
                      onSelect={() =>
                        setFieldsValue({ netWeightUnitDirect: null })
                      }
                    >
                      {netWeightUnitOptions.map((option) => (
                        <Select.Option key={option.value} value={option.value}>
                          {option.text}
                        </Select.Option>
                      ))}
                      <Select.Option key="others" value="others">
                        직접입력
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                {getFieldValue('netWeightUnit') === 'others' && (
                  <Col>
                    <Form.Item name="netWeightUnitDirect" rules={[requireRule]}>
                      <Input
                        autoFocus
                        placeholder="단위 직접 입력"
                        style={{ width: 168 }}
                        onBlur={(e) => {
                          const netWeightUnit = parseWeightUnit(e.target.value);
                          if (netWeightUnit) {
                            setFieldsValue({
                              netWeightUnit,
                              netWeightUnitDirect: null,
                            });
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>
            );
          }}
        </Form.Item>
        <Row gutter={8} align="bottom">
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              label={
                <>
                  화장품제조업자 (국문/영문){' '}
                  <Tip style={{ marginLeft: 8 }} trigger="click">
                    <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                      국문 예시 : <span className="primary">(주)30코스</span>
                    </Typography.Text>
                    <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                      영문 예시 :{' '}
                      <span className="primary">30COS Co., Ltd.</span> (cGMP
                      또는 ISO 22716에 등록된 영문과 동일하게 입력)
                    </Typography.Text>
                  </Tip>
                </>
              }
              name="manufacturerId"
              rules={[requireRule]}
            >
              <Select
                placeholder="국문"
                showSearch
                filterOption={(keyword, option) =>
                  (option?.label &&
                    typeof option.label === 'string' &&
                    option.label.includes(keyword)) ||
                  false
                }
                disabled={isManufacturer}
                options={manufacturers.map(
                  ({
                    manufacturerId,
                    companyNameKo,
                  }: {
                    manufacturerId: number;
                    companyNameKo: string;
                  }) => ({
                    label: companyNameKo,
                    value: manufacturerId,
                  }),
                )}
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              name="manufacturerNameEn"
              required
              rules={[requireRule, exceptKoreanRule]}
            >
              <Input placeholder="cGMP 또는 ISO22716 보유시 같은 영문" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label={
            <>
              화장품제조업자 주소 (영문){' '}
              <Tip style={{ marginLeft: 8 }} trigger="click">
                <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                  예시 :{' '}
                  <span className="primary">
                    A-13, 3F SB plaza, 194-25, Osongsaengmyeong 1-ro, Osong-eup,
                    <br />
                    Heungdeok-gu, Cheongju-si, Chungcheongbuk-do, Republic of
                    Korea
                  </span>
                </Typography.Text>
                <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                  (cGMP 또는 ISO 22716에 등록된 영문과 동일하게 입력)
                </Typography.Text>
              </Tip>
            </>
          }
          name="manufacturerAddressEn"
          required
          rules={[requireRule, exceptKoreanRule]}
        >
          <Input placeholder="영문" />
        </Form.Item>
        <Row gutter={8} align="bottom">
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              label="화장품제조업자 전화번호"
              name="manufacturerTel"
              required
              rules={[requireRule, phoneRule]}
            >
              <Input placeholder={messages.PHONE_FORMAT} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              label="화장품제조업자 팩스번호"
              name="manufacturerFax"
              required
              rules={[requireRule, phoneRule]}
            >
              <Input placeholder={messages.PHONE_FORMAT} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8} align="bottom">
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              label="화장품책임판매업자 (국문)"
              name="brandCompanyNameKo"
              required
              rules={[requireRule]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              label="화장품책임판매업자 (영문)"
              name="brandCompanyNameEn"
              required
              rules={[requireRule, exceptKoreanRule]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="화장품책임판매업자 주소 (국문)"
          name="brandCompanyAddressKo"
          required
          rules={[requireRule]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="화장품책임판매업자 주소 (영문)"
          name="brandCompanyAddressEn"
          required
          rules={[requireRule, exceptKoreanRule]}
        >
          <Input disabled />
        </Form.Item>
        <FooterBox>
          <Button type="primary" htmlType="submit" loading={fetchLoading}>
            {!updateMode ? '다음' : '수정'}
          </Button>
        </FooterBox>
      </Form>
    </ProductBasicFormBlock>
  );
};

export default ProductBasicForm;
