import { Button, Col, Empty, List, Row, Table } from 'antd';
import palette from 'lib/styles/palette';
import { useMemo } from 'react';
import styled, { css } from 'styled-components';
import SearchableList from 'components/SearchableList';
import { Link } from 'react-router-dom';
import path from 'lib/path';
import { Tip, Typography } from 'components/system';
import { ColumnsType } from 'antd/lib/table';

const EstimatePickBlock = styled.div`
  margin-top: 20px;
`;
const ProductItem = styled(List.Item)<{ selected: boolean }>`
  cursor: pointer;
  background-color: ${(props) =>
    props.selected ? palette.primaryLight : '#fff'};
  border: none;
  border-bottom: 1px solid #d3d3d335 !important;
`;
const CountryItem = styled(List.Item)<{ selected: boolean }>`
  cursor: pointer;
  border: none;
  border-bottom: 1px solid #d3d3d335 !important;

  ${(props) =>
    props.selected
      ? css`
          i.material-icons {
            color: ${palette.primary};
            -webkit-text-stroke: 1px ${palette.primary};
          }
        `
      : css`
          i.material-icons {
            color: #d3d3d3;
          }
        `}
`;
const AddButton = styled(Button)`
  display: block;
  margin: 32px auto 8px;
`;

const EstimatePick = ({
  estimateTargets,
  selectedEstimateKeys,
  products,
  selectedProduct,
  countries,
  selectedCountries,
  getProductsLoading,
  getEstimateTargetsLoading,
  publishLoading,
  deleteLoading,
  addLoading,
  onSelectProduct,
  onSelectCountry,
  onSelectAllCountries,
  onAddCountries,
  onChangeEstimateKeys,
  onDeleteEstimateTargets,
  onPublishEstimate,
  onClickChecklist,
}: any) => {
  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: '제품명 (용량)',
        width: 400,
        render: ({
          estimateTargetId,
          productDetails,
          netWeight,
          netWeightUnit,
        }) => (
          <Typography.Text
            type="secondary"
            style={{
              color: '#222',
              fontWeight: 500,
              cursor: 'pointer',
            }}
            onClick={() => {
              if (selectedEstimateKeys.includes(estimateTargetId)) {
                onChangeEstimateKeys(
                  selectedEstimateKeys.filter(
                    (key: number) => key !== estimateTargetId,
                  ),
                );
              } else {
                onChangeEstimateKeys(
                  selectedEstimateKeys.concat(estimateTargetId),
                );
              }
            }}
          >
            {`${productDetails[0].productNameEn} ${
              netWeight ? `(${netWeight} ${netWeightUnit})` : '( - )'
            }`}
          </Typography.Text>
        ),
      },
      {
        title: '국가',
        width: 240,
        align: 'center',
        render: ({ countryName }) => (
          <Typography.Text type="secondary" color="gray">
            {countryName}
          </Typography.Text>
        ),
      },
      {
        title: '체크리스트',
        align: 'center',
        width: 184,
        render: (estimateTarget) =>
          estimateTarget.status !== 'ONG' ? (
            <Typography.Label
              type={estimateTarget.status === 'INI' ? 'primary' : 'disabled'}
              onClick={() => onClickChecklist(estimateTarget)}
            >
              {estimateTarget.status === 'INI' ? '작성하기' : '작성완료'}
              <i className="material-icons outlined md-12">chevron_right</i>
            </Typography.Label>
          ) : (
            <span>-</span>
          ),
      },
    ],
    [selectedEstimateKeys, onChangeEstimateKeys],
  );
  const handleClickPublishEstimate = () => {
    if (selectedEstimateKeys.length > 0) {
      onPublishEstimate();
    }
  };
  return (
    <EstimatePickBlock>
      <Row gutter={[16, 32]}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
          <SearchableList
            title="제품 선택"
            placeholder="제품명 입력"
            filterList={({ productDetail: { productNameEn } }, keyword) =>
              productNameEn.includes(keyword)
            }
            list={products}
            loading={getProductsLoading}
            renderItem={(item) => (
              <ProductItem
                selected={item === selectedProduct}
                onClick={() => onSelectProduct(item)}
              >
                {`${item.productDetail.productNameEn} (${item.netWeight} ${item.netWeightUnit})`}
              </ProductItem>
            )}
            emptyText={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <>
                    등록 완료된 제품이 없습니다.
                    <br />
                    먼저 제품을 등록해 주세요.
                  </>
                }
              />
            }
          />
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
          <SearchableList
            title="국가 선택"
            placeholder="국가 입력"
            list={countries}
            top={selectedProduct}
            filterList={({ country: { countryNameKo } }, keyword) =>
              countryNameKo.includes(keyword)
            }
            action={
              <Typography.Text
                type="secondary"
                style={{ color: palette.primary, cursor: 'pointer' }}
                onClick={onSelectAllCountries}
              >
                전체 선택
              </Typography.Text>
            }
            emptyText={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  selectedProduct ? (
                    <>
                      이미 모든 국가가 추가 되었습니다.
                      <br />
                      아래 목록에 없을 경우{' '}
                      <Link
                        to={path.certificate.ongoing}
                        style={{ textDecoration: 'underline' }}
                      >
                        인증 진행
                      </Link>{' '}
                      또는{' '}
                      <Link
                        to={path.certificate.complete}
                        style={{ textDecoration: 'underline' }}
                      >
                        인증 완료
                      </Link>{' '}
                      상태일 수 있습니다.
                    </>
                  ) : (
                    '제품을 선택해 주세요.'
                  )
                }
              />
            }
            renderItem={(item) => (
              <CountryItem
                selected={selectedCountries.includes(item)}
                onClick={() => onSelectCountry(item)}
              >
                <Row align="middle" style={{ width: '100%' }}>
                  <Col style={{ marginRight: 12 }}>
                    <i className={`material-icons md-18`}>done</i>
                  </Col>
                  <Col style={{ marginRight: 4 }}>
                    <img
                      src={`https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/flag/${item.country.countryCode.toLowerCase()}.png`}
                      alt=""
                      style={{ width: '20px', marginRight: '0px' }}
                    />
                  </Col>
                  <Col>{item.country.countryNameKo}</Col>
                </Row>
              </CountryItem>
            )}
          />
        </Col>
      </Row>
      <AddButton
        loading={addLoading}
        icon={<i className="material-icons md-18">add</i>}
        onClick={onAddCountries}
      >
        셀프 견적 리스트에 추가
      </AddButton>
      <Row align="middle" gutter={8}>
        <Col>
          <Typography.Title>셀프 견적 리스트</Typography.Title>
        </Col>
        <Col>
          <Tip trigger="click" bodyStyle={{ padding: '12px 24px' }}>
            <Typography.Text type="tertiary">
              위의 ‘셀프 견적 리스트에 추가’ 버튼으로 제품을
              <br />
              계속 추가하실 수 있습니다.
            </Typography.Text>
          </Tip>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={estimateTargets}
        rowKey={({ estimateTargetId }) => estimateTargetId}
        pagination={false}
        rowSelection={{
          columnWidth: 60,
          selectedRowKeys: selectedEstimateKeys,
          onChange: onChangeEstimateKeys,
        }}
        loading={getEstimateTargetsLoading}
        style={{ marginTop: 16 }}
      />
      <Row justify="end" gutter={[8, 8]} style={{ marginTop: 24 }}>
        <Col>
          <Button
            loading={deleteLoading}
            type="default"
            icon={
              <i className="material-icons outlined primary md-18">delete</i>
            }
            onClick={onDeleteEstimateTargets}
          >
            리스트에서 제거
          </Button>
        </Col>
        <Col>
          <Button
            type={selectedEstimateKeys.length !== 0 ? 'primary' : undefined}
            className={
              selectedEstimateKeys.length === 0 ? 'ant-btn-waiting' : ''
            }
            loading={publishLoading}
            onClick={handleClickPublishEstimate}
          >
            총 {selectedEstimateKeys.length} SKU 견적서 한 번에 받기
          </Button>
        </Col>
      </Row>
    </EstimatePickBlock>
  );
};

export default EstimatePick;
