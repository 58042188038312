import MistakeNoticeItem from 'components/MistakeNoticeItem';
import ProductBuyers from 'components/product/ProductBuyers';
import { useCountryCode } from 'service/country';
import ProductPageTemplate from 'templates/ProductPageTemplate';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';

const ProductBuyersPage = () => {
  const { productId } = useCurrentProduct();
  const countryId = useCountryId();
  const countryCode = useCountryCode(countryId);
  const notices = [
    countryCode !== 'JP'
      ? '한 제품에 여러명의 바이어를 등록하실 수 있습니다.'
      : '',
    '기존 바이어 목록에 바이어 추가를 하여 신규 바이어를 등록하실 수 있으며 바이어 수정은 마이페이지 > 바이어 관리에서 가능합니다.',
    <MistakeNoticeItem />,
  ];

  return (
    <ProductPageTemplate
      subtitle="바이어/에이전시 정보"
      hasSkip={false}
      notices={notices}
    >
      <ProductBuyers productId={productId} countryId={countryId} />
    </ProductPageTemplate>
  );
};

export default ProductBuyersPage;
