import { Link, useParams } from 'react-router-dom';

import DocumentsUpload from 'components/brand/retailer/retailerApply/DocumentsUpload';
import ProductPageTemplate from 'components/brand/retailer/retailerApply/product/ProductPageTemplate';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import { Typography } from 'components/system';
import path from 'lib/path';
import { EDocumentCode } from 'types/brand/retailer/retailerApply';

const ProductCFIRCPage = () => {
  const params = useParams<{ productId: string }>();
  const productId = Number(params.productId);
  return (
    <ProductPageTemplate
      productId={productId}
      subtitle="기능성 원료 확인 및 함량 성적서"
      notices={[
        '해당 서류는 기능성 화장품에 한해서만 필요한 서류입니다.',
        <Typography.Text type="secondary">
          <Typography.Text inline bold type="secondary">
            식약처 화장품 지정 품질검사 기관
          </Typography.Text>
          에서 발행된 성적서만 가능합니다. (해당기관 목록은{' '}
          <Link
            to={`${path.service}/guide`}
            target="_blank"
            style={{ fontWeight: 700, textDecoration: 'underline' }}
          >
            여기
          </Link>
          에서 확인이 가능합니다.)
        </Typography.Text>,
        '완제품으로 의뢰한 성적서만 인정되며, 성적서에 시료 사진은 반드시 첨부되어 있어야 합니다.',
        <MistakeNoticeItem />,
      ]}
    >
      <DocumentsUpload
        productId={productId}
        documentCode={EDocumentCode.CFIRC}
        accept="application/pdf"
      />
    </ProductPageTemplate>
  );
};

export default ProductCFIRCPage;
