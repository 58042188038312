import MistakeNoticeItem from 'components/MistakeNoticeItem';
import ProductPageTemplate from 'templates/ProductPageTemplate';
import ProductMicrobiologicalStandardTable from 'components/product/microbiologicalProperty/ProductMicrobiologicalStandardTable';
import ProductMicrobiologicalProperty from 'components/product/microbiologicalProperty/ProductMicrobiologicalProperty';

const notices = [
  '아래 기재된 기준 이하의 미생물 수치일 경우에만 인증이 가능합니다.',
  <MistakeNoticeItem />,
];

const ProductMicrobiologicalPropertyPage = () => {
  return (
    <ProductPageTemplate
      subtitle="Microbiological Properties"
      notices={notices}
    >
      <ProductMicrobiologicalStandardTable />
      <ProductMicrobiologicalProperty />
    </ProductPageTemplate>
  );
};

export default ProductMicrobiologicalPropertyPage;
