import qs from 'qs';

import client from './client';
import { createFormData } from 'lib/file';
import {
  EAttachType,
  IPackingAttestationAddParam,
  IProductChallengeTestAddParam,
  IProductSpecification,
  IPackingAttestationUpdateParam,
  IProductChallengeTestUpdateParam,
  IShelfLifeDeclaration,
  IProductFormulaBreakdownItem,
  IProductFormulaBreakdownDraft,
  IPackingAttestation,
  IProductAddParams,
  IProductUpdateParams,
  IProductCategoryAddParams,
  IProductDetail,
  IProductDocumentStatus,
  IProduct,
  EProductStatus,
  EProductSearchType,
  EDocumentCode,
  IProductDocStatus,
  IProductItem,
} from 'types/product';
import { IAPIResponse, IAPIPageableResponse } from 'types/common';
import {
  IFDACertificatedColor,
  ISaleInfo,
  IVCRPAccount,
  IVCRPAccountRequest,
} from 'types/brand/vcrp';

// Product
export const getProduct = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId?: number;
}) =>
  client.get<IAPIPageableResponse<IProduct[]>>(
    `/v2/products/${productId}${
      typeof countryId !== 'undefined' ? `/countries/${countryId}` : ''
    }`,
  );
export const getProducts = ({
  page,
  size = 10,
  searchType,
  searchKeyword,
  status = null,
}: {
  page: number;
  size?: number;
  searchType: EProductSearchType;
  searchKeyword: string;
  status: EProductStatus | null;
}) =>
  client.get<IAPIPageableResponse<IProductItem[]>>('/v2/products', {
    params: { status, page, searchType, searchKeyword, size },
  });
export const manuGetProducts = ({
  page = 1,
  companyId,
  manufacturerItemNameKo,
  manufacturerItemNo,
  brandCompanyNameKo,
  status,
}: {
  page?: number;
  companyId: number;
  manufacturerItemNameKo: string;
  manufacturerItemNo: string;
  brandCompanyNameKo: string;
  status: string;
}) =>
  client.post(`/manufacturer/product/all/${page}/10`, {
    companyId,
    manufacturerItemNameKo,
    manufacturerItemNo,
    brandCompanyNameKo,
    status,
  });
export const getProductDetail = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId?: number;
}) =>
  client.get<IAPIResponse<IProductDetail>>(
    `/product/${productId}${countryId ? `/${countryId}` : ''}`,
  );
export const getProductIsCertificated = (productId: number) =>
  client.get<IAPIResponse<boolean>>(
    `/products/is-certificated?productIds=${productId}`,
  );
export const deleteProducts = (productIds: number[]) =>
  client.delete(`/products?productIds=${productIds}`);

export const addProduct = (productAddParams: IProductAddParams) =>
  client.post('/product', productAddParams);

export const updateProduct = (productUpdateParams: IProductUpdateParams) =>
  client.patch('/product', productUpdateParams);

// Country
export const getCountries = () => client.get('/countries');
export const addProductCountry = (body: {
  productId: number;
  countryIds: number[];
}) => client.post('/product-country', body);
export const getProductCountries = (productId: number) =>
  client.get(`/product-countries/${productId}`);
export const deleteProductCountry = (productCountryIds: number[]) =>
  client.delete(
    `/product-country?${qs.stringify(productCountryIds, { indices: false })}`,
  );
export const manuDeleteProductCountry = ({
  productId,
  productCountryIds,
}: {
  productId: number;
  productCountryIds: number[];
}) =>
  client.delete(
    `/manufacturer/product-country/${productId}?${qs.stringify(
      { productCountryIds },
      {
        indices: false,
      },
    )}`,
  );

// Category
export const getCategories = () => client.get('/cosmetic-categories');
export const getFunctionalCategories = () =>
  client.get('/functional-categories');

export const getProductCategory = (productId: number) =>
  client.get(`/product-category/${productId}`);

export const addProductCategory = (
  productCategoryAddParams: IProductCategoryAddParams,
) => client.post('/product-category', productCategoryAddParams);

// Physical Property
export const getProductPhysicalProperty = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId?: number;
}) =>
  client.get(
    `/product-physical-property/${productId}${
      countryId ? `/${countryId}` : ''
    }`,
  );
export const addProductPhysicalProperty = (productPhysicalProperty: {
  productId: number;
  form: string;
  color: string;
  odour: string;
  phLimitMin: number;
  phLimitMax: number;
  ph: number;
  specificGravityLimitMin: number;
  specificGravityLimitMax: number;
  specificGravity: number;
  netWeight: number;
  netWeightUnit: string;
  lotNo: string;
  manufacturingDate: string;
  isAerosolProduct: boolean;
  isEthanolProduct: boolean;
  countryId: number;
}) => client.post('/product-physical-property', productPhysicalProperty);
export const updateProductPhysicalProperty = (productPhysicalProperty: {
  productPhysicalPropertyId: number;
  form: string;
  color: string;
  odour: string;
  phLimitMin: number;
  phLimitMax: number;
  ph: number;
  specificGravityLimitMin: number;
  specificGravityLimitMax: number;
  specificGravity: number;
  netWeight: number;
  netWeightUnit: string;
  lotNo: string;
  manufacturingDate: string;
  isAerosolProduct: boolean;
  isEthanolProduct: boolean;
  countryId: number;
}) => client.patch('/product-physical-property', productPhysicalProperty);

// Chemical Property
export const addProductChemicalProperty = (productChemicalProperty: {
  productId: number;
  isLead: boolean;
  isArsenic: boolean;
  isMercury: boolean;
  isAntimony: boolean;
  isCadmium: boolean;
  isNickel: boolean;
}) => client.post('/product-chemical-property', productChemicalProperty);

// Microbiological Property
export const addProductMicrobiologicalProperty = (productMicrobiologicalProperty: {
  productId: number;
  isTotalABacteria: boolean;
  isSAureus: boolean;
  isEColi: boolean;
  isPAeruginosa: boolean;
}) =>
  client.post(
    '/product-microbiological-property',
    productMicrobiologicalProperty,
  );

// Product Formula
export const uploadProductFormulaBreakdown = ({
  productId,
  countryId,
  file,
}: {
  productId: number;
  countryId?: number;
  file: File;
}) =>
  client.post<IAPIResponse<IProductFormulaBreakdownDraft>>(
    `/product-formula-breakdown/upload/${productId}${
      countryId ? `/${countryId}` : ''
    }`,
    {
      file,
    },
  );
export const uploadProductFormulaBreakdownChina = ({
  productId,
  file,
}: {
  productId: number;
  file: File;
}) =>
  client.post<IAPIResponse<IProductFormulaBreakdownDraft>>(
    `/product-formula-breakdown-china/upload/${productId}`,
    {
      file,
    },
  );

export const getProductFormulaBreakdown = (
  productId: number,
  countryId?: number,
) =>
  client.get<IAPIResponse<IProductFormulaBreakdownItem[]>>(
    `/product-formula-breakdown/${productId}${
      countryId ? `?countryId=${countryId}` : ''
    }`,
  );

export const getProductFormulaBreakdownChina = (
  productId: number,
  countryId?: number,
) =>
  client.get<IAPIResponse<IProductFormulaBreakdownItem[]>>(
    `/product-formula-breakdown-china/${productId}${
      countryId ? `?countryId=${countryId}` : ''
    }`,
  );

// Artwork
export const getProductArtworks = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId?: number;
}) =>
  client.get(
    `/product-artwork/${productId}${countryId ? `/${countryId}` : ''}`,
  );
export const addProductArtwork = (productArtwork: {
  productId: number;
  documentFiles: File[];
  countryId?: number;
}) => client.post('/product-artwork', productArtwork);
export const deleteProductArtwork = (productArtworkId: number) =>
  client.delete(`product-artwork?productArtworkId=${productArtworkId}`);

// Stability Test
export const getProductStabilityTest = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId?: number;
}) =>
  client.get(
    `/product-stability-test/${productId}${countryId ? `/${countryId}` : ''}`,
  );
export const addProductStabilityTest = (productStabilityTest: {
  productId: number;
  temperatureRange1: string;
  temperatureRange2: string;
  temperatureRange3: string;
  cycleTemperatureRange: string;
  countryId: number;
}) => client.post('/product-stability-test', productStabilityTest);
export const updateProductStabilityTest = (productStabilityTest: {
  productStabilityTestId: number;
  temperatureRange1: string;
  temperatureRange2: string;
  temperatureRange3: string;
  cycleTemperatureRange: string;
  countryId: number;
}) => client.patch('/product-stability-test', productStabilityTest);

// Challenge Test
export const getProductChallengeTest = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId?: number;
}) =>
  client.get(
    `/product-challenge-test/${productId}${countryId ? `/${countryId}` : ''}`,
  );
export const addProductChallengeTest = (body: IProductChallengeTestAddParam) =>
  client.post('/product-challenge-test', body);
export const updateProductChallengeTest = (
  body: IProductChallengeTestUpdateParam,
) => client.patch('/product-challenge-test', body);

// Packing Attestation
export const getProductPackingAttestation = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId?: number;
}) =>
  client.get<IAPIResponse<IPackingAttestation>>(
    `/product-packing-attestation/${productId}${
      countryId ? `/${countryId}` : ''
    }`,
  );
export const addProductPackingAttestation = (
  productPackingAttestation: IPackingAttestationAddParam,
) => client.post('/product-packing-attestation', productPackingAttestation);
export const updateProductPackingAttestation = (
  productPackingAttestation: IPackingAttestationUpdateParam,
) => client.patch('/product-packing-attestation', productPackingAttestation);
export const addProductPackingAttestationAttach = (productPackingAttestationAttach: {
  productPackingAttestationId: number[];
  attachType: EAttachType;
  attachFile: File;
}) =>
  client.post(
    '/product-packing-attestation-attach',
    productPackingAttestationAttach,
  );
export const deleteProductPackingAttestationAttach = (
  productPackingAttestationAttachId: number,
) =>
  client.delete(
    `/product-packing-attestation-attach?productPackingAttestationAttachId=${productPackingAttestationAttachId}`,
  );

// Allergens List
export const getProductAllergensList = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId?: number;
}) =>
  client.get(
    `/product-allergen-list/${productId}${countryId ? `/${countryId}` : ''}`,
  );
export const addProductAllergensList = (productAllergensList: {
  isOdourless: boolean;
  isNonAllergenOdour: boolean;
  productId: number;
  documentFile: File;
}) => client.post('/product-allergen-list', productAllergensList);
export const updateProductAllergensList = (productAllergensList: {
  productId: number;
  countryId?: number;
  isOdourless: boolean;
  isNonAllergenOdour: boolean;
  documentFile: File;
}) => client.patch('/product-allergen-list', productAllergensList);

// Third Party Test Report
export const getProductThirdPartyTestReports = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) => client.get(`/product-third-party-test/${productId}/${countryId}`);
export const addProductThirdPartyTestReport = (productThirdPartyTestReport: {
  productId: number;
  countryId: number;
  documentFiles: File[];
}) => client.post('/product-third-party-test', productThirdPartyTestReport);
export const deleteProductThirdPartyTestReport = (
  productThirdPartyTestId: number,
) =>
  client.delete(
    `/product-third-party-test?productThirdPartyTestId=${productThirdPartyTestId}`,
  );

// Product Specifications
export const getProductSpecifications = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) => client.get(`/product-specification/${productId}/${countryId}`);
export const addProductSpecifications = ({
  productId,
  countryId,
  productSpecifications,
}: {
  productId: number;
  countryId: number;
  productSpecifications: IProductSpecification;
}) =>
  client.post(
    `/product-specification/${productId}/${countryId}`,
    productSpecifications,
  );
export const updateProductSpecifications = ({
  productId,
  countryId,
  productSpecifications,
}: {
  productId: number;
  countryId: number;
  productSpecifications: IProductSpecification;
}) =>
  client.patch(
    `/product-specification/${productId}/${countryId}`,
    productSpecifications,
  );

// Document
export const getDocuments = (productId: number) =>
  client.get(`/products/pre-list/${productId}`);
export const getDocumentSeqs = () => client.get('/document-seqs');
export const getDocumentFields = () => client.get('/doc/fields');
export const getDocumentLogs = (documentLogs: {
  productId: number;
  documentSeqId: number;
  documentFieldId: number;
  countryId: number;
}) => client.post('/doc/logs', documentLogs);
export const manuGetDocuments = (productId: number) =>
  client.get(`/manufacturer/product/list/${productId}`);
export const getDocStatus = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId?: number;
}) =>
  client.get<IAPIResponse<IProductDocumentStatus[]>>(
    `/doc-status/${productId}${countryId ? `/${countryId}` : ''}`,
  );
export const getProductDocStatus = ({
  productId,
  countryId,
  documentCode,
}: {
  productId: number;
  countryId?: number;
  documentCode: EDocumentCode;
}) =>
  client.get<IAPIResponse<IProductDocStatus>>(
    `/v2/products/${productId}${
      typeof countryId !== 'undefined' ? `/countries/${countryId}` : ''
    }/product-doc-statuses/${documentCode.toUpperCase()}01`,
  );
export const addDocStatus = ({
  isSkip = false,
  documentCode,
  productId,
  countryId,
  documentFile,
}: {
  isSkip?: boolean;
  documentCode: string;
  productId: number;
  countryId?: number;
  documentFile?: File;
}) =>
  client.post(
    `/doc-status/${documentCode.toUpperCase()}01`,
    createFormData({
      productId,
      isSkip,
      ...(documentFile && { documentFile }),
      ...(countryId && { countryId }),
    }),
  );

export const savePackingAttestationType = ({
  productId,
  packingAttestationType,
}: {
  productId: number;
  packingAttestationType: string;
}) =>
  client.patch(
    `/manufacturer/pack-attestation/${productId}?packingAttestationType=${packingAttestationType}`,
  );
export const manuRegisterProduct = (productId: number) =>
  client.patch(`/manufacturer/product/fin/${productId}`);
export const validateAuthCode = ({
  manufacturerId,
  authCode,
}: {
  manufacturerId: number;
  authCode: string;
}) =>
  client.post(`/auth/brand/validate/${manufacturerId}?authCode=${authCode}`);
export const importProducts = ({
  manufacturerId,
  authCodes,
}: {
  manufacturerId: number;
  authCodes: string[];
}) =>
  client.patch(
    `/auth/brand/${manufacturerId}?${qs.stringify(
      { authCodes },
      { indices: false },
    )}`,
  );

// 경내 책임 회사
export const getProductInternalResponsibleCompany = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId?: number;
}) => client.get(`/internal-responsible-company/${productId}/${countryId}`);
export const addProductInternalResponsibleCompany = (
  productInternalResponsibleCompany: FormData,
) =>
  client.post(
    `/internal-responsible-company`,
    productInternalResponsibleCompany,
  );
export const updateProductInternalResponsibleCompany = (
  productInternalResponsibleCompany: FormData,
) =>
  client.patch(
    `/internal-responsible-company`,
    productInternalResponsibleCompany,
  );

export const getShelfLifeDeclaration = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) =>
  client.get<IAPIResponse<IShelfLifeDeclaration>>(
    `/shelf-life?${qs.stringify({ productId, countryId })}`,
  );

export const updateShelfLifeDeclaration = (shelfLifeDeclarationUpdate: {
  productId: number;
  countryId: number;
  productExpireMonths: number;
}) => client.put('/shelf-life', shelfLifeDeclarationUpdate);

// Sale Info
export const getSaleInfo = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) =>
  client.get<IAPIResponse<ISaleInfo | null>>(
    `/products/${productId}/countries/${countryId}/sale-info`,
  );

export const addSaleInfo = ({
  productId,
  countryId,
  formValues,
}: {
  productId: number;
  countryId: number;
  formValues: FormData;
}) =>
  client.post<IAPIResponse<null>>(
    `/products/${productId}/countries/${countryId}/sale-info`,
    formValues,
  );

export const updateSaleInfo = ({
  productId,
  countryId,
  formValues,
}: {
  productId: number;
  countryId: number;
  formValues: FormData;
}) =>
  client.post<IAPIResponse<null>>(
    `/products/${productId}/countries/${countryId}/sale-info/update`,
    formValues,
  );

// FDA Certificated Color
export const getFDACertificatedColor = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) =>
  client.get<IAPIResponse<IFDACertificatedColor[]>>(
    `/products/${productId}/countries/${countryId}/fda-certificated-color`,
  );

export const addFDACertificatedColor = ({
  productId,
  countryId,
  formValues,
}: {
  productId: number;
  countryId: number;
  formValues: FormData;
}) =>
  client.post<IAPIResponse<null>>(
    `/products/${productId}/countries/${countryId}/fda-certificated-color`,
    formValues,
  );

// VCRP Account
export const getVCRPAccount = () =>
  client.get<IAPIResponse<IVCRPAccount>>(`/vcrp-account`);

export const addVCRPAccount = ({
  vcrpAccount,
  vcrpPassword,
}: Omit<IVCRPAccount, 'vcrpAccountId'>) =>
  client.post<IAPIResponse<null>>(`/vcrp-account`, {
    vcrpAccount,
    vcrpPassword,
  });

export const updateVCRPAccount = ({
  vcrpAccountId,
  vcrpAccount,
  vcrpPassword,
}: IVCRPAccount) =>
  client.patch<IAPIResponse<null>>(`/vcrp-accounts/${vcrpAccountId}`, {
    vcrpAccount,
    vcrpPassword,
  });

export const getProductVCRPAccount = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) =>
  client.get<IAPIResponse<Pick<IVCRPAccount, 'vcrpAccountId'>>>(
    `/products/${productId}/countries/${countryId}/vcrp-account`,
  );

export const addProductVCRPAccount = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) =>
  client.put<IAPIResponse<null>>(
    `/products/${productId}/countries/${countryId}/vcrp-account`,
  );

// VCRP Account Request
export const getVCRPAccountRequest = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) =>
  client.get<IAPIResponse<IVCRPAccountRequest>>(
    `/products/${productId}/countries/${countryId}/vcrp-account-request`,
  );

export const addVCRPAccountRequest = ({
  productId,
  countryId,
  formValues,
}: {
  productId: number;
  countryId: number;
  formValues: IVCRPAccountRequest;
}) =>
  client.post<IAPIResponse<null>>(
    `/products/${productId}/countries/${countryId}/vcrp-account-request`,
    formValues,
  );

export const updateVCRPAccountRequest = ({
  productId,
  countryId,
  formValues,
}: {
  productId: number;
  countryId: number;
  formValues: IVCRPAccountRequest;
}) =>
  client.patch<IAPIResponse<null>>(
    `/products/${productId}/countries/${countryId}/vcrp-account-request`,
    formValues,
  );
