import {
  all,
  call,
  getContext,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import produce from 'immer';
import { createAction, handleActions } from 'redux-actions';
import { message, notification } from 'antd';
import styled from 'styled-components';

import {
  createRequestSaga,
  createMultipleRequestSaga,
  createRequestActionTypes,
} from 'lib/saga';
import * as productApi from 'lib/api/product';
import * as manuProductApi from 'lib/api/manufacturer/product';
import path from 'lib/path';
import { createFormData } from 'lib/file';
import history from 'lib/history';
import { finishLoading, startLoading } from 'modules/loading';
import * as certificateActions from 'modules/certificate';
import { Typography } from 'components/system';

const NotificationItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -48px;
  cursor: pointer;
`;

// 공통
const SET_SKIP_MODE = 'product/SET_SKIP_MODE';
const SKIP_PAGE = 'product/SKIP_PAGE';
const SET_NEXT_PATHNAME = 'product/SET_NEXT_PATHNAME';
const MANU_SET_NEXT_PATHNAME = 'product/MANU_SET_NEXT_PATHNAME';
const [ADD_PRODUCT_DOC_STATUS] = createRequestActionTypes(
  'product/ADD_PRODUCT_DOC_STATUS',
);
const [UPDATE_PRODUCT_DOC_STATUS] = createRequestActionTypes(
  'product/UPDATE_PRODUCT_DOC_STATUS',
);
const SET_DOCUMENT_FIELD_MAP = 'product/SET_DOCUMENT_FIELD_MAP';
const [GET_DOCUMENT_LOGS, GET_DOCUMENT_LOGS_SUCCESS] = createRequestActionTypes(
  'product/GET_DOCUMENT_LOGS',
);
const INITIALIZE_DOCUMENT_LOGS = 'product/INITIALIZE_DOCUMENT_LOGS';

// Product
const [MANU_GET_PRODUCTS, MANU_GET_PRODUCTS_SUCCESS] = createRequestActionTypes(
  'product/MANU_GET_PRODUCTS',
);
const [
  UPDATE_PRODUCT_NAME,
  UPDATE_PRODUCT_NAME_SUCCESS,
] = createRequestActionTypes('product/UPDATE_PRODUCT_NAME');
const [
  UPDATE_CHINESE_PRODUCT_NAME,
  UPDATE_CHINESE_PRODUCT_NAME_SUCCESS,
] = createRequestActionTypes('product/UPDATE_CHINESE_PRODUCT_NAME');
const [
  UPDATE_JAPANESE_PRODUCT_NAME,
  UPDATE_JAPANESE_PRODUCT_NAME_SUCCESS,
] = createRequestActionTypes('product/UPDATE_JAPANESE_PRODUCT_NAME');
const [
  UPDATE_COMPANY_INFO,
  UPDATE_COMPANY_INFO_SUCCESS,
] = createRequestActionTypes('product/UPDATE_COMPANY_INFO');
const [REGISTER_PRODUCT] = createRequestActionTypes('product/REGISTER_PRODUCT');
const [MANU_REGISTER_PRODUCT] = createRequestActionTypes(
  'product/MANU_REGISTER_PRODUCT',
);
const [GET_DOCUMENTS, GET_DOCUMENTS_SUCCESS] = createRequestActionTypes(
  'product/GET_DOCUMENTS',
);
const SET_DOCUMENT_CODES = 'product/SET_DOCUMENT_CODES';
const MANU_SET_DOCUMENT_CODES = 'product/MANU_SET_DOCUMENT_CODES';
const [
  MANU_GET_DOCUMENTS,
  MANU_GET_DOCUMENTS_SUCCESS,
] = createRequestActionTypes('product/MANU_GET_DOCUMENTS');
const SET_CURRENT_DOC_STATUS = 'product/SET_CURRENT_DOC_STATUS';
const INITIALIZE_DOCUMENTS = 'product/INITIALIZE_DOCUMENTS';
const MANU_INITIALIZE_DOCUMENTS = 'product/MANU_INITIALIZE_DOCUMENTS';
const UPDATE_CURRENT_PATHNAME = 'product/UPDATE_CURRENT_PATHNAME';
const SET_CURRENT_PRODUCT = 'product/SET_CURRENT_PRODUCT';
const [ADD_PRODUCT, ADD_PRODUCT_SUCCESS] = createRequestActionTypes(
  'product/ADD_PRODUCT',
);
const [MANU_ADD_PRODUCT, MANU_ADD_PRODUCT_SUCCESS] = createRequestActionTypes(
  'product/MANU_ADD_PRODUCT',
);
const [UPDATE_PRODUCT, UPDATE_PRODUCT_SUCCESS] = createRequestActionTypes(
  'product/UPDATE_PRODUCT',
);
const [
  MANU_UPDATE_PRODUCT,
  MANU_UPDATE_PRODUCT_SUCCESS,
] = createRequestActionTypes('product/MANU_UPDATE_PRODUCT');
const [GET_COUNTRIES, GET_COUNTRIES_SUCCESS] = createRequestActionTypes(
  'product/GET_COUNTRIES',
);
const [
  SET_PRODUCT_EXPIRE_MONTHS,
  SET_PRODUCT_EXPIRE_MONTHS_SUCCESS,
] = createRequestActionTypes('product/SET_PRODUCT_EXPIRE_MONTHS');

// Country
const [
  ADD_PRODUCT_COUNTRY,
  ADD_PRODUCT_COUNTRY_SUCCESS,
] = createRequestActionTypes('product/ADD_PRODUCT_COUNTRY');
const [GET_CATEGORIES, GET_CATEGORIES_SUCCESS] = createRequestActionTypes(
  'product/GET_CATEGORIES',
);
const [ADD_PRODUCT_CATEGORY] = createRequestActionTypes(
  'product/ADD_PRODUCT_CATEGORY',
);
const [
  UPDATE_PRODUCT_COUNTRY,
  UPDATE_PRODUCT_COUNTRY_SUCCESS,
  UPDATE_PRODUCT_COUNTRY_FAILURE,
] = createRequestActionTypes('product/UPDATE_PRODUCT_COUNTRY');

// Property
const [
  GET_PRODUCT_PHYSICAL_PROPERTY,
  GET_PRODUCT_PHYSICAL_PROPERTY_SUCCESS,
] = createRequestActionTypes('product/GET_PRODUCT_PHYSICAL_PROPERTY');
const INITIALIZE_PRODUCT_PHYSICAL_PROPERTY =
  'product/INITIALIZE_PRODUCT_PHYSICAL_PROPERTY';
const [ADD_PRODUCT_PHYSICAL_PROPERTY] = createRequestActionTypes(
  'product/ADD_PRODUCT_PHYSICAL_PROPERTY',
);
const [UPDATE_PRODUCT_PHYSICAL_PROPERTY] = createRequestActionTypes(
  'product/UPDATE_PRODUCT_PHYSICAL_PROPERTY',
);

const [ADD_PRODUCT_CHEMICAL_PROPERTY] = createRequestActionTypes(
  'product/ADD_PRODUCT_CHEMICAL_PROPERTY',
);
const [ADD_PRODUCT_MICROBIOLOGICAL_PROPERTY] = createRequestActionTypes(
  'product/ADD_PRODUCT_MICROBIOLOGICAL_PROPERTY',
);

// Artwork
const [ADD_PRODUCT_ARTWORK] = createRequestActionTypes(
  'product/ADD_PRODUCT_ARTWORK',
);
const [
  GET_PRODUCT_ARTWORKS,
  GET_PRODUCT_ARTWORKS_SUCCESS,
] = createRequestActionTypes('product/GET_PRODUCT_ARTWORKS');
const [
  UPDATE_PRODUCT_ARTWORK,
  UPDATE_PRODUCT_ARTWORK_SUCCESS,
] = createRequestActionTypes('product/UPDATE_PRODUCT_ARTWORK');
const INITIALIZE_ARTWORK = 'product/INITIALIZE_ARTWORK';

//CGMP
const [
  ADD_PRODUCT_CGMP,
  ADD_PRODUCT_CGMP_SUCCESS,
  ADD_PRODUCT_CGMP_FAILURE,
] = createRequestActionTypes('product/ADD_PRODUCT_CGMP');
const [
  UPDATE_PRODUCT_CGMP,
  UPDATE_PRODUCT_CGMP_SUCCESS,
  UPDATE_PRODUCT_CGMP_FAILURE,
] = createRequestActionTypes('product/UPDATE_PRODUCT_CGMP');

// Stability Test
const [ADD_PRODUCT_STABILITY_TEST] = createRequestActionTypes(
  'product/ADD_PRODUCT_STABILITY_TEST',
);
const [
  GET_PRODUCT_STABILITY_TEST,
  GET_PRODUCT_STABILITY_TEST_SUCCESS,
] = createRequestActionTypes('product/GET_PRODUCT_STABILITY_TEST');
const [UPDATE_PRODUCT_STABILITY_TEST] = createRequestActionTypes(
  'product/UPDATE_PRODUCT_STABILITY_TEST',
);
const INITIALIZE_PRODUCT_STABILITY_TEST =
  'product/INITIALIZE_PRODUCT_STABILITY_TEST';

// Challenge Test
const [ADD_PRODUCT_CHALLENGE_TEST] = createRequestActionTypes(
  'product/ADD_PRODUCT_CHALLENGE_TEST',
);
const [
  GET_PRODUCT_CHALLENGE_TEST,
  GET_PRODUCT_CHALLENGE_TEST_SUCCESS,
] = createRequestActionTypes('product/GET_PRODUCT_CHALLENGE_TEST');
const [UPDATE_PRODUCT_CHALLENGE_TEST] = createRequestActionTypes(
  'product/UPDATE_PRODUCT_CHALLENGE_TEST',
);

const INITIALIZE_PRODUCT_CHALLENGE_TEST =
  'product/INITIALIZE_PRODUCT_CHALLENGE_TEST';

// Packing Attestation
const [ADD_PRODUCT_PACKING_ATTESTATION] = createRequestActionTypes(
  'product/ADD_PRODUCT_PACKING_ATTESTATION',
);

// Allergens List
const [
  GET_PRODUCT_ALLERGENS_LIST,
  GET_PRODUCT_ALLERGENS_LIST_SUCCESS,
] = createRequestActionTypes('product/GET_ALLERGENS_LIST');
const INITIALIZE_PRODUCT_ALLERGENS_LIST = 'product/INITIALIZE_ALLERGENS_LIST';
const [
  ADD_PRODUCT_ALLERGENS_LIST,
  ADD_PRODUCT_ALLERGENS_LIST_SUCCESS,
] = createRequestActionTypes('product/ADD_PRODUCT_ALLERGENS_LIST');
const [UPDATE_PRODUCT_ALLERGENS_LIST] = createRequestActionTypes(
  'product/UPDATE_PRODUCT_ALLERGENS_LIST',
);

const [
  GET_PRODUCT_PACKING_ATTESTATION,
  GET_PRODUCT_PACKING_ATTESTATION_SUCCESS,
] = createRequestActionTypes('product/GET_PRODUCT_PACKING_ATTESTATION');
const [UPDATE_PRODUCT_PACKING_ATTESTATION] = createRequestActionTypes(
  'product/UPDATE_PRODUCT_PACKING_ATTESTATION',
);
const INITIALIZE_PRODUCT_PACKING_ATTESTATION =
  'product/INITIALIZE_PRODUCT_PACKING_ATTESTATION';

// Third Party Test Report
const [ADD_PRODUCT_THIRD_PARTY_TEST_REPORT] = createRequestActionTypes(
  'product/ADD_PRODUCT_THIRD_PARTY_TEST_REPORT',
);
const [
  UPDATE_PRODUCT_THIRD_PARTY_TEST_REPORT,
  UPDATE_PRODUCT_THIRD_PARTY_TEST_REPORT_SUCCESS,
] = createRequestActionTypes('product/UPDATE_PRODUCT_THIRD_PARTY_TEST_REPORT');
const [
  GET_PRODUCT_THIRD_PARTY_TEST_REPORTS,
  GET_PRODUCT_THIRD_PARTY_TEST_REPORTS_SUCCESS,
] = createRequestActionTypes('product/GET_PRODUCT_THIRD_PARTY_TEST_REPORTS');
const INITIALIZE_PRODUCT_THIRD_PARTY_TEST_REPORTS =
  'product/INITIALIZE_PRODUCT_THIRD_PARTY_TEST_REPORTS';

// Packing Attestation
const [
  SAVE_PACKING_ATTESTATION_TYPE,
  SAVE_PACKING_ATTESTATION_TYPE_SUCCESS,
] = createRequestActionTypes('product/SAVE_PACKING_ATTESTATION_TYPE');

// Product Specifications
const [
  GET_PRODUCT_SPECIFICATIONS,
  GET_PRODUCT_SPECIFICATIONS_SUCCESS,
] = createRequestActionTypes('product/GET_PRODUCT_SPECIFICATIONS');
const [SAVE_PRODUCT_SPECIFICATIONS] = createRequestActionTypes(
  'product/SAVE_PRODUCT_SPECIFICATIONS',
);
const [ADD_PRODUCT_SPECIFICATIONS] = createRequestActionTypes(
  'product/ADD_PRODUCT_SPECIFICATIONS',
);
const [UPDATE_PRODUCT_SPECIFICATIONS] = createRequestActionTypes(
  'product/UPDATE_PRODUCT_SPECIFICATIONS',
);
const INITIALIZE_PRODUCT_SPECIFICATIONS =
  'product/INITIALIZE_PRODUCT_SPECIFICATIONS';

// 제조사 import
const [IMPORT_PRODUCTS] = createRequestActionTypes('product/IMPORT_PRODUCTS');

// 경내 책임 회사
const INITIALIZE_PRODUCT_INTERNAL_RESPONSIBLE_COMPANY =
  'product/INITIALIZE_PRODUCT_INTERNAL_RESPONSIBLE_COMPANY';
const [
  GET_PRODUCT_INTERNAL_RESPONSIBLE_COMPANY,
  GET_PRODUCT_INTERNAL_RESPONSIBLE_COMPANY_SUCCESS,
] = createRequestActionTypes(
  'product/GET_PRODUCT_INTERNAL_RESPONSIBLE_COMPANY',
);
const [
  ADD_PRODUCT_INTERNAL_RESPONSIBLE_COMPANY,
  ADD_PRODUCT_INTERNAL_RESPONSIBLE_COMPANY_SUCCESS,
] = createRequestActionTypes(
  'product/ADD_PRODUCT_INTERNAL_RESPONSIBLE_COMPANY',
);
const [
  UPDATE_PRODUCT_INTERNAL_RESPONSIBLE_COMPANY,
  UPDATE_PRODUCT_INTERNAL_RESPONSIBLE_COMPANY_SUCCESS,
] = createRequestActionTypes(
  'product/UPDATE_PRODUCT_INTERNAL_RESPONSIBLE_COMPANY',
);

// 공통
export const setSkipMode = createAction(SET_SKIP_MODE);
export const skipPage = createAction(SKIP_PAGE);
export const setNextPathname = createAction(SET_NEXT_PATHNAME);
export const manuSetNextPathname = createAction(MANU_SET_NEXT_PATHNAME);
const moveToNextPathnameSaga = function* () {
  const { manuMode, nextPathname, skipMode, productId } = yield select(
    ({ company, product }) => ({
      manuMode: company.company.isManufacturer,
      nextPathname: product.nextPathname,
      skipMode: product.skipMode,
      productId: product.currentProduct?.productId,
    }),
  );
  try {
    if (skipMode) {
      if (nextPathname === 'finish') {
        if (!manuMode) {
          history.replace(`/product/${productId}`);
        } else {
          history.replace(`/manufacturer/product/${productId}`);
        }
      } else {
        history.replace(
          `/product/${productId}/${nextPathname}`,
          nextPathname === 'phy' && manuMode
            ? { referer: path.manufacturer.product.basic }
            : null,
        );
        if (!manuMode) {
          yield put(setNextPathname(nextPathname));
        } else {
          yield put(manuSetNextPathname(nextPathname));
        }
      }
    } else {
      history.goBack();
      message.success('입력되었습니다.');
    }
  } catch (e) {
    console.error(e);
  }
};
const updateProductSuccessSaga = function* ({ type }) {
  const { currentProduct, currentDocStatus } = yield select(
    ({ product }) => product,
  );
  if (currentDocStatus && currentDocStatus.status === 'MOD') {
    yield put(
      certificateActions.fixDocument(currentDocStatus.productDocStatusId),
    );
    message.success('보완 완료 되었습니다.');
  } else {
    message.success('수정되었습니다.');
  }
  if (type === UPDATE_PRODUCT_SUCCESS && !currentProduct.productCategory) {
    history.replace(`/product/${currentProduct.productId}/cat`);
  } else if (
    type !== UPDATE_PRODUCT_NAME_SUCCESS &&
    type !== UPDATE_CHINESE_PRODUCT_NAME_SUCCESS &&
    type !== UPDATE_JAPANESE_PRODUCT_NAME_SUCCESS &&
    type !== UPDATE_COMPANY_INFO_SUCCESS
  ) {
    history.goBack();
  } else {
    const certTargetId = yield select(
      ({ certificate }) => certificate.certificate.certTargetId,
    );
    yield put(certificateActions.getDocuments(certTargetId));
  }
};
export const setDocumentFieldMap = createAction(SET_DOCUMENT_FIELD_MAP);
export const getDocumentLogs = createAction(GET_DOCUMENT_LOGS);
const getDocumentLogsSaga = createRequestSaga(
  GET_DOCUMENT_LOGS,
  productApi.getDocumentLogs,
);
export const initializeDocumentLogs = createAction(INITIALIZE_DOCUMENT_LOGS);

// Product
export const manuGetProducts = createAction(MANU_GET_PRODUCTS);
const manuGetProductsSaga = createRequestSaga(
  MANU_GET_PRODUCTS,
  productApi.manuGetProducts,
);
export const updateProductName = createAction(UPDATE_PRODUCT_NAME);
const updateProductNameSaga = createRequestSaga(
  UPDATE_PRODUCT_NAME,
  productApi.updateProduct,
);
export const updateChineseProductName = createAction(
  UPDATE_CHINESE_PRODUCT_NAME,
);
const updateChineseProductNameSaga = createRequestSaga(
  UPDATE_CHINESE_PRODUCT_NAME,
  productApi.updateProduct,
);
export const updateJapaneseProductName = createAction(
  UPDATE_JAPANESE_PRODUCT_NAME,
);
const updateJapaneseProductNameSaga = createRequestSaga(
  UPDATE_JAPANESE_PRODUCT_NAME,
  productApi.updateProduct,
);
export const updateCompanyInfo = createAction(UPDATE_COMPANY_INFO);
const updateCompanyInfoSaga = createRequestSaga(
  UPDATE_COMPANY_INFO,
  productApi.updateProduct,
);
export const registerProduct = createAction(REGISTER_PRODUCT);
const registerProductSaga = createRequestSaga(
  REGISTER_PRODUCT,
  productApi.updateProduct,
  {
    onSuccess: function* () {
      const history = yield getContext('history');
      history.push(path.product.registered);
    },
  },
);
export const manuRegisterProduct = createAction(MANU_REGISTER_PRODUCT);
const manuRegisterProductSaga = createRequestSaga(
  MANU_REGISTER_PRODUCT,
  productApi.manuRegisterProduct,
  {
    onSuccess: function* () {
      const history = yield getContext('history');
      history.push(path.manufacturer.product.list);
    },
  },
);

export const getDocuments = createAction(GET_DOCUMENTS);
const getDocumentsSaga = createRequestSaga(
  GET_DOCUMENTS,
  productApi.getDocuments,
);
const setDocumentCodes = createAction(SET_DOCUMENT_CODES);
const manuSetDocumentCodes = createAction(MANU_SET_DOCUMENT_CODES);
export const manuGetDocuments = createAction(MANU_GET_DOCUMENTS);
const manuGetDocumentsSaga = createRequestSaga(
  MANU_GET_DOCUMENTS,
  productApi.manuGetDocuments,
);
export const setCurrentDocStatus = createAction(SET_CURRENT_DOC_STATUS);
export const initializeDocuments = createAction(INITIALIZE_DOCUMENTS);
export const manuInitializeDocuments = createAction(MANU_INITIALIZE_DOCUMENTS);
export const setProductExpireMonths = createAction(SET_PRODUCT_EXPIRE_MONTHS);
const setProductExpireMonthsSaga = function* ({ payload }) {
  const {
    productId,
    countryId,
    productExpireMonths,
    certTargetId,
    updateMode,
  } = payload;
  yield put(startLoading(SET_PRODUCT_EXPIRE_MONTHS));
  try {
    yield call(productApi.updateShelfLifeDeclaration, {
      productId,
      countryId,
      productExpireMonths,
    });
    yield put({
      type: SET_PRODUCT_EXPIRE_MONTHS_SUCCESS,
      payload,
    });
    yield put(certificateActions.toggleShelfLifeMonthModalVisible());
    if (updateMode) {
      const { productDocStatusId } = yield select(
        ({ product }) => product.currentDocStatus,
      );
      yield put(certificateActions.fixDocument(productDocStatusId));
      message.success('보완 완료 되었습니다.');
    } else {
      yield put(certificateActions.getDocuments(certTargetId));
      message.success('입력되었습니다.');
    }
  } catch (error) {}
  yield put(finishLoading(SET_PRODUCT_EXPIRE_MONTHS));
};

// Document Status
export const addDocStatus = createAction(ADD_PRODUCT_DOC_STATUS);
const addDocStatusSaga = createRequestSaga(
  ADD_PRODUCT_DOC_STATUS,
  productApi.addDocStatus,
);
export const updateDocStatus = createAction(UPDATE_PRODUCT_DOC_STATUS);
const updateDocStatusSaga = createRequestSaga(
  UPDATE_PRODUCT_DOC_STATUS,
  productApi.addDocStatus,
  {
    onSuccess: function* ({ payload }) {
      if (payload.status === 'MOD') {
        yield put(certificateActions.fixDocument(payload.productDocStatusId));
      }
    },
  },
);

export const updateCurrentPathname = createAction(UPDATE_CURRENT_PATHNAME);
export const setCurrentProduct = createAction(SET_CURRENT_PRODUCT);
export const addProduct = createAction(ADD_PRODUCT);
const addProductSaga = createRequestSaga(ADD_PRODUCT, productApi.addProduct);
export const updateProduct = createAction(UPDATE_PRODUCT);
const updateProductSaga = createRequestSaga(
  UPDATE_PRODUCT,
  productApi.updateProduct,
);

export const manuAddProduct = createAction(MANU_ADD_PRODUCT);
const manuAddProductSaga = function* ({ payload }) {
  yield put(startLoading(MANU_ADD_PRODUCT));
  try {
    const {
      data: { result: addedProduct },
    } = yield call(manuProductApi.addProductBasic, payload);
    const { productId } = addedProduct;
    yield put({
      type: MANU_ADD_PRODUCT_SUCCESS,
      payload: addedProduct,
    });
    yield put(manuGetDocuments(productId));
  } catch (error) {
    console.error(error);
  }
  yield put(finishLoading(MANU_ADD_PRODUCT));
};
export const manuUpdateProduct = createAction(MANU_UPDATE_PRODUCT);
const manuUpdateProductSaga = function* ({ payload }) {
  yield put(startLoading(MANU_UPDATE_PRODUCT));
  try {
    const { productId } = payload;

    // 제품 수정
    yield call(manuProductApi.updateProductBasic, payload);
    yield put({
      type: MANU_UPDATE_PRODUCT_SUCCESS,
    });
    yield put(manuGetDocuments(productId));
  } catch (error) {
    console.error(error);
  }
  yield put(finishLoading(MANU_UPDATE_PRODUCT));
};

// Country
export const getCountries = createAction(GET_COUNTRIES);
const getCountriesSaga = createRequestSaga(
  GET_COUNTRIES,
  productApi.getCountries,
);
export const addProductCountry = createAction(ADD_PRODUCT_COUNTRY);
const addProductCountrySaga = createRequestSaga(
  ADD_PRODUCT_COUNTRY,
  productApi.addProductCountry,
);
const addProductCountrySuccessSaga = function* ({ payload: productCountries }) {
  const currentProduct = yield select(({ product }) => product.currentProduct);
  yield put(
    setCurrentProduct({
      ...currentProduct,
      productCountries,
    }),
  );
  yield put(getDocuments(currentProduct.productId));
};
export const updateProductCountry = createAction(
  UPDATE_PRODUCT_COUNTRY,
  (body) => body,
  (_, registered) => registered,
);
const updateProductCountrySaga = function* ({ payload, meta: registered }) {
  const { productId, newCountryIds, deletedProductCountryIds } = payload;
  yield put(startLoading(UPDATE_PRODUCT_COUNTRY));
  try {
    if (deletedProductCountryIds.length) {
      yield call(productApi.deleteProductCountry, {
        productCountryIds: deletedProductCountryIds,
      });
    }
    if (newCountryIds.length) {
      yield call(productApi.addProductCountry, {
        productId,
        countryIds: newCountryIds,
      });
      // HINT : 국가의 추가가 있는 경우만 메세지 생성.
      if (
        !registered &&
        !(!newCountryIds.length && deletedProductCountryIds.length)
      ) {
        setTimeout(
          () =>
            message.info(
              '추가 국가의 규제 및 사용 여부를 Product Formula Breakdown 에서 확인해 주세요.',
            ),
          0,
        );
      }

      // 정책 : 등록 완료제품일 경우 국가 추가시 추가 서류 필요 여부를 판단
      if (registered) {
        const response = yield call(productApi.getDocuments, productId);
        const documents = response.data.result;
        if (documents.find(({ status }) => status === 'INP')) {
          yield call(productApi.updateProduct, {
            productId,
            status: 'PRD-RDY',
          });
          const history = yield getContext('history');
          history.replace(path.product.ready);
          message.info(
            "추가 서류 등록이 필요하여 제품이 '등록 중 제품 관리'로 이동합니다.",
            5,
          );
          // HINT : 국가의 추가가 있는 경우만 메세지 생성.
          if (!(!newCountryIds.length && deletedProductCountryIds.length)) {
            message.info(
              '추가 국가의 규제 및 사용 여부를 Product Formula Breakdown 에서 확인해 주세요.',
            );
          }
          yield put(finishLoading(UPDATE_PRODUCT_COUNTRY));
          return;
        } else {
          const product = yield select(({ product }) => product.currentProduct);
          const queryClient = yield getContext('queryClient');
          const countries = queryClient
            .getQueryData('country/getCountries')
            .data.result.filter(({ countryId }) =>
              newCountryIds.includes(countryId),
            );

          notification.info({
            style: {
              backgroundColor: '#fff',
              border: '1px solid #f3f3f3',
              padding: '16px 24px',
              boxShadow:
                '0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)',
            },
            icon: <></>,
            message: null,
            description: (
              <NotificationItem
                onClick={() => history.push(path.estimate.pick)}
              >
                <Typography.Text style={{ marginBottom: 8 }}>
                  제품 등록
                </Typography.Text>
                <Typography.Text type="secondary">
                  <b>{`${product.productDetail.productNameEn} ${
                    product.netWeight
                      ? `(${product.netWeight} ${product.netWeightUnit})`
                      : '( - )'
                  }`}</b>{' '}
                  제품에{' '}
                  <b>
                    {`[${countries[0].countryNameKo}${
                      countries.length > 1
                        ? ` 외 ${countries.length - 1}개 국가`
                        : ''
                    }]`}
                  </b>{' '}
                  추가가 완료되었습니다. <br />
                  <b>인증 신청 &gt; 셀프 견적</b>
                  에서 해당 제품의 견적을 받을 수 있습니다.
                </Typography.Text>
              </NotificationItem>
            ),
            duration: 0,
          });
        }
      }
    }
    yield put({
      type: UPDATE_PRODUCT_COUNTRY_SUCCESS,
    });
  } catch (error) {
    if (error.code) {
      yield put({
        type: UPDATE_PRODUCT_COUNTRY_FAILURE,
        payload: error,
        error: true,
      });
    }
  }
  yield put(finishLoading(UPDATE_PRODUCT_COUNTRY));
};
const updateProductCountrySuccessSaga = function* () {
  const currentProduct = yield select(({ product }) => product.currentProduct);
  if (!currentProduct) return;
  try {
    const response = yield call(
      productApi.getProductCountries,
      currentProduct.productId,
    );
    yield put(
      setCurrentProduct({
        ...currentProduct,
        productCountries: response.data.result,
      }),
    );
  } catch (error) {}
};

// Category
export const getCategories = createAction(GET_CATEGORIES);
const getCategoriesSaga = createMultipleRequestSaga(GET_CATEGORIES, [
  productApi.getCategories,
  productApi.getFunctionalCategories,
]);
export const addProductCategory = createAction(ADD_PRODUCT_CATEGORY);
const addProductCategorySaga = createRequestSaga(
  ADD_PRODUCT_CATEGORY,
  productApi.addProductCategory,
);

// Physical Property
export const getProductPhysicalProperty = createAction(
  GET_PRODUCT_PHYSICAL_PROPERTY,
);
const getProductPhysicalPropertySaga = createRequestSaga(
  GET_PRODUCT_PHYSICAL_PROPERTY,
  productApi.getProductPhysicalProperty,
);
export const initializeProductPhysicalProperty = createAction(
  INITIALIZE_PRODUCT_PHYSICAL_PROPERTY,
);
export const addProductPhysicalProperty = createAction(
  ADD_PRODUCT_PHYSICAL_PROPERTY,
);
const addProductPhysicalPropertySaga = createRequestSaga(
  ADD_PRODUCT_PHYSICAL_PROPERTY,
  productApi.addProductPhysicalProperty,
);
export const updateProductPhysicalProperty = createAction(
  UPDATE_PRODUCT_PHYSICAL_PROPERTY,
);
const updateProductPhysicalPropertySaga = createRequestSaga(
  UPDATE_PRODUCT_PHYSICAL_PROPERTY,
  productApi.updateProductPhysicalProperty,
);

// Chemical Property
export const addProductChemicalProperty = createAction(
  ADD_PRODUCT_CHEMICAL_PROPERTY,
);
const addProductChemicalPropertySaga = createRequestSaga(
  ADD_PRODUCT_CHEMICAL_PROPERTY,
  productApi.addProductChemicalProperty,
);

// Microbiological Property
export const addProductMicrobiologicalProperty = createAction(
  ADD_PRODUCT_MICROBIOLOGICAL_PROPERTY,
);
const addProductMicroBiologicalPropertySaga = createRequestSaga(
  ADD_PRODUCT_MICROBIOLOGICAL_PROPERTY,
  productApi.addProductMicrobiologicalProperty,
);

// Artwork
export const addProductArtwork = createAction(ADD_PRODUCT_ARTWORK);
const addProductArtworkSaga = createRequestSaga(
  ADD_PRODUCT_ARTWORK,
  productApi.addProductArtwork,
);
export const getProductArtworks = createAction(GET_PRODUCT_ARTWORKS);
const getProductArtworksSaga = createRequestSaga(
  GET_PRODUCT_ARTWORKS,
  productApi.getProductArtworks,
);
export const updateProductArtwork = createAction(UPDATE_PRODUCT_ARTWORK);
const updateProductArtworkSaga = function* ({ payload }) {
  const { productId, countryId, newArtworks, deletedArtworkIds } = payload;
  yield put(startLoading(UPDATE_PRODUCT_ARTWORK));
  try {
    if (deletedArtworkIds.length) {
      yield all(
        deletedArtworkIds.map((deletedArtworkId) =>
          call(productApi.deleteProductArtwork, deletedArtworkId),
        ),
      );
    }
    if (newArtworks.length) {
      yield call(productApi.addProductArtwork, {
        productId,
        ...(countryId && { countryId }),
        documentFiles: newArtworks,
      });
    }
    yield put({ type: UPDATE_PRODUCT_ARTWORK_SUCCESS });
  } catch (error) {
    if (error.code) {
    }
  }
  yield put(finishLoading(UPDATE_PRODUCT_ARTWORK));
};
export const initializeArtwork = createAction(INITIALIZE_ARTWORK);

// CGMP
export const addProductCGMP = createAction(ADD_PRODUCT_CGMP);
const addProductCGMPSaga = function* ({ payload }) {
  const { productId, file, isCGMP, ISOExpiredDate } = payload;
  yield put(startLoading(ADD_PRODUCT_CGMP));
  try {
    yield call(productApi.addDocStatus, {
      productId,
      documentCode: 'cgmp',
      documentFile: file,
    });
    yield call(productApi.updateProduct, {
      productId,
      iso22716ExpireDate: isCGMP ? '' : ISOExpiredDate,
    });
    yield put({
      type: ADD_PRODUCT_CGMP_SUCCESS,
    });
  } catch (error) {
    if (error.code) {
      yield put({
        type: ADD_PRODUCT_CGMP_FAILURE,
        payload: error,
        error: true,
      });
    }
  }
  yield put(finishLoading(ADD_PRODUCT_CGMP));
};
export const updateProductCGMP = createAction(UPDATE_PRODUCT_CGMP);
const updateProductCGMPSaga = function* ({ payload }) {
  const { productId, file, countryId, ISOExpiredDate } = payload;
  yield put(startLoading(UPDATE_PRODUCT_CGMP));
  try {
    if (typeof ISOExpiredDate !== 'undefined') {
      const res = yield call(productApi.updateProduct, {
        productId,
        ...(countryId && { countryId }),
        iso22716ExpireDate: ISOExpiredDate,
      });
      yield put(setCurrentProduct(res.data.result));
    }
    if (file) {
      yield call(productApi.addDocStatus, {
        productId,
        documentCode: 'cgmp',
        documentFile: file,
        ...(countryId && { countryId }),
      });
    }
    yield put({
      type: UPDATE_PRODUCT_CGMP_SUCCESS,
    });
  } catch (error) {
    if (error.code) {
      yield put({
        type: UPDATE_PRODUCT_CGMP_FAILURE,
        payload: error,
        error: true,
      });
    }
  }
  yield put(finishLoading(UPDATE_PRODUCT_CGMP));
};

// Stability Test
export const initializeProductStabilityTest = createAction(
  INITIALIZE_PRODUCT_STABILITY_TEST,
);
export const addProductStabilityTest = createAction(ADD_PRODUCT_STABILITY_TEST);
const addProductStabilityTestSaga = createRequestSaga(
  ADD_PRODUCT_STABILITY_TEST,
  productApi.addProductStabilityTest,
);
export const getProductStabilityTest = createAction(GET_PRODUCT_STABILITY_TEST);
const getProductStabilityTestSaga = createRequestSaga(
  GET_PRODUCT_STABILITY_TEST,
  productApi.getProductStabilityTest,
);
export const updateProductStabilityTest = createAction(
  UPDATE_PRODUCT_STABILITY_TEST,
);
const updateProductStabilityTestSaga = createRequestSaga(
  UPDATE_PRODUCT_STABILITY_TEST,
  productApi.updateProductStabilityTest,
);

// Challenge Test
export const addProductChallengeTest = createAction(ADD_PRODUCT_CHALLENGE_TEST);
const addProductChallengeTestSaga = createRequestSaga(
  ADD_PRODUCT_CHALLENGE_TEST,
  productApi.addProductChallengeTest,
);
export const getProductChallengeTest = createAction(GET_PRODUCT_CHALLENGE_TEST);
const getProductChallengeTestSaga = createRequestSaga(
  GET_PRODUCT_CHALLENGE_TEST,
  productApi.getProductChallengeTest,
);
export const updateProductChallengeTest = createAction(
  UPDATE_PRODUCT_CHALLENGE_TEST,
);
const updateProductChallengeTestSaga = createRequestSaga(
  UPDATE_PRODUCT_CHALLENGE_TEST,
  productApi.updateProductChallengeTest,
);
export const initializeProductChallengeTest = createAction(
  INITIALIZE_PRODUCT_CHALLENGE_TEST,
);

// Packing Attestation
export const initializeProductPackingAttestation = createAction(
  INITIALIZE_PRODUCT_PACKING_ATTESTATION,
);
export const addProductPackingAttestation = createAction(
  ADD_PRODUCT_PACKING_ATTESTATION,
);
export const addProductPackingAttestationSaga = function* (action) {
  const { type, payload } = action;
  const SUCCESS = `${type}_SUCCESS`;
  const FAILURE = `${type}_FAILURE`;
  yield put(startLoading(type));
  try {
    const [
      packingAttestation,
      { firstPackingFiles, secondPackingFiles },
      packingAttestationType,
    ] = payload;
    const packingAttestationResponse = yield call(
      productApi.addProductPackingAttestation,
      packingAttestation,
    );
    yield all([
      ...firstPackingFiles.map((attachFile) =>
        call(productApi.addProductPackingAttestationAttach, {
          productPackingAttestationId: packingAttestationResponse.data.result,
          attachType: '1ST',
          attachFile,
        }),
      ),
      ...(secondPackingFiles
        ? secondPackingFiles.map((attachFile) =>
            call(productApi.addProductPackingAttestationAttach, {
              productPackingAttestationId:
                packingAttestationResponse.data.result,
              attachType: '2ST',
              attachFile,
            }),
          )
        : []),
      ...(packingAttestationType
        ? [
            call(productApi.savePackingAttestationType, {
              productId: packingAttestation.productId,
              packingAttestationType,
            }),
          ]
        : []),
    ]);
    yield put({
      type: SUCCESS,
    });
    if (packingAttestationType) {
      yield put({
        type: SAVE_PACKING_ATTESTATION_TYPE_SUCCESS,
        meta: { packingAttestationType },
      });
    }
  } catch (error) {
    if (error.code) {
      yield put({
        type: FAILURE,
        payload: error,
        error: true,
      });
    }
  }
  yield put(finishLoading(type));
};

export const getProductPackingAttestation = createAction(
  GET_PRODUCT_PACKING_ATTESTATION,
);
const getProductPackingAttestationSaga = createRequestSaga(
  GET_PRODUCT_PACKING_ATTESTATION,
  productApi.getProductPackingAttestation,
);
export const updateProductPackingAttestation = createAction(
  UPDATE_PRODUCT_PACKING_ATTESTATION,
);
const updateProductPackingAttestationSaga = function* (action) {
  const { type, payload } = action;
  const SUCCESS = `${type}_SUCCESS`;
  const [
    packingAttestation,
    { firstPackingFiles, secondPackingFiles, deletedFiles },
    packingAttestationType,
  ] = payload;
  yield put(startLoading(type));
  try {
    yield all([
      call(productApi.updateProductPackingAttestation, packingAttestation),
      ...firstPackingFiles.map((attachFile) =>
        call(productApi.addProductPackingAttestationAttach, {
          productPackingAttestationId:
            packingAttestation.productPackingAttestationId,
          attachType: '1ST',
          attachFile,
        }),
      ),
      ...secondPackingFiles.map((attachFile) =>
        call(productApi.addProductPackingAttestationAttach, {
          productPackingAttestationId:
            packingAttestation.productPackingAttestationId,
          attachType: '2ST',
          attachFile,
        }),
      ),
      ...deletedFiles.map((attachFile) =>
        call(
          productApi.deleteProductPackingAttestationAttach,
          attachFile.productPackingAttestationAttachId,
        ),
      ),
      ...(packingAttestationType
        ? [
            call(productApi.savePackingAttestationType, {
              productId: packingAttestation.productId,
              packingAttestationType,
            }),
          ]
        : []),
    ]);
    yield put({
      type: SUCCESS,
    });
    if (packingAttestationType) {
      yield put({
        type: SAVE_PACKING_ATTESTATION_TYPE_SUCCESS,
        meta: { packingAttestationType },
      });
    }
  } catch (error) {
    console.error(error);
  }
  yield put(finishLoading(type));
};

// Allergens List
export const getProductAllergensList = createAction(GET_PRODUCT_ALLERGENS_LIST);
const getProductAllergensListSaga = createRequestSaga(
  GET_PRODUCT_ALLERGENS_LIST,
  productApi.getProductAllergensList,
);
export const initializeProductAllergensList = createAction(
  INITIALIZE_PRODUCT_ALLERGENS_LIST,
);
export const addAllergensList = createAction(ADD_PRODUCT_ALLERGENS_LIST);
const addProductAllergensListSaga = function* ({ payload }) {
  const { isOdourless } = payload;
  yield put(startLoading(ADD_PRODUCT_ALLERGENS_LIST));
  try {
    yield call(productApi.addProductAllergensList, createFormData(payload));
    const { skipMode, manuMode, documentCodes } = yield select(
      ({ product, company }) => ({
        skipMode: product.skipMode,
        manuMode: company.company?.isManufacturer,
        documentCodes: !company.company?.isManufacturer
          ? product.documentCodes
          : product.manuDocumentCodes,
      }),
    );
    // HINT : 유향, skipMode일 경우 documentCodes에 IFRA 추가
    if (!isOdourless && skipMode) {
      const index = documentCodes.indexOf('alle');
      const newDocumentCodes = [
        ...documentCodes.slice(0, index + 1),
        'ifra',
        ...documentCodes.slice(index + 1),
      ];
      yield put(
        (!manuMode ? setDocumentCodes : manuSetDocumentCodes)(newDocumentCodes),
      );
      yield put((!manuMode ? setNextPathname : manuSetNextPathname)('alle'));
    }
    yield put({ type: ADD_PRODUCT_ALLERGENS_LIST_SUCCESS });
  } catch (error) {
    console.error(error);
  }
  yield put(finishLoading(ADD_PRODUCT_ALLERGENS_LIST));
};
export const updateAllergensList = createAction(UPDATE_PRODUCT_ALLERGENS_LIST);
const updateProductAllergensListSaga = createRequestSaga(
  UPDATE_PRODUCT_ALLERGENS_LIST,
  productApi.updateProductAllergensList,
);

// Third Party Test Report
export const addProductThirdPartyTestReport = createAction(
  ADD_PRODUCT_THIRD_PARTY_TEST_REPORT,
);
const addProductThirdPartyTestReportSaga = createRequestSaga(
  ADD_PRODUCT_THIRD_PARTY_TEST_REPORT,
  productApi.addProductThirdPartyTestReport,
);
export const updateProductThirdPartyTestReport = createAction(
  UPDATE_PRODUCT_THIRD_PARTY_TEST_REPORT,
);
const updateProductThirdPartyTestReportSaga = function* ({ payload }) {
  const { newThirdPartyReports, productThirdPartyTestIdsBeDeleted } = payload;
  yield put(startLoading(UPDATE_PRODUCT_THIRD_PARTY_TEST_REPORT));
  try {
    if (newThirdPartyReports) {
      yield call(
        productApi.addProductThirdPartyTestReport,
        newThirdPartyReports,
      );
    }
    if (productThirdPartyTestIdsBeDeleted.length) {
      for (const productThirdPartyTestId of productThirdPartyTestIdsBeDeleted) {
        yield call(
          productApi.deleteProductThirdPartyTestReport,
          productThirdPartyTestId,
        );
      }
    }
    yield put({ type: UPDATE_PRODUCT_THIRD_PARTY_TEST_REPORT_SUCCESS });
  } catch (error) {
    console.error(error);
  }
  yield put(finishLoading(UPDATE_PRODUCT_THIRD_PARTY_TEST_REPORT));
};

export const getProductThirdPartyTestReports = createAction(
  GET_PRODUCT_THIRD_PARTY_TEST_REPORTS,
);
const getProductThirdPartyTestReportsSaga = createRequestSaga(
  GET_PRODUCT_THIRD_PARTY_TEST_REPORTS,
  productApi.getProductThirdPartyTestReports,
);
export const initializeProductThirdPartyTestReports = createAction(
  INITIALIZE_PRODUCT_THIRD_PARTY_TEST_REPORTS,
);

// Packing Attestation

export const savePackingAttestationType = createAction(
  SAVE_PACKING_ATTESTATION_TYPE,
);
const savePackingAttestationTypeSaga = createRequestSaga(
  SAVE_PACKING_ATTESTATION_TYPE,
  productApi.savePackingAttestationType,
  {
    onSuccess: function* () {
      const skipMode = yield select(({ product }) => product.skipMode);
      if (skipMode) {
        yield put({ type: SKIP_PAGE });
      } else {
        const history = yield getContext('history');
        history.goBack();
      }
    },
  },
);

// Product Specifications
export const initializeProductSpecifications = createAction(
  INITIALIZE_PRODUCT_SPECIFICATIONS,
);
export const getProductSpecifications = createAction(
  GET_PRODUCT_SPECIFICATIONS,
);
const getProductSpecificationsSaga = createRequestSaga(
  GET_PRODUCT_SPECIFICATIONS,
  productApi.getProductSpecifications,
);
export const saveProductSpecifications = createAction(
  SAVE_PRODUCT_SPECIFICATIONS,
);
const saveProductSpecificationsSaga = createRequestSaga(
  SAVE_PRODUCT_SPECIFICATIONS,
  productApi.addProductSpecifications,
  {
    onSuccess: function* () {
      message.success('입력하신 데이터가 임시 저장되었습니다.');
    },
  },
);
export const addProductSpecifications = createAction(
  ADD_PRODUCT_SPECIFICATIONS,
);
const addProductSpecificationsSaga = createRequestSaga(
  ADD_PRODUCT_SPECIFICATIONS,
  productApi.addProductSpecifications,
);
export const updateProductSpecifications = createAction(
  UPDATE_PRODUCT_SPECIFICATIONS,
);
const updateProductSpecificationsSaga = createRequestSaga(
  UPDATE_PRODUCT_SPECIFICATIONS,
  productApi.updateProductSpecifications,
);

// 제조사
export const importProducts = createAction(IMPORT_PRODUCTS);
const importProductsSaga = createRequestSaga(
  IMPORT_PRODUCTS,
  productApi.importProducts,
  {
    onSuccess: function* () {
      const history = yield getContext('history');
      history.push(path.product.ready);
    },
  },
);

// 경내 책임 회사
export const initializeProductInternalResponsibleCompany = createAction(
  INITIALIZE_PRODUCT_INTERNAL_RESPONSIBLE_COMPANY,
);
export const getProductInternalResponsibleCompany = createAction(
  GET_PRODUCT_INTERNAL_RESPONSIBLE_COMPANY,
);
const getProductInternalResponsibleCompanySaga = createRequestSaga(
  GET_PRODUCT_INTERNAL_RESPONSIBLE_COMPANY,
  productApi.getProductInternalResponsibleCompany,
);
export const addProductInternalResponsibleCompany = createAction(
  ADD_PRODUCT_INTERNAL_RESPONSIBLE_COMPANY,
);
const addProductInternalResponsibleCompanySaga = createRequestSaga(
  ADD_PRODUCT_INTERNAL_RESPONSIBLE_COMPANY,
  productApi.addProductInternalResponsibleCompany,
);
export const updateProductInternalResponsibleCompany = createAction(
  UPDATE_PRODUCT_INTERNAL_RESPONSIBLE_COMPANY,
);
const updateProductInternalResponsibleCompanySaga = createRequestSaga(
  UPDATE_PRODUCT_INTERNAL_RESPONSIBLE_COMPANY,
  productApi.updateProductInternalResponsibleCompany,
);

export const productSaga = function* () {
  // 공통
  yield takeLatest(
    ({ type }) =>
      /^product\/(MANU_)?ADD_PRODUCT.*_SUCCESS$/.test(type) ||
      type === UPDATE_CURRENT_PATHNAME ||
      type === SKIP_PAGE,
    moveToNextPathnameSaga,
  );
  yield takeLatest(
    ({ type }) =>
      /^product\/(MANU_)?UPDATE_PRODUCT.*_SUCCESS$/.test(type) ||
      type === UPDATE_CHINESE_PRODUCT_NAME_SUCCESS ||
      type === UPDATE_JAPANESE_PRODUCT_NAME_SUCCESS ||
      type === UPDATE_COMPANY_INFO_SUCCESS ||
      type === 'buyer/UPDATE_PRODUCT_BUYERS_SUCCESS',
    updateProductSuccessSaga,
  );
  yield takeLatest(ADD_PRODUCT_DOC_STATUS, addDocStatusSaga);
  yield takeLatest(UPDATE_PRODUCT_DOC_STATUS, updateDocStatusSaga);
  yield takeLatest(GET_DOCUMENT_LOGS, getDocumentLogsSaga);

  // Product
  yield takeLatest(MANU_GET_PRODUCTS, manuGetProductsSaga);

  yield takeLatest(UPDATE_PRODUCT_NAME, updateProductNameSaga);
  yield takeLatest(UPDATE_CHINESE_PRODUCT_NAME, updateChineseProductNameSaga);
  yield takeLatest(UPDATE_JAPANESE_PRODUCT_NAME, updateJapaneseProductNameSaga);

  yield takeLatest(UPDATE_COMPANY_INFO, updateCompanyInfoSaga);

  yield takeLatest(REGISTER_PRODUCT, registerProductSaga);
  yield takeLatest(MANU_REGISTER_PRODUCT, manuRegisterProductSaga);
  yield takeLatest(GET_DOCUMENTS, getDocumentsSaga);
  yield takeLatest(MANU_GET_DOCUMENTS, manuGetDocumentsSaga);
  yield takeLatest(ADD_PRODUCT, addProductSaga);
  yield takeLatest(MANU_ADD_PRODUCT, manuAddProductSaga);
  yield takeLatest(UPDATE_PRODUCT, updateProductSaga);
  yield takeLatest(MANU_UPDATE_PRODUCT, manuUpdateProductSaga);
  yield takeLatest(SET_PRODUCT_EXPIRE_MONTHS, setProductExpireMonthsSaga);

  // Country
  yield takeLatest(GET_COUNTRIES, getCountriesSaga);
  yield takeLatest(ADD_PRODUCT_COUNTRY, addProductCountrySaga);
  yield takeLatest(ADD_PRODUCT_COUNTRY_SUCCESS, addProductCountrySuccessSaga);
  yield takeLatest(UPDATE_PRODUCT_COUNTRY, updateProductCountrySaga);
  yield takeLatest(
    UPDATE_PRODUCT_COUNTRY_SUCCESS,
    updateProductCountrySuccessSaga,
  );

  // Category
  yield takeLatest(GET_CATEGORIES, getCategoriesSaga);
  yield takeLatest(ADD_PRODUCT_CATEGORY, addProductCategorySaga);

  // Physical Property
  yield takeLatest(
    GET_PRODUCT_PHYSICAL_PROPERTY,
    getProductPhysicalPropertySaga,
  );
  yield takeLatest(
    ADD_PRODUCT_PHYSICAL_PROPERTY,
    addProductPhysicalPropertySaga,
  );
  yield takeLatest(
    UPDATE_PRODUCT_PHYSICAL_PROPERTY,
    updateProductPhysicalPropertySaga,
  );

  // Chemical Property
  yield takeLatest(
    ADD_PRODUCT_CHEMICAL_PROPERTY,
    addProductChemicalPropertySaga,
  );

  // Microbiological Property
  yield takeLatest(
    ADD_PRODUCT_MICROBIOLOGICAL_PROPERTY,
    addProductMicroBiologicalPropertySaga,
  );

  // Artwork
  yield takeLatest(ADD_PRODUCT_ARTWORK, addProductArtworkSaga);
  yield takeLatest(GET_PRODUCT_ARTWORKS, getProductArtworksSaga);
  yield takeLatest(UPDATE_PRODUCT_ARTWORK, updateProductArtworkSaga);

  //CGMP
  yield takeLatest(ADD_PRODUCT_CGMP, addProductCGMPSaga);
  yield takeLatest(UPDATE_PRODUCT_CGMP, updateProductCGMPSaga);

  // Stability Test
  yield takeLatest(ADD_PRODUCT_STABILITY_TEST, addProductStabilityTestSaga);
  yield takeLatest(GET_PRODUCT_STABILITY_TEST, getProductStabilityTestSaga);
  yield takeLatest(
    UPDATE_PRODUCT_STABILITY_TEST,
    updateProductStabilityTestSaga,
  );

  // Challenge Test
  yield takeLatest(ADD_PRODUCT_CHALLENGE_TEST, addProductChallengeTestSaga);
  yield takeLatest(GET_PRODUCT_CHALLENGE_TEST, getProductChallengeTestSaga);
  yield takeLatest(
    UPDATE_PRODUCT_CHALLENGE_TEST,
    updateProductChallengeTestSaga,
  );

  // Packing Attestation
  yield takeLatest(
    ADD_PRODUCT_PACKING_ATTESTATION,
    addProductPackingAttestationSaga,
  );
  yield takeLatest(
    GET_PRODUCT_PACKING_ATTESTATION,
    getProductPackingAttestationSaga,
  );
  yield takeLatest(
    UPDATE_PRODUCT_PACKING_ATTESTATION,
    updateProductPackingAttestationSaga,
  );

  // Allergens List
  yield takeLatest(GET_PRODUCT_ALLERGENS_LIST, getProductAllergensListSaga);
  yield takeLatest(ADD_PRODUCT_ALLERGENS_LIST, addProductAllergensListSaga);
  yield takeLatest(
    UPDATE_PRODUCT_ALLERGENS_LIST,
    updateProductAllergensListSaga,
  );

  // Third Party Test Report
  yield takeLatest(
    ADD_PRODUCT_THIRD_PARTY_TEST_REPORT,
    addProductThirdPartyTestReportSaga,
  );
  yield takeLatest(
    UPDATE_PRODUCT_THIRD_PARTY_TEST_REPORT,
    updateProductThirdPartyTestReportSaga,
  );
  yield takeLatest(
    GET_PRODUCT_THIRD_PARTY_TEST_REPORTS,
    getProductThirdPartyTestReportsSaga,
  );

  // Packing Attestation
  yield takeLatest(
    SAVE_PACKING_ATTESTATION_TYPE,
    savePackingAttestationTypeSaga,
  );

  // Product Specifications
  yield takeLatest(GET_PRODUCT_SPECIFICATIONS, getProductSpecificationsSaga);
  yield takeLatest(SAVE_PRODUCT_SPECIFICATIONS, saveProductSpecificationsSaga);
  yield takeLatest(ADD_PRODUCT_SPECIFICATIONS, addProductSpecificationsSaga);
  yield takeLatest(
    UPDATE_PRODUCT_SPECIFICATIONS,
    updateProductSpecificationsSaga,
  );

  // 제조사
  yield takeLatest(IMPORT_PRODUCTS, importProductsSaga);

  // 경내 책임 회사
  yield takeLatest(
    GET_PRODUCT_INTERNAL_RESPONSIBLE_COMPANY,
    getProductInternalResponsibleCompanySaga,
  );
  yield takeLatest(
    ADD_PRODUCT_INTERNAL_RESPONSIBLE_COMPANY,
    addProductInternalResponsibleCompanySaga,
  );
  yield takeLatest(
    UPDATE_PRODUCT_INTERNAL_RESPONSIBLE_COMPANY,
    updateProductInternalResponsibleCompanySaga,
  );
};

const initialState = {
  skipMode: false,
  nextPathname: null,
  manuProducts: [],
  currentProduct: null,
  countries: [],
  documents: [],
  documentCodes: ['basic', 'cat', 'country', 'phy', 'che'],
  manuDocuments: [],
  manuDocumentCodes: ['basic', 'phy', 'che'],
  documentFieldMap: null,
  documentLogs: [],
  product: {
    productNameEnDupCheck: {
      status: 'INIT',
      duplicatedProduct: null,
      data: null,
    },
  },
  categories: [],
  functionalCategories: [],
  productPhysicalProperty: null,
  productSpecifications: null,
  artwork: [],
  stabilityTest: null,
  challengeTest: null,
  productAllergensList: null,
  packingAttestation: null,
  thirdPartyTestReport: {
    list: [],
  },
  internalResponsibleCompany: null,
};

export default handleActions(
  {
    [SET_SKIP_MODE]: (state, { payload: skipMode }) =>
      produce(state, (draft) => {
        draft.skipMode = skipMode;
      }),
    [SET_NEXT_PATHNAME]: (state, { payload: currentPathname }) =>
      produce(state, (draft) => {
        const currentIndex = state.documentCodes.indexOf(currentPathname);
        draft.nextPathname =
          currentIndex === state.documentCodes.length - 1
            ? 'finish'
            : state.documentCodes[currentIndex + 1];
      }),
    [MANU_SET_NEXT_PATHNAME]: (state, { payload: currentPathname }) =>
      produce(state, (draft) => {
        const currentIndex = state.manuDocumentCodes.indexOf(currentPathname);
        draft.nextPathname =
          currentIndex === state.manuDocumentCodes.length - 1
            ? 'finish'
            : state.manuDocumentCodes[currentIndex + 1];
      }),
    [SET_DOCUMENT_FIELD_MAP]: (state, { payload: documentFieldMap }) =>
      produce(state, (draft) => {
        draft.documentFieldMap = documentFieldMap;
      }),
    [GET_DOCUMENT_LOGS_SUCCESS]: (state, { payload: documentLogs }) =>
      produce(state, (draft) => {
        draft.documentLogs = documentLogs;
      }),
    [INITIALIZE_DOCUMENT_LOGS]: (state) =>
      produce(state, (draft) => {
        draft.documentLogs = [];
      }),
    [MANU_GET_PRODUCTS_SUCCESS]: (state, { payload: manuProducts }) =>
      produce(state, (draft) => {
        draft.manuProducts = manuProducts;
      }),
    [INITIALIZE_DOCUMENTS]: (state) =>
      produce(state, (draft) => {
        draft.documents = [];
        draft.documentCodes = initialState.documentCodes;
      }),
    [MANU_INITIALIZE_DOCUMENTS]: (state) =>
      produce(state, (draft) => {
        draft.manuDocuments = [];
        draft.manuDocumentCodes = initialState.manuDocumentCodes;
      }),
    [GET_DOCUMENTS_SUCCESS]: (state, { payload: documents }) =>
      produce(state, (draft) => {
        // 정책 : Chemical Properties, Microbiological Properties의 경우 미입력 된 경우에만 노출
        const parsedDocuments = documents
          .filter(
            (document) =>
              (document.documentCode !== 'CHE01' &&
                document.documentCode !== 'MIC01') ||
              document.status === 'INP',
          )
          .map((document, index) => ({
            ...document,
            no: index + 1,
            documentCode: document.documentCode
              .replace(/\d+/, '')
              .toLowerCase(),
          }));
        draft.documents = parsedDocuments;
        draft.documentCodes = parsedDocuments.map(
          ({ documentCode }) => documentCode,
        );
      }),
    [MANU_GET_DOCUMENTS_SUCCESS]: (state, { payload: documents }) =>
      produce(state, (draft) => {
        // 정책 : Chemical Properties, Microbiological Properties의 경우 미입력 된 경우에만 노출
        const parsedDocuments = documents
          .filter(
            (document) =>
              (document.documentCode !== 'CHE01' &&
                document.documentCode !== 'MIC01') ||
              document.status === 'INP',
          )
          .map((document, index) => ({
            ...document,
            no: index + 1,
            documentCode: document.documentCode
              .replace(/\d+/, '')
              .toLowerCase(),
          }));
        draft.manuDocuments = parsedDocuments;
        // 정책 : cgmp는 skip모드에서 패스
        draft.manuDocumentCodes = parsedDocuments
          .filter(({ documentCode }) => documentCode !== 'cgmp')
          .map(({ documentCode }) => documentCode);
      }),
    [SET_CURRENT_DOC_STATUS]: (state, { payload: currentDocStatus }) =>
      produce(state, (draft) => {
        draft.currentDocStatus = currentDocStatus;
      }),
    [SET_DOCUMENT_CODES]: (state, { payload: documentCodes }) =>
      produce(state, (draft) => {
        draft.documentCodes = documentCodes;
      }),
    [MANU_SET_DOCUMENT_CODES]: (state, { payload: manuDocumentCodes }) =>
      produce(state, (draft) => {
        draft.manuDocumentCodes = manuDocumentCodes;
      }),
    [GET_COUNTRIES_SUCCESS]: (state, { payload: countries }) =>
      produce(state, (draft) => {
        draft.countries = countries;
      }),
    [GET_CATEGORIES_SUCCESS]: (
      state,
      { payload: [categories, functionalCategories] },
    ) =>
      produce(state, (draft) => {
        draft.categories = categories;
        draft.functionalCategories = functionalCategories;
      }),
    [SET_CURRENT_PRODUCT]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.currentProduct = payload;
      }),
    [ADD_PRODUCT_SUCCESS]: (state, { payload: currentProduct }) =>
      produce(state, (draft) => {
        draft.currentProduct = currentProduct;
      }),
    [UPDATE_PRODUCT_SUCCESS]: (state, { payload: currentProduct }) =>
      produce(state, (draft) => {
        draft.currentProduct = currentProduct;
      }),
    [MANU_ADD_PRODUCT_SUCCESS]: (state, { payload: currentProduct }) =>
      produce(state, (draft) => {
        draft.currentProduct = currentProduct;
      }),
    [GET_PRODUCT_PHYSICAL_PROPERTY_SUCCESS]: (
      state,
      { payload: productPhysicalProperty },
    ) =>
      produce(state, (draft) => {
        draft.productPhysicalProperty = productPhysicalProperty;
      }),
    [INITIALIZE_PRODUCT_PHYSICAL_PROPERTY]: (state) =>
      produce(state, (draft) => {
        draft.productPhysicalProperty = null;
      }),
    [GET_PRODUCT_ARTWORKS_SUCCESS]: (state, { payload: artworks }) =>
      produce(state, (draft) => {
        draft.artwork = artworks.map((artwork) => ({
          name: artwork.filename,
          url: artwork.uploadFileUrl,
          ...artwork,
        }));
      }),
    [INITIALIZE_ARTWORK]: (state) =>
      produce(state, (draft) => {
        draft.artwork = [];
      }),
    [INITIALIZE_PRODUCT_STABILITY_TEST]: (state) =>
      produce(state, (draft) => {
        draft.stabilityTest = null;
      }),
    [GET_PRODUCT_STABILITY_TEST_SUCCESS]: (state, { payload: stabilityTest }) =>
      produce(state, (draft) => {
        draft.stabilityTest = stabilityTest;
      }),
    [GET_PRODUCT_CHALLENGE_TEST_SUCCESS]: (state, { payload: challengeTest }) =>
      produce(state, (draft) => {
        if (challengeTest !== null) {
          draft.challengeTest = Object.fromEntries(
            Object.entries(challengeTest).filter((entry) => entry[1] !== null),
          );
        }
      }),
    [INITIALIZE_PRODUCT_CHALLENGE_TEST]: (state) =>
      produce(state, (draft) => {
        draft.challengeTest = null;
      }),
    [GET_PRODUCT_ALLERGENS_LIST_SUCCESS]: (
      state,
      { payload: productAllergensList },
    ) =>
      produce(state, (draft) => {
        draft.productAllergensList = productAllergensList;
      }),
    [INITIALIZE_PRODUCT_ALLERGENS_LIST]: (state) =>
      produce(state, (draft) => {
        draft.productAllergensList = null;
      }),
    [GET_PRODUCT_PACKING_ATTESTATION_SUCCESS]: (
      state,
      { payload: packingAttestation },
    ) =>
      produce(state, (draft) => {
        if (packingAttestation !== null) {
          draft.packingAttestation = {
            ...packingAttestation,
            productPackingAttestationAttaches: packingAttestation.productPackingAttestationAttaches.map(
              (attach) => ({
                ...attach,
                url: attach.attachUrl,
                name: attach.filename,
              }),
            ),
          };
        }
      }),
    [INITIALIZE_PRODUCT_PACKING_ATTESTATION]: (state) =>
      produce(state, (draft) => {
        draft.packingAttestation = null;
      }),
    [GET_PRODUCT_THIRD_PARTY_TEST_REPORTS_SUCCESS]: (
      state,
      { payload: thirdPartyTestReports },
    ) =>
      produce(state, (draft) => {
        draft.thirdPartyTestReport.list = thirdPartyTestReports;
      }),
    [INITIALIZE_PRODUCT_THIRD_PARTY_TEST_REPORTS]: (state) =>
      produce(state, (draft) => {
        draft.thirdPartyTestReport = initialState.thirdPartyTestReport;
      }),
    [SAVE_PACKING_ATTESTATION_TYPE_SUCCESS]: (
      state,
      { meta: { packingAttestationType } },
    ) =>
      produce(state, (draft) => {
        draft.currentProduct.packingAttestationType = packingAttestationType;
      }),
    [GET_PRODUCT_SPECIFICATIONS_SUCCESS]: (
      state,
      { payload: productSpecifications },
    ) =>
      produce(state, (draft) => {
        draft.productSpecifications = productSpecifications;
      }),
    [INITIALIZE_PRODUCT_SPECIFICATIONS]: (state) =>
      produce(state, (draft) => {
        draft.productSpecifications = null;
      }),
    // 경내 책임 회사
    [INITIALIZE_PRODUCT_INTERNAL_RESPONSIBLE_COMPANY]: (state) =>
      produce(state, (draft) => {
        draft.internalResponsibleCompany =
          initialState.internalResponsibleCompany;
      }),
    [GET_PRODUCT_INTERNAL_RESPONSIBLE_COMPANY_SUCCESS]: (
      state,
      { payload: internalResponsibleCompany },
    ) =>
      produce(state, (draft) => {
        draft.internalResponsibleCompany = internalResponsibleCompany;
      }),
    [ADD_PRODUCT_INTERNAL_RESPONSIBLE_COMPANY_SUCCESS]: (
      state,
      { payload: internalResponsibleCompany },
    ) =>
      produce(state, (draft) => {
        draft.internalResponsibleCompany = internalResponsibleCompany;
      }),
    [UPDATE_PRODUCT_INTERNAL_RESPONSIBLE_COMPANY_SUCCESS]: (
      state,
      { payload: internalResponsibleCompany },
    ) =>
      produce(state, (draft) => {
        draft.internalResponsibleCompany = internalResponsibleCompany;
      }),
  },
  initialState,
);
