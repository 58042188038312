import { Col, Row } from 'antd';
import styled from 'styled-components';

import { Typography } from 'components/system';
import { useProductDetail } from 'service/product';
import PageTemplate from 'templates/PageTemplate';

const Container = styled.div`
  .ant-page-header-heading-left {
    align-items: unset;
  }

  .ant-page-header-back {
    padding-top: 12px;
  }

  .ant-page-header-heading-sub-title {
    padding-top: 8px;
  }
`;

const ProductPageTemplate = ({
  productId,
  children,
  subtitle,
  exampleImg,
  ...restProps
}: {
  productId: number;
} & React.ComponentProps<typeof PageTemplate>) => {
  const { productDetail } = useProductDetail({ productId });
  const { netWeight, netWeightUnit, productNameKo } = productDetail || {};
  const { documentCode } = (children as any).props;
  return (
    <Container>
      <PageTemplate
        title="국내 입점서류 관리"
        {...restProps}
        subtitle={
          <>
            <Row align="middle" gutter={16}>
              <Col>
                <Typography.Text style={{ fontSize: 20 }}>
                  {subtitle}
                </Typography.Text>
              </Col>
              {productDetail && (
                <Col>
                  <Row align="middle" gutter={4}>
                    <Col>
                      <Typography.Text
                        type="tertiary"
                        color="slate"
                        style={{ opacity: 0.5 }}
                      >
                        국문 제품명 (용량)
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text
                        type="primary"
                        medium
                        color="slate"
                        style={{
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        {`${
                          productNameKo || '-'
                        } (${netWeight} ${netWeightUnit})`}
                      </Typography.Text>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </>
        }
        exampleImg={
          exampleImg ||
          (typeof documentCode !== 'undefined'
            ? `${documentCode}_V1.png`
            : undefined)
        }
      >
        {children}
      </PageTemplate>
    </Container>
  );
};

export default ProductPageTemplate;
