import ProductSampleReadyList from 'components/manufacturer/productSample/ProductSampleReadyList';
import PageTemplate from 'templates/PageTemplate';

const notices = [
  '샘플 수정 및 샘플 확정은 ‘나의 샘플 목록’에서 할 수 있습니다.',
  '처방 상세를 다른 유저가 볼 수 없게 하려면 ‘나의 샘플 목록’에서 처방 상세 공개를 비공개로 설정해 주세요.',
  '샘플 확정 후에는 확정 취소 및 수정이 불가능하니, 완제품 기준 확정된 데이터로 입력해 주세요.',
  '현재 등록 중인 모든 샘플의 목록과 공개된 처방 상세의 내용은 ‘전체 샘플 목록’에서 확인할 수 있습니다.',
];

const ProductSampleReadyListPage = () => {
  return (
    <PageTemplate title="등록 중 샘플 관리" back={false} notices={notices}>
      <ProductSampleReadyList />
    </PageTemplate>
  );
};

export default ProductSampleReadyListPage;
