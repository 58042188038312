import React, { useEffect, useMemo, useState } from 'react';
import {
  Badge,
  Button,
  Col,
  Modal,
  Row,
  Statistic,
  Table,
  Typography as Typo,
} from 'antd';
import styled from 'styled-components';

import FooterBox from 'components/FooterBox';
import palette from 'lib/styles/palette';
import { Typography } from 'components/system';
import { useProductDetail } from 'service/manufacturer/productDetail';
import ProductInputRequestModal from './ProductInputRequestModal';
import { useProductInputRequest } from 'service/manufacturer/productInputRequest';
import history from 'lib/history';
import { useUser } from 'service/auth';
import { documentNameMap } from 'lib/documentNames';
import StatisticCard from 'components/StatisticCard';
import path from 'lib/path';

const ProductDetailBlock = styled.div``;

export const ProductDetail = ({
  productId,
  status,
  documentCode,
  setRegisterable,
}: {
  productId: number;
  status?: string;
  documentCode?: string;
  setRegisterable: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [
    documentCodeForInputRequest,
    setDocumentCodeForInputRequest,
  ] = useState<string | null>(null);
  const { userId: currentUserId } = useUser();
  const {
    currentProduct: product,
    documents,
    registerable,
    registerLoading,
    fetchLoading,
    registerProduct,
    showDocument,
    getManuDocuments,
  } = useProductDetail(productId);
  useEffect(() => {
    setRegisterable(registerable);
  }, [registerable]);
  useEffect(() => {
    getManuDocuments();
  }, [documentCode, status]);
  const { showOngoingRequestModal, showRequestModal } = useProductInputRequest({
    productId,
  });
  const columns: any = useMemo(
    () =>
      !product
        ? []
        : [
            { title: '구분', dataIndex: 'no', align: 'center' },
            {
              title: '입력정보',
              render: (document: any) => (
                <Typography.Text
                  type="secondary"
                  style={{
                    fontWeight: 500,
                    cursor: 'pointer',
                    color: palette.text.black,
                  }}
                  onClick={() => showDocument(document)}
                >
                  {document.documentName}
                </Typography.Text>
              ),
            },
            {
              title: '처리현황',
              align: 'center',
              render: (document: any) =>
                document.status === 'INP' ? (
                  <Typography.Label
                    type="primary"
                    onClick={() => {
                      showDocument(document);
                    }}
                  >
                    입력 필요
                    <i className="material-icons outlined md-12">
                      chevron_right
                    </i>
                  </Typography.Label>
                ) : (
                  <Typo.Text>{document.statusString}</Typo.Text>
                ),
            },
            ...(product.manufacturerStatus === 'PRD-RDY'
              ? [
                  {
                    title: '입력 요청',
                    align: 'center',
                    render: (document: any) => {
                      if (document.status !== 'INP') return null;
                      if (
                        !document.inputRequest ||
                        document.inputRequest.status !== 'REQUEST'
                      ) {
                        return (
                          <Typography.Label
                            type="gray"
                            onClick={() =>
                              setDocumentCodeForInputRequest(
                                document.documentCode,
                              )
                            }
                            bordered
                            style={{ border: '1px solid #d3d3d3' }}
                          >
                            입력 요청
                            <i className="material-icons outlined md-12">
                              chevron_right
                            </i>
                          </Typography.Label>
                        );
                      }
                      const { status, toUserId } = document.inputRequest;
                      if (status === 'REQUEST') {
                        if (toUserId === currentUserId) {
                          return (
                            <Badge
                              dot
                              style={{
                                top: 6,
                                right: 11,
                                backgroundColor: palette.error[0],
                                minWidth: 0,
                                width: 4,
                                height: 4,
                              }}
                            >
                              <Typography.Label
                                type="primary"
                                onClick={() => {
                                  showRequestModal(document);
                                }}
                                bordered
                                style={{ padding: '4px 0 0 0' }}
                              >
                                받은 요청
                              </Typography.Label>
                            </Badge>
                          );
                        } else {
                          return (
                            <Typography.Label
                              type="disabled"
                              onClick={() => {
                                showOngoingRequestModal(document);
                              }}
                              style={{ border: '1px solid #d3d3d3' }}
                            >
                              요청 중
                              <i className="material-icons outlined md-12">
                                chevron_right
                              </i>
                            </Typography.Label>
                          );
                        }
                      }
                    },
                  },
                ]
              : []),
            {
              title: '최근 입력자 명',
              align: 'center',
              render: (document: any) =>
                document.modifierName
                  ? `${document.modifierName} (${document.modifierCompanyName})`
                  : '',
            },
            {
              title: '입력 일시',
              align: 'center',
              render: (document: any) =>
                document.modifierName && document.updateDate
                  ? document.updateDate
                  : '',
            },
          ],
    [product, showDocument, showOngoingRequestModal, showRequestModal],
  );

  useEffect(() => {
    if (status && documentCode) {
      const targetDocument = documents.find(
        (document: any) =>
          documentCode.replace('01', '').toLowerCase() ===
          document.documentCode,
      );
      if (status === 'CANCEL') {
        Modal.info({
          width: 400,
          style: { textAlign: 'center' },
          bodyStyle: {
            marginTop: 24,
          },
          icon: null,
          closable: true,
          content: (
            <p>
              요청자에 의해 {documentNameMap[documentCode]} 의<br /> 입력 요청이
              취소되었습니다.
            </p>
          ),
        });
      }
      if (status === 'REQUEST' && targetDocument?.status === 'FIN') {
        Modal.info({
          width: 400,
          style: { textAlign: 'center' },
          bodyStyle: {
            marginTop: 24,
          },
          icon: null,
          closable: true,
          content: (
            <p>{documentNameMap[documentCode]}가 이미 입력 완료되었습니다.</p>
          ),
        });
      }
      history.replace(`${path.manufacturer.product.root}${productId}`);
    }
  }, [status, productId, documentCode, documents]);
  return (
    <ProductDetailBlock>
      {documentCodeForInputRequest && (
        <ProductInputRequestModal
          documentCode={documentCodeForInputRequest}
          productId={product?.productId}
          productName={`${product?.manufacturerItemNameKo || '-'} ${
            product?.netWeight
              ? `(${product?.netWeight} ${product?.netWeightUnit})`
              : '( - )'
          }`}
          onClose={() => setDocumentCodeForInputRequest(null)}
        />
      )}
      <StatisticCard>
        <Row gutter={[24, 24]}>
          <Col span={9}>
            <Statistic
              title="자체 품목명 (용량)"
              value={`${product?.manufacturerItemNameKo} (${product?.netWeight} ${product?.netWeightUnit})`}
            />
          </Col>
          <Col span={8}>
            <Statistic
              title="Lot No."
              groupSeparator=""
              value={
                product?.productLotNos.length > 0
                  ? product?.productLotNos[0].lotNo
                  : '-'
              }
            />
          </Col>
          <Col span={7}>
            <Statistic
              title="제조사"
              value={product?.productDetail.manufacturerNameKo}
            />
          </Col>
          <Col span={9}>
            <Statistic
              title="샘플 랩넘버"
              groupSeparator=""
              value={product?.productLabNos[0].labNo}
            />
          </Col>
          <Col span={8}>
            <Statistic
              title="샘플 담당자"
              value={
                product?.isUsedProductSample
                  ? `${product?.productSampleRegisterUserName} (${product?.productSampleRegisterUserDeptName})`
                  : '-'
              }
            />
          </Col>
        </Row>
      </StatisticCard>
      <Table
        size="small"
        columns={columns}
        rowKey={(row) => row.documentCode}
        dataSource={documents}
        pagination={false}
        loading={fetchLoading}
        style={{ marginTop: 30 }}
      />
      {registerable && (
        <FooterBox>
          <Button
            type="primary"
            loading={registerLoading}
            onClick={registerProduct}
          >
            등록 완료
          </Button>
        </FooterBox>
      )}
    </ProductDetailBlock>
  );
};
