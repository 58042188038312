import client from 'lib/api/client';
import { IAPIResponse } from 'types/common';
import {
  IMarketingTemplateGet,
  IMarketingUploadGet,
} from 'types/material/marketing';

export const getMarketingTemplate = async (materialId: number) => {
  const res = await client.get<IAPIResponse<IMarketingTemplateGet>>(
    `/material/marketing/template/${materialId}`,
  );
  return res.data;
};

export const addMarketingTemplate = async (params: {
  formData: FormData;
  materialId: number;
}) => {
  const res = await client.post<
    IAPIResponse<{
      attachUrl: string;
      fileName: string;
    }>
  >(`/material/marketing/template/${params.materialId}`, params.formData);
  return res.data;
};

export const updateMarketingTemplate = async (params: {
  materialId: number;
  formData: FormData;
}) => {
  const res = await client.patch<
    IAPIResponse<{
      attachUrl: string;
      fileName: string;
    }>
  >(`/material/marketing/template/${params.materialId}`, params.formData);
  return res.data;
};

export const getMarketingUpload = async (materialId: number) => {
  const res = await client.get<IAPIResponse<IMarketingUploadGet>>(
    `/material/marketing/upload/${materialId}`,
  );
  return res.data;
};

export const addMarketingUpload = async (params: {
  materialId: number;
  formData: FormData;
}) => {
  const res = await client.post<
    IAPIResponse<{
      attachUrl: string;
      fileName: string;
    }>
  >(`/material/marketing/upload/${params.materialId}`, params.formData);
  return res.data;
};

export const updateMarketingUpload = async (params: {
  materialId: number;
  formData: FormData;
}) => {
  const res = await client.patch<
    IAPIResponse<{
      attachUrl: string;
      fileName: string;
    }>
  >(`/material/marketing/upload/${params.materialId}`, params.formData);
  return res.data;
};

export const deleteMarketing = async (materialMarketingDataId: number) => {
  const res = await client.delete<IAPIResponse<null>>(
    `material/marketing/${materialMarketingDataId}`,
  );
  return res.data;
};

export const getTemplateStatus = async (materialId: number) => {
  const res = await client.get<
    IAPIResponse<{
      [key: string]: string;
    }>
  >(`material/marketing/template/status/${materialId}`);

  return res.data;
};
