import { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import palette from 'lib/styles/palette';
import { useIsMobile } from 'hook/etc';
import InfoIcon from '../general/icon/InfoIcon';

const TipBlock = styled.div<{
  $isMobile: boolean;
}>`
  position: ${({ $isMobile }) => ($isMobile ? 'static' : 'relative')};
  vertical-align: middle;
`;

const IconWrap = styled.div``;

const TipBodyBlock = styled.div<{
  $visible: boolean;
  $isMobile: boolean;
  $trigger: 'hover' | 'click';
}>`
  width: auto;
  // HINT: 데스크탑의 경우 opacity로 on / off
  opacity: ${({ $isMobile, $visible }) => ($isMobile || $visible ? 1 : 0)};
  // HINT: 모바일의 경우 display로 on / off
  display: ${({ $isMobile, $visible }) =>
    !$isMobile || $visible ? 'block' : 'none'};
  transform-origin: left top;
  transform: scale(
    ${({ $isMobile, $visible }) => ($isMobile || $visible ? 1 : 0)}
  );
  ${({ $isMobile }) =>
    !$isMobile
      ? css`
          position: absolute;
          top: 0;
          left: calc(100% + 8px);
          transition: opacity 0.2s ease, transform 0.1s ease;
        `
      : css`
          position: fixed;
          top: 50%;
          left: 50%;
          max-width: calc(100vw - 32px);
          transform: translate(-50%, -50%);
          opacity: 1;
        `}
  color: ${palette.text.black};
  min-width: 50px;
  z-index: 10;
  white-space: nowrap;
  padding: 12px 16px;
  ${({ $trigger }) =>
    $trigger &&
    css`
      padding-right: 32px;
    `}
  border: 1px solid ${palette.primary};
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(162, 162, 162, 0.23);
  font-size: 12px;

  i.material-icons {
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px;
    cursor: pointer;
  }
`;

interface IProps {
  children: React.ReactNode;
  icon?: React.ReactChild;
  trigger?: 'hover' | 'click';
  isVisible?: boolean;
  style?: React.CSSProperties;
  bodyStyle?: React.CSSProperties;
}

const Tip = ({
  children,
  icon,
  trigger = 'hover',
  isVisible,
  style,
  bodyStyle,
}: IProps) => {
  const [visible, setVisible] = useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    setVisible(!!isVisible);
  }, [isVisible]);
  return (
    <TipBlock style={style} $isMobile={isMobile}>
      <IconWrap
        onClick={() => {
          (isMobile || trigger === 'click') && setVisible((draft) => !draft);
        }}
        onMouseEnter={() =>
          !isMobile && trigger === 'hover' && setVisible(true)
        }
        onMouseLeave={() =>
          !isMobile && trigger === 'hover' && setVisible(false)
        }
      >
        {icon ? icon : <InfoIcon style={{ cursor: 'pointer' }} />}
      </IconWrap>
      <TipBodyBlock
        $visible={visible}
        $isMobile={isMobile}
        $trigger={trigger}
        style={bodyStyle}
      >
        {children}
        {(isMobile || trigger === 'click') && (
          <i
            className="material-icons md-12 primary"
            onClick={(e) => {
              e.stopPropagation();
              (isMobile || trigger === 'click') && setVisible(false);
            }}
          >
            close
          </i>
        )}
      </TipBodyBlock>
    </TipBlock>
  );
};

export default Tip;
