import { Button, Col, message, Modal, Row, Select, Space, Table } from 'antd';
import { useMemo, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { Typography } from 'components/system';
import {
  ERegisterSearchType,
  ERegisterStatus,
  IMaterialRegister,
} from 'types/material/register';
import SearchInput from 'components/system/form/SearchInput';
import { useMaterialRegisterManage } from 'service/material/manage';
import palette from 'lib/styles/palette';
import { registerManageSearchTypeOptions, s3AssetDomain } from 'lib/consts';
import path from 'lib/path';

const MaterialDoneBlock = styled.div``;

const StyledBox = styled.div<{ isDone: boolean }>`
  height: 24px;
  padding: 3px 8px;
  border-radius: 4px;
  background-color: ${(props) => (props.isDone ? palette.disabled : '#ffe6e6')};
  color: ${(props) =>
    props.isDone ? palette.text.disabled : palette.error[0]};
`;

const StyledImg = styled.img`
  margin-right: 4px;
  width: 18px;
  height: 18px;
`;

const MaterialDone = () => {
  const [pageNo, setPageNo] = useState(1);
  const [searchInput, setSearchInput] = useState('');
  const [searchType, setSearchType] = useState<ERegisterSearchType>(
    ERegisterSearchType.ALL,
  );
  const [selectedMaterialIds, setSelectedMaterialIds] = useState<number[]>([]);
  const history = useHistory();
  const {
    materialRegisters,
    getMaterialRegistersLoading,
    refetchRegisters,
    discontinueMaterials,
    discontinueMaterialsLoading,
  } = useMaterialRegisterManage({
    status: ERegisterStatus.RM_REG,
    pageNo,
    ...(searchInput.trim().length > 0 && { [searchType]: searchInput }),
  });

  const handleClickMaterial = (materialId: number) => {
    history.push(`${path.material.rawMaterial.root}/${materialId}`);
  };

  const columns: ColumnsType<IMaterialRegister> = useMemo(
    () => [
      {
        title: '원료명',
        dataIndex: 'materialNameEn',
        align: 'center',
        render: (materialNameEn, record) => (
          <Row align="middle" gutter={[8, 8]}>
            <Col>
              <Typography.Text
                type="secondary"
                color="black"
                style={{ fontWeight: 500, cursor: 'pointer' }}
                onClick={() => handleClickMaterial(record.materialId)}
              >
                {materialNameEn}
              </Typography.Text>
            </Col>
            {record.isDiscontinued && (
              <Col>
                <StyledBox isDone>단종 원료</StyledBox>
              </Col>
            )}
            {!record.isDiscontinued &&
              record.uploadExpiredDate !== null &&
              typeof record.uploadExpiredDate !== 'undefined' &&
              record.uploadExpiredDate >= 0 && (
                <Col>
                  <StyledBox isDone={false}>
                    {record.uploadExpiredDate
                      ? `업로드 만료 D-${record.uploadExpiredDate}`
                      : '업로드 만료일'}
                  </StyledBox>
                </Col>
              )}
          </Row>
        ),
      },
      {
        title: '실 생산자명',
        align: 'center',
        dataIndex: 'originManufacturerName',
      },
      {
        title: '실 생산자명 공개 여부',
        align: 'center',
        dataIndex: 'isShowManufacturerName',
        render: (isShowManufacturerName) => (
          <div>{isShowManufacturerName ? '공개' : '비공개'}</div>
        ),
      },
      {
        title: '원료 등록일',
        align: 'center',
        dataIndex: 'finishRegisterDt',
        render: (finishRegisterDt) => {
          return finishRegisterDt ? finishRegisterDt.replace('T', ' ') : '-';
        },
      },
      {
        title: '원료 등록자',
        align: 'center',
        dataIndex: 'finishRegisterName',
        render: (finishRegisterName, record) => (
          <div>{`${finishRegisterName || '-'} ${
            record.finishRegisterDeptName
              ? `(${record.finishRegisterDeptName})`
              : ''
          }`}</div>
        ),
      },
    ],
    [],
  );

  const handleDiscontinueMaterials = () => {
    if (!selectedMaterialIds.length) return;
    Modal.confirm({
      style: { textAlign: 'center' },
      title: '해당 원료를 단종 처리하시겠습니까?',
      content:
        '원료 정보는 현재와 같이 유지되지만 단종 원료이므로, 제조사로부터 원료 자료 요청을 받을 수 없게 됩니다.',
      icon: null,
      onOk: () => {
        discontinueMaterials(selectedMaterialIds);
        setSelectedMaterialIds([]);
      },
    });
  };

  const searchRegisters = (input: string) => {
    if (input.trim().length === 0) {
      return message.warning('검색어를 입력해주세요.');
    }
    setPageNo(1);
    setSearchInput(input);
    setTimeout(refetchRegisters);
  };

  const handleSelectChange = (type: ERegisterSearchType) => {
    setSearchType(type);
    setSearchInput('');
    setTimeout(refetchRegisters);
  };

  return (
    <MaterialDoneBlock>
      <Space
        style={{
          width: '100%',
          justifyContent: 'flex-end',
          marginBottom: 24,
        }}
      >
        <Select
          options={registerManageSearchTypeOptions}
          value={searchType}
          onChange={(type) => handleSelectChange(type)}
          style={{ width: 115 }}
        />
        <SearchInput
          defaultValue={searchInput}
          disabled={searchType === ERegisterSearchType.ALL}
          placeholder="검색"
          onSearch={searchRegisters}
          style={{ width: 280 }}
        />
      </Space>
      <Table
        columns={columns}
        dataSource={materialRegisters?.content}
        rowKey={({ materialId }) => materialId}
        pagination={{
          current: pageNo,
          pageSize: materialRegisters?.size,
          total: materialRegisters?.totalElements,
          onChange: (page) => {
            setPageNo(page);
            setTimeout(refetchRegisters);
          },
        }}
        rowSelection={{
          selectedRowKeys: selectedMaterialIds,
          onChange: (value) => {
            setSelectedMaterialIds(value as number[]);
          },
        }}
        loading={getMaterialRegistersLoading || discontinueMaterialsLoading}
        footer={() => (
          <Space
            align="center"
            style={{
              marginTop: '16px',
              width: '100%',
            }}
          >
            <Button
              type="default"
              icon={
                <StyledImg
                  src={`${s3AssetDomain}/discontinue_blue.png`}
                  alt="discontinue"
                />
              }
              onClick={handleDiscontinueMaterials}
            >
              단종 처리
            </Button>
          </Space>
        )}
      />
    </MaterialDoneBlock>
  );
};

export default MaterialDone;
