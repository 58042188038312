import { useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import _ from 'lodash';
import { message } from 'antd';

import * as materialApi from 'lib/api/material/ifra';
import { IIFRA, IIFRAAdd, IIFRAUpdate } from 'types/material/ifra';
import history from 'lib/history';
import { messages } from 'lib/consts';

export const useIFRA = (materialId?: number) => {
  const {
    mutate: addIFRAs,
    isLoading: addLoading,
  } = useMutation((payload: IIFRAAdd) => materialApi.addIFRAs(payload));

  const { mutate: updateIFRAs, isLoading: updateLoading } = useMutation(
    (payload: IIFRAUpdate) => materialApi.updateIFRAs(payload),
    {
      onSuccess: () => {
        message.success('수정되었습니다.');
      },
    },
  );

  const { data: ifras = null, isFetching: getListLoading } = useQuery(
    [`/material/allergen/${materialId}`, updateLoading],
    () => materialApi.getIFRAs(materialId!),
    {
      enabled: typeof materialId !== 'undefined',
      select: (res) => res.result,
    },
  );

  const getIngredients = (formValue: { [key: string]: string }) => {
    const ingredients = Object.keys(formValue).map((value) => ({
      materialCategoryId: Number(value),
      categoryPercent: formValue[value],
    }));
    return ingredients;
  };

  const getTobeUpdatedIngredients = (formValue: { [key: string]: string }) => {
    const tobeUpdatedIngredients: {
      materialAllergenIfraId: number;
      categoryPercent: string;
    }[] = [];

    Object.keys(formValue).forEach((id) => {
      const ifra = ifras?.find(
        (ifra) => ifra.materialAllergenIfraId === Number(id),
      );
      if (ifra?.categoryPercent !== formValue[id]) {
        tobeUpdatedIngredients.push({
          materialAllergenIfraId: Number(id),
          categoryPercent: formValue[id],
        });
      }
    });

    return tobeUpdatedIngredients;
  };

  const handleSubmit = (
    formValue: { id: string },
    materialAllergenListId?: number,
  ) => {
    if (ifras?.length === 0) {
      addIFRAs(
        {
          materialAllergenListId: materialAllergenListId!,
          ingredients: getIngredients(formValue),
        },
        { onSuccess: history.goBack },
      );
    } else {
      const ingredients = getTobeUpdatedIngredients(formValue);
      if (ingredients.length > 0) {
        updateIFRAs(
          {
            materialAllergenListId: materialAllergenListId!,
            ingredients,
          },
          { onSuccess: history.goBack },
        );
      } else {
        return message.warn(messages.NO_NEED_TO_UPDATE);
      }
    }
  };

  return useMemo(
    () => ({
      getListLoading,
      ifras,
      addIFRAs,
      addLoading,
      updateIFRAs,
      updateLoading,
      getIngredients,
      getTobeUpdatedIngredients,
      onSubmit: handleSubmit,
    }),
    [ifras, materialId, getListLoading, addLoading, updateLoading],
  );
};

export const useIFRADefault = ({
  ifras,
  materialId,
  getListLoading,
}: {
  ifras: IIFRA[] | null;
  materialId?: number;
  getListLoading: boolean;
}) => {
  const { data: ifraDefaults = null, isFetching: getDefaultLoading } = useQuery(
    [`/material/allergen/tests`, materialId],
    () => materialApi.getIFRADefault(),
    {
      enabled: _.isEmpty(ifras) && !getListLoading,
      select: (res) => res.result,
    },
  );

  return useMemo(
    () => ({
      ifraDefaults,
      getDefaultLoading,
    }),
    [materialId, getDefaultLoading],
  );
};
