import { useCallback, useMemo, useState } from 'react';
import { MutationOptions, useMutation, useQuery } from 'react-query';

import * as msdsApi from 'lib/api/material/msds';
import {
  ICompositionInformation,
  ICompositionInformationUpdate,
  IFirstAidForm,
  IFirstAidGet,
  IFirstAidStatementForm,
  IFirstAidStatementGet,
  IFirstAidStatementUpdate,
  IFirstAidUpdate,
  IHazardsForm,
  IHazardsGet,
  IHazardsUpdate,
  IMsdsAdd,
  IMsdsForm,
  IMsdsUpdate,
} from 'types/material/msds';
import { getUpdatingObject } from 'lib/form';
import { message } from 'antd';
import { messages } from 'lib/consts';
import history from 'lib/history';
import { AxiosResponse } from 'axios';

export const useClassifications = () => {
  const { data: ghsClassifications = [], isFetching: getLoading } = useQuery(
    'material/msds/getClassifications',
    msdsApi.getClassifications,
    {
      select: (res) => res.data.result,
    },
  );

  return useMemo(() => ({ ghsClassifications, getLoading }), [
    ghsClassifications,
    getLoading,
  ]);
};

export const useGetStatementMap = () => {
  const {
    mutate: getStatementMap,
    isLoading: getLoading,
  } = useMutation((materialCategoryIds: number[]) =>
    msdsApi.getStatementMap(materialCategoryIds),
  );
  return useMemo(() => ({ getStatementMap, getLoading }), [
    getStatementMap,
    getLoading,
  ]);
};

export const useMsdsDefaultInfo = (materialId: number) => {
  const { data: msdsDefaultInfo, isFetching: getLoading } = useQuery(
    ['material/msds/getMsdsDefaultInfo'],
    () => msdsApi.getMsdsDefaultInfo(materialId),
    { select: (res) => res.data.result, cacheTime: 0 },
  );

  return useMemo(() => ({ msdsDefaultInfo, getLoading }), [
    msdsDefaultInfo,
    getLoading,
  ]);
};

export const useMsds = (materialId: number) => {
  const [isAddSuccess, setIsAddSuccess] = useState(false);
  const { data: msds = null, isFetching: getLoading } = useQuery(
    ['material/msds/getMsds', materialId],
    () => msdsApi.getMsds(materialId),
    {
      select: (res) => res.data.result,
      cacheTime: 0,
    },
  );

  const { mutate: addMsdsMutate, isLoading: addLoading } = useMutation(
    (msds: IMsdsAdd) => msdsApi.addMsds(msds),
    {
      onMutate: () => {
        setIsAddSuccess(true);
      },
    },
  );

  const addMsds = useCallback(
    (
      {
        hazards: { pictograms, ghsClassifications, ...hazardsRest },
        ...rest
      }: IMsdsForm,
      options: MutationOptions<AxiosResponse<any>, unknown, IMsdsAdd, unknown>,
    ) => {
      const msds: IMsdsAdd = {
        materialId,
        hazards: {
          ghsClassificationIds: ghsClassifications
            .filter(({ depth2GHSId }) => depth2GHSId)
            .map(({ depth2GHSId }) => depth2GHSId),
          pictogramIds: pictograms.map(
            ({ materialMsdsStatementId }) => materialMsdsStatementId,
          ),
          ...hazardsRest,
        },
        ...rest,
      };
      return addMsdsMutate(msds, options);
    },
    [],
  );

  const { mutate: updateMsdsMutate, isLoading: updateLoading } = useMutation(
    (msds: IMsdsUpdate) => msdsApi.updateMsds(msds),
    {
      onSuccess: () => {
        message.success('수정되었습니다.');
        history.goBack();
      },
    },
  );

  const updateMsds = useCallback(
    ({
      msdsBasicInfo,
      fireFighting,
      accidentalRelease,
      handlingStorage,
      exposureControl,
      physicalChemical,
      stabilityReactivity,
      toxicological,
      ecological,
      disposal,
      transport,
      regulatory,
      other,
      hazards,
      firstAid,
      compositionInformations,
    }: IMsdsForm) => {
      if (!msds) {
        throw new Error('Invalid msds');
      }
      const getUpdatingHazards = (
        { pictograms, ghsClassifications, ...hazardsRest }: IHazardsForm,
        {
          ghsClassifications: originGhsClassifications,
          pictograms: originPictograms,
          ...originHazardsRest
        }: IHazardsGet,
      ): IHazardsUpdate | undefined => {
        const newGhsClassificationIds = ghsClassifications.map(
          ({ depth2GHSId }) => depth2GHSId,
        );
        const newPictogramIds = pictograms.map(
          ({ materialMsdsStatementId }) => materialMsdsStatementId,
        );
        const originGhsClassificationIds = originGhsClassifications.map(
          ({ depth2GHSId }) => depth2GHSId,
        );
        const originPictogramIds = originPictograms.map(
          ({ materialMsdsStatementId }) => materialMsdsStatementId,
        );
        const result = {
          ...((originGhsClassificationIds.length !==
            newGhsClassificationIds.length ||
            newGhsClassificationIds.some(
              (ghsClassificationId) =>
                !originGhsClassificationIds.includes(ghsClassificationId),
            )) && {
            ghsClassificationIds: newGhsClassificationIds,
          }),
          ...((originPictogramIds.length !== newPictogramIds.length ||
            newPictogramIds.some(
              (pictogramId) => !originPictogramIds.includes(pictogramId),
            )) && {
            pictogramIds: newPictogramIds,
          }),
          ...getUpdatingObject(hazardsRest, originHazardsRest),
        };
        return Object.keys(result).length > 0 ? result : undefined;
      };

      const getUpdatingFirstAid = (
        newFirstAid: IFirstAidForm,
        originFirstAid: IFirstAidGet,
      ): IFirstAidUpdate | undefined => {
        const getUpdatingFirstAidStatements = (
          newFirstAidStatements: IFirstAidStatementForm[],
          originalFirstAidStatements: IFirstAidStatementGet[],
        ): IFirstAidStatementUpdate[] | undefined => {
          const result: IFirstAidStatementUpdate[] = [];
          newFirstAidStatements.forEach((newFirstAidStatement) => {
            if (!('materialMsdsFirstAidMeasuresId' in newFirstAidStatement)) {
              // HINT: 추가
              result.push(newFirstAidStatement);
            } else {
              const originalFirstAidStatement = originalFirstAidStatements.find(
                ({ materialMsdsFirstAidMeasuresId }) =>
                  materialMsdsFirstAidMeasuresId ===
                  newFirstAidStatement.materialMsdsFirstAidMeasuresId,
              );
              // HINT: 수정 (직접 입력)
              if (
                newFirstAidStatement.statementEn !==
                originalFirstAidStatement?.statementEn
              ) {
                result.push(newFirstAidStatement);
              }
            }
          });
          originalFirstAidStatements.forEach((originalFirstAidStatement) => {
            if (
              !newFirstAidStatements.find(
                (newFirstAidStatement) =>
                  'materialMsdsFirstAidMeasuresId' in newFirstAidStatement &&
                  newFirstAidStatement.materialMsdsFirstAidMeasuresId ===
                    originalFirstAidStatement.materialMsdsFirstAidMeasuresId,
              )
            ) {
              result.push({ ...originalFirstAidStatement, isDelete: true });
            }
          });
          return Object.keys(result).length > 0 ? result : undefined;
        };

        const eyeStatements = getUpdatingFirstAidStatements(
          newFirstAid.eyeStatements,
          originFirstAid.eyeStatements,
        );
        const skinStatements = getUpdatingFirstAidStatements(
          newFirstAid.skinStatements,
          originFirstAid.skinStatements,
        );
        const inhalationStatements = getUpdatingFirstAidStatements(
          newFirstAid.inhalationStatements,
          originFirstAid.inhalationStatements,
        );
        const ingestionStatements = getUpdatingFirstAidStatements(
          newFirstAid.ingestionStatements,
          originFirstAid.ingestionStatements,
        );
        const acuteSymptomStatements = getUpdatingFirstAidStatements(
          newFirstAid.acuteSymptomStatements,
          originFirstAid.acuteSymptomStatements,
        );
        const delayedSymptomStatements = getUpdatingFirstAidStatements(
          newFirstAid.delayedSymptomStatements,
          originFirstAid.delayedSymptomStatements,
        );
        const physicianStatements = getUpdatingFirstAidStatements(
          newFirstAid.physicianStatements,
          originFirstAid.physicianStatements,
        );
        const adviceStatements = getUpdatingFirstAidStatements(
          newFirstAid.adviceStatements,
          originFirstAid.adviceStatements,
        );

        const result = {
          ...(eyeStatements && { eyeStatements }),
          ...(skinStatements && { skinStatements }),
          ...(inhalationStatements && { inhalationStatements }),
          ...(ingestionStatements && { ingestionStatements }),
          ...(acuteSymptomStatements && { acuteSymptomStatements }),
          ...(delayedSymptomStatements && { delayedSymptomStatements }),
          ...(physicianStatements && { physicianStatements }),
          ...(adviceStatements && { adviceStatements }),
        };

        return Object.keys(result).length > 0 ? result : undefined;
      };

      const getUpdatingCompositionInformations = (
        newCompositionInformations: ICompositionInformation[],
        originCompositionInformations: ICompositionInformation[],
      ) => {
        const updatingCompositionInformations = newCompositionInformations.reduce<
          ICompositionInformationUpdate[]
        >((acc, curr) => {
          const updatingCompositionInformation = getUpdatingObject(
            curr,
            originCompositionInformations.find(
              ({ materialMsdsCompositionInformationId }) =>
                materialMsdsCompositionInformationId ===
                curr.materialMsdsCompositionInformationId,
            ),
          );
          if (updatingCompositionInformation) {
            acc.push({
              ...updatingCompositionInformation,
              materialMsdsCompositionInformationId:
                curr.materialMsdsCompositionInformationId,
            });
          }
          return acc;
        }, []);
        return updatingCompositionInformations.length > 0
          ? updatingCompositionInformations
          : undefined;
      };

      const updatingMsds: IMsdsUpdate = {
        materialMsdsBasicInfoId: msds.materialMsdsBasicInfoId,
        msdsBasicInfo: getUpdatingObject(msdsBasicInfo, msds.msdsBasicInfo),
        fireFighting: getUpdatingObject(fireFighting, msds.fireFighting),
        accidentalRelease: getUpdatingObject(
          accidentalRelease,
          msds.accidentalRelease,
        ),
        handlingStorage: getUpdatingObject(
          handlingStorage,
          msds.handlingStorage,
        ),
        exposureControl: getUpdatingObject(
          exposureControl,
          msds.exposureControl,
        ),
        physicalChemical: getUpdatingObject(
          physicalChemical,
          msds.physicalChemical,
        ),
        stabilityReactivity: getUpdatingObject(
          stabilityReactivity,
          msds.stabilityReactivity,
        ),
        toxicological: getUpdatingObject(toxicological, msds.toxicological),
        ecological: getUpdatingObject(ecological, msds.ecological),
        disposal: getUpdatingObject(disposal, msds.disposal),
        transport: getUpdatingObject(transport, msds.transport),
        regulatory: getUpdatingObject(regulatory, msds.regulatory),
        other: getUpdatingObject(other, msds.other),
        hazards: getUpdatingHazards(hazards, msds.hazards),
        firstAid: getUpdatingFirstAid(firstAid, msds.firstAid),
        compositionInformations: getUpdatingCompositionInformations(
          compositionInformations,
          msds.compositionInformations,
        ),
      };
      if (Object.values(updatingMsds).filter((field) => field).length === 1) {
        message.warn(messages.NO_NEED_TO_UPDATE);
      } else {
        updateMsdsMutate(updatingMsds);
      }
    },
    [msds],
  );

  return useMemo(
    () => ({
      msds,
      getLoading,
      addMsds,
      addLoading,
      updateMsds,
      updateLoading,
      isAddSuccess,
    }),
    [
      msds,
      getLoading,
      addMsds,
      addLoading,
      updateMsds,
      updateLoading,
      isAddSuccess,
    ],
  );
};

export const useAllPictograms = () => {
  const { data: pictograms = [] } = useQuery(
    'material/msds/getAllPictograms',
    msdsApi.getAllPictograms,
    {
      select: (res) => res.data.result,
    },
  );
  return pictograms;
};

export const useGetMsds = () => {
  const {
    mutate: getMsds,
    isLoading: getLoading,
  } = useMutation((materialId: number) => msdsApi.getMsds(materialId));
  return useMemo(() => ({ getMsds, getLoading }), [getLoading]);
};
