import { Col, Row, Modal, Descriptions, Space } from 'antd';
import styled from 'styled-components';

import { Typography } from 'components/system';
import palette from 'lib/styles/palette';
import { useState } from 'react';
import { CloseIcon } from 'components/system/general/icon';
import {
  ICompositionInformation,
  IMaterialBasicInformation,
} from 'types/material/rawMaterialDetail';
import { ESpecialBrandDocResponseType } from 'types/material/rawMaterial';
import { IMaterialCompanyBase } from 'types/company';

const StyledDescriptions = styled(Descriptions)`
  & > .ant-descriptions-view {
    border-left: none;
    border-right: none;
    border-top: 2px solid ${palette.primary};
    border-radius: 0;

    .ant-descriptions-item-label {
      width: 200px;
      padding: 10px 16px;
      color: ${palette.slate};
      font-weight: 500;
      font-size: 16px;
      vertical-align: top;
    }
    .ant-descriptions-item-content {
      width: 260px;
      max-width: 260px;
      padding: 10px 16px;
      color: ${palette.slate};
      font-weight: 500;
      vertical-align: top;
    }
  }
`;

const InnerDescriptions = styled(Descriptions)`
  .ant-descriptions-view {
    border: none;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: transparent;
    padding: 32px 40px 0px 40px;
    display: none;
  }
`;

const CompanyInfo = styled.div`
  padding: 3px 8px;
  border-radius: 4px;
  border: solid 1px ${palette.inactive};
  background-color: #fff;
  font-size: 12px;
  line-height: normal;
  color: ${palette.text.gray};

  cursor: pointer;
`;

const Description = ({
  materialBasicInformation,
  compositionInformations,
  company,
}: {
  materialBasicInformation: IMaterialBasicInformation;
  compositionInformations: ICompositionInformation[];
  company: IMaterialCompanyBase;
}) => {
  const [companyInfoModalVisible, setCompanyInfoModalVisible] = useState(false);
  const {
    materialNameEn,
    materialNameKo,
    originManufacturerName,
    isShowManufacturerName,
    efficacies,
    formulaPurposes,
    hsCode,
    canUseForFragrance,
    marketingKeywords,
    marketingFiles,
    hasExperienceSpecialBrandDelivery,
    specialBrands,
    patents,
    isAllergenFree,
    isDiscontinued,
  } = materialBasicInformation;
  return (
    <>
      <Row align="middle" gutter={16} style={{ marginBottom: 12 }}>
        <Col>
          <Typography.Title>{materialNameEn}</Typography.Title>
        </Col>
        <Col>
          <Space>
            <Typography.Text style={{ marginTop: 3 }} color="slate" medium>
              {materialNameKo}
            </Typography.Text>
            {isDiscontinued && (
              <Typography.Label type="disabled">단종 원료</Typography.Label>
            )}
          </Space>
        </Col>
      </Row>
      <StyledDescriptions bordered column={{ sm: 2, xs: 1 }}>
        <Descriptions.Item label="원료사">
          <Row gutter={8} align="middle">
            <Col>{company.companyNameKo}</Col>
            <Col>
              <CompanyInfo onClick={() => setCompanyInfoModalVisible(true)}>
                회사 정보
              </CompanyInfo>
            </Col>
          </Row>
        </Descriptions.Item>
        <Descriptions.Item label="실 생산자명">
          {isShowManufacturerName ? originManufacturerName : '-'}
        </Descriptions.Item>
        <Descriptions.Item label="INCI Name" span={2}>
          {compositionInformations.length > 0 &&
            `${compositionInformations[0].inciNameEn} 등`}
        </Descriptions.Item>
        <Descriptions.Item label="효능">
          <Row gutter={[4, 4]}>
            {efficacies.map(({ efficacyCategoryId, efficacyName }) => (
              <Col key={efficacyCategoryId}>
                <Typography.Chip>{efficacyName}</Typography.Chip>
              </Col>
            ))}
          </Row>
        </Descriptions.Item>
        <Descriptions.Item label="배합 목적">
          <Row gutter={[4, 4]}>
            {formulaPurposes.map(
              ({ formulaPurposeCategoryId, formulaPurposeName }) => (
                <Col key={formulaPurposeCategoryId}>
                  <Typography.Chip>{formulaPurposeName}</Typography.Chip>
                </Col>
              ),
            )}
          </Row>
        </Descriptions.Item>
        <Descriptions.Item label="향료 사용 가능 여부">
          {canUseForFragrance ? '향료로 사용 가능' : '향료로 사용 불가'}
        </Descriptions.Item>
        <Descriptions.Item label="알러지 유발 성분 유무">
          {isAllergenFree === null
            ? '-'
            : isAllergenFree
            ? '알러지 프리'
            : '알러지 유발 성분 있음'}
        </Descriptions.Item>
        <Descriptions.Item label="마케팅 자료" span={2}>
          {marketingFiles &&
            marketingFiles.map(
              ({ materialMarketingDataId, displayType, attachUrl }) => (
                <a
                  href={attachUrl}
                  target="_self"
                  key={materialMarketingDataId}
                >
                  <i
                    key={materialMarketingDataId}
                    className={`material-icons outlined cursor ${
                      displayType === 'PUBLIC' ? 'primary' : 'gray'
                    }`}
                  >
                    description
                  </i>
                </a>
              ),
            )}
        </Descriptions.Item>
        <Descriptions.Item label="마케팅 키워드" span={2}>
          <Row gutter={[4, 4]}>
            {marketingKeywords.map(({ marketingKeywordId, keyword }) => (
              <Col key={marketingKeywordId}>
                <Typography.Chip>{keyword}</Typography.Chip>
              </Col>
            ))}
          </Row>
        </Descriptions.Item>
        <Descriptions.Item
          className="납품 가능한 특수 브랜드"
          label="납품 가능한 특수 브랜드"
          span={2}
          contentStyle={
            hasExperienceSpecialBrandDelivery ? { padding: 0 } : undefined
          }
        >
          {hasExperienceSpecialBrandDelivery ? (
            <InnerDescriptions
              bordered
              column={1}
              labelStyle={{ display: 'none' }}
            >
              {specialBrands.map(
                ({ specialBrandId, brandName, docResponseType }) => (
                  <Descriptions.Item key={specialBrandId}>
                    {brandName} / 서류 대응 여부 :{' '}
                    {docResponseType === ESpecialBrandDocResponseType.POSSIBLE
                      ? '가능'
                      : docResponseType ===
                        ESpecialBrandDocResponseType.IMPOSSIBLE
                      ? '불가능'
                      : '모름'}
                  </Descriptions.Item>
                ),
              )}
            </InnerDescriptions>
          ) : (
            '없음'
          )}
        </Descriptions.Item>
        <Descriptions.Item label="HS Code">{hsCode}</Descriptions.Item>
        <Descriptions.Item label="특허 번호">
          {patents.map(({ patentNumber }) => patentNumber).join(', ')}
        </Descriptions.Item>
      </StyledDescriptions>
      <StyledModal
        width={800}
        visible={companyInfoModalVisible}
        title="회사 정보"
        footer={null}
        closable={false}
        onCancel={() => setCompanyInfoModalVisible(false)}
      >
        <Row
          justify="space-between"
          align="middle"
          style={{ marginBottom: 16 }}
        >
          <Typography.Title>회사 정보</Typography.Title>
          <CloseIcon onClick={() => setCompanyInfoModalVisible(false)} />
        </Row>
        <StyledDescriptions column={1} bordered>
          <Descriptions.Item label="회사명">
            {company.companyNameKo}
          </Descriptions.Item>
          <Descriptions.Item label="대표자 성명">
            {company.ceoNameKo}
          </Descriptions.Item>
          <Descriptions.Item label="회사 전화번호">
            {company.tel}
          </Descriptions.Item>
          <Descriptions.Item label="회사 이메일">
            {company.email ?? '-'}
          </Descriptions.Item>
          <Descriptions.Item label="팩스번호 (FAX)">
            {company.fax}
          </Descriptions.Item>
          <Descriptions.Item label="주요 품목">
            {company.mainItem}
          </Descriptions.Item>
          <Descriptions.Item label="회사 소개">
            {company.introduction}
          </Descriptions.Item>
        </StyledDescriptions>
      </StyledModal>
    </>
  );
};

export default Description;
