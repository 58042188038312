import { Button, Col, Form, Input, Modal, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { exceptKoreanRule, requireRule } from 'lib/validate';
import { toggleChineseProductNameModalVisible } from 'modules/certificate';
import UpdateLog from 'components/product/UpdateLog';
import CorrectRequestMessageModal from 'components/CorrectRequestMessageModal';
import FooterBox from 'components/FooterBox';
import { useCountryProductName } from 'service/brand/certificate/certificateDetail';
import { updateChineseProductName } from 'modules/product';
import { EDocumentCode } from 'types/product';

const ChineseProductNameModal = () => {
  const dispatch = useDispatch();
  const visible = useSelector(
    ({ certificate }: any) => certificate.chineseProductNameModalVisible,
  );
  const onClose = () => {
    dispatch(toggleChineseProductNameModalVisible());
  };
  const {
    form,
    productId,
    countryId,
    readOnlyMode,
    updateLoading,
    onSubmit,
  } = useCountryProductName(updateChineseProductName);
  return (
    <Modal
      visible={visible}
      title="중문 제품명 관리"
      footer={null}
      destroyOnClose
      onCancel={onClose}
    >
      <Row justify="end" gutter={8}>
        <Col>
          <CorrectRequestMessageModal documentCode={EDocumentCode.PN} />
        </Col>
        <Col>
          <UpdateLog
            productId={productId}
            countryId={countryId}
            documentCode={EDocumentCode.BASIC}
            validCodes={['BASIC01-BNC', 'BASIC01-PNE', 'BASIC01-PNC']}
          />
        </Col>
      </Row>

      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Form.Item
          label="중문 브랜드명"
          name="countryBrandName"
          rules={[requireRule, exceptKoreanRule]}
        >
          <Input disabled={readOnlyMode} />
        </Form.Item>
        <Form.Item
          label="제품명 (영문)"
          name="productNameEn"
          rules={[requireRule, exceptKoreanRule]}
        >
          <Input disabled={readOnlyMode} />
        </Form.Item>
        <Form.Item
          label="제품명 (중문)"
          name="countryProductName"
          rules={[requireRule, exceptKoreanRule]}
        >
          <Input disabled={readOnlyMode} />
        </Form.Item>
        {!readOnlyMode && (
          <FooterBox>
            <Button type="primary" loading={updateLoading} htmlType="submit">
              수정
            </Button>
          </FooterBox>
        )}
      </Form>
    </Modal>
  );
};

export default ChineseProductNameModal;
