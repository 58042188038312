import { Layout } from 'antd';
import styled from 'styled-components';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import Footer from 'components/system/layout/Footer';
import NavigatorContainer from 'containers/NavigatorContainer';
import { deviceSize, size } from 'lib/styles';
import AppRouter from 'routers/AppRouter';
import LoginModal from 'components/ui/Modal/LoginModal';
import { closeLoginModal } from 'modules/modal';

const LayoutBlock = styled(Layout)`
  min-height: 100vh;
  background-color: #fff;
  padding: 0 32px;
  overflow: hidden;

  @media ${deviceSize.sm} {
    padding: 0 16px;
  }
`;

const HeaderBlock = styled(Layout.Header)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  padding: 0 32px;
  background-color: #fff;
  border-bottom: 1px solid #f0f0f0;
  box-shadow: 0 0 30px #f3f1f1;
  z-index: 100;

  @media ${deviceSize.sm} {
    padding: 0 16px;
  }
`;

const ContentBlock = styled(Layout.Content)`
  width: 100%;
  margin: 80px auto 0;
  padding: 80px 0;
  display: flex;

  & > div {
    width: 100%;
    max-width: ${size.layout.contentMax};
    margin: 0 auto;
  }

  @media ${deviceSize.sm} {
    padding: 40px 0;
  }
`;

const FooterBlock = styled(Layout.Footer)`
  border-top: 1px solid #deded3;
  background-color: #fff;
  margin: 0 -32px;
  padding: 36px 32px;

  @media ${deviceSize.sm} {
    margin: 0 -16px;
    padding: 20px 16px 12px;
  }
`;

const App = () => {
  const { isOpenLoginModal } = useSelector(
    ({ modal }: any) => ({
      isOpenLoginModal: modal.isOpenLoginModal,
    }),
    shallowEqual,
  );
  const dispatch = useDispatch();
  return (
    <LayoutBlock>
      <HeaderBlock>
        <NavigatorContainer />
      </HeaderBlock>
      <ContentBlock>
        <AppRouter />
      </ContentBlock>
      <FooterBlock>
        <Footer />
      </FooterBlock>
      <LoginModal
        isOnlyBrand
        visible={isOpenLoginModal}
        onClose={() => dispatch(closeLoginModal())}
      />
    </LayoutBlock>
  );
};

export default App;
