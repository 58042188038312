export enum EOriginType {
  VEGAN = 'VEGAN', // 식물성
  ORGANIC = 'ORGANIC', // 동물성
  BOTH = 'BOTH', // 혼합
  NOTHING = 'NOTHING', // 해당 없음
}

export enum EDocumentCode {
  MDI = 'MDI01',
  MCAS = 'MCAS01',
  MCI = 'MCI01',
  COA = 'COA01',
  MSDS = 'MSDS01',
  ALLE = 'ALLE01',
  IFRA = 'IFRA01',
  MMD = 'MMD01',
}

export enum EDocumentStatus {
  INP = 'INP', // 입력 필요
  IPS = 'IPS', // 입력 가능
  UPD = 'UPD', // 업로드 필요
  FIN = 'FIN', // 입력 완료
}

export enum EDisclosureType {
  ALL = 'ALL',
  CLOSE = 'CLOSE',
  TARGET = 'TARGET',
}

export enum EDisclosureCompanyKey {
  BRAND = 'materialDisclosureBrandCompanies',
  MANU = 'materialDisclosureManufacturers',
  MATERIAL = 'materialDisclosureMaterialCompanies',
}

export enum EDeleteDisclosureCompanyKey {
  BRAND = 'deleteDisclosureBrandCompanyIds',
  MANU = 'deleteDisclosureManufacturerIds',
  MATERIAL = 'deleteDisclosureMaterialCompanyIds',
}

export enum EAddDisclosureCompanyKey {
  BRAND = 'addDisclosureBrandCompanyIds',
  MANU = 'addDisclosureManufacturerIds',
  MATERIAL = 'addDisclosureMaterialCompanyIds',
}

export enum ECompanyIdKey {
  BRAND = 'brandCompanyId',
  MANU = 'manufacturerId',
  MATERIAL = 'materialCompanyId',
}
export interface IDocumentSeq {
  documentSeqId: number;
  documentName: string;
  documentCode: EDocumentCode;
}
export interface IDocument {
  materialDocId: number;
  documentSeq: IDocumentSeq;
  status: EDocumentStatus;
  registerDt: string | null;
  updateDt: string | null;
}
export interface IDisclosures {
  disclosureBrandCompanyIds: number[];
  disclosureManufacturerIds: number[];
  disclosureMaterialCompanyIds: number[];
}
interface IRawMaterialBase {
  materialNameEn: string;
  materialNameKo?: string;
  originManufacturerName: string;
  isSelfProduction: boolean;
  isShowManufacturerName: boolean;
  madeCountry: string;
  hsCode: string;
  manageCode?: string;
  brandCompanyDisclosureType: EDisclosureType;
  manufacturerDisclosureType: EDisclosureType;
  materialCompanyDisclosureType: EDisclosureType;
  hasExperienceSpecialBrandDelivery: boolean;
  originType: EOriginType;
}
export interface IRawMaterialForm extends IRawMaterialBase {
  disclosures: IDisclosures;
  specialBrands?: ISpecialBrandAdd[];
  efficacies: string[];
  formulaPurposes: string[];
  marketingKeywords: string;
  patents: string[];
  theses: (File | IThesisGet)[];
  effectiveIngredients?: IEffectiveIngredient[];
  combinationWarningText?: string;
}
export interface IRawMaterialGet extends IRawMaterialBase {
  materialId: number;
  specialBrands: ISpecialBrandGet[];
  materialDisclosureBrandCompanies: {
    brandCompanyId: number;
  }[];
  materialDisclosureManufacturers: {
    manufacturerId: number;
  }[];
  materialDisclosureMaterialCompanies: {
    materialCompanyId: number;
  }[];
  efficacies: IEfficacyGet[];
  formulaPurposes: IFormulaPurposeGet[];
  marketingKeywords: IMarketingKeywordGet[];
  effectiveIngredients: IEffectiveIngredient[];
  patents: IPatentGet[];
  theses: IThesisGet[];
  combinationWarningText: string;
  isDiscontinued: boolean;
  isLaterUpload: boolean;
  status: 'RM_RDY' | 'RM_REG';
}
export interface IRawMaterialAdd extends IRawMaterialBase {
  specialBrands?: ISpecialBrandAdd[];
  efficacies: IEfficacy[];
  formulaPurposes: IFormulaPurpose[];
  marketingKeywords: IMarketingKeyword[];
  patents?: IPatent[];
  disclosureBrandCompanyIds?: number[];
  disclosureManufacturerIds?: number[];
  disclosureMaterialCompanyIds?: number[];
  theses?: IThesis[];
  effectiveIngredients?: IEffectiveIngredient[];
  combinationWarningText?: string;
}
export interface IRawMaterialUpdate
  extends Omit<Partial<IRawMaterialAdd>, 'specialBrands'> {
  materialId: number;
  deleteDisclosureBrandCompanyIds?: number[];
  addDisclosureBrandCompanyIds?: number[];
  deleteDisclosureManufacturerIds?: number[];
  addDisclosureManufacturerIds?: number[];
  deleteDisclosureMaterialCompanyIds?: number[];
  addDisclosureMaterialCompanyIds?: number[];
  specialBrands?: ISpecialBrandUpdate[];
  deleteEfficacies?: number[];
  addEfficacies?: IEfficacy[];
  deleteFormulaPurposes?: number[];
  addFormulaPurposes?: IFormulaPurpose[];
  deleteMarketingKeywords?: number[];
  addMarketingKeywords?: IMarketingKeyword[];
  deletePatents?: number[];
  addPatents?: IPatent[];
  deleteTheses?: number[];
  addTheses?: IThesis[];
  effectiveIngredients?: IEffectiveIngredient[];
  deleteEffectiveIngredients?: number[];
  combinationWarningText?: string;
}
export interface IEfficacy {
  efficacyName: string;
  isDefault: boolean;
}

export interface IEfficacyGet extends IEfficacy {
  efficacyCategoryId: number;
}

export interface IFormulaPurpose {
  formulaPurposeName: string;
  isDefault: boolean;
}

export interface IFormulaPurposeGet extends IFormulaPurpose {
  formulaPurposeCategoryId: number;
}

export interface IMarketingKeyword {
  keyword: string;
}
export interface IMarketingKeywordGet extends IMarketingKeyword {
  marketingKeywordId: number;
  keyword: string;
}

export interface IEffectiveIngredient {
  materialEffectiveIngredientId?: number;
  ingredientName: string;
  ingredientMinContent: string;
  ingredientUnit: string;
}
export interface IPatent {
  patentNumber: string;
}
export interface IPatentGet extends IPatent {
  materialPatentId: number;
}

export interface IThesis {
  uploadFile: File;
}
export interface IThesisGet extends IThesis {
  materialThesisId: number;
  attachUrl: string;
  fileName: string;
}

export enum ESpecialBrandDocResponseType {
  POSSIBLE = 'POSSIBLE',
  IMPOSSIBLE = 'IMPOSSIBLE',
  NONE = 'NONE',
}

export interface ISpecialBrandAdd {
  brandCode: string;
  docResponseType: ESpecialBrandDocResponseType;
}

export interface ISpecialBrandForm extends Partial<ISpecialBrandAdd> {}

export interface ISpecialBrandGet {
  specialBrandId: number;
  brandCode: string;
  docResponseType: ESpecialBrandDocResponseType;
  brandName: string;
}

export interface ISpecialBrandUpdate {
  specialBrandId?: number;
  brandCode?: string;
  docResponseType?: string;
  actionType: 'ADD' | 'MOD' | 'DELETE';
}

export interface IMonopolyManufacturer {
  manufacturerId: number;
  companyNameKo: string;
}

export interface IRegisteredRawMaterial
  extends Pick<
    IRawMaterialGet,
    'materialId' | 'materialNameKo' | 'materialNameEn'
  > {
  originManufacturerName: string;
  finishRegisterDt: string;
  finishRegistrant: { name: string };
}

export interface IMadeCountry {
  countryListId: number;
  nameEn: string;
}
