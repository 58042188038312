import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Col, message, Row } from 'antd';

import { messages } from 'lib/consts';
import * as productActions from 'modules/product';
import FileUploadTemplate from 'templates/FileUploadTemplate';
import UpdateLog from 'components/product/UpdateLog';
import CorrectRequestMessageModal from 'components/CorrectRequestMessageModal';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';

const ProductThirdPartyTestReportContainer = () => {
  const { productId } = useCurrentProduct();
  const countryId = useCountryId();
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const {
    currentProduct,
    thirdPartyTestReports,
    readOnlyMode,
    fetchLoading,
  } = useSelector(
    ({ product, certificate, loading }) => ({
      currentProduct: product.currentProduct,
      thirdPartyTestReports: product.thirdPartyTestReport.list,
      readOnlyMode: certificate.readOnlyMode,
      fetchLoading:
        loading['product/ADD_PRODUCT_THIRD_PARTY_TEST_REPORT'] ||
        loading['product/UPDATE_PRODUCT_THIRD_PARTY_TEST_REPORT'],
    }),
    shallowEqual,
  );
  const updateMode = thirdPartyTestReports.length !== 0;
  const onUpload = (file) => {
    if (files.find((f) => f.name === file.name)) {
      message.warning(messages.DUPLICATE_FILE_NAME);
      return false;
    }
    setFiles((draft) => draft.concat(file));
    return true;
  };
  const onDelete = (target) => {
    if (updateMode && files.length === 1) {
      message.warning(messages.REQUIRE_ONE_OR_MORE_FILES);
      return true;
    }
    setFiles(files.filter((file) => file !== target));
  };
  const onSubmit = () => {
    if (!files.length) {
      return message.warn(messages.REQUIRED_FILES);
    }
    if (!updateMode) {
      dispatch(
        productActions.addProductThirdPartyTestReport({
          productId: currentProduct.productId,
          countryId,
          documentFiles: files,
        }),
      );
    } else {
      const existProductIds = files
        .filter(({ productThirdPartyTestId }) => productThirdPartyTestId)
        .map(({ productThirdPartyTestId }) => productThirdPartyTestId);
      dispatch(
        productActions.updateProductThirdPartyTestReport({
          ...(files.filter(
            ({ productThirdPartyTestId }) => !productThirdPartyTestId,
          ).length > 0 && {
            newThirdPartyReports: {
              productId,
              countryId,
              documentFiles: files.filter(
                ({ productThirdPartyTestId }) => !productThirdPartyTestId,
              ),
            },
          }),
          productThirdPartyTestIdsBeDeleted: thirdPartyTestReports
            .filter(
              ({ productThirdPartyTestId }) =>
                !existProductIds.includes(productThirdPartyTestId),
            )
            .map(({ productThirdPartyTestId }) => productThirdPartyTestId),
        }),
      );
    }
  };
  useEffect(() => {
    dispatch(
      productActions.getProductThirdPartyTestReports({
        productId,
        countryId,
      }),
    );
    return () => {
      dispatch(productActions.initializeProductThirdPartyTestReports());
    };
  }, []);
  useEffect(() => {
    if (thirdPartyTestReports.length) {
      setFiles(
        thirdPartyTestReports.map(
          ({ productThirdPartyTestId, filename, uploadFileUrl }) => ({
            productThirdPartyTestId,
            name: filename,
            url: uploadFileUrl,
          }),
        ),
      );
    }
  }, [thirdPartyTestReports]);
  return (
    <>
      <Row justify="end" gutter={8}>
        <Col>
          <CorrectRequestMessageModal documentCode="third" />
        </Col>
        {updateMode && (
          <Col>
            <UpdateLog
              productId={productId}
              countryId={countryId}
              documentCode="third"
            />
          </Col>
        )}
      </Row>
      <FileUploadTemplate
        updateMode={updateMode}
        readOnly={readOnlyMode}
        files={files}
        viewerVisible
        status="MOD"
        fetchLoading={fetchLoading}
        onUpload={onUpload}
        onDelete={onDelete}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default ProductThirdPartyTestReportContainer;
