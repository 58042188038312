import RawMaterialBasic from 'components/material/rawMaterial/basic/RawMaterialBasic';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import { useParams } from 'react-router-dom';
import RawMaterialPageTemplate from 'templates/material/RawMaterialPageTemplate';

const RawMaterialBasicPage = () => {
  const notices = [
    '원료에 대한 기본적인 정보를 입력해 주세요.',
    <MistakeNoticeItem />,
  ];
  const { materialId } = useParams<{ materialId: string }>();
  return (
    <RawMaterialPageTemplate
      documentName="기본 정보"
      notices={notices}
      back={typeof materialId !== 'undefined'}
    >
      <RawMaterialBasic />
    </RawMaterialPageTemplate>
  );
};

export default RawMaterialBasicPage;
