import { Form, InputNumber, Radio, Row, Col, Select, Input } from 'antd';

import * as S from './Styled';
import { Typography } from 'components/system';
import { requireRule } from 'lib/validate';
import { hardnessUnitOptions } from 'lib/selectOption';

const HardnessForm = ({
  readOnlyMode,
  onChangeHardnessRadioGroup,
}: {
  readOnlyMode: boolean;
  onChangeHardnessRadioGroup: (isExistedHardness: boolean) => void;
}) => {
  return (
    <S.StyledFormItem label="Hardness" required>
      <Form.Item name="isExistedHardness" noStyle initialValue={true}>
        <Radio.Group
          disabled={readOnlyMode}
          onChange={(e) => onChangeHardnessRadioGroup(e.target.value)}
        >
          <Radio value={true}>입력하기</Radio>
          <Radio value={false}>해당 없음</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        shouldUpdate={(prev, next) =>
          prev.isExistedHardness !== next.isExistedHardness ||
          prev.hardnessLimitMax !== next.hardnessLimitMax ||
          prev.hardnessLimitMin !== next.hardnessLimitMin
        }
        noStyle
      >
        {({ getFieldValue }) => {
          const isExistedHardness = getFieldValue('isExistedHardness');
          const hardnessLimitMax = getFieldValue('hardnessLimitMax');
          const hardnessLimitMin = getFieldValue('hardnessLimitMin');
          return (
            <>
              <S.StyledRow style={{ marginTop: 8 }}>
                <Typography.Text gutter={{ right: 4 }}>
                  정상 범위
                </Typography.Text>
                {/* HINT : Form의 validation이 reject된 상태에서 disabled가 먹히지 않으므로 
                      Form.Item 렌더링을 disabled된 컴포넌트와 아닌 컴포넌트로 분기 처리 */}
                {isExistedHardness ? (
                  <S.AntdExplainWrapper>
                    <Form.Item
                      style={{ width: 120 }}
                      dependencies={['hardnessLimitMax']}
                      name="hardnessLimitMin"
                      rules={[
                        requireRule,
                        {
                          validator: (_, hardnessLimitMinValue) => {
                            if (hardnessLimitMinValue > hardnessLimitMax) {
                              return Promise.reject(
                                '좌측 정상 범위는 우측 정상 범위 보다 클 수 없음',
                              );
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <InputNumber
                        maxLength={8}
                        precision={4}
                        min={0}
                        max={999.9999}
                        disabled={readOnlyMode}
                        step={0.1}
                        style={{ width: 120 }}
                      />
                    </Form.Item>
                  </S.AntdExplainWrapper>
                ) : (
                  <Form.Item>
                    <InputNumber disabled style={{ width: 120 }} />
                  </Form.Item>
                )}
                <Typography.Text
                  style={{
                    display: 'inline-block',
                    width: 16,
                    textAlign: 'center',
                  }}
                >
                  ~
                </Typography.Text>
                {isExistedHardness ? (
                  <Form.Item name="hardnessLimitMax" rules={[requireRule]}>
                    <InputNumber
                      maxLength={8}
                      step={0.1}
                      min={0}
                      max={999.9999}
                      precision={4}
                      disabled={readOnlyMode}
                      style={{ width: 120 }}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item>
                    <InputNumber disabled style={{ width: 120 }} />
                  </Form.Item>
                )}
              </S.StyledRow>
              <S.StyledRow>
                <Typography.Text gutter={{ left: 30, right: 4 }}>
                  수치
                </Typography.Text>
                <Form.Item
                  shouldUpdate={(prev, next) =>
                    prev.hardnessUnit !== next.hardnessUnit
                  }
                  style={{ marginBottom: 0 }}
                >
                  {({ getFieldValue, setFieldsValue }) => {
                    return (
                      <Row gutter={8} style={{ marginBottom: '0px important' }}>
                        <Col style={{ width: 128 }}>
                          {isExistedHardness ? (
                            <Form.Item
                              name="hardness"
                              required
                              dependencies={[
                                'hardnessLimitMax',
                                'hardnessLimitMin',
                              ]}
                              rules={[
                                requireRule,
                                {
                                  validator: (_, hardness) => {
                                    if (
                                      hardness < hardnessLimitMin ||
                                      hardness > hardnessLimitMax
                                    ) {
                                      return Promise.reject(
                                        '정상 범위를 벗어난 수치',
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                },
                              ]}
                            >
                              <InputNumber
                                maxLength={8}
                                disabled={readOnlyMode}
                                step={0.1}
                                max={999.9999}
                                precision={4}
                                style={{ width: 120 }}
                              />
                            </Form.Item>
                          ) : (
                            <Form.Item>
                              <InputNumber disabled style={{ width: 120 }} />
                            </Form.Item>
                          )}
                        </Col>
                        <Col style={{ width: 120 }}>
                          {isExistedHardness ? (
                            <Form.Item
                              name="hardnessUnit"
                              rules={[requireRule]}
                            >
                              <Select
                                placeholder="단위 선택"
                                onSelect={() =>
                                  setFieldsValue({
                                    hardnessUnitDirect: null,
                                  })
                                }
                                disabled={readOnlyMode}
                              >
                                {hardnessUnitOptions.map((option) => (
                                  <Select.Option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.text}
                                  </Select.Option>
                                ))}
                                <Select.Option key="others" value="others">
                                  직접입력
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          ) : (
                            <Form.Item>
                              <Select disabled />
                            </Form.Item>
                          )}
                        </Col>
                        {getFieldValue('hardnessUnit') === 'others' &&
                          isExistedHardness && (
                            <Col style={{ width: 120 }}>
                              <Form.Item
                                name={
                                  getFieldValue('hardnessUnit') === 'others'
                                    ? 'hardnessUnitDirect'
                                    : 'hardnessUnit'
                                }
                                rules={[requireRule]}
                              >
                                <Input
                                  placeholder="단위 직접 입력"
                                  disabled={readOnlyMode}
                                />
                              </Form.Item>
                            </Col>
                          )}
                      </Row>
                    );
                  }}
                </Form.Item>
              </S.StyledRow>
            </>
          );
        }}
      </Form.Item>
    </S.StyledFormItem>
  );
};

export default HardnessForm;
