import React from 'react';
import styled from 'styled-components';

const CloseIconBlock = styled.div`
  position: relative;
  display: inline-block;
  width: 21px;
  height: 21px;
  cursor: pointer;

  &:after {
    position: absolute;
    left: 1px;
    display: block;
    width: 28px;
    height: 2px;
    transform: rotate(45deg);
    transform-origin: 0 0;
    content: '';
    background-color: #222;
  }

  &:before {
    position: absolute;
    display: block;
    width: 28px;
    height: 2px;
    top: 10px;
    left: -4px;
    transform: rotate(-45deg);
    content: '';
    background-color: #222;
  }
`;

const CloseIcon = (props: any) => {
  return <CloseIconBlock {...props} />;
};

export default CloseIcon;
