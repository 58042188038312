import { useHistory } from 'react-router-dom';

import path from 'lib/path';
import PageTemplate from 'templates/PageTemplate';
import ProductList from 'components/brand/retailer/retailerApply/product/ProductList';
import { IProductListPageState } from 'types/brand/retailer/retailerApply';

const ProductListPage = () => {
  const history = useHistory<IProductListPageState>();
  const productListPageState = history.location.state || {
    page: 1,
    searchValue: '',
  };

  const handleChangePageState = (state: IProductListPageState) => {
    history.replace(path.retailer.retailerApply.product.list, state);
  };

  return (
    <PageTemplate
      back={false}
      title="국내 입점 서류 관리"
      description="제품을 선택하여 유통사에 제출할 서류들을 한 번에 받아보세요."
    >
      <ProductList
        initialPageState={productListPageState}
        onChangePageState={handleChangePageState}
      />
    </PageTemplate>
  );
};

export default ProductListPage;
