import styled from 'styled-components';

import palette from 'lib/styles/palette';
import { Typography } from 'components/system';

const ThirtyCOSAddressBlock = styled.div`
  margin-top: 24px;
`;

const ThirtyCOSAddressBody = styled.div`
  padding: 20px 0;
  border-radius: 8px;
  background-color: ${palette.gray};
  text-align: center;
`;

const ThirtyCOSAddress = () => {
  return (
    <ThirtyCOSAddressBlock>
      <Typography.Title gutter={{ bottom: 16 }}>
        계약서 보내실 주소 (30COS 본사)
      </Typography.Title>
      <ThirtyCOSAddressBody>
        <Typography.Title medium color="slate" style={{ fontSize: 16 }}>
          충북 청주시 흥덕구 오송읍 오송생명1로 194-25 SB플라자 3층 A-13호
        </Typography.Title>
      </ThirtyCOSAddressBody>
    </ThirtyCOSAddressBlock>
  );
};

export default ThirtyCOSAddress;
