import { useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { message } from 'antd';

import * as rawMaterialApi from 'lib/api/material/rawMaterial';
import {
  EAddDisclosureCompanyKey,
  ECompanyIdKey,
  EDeleteDisclosureCompanyKey,
  EDisclosureCompanyKey,
  IEffectiveIngredient,
  IRawMaterialAdd,
  IRawMaterialForm,
  IRawMaterialUpdate,
  ISpecialBrandUpdate,
  IThesisGet,
} from 'types/material/rawMaterial';
import {
  defaultMaterialEfficacies,
  defaultMaterialFormulaPurposes,
  messages,
} from 'lib/consts';
import history from 'lib/history';
import path from 'lib/path';
import { getUpdatingObject } from 'lib/form';

export const useRawMaterialDocuments = (materialId: number) => {
  const { data: documents = [], isFetching: getLoading } = useQuery(
    ['material/rawMaterial/getRawMaterialDocuments', materialId],
    () => rawMaterialApi.getDocuments(materialId),
    {
      select: (res) => res.data.result,
    },
  );

  return useMemo(() => ({ documents, getLoading }), [documents, getLoading]);
};

export const useRawMaterialNameEnDuplicateCheck = () => {
  const {
    mutateAsync: rawMaterialNameEnDuplicateCheck,
  } = useMutation((materialNameEn: string) =>
    rawMaterialApi.rawMaterialNameEnDuplicateCheck(materialNameEn),
  );
  return rawMaterialNameEnDuplicateCheck;
};

export const useMadeCountries = () => {
  const { data: madeCountries = [], isFetching: getLoading } = useQuery(
    ['country-list'],
    rawMaterialApi.getMadeCountries,
    {
      select: (res) => res.data.result,
    },
  );

  return useMemo(() => ({ madeCountries, getLoading }), [
    madeCountries,
    getLoading,
  ]);
};

export const useRawMaterial = (materialId?: number) => {
  const { data: rawMaterial = null, isFetching: getLoading } = useQuery(
    ['material/rawMaterial/getRawMaterial', materialId],
    () => rawMaterialApi.getRawMaterial(materialId!),
    {
      enabled: typeof materialId !== 'undefined',
      select: (res) => res.data.result,
    },
  );

  const { mutate: addRawMaterialMutate, isLoading: addLoading } = useMutation(
    (rawMaterial: IRawMaterialAdd) =>
      rawMaterialApi.addRawMaterial(rawMaterial),
    {
      onSuccess: (res) => {
        message.success('입력되었습니다.');
        history.replace(
          `${path.material.rawMaterial.root}/${res.data.result.materialId}`,
        );
      },
    },
  );

  const addRawMaterial = ({
    efficacies,
    formulaPurposes,
    marketingKeywords,
    patents,
    theses,
    disclosures,
    specialBrands,
    effectiveIngredients,
    combinationWarningText,
    ...rest
  }: IRawMaterialForm) => {
    addRawMaterialMutate({
      ...rest,
      ...(effectiveIngredients?.length && {
        effectiveIngredients,
      }),
      ...(combinationWarningText && {
        combinationWarningText,
      }),
      ...(disclosures['disclosureBrandCompanyIds'].length && {
        disclosureBrandCompanyIds: disclosures['disclosureBrandCompanyIds'],
      }),
      ...(disclosures['disclosureManufacturerIds'].length && {
        disclosureManufacturerIds: disclosures['disclosureManufacturerIds'],
      }),
      ...(disclosures['disclosureMaterialCompanyIds'].length && {
        disclosureMaterialCompanyIds:
          disclosures['disclosureMaterialCompanyIds'],
      }),
      ...(rest.hasExperienceSpecialBrandDelivery && { specialBrands }),
      efficacies: efficacies.map((efficacyName) => ({
        efficacyName,
        isDefault: defaultMaterialEfficacies.includes(efficacyName),
      })),
      formulaPurposes: formulaPurposes.map((formulaPurposeName) => ({
        formulaPurposeName,
        isDefault: defaultMaterialFormulaPurposes.includes(formulaPurposeName),
      })),
      marketingKeywords: Array.from(
        new Set(
          marketingKeywords
            .split(/,\s?/)
            .map((keyword) => keyword.slice(0, 15))
            .filter((keyword: string) => keyword.trim() !== ''),
        ),
      ).map((keyword) => ({
        keyword,
      })),
      ...(patents.length && {
        patents: patents.map((patentNumber) => ({ patentNumber })),
      }),
      ...(theses.length && {
        theses: (theses as File[]).map((uploadFile) => ({ uploadFile })),
      }),
    });
  };

  const {
    mutate: updateRawMaterialMutate,
    isLoading: updateLoading,
  } = useMutation(
    (rawMaterial: IRawMaterialUpdate) =>
      rawMaterialApi.updateRawMaterial(rawMaterial),
    {
      onSuccess: () => {
        message.success('수정되었습니다.');
        history.goBack();
      },
    },
  );

  const getDisclosureKeys = (
    key: string,
  ): [
    EDisclosureCompanyKey,
    EDeleteDisclosureCompanyKey,
    EAddDisclosureCompanyKey,
    ECompanyIdKey,
  ] => {
    switch (key) {
      case 'disclosureBrandCompanyIds':
        return [
          EDisclosureCompanyKey.BRAND,
          EDeleteDisclosureCompanyKey.BRAND,
          EAddDisclosureCompanyKey.BRAND,
          ECompanyIdKey.BRAND,
        ];
      case 'disclosureManufacturerIds':
        return [
          EDisclosureCompanyKey.MANU,
          EDeleteDisclosureCompanyKey.MANU,
          EAddDisclosureCompanyKey.MANU,
          ECompanyIdKey.MANU,
        ];
      case 'disclosureMaterialCompanyIds':
        return [
          EDisclosureCompanyKey.MATERIAL,
          EDeleteDisclosureCompanyKey.MATERIAL,
          EAddDisclosureCompanyKey.MATERIAL,
          ECompanyIdKey.MATERIAL,
        ];
      default:
        return [
          EDisclosureCompanyKey.BRAND,
          EDeleteDisclosureCompanyKey.BRAND,
          EAddDisclosureCompanyKey.BRAND,
          ECompanyIdKey.BRAND,
        ];
    }
  };

  const addTobeUpdatedDisclosures = (
    rawMaterialUpdateValues: IRawMaterialUpdate,
    companyIds: number[],
    disclosureKey: string,
  ) => {
    const [companyKey, deleteKey, addKey, companyIdKey] = getDisclosureKeys(
      disclosureKey,
    );
    if (rawMaterial) {
      const originalDisclosures = rawMaterial[companyKey] as {
        [key: string]: number;
      }[];
      const deletingDisclosureIds = originalDisclosures
        .filter(
          (originalDisclosure) =>
            !companyIds.includes(originalDisclosure[companyIdKey]),
        )
        .map((originalDisclosure) => originalDisclosure[companyIdKey]);
      if (deletingDisclosureIds.length) {
        rawMaterialUpdateValues[deleteKey] = deletingDisclosureIds;
      }
      const newDisclosureIds = companyIds.filter((id) =>
        originalDisclosures.every(
          (originalDisclosure) => originalDisclosure[companyIdKey] !== id,
        ),
      );
      if (newDisclosureIds.length) {
        rawMaterialUpdateValues[addKey] = newDisclosureIds;
      }
    }
  };

  const updateRawMaterial = ({
    materialNameEn,
    materialNameKo,
    originManufacturerName,
    isSelfProduction,
    isShowManufacturerName,
    madeCountry,
    hsCode,
    manageCode,
    hasExperienceSpecialBrandDelivery,
    originType,
    efficacies,
    formulaPurposes,
    marketingKeywords,
    patents,
    theses,
    brandCompanyDisclosureType,
    manufacturerDisclosureType,
    materialCompanyDisclosureType,
    disclosures,
    specialBrands,
    effectiveIngredients,
    combinationWarningText,
  }: IRawMaterialForm) => {
    if (rawMaterial) {
      const rawMaterialUpdateValues: IRawMaterialUpdate = {
        materialId: rawMaterial.materialId,
        ...(materialNameEn !== rawMaterial.materialNameEn && {
          materialNameEn,
        }),
        ...(materialNameKo !== rawMaterial.materialNameKo && {
          materialNameKo,
        }),
        ...(originManufacturerName !== rawMaterial.originManufacturerName && {
          originManufacturerName,
        }),
        ...(isSelfProduction !== rawMaterial.isSelfProduction && {
          isSelfProduction,
        }),
        ...(isShowManufacturerName !== rawMaterial.isShowManufacturerName && {
          isShowManufacturerName,
        }),
        ...(madeCountry !== rawMaterial.madeCountry && {
          madeCountry,
        }),
        ...(hsCode !== rawMaterial.hsCode && {
          hsCode,
        }),
        ...(manageCode !== rawMaterial.manageCode && {
          manageCode,
        }),
        ...(brandCompanyDisclosureType !==
          rawMaterial.brandCompanyDisclosureType && {
          brandCompanyDisclosureType,
        }),
        ...(manufacturerDisclosureType !==
          rawMaterial.manufacturerDisclosureType && {
          manufacturerDisclosureType,
        }),
        ...(materialCompanyDisclosureType !==
          rawMaterial.materialCompanyDisclosureType && {
          materialCompanyDisclosureType,
        }),
        ...(hasExperienceSpecialBrandDelivery !==
          rawMaterial.hasExperienceSpecialBrandDelivery && {
          hasExperienceSpecialBrandDelivery,
        }),
        ...(originType !== rawMaterial.originType && {
          originType,
        }),
        ...(combinationWarningText !== rawMaterial.combinationWarningText && {
          combinationWarningText,
        }),
      };

      // disclosures
      Object.entries(disclosures).map((disclosure) =>
        addTobeUpdatedDisclosures(
          rawMaterialUpdateValues,
          disclosure[1],
          disclosure[0],
        ),
      );
      // specialBrand
      if (specialBrands) {
        const updatingSpecialBrands =
          specialBrands.reduce<ISpecialBrandUpdate[]>(
            (acc, { brandCode, docResponseType }) => {
              const originSpecialBrand = rawMaterial.specialBrands.find(
                (specialBrand) => specialBrand.brandCode === brandCode,
              );

              if (!originSpecialBrand) {
                // new
                acc.push({ brandCode, actionType: 'ADD', docResponseType });
              } else if (
                originSpecialBrand.docResponseType !== docResponseType
              ) {
                // update
                acc.push({
                  specialBrandId: originSpecialBrand.specialBrandId,
                  brandCode,
                  actionType: 'MOD',
                  docResponseType,
                });
              }
              return acc;
            },
            [],
          ) || [];

        rawMaterial.specialBrands.forEach(({ specialBrandId, brandCode }) => {
          if (
            specialBrands.every(
              (specialBrand) => specialBrand.brandCode !== brandCode,
            )
          ) {
            updatingSpecialBrands.push({
              specialBrandId,
              actionType: 'DELETE',
            });
          }
        });
        if (updatingSpecialBrands.length) {
          rawMaterialUpdateValues.specialBrands = updatingSpecialBrands;
        }
      }

      // efficacy
      const deletingEfficacyIds = rawMaterial.efficacies
        .filter(({ efficacyName }) => !efficacies.includes(efficacyName))
        .map(({ efficacyCategoryId }) => efficacyCategoryId);
      if (deletingEfficacyIds.length) {
        rawMaterialUpdateValues.deleteEfficacies = deletingEfficacyIds;
      }
      const newEfficacies = efficacies.filter((efficacy) =>
        rawMaterial.efficacies.every(
          ({ efficacyName }) => efficacy !== efficacyName,
        ),
      );
      if (newEfficacies.length) {
        rawMaterialUpdateValues.addEfficacies = newEfficacies.map(
          (efficacyName) => ({
            efficacyName,
            isDefault: defaultMaterialEfficacies.includes(efficacyName),
          }),
        );
      }
      // formulaPurpose
      const deletingFormulaPurposeIds = rawMaterial.formulaPurposes
        .filter(
          ({ formulaPurposeName }) =>
            !formulaPurposes.includes(formulaPurposeName),
        )
        .map(({ formulaPurposeCategoryId }) => formulaPurposeCategoryId);
      if (deletingFormulaPurposeIds.length) {
        rawMaterialUpdateValues.deleteFormulaPurposes = deletingFormulaPurposeIds;
      }
      const newFormulaPurpose = formulaPurposes.filter((efficacy) =>
        rawMaterial.formulaPurposes.every(
          ({ formulaPurposeName }) => efficacy !== formulaPurposeName,
        ),
      );
      if (newFormulaPurpose.length) {
        rawMaterialUpdateValues.addFormulaPurposes = newFormulaPurpose.map(
          (formulaPurposeName) => ({
            formulaPurposeName,
            isDefault: defaultMaterialFormulaPurposes.includes(
              formulaPurposeName,
            ),
          }),
        );
      }

      // marketingKeywords
      const deduplicatedMarketingKeywords = Array.from(
        new Set(
          marketingKeywords
            .split(/,\s?/)
            .map((keyword) => keyword.slice(0, 15))
            .filter((keyword: string) => keyword.trim() !== ''),
        ),
      );

      const deletingMarketingKeywordIds = rawMaterial.marketingKeywords
        .filter(
          ({ keyword }) => !deduplicatedMarketingKeywords.includes(keyword),
        )
        .map(({ marketingKeywordId }) => marketingKeywordId);
      if (deletingMarketingKeywordIds.length) {
        rawMaterialUpdateValues.deleteMarketingKeywords = deletingMarketingKeywordIds;
      }
      const newMarketingKeywords = deduplicatedMarketingKeywords.filter(
        (marketingKeyword) =>
          rawMaterial.marketingKeywords.every(
            ({ keyword }) => marketingKeyword !== keyword,
          ),
      );
      if (newMarketingKeywords.length) {
        rawMaterialUpdateValues.addMarketingKeywords = newMarketingKeywords.map(
          (keyword) => ({
            keyword,
          }),
        );
      }

      // 유효 성분 (effectiveIngredients)
      if (effectiveIngredients) {
        const [
          newEffectiveIngredients,
          tobeUpdatedEffectiveIngredients,
        ] = effectiveIngredients.reduce<
          [IEffectiveIngredient[], IEffectiveIngredient[]]
        >(
          (acc, curr) => {
            acc[curr.materialEffectiveIngredientId ? 0 : 1].push(curr);
            return acc;
          },
          [[], []],
        );
        if (rawMaterial.effectiveIngredients) {
          const checkEffectiveIngredientTobeDelete = (
            originalEffectiveIngredient: IEffectiveIngredient,
          ) => {
            const effectiveIngredient = effectiveIngredients.find(
              (effectiveIngredient) =>
                effectiveIngredient.materialEffectiveIngredientId ===
                originalEffectiveIngredient.materialEffectiveIngredientId,
            );
            return effectiveIngredient ? false : true;
          };
          const getTobeDeleteEffectiveIngredientIds = () =>
            rawMaterial.effectiveIngredients
              .filter((originalEffectiveIngredient) =>
                checkEffectiveIngredientTobeDelete(originalEffectiveIngredient),
              )
              .map(
                (effectiveIngredient) =>
                  effectiveIngredient.materialEffectiveIngredientId!,
              );
          const tobeDeleteEffectiveIngredientIds = getTobeDeleteEffectiveIngredientIds();
          if (tobeDeleteEffectiveIngredientIds.length > 0) {
            rawMaterialUpdateValues.deleteEffectiveIngredients = tobeDeleteEffectiveIngredientIds;
          }
          const checkEffectiveIngredientTobeUpdated = (
            effectiveIngredient: IEffectiveIngredient,
          ) => {
            const originalEffectiveIngredient = rawMaterial.effectiveIngredients.find(
              (originalEffectiveIngredient) =>
                effectiveIngredient.materialEffectiveIngredientId ===
                originalEffectiveIngredient.materialEffectiveIngredientId,
            );
            return !!getUpdatingObject(
              originalEffectiveIngredient,
              effectiveIngredient,
            );
          };
          newEffectiveIngredients.forEach((newEffectiveIngredient) => {
            if (checkEffectiveIngredientTobeUpdated(newEffectiveIngredient))
              tobeUpdatedEffectiveIngredients.push(newEffectiveIngredient);
          });
        }
        if (tobeUpdatedEffectiveIngredients.length > 0) {
          rawMaterialUpdateValues.effectiveIngredients = tobeUpdatedEffectiveIngredients;
        }
      }

      // patent
      const deletingPatentIds = rawMaterial.patents
        .filter(({ patentNumber }) => !patents.includes(patentNumber))
        .map(({ materialPatentId }) => materialPatentId);
      if (deletingPatentIds.length) {
        rawMaterialUpdateValues.deletePatents = deletingPatentIds;
      }
      const newPatents = patents.filter((patent) =>
        rawMaterial.patents.every(
          ({ patentNumber }) => patent !== patentNumber,
        ),
      );
      if (newPatents.length) {
        rawMaterialUpdateValues.addPatents = newPatents.map((patentNumber) => ({
          patentNumber,
        }));
      }

      // thesis
      const remainingTheses = theses.filter(
        (thesis) => !(thesis instanceof File),
      ) as IThesisGet[];
      const deletingThesisIds = rawMaterial.theses
        .filter(({ fileName }) =>
          remainingTheses.every(
            (remainingThesis) => remainingThesis.fileName !== fileName,
          ),
        )
        .map(({ materialThesisId }) => materialThesisId);
      if (deletingThesisIds.length) {
        rawMaterialUpdateValues.deleteTheses = deletingThesisIds;
      }
      const newTheses = theses.filter(
        (thesis) => thesis instanceof File,
      ) as File[];
      if (newTheses.length) {
        rawMaterialUpdateValues.addTheses = newTheses.map((uploadFile) => ({
          uploadFile,
        }));
      }

      if (Object.keys(rawMaterialUpdateValues).length === 1) {
        return message.warn(messages.NO_NEED_TO_UPDATE);
      }

      updateRawMaterialMutate(rawMaterialUpdateValues);
    } else {
      throw Error("rawMaterial can't not be null");
    }
  };

  const {
    mutate: registerRawMaterial,
    isLoading: registerLoading,
  } = useMutation((materialId: number) =>
    rawMaterialApi.registerRawMaterial(materialId),
  );

  return useMemo(
    () => ({
      rawMaterial,
      getLoading,
      addRawMaterial,
      addLoading,
      updateRawMaterial,
      updateLoading,
      registerRawMaterial,
      registerLoading,
    }),
    [
      rawMaterial,
      getLoading,
      addRawMaterial,
      addLoading,
      updateRawMaterial,
      updateLoading,
      registerRawMaterial,
      registerLoading,
    ],
  );
};

export const useRegisteredRawMaterials = (enabled?: boolean) => {
  const {
    data: registeredRawMaterials = [],
    isFetching: getLoading,
  } = useQuery(
    'material/rawMaterial/getRegisteredRawMaterials',
    rawMaterialApi.getRegisteredRawMaterials,
    {
      select: (res) => res.data.result,
      enabled,
    },
  );

  return useMemo(
    () => ({
      registeredRawMaterials,
      getLoading,
    }),
    [registeredRawMaterials, getLoading],
  );
};
