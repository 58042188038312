import MistakeNoticeItem from 'components/MistakeNoticeItem';
import ProductVCRPAccountRequest from 'components/product/ProductVCRPAccountRequest';
import ProductPageTemplate from 'templates/ProductPageTemplate';
import { Typography } from 'components/system';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';

const notices = [
  <>
    아래 정보에 기입된 담당자는{' '}
    <Typography.Text type="secondary" medium inline>
      FDA
    </Typography.Text>
    로부터 회원가입 승인에 따른 비밀번호 변경 링크, 제품 문의 등의 연락을 받게
    됩니다.
  </>,
  <MistakeNoticeItem />,
];

const ProductVCRPAccountRequestPage = () => {
  const { productId } = useCurrentProduct();
  const countryId = useCountryId()!;

  return (
    <ProductPageTemplate subtitle="신규 VCRP 계정 생성" notices={notices}>
      <ProductVCRPAccountRequest productId={productId} countryId={countryId} />
    </ProductPageTemplate>
  );
};

export default ProductVCRPAccountRequestPage;
