import { useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { message } from 'antd';

import * as marketingApi from 'lib/api/material/marketing';
import {
  createFormData,
  createFormDataForMarketing,
  downloadFile,
} from 'lib/file';
import {
  IMarketingTemplateFormData,
  IMarketingFormDataBase,
  IUploadType,
  MarketingUploadType,
  IPage,
  EMarketingDisplayType,
} from 'types/material/marketing';
import { checkIsPageEmpty } from 'lib/validate';
import history from 'lib/history';

export const useMarketingTemplate = (materialId?: number) => {
  const {
    data: marketingTemplateData = null,
    isFetching: getMarketingTemplateDataLoading,
  } = useQuery(
    [`/material/marketing/template/${materialId}`, materialId],
    () => marketingApi.getMarketingTemplate(materialId!),
    {
      enabled: typeof materialId !== 'undefined',
      select: (res) => res.result,
    },
  );

  const {
    mutateAsync: addMarketingTemplate,
    isLoading: addMarketingTemplateLoading,
  } = useMutation((param: { formData: FormData; materialId: number }) =>
    marketingApi.addMarketingTemplate(param),
  );

  const updateModeTemplateLimit = useMemo(() => {
    if (
      !!marketingTemplateData?.limitedPages.length &&
      marketingTemplateData?.files.length !== 0
    ) {
      return true;
    } else {
      return false;
    }
  }, [marketingTemplateData]);

  const updateModeTemplatePublic = useMemo(() => {
    if (
      !!marketingTemplateData?.publicPages.length &&
      marketingTemplateData?.files.length !== 0
    ) {
      return true;
    } else {
      return false;
    }
  }, [marketingTemplateData]);

  const {
    mutateAsync: updateMarketingTemplate,
    isLoading: updateMarketingTemplateLoading,
  } = useMutation((formData: FormData) =>
    marketingApi.updateMarketingTemplate({ materialId: materialId!, formData }),
  );

  const handleDownloadOnly = async (
    formData: IMarketingFormDataBase,
    setDownloadLoading: any,
  ) => {
    if (checkIsPageEmpty(formData)) {
      return message.warn(
        '빈 페이지가 존재합니다. 삭제 후 다시 시도해 주세요.',
      );
    }
    addMarketingTemplate(
      {
        materialId: materialId!,
        formData: createFormDataForMarketing(formData),
      },
      {
        onSuccess: async (res) => {
          setDownloadLoading(true);
          await downloadFile(res.result.attachUrl, res.result.fileName);
          setDownloadLoading(false);
        },
      },
    );
  };

  const handleComplete = async (formData: IMarketingTemplateFormData) => {
    if (updateModeTemplatePublic || updateModeTemplateLimit) {
      return await updateMarketingTemplate(
        createFormDataForMarketing(formData),
      );
    }

    return await addMarketingTemplate({
      materialId: materialId!,
      formData: createFormDataForMarketing(formData),
    });
  };

  const handleTempSave = async (formData: IMarketingTemplateFormData) => {
    return await addMarketingTemplate({
      materialId: materialId!,
      formData: createFormDataForMarketing(formData),
    });
  };

  const validateTemplateChange = (
    formData: IMarketingTemplateFormData,
    previousPages: IPage[],
  ) => {
    if (formData.pages) {
      // HINT : 현재 폼 데이터의 페이지 수가 기존 등록 데이터와 다름.
      if (formData.pages.length !== previousPages.length) {
        return true;
      }
      // HINT : 페이지수는 같지만 템플릿 타입이 변경됨
      if (
        formData.pages.find(
          (page, index) =>
            page.templateType !== previousPages[index].templateType,
        )
      ) {
        return true;
      }
      // HINT : 페이지수 및 모든 템플릿 타입이 같기 때문에 기존 데이터와 현재 폼 데이터의 내용 비교.
      for (let i = 0; i < previousPages.length; i++) {
        const prePage = previousPages[i];
        const curFormPage = formData.pages[i];
        for (let j = 0; j < prePage.items.length; j++) {
          const preItem = prePage.items[j];
          const curFormItem = curFormPage.items[j];
          if (preItem.attachUrl !== curFormItem.attachUrl) {
            return true;
          }
          if (preItem.dataTitle?.trim() !== curFormItem.dataTitle?.trim()) {
            return true;
          }
          if (preItem.description?.trim() !== curFormItem.description?.trim()) {
            return true;
          }
          if (preItem.itemType?.trim() !== curFormItem.itemType?.trim()) {
            return true;
          }
          if (
            preItem.shortDescription?.trim() !==
            curFormItem.shortDescription?.trim()
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const checkMarketingTemplateChange = (
    limitTemplateFormData: IMarketingTemplateFormData,
    publicTemplateFormData: IMarketingTemplateFormData,
  ) => {
    if (marketingTemplateData) {
      // HINT : 공개형, 제한형 둘 다 등록된 적이 없고 폼에 입력된 데이터가 없음.
      if (
        marketingTemplateData.publicPages.length === 0 &&
        !publicTemplateFormData.pages &&
        marketingTemplateData.limitedPages.length === 0 &&
        !limitTemplateFormData.pages
      ) {
        return false;
      }

      // 공개형
      if (
        publicTemplateFormData.pages &&
        validateTemplateChange(
          publicTemplateFormData,
          marketingTemplateData.publicPages,
        )
      ) {
        return true;
      }
      // 제한형
      if (
        limitTemplateFormData.pages &&
        validateTemplateChange(
          limitTemplateFormData,
          marketingTemplateData.limitedPages,
        )
      ) {
        return true;
      }
    }
    return false;
  };

  return useMemo(
    () => ({
      marketingTemplateData,
      getMarketingTemplateDataLoading,
      updateModeTemplateLimit,
      updateModeTemplatePublic,
      addMarketingTemplateLoading,
      updateMarketingTemplateLoading,
      onDownloadOnly: handleDownloadOnly,
      onComplete: handleComplete,
      onTempSave: handleTempSave,
      checkMarketingTemplateChange,
    }),
    [
      materialId,
      getMarketingTemplateDataLoading,
      addMarketingTemplateLoading,
      updateMarketingTemplateLoading,
      handleDownloadOnly,
      handleComplete,
      handleTempSave,
      checkMarketingTemplateChange,
    ],
  );
};

export const useMarketingUpload = (materialId?: number) => {
  const {
    mutateAsync: addMarketingUpload,
    isLoading: addMarketingUploadLoading,
  } = useMutation((params: { materialId: number; formData: FormData }) =>
    marketingApi.addMarketingUpload(params),
  );

  const {
    mutateAsync: updateMarketingUpload,
    isLoading: updateMarketingUploadLoading,
  } = useMutation((params: { materialId: number; formData: FormData }) =>
    marketingApi.updateMarketingUpload(params),
  );

  const {
    mutateAsync: deleteMarketing,
    isLoading: deleteMarketingLoading,
  } = useMutation((materialMarketingDataId: number) =>
    marketingApi.deleteMarketing(materialMarketingDataId),
  );

  const {
    data: marketingUploadData = null,
    isFetching: getMarketingUploadDataLoading,
  } = useQuery(
    [
      `/material/marketing/upload`,
      materialId,
      addMarketingUploadLoading,
      deleteMarketingLoading,
      updateMarketingUploadLoading,
    ],
    () => marketingApi.getMarketingUpload(materialId!),
    {
      enabled: typeof materialId !== 'undefined',
      select: (res) => res.result,
    },
  );
  const marketingUploadTypeData = useMemo(() => {
    const initialUploadTypeData: {
      public: IUploadType | null;
      limit: IUploadType | null;
    } = {
      public: null,
      limit: null,
    };
    if (!!marketingUploadData?.uploadTypes?.length) {
      marketingUploadData?.uploadTypes.forEach((file) => {
        if (file.displayType === EMarketingDisplayType.PUBLIC) {
          initialUploadTypeData.public = file;
        }
        if (file.displayType === EMarketingDisplayType.LIMIT) {
          initialUploadTypeData.limit = file;
        }
      });
    }
    return initialUploadTypeData;
  }, [
    marketingUploadData,
    addMarketingUploadLoading,
    deleteMarketingLoading,
    updateMarketingUploadLoading,
  ]);

  const handleSubmit = async (
    publicUploadFile: MarketingUploadType,
    limitUploadFile: MarketingUploadType,
  ) => {
    const updateUploadTypes = [];
    const deleteUploadTypeIds = [];
    const addUploadTypes = [];
    if (typeof publicUploadFile === 'number') {
      await deleteMarketing(publicUploadFile);
    } else {
      // public
      if (marketingUploadTypeData.public) {
        if (publicUploadFile instanceof File) {
          updateUploadTypes.push({
            displayType: EMarketingDisplayType.PUBLIC,
            uploadFile: publicUploadFile,
          });
          deleteUploadTypeIds.push(
            marketingUploadTypeData.public.materialMarketingDataId,
          );
        }
      } else {
        if (publicUploadFile instanceof File) {
          addUploadTypes.push({
            displayType: EMarketingDisplayType.PUBLIC,
            uploadFile: publicUploadFile,
          });
        }
      }
    }
    // limit
    if (typeof limitUploadFile === 'number') {
      await deleteMarketing(limitUploadFile);
    } else {
      if (marketingUploadTypeData.limit) {
        if (limitUploadFile instanceof File) {
          updateUploadTypes.push({
            displayType: EMarketingDisplayType.LIMIT,
            uploadFile: limitUploadFile,
          });
          deleteUploadTypeIds.push(
            marketingUploadTypeData.limit.materialMarketingDataId,
          );
        }
      } else {
        if (limitUploadFile instanceof File) {
          addUploadTypes.push({
            displayType: EMarketingDisplayType.LIMIT,
            uploadFile: limitUploadFile,
          });
        }
      }

      if (
        updateUploadTypes.length === 0 &&
        addUploadTypes.length === 0 &&
        typeof publicUploadFile !== 'number' &&
        typeof limitUploadFile !== 'number'
      ) {
        return message.warn('파일을 입력해 주세요.');
      }

      if (addUploadTypes.length > 0) {
        await addMarketingUpload({
          materialId: materialId!,
          formData: createFormData({
            uploadTypes: addUploadTypes,
          }),
        });
      }

      if (updateUploadTypes.length > 0) {
        await updateMarketingUpload({
          materialId: materialId!,
          formData: createFormData({
            deleteUploadTypeIds,
            uploadTypes: updateUploadTypes,
          }),
        });
      }
    }

    message.success(
      `${
        marketingUploadTypeData.public || marketingUploadTypeData.limit
          ? '수정'
          : '입력'
      }되었습니다.`,
    );
    history.goBack();
  };

  const checkMarketingUploadChange = (
    publicUploadFile: MarketingUploadType,
    limitUploadFile: MarketingUploadType,
  ) => {
    if (
      typeof publicUploadFile === 'number' ||
      typeof limitUploadFile === 'number'
    ) {
      return true;
    } else if (
      publicUploadFile instanceof File ||
      limitUploadFile instanceof File
    ) {
      return true;
    }

    return false;
  };

  return useMemo(
    () => ({
      marketingUploadData,
      marketingUploadTypeData,
      getMarketingUploadDataLoading,
      addMarketingUpload,
      addMarketingUploadLoading,
      updateMarketingUpload,
      updateMarketingUploadLoading,
      deleteMarketing,
      deleteMarketingLoading,
      onSubmit: handleSubmit,
      checkMarketingUploadChange,
    }),
    [
      materialId,
      marketingUploadData,
      getMarketingUploadDataLoading,
      addMarketingUploadLoading,
      updateMarketingUploadLoading,
      deleteMarketingLoading,
      addMarketingUpload,
      updateMarketingUpload,
      deleteMarketing,
      handleSubmit,
      checkMarketingUploadChange,
    ],
  );
};
