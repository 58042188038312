import { memo } from 'react';
import { nanoid } from 'nanoid';
import styled from 'styled-components';
import { Form, Input, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { ICompositionInformation } from 'types/material/msds';
import { Typography } from 'components/system';
import palette from 'lib/styles/palette';
import { exceptKoreanRule } from 'lib/validate';

const TextWithAsterisk = styled(Typography.Text)`
  &::before {
    content: '*';
    color: ${palette.primary};
  }
`;

const MSDSCompositionInformationsBlock = styled.div`
  margin-bottom: 32px;

  th.ant-table-cell {
    text-align: center;
  }

  td.ant-table-cell {
    padding: 10px 4px !important;
    vertical-align: top;

    .ant-form-item {
      margin-bottom: 0;

      .ant-form-item-control-input {
        min-height: 22px;

        .ant-input {
          padding: 8px;
          border-color: transparent;
          background-color: transparent;
          text-align: center;

          &:hover,
          &:focus {
            border-color: ${palette.primary};
            background-color: #fff;
          }
        }
      }
    }
  }
`;

const MSDSCompositionInformations = () => {
  const normalizeInput = (value: string) => value.replace('\n', '');

  const columns: ColumnsType<ICompositionInformation> = [
    {
      title: 'No.',
      align: 'center',
      width: 40,
      render: (_, __, index) => (
        <Typography.Text type="secondary" gutter={{ top: 10 }}>
          {index + 1}
        </Typography.Text>
      ),
    },
    {
      title: 'INCI Name',
      width: 444,
      render: (_, __, index) => (
        <Form.Item
          name={['compositionInformations', index, 'inciNameEn']}
          normalize={normalizeInput}
          rules={[exceptKoreanRule]}
        >
          <Input.TextArea
            autoSize={{ minRows: 1, maxRows: 3 }}
            style={{ textAlign: 'left' }}
          />
        </Form.Item>
      ),
    },
    {
      title: 'WT %',
      dataIndex: 'wt',
      width: 128,
      render: (_, __, index) => (
        <Form.Item
          name={['compositionInformations', index, 'wt']}
          normalize={normalizeInput}
          rules={[exceptKoreanRule]}
        >
          <Input.TextArea
            autoSize={{ minRows: 1, maxRows: 3 }}
            style={{ textAlign: 'right' }}
          />
        </Form.Item>
      ),
    },
    {
      title: 'CAS NO.',
      width: 128,
      render: (_, __, index) => (
        <Form.Item
          name={['compositionInformations', index, 'casNo']}
          normalize={normalizeInput}
          rules={[exceptKoreanRule]}
        >
          <Input.TextArea autoSize={{ minRows: 1, maxRows: 3 }} />
        </Form.Item>
      ),
    },
    {
      title: 'EC NO.',
      width: 128,
      render: (_, __, index) => (
        <Form.Item
          name={['compositionInformations', index, 'euCasNo']}
          normalize={normalizeInput}
          rules={[exceptKoreanRule]}
        >
          <Input.TextArea autoSize={{ minRows: 1, maxRows: 3 }} />
        </Form.Item>
      ),
    },
  ];
  return (
    <MSDSCompositionInformationsBlock>
      <TextWithAsterisk type="secondary" gutter={{ bottom: 4 }}>
        현재 페이지에서 수정되는 Data는 'Composition Information' 페이지의
        정보에 영향을 주지 않습니다.
      </TextWithAsterisk>
      <Form.Item
        shouldUpdate={(prev, next) =>
          prev.compositionInformations?.length !==
          next.compositionInformations?.length
        }
      >
        {({ getFieldValue }) => (
          <Table
            columns={columns}
            pagination={false}
            dataSource={getFieldValue(['compositionInformations'])}
            rowKey={({ materialMsdsCompositionInformationId }) =>
              materialMsdsCompositionInformationId || nanoid()
            }
          />
        )}
      </Form.Item>
    </MSDSCompositionInformationsBlock>
  );
};

export default memo(MSDSCompositionInformations);
