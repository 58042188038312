import styled, { css } from 'styled-components';
import { Col, message, Row } from 'antd';

import palette from 'lib/styles/palette';
import { Typography } from 'components/system';
import { ReactComponent as Download } from 'asset/svg/download.svg';

const Container = styled(Row)<{ $active: boolean }>`
  min-width: 176px;
  padding: 12px 16px;
  border-radius: 4px;
  border: 1px solid ${palette.inactive};
  cursor: pointer;

  img {
    margin-right: 8px;
  }

  ${({ $active }) =>
    $active &&
    css`
      box-shadow: 0px 2px 22px rgba(162, 162, 162, 0.23);
      border-color: ${palette.primary};
    `}
`;

const DownloadContainer = styled.div<{ $active: boolean }>`
  width: 24px;
  height: 24px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: ${palette.paleGray10};

  ${({ $active }) =>
    $active &&
    css`
      background-color: ${palette.lightBlue10};
    `}
`;

const RetailerMark = ({
  title,
  imageUrl,
  active,
  downloadable,
  onDownload,
  onClick,
}: {
  title: string;
  imageUrl?: string;
  active: boolean;
  downloadable?: boolean;
  onDownload?: () => void;
  onClick?: () => void;
}) => {
  const handleDownload = () => {
    if (!downloadable) {
      return message.warn(
        '입력필요 항목을 모두 입력하셔야 다운로드할 수있습니다.',
      );
    }

    onDownload && onDownload();
  };

  return (
    <Container
      $active={active}
      align="middle"
      justify="space-between"
      onClick={onClick}
    >
      <Col>
        {imageUrl && <img src={imageUrl} alt={title} />}
        <Typography.Text color={active ? 'black' : 'disabled'} inline>
          {title}
        </Typography.Text>
      </Col>
      {active && imageUrl && (
        <Col>
          <DownloadContainer
            $active={!!downloadable}
            onClick={(e) => {
              e.stopPropagation();
              handleDownload();
            }}
          >
            <Download
              fill={downloadable ? palette.primary : palette.inactive}
              width={12}
              height={12}
            />
          </DownloadContainer>
        </Col>
      )}
    </Container>
  );
};

export default RetailerMark;
