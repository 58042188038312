import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { message } from 'antd';
import moment from 'moment';

import { IEtcDataForm } from 'types/material/etcData';
import * as etcDataApi from 'lib/api/material/etcData';
import history from 'lib/history';
import { messages } from 'lib/consts';
import { useCompany } from 'service/company';

const MB = 10 ** 6;

export const useEtcDatas = (materialId: number, enabled: boolean) => {
  const [etcDatas, setEtcDatas] = useState<IEtcDataForm[]>([]);
  const { companyId } = useCompany();
  const { data: etcDataUsages = 0 } = useQuery(
    ['material/etcData/getUsages', companyId],
    () => etcDataApi.getEtcDataUsages(companyId),
    { select: (res) => res.data.result },
  );
  const { data: originEtcDatas = [], isFetching: getLoading } = useQuery(
    ['material/etcData/getEtcDatas', materialId],
    () => etcDataApi.getEtcDatas(materialId),
    {
      enabled: enabled,
      select: (res) => res.data.result,
    },
  );
  const totalFileSize = useMemo(() => {
    const originUsages =
      etcDataUsages -
      originEtcDatas.reduce((acc, { filesize }) => acc + filesize, 0);
    return etcDatas.reduce((acc, curr) => acc + curr.filesize, originUsages);
  }, [etcDatas, etcDataUsages]);
  const isFileSizeExceed = totalFileSize > 500 * MB;
  const addEtcData = useCallback(
    (file: File) => {
      if (file.size > 200 * MB) {
        return message.warn(`${file.name} : 200 MB 초과`);
      }
      if (etcDatas.some(({ filename }) => filename === file.name)) {
        return message.warn(`${file.name} : 이미 업로드한 파일`);
      }
      setEtcDatas((draftEtcDatas) =>
        draftEtcDatas.concat({
          file: file,
          filename: file.name,
          filesize: file.size,
          registerDt: moment().format('YYYY-MM-DD'),
        }),
      );
    },
    [etcDatas],
  );

  const deleteFile = useCallback((index: number) => {
    setEtcDatas((draftEtcDatas) => draftEtcDatas.filter((_, i) => i !== index));
  }, []);

  const saveEtcDatas = useCallback(() => {
    if (isFileSizeExceed) {
      return message.warn('최대 용량인 500 MB를 초과했습니다.');
    }
    const addingEtcDataFiles = etcDatas.reduce<File[]>((acc, curr) => {
      if (curr.file) acc.push(curr.file);
      return acc;
    }, []);
    const deletingEtcDataDataIds = originEtcDatas
      .filter(({ materialEtcDataId }) =>
        etcDatas.every(
          (etcData) => etcData.materialEtcDataId !== materialEtcDataId,
        ),
      )
      .map(({ materialEtcDataId }) => materialEtcDataId);
    if (
      addingEtcDataFiles.length === 0 &&
      deletingEtcDataDataIds.length === 0
    ) {
      return message.warn(messages.NO_NEED_TO_UPDATE);
    }
    saveEtcDatasMutate({ addingEtcDataFiles, deletingEtcDataDataIds });
  }, [etcDatas, originEtcDatas]);

  const { mutate: saveEtcDatasMutate, isLoading: saveLoading } = useMutation(
    async ({
      addingEtcDataFiles,
      deletingEtcDataDataIds,
    }: {
      addingEtcDataFiles: File[];
      deletingEtcDataDataIds: number[];
    }) => {
      if (addingEtcDataFiles.length > 0)
        await etcDataApi.addEtcDatas({
          materialId,
          documentFiles: addingEtcDataFiles,
        });
      if (deletingEtcDataDataIds.length > 0) {
        await etcDataApi.deleteEtcDatas(deletingEtcDataDataIds);
      }
    },
    {
      onSuccess: () => {
        history.goBack();
        message.success(!enabled ? '저장되었습니다,' : '수정되었습니다.');
      },
    },
  );

  useEffect(() => {
    if (originEtcDatas.length > 0) {
      setEtcDatas(originEtcDatas);
    }
  }, [originEtcDatas]);

  return useMemo(
    () => ({
      etcDatas,
      getLoading,
      totalFileSize,
      isFileSizeExceed,
      addEtcData,
      deleteFile,
      saveEtcDatas,
      saveLoading,
    }),
    [
      etcDatas,
      totalFileSize,
      isFileSizeExceed,
      getLoading,
      addEtcData,
      deleteFile,
      saveEtcDatas,
      saveLoading,
    ],
  );
};
