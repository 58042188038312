import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message, Modal } from 'antd';

import EstimatePick from 'components/estimate/EstimatePick';
import * as estimateActions from 'modules/estimate';
import ChecklistModal from 'components/modal/checklist/ChecklistModal';
import { useProducts } from 'service/product';
import { EProductStatus, IProductCountry, IProductItem } from 'types/product';
import { EProductSearchType } from 'types/product';
import { IEstimateTarget } from 'types/brand/estimate';
import {
  useAddEstimateTargets,
  useDeleteEstimateTargets,
  useEstimateTargets,
} from 'service/brand/estimate';

const EstimatePickContainer = () => {
  const dispatch = useDispatch();
  const { companyId, selectedEstimateTargetIds, publishLoading } = useSelector(
    ({ auth, estimate, loading }: any) => ({
      companyId: auth.user.companyId,
      selectedEstimateTargetIds: estimate.selectedEstimateTargetIds,
      deleteLoading: loading['estimate/DELETE_ESTIMATE_TARGETS'],
      publishLoading: loading['estimate/PUBLISH_ESTIMATE'],
    }),
  );
  const {
    products: registeredProducts,
    getLoading: getProductsLoading,
  } = useProducts({
    page: 1,
    searchType: EProductSearchType.ALL,
    searchKeyword: '',
    size: 1000,
    status: EProductStatus.PRD_REG,
  });

  const [selectedProduct, setSelectedProduct] = useState<IProductItem | null>(
    null,
  );
  const {
    estimateTargets,
    isLoading: getEstimateTargetsLoading,
  } = useEstimateTargets(companyId);
  const { addEstimateTargets, addLoading } = useAddEstimateTargets();
  const { deleteEstimateTargets, deleteLoading } = useDeleteEstimateTargets();
  const filteredCountries = useMemo(
    () =>
      selectedProduct !== null
        ? selectedProduct.productCountries!.filter(
            ({ country }) =>
              !estimateTargets.find(
                ({
                  productId,
                  countryId,
                }: {
                  productId: number;
                  countryId: number;
                }) =>
                  productId === selectedProduct.productId &&
                  countryId === country.countryId,
              ),
          )
        : [],
    [selectedProduct, estimateTargets],
  );
  const filteredEstimateTargets = useMemo(
    () => estimateTargets.filter(({ status }) => status !== 'CON'),
    [estimateTargets],
  );
  const [selectedCountries, setSelectedCountries] = useState<IProductCountry[]>(
    [],
  );

  const selectProduct = (product: IProductItem) => {
    setSelectedProduct(product);
    setSelectedCountries([]);
  };
  const selectCountry = (target: IProductCountry) => {
    if (selectedCountries.includes(target)) {
      setSelectedCountries((draft) =>
        draft.filter((country) => country !== target),
      );
    } else {
      setSelectedCountries((draft) => draft.concat(target));
    }
  };
  const selectAllCountries = () => {
    setSelectedCountries(filteredCountries);
  };
  const handleAddEstimateTargets = () => {
    if (!selectedProduct) return message.warn('제품을 선택해 주세요.');
    if (!selectedCountries.length) return message.warn('국가를 선택해 주세요.');
    setSelectedCountries([]);
    addEstimateTargets({
      productId: selectedProduct.productId,
      countryCodes: selectedCountries.map(({ country }) => country.countryCode),
    });
  };
  const handleChangeSelectedEstimateTargetIds = (ids: number[]) => {
    dispatch(estimateActions.setSelectedEstimateTargetIds(ids));
  };
  const publishEstimate = () => {
    if (
      estimateTargets
        .filter(({ estimateTargetId }) =>
          selectedEstimateTargetIds.includes(estimateTargetId),
        )
        .find(({ status }) => status === 'INI')
    ) {
      return message.warn('체크리스트를 작성해 주세요.');
    }
    dispatch(
      estimateActions.publishEstimate({
        estimateTargetIds: selectedEstimateTargetIds,
      }),
    );
  };
  const handleDeleteEstimateTargets = () => {
    if (!selectedEstimateTargetIds.length) return;
    Modal.confirm({
      content: '정말로 삭제하시겠습니까?',
      onOk: () => {
        deleteEstimateTargets(selectedEstimateTargetIds);
      },
    });
  };
  const [estimateTarget, setEstimateTarget] = useState<IEstimateTarget | null>(
    null,
  );
  const showChecklist = (estimateTarget: IEstimateTarget) => {
    setEstimateTarget(estimateTarget);
  };
  const closeChecklistModal = () => {
    setEstimateTarget(null);
  };

  return (
    <>
      {estimateTarget !== null && (
        <ChecklistModal
          estimateTarget={estimateTarget}
          onClose={closeChecklistModal}
        />
      )}
      <EstimatePick
        estimateTargets={filteredEstimateTargets}
        selectedEstimateKeys={selectedEstimateTargetIds}
        products={registeredProducts}
        selectedProduct={selectedProduct}
        countries={filteredCountries}
        selectedCountries={selectedCountries}
        getProductsLoading={getProductsLoading}
        getEstimateTargetsLoading={getEstimateTargetsLoading}
        deleteLoading={deleteLoading}
        publishLoading={publishLoading}
        addLoading={addLoading}
        onSelectProduct={selectProduct}
        onSelectCountry={selectCountry}
        onSelectAllCountries={selectAllCountries}
        onAddCountries={handleAddEstimateTargets}
        onChangeEstimateKeys={handleChangeSelectedEstimateTargetIds}
        onPublishEstimate={publishEstimate}
        onDeleteEstimateTargets={handleDeleteEstimateTargets}
        onClickChecklist={showChecklist}
      />
    </>
  );
};

export default EstimatePickContainer;
