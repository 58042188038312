import PostList from 'components/service/PostList';
import { getUseGuides } from 'modules/service';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as serviceActions from 'modules/service';

const UseGuideContainer = () => {
  const dispatch = useDispatch();
  const useGuides = useSelector(({ service }) => service.useGuides);
  const getLoading = useSelector(
    ({ loading }) => loading['service/GET_USE_GUIDES'],
  );
  const setPost = (post) => {
    dispatch(serviceActions.setPost(post));
  };

  useEffect(() => {
    dispatch(getUseGuides());
  }, []);
  return (
    <PostList
      type="useGuide"
      title="이용 안내"
      list={useGuides}
      loading={getLoading}
      onClick={setPost}
    />
  );
};

export default UseGuideContainer;
