import styled, { css } from 'styled-components';

import palette from 'lib/styles/palette';
import { ITypographyBlockProps, ITypographyProps } from '.';

const HeadlineBlock = styled.h1<ITypographyBlockProps>`
  margin: 0;
  line-height: 46px;
  transform-style: preserve-3d;

  ${({ type }) =>
    type === 'primary' &&
    css`
      font-size: 38px;
      font-weight: 500;
    `}
  ${({ type }) =>
    type === 'secondary' &&
    css`
      font-size: 34px;
      font-weight: 300;
    `}
  ${({ type }) =>
    type === 'tertiary' &&
    css`
      font-size: 30px;
      font-weight: 300;
    `}
  ${({ gutter }) => {
    if (typeof gutter === 'number') {
      return css`
        margin: ${gutter}px;
      `;
    }
    if (typeof gutter === 'object') {
      return css`
        margin-top: ${gutter.top ? `${gutter.top}px` : 0};
        margin-bottom: ${gutter.bottom ? `${gutter.bottom}px` : 0};
        margin-left: ${gutter.left ? `${gutter.left}px` : 0};
        margin-right: ${gutter.right ? `${gutter.right}px` : 0};
      `;
    }
  }}

  ${({ align }) =>
    align &&
    css`
      text-align: ${align};
    `}
  
  ${({ color }) =>
    color &&
    css`
      color: ${palette.text[color]};
    `}

  mark {
    position: relative;
    background-color: transparent;
    display: inline-block;
    padding: 0;
    transform-style: preserve-3d;

    &::after {
      content: '';
      position: absolute;
      bottom: 4px;
      left: 50%;
      display: inline-block;
      width: calc(100% + 24px);
      height: 8px;
      background-color: #b1e2ff;
      transform: translateZ(-1px) translateX(-50%);
    }
  }
`;

const Headline = ({
  children,
  type = 'primary',
  gutter,
  align,
  className,
  color,
  style,
  onClick,
}: ITypographyProps) => {
  return (
    <HeadlineBlock
      type={type}
      gutter={gutter}
      color={color}
      align={align}
      className={className}
      style={style}
      onClick={onClick}
    >
      {children}
    </HeadlineBlock>
  );
};

export default Headline;
