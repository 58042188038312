import { Input, InputProps } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const SearchInputWrap = styled.div`
  position: relative;

  .ant-input {
    padding-right: 40px;
  }
`;
const IconBlock = styled.i`
  position: absolute;
  top: 10px;
  right: 16px;
`;

interface SearchInputProps extends InputProps {
  style?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  disabled?: boolean;
  defaultValue?: string;
  onSearch: (keyword: string) => void;
}

const SearchInput = ({
  style,
  inputStyle,
  disabled,
  defaultValue,
  onSearch,
  ...restProps
}: SearchInputProps) => {
  const [value, setValue] = useState('');
  useEffect(() => {
    if (typeof defaultValue !== 'undefined' && defaultValue !== value) {
      setValue(defaultValue);
    }
  }, [defaultValue]);
  return (
    <SearchInputWrap style={style}>
      <Input
        {...restProps}
        disabled={disabled}
        style={inputStyle}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          if (restProps.onChange) {
            restProps.onChange(e);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onSearch(value);
          }
        }}
      />
      <IconBlock
        className={`material-icons gray cursor ${disabled ? 'disabled' : ''}`}
        onClick={() => {
          if (!disabled) {
            onSearch(value);
          }
        }}
      >
        search
      </IconBlock>
    </SearchInputWrap>
  );
};

export default SearchInput;
