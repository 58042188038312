import { Button, Form, Input, Modal, Radio } from 'antd';

import { emailRule } from 'lib/validate';

const FindPasswordModal = ({
  visible,
  findPasswordLoading,
  onOk,
  onCancel,
}: any) => {
  return (
    <Modal
      title="비밀번호 찾기"
      visible={visible}
      destroyOnClose
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          취소
        </Button>,
        <Button
          type="primary"
          key="ok"
          loading={findPasswordLoading}
          form="findPasswordForm"
          htmlType="submit"
        >
          인증메일 전송
        </Button>,
      ]}
    >
      <Form
        labelCol={{ span: 4 }}
        colon={false}
        id="findPasswordForm"
        onFinish={onOk}
      >
        <Form.Item label="소속" name="userType" initialValue="BRAND" required>
          <Radio.Group>
            <Radio value="BRAND">브랜드사</Radio>
            <Radio value="MANUFACTURE">제조사</Radio>
            <Radio value="MATERIAL">원료사</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="아이디"
          name="username"
          rules={[{ required: true, message: '아이디를 입력해 주세요' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="이메일"
          name="email"
          rules={[
            { required: true, message: '이메일을 입력해 주세요' },
            emailRule,
          ]}
          style={{ marginBottom: 0 }}
        >
          <Input type="email" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FindPasswordModal;
