import { Form, message, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ProductChemicalProperty from 'components/product/ProductChemicalProperty';
import { addProductChemicalProperty } from 'modules/product';

const chemicalProperties = [
  {
    label: 'Lead(Pb)',
    name: 'isLead',
    criteria:
      '점토 포함된 분말 제품 : 50µg / g 이하\n그 밖의 제품 : 20µg / g 이하',
  },
  { label: 'Arsenic(As)', name: 'isArsenic', criteria: '10µg / g 이하' },
  { label: 'Mercury(Hg)', name: 'isMercury', criteria: '1µg / g 이하' },
  { label: 'Antimony(Sb)', name: 'isAntimony', criteria: '10µg / g 이하' },
  { label: 'Cadmium(Cd)', name: 'isCadmium', criteria: '5µg / g 이하' },
  {
    label: 'Nickel(Ni)',
    name: 'isNickel',
    criteria: `눈 화장용 제품 : 35µg / g 이하\n색조 화장용 제품 : 30µg / g 이하\n그 밖의 제품 : 10µg / g 이하`,
  },
];

const ProductChemicalPropertyContainer = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { fetchLoading, productId } = useSelector(({ loading, product }) => ({
    fetchLoading: loading['product/ADD_PRODUCT_CHEMICAL_PROPERTY'],
    productId: product.currentProduct.productId,
  }));
  const [checkAll, setCheckAll] = useState(false);
  const handleChangeCheckAll = () => {
    if (!checkAll) {
      Modal.confirm({
        icon: null,
        content: "모든 항목을 '검출되지 않음'으로 변경하시겠습니까?",
        onOk: () => {
          setCheckAll(true);
          form.setFieldsValue(
            Object.fromEntries(
              chemicalProperties.map(({ name }) => [name, false]),
            ),
          );
        },
      });
    } else {
      setCheckAll(false);
      form.setFieldsValue(
        Object.fromEntries(
          chemicalProperties.map(({ name }) => [name, undefined]),
        ),
      );
    }
  };
  const onSubmit = (formValue) => {
    if (Object.values(formValue).includes(true)) {
      return message.warning(
        '기준 이상의 중금속이 검출된 제품의 경우, 인증을 진행하실 수 없습니다.',
      );
    }
    if (fetchLoading) return;
    dispatch(addProductChemicalProperty({ productId, ...formValue }));
  };

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      form.setFieldsValue({
        isLead: false,
        isArsenic: false,
        isMercury: false,
        isAntimony: false,
        isCadmium: false,
        isNickel: false,
      });
    }
  }, []);

  return (
    <ProductChemicalProperty
      form={form}
      checkAll={checkAll}
      chemicalProperties={chemicalProperties}
      fetchLoading={fetchLoading}
      onClickCheckAll={handleChangeCheckAll}
      onChangeCheckAll={setCheckAll}
      onSubmit={onSubmit}
    />
  );
};

export default ProductChemicalPropertyContainer;
