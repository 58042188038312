import MistakeNoticeItem from 'components/MistakeNoticeItem';
import ProductSingleFileContainer from 'containers/product/ProductSingleFileContainer';
import ProductPageTemplate from 'templates/ProductPageTemplate';

const notices = [
  '원료사에서 제공받은 파일을 집(.zip)파일로 압축해서 업로드해 주세요.',
  <MistakeNoticeItem />,
];

const ProductRawMaterialMSDSPage = () => {
  return (
    <ProductPageTemplate
      subtitle="Raw Material MSDS"
      notices={notices}
      exampleImg="Raw_Material_MSDS.jpg"
    >
      <ProductSingleFileContainer documentCode="rmmsds" accept=".zip" />
    </ProductPageTemplate>
  );
};

export default ProductRawMaterialMSDSPage;
