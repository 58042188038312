import { Suspense, useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Spin } from 'antd';

import * as certificateActions from 'modules/certificate';
import * as productActions from 'modules/product';
import CertificateDetail from 'components/certificate/CertificateDetail';
import CompanyInfoModal from 'components/modal/certificate/CompanyInfoModal';
import ProductNameModal from 'components/modal/certificate/ProductNameModal';
import ChineseProductNameModal from 'components/modal/certificate/ChineseProductNameModal';
import JapaneseProductNameModal from 'components/modal/certificate/JapaneseProductNameModal';
import ShelfLifeDeclarationModal from 'components/modal/certificate/ShelfLifeDeclarationModal';
import SeriesProductInfoModal from 'components/modal/certificate/SeriesProductInfoModal';
import { useSetCurrentProduct } from 'service/brand/product/product';
import { EDocumentCode } from 'types/product';

const CertificateDetailContainer = () => {
  const history = useHistory();
  const { certTargetId } = useParams();
  const dispatch = useDispatch();
  const {
    certificate,
    productDetail,
    documents,
    fetchLoading,
    fixDocumentLoading,
  } = useSelector(
    ({ certificate, product, loading }) => ({
      certificate: certificate.certificate,
      productDetail: product.productDetail,
      documents: certificate.documents,
      fetchLoading: loading['certificate/GET_DOCUMENTS'],
      fixDocumentLoading: loading['certificate/FIX_DOCUMENT'],
    }),
    shallowEqual,
  );

  const [fixingDocumentIndex, setFixingDocumentIndex] = useState(0);
  const isChinese =
    certificate?.estimateTarget.productDetails[0].country.countryCode === 'CN';
  const isJapan =
    certificate?.estimateTarget.productDetails[0].country.countryCode === 'JP';
  const { productId, countryId } = { ...certificate?.estimateTarget };
  useSetCurrentProduct({ productId, countryId });
  const showDocument = useCallback(
    (document) => {
      const { documentCode, status } = document;
      if (
        documentCode === EDocumentCode.PN ||
        documentCode === EDocumentCode.SPI ||
        documentCode === EDocumentCode.CI ||
        documentCode === EDocumentCode.SLD
      ) {
        dispatch(certificateActions.setReadOnlyMode(status === 'ONG'));
      }
      switch (documentCode) {
        case EDocumentCode.PN:
          if (isChinese) {
            dispatch(certificateActions.toggleChineseProductNameModalVisible());
          } else if (isJapan) {
            dispatch(
              certificateActions.toggleJapaneseProductNameModalVisible(),
            );
          } else {
            dispatch(certificateActions.toggleProductNameModalVisible());
          }
          break;
        case EDocumentCode.SPI:
          dispatch(certificateActions.toggleSeriesProductInfoModalVisible());
          break;
        case EDocumentCode.CI:
          dispatch(certificateActions.toggleCompanyInfoModalVisible());
          break;
        case EDocumentCode.SLD:
          dispatch(certificateActions.toggleShelfLifeMonthModalVisible());
          break;
        default: {
          history.push(
            `/product/${productId}/${documentCode}?countryId=${countryId}`,
          );
        }
      }
    },
    [productId, countryId],
  );

  const fixDocument = (productDocStatusId) => {
    dispatch(certificateActions.fixDocument(productDocStatusId));
  };

  useEffect(() => {
    dispatch(certificateActions.getCertificate(certTargetId));
    dispatch(productActions.setSkipMode(false));
    dispatch(productActions.setCurrentDocStatus(null));
    dispatch(certificateActions.getDocuments(certTargetId));

    return () => {
      dispatch(certificateActions.setCertificate(null));
    };
  }, [certTargetId]);

  if (!certificate) return null;
  return (
    <>
      <ProductNameModal />
      <ChineseProductNameModal />
      <JapaneseProductNameModal />
      <SeriesProductInfoModal certTargetId={certTargetId} />
      <CompanyInfoModal />
      <ShelfLifeDeclarationModal />
      <CertificateDetail
        certificate={certificate}
        productDetail={productDetail}
        documents={documents}
        fetchLoading={fetchLoading}
        fixDocumentLoading={fixDocumentLoading}
        fixingDocumentIndex={fixingDocumentIndex}
        onClickDocument={showDocument}
        onFixDocument={fixDocument}
        setFixingDocumentIndex={setFixingDocumentIndex}
      />
    </>
  );
};

const SuspendedCertificateDetailContainer = () => {
  return (
    <Suspense fallback={<Spin />}>
      <CertificateDetailContainer />
    </Suspense>
  );
};

export default SuspendedCertificateDetailContainer;
