export enum ERegisterStatus {
  RM_RDY = 'RM_RDY',
  RM_REG = 'RM_REG',
}

export enum ERegisterSearchType {
  ALL = 'all',
  MATERIAL_NAME_EN = 'materialNameEn',
  ORIGIN_MANUFACTURE_NAME = 'originManufacturerName',
}

export interface IMaterialRegisterGet {
  status: ERegisterStatus;
  pageNo: number;
  pageItemCount?: number;
  materialNameEn?: string;
  originManufacturerName?: string;
}

export interface IMaterialRegister {
  materialId: number;
  materialNameEn: string;
  originManufacturerName: string;
  uploadExpiredDate?: number;
  finishRegisterDt: string;
  isShowManufacturerName: boolean;
  finishRegisterName: string;
  finishRegisterDeptName: string;
  isDiscontinued: boolean;
  updateDt?: string;
}
