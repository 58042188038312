interface IDocumentNameMap {
  [key: string]: string;
}

export const documentNameMap: IDocumentNameMap = {
  A01: 'Authorization',
  ALLE01: 'Allergens List',
  ART01: 'Artwork',
  BASIC01: '기본 정보',
  BI01: '바이어/에이전시 정보',
  BSEFL01: 'BSE Free Letter',
  BTFD01: 'BSE/TSE Free Declaration',
  CAT01: '카테고리 분류',
  CD01: 'CMR Declaration',
  CFF01: 'Clarification for Fragrance',
  CFS01: 'CFS',
  CGMP01: 'cGMP',
  CHAL01: 'Challenge Test',
  CHE01: 'Chemical Properties',
  CI01: '회사 정보',
  CLOD01: '기타 자료 (클라우드)',
  COA01: 'Certificate of Analysis',
  COBRM01: 'Certificate of Business Registration_Manufacturer',
  COUNTRY01: '국가 선택',
  FOAC01: 'Free of alcohol and coenzyme Q10 statement',
  FORMBR01: 'Product Formula Breakdown',
  FORMBRC01: 'Product Formula Breakdown (China)',
  HMDC01: 'Heavy Metals Declaration Certificate',
  IFRA01: 'IFRA Conformity Certificate',
  IRC01: '경내책임회사 정보',
  LOA01: 'Letter of Authorization',
  LOGOAI01: 'Brand Logo_AI file',
  LTU01: 'License to Use',
  MANU01: 'Manufacturing Process',
  MANUC01: 'Manufacturing Process (China)',
  MBIO01: 'Microbiological Test',
  MCAS01: '인증 및 특이사항',
  MCI01: 'Composition Information',
  MD01: "Manufacturer's Declaration",
  MDI01: '기본 정보',
  MIC01: 'Microbiological Properties',
  MMD01: '마케팅 자료',
  MSDS01: 'Material Safety Data Sheet',
  ND01: 'NANO Declaration',
  NONANIM01: 'Non-Animal Testing Declaration',
  NONHEXA01: 'Non-Hexachlorophene Declaration',
  NONMIC01: 'Non-Micro Beads Declaration',
  PACK01: 'Packing Attestation',
  PC01: '공정도',
  PCC01: '중국 상표등록증',
  PHY01: 'Physical Properties',
  PN01: '제품명',
  PS01: 'Product Specifications',
  RDI01: '처방 상세 정보',
  RECIPE01: '처방',
  RL01: 'Relationship Letter',
  RMCOA01: 'Raw Material CoA',
  RMMSDS01: 'Raw Material MSDS',
  ROPQM01: 'Resume of product quality manager',
  SAFE01: 'Safety Assessment',
  SC01: 'Safety Certificate',
  SLD01: 'Shelf Life Declaration',
  SPEC01: 'Specification',
  STAB01: 'Stability Test',
  THIRD01: 'Third Party Test Report',
  TPCOA01: 'Testing Protocol_CoA Test Method',
  TPHM01: 'Testing Protocol_Heavy Metal Test Method',
  UNDER01: 'Undertaking for Heavy Metal',
  UPSI01: '미국내 제품 판매 정보',
  FCCAD01: 'FDA 색소 인증 서류',
  VCRPAR01: '보유 VCRP 계정 정보',
  VCRPAC01: '신규 VCRP 계정 생성',
};
