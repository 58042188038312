import palette from 'lib/styles/palette';
import styled from 'styled-components';

const StatusIconBlock = styled.span<{
  status: 'success' | 'error';
}>`
  display: inline-flex;
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
  border-radius: 50%;
  background-color: ${({ status }) => palette[status]};

  i {
    font-size: 8px;
    color: #fff;
  }
`;

const StatusIcon = ({
  status,
  style,
}: {
  status: 'success' | 'error';
  style?: React.CSSProperties;
}) => {
  const renderIcon = () => {
    switch (status) {
      case 'success':
        return <i className="material-icons">done</i>;
      case 'error':
        return <i className="material-icons">clear</i>;
      default:
        return null;
    }
  };
  return (
    <StatusIconBlock status={status} style={style}>
      {renderIcon()}
    </StatusIconBlock>
  );
};

export default StatusIcon;
