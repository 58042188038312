import ProductCategory from 'components/product/ProductCategory';
import ProductPageTemplate from 'templates/ProductPageTemplate';

const notices = [
  '국내 화장품법에 따른 카테고리 분류입니다.',
  '한 번 선택하신 카테고리는 수정이 불가하니 신중히 선택해 주세요. ( 단, 인증 진행 시 국가에 따라 해당 제품 카테고리가 관리자와 협의 후 변경될 수 있습니다.)',
];

const ProductCategoryPage = () => {
  return (
    <ProductPageTemplate subtitle="카테고리" hasSkip={false} notices={notices}>
      <ProductCategory />
    </ProductPageTemplate>
  );
};

export default ProductCategoryPage;
