import { Upload } from 'antd';
import { s3AssetDomain } from 'lib/consts';
import palette from 'lib/styles/palette';
import { useState } from 'react';
import styled from 'styled-components';

const StyledUploadDragger = styled(Upload.Dragger)<{
  height?: number;
  width?: number;
}>`
  width: ${(props) => (props.width ? props.width : 350)}px !important;
  height: ${(props) => (props.height ? props.height : 175)}px !important;

  border: none !important;
  background: ${palette.paleGray10} !important;

  padding: 0px !important;

  .ant-upload {
    padding: 0px !important;
  }
`;

const PictureNoticeWrapper = styled.div<{
  height?: number;
  width?: number;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.width ? props.width : 350)}px !important;
  height: ${(props) => (props.height ? props.height : 175)}px !important;
`;

const AlbumIcon = styled.img`
  width: 48px;
  height: 48px;

  margin-bottom: 8px;
`;

const StyledText = styled.div`
  height: 15px;
  font-family: NotoSansCJKkr;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.45px;
  color: #a9a6a6;
`;

interface IProps {
  width?: number;
  height?: number;
  disabled?: boolean;
  style?: React.CSSProperties;
  initialImageUrl?: string;
  onDragger?: (image: any) => void;
}

const ImageUploadDragger: React.FC<IProps> = ({
  width,
  height,
  disabled,
  style,
  initialImageUrl,
  onDragger,
}) => {
  const [image, setImage] = useState(initialImageUrl || '');

  return (
    <StyledUploadDragger
      style={style}
      disabled={disabled}
      width={width}
      height={height}
      name="uploadFile"
      accept="image/*"
      multiple={false}
      onChange={(info) => {
        const objectUrl = URL.createObjectURL(info.file.originFileObj);
        if (onDragger) onDragger(info.file.originFileObj);
        setImage(objectUrl);
      }}
      beforeUpload={() => {
        return false;
      }}
    >
      <PictureNoticeWrapper width={width} height={height}>
        {!image ? (
          <>
            <AlbumIcon src={`${s3AssetDomain}/album_icon.png`} alt="album" />
            <StyledText>
              클릭 혹은 이미지 끌어서 넣기 <br />
              크기{' '}
              {`${
                width ? '880 x 320 / 용량 500KB' : '400 x 200 / 용량 300KB'
              }`}{' '}
              이하 권장
            </StyledText>
          </>
        ) : (
          <img
            src={image}
            alt="marketingImg"
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        )}
      </PictureNoticeWrapper>
    </StyledUploadDragger>
  );
};

export default ImageUploadDragger;
