import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Button, Form } from 'antd';

import { scrollToFirstErrorOption } from 'lib/consts';
import path from 'lib/path';
import FooterBox from 'components/FooterBox';
import SuperUserForm from 'components/auth/SuperUserForm';
import BrandForm from './BrandForm';

const BrandRegisterFormBlock = styled.div<{ updateMode: boolean }>`
  max-width: 520px;
  margin: 0 auto;
  ${(props) =>
    !props.updateMode &&
    css`
      padding: 40px 0;
    `}
`;
const BrandRegisterForm = ({
  tab,
  onChangeTab,
  updateMode,
  form,
  submitLoading,
  onSubmit,
  onClickChangePassword,
  brands,
  bizLicenseEnUrl,
}: any) => {
  const handleClickNext = () => {
    form
      .validateFields()
      .then(() => {
        form.setFieldsValue({
          username: form.getFieldValue('bizNumber').replaceAll('-', ''),
        });
        onChangeTab('user');
      })
      .catch((error: any) => {
        form.scrollToField(
          error.errorFields[0].name[0],
          scrollToFirstErrorOption,
        );
      });
  };
  const handleClickBack = () => {
    onChangeTab('company');
  };
  return (
    <BrandRegisterFormBlock updateMode={updateMode}>
      <Form
        form={form}
        layout="vertical"
        colon={false}
        scrollToFirstError={scrollToFirstErrorOption}
        onFinish={onSubmit}
      >
        <BrandForm
          brands={brands}
          visible={updateMode || tab === 'company'}
          updateMode={updateMode}
          bizLicenseEnUrl={bizLicenseEnUrl}
        />
        {(updateMode || tab === 'user') && (
          <SuperUserForm
            form={form}
            updateMode={updateMode}
            onClickChangePassword={onClickChangePassword}
            onClickBack={handleClickBack}
          />
        )}
        <FooterBox>
          {!updateMode && tab === 'company' && (
            <Button>
              <Link to={path.login}>취소</Link>
            </Button>
          )}
          {!updateMode && tab === 'user' && (
            <Button onClick={handleClickBack}>뒤로</Button>
          )}
          {!updateMode && tab === 'company' && (
            <Button type="primary" onClick={handleClickNext}>
              다음
            </Button>
          )}
          {(updateMode || tab === 'user') && (
            <Button type="primary" loading={submitLoading} htmlType="submit">
              {!updateMode ? '회원가입' : '회원정보 수정'}
            </Button>
          )}
        </FooterBox>
      </Form>
    </BrandRegisterFormBlock>
  );
};

export default BrandRegisterForm;
