import { Switch, Route } from 'react-router-dom';

import path from 'lib/path';
import ProductListPage from 'pages/brand/retailer/retailerApply/product/ProductListPage';
import ProductBasicPage from 'pages/brand/retailer/retailerApply/product/ProductBasicPage';
import ProductCategoryPage from 'pages/product/ProductCategoryPage';
import ProductDetailPage from 'pages/brand/retailer/retailerApply/product/ProductDetailPage';
import BLPage from 'pages/brand/retailer/retailerApply/BLPage';
import COBPage from 'pages/brand/retailer/retailerApply/COBPage';
import CRCSBPage from 'pages/brand/retailer/retailerApply/CRCSBPage';
import OBDPage from 'pages/brand/retailer/retailerApply/OBDPage';
import ProductCMBCPage from 'pages/brand/retailer/retailerApply/product/ProductCMBCPage';
import ProductPSPage from 'pages/brand/retailer/retailerApply/product/ProductPSPage';
import ProductINGSPage from 'pages/brand/retailer/retailerApply/product/ProductINGSPage';
import ProductBCOAPage from 'pages/brand/retailer/retailerApply/product/ProductBCOAPage';
import ProductCOAPage from 'pages/brand/retailer/retailerApply/product/ProductCOAPage';
import ProductKARTPage from 'pages/brand/retailer/retailerApply/product/ProductKARTPage';
import ProductAIMGPage from 'pages/brand/retailer/retailerApply/product/ProductAIMGPage';
import ProductFRPage from 'pages/brand/retailer/retailerApply/product/ProductFRPage';
import ProductCFIRCPage from 'pages/brand/retailer/retailerApply/product/ProductCFIRCPage';
import ProductRHSPage from 'pages/brand/retailer/retailerApply/product/ProductRHSPage';
import ProductRNISPage from 'pages/brand/retailer/retailerApply/product/ProductRNISPage';
import ProductHATRPage from 'pages/brand/retailer/retailerApply/product/ProductHATRPage';
import ProductPFAPage from 'pages/brand/retailer/retailerApply/product/ProductPFAPage';
import ProductPSRPage from 'pages/brand/retailer/retailerApply/product/ProductPSRPage';
import ProductPLIPage from 'pages/brand/retailer/retailerApply/product/ProductPLIPage';
import ProductCRCPage from 'pages/brand/retailer/retailerApply/product/ProductCRCPage';
import ProductCPRSPage from 'pages/brand/retailer/retailerApply/product/ProductCPRSPage';
import ProductLPBPage from 'pages/brand/retailer/retailerApply/product/ProductLPBPage';
import ProductCSIGPage from 'pages/brand/retailer/retailerApply/product/ProductCSIGPage';

import Error404Page from 'pages/error/Error404Page';

const RetailerApplyRouter = () => {
  return (
    <Switch>
      <Route
        path={path.retailer.retailerApply.product.list}
        component={ProductListPage}
      />
      <Route
        path={path.retailer.retailerApply.product.basic}
        component={ProductBasicPage}
      />
      <Route
        path={`${path.retailer.retailerApply.product.cat}/:productId`}
        component={ProductCategoryPage}
      />
      <Route
        exact
        path={`${path.retailer.retailerApply.product.root}/:productId`}
        component={ProductDetailPage}
      />
      <Route path={path.retailer.retailerApply.BL} component={BLPage} />
      <Route path={path.retailer.retailerApply.COB} component={COBPage} />
      <Route path={path.retailer.retailerApply.CRCSB} component={CRCSBPage} />
      <Route path={path.retailer.retailerApply.OBD} component={OBDPage} />
      <Route
        path={`${path.retailer.retailerApply.product.CMBC}/:productId`}
        component={ProductCMBCPage}
      />
      <Route
        path={`${path.retailer.retailerApply.product.PS}/:productId`}
        component={ProductPSPage}
      />
      <Route
        path={`${path.retailer.retailerApply.product.INGS}/:productId`}
        component={ProductINGSPage}
      />
      <Route
        path={`${path.retailer.retailerApply.product.BCOA}/:productId`}
        component={ProductBCOAPage}
      />
      <Route
        path={`${path.retailer.retailerApply.product.COA}/:productId`}
        component={ProductCOAPage}
      />
      <Route
        path={`${path.retailer.retailerApply.product.KART}/:productId`}
        component={ProductKARTPage}
      />
      <Route
        path={`${path.retailer.retailerApply.product.AIMG}/:productId`}
        component={ProductAIMGPage}
      />
      <Route
        path={`${path.retailer.retailerApply.product.FR}/:productId`}
        component={ProductFRPage}
      />
      <Route
        path={`${path.retailer.retailerApply.product.CFIRC}/:productId`}
        component={ProductCFIRCPage}
      />
      <Route
        path={`${path.retailer.retailerApply.product.RHS}/:productId`}
        component={ProductRHSPage}
      />
      <Route
        path={`${path.retailer.retailerApply.product.RNIS}/:productId`}
        component={ProductRNISPage}
      />
      <Route
        path={`${path.retailer.retailerApply.product.HATR}/:productId`}
        component={ProductHATRPage}
      />
      <Route
        path={`${path.retailer.retailerApply.product.PFA}/:productId`}
        component={ProductPFAPage}
      />
      <Route
        path={`${path.retailer.retailerApply.product.PSR}/:productId`}
        component={ProductPSRPage}
      />
      <Route
        path={`${path.retailer.retailerApply.product.PLI}/:productId`}
        component={ProductPLIPage}
      />
      <Route
        path={`${path.retailer.retailerApply.product.CRC}/:productId`}
        component={ProductCRCPage}
      />
      <Route
        path={`${path.retailer.retailerApply.product.CPRS}/:productId`}
        component={ProductCPRSPage}
      />
      <Route
        path={`${path.retailer.retailerApply.product.LPB}/:productId`}
        component={ProductLPBPage}
      />
      <Route
        path={`${path.retailer.retailerApply.product.CSIG}/:productId`}
        component={ProductCSIGPage}
      />
      <Route path="*" component={Error404Page} />
    </Switch>
  );
};

export default RetailerApplyRouter;
