import { Button, Col, Row } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import { downloadFile } from 'lib/file';
import FooterBox from 'components/FooterBox';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import FileUploadContainer from 'containers/file/FileUploadContainer';

const FileUploadTemplate = ({
  updateMode,
  readOnly = false,
  files,
  fetchLoading,
  accept,
  viewerVisible = true,
  footerVisible = true,
  formUrl = null,
  status,
  max,
  onUpload,
  onDelete,
  onSubmit,
  children,
}: {
  updateMode: boolean;
  readOnly?: boolean;
  files: any;
  fetchLoading: boolean;
  accept?: string;
  viewerVisible?: boolean;
  footerVisible?: boolean;
  formUrl?: string | null;
  status?: string;
  max?: number;
  onUpload: (file: any) => any;
  onDelete: (file: any) => any;
  onSubmit: () => void;
  children?: React.ReactNode;
}) => {
  return (
    <>
      {children}
      {formUrl && (
        <Row justify="center" style={{ marginBottom: 32 }}>
          <Col>
            <Button
              type="dashed"
              icon={<DownloadOutlined />}
              onClick={() => downloadFile(formUrl)}
            >
              서식 다운로드
            </Button>
          </Col>
        </Row>
      )}
      <FileUploadContainer
        readOnly={readOnly}
        files={files}
        viewerVisible={viewerVisible}
        accept={accept}
        max={max}
        onUpload={onUpload}
        onDelete={onDelete}
      />
      {footerVisible && (
        <FooterBox>
          <ReadOnlyBackButton readOnly={readOnly}>
            <Button
              type="primary"
              htmlType="submit"
              loading={fetchLoading}
              onClick={onSubmit}
            >
              {!updateMode ? '등록' : status !== 'MOD' ? '수정' : '보완 완료'}
            </Button>
          </ReadOnlyBackButton>
        </FooterBox>
      )}
    </>
  );
};
export default FileUploadTemplate;
