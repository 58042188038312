import styled from 'styled-components';

import ClinicalTrialEstimateRequest from 'components/brand/clinicalTrial/estimate/ClinicalTrialEstimateRequest';

const Container = styled.div``;

const ClinicalTrialEstimateRequestPage = () => {
  return (
    <Container>
      <ClinicalTrialEstimateRequest />
    </Container>
  );
};

export default ClinicalTrialEstimateRequestPage;
