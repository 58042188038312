import { Checkbox, Col, Row } from 'antd';
import styled from 'styled-components';
import { useState } from 'react';

import { Typography } from 'components/system';
import { ReactComponent as Minus } from 'asset/svg/minus.svg';
import { ReactComponent as Plus } from 'asset/svg/plus.svg';
import { IClinicalTrialEstimateItem } from 'types/brand/clinicalTrial/estimate';

const Container = styled.div`
  width: 352px;
  background-color: #fff;
  padding: 10px 16px;
  border-radius: 8px;

  svg {
    cursor: pointer;
  }
`;

const NumberContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const RequestItem = ({
  savedClinicalTrial: {
    categoryDataId,
    clinicalTrialId,
    itemQuantity,
    clinicalTrialName,
    clinicalTrialEstimateBasketId,
    isCheck,
  },
  isRecommendation = false,
  onChangeCheck,
  updateItemQuantity,
}: {
  savedClinicalTrial: IClinicalTrialEstimateItem;
  isRecommendation?: boolean;
  onChangeCheck: (onChangeCheckProps: {
    isCheck: boolean;
    categoryDataId: number;
    clinicalTrialId: number;
    clinicalTrialEstimateBasketId: number | string;
  }) => void;
  updateItemQuantity: (
    plus: boolean,
    count: number,
    categoryDataId: number,
    clinicalTrialId: number,
    clinicalTrialEstimateBasketId: string | number,
  ) => void;
}) => {
  const [count, setCount] = useState(itemQuantity);
  const toggleOperator = (plus: boolean) => {
    setCount((draft) => draft + (plus ? 1 : -1));
    updateItemQuantity(
      plus,
      count,
      categoryDataId,
      clinicalTrialId,
      clinicalTrialEstimateBasketId,
    );
  };

  return (
    <Container>
      <Row justify="space-between" align="middle">
        <Col>
          <Row>
            <Checkbox
              defaultChecked={isCheck}
              onChange={(e) =>
                onChangeCheck({
                  isCheck: e.target.checked,
                  categoryDataId,
                  clinicalTrialId,
                  clinicalTrialEstimateBasketId,
                })
              }
            />
            <Row
              gutter={8}
              align="middle"
              style={{ width: 228, marginLeft: 8 }}
            >
              <Col>
                <Typography.Text style={{ wordBreak: 'break-word' }}>
                  {clinicalTrialName}
                </Typography.Text>
              </Col>
              {isRecommendation && (
                <Col style={{ height: '100%' }}>
                  <Typography.Label
                    type="check"
                    style={{
                      minWidth: 35,
                      height: 16,
                      fontSize: 10,
                      padding: '0px 8px',
                    }}
                  >
                    추천
                  </Typography.Label>
                </Col>
              )}
            </Row>
          </Row>
        </Col>
        <Col style={{ alignSelf: 'flex-start' }}>
          <NumberContainer>
            <Minus
              onClick={count !== 1 ? () => toggleOperator(false) : undefined}
            />
            <Typography.Text color="primary">{count}</Typography.Text>
            <Plus onClick={() => toggleOperator(true)} />
          </NumberContainer>
        </Col>
      </Row>
    </Container>
  );
};

export default RequestItem;
