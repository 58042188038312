import { Form, Select, Button, message } from 'antd';
import styled from 'styled-components';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { Typography } from 'components/system';
import palette from 'lib/styles/palette';
import RequestItem from './RequestItem';
import SlateButton from 'components/ui/Button/SlateButton';
import RequesterForm from './RequesterForm';
import { useClinicalTrials } from 'service/brand/clinicalTrial/clinicalTrial';
import {
  useEstimateRequest,
  useSaveClinicalTrial,
  useUnSaveClinicalTrial,
} from 'service/brand/clinicalTrial/estimate';
import ClinicalTrialModal from '../ClinicalTrialModal';
import {
  IClinicalTrial,
  IClinicalTrialRecommendation,
} from 'types/brand/clinicalTrial/clinicalTrial';
import useOpenLoginModal from 'hook/useOpenLoginModal';

const RequestTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
`;

const Container = styled.div`
  width: 400px !important;
  margin: 0 auto;
  counter-reset: no;

  ${RequestTitle} {
    &::before {
      display: inline-block;
      counter-increment: no;
      content: counter(no);
      background-color: ${palette.primary};
      color: #fff;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      text-align: center;
      font-size: 12px;
    }
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: ${palette.slategray10};
    border: none;
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;

    span:first-child {
      color: ${palette.darkNavy};
      font-weight: 500;
    }
  }

  .ant-select-item-option {
    padding: 10px 16px;
  }

  .ant-select-arrow {
    font-size: 16px;
    top: 50%;
    right: 20px;
    color: ${palette.slategray50};
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    color: ${palette.slategray50};
  }
`;

const RequestContainer = styled.div`
  background: #f5f8fb;
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 8px;
`;

const RequestItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-bottom: 16px;
`;

const InformationBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f6f7f9;
  border-radius: 8px;
  padding: 12px 0px;
  margin-bottom: 24px;
  counter-reset: no;

  div {
    &::before {
      display: block;
      counter-increment: no;
      content: counter(no);
      background-color: ${palette.primary};
      color: #fff;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      text-align: center;
      font-size: 12px;
    }
  }
`;

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ClinicalTrialEstimateRequest = () => {
  const [isOngoingRequest, setIsOngoingRequest] = useState(true);
  const user = useSelector(({ auth }: any) => auth.user);
  const isLoggedIn = user !== null;
  const {
    items,
    savedClinicalTrialMap,
    clinicalTrialRecommendations,
    categoryRecommendMap,
    clinicalTrialEstimateItems,
    setClinicalTrialEstimateItems,
    onChangeCheck,
    updateItemQuantity,
  } = useEstimateRequest(isLoggedIn);
  const [form] = Form.useForm();
  const { clinicalTrials } = useClinicalTrials();
  const [
    selectedRecommendation,
    setSelectedRecommendation,
  ] = useState<IClinicalTrialRecommendation>();
  const handleSelectCategory = (
    clinicalTrialRecommendation: IClinicalTrialRecommendation,
  ) => {
    setSelectedRecommendation(clinicalTrialRecommendation);
    form.setFieldsValue({
      clinicalTrial: null,
    });
  };
  const recommendClinicalTrialIds = useMemo(() => {
    if (selectedRecommendation && categoryRecommendMap) {
      return (
        categoryRecommendMap.get(selectedRecommendation.categoryDataId) || []
      );
    }
    return [];
  }, [categoryRecommendMap, selectedRecommendation]);
  const { saveClinicalTrial } = useSaveClinicalTrial();
  const handleSelectClinicalTrial = (clinicalTrial: IClinicalTrial) => {
    if (!selectedRecommendation) {
      return message.warning('제품 카테고리를 먼저 선택해 주세요.');
    }

    if (
      items.some(
        ({ categoryDataName, clinicalTrialName }) =>
          categoryDataName === selectedRecommendation.categoryDataName &&
          clinicalTrial.clinicalTrialName === clinicalTrialName,
      )
    ) {
      message.warn('이미 선택한 항목입니다.');
      return;
    }
    if (isLoggedIn) {
      saveClinicalTrial(
        [
          {
            categoryDataId: selectedRecommendation.categoryDataId,
            clinicalTrialId: clinicalTrial.clinicalTrialId,
            isCheck: true,
            itemQuantity: 1,
          },
        ],
        {
          onSuccess: () => {
            message.success('임상 견적 리스트에 시험 항목이 추가되었습니다.');
          },
        },
      );
    } else {
      const newItems = [
        ...clinicalTrialEstimateItems,
        {
          clinicalTrialEstimateBasketId: `${selectedRecommendation.categoryDataName}&${clinicalTrial.clinicalTrialName}`,
          categoryDataId: selectedRecommendation.categoryDataId,
          categoryDataName: selectedRecommendation.categoryDataName,
          clinicalTrialId: clinicalTrial.clinicalTrialId,
          clinicalTrialName: clinicalTrial.clinicalTrialName,
          isCheck: true,
          itemQuantity: 1,
        },
      ];
      setClinicalTrialEstimateItems(newItems);
    }
  };
  const [isOpenClinicalTrialModal, setIsOpenClinicalTrialModal] = useState(
    false,
  );
  const { unSaveClinicalTrial } = useUnSaveClinicalTrial();
  const handleClickUnSave = () => {
    if (items.every(({ isCheck }) => !isCheck)) {
      return message.warn('선택된 견적 요청 항목이 없습니다.');
    }

    setIsOpenClinicalTrialModal(true);
  };
  const deleteClinicalTrial = () => {
    if (isLoggedIn) {
      unSaveClinicalTrial(
        items
          .filter(({ isCheck }) => isCheck)
          .map(
            ({ clinicalTrialEstimateBasketId }) =>
              clinicalTrialEstimateBasketId,
          ) as number[],
        {
          onSuccess: () => {
            setIsOpenClinicalTrialModal(false);
          },
        },
      );
    } else {
      setClinicalTrialEstimateItems(items.filter(({ isCheck }) => !isCheck));
      setIsOpenClinicalTrialModal(false);
    }
  };
  const openLoginModal = useOpenLoginModal();
  const handleRequestEstimate = () => {
    if (items.every(({ isCheck }) => !isCheck)) {
      return message.warn('선택된 견적 요청 항목이 없습니다.');
    }

    setIsOngoingRequest(false);
  };

  useEffect(() => {
    if (!isOngoingRequest) {
      window.scrollTo(0, 0);
    }
  }, [isOngoingRequest]);

  return (
    <Container>
      {isOpenClinicalTrialModal && (
        <ClinicalTrialModal
          content="선택 항목을 삭제하시겠습니까?"
          okText="삭제"
          closeText="취소"
          onOk={deleteClinicalTrial}
          onClose={() => setIsOpenClinicalTrialModal(false)}
          style={{ padding: '125px 40px 30px' }}
        />
      )}
      {isOngoingRequest ? (
        <Form form={form}>
          <Typography.Headline type="secondary" gutter={{ bottom: 40 }}>
            견적 요청하기
          </Typography.Headline>
          <RequestTitle>
            <Typography.Title inline>
              제품 카테고리가 무엇인가요?
            </Typography.Title>
          </RequestTitle>
          <Form.Item name="category" noStyle>
            <Select
              onSelect={(v, o) =>
                handleSelectCategory(
                  JSON.parse(
                    o.children.props.className,
                  ) as IClinicalTrialRecommendation,
                )
              }
              showSearch
              style={{ width: 400, marginBottom: 32 }}
              placeholder="카테고리 선택"
              filterOption={(input, option) =>
                (option?.children.props.children as string).includes(input)
              }
            >
              {clinicalTrialRecommendations.map(
                (clinicalTrialRecommendation) => (
                  <Select.Option
                    key={clinicalTrialRecommendation.categoryDataId}
                    value={clinicalTrialRecommendation.categoryDataId}
                  >
                    <Typography.Text
                      color="slategray50"
                      type="secondary"
                      className={JSON.stringify(clinicalTrialRecommendation)}
                    >
                      {clinicalTrialRecommendation.categoryDataName}
                    </Typography.Text>
                  </Select.Option>
                ),
              )}
            </Select>
          </Form.Item>
          <Form.Item
            shouldUpdate={(prev, curr) => prev.category !== curr.category}
            noStyle
          >
            {({ getFieldValue }) =>
              (getFieldValue('category') || items.length > 0) && (
                <>
                  <RequestTitle>
                    <Typography.Title inline>
                      어떤 임상시험을 진행하시나요?
                    </Typography.Title>
                  </RequestTitle>
                  <Form.Item
                    name="clinicalTrial"
                    noStyle
                    shouldUpdate={(prev, curr) =>
                      prev.category !== curr.category
                    }
                    normalize={(value) => {
                      if (!getFieldValue('category')) {
                        return null;
                      }

                      return value;
                    }}
                  >
                    <Select
                      showSearch
                      style={{ width: 400, marginBottom: 32 }}
                      placeholder="임상시험 선택"
                      filterOption={(input, option) => {
                        return (option?.children.props.children[0].props
                          .children as string).includes(input);
                      }}
                      onSelect={(v, o) => {
                        handleSelectClinicalTrial(
                          JSON.parse(
                            o.children.props.className,
                          ) as IClinicalTrial,
                        );
                      }}
                    >
                      {clinicalTrials
                        .sort((a) =>
                          recommendClinicalTrialIds.includes(a.clinicalTrialId)
                            ? -1
                            : 1,
                        )
                        .map((clinicalTrial) => (
                          <Select.Option
                            key={clinicalTrial.clinicalTrialId}
                            value={clinicalTrial.clinicalTrialId}
                          >
                            <OptionContainer
                              className={JSON.stringify(clinicalTrial)}
                            >
                              <Typography.Text
                                color="slategray50"
                                type="secondary"
                              >
                                {clinicalTrial.clinicalTrialName}
                              </Typography.Text>
                              {recommendClinicalTrialIds.some(
                                (id) => id === clinicalTrial.clinicalTrialId,
                              ) && (
                                <Typography.Label
                                  type="check"
                                  style={{
                                    minWidth: 35,
                                    height: 16,
                                    fontSize: 10,
                                    padding: '0px 8px',
                                  }}
                                >
                                  추천
                                </Typography.Label>
                              )}
                            </OptionContainer>
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </>
              )
            }
          </Form.Item>
          <Form.Item
            shouldUpdate={(prev, curr) =>
              prev.clinicalTrial !== curr.clinicalTrial
            }
            noStyle
          >
            {() =>
              items.length > 0 && (
                <>
                  <RequestTitle>
                    <Typography.Title inline>
                      견적 요청할 항목을 선택해 주세요.
                    </Typography.Title>
                  </RequestTitle>
                  <RequestContainer>
                    {Array.from(savedClinicalTrialMap.entries()).map(
                      ([categoryName, savedClinicalTrialEstimateItems]) => (
                        <RequestItemContainer key={categoryName}>
                          <Typography.Text bold>{categoryName}</Typography.Text>
                          {savedClinicalTrialEstimateItems.map(
                            (savedClinicalTrial) => (
                              <RequestItem
                                key={`${savedClinicalTrial.categoryDataName}&${savedClinicalTrial.clinicalTrialName}
                                }`}
                                savedClinicalTrial={savedClinicalTrial}
                                isRecommendation={categoryRecommendMap
                                  .get(savedClinicalTrial.categoryDataId)
                                  ?.includes(
                                    savedClinicalTrial.clinicalTrialId,
                                  )}
                                onChangeCheck={onChangeCheck}
                                updateItemQuantity={updateItemQuantity}
                              />
                            ),
                          )}
                        </RequestItemContainer>
                      ),
                    )}
                    <Typography.Text
                      color="slate"
                      type="secondary"
                      medium
                      onClick={handleClickUnSave}
                    >
                      선택삭제
                    </Typography.Text>
                  </RequestContainer>
                  <InformationBox>
                    <div />,
                    <div style={{ marginLeft: 4 }} />
                    <Typography.Text gutter={{ left: 4 }} type="tertiary">
                      를 반복하여 시험 항목을 계속해서 추가할 수 있어요 !
                    </Typography.Text>
                  </InformationBox>
                  {!isLoggedIn ? (
                    <>
                      <Button
                        type="primary"
                        style={{ width: '100%', height: 56, fontSize: 16 }}
                        onClick={openLoginModal}
                      >
                        로그인해서 견적 받기
                      </Button>
                      <SlateButton onClick={handleRequestEstimate}>
                        로그인 없이 진행
                      </SlateButton>
                    </>
                  ) : (
                    <Button
                      type="primary"
                      style={{ width: '100%', height: 56, fontSize: 16 }}
                      onClick={handleRequestEstimate}
                    >
                      견적 받기
                    </Button>
                  )}
                </>
              )
            }
          </Form.Item>
        </Form>
      ) : (
        <RequesterForm user={user} items={items} />
      )}
    </Container>
  );
};

export default ClinicalTrialEstimateRequest;
