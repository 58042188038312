import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Modal, Space, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useMemo, useState } from 'react';
import styled from 'styled-components';

import BuyerAddModal from 'components/modal/buyer/BuyerAddModal';
import BuyerDetailModal from 'components/modal/buyer/BuyerDetailModal';
import { useBuyer } from 'hook/buyer';

const BuyerListBlock = styled.div``;

const BuyerList = ({ buyers, getLoading }: any) => {
  const [selectedBuyerIds, setSelectedBuyerIds] = useState<number[]>([]);
  const {
    countries,
    deleteLoading,
    toggleShowAddModal,
    setBuyer,
    deleteBuyers,
  } = useBuyer();
  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: '국가',
        width: '33%',
        align: 'center',
        render: ({ countryId }) =>
          countries.find((country) => country.countryId === countryId)
            ?.countryNameKo,
        filters: countries.map(({ countryId, countryNameKo }) => ({
          text: countryNameKo,
          value: countryId,
        })),
        onFilter: (value, record) => record.countryId === value,
      },
      {
        title: '바이어',
        width: '34%',
        align: 'center',
        render: (buyer) => (
          <Typography.Link underline onClick={() => setBuyer(buyer)}>
            {buyer.companyName}
          </Typography.Link>
        ),
      },
      {
        title: '대표',
        width: '33%',
        align: 'center',
        render: ({ ceoName }) => ceoName,
      },
    ],
    [countries],
  );
  return (
    <BuyerListBlock>
      <BuyerAddModal />
      <BuyerDetailModal />
      <Space
        style={{ width: '100%', marginBottom: 8, justifyContent: 'flex-end' }}
      >
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={toggleShowAddModal}
        >
          바이어 추가
        </Button>
        <Button
          loading={deleteLoading}
          type="default"
          icon={<DeleteOutlined />}
          onClick={() => {
            if (!selectedBuyerIds.length) return;
            Modal.confirm({
              icon: null,
              content: '정말로 삭제하시겠습니까?',
              onOk: () => {
                deleteBuyers(selectedBuyerIds);
                setSelectedBuyerIds([]);
              },
            });
          }}
        >
          선택한 바이어 삭제
        </Button>
      </Space>
      <Table
        rowKey={({ buyerId }) => buyerId}
        rowSelection={{
          selectedRowKeys: selectedBuyerIds,
          onChange: (key) => setSelectedBuyerIds(key as number[]),
        }}
        columns={columns}
        dataSource={buyers}
        loading={getLoading}
      />
    </BuyerListBlock>
  );
};

export default BuyerList;
