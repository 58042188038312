import { memo } from 'react';
import styled from 'styled-components';
import { Col, Form, Input, Row } from 'antd';

import { requireRule, exceptKoreanRule } from 'lib/validate';
import AlphabetLabel from './AlphabetLabel';

const MSDSPhysicalChemicalBlock = styled.div`
  width: 580px;
  margin: 0 auto;
`;

const MSDSPhysicalChemical = () => {
  return (
    <MSDSPhysicalChemicalBlock>
      <Row gutter={112} justify="center">
        <Col flex="1 1 256px">
          <AlphabetLabel alphabet="A" style={{ marginBottom: 12 }}>
            Appearance
          </AlphabetLabel>
          <Form.Item
            label="Form"
            name={['physicalChemical', 'appearanceForm']}
            rules={[requireRule, exceptKoreanRule]}
          >
            <Input placeholder="물리적 상태" />
          </Form.Item>
          <Form.Item
            label="Color"
            name={['physicalChemical', 'appearanceColor']}
            rules={[requireRule, exceptKoreanRule]}
          >
            <Input placeholder="색" />
          </Form.Item>
          <Form.Item
            label={<AlphabetLabel alphabet="B">Odour</AlphabetLabel>}
            name={['physicalChemical', 'odour']}
            rules={[requireRule, exceptKoreanRule]}
            required={false}
          >
            <Input placeholder="냄새" />
          </Form.Item>
          <Form.Item
            label={<AlphabetLabel alphabet="C">Odour Threshold</AlphabetLabel>}
            name={['physicalChemical', 'odourThreshold']}
            rules={[requireRule, exceptKoreanRule]}
            required={false}
          >
            <Input placeholder="냄새 역치" />
          </Form.Item>
          <Form.Item
            label={<AlphabetLabel alphabet="D">pH</AlphabetLabel>}
            name={['physicalChemical', 'ph']}
            rules={[requireRule, exceptKoreanRule]}
            required={false}
          >
            <Input placeholder="산도" />
          </Form.Item>
          <Form.Item
            label={<AlphabetLabel alphabet="E">Melting Point</AlphabetLabel>}
            name={['physicalChemical', 'meltingPoint']}
            rules={[requireRule, exceptKoreanRule]}
            required={false}
          >
            <Input placeholder="녹는점/어는점" />
          </Form.Item>
          <Form.Item
            label={
              <AlphabetLabel alphabet="F">Initial Boiling Point</AlphabetLabel>
            }
            name={['physicalChemical', 'initialBoilingPoint']}
            rules={[requireRule, exceptKoreanRule]}
            required={false}
          >
            <Input placeholder="초기 끓는점" />
          </Form.Item>
          <Form.Item
            label={<AlphabetLabel alphabet="G">Flash Point</AlphabetLabel>}
            name={['physicalChemical', 'flashPoint']}
            rules={[requireRule, exceptKoreanRule]}
            required={false}
            initialValue="No data"
          >
            <Input placeholder="인화점" />
          </Form.Item>
          <Form.Item
            label={<AlphabetLabel alphabet="H">Evaporation Rate</AlphabetLabel>}
            name={['physicalChemical', 'evaporationRate']}
            rules={[requireRule, exceptKoreanRule]}
            required={false}
          >
            <Input placeholder="증발 속도" />
          </Form.Item>
          <Form.Item
            label={
              <AlphabetLabel alphabet="I">
                Flammability (Solid, Gas)
              </AlphabetLabel>
            }
            name={['physicalChemical', 'flammability']}
            rules={[requireRule, exceptKoreanRule]}
            required={false}
            initialValue="No data"
          >
            <Input placeholder="인화성(고체, 기체)" />
          </Form.Item>
          <Form.Item
            label={
              <AlphabetLabel alphabet="J">LowerExplosion Limit</AlphabetLabel>
            }
            name={['physicalChemical', 'lowerExplosionLimit']}
            rules={[requireRule, exceptKoreanRule]}
            required={false}
            initialValue="No data"
          >
            <Input placeholder="폭발 하한" />
          </Form.Item>
        </Col>
        <Col flex="1 1 256px">
          <Form.Item
            label={<AlphabetLabel alphabet="K">Vapour Pressure</AlphabetLabel>}
            name={['physicalChemical', 'vapourPressure']}
            rules={[requireRule, exceptKoreanRule]}
            required={false}
          >
            <Input placeholder="증기압" />
          </Form.Item>
          <Form.Item
            label={<AlphabetLabel alphabet="L">Water Solubility</AlphabetLabel>}
            name={['physicalChemical', 'waterSolubility']}
            rules={[requireRule, exceptKoreanRule]}
            required={false}
          >
            <Input placeholder="용해도" />
          </Form.Item>
          <Form.Item
            label={
              <AlphabetLabel alphabet="M">
                Relative Vapour Density
              </AlphabetLabel>
            }
            name={['physicalChemical', 'relativeVapourDensity']}
            rules={[requireRule, exceptKoreanRule]}
            required={false}
          >
            <Input placeholder="증기밀도" />
          </Form.Item>
          <Form.Item
            label={<AlphabetLabel alphabet="N">Density</AlphabetLabel>}
            name={['physicalChemical', 'density']}
            rules={[requireRule, exceptKoreanRule]}
            required={false}
          >
            <Input placeholder="비중" />
          </Form.Item>
          <Form.Item
            label={
              <AlphabetLabel alphabet="O">
                Partition Coefficient: n-octanol/Water
              </AlphabetLabel>
            }
            name={['physicalChemical', 'octanolWater']}
            rules={[requireRule, exceptKoreanRule]}
            required={false}
            initialValue="No data"
          >
            <Input placeholder="n 옥탄올/물 분배계수" />
          </Form.Item>
          <Form.Item
            label={
              <AlphabetLabel alphabet="P">
                Auto-ignition Temperature
              </AlphabetLabel>
            }
            name={['physicalChemical', 'autoIgnitionTemperature']}
            rules={[requireRule, exceptKoreanRule]}
            required={false}
            initialValue="No data"
          >
            <Input placeholder="자연발화 온도" />
          </Form.Item>
          <Form.Item
            label={
              <AlphabetLabel alphabet="Q">
                Decomposition Temperature
              </AlphabetLabel>
            }
            name={['physicalChemical', 'decompositionTemperature']}
            rules={[requireRule, exceptKoreanRule]}
            required={false}
            initialValue="No data"
          >
            <Input placeholder="분해 온도" />
          </Form.Item>
          <AlphabetLabel alphabet="R">Viscosity</AlphabetLabel>
          <Form.Item
            label="Viscosity, Dynamic"
            name={['physicalChemical', 'viscosityDynamic']}
            rules={[requireRule, exceptKoreanRule]}
          >
            <Input placeholder="동적 점도" />
          </Form.Item>
          <Form.Item
            label="Viscosity, Kinematic"
            name={['physicalChemical', 'viscosityKinematic']}
            rules={[requireRule, exceptKoreanRule]}
          >
            <Input placeholder="역학적 점도" />
          </Form.Item>
          <Form.Item
            label={<AlphabetLabel alphabet="S">Molecular Weight</AlphabetLabel>}
            name={['physicalChemical', 'molecularWeight']}
            rules={[requireRule, exceptKoreanRule]}
            required={false}
          >
            <Input placeholder="분자량" />
          </Form.Item>
        </Col>
      </Row>
    </MSDSPhysicalChemicalBlock>
  );
};

export default memo(MSDSPhysicalChemical);
