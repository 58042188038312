import { Form, message } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useManuMode } from 'hook/company';
import { messages } from 'lib/consts';
import {
  addProductPackingAttestation,
  getProductPackingAttestation,
  initializeProductPackingAttestation,
  savePackingAttestationType,
  updateProductPackingAttestation,
} from 'modules/product';
import { getUpdatingObject } from 'lib/form';
import { IPackingAttestationBasic } from 'types/product';

interface IPackingAttestationAttache {
  attachType: '1ST' | '2ST';
  url: string;
  name: string;
  productPackingAttestationAttachId: number;
  productPackingAttestationId: number;
}

interface IPackingAttestation extends IPackingAttestationBasic {
  productId: number;
  productPackingAttestationId: number;
  productPackingAttestationAttaches: IPackingAttestationAttache[];
}

export const useProductPackingAttestation = (
  productId: number,
  countryId?: number,
) => {
  const {
    currentProduct,
    packingAttestation,
    fetchLoading,
    getLoading,
    updateLoading,
  } = useSelector(
    ({ product, loading }: any) => ({
      currentProduct: product.currentProduct,
      packingAttestation: product.packingAttestation as IPackingAttestation,
      getLoading: loading['product/GET_PRODUCT_PACKING_ATTESTATION'],
      fetchLoading:
        loading['product/ADD_PRODUCT_PACKING_ATTESTATION'] ||
        loading['product/SAVE_PACKING_ATTESTATION_TYPE'],
      updateLoading: loading['product/UPDATE_PRODUCT_PACKING_ATTESTATION'],
    }),
    shallowEqual,
  );

  const [firstPackingFiles, setFirstPackingFiles] = useState<
    (IPackingAttestationAttache | File)[]
  >([]);
  const [secondPackingFiles, setSecondPackingFiles] = useState<
    (IPackingAttestationAttache | File)[]
  >([]);
  const [firstPreviewFile, setFirstPreviewFile] = useState<
    IPackingAttestationAttache | File | null
  >(null);
  const [secondPreviewFile, setSecondPreviewFile] = useState<
    IPackingAttestationAttache | File | null
  >(null);
  const packingFileNames = useMemo(
    () => [
      ...firstPackingFiles.map(({ name }) => name),
      ...secondPackingFiles.map(({ name }) => name),
    ],
    [firstPackingFiles, secondPackingFiles],
  );
  const showFirstPreviewFile = useCallback((file: File) => {
    setFirstPreviewFile(file);
    setSecondPreviewFile(null);
  }, []);
  const showSecondPreviewFile = useCallback((file: File) => {
    setSecondPreviewFile(file);
    setFirstPreviewFile(null);
  }, []);
  const uploadFirstPackingFile = useCallback(
    (file: File) => {
      if (packingFileNames.find((name) => name === file.name)) {
        return message.warning(messages.DUPLICATE_FILE_NAME);
      }
      showFirstPreviewFile(file);
      setFirstPackingFiles((draft) => draft.concat(file));
    },
    [packingFileNames, showFirstPreviewFile],
  );
  const uploadSecondPackingFile = useCallback(
    (file: File) => {
      if (packingFileNames.find((name) => name === file.name)) {
        return message.warning(messages.DUPLICATE_FILE_NAME);
      }
      showSecondPreviewFile(file);
      setSecondPackingFiles((draft) => draft.concat(file));
    },
    [packingFileNames, showSecondPreviewFile],
  );

  const updateMode = packingAttestation !== null;
  const isFile = (file: any): file is File => !file.url;
  const [deletedFiles, setDeletedFiles] = useState<
    (IPackingAttestationAttache | File)[]
  >([]);
  const deletePackingFile = useCallback(
    (file: IPackingAttestationAttache | File) => {
      if (updateMode && !isFile(file)) {
        setDeletedFiles((draft) => draft.concat(file));
      }
      const first = firstPackingFiles.filter(
        (firstPackingFile) => firstPackingFile.name !== file.name,
      );
      const second = secondPackingFiles.filter(
        (secondPackingFiles) => secondPackingFiles.name !== file.name,
      );
      setFirstPreviewFile(first[0] || null);
      setSecondPreviewFile(second[0] || null);
      setFirstPackingFiles(first);
      setSecondPackingFiles(second);
    },
    [updateMode, firstPackingFiles, secondPackingFiles],
  );
  const [form] = Form.useForm();
  const initSecondPackingFiles = useCallback(
    (isUseSecondPackingContainer: boolean) => {
      if (!isUseSecondPackingContainer) {
        setFirstPreviewFile(firstPackingFiles[0] || null);
        setSecondPreviewFile(null);
        if (secondPackingFiles.length > 0) {
          setDeletedFiles((draft) => draft.concat(secondPackingFiles));
        }
      }
    },
    [form, firstPackingFiles, secondPackingFiles],
  );
  const [packingAttestationType, setPackingAttestationType] = useState(
    currentProduct.packingAttestationType,
  );
  const dispatch = useDispatch();
  const manuMode = useManuMode();
  const submit = useCallback(
    (formValue: any) => {
      if (!updateMode) {
        if (!manuMode) {
          dispatch(
            addProductPackingAttestation([
              { productId: currentProduct.productId, ...formValue },
              {
                firstPackingFiles,
                ...(formValue.isUsePackingContainer2 && { secondPackingFiles }),
              },
            ]),
          );
        } else {
          dispatch(
            addProductPackingAttestation([
              { productId: currentProduct.productId, ...formValue },
              { firstPackingFiles, secondPackingFiles },
              packingAttestationType,
            ]),
          );
        }
      } else {
        const {
          packingContainer1,
          isUsePackingContainer2,
          packingContainer2,
          isUseRecyclingContainer,
          isUseColorContainer,
        } = formValue;
        if (!manuMode) {
          const updateValues = {
            ...getUpdatingObject(
              {
                packingContainer1,
                isUsePackingContainer2,
                isUseRecyclingContainer,
              },
              packingAttestation,
            ),
            ...(typeof packingContainer2 !== 'undefined' &&
              packingContainer2 !== packingAttestation.packingContainer2 && {
                packingContainer2,
              }),
            ...(typeof isUseColorContainer !== 'undefined' &&
              isUseColorContainer !==
                packingAttestation.isUseColorContainer && {
                isUseColorContainer: isUseColorContainer || false,
              }),
          };
          const newFirstPackingFiles = firstPackingFiles.filter((file) =>
            isFile(file),
          );
          const newSecondPackingFiles = secondPackingFiles.filter((file) =>
            isFile(file),
          );
          if (
            !Object.keys(updateValues).length &&
            currentProduct.packingAttestationType === packingAttestationType &&
            deletedFiles.length === 0 &&
            newFirstPackingFiles.length === 0 &&
            newSecondPackingFiles.length === 0
          ) {
            return message.warn(messages.NO_NEED_TO_UPDATE);
          }
          dispatch(
            updateProductPackingAttestation([
              {
                productPackingAttestationId:
                  packingAttestation.productPackingAttestationId,
                ...updateValues,
              },
              {
                firstPackingFiles: newFirstPackingFiles,
                secondPackingFiles: newSecondPackingFiles,
                deletedFiles:
                  packingAttestation.isUsePackingContainer2 &&
                  !isUsePackingContainer2
                    ? deletedFiles.concat(
                        packingAttestation.productPackingAttestationAttaches.filter(
                          ({ attachType }) => attachType === '2ST',
                        ),
                      )
                    : deletedFiles,
              },
            ]),
          );
        } else {
          // 브랜드 > 턴키 => 추가
          if (
            currentProduct.packingAttestationType === 'BND' &&
            packingAttestationType !== 'BND'
          ) {
            dispatch(
              addProductPackingAttestation([
                { productId, ...formValue },
                { firstPackingFiles, secondPackingFiles },
                packingAttestationType,
              ]),
            );
            return;
          }

          // 턴키 > 턴키 => 수정
          if (
            currentProduct.packingAttestationType !== 'BND' &&
            packingAttestationType !== 'BND'
          ) {
            const updateValues = {
              ...getUpdatingObject(
                {
                  packingContainer1,
                  isUsePackingContainer2,
                  isUseRecyclingContainer,
                },
                packingAttestation,
              ),
              ...(typeof packingContainer2 !== 'undefined' &&
                packingContainer2 !== packingAttestation.packingContainer2 && {
                  packingContainer2,
                }),
              ...(typeof isUseColorContainer !== 'undefined' &&
                isUseColorContainer !==
                  packingAttestation.isUseColorContainer && {
                  isUseColorContainer: isUseColorContainer || false,
                }),
            };
            const newFirstPackingFiles = firstPackingFiles.filter((file) =>
              isFile(file),
            );
            const newSecondPackingFiles = secondPackingFiles.filter((file) =>
              isFile(file),
            );
            if (
              !Object.keys(updateValues).length &&
              currentProduct.packingAttestationType ===
                packingAttestationType &&
              deletedFiles.length === 0 &&
              newFirstPackingFiles.length === 0 &&
              newSecondPackingFiles.length === 0
            ) {
              return message.warn(messages.NO_NEED_TO_UPDATE);
            }

            dispatch(
              updateProductPackingAttestation([
                {
                  productId,
                  productPackingAttestationId:
                    packingAttestation.productPackingAttestationId,
                  ...updateValues,
                },
                {
                  firstPackingFiles: newFirstPackingFiles,
                  secondPackingFiles: newSecondPackingFiles,
                  deletedFiles,
                },
                packingAttestationType,
              ]),
            );
          }
        }
      }
    },
    [
      deletedFiles,
      currentProduct,
      updateMode,
      manuMode,
      packingAttestation,
      firstPackingFiles,
      secondPackingFiles,
      packingAttestationType,
    ],
  );

  const previewFile: File | string | null = useMemo(() => {
    if (firstPreviewFile) {
      return !isFile(firstPreviewFile)
        ? firstPreviewFile.url
        : firstPreviewFile;
    }
    if (secondPreviewFile) {
      return !isFile(secondPreviewFile)
        ? secondPreviewFile.url
        : secondPreviewFile;
    }
    return null;
  }, [firstPreviewFile, secondPreviewFile]);
  const patchPackingAttestationType = useCallback(() => {
    dispatch(
      savePackingAttestationType({
        productId: currentProduct.productId,
        packingAttestationType,
      }),
    );
  }, [currentProduct, packingAttestationType]);
  useEffect(() => {
    if (!manuMode || packingAttestationType !== 'BND') {
      dispatch(
        getProductPackingAttestation({
          productId: productId,
          countryId: countryId!,
        }),
      );
      return () => {
        dispatch(initializeProductPackingAttestation());
      };
    }
  }, [manuMode, packingAttestationType]);

  useEffect(() => {
    if (packingAttestation) {
      form.setFieldsValue({
        packingContainer1: packingAttestation.packingContainer1,
        isUsePackingContainer2: packingAttestation.isUsePackingContainer2,
        packingContainer2: packingAttestation.packingContainer2,
        isUseRecyclingContainer: packingAttestation.isUseRecyclingContainer,
        isUseColorContainer: packingAttestation.isUseColorContainer,
      });
      setFirstPackingFiles(
        packingAttestation.productPackingAttestationAttaches.filter(
          ({ attachType }) => attachType === '1ST',
        ),
      );
      if (packingAttestation.isUsePackingContainer2) {
        setSecondPackingFiles(
          packingAttestation.productPackingAttestationAttaches.filter(
            ({ attachType }) => attachType === '2ST',
          ),
        );
      }
      setFirstPreviewFile(
        packingAttestation.productPackingAttestationAttaches[0],
      );
    }
  }, [packingAttestation]);

  useEffect(() => {
    if (!updateMode && process.env.NODE_ENV === 'development') {
      form.setFieldsValue({
        packingContainer1: 'Body : ABL /  CAP : PP',
        packingContainer2: 'Body : ABL /  PUMP : PP',
      });
    }
  }, [updateMode]);

  return useMemo(
    () => ({
      updateMode,
      previewFile,
      form,
      firstPackingFiles,
      secondPackingFiles,
      getLoading,
      fetchLoading,
      updateLoading,
      packingAttestationType,
      uploadFirstPackingFile,
      uploadSecondPackingFile,
      firstPreviewFile,
      secondPreviewFile,
      showFirstPreviewFile,
      showSecondPreviewFile,
      deletePackingFile,
      submit,
      changePackingAttestationType: setPackingAttestationType,
      patchPackingAttestationType,
      initSecondPackingFiles,
    }),
    [
      updateMode,
      previewFile,
      form,
      firstPackingFiles,
      secondPackingFiles,
      getLoading,
      fetchLoading,
      updateLoading,
      packingAttestationType,
      uploadFirstPackingFile,
      uploadSecondPackingFile,
      firstPreviewFile,
      secondPreviewFile,
      deletePackingFile,
      submit,
      initSecondPackingFiles,
    ],
  );
};
