import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useMemo, useState } from 'react';
import styled from 'styled-components';

import DocumentPreviewModal from 'components/modal/product/DocumentPreviewModal';
import { Typography } from 'components/system';
import { IMyClinicalTrialEstimate } from 'types/brand/clinicalTrial/estimate';
import { useMyClinicalTrialEstimates } from 'service/brand/clinicalTrial/estimate';

const Container = styled.div``;

const EstimateList = () => {
  const [page, setPage] = useState(1);
  const [previewedFileUrl, setPreviewedFileUrl] = useState<string | null>(null);
  const {
    myClinicalTrialEstimates,
    totalElements,
  } = useMyClinicalTrialEstimates({
    page,
  });
  const columns: ColumnsType<IMyClinicalTrialEstimate> = useMemo(
    () => [
      {
        title: '견적서 번호',
        dataIndex: 'estimateNo',
        align: 'center',
        render: (estimateNo) => (
          <Typography.Text medium type="secondary" color="black">
            {estimateNo}
          </Typography.Text>
        ),
      },
      {
        title: '견적서 발행일',
        dataIndex: 'publishDate',
        align: 'center',
        render: (publishDate) => publishDate.slice(0, 10),
      },
      {
        title: '견적 금액',
        dataIndex: 'totalPrice',
        align: 'center',
        render: (totalPrice) => `${totalPrice.toLocaleString()}원`,
      },
      {
        title: '입금 확인',
        dataIndex: 'isDepositFinish',
        align: 'center',
        render: (isDepositFinish) => (
          <Typography.Label
            type={isDepositFinish ? 'success' : 'disabled'}
            style={{ minWidth: 56, width: 56 }}
          >
            {isDepositFinish ? '완료' : '대기중'}
          </Typography.Label>
        ),
      },
      {
        title: '견적서',
        dataIndex: 'estimateUrl',
        align: 'center',
        render: (estimateUrl) => (
          <i
            className="material-icons outlined cursor black"
            onClick={() => setPreviewedFileUrl(estimateUrl)}
          >
            description
          </i>
        ),
      },
    ],
    [setPreviewedFileUrl],
  );

  return (
    <Container>
      {previewedFileUrl && (
        <DocumentPreviewModal
          documentUrls={previewedFileUrl}
          onClose={() => setPreviewedFileUrl(null)}
        />
      )}
      <Table
        columns={columns}
        dataSource={myClinicalTrialEstimates}
        rowKey="estimateId"
        pagination={{
          current: page,
          total: totalElements,
          onChange: setPage,
        }}
      />
    </Container>
  );
};

export default EstimateList;
