import styled from 'styled-components';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { IEtcDataForm } from 'types/material/etcData';
import { downloadFile, getReadableFileSize } from 'lib/file';

const EtcDataTableBlock = styled.div`
  .ant-table {
    position: relative;
    border-radius: 0 !important;
    min-height: 200px;
    border: 1px solid #d3d3d3;
    border-top: none;
    overflow: visible;
    padding: 0 4px;

    &::before {
      content: '';
      position: absolute;
      left: -1px;
      top: 0;
      height: 38px;
      width: calc(100% + 2px);
      border-left: 1px solid #fff;
      border-right: 1px solid #fff;
      border-bottom: 1px solid #d3d3d3;
      z-index: 100;
    }
    .ant-table-tbody > tr.ant-table-row:hover > td {
      background: #f0faff;
    }
    th {
      background: transparent;
      border: none;
    }
    td {
      border-bottom: none;
      padding-top: 8px;
      padding-bottom: 8px;
    }

    th,
    td {
      color: #222;
    }
  }
`;

const EtcDataTable = ({
  etcDatas,
  loading,
  onDeleteFile,
}: {
  etcDatas: IEtcDataForm[];
  loading: boolean;
  onDeleteFile: (index: number) => void;
}) => {
  const columns: ColumnsType<IEtcDataForm> = [
    { title: '파일명', width: '45%', dataIndex: 'filename' },
    {
      title: '다운로드',
      align: 'center',
      render: (_, { uploadFileUrl, filename }) => (
        <i
          className="material-icons outlined gray cursor"
          onClick={() => downloadFile(uploadFileUrl, filename)}
        >
          file_download
        </i>
      ),
    },
    {
      title: '크기',
      render: (_, { filesize }) => getReadableFileSize(filesize),
    },
    {
      title: '업로드 날짜',
      align: 'center',
      dataIndex: 'registerDt',
    },
    {
      title: '삭제',
      align: 'center',
      render: (_, __, index) => (
        <i
          className="material-icons outlined gray cursor"
          onClick={() => onDeleteFile(index)}
        >
          delete
        </i>
      ),
    },
  ];
  return (
    <EtcDataTableBlock>
      <Table
        columns={columns}
        dataSource={etcDatas}
        rowKey={({ materialEtcDataId, file }) =>
          materialEtcDataId || file!.name
        }
        loading={loading}
        pagination={false}
      />
    </EtcDataTableBlock>
  );
};

export default EtcDataTable;
