import { message } from 'antd';
import moment from 'moment';
import { Rule } from 'rc-field-form/lib/interface';
import { IMarketingFormDataBase } from 'types/material/marketing';
import { messages } from './consts';

/* REGEX */

export const dateRegex = /^(19|20)\d{2}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[0-1])$/;

/* RULES */
export const requireRule = {
  required: true,
  message: messages.REQUIRED_FIELD,
};

export const exceptKoreanRule = {
  pattern: /^[^ㄱ-ㅎㅏ-ㅣ가-힣]*$/,
  message: messages.SHOULD_EXCEPT_KOREAN,
};

export const exceptAlphabetRule = {
  pattern: /^[^A-Z]*$/i,
  message: messages.SHOULD_EXCEPT_ALPHABET,
};

export const exceptKoreanOrAlphabetRule = {
  pattern: /^[^ㄱ-ㅎㅏ-ㅣ가-힣A-Z]*$/i,
  message: messages.SHOULD_EXCEPT_KOREAN_OR_ALPHABET,
};

export const exceptKoreanOrNumberRule = {
  pattern: /^[^ㄱ-ㅎㅏ-ㅣ가-힣0-9]*$/i,
  message: messages.SHOULD_EXCEPT_KOREAN_OR_NUMBER,
};

export const alphabetOrNumberRule = {
  pattern: /^[A-Z0-9]*$/i,
  message: messages.SHOULD_ALPHABET_OR_NUMBER,
};

export const numberRule = {
  type: 'number' as const,
  message: messages.SHOULD_NUMBER,
};

export const numbersRule = {
  pattern: /^[-]?\d+(?:[.]\d+)?$/,
  message: messages.SHOULD_NUMBER,
};

export const phoneRule = {
  pattern: /^\d{8,12}$/i,
  message: messages.PHONE_FORMAT,
};

export const mobilePhoneRule = {
  pattern: /^\d{10,11}$/i,
  message: messages.MOBILE_PHONE_FORMAT,
};

export const chinaPhoneRule = {
  pattern: /^\d{10,20}$/i,
  message: messages.CHINA_PHONE_FORMAT,
};

export const emailRule = {
  pattern: /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}(\.[a-zA-Z]{2})?$/i,
  message: messages.INVALID_EMAIL_FORMAT,
};

export const numberOrKoreanRule = {
  pattern: /^[ㄱ-ㅎ|가-힣|0-9|]+$/,
  message: messages.SHOULD_NUMBER_OR_KOREAN,
};

export const koreanOrAlphabetRule = {
  pattern: /^[ㄱ-ㅎ|가-힣|A-Z]+$/i,
  message: messages.SHOULD_KOREAN_OR_ALPHABET,
};

export const dateRule: Rule = {
  validator: async (_, value) => {
    if (value !== '' && !moment(value, 'YYYY-MM-DD', true).isValid()) {
      throw Error(messages.INVALID_DATE_FORMAT);
    }
  },
};

export const noPastDateRule: Rule = {
  validator: async (_, value) => {
    if (!value) return;
    if (value !== '' && !moment(value, 'YYYY-MM-DD', true).isValid()) {
      throw Error(messages.INVALID_DATE_FORMAT);
    }
    if (
      dateRegex.test(value) &&
      new Date(value).getTime() < new Date().getTime()
    ) {
      throw new Error('과거의 날짜는 입력 불가');
    }
  },
};

export const noFutureDateRule: Rule = {
  validator: async (_, value) => {
    if (!value) return;
    if (value !== '' && !moment(value, 'YYYY-MM-DD', true).isValid()) {
      throw Error(messages.INVALID_DATE_FORMAT);
    }
    if (new Date(value).getTime() > new Date().getTime()) {
      throw new Error('미래의 날짜는 입력 불가');
    }
  },
};

export const alphabetOrSpaceRule = {
  pattern: /^[A-Z\s]*$/i,
  message: messages.SHOULD_ALPHABET_OR_SPACE,
};

export const urlRule = {
  type: 'url' as const,
  message: '올바르지 않은 URL 형식입니다.',
};

/* NORMALIZE */
export const normalizeDate = (value: any) =>
  value
    .replace(/[^\d-]/, '')
    .replace(/^(\d{4})(\d{1,})/, '$1-$2')
    .replace(/^(\d{4}-\d{2})(\d{1,})/, '$1-$2')
    .replace(/^(\d{4}-\d{2}-\d{2}).+/, '$1');

export const normalizeNumber = (value: any) => value.replace(/[^\d]/g, '');
export const capitalize = (value: any) =>
  value.charAt(0).toUpperCase() + value.slice(1);

export const checkInputNumber = (
  target: string,
  maxLen: number,
  msg: string,
) => {
  if (target.length > maxLen) {
    message.warn(msg);
  }
};

export const validateStringItem = (value?: string) => {
  if (value) {
    if (value.trim().length > 0) {
      return false;
    }
  }
  return true;
};

export const checkIsPageEmpty = (formData: IMarketingFormDataBase): boolean => {
  for (let i = 0; i < formData?.pages?.length; i++) {
    let isPageEmpty = true;
    const page = formData.pages[i];
    for (let j = 0; j < page.items.length; j++) {
      const item = page.items[j];
      if (!validateStringItem(item.dataTitle)) {
        isPageEmpty = false;
        break;
      }
      if (!validateStringItem(item.description)) {
        isPageEmpty = false;
        break;
      }
      if (!validateStringItem(item.attachUrl)) {
        isPageEmpty = false;
        break;
      }
      if (!validateStringItem(item.shortDescription)) {
        isPageEmpty = false;
        break;
      }
      if (!validateStringItem(item.itemType)) {
        isPageEmpty = false;
        break;
      }
      if (item.uploadFile) {
        isPageEmpty = false;
      }
    }
    if (isPageEmpty) {
      return isPageEmpty;
    }
  }

  return false;
};

export const checkIsPastDate = (current: moment.Moment) =>
  current.isBefore(moment().endOf('day'));

export const checkIsFutureDate = (current: moment.Moment) =>
  current.isAfter(moment().endOf('day'));
