import ProductList from 'components/product/ProductList';
import PageTemplate from 'templates/PageTemplate';

const ProductRegisteredPage = () => {
  return (
    <PageTemplate
      title="등록 완료 제품 관리"
      back={false}
      description="아래 등록 완료된 제품을 관리해보세요."
    >
      <ProductList />
    </PageTemplate>
  );
};

export default ProductRegisteredPage;
