import { message } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import path from './path';

export const useForceUpdate = () => {
  const [value, setValue] = useState(0);
  return () => setValue(value + 1);
};

export const useCheckManufacturer = () => {
  const isManufacturer = useSelector(({ auth }) => auth.user.isManufacturer);
  const history = useHistory();
  useEffect(() => {
    if (isManufacturer) {
      message.warn('올바르지 않은 접근입니다.');
      history.replace(path.main);
    }
  }, [isManufacturer]);
};
