import PageTemplate from 'templates/PageTemplate';
import EstimatePickContainer from 'containers/estimate/EstimatePickContainer';

const notices = [
  '셀프 견적을 통해 인증 진행하려는 제품과 국가를 차례로 선택하여 여러 개의 제품을 한 번에 합산된 금액으로 견적을 낼 수 있습니다.',
  '① 제품 선택 → ② 국가 선택 (여러 국가 선택 가능) → ③ 셀프 견적 리스트에 추가 순으로 제품과 국가를 추가할 수 있으며, 여러 개의 제품을 추가하시려면 다시 ① 번 부터 진행하시면 됩니다.',
  '체크리스트 작성이 필요한 항목의 경우, 작성완료 한 경우에만 견적서 받기가 가능합니다.',
];

const EstimatePickPage = () => {
  return (
    <PageTemplate title="셀프 견적" notices={notices} back={false}>
      <EstimatePickContainer />
    </PageTemplate>
  );
};
export default EstimatePickPage;
