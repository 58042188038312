import MistakeNoticeItem from 'components/MistakeNoticeItem';
import ProductChemicalPropertyContainer from 'containers/product/ProductChemicalPropertyContainer';
import ProductPageTemplate from 'templates/ProductPageTemplate';

const notices = [
  '선택 항목 우측에 기재된 기준 이하의 중금속 수치일 경우에만 인증이 가능합니다.',
  '아래 목록 이외의 중금속 검출 테스트를 진행하시는 경우, 채널톡으로 문의 바랍니다. ',
  <MistakeNoticeItem />,
];

const ProductChemicalPropertyPage = () => {
  return (
    <ProductPageTemplate subtitle="Chemical Properties" notices={notices}>
      <ProductChemicalPropertyContainer />
    </ProductPageTemplate>
  );
};

export default ProductChemicalPropertyPage;
