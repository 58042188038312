import { Button, message, Modal, Select, Space, Table } from 'antd';
import { useMemo, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { DeleteTwoTone } from '@ant-design/icons';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { Typography } from 'components/system';
import {
  ERegisterSearchType,
  ERegisterStatus,
  IMaterialRegister,
} from 'types/material/register';
import SearchInput from 'components/system/form/SearchInput';
import { useMaterialRegisterManage } from 'service/material/manage';
import { registerManageSearchTypeOptions } from 'lib/consts';
import path from 'lib/path';

const MaterialReadyBlock = styled.div``;

const MaterialReady = () => {
  const [pageNo, setPageNo] = useState(1);
  const [searchType, setSearchType] = useState<ERegisterSearchType>(
    ERegisterSearchType.ALL,
  );
  const [searchInput, setSearchInput] = useState('');
  const [selectedMaterialIds, setSelectedMaterialIds] = useState<number[]>([]);
  const history = useHistory();
  const {
    materialRegisters,
    getMaterialRegistersLoading,
    refetchRegisters,
    deleteMaterials,
    deleteMaterialsLoading,
  } = useMaterialRegisterManage({
    status: ERegisterStatus.RM_RDY,
    pageNo,
    ...(searchInput.trim().length > 0 && { [searchType]: searchInput }),
  });
  const handleClickMaterial = (materialId: number) => {
    history.push(`${path.material.rawMaterial.root}/${materialId}`);
  };

  const columns: ColumnsType<IMaterialRegister> = useMemo(
    () => [
      {
        title: '원료명',
        dataIndex: 'materialNameEn',
        align: 'center',
        render: (materialNameEn, record) => (
          <Typography.Text
            type="secondary"
            color="black"
            style={{ fontWeight: 500, cursor: 'pointer' }}
            onClick={() => handleClickMaterial(record.materialId)}
          >
            {materialNameEn}
          </Typography.Text>
        ),
      },
      {
        title: '실 생산자명',
        dataIndex: 'originManufacturerName',
        align: 'center',
      },
      {
        title: '최근 수정 일시',
        dataIndex: 'updateDt',
        align: 'center',
        render: (updateDt) => {
          return updateDt ? updateDt.replace('T', ' ') : '-';
        },
      },
    ],
    [],
  );

  const handleDeleteMaterials = () => {
    if (!selectedMaterialIds.length) return;
    Modal.confirm({
      title: '정말로 삭제하시겠습니까?',
      icon: null,
      onOk: () => {
        deleteMaterials(selectedMaterialIds);
        setSelectedMaterialIds([]);
      },
    });
  };

  const searchRegisters = (input: string) => {
    if (input.trim().length === 0) {
      return message.warning('검색어를 입력해주세요.');
    }
    setPageNo(1);
    setSearchInput(input);
    setTimeout(refetchRegisters);
  };

  const handleSelectChange = (type: ERegisterSearchType) => {
    setSearchType(type);
    setSearchInput('');
    setTimeout(refetchRegisters);
  };

  return (
    <MaterialReadyBlock>
      <Space
        style={{
          width: '100%',
          justifyContent: 'flex-end',
          marginBottom: 24,
        }}
      >
        <Select
          options={registerManageSearchTypeOptions}
          value={searchType}
          onChange={(type) => handleSelectChange(type)}
          style={{ width: 115 }}
        />
        <SearchInput
          defaultValue={searchInput}
          disabled={searchType === ERegisterSearchType.ALL}
          placeholder="검색"
          onSearch={searchRegisters}
          style={{ width: 280 }}
        />
      </Space>
      <Table
        columns={columns}
        dataSource={materialRegisters?.content}
        rowKey={({ materialId }) => materialId}
        pagination={{
          current: pageNo,
          pageSize: materialRegisters?.size,
          total: materialRegisters?.totalElements,
          onChange: (page) => {
            setPageNo(page);
            setTimeout(refetchRegisters);
          },
        }}
        rowSelection={{
          selectedRowKeys: selectedMaterialIds,
          onChange: (value) => {
            setSelectedMaterialIds(value as number[]);
          },
        }}
        loading={getMaterialRegistersLoading || deleteMaterialsLoading}
        footer={() => (
          <Space
            align="center"
            style={{
              marginTop: '16px',
              width: '100%',
            }}
          >
            <Button
              type="default"
              icon={<DeleteTwoTone />}
              onClick={handleDeleteMaterials}
            >
              선택 삭제
            </Button>
          </Space>
        )}
      />
    </MaterialReadyBlock>
  );
};

export default MaterialReady;
