import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as certificateActions from 'modules/certificate';
import useUpdateEffect from 'hook/useUpdateEffect';
import { useCountryId } from 'service/brand/product/product';

enum ESearchType {
  ALL = 'all',
  PRODUCT_NAME_EN = 'productNameEn',
  countryNameKo = 'countryNameKo',
}

export const useCertificates = (isFinish: boolean) => {
  const dispatch = useDispatch();
  const { companyId, certificates, loading } = useSelector<any, any>(
    ({ auth, certificate, loading }) => ({
      companyId: auth.user.companyId,
      certificates: certificate.certificates,
      loading: loading['certificate/GET_CERTIFICATES'],
    }),
  );

  const [page, setPage] = useState(1);
  const handleChangePage = (page: number) => {
    setPage(page);
    getCertificates(page);
  };

  const [searchType, setSearchType] = useState<ESearchType>(ESearchType.ALL);
  const [searchValue, setSearchValue] = useState('');
  const handleChangeSearchType = useCallback((searchType: ESearchType) => {
    setSearchType(searchType);
    setSearchValue('');
  }, []);

  const [finishDate, setFinishDate] = useState({ lower: '', upper: '' });

  const getCertificates = useCallback(
    (page: number, searchValue?: string) => {
      dispatch(
        certificateActions.getCertificates({
          companyId,
          isFinish,
          page,
          [searchType]: searchValue,
          finishDateLower: finishDate.lower,
          finishDateUpper: finishDate.upper,
        }),
      );
    },
    [searchType, searchValue, finishDate],
  );
  const searchCertificates = useCallback(
    (searchValue: string) => {
      setPage(1);
      getCertificates(1, searchValue);
      setSearchValue(searchValue);
    },
    [getCertificates],
  );

  useEffect(() => {
    getCertificates(1);
    return () => {
      dispatch(certificateActions.initializeCertificates());
    };
  }, []);
  useUpdateEffect(() => {
    if (searchType === ESearchType.ALL) {
      searchCertificates('');
    }
  }, [searchType]);

  useUpdateEffect(() => {
    searchCertificates(searchValue);
  }, [finishDate]);

  return useMemo(
    () => ({
      certificates,
      loading,
      page,
      setPage: handleChangePage,
      searchType,
      setSearchType: handleChangeSearchType,
      searchValue,
      searchCertificates,
      finishDate,
      setFinishDate,
    }),
    [
      certificates,
      loading,
      page,
      handleChangePage,
      searchType,
      handleChangeSearchType,
      searchValue,
      searchCertificates,
      finishDate,
    ],
  );
};

export const useCertificateMode = () => {
  const countryId = useCountryId();
  return typeof countryId !== 'undefined';
};
export const useReadOnlyMode = () => {
  const readOnlyMode = useSelector(
    ({ certificate }: any) => certificate.readOnlyMode,
  );
  return readOnlyMode;
};
