import { useMemo } from 'react';

import CertificateDetailContainer from 'containers/certificate/CertificateDetailContainer';
import PageTemplate from 'templates/PageTemplate';

const CertificateDetailPage = () => {
  const notices = useMemo(
    () => [
      '원활한 인증 진행을 위해 필수 항목의 모든 정보를 입력해 주세요.',
      '모든 서류의 검토 후 인증기관에 서류를 제출할 시 반드시 30COS에서 제공하는 폼을 사용해 주세요.',
      '인증 진행시 현지에 맞는 LOA 발행을 위해서 바이어 정보가 필요합니다. 입력하신 바이어 정보는 다른 곳에 공유하지 않으니 안심하셔도 됩니다.',
      '용량의 경우, 실제 인증을 진행하는 서류의 제품명에 반영되지 않습니다.',
    ],
    [],
  );
  return (
    <PageTemplate title="인증 상세 정보" notices={notices}>
      <CertificateDetailContainer />
    </PageTemplate>
  );
};

export default CertificateDetailPage;
