import qs from 'qs';
import client from 'lib/api/client';
import { IAPIResponse } from 'types/common';
import { IEtcDataGet, IEtcDatasAdd } from 'types/material/etcData';

export const getEtcDatas = (materialId: number) =>
  client.get<IAPIResponse<IEtcDataGet[]>>(`/material/etc-datas/${materialId}`);

export const getEtcDataUsages = (companyId: number) =>
  client.get<IAPIResponse<number>>(
    `/material/etc-datas/usages?companyId=${companyId}`,
  );

export const addEtcDatas = (etcDatas: IEtcDatasAdd) =>
  client.post('/material/etc-datas', etcDatas);

export const deleteEtcDatas = (materialEtcDataIds: number[]) =>
  client.delete(
    `/material/etc-datas?${qs.stringify(
      { materialEtcDataIds },
      { indices: false },
    )}`,
  );
