import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, Col, Empty, List, Row, Spin } from 'antd';
import styled, { css } from 'styled-components';

import palette from 'lib/styles/palette';
import FooterBox from 'components/FooterBox';
import Typography from 'components/system/general/Typography';
import { useProductCategory } from 'service/brand/product/category';
import { useCurrentProduct } from 'service/brand/product/product';

const ProductCategoryBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
`;

const CategorySelectBlock = styled(Card)<{
  $active: boolean;
  disabled: boolean;
}>`
  border: none;

  .ant-card-head {
    min-height: auto;
    margin-bottom: 16px;
    padding: 0;
    border-bottom: none;

    .ant-card-head-title {
      padding: 0 !important;

      p {
        display: flex;
        align-items: center;
        font-size: 18px;

        .no {
          display: inline-block;
          width: 18px;
          height: 18px;
          margin-right: 8px;
          border-radius: 50%;
          background-color: ${palette.primary};
          color: #fff;
          font-size: 12px;
          text-align: center;
        }
      }
    }
  }

  .ant-card-body {
    height: 664px;
    padding: 4px;
    border: 1px solid ${palette.inactive};
    border-radius: 4px;

    ${({ $active }) =>
      $active &&
      css`
        border-color: ${palette.primary};
        box-shadow: 0 2px 22px 0 rgba(162, 162, 162, 0.23);
      `}

    .ant-list-item {
      cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
      min-height: 44px;
      color: ${palette.text.disabled};
      letter-spacing: -0.5px;

      &.selected {
        background-color: ${({ disabled }) =>
          disabled ? palette.disabled : palette.primaryLight};
        color: ${({ disabled }) => !disabled && palette.text.black};
      }

      &:hover {
        ${({ disabled }) =>
          !disabled &&
          css`
            background-color: ${palette.primaryWeek};
            color: ${palette.text.black};
          `}
      }
      &:last-child {
        border-bottom: 1px solid #f0f0f0;
      }
    }
  }
`;

const ExampleBlock = styled.div`
  width: 500px;
  margin: 56px auto 0;
`;

const CategorySelect = ({
  title,
  categories,
  selectedCategoryId,
  active,
  empty,
  onChange,
  disabled,
}: any) => {
  return (
    <CategorySelectBlock
      title={title}
      size="small"
      $active={active}
      disabled={disabled}
    >
      <List
        size="small"
        dataSource={categories}
        locale={empty}
        renderItem={(category: any) => (
          <List.Item
            className={
              category.cosmeticCategoryId === selectedCategoryId ||
              category.functionalCategoryId === selectedCategoryId
                ? 'selected'
                : ''
            }
            onClick={() =>
              !disabled &&
              onChange(
                category.cosmeticCategoryId || category.functionalCategoryId,
              )
            }
          >
            {category.name}
          </List.Item>
        )}
      />
    </CategorySelectBlock>
  );
};

const ProductCategory = () => {
  const history = useHistory();
  const { productId } = useCurrentProduct();
  const {
    productCategory,
    firstCategories,
    secondCategories,
    functionalCategories,
    isFunctional,
    setIsFunctional,
    selectedFirstCategoryId,
    setSelectedFirstCategoryId,
    selectedSecondCategoryId,
    setSelectedSecondCategoryId,
    selectedFunctionalCategoryId,
    setSelectedFunctionalCategoryId,
    getCategoryLoading,
    categoryExample,
    addProductCategoryLoading,
    onSubmit,
  } = useProductCategory(productId);

  useEffect(() => {
    if (productCategory) {
      setSelectedFirstCategoryId(productCategory.cosmeticCategoryIdDepth1);
      setSelectedSecondCategoryId(productCategory.cosmeticCategoryIdDepth2);
      setIsFunctional(productCategory.isFunctional);
      setSelectedFunctionalCategoryId(productCategory.functionalCategoryId);
    }
  }, [productCategory]);

  useEffect(() => {
    if (!productCategory) {
      setSelectedSecondCategoryId(null);
    }
  }, [selectedFirstCategoryId]);

  useEffect(() => {
    if (!productCategory) {
      setIsFunctional(null);
    }
  }, [selectedSecondCategoryId]);

  useEffect(() => {
    if (!productCategory) {
      setSelectedFunctionalCategoryId(null);
    }
  }, [isFunctional]);

  return (
    <Spin spinning={getCategoryLoading}>
      <ProductCategoryBlock>
        <Row gutter={12} style={{ width: 'calc(100% + 8px)' }} wrap={false}>
          <Col flex="1 1 300px">
            <CategorySelect
              title={
                <Typography.Title type="secondary">
                  <span className="no">1</span>대분류
                </Typography.Title>
              }
              categories={firstCategories}
              selectedCategoryId={selectedFirstCategoryId}
              active={!selectedFirstCategoryId}
              onChange={setSelectedFirstCategoryId}
              disabled={productCategory}
            />
          </Col>
          <Col flex="1 1 300px">
            <CategorySelect
              title={
                <Typography.Title type="secondary">
                  <span className="no">2</span>중분류
                </Typography.Title>
              }
              categories={secondCategories}
              selectedCategoryId={selectedSecondCategoryId}
              active={selectedFirstCategoryId && !selectedSecondCategoryId}
              empty={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="대분류를 선택해 주세요."
                  />
                ),
              }}
              onChange={setSelectedSecondCategoryId}
              disabled={productCategory}
            />
          </Col>
          <Col flex="1 1 300px">
            <CategorySelectBlock
              title={
                <Typography.Title type="secondary">
                  <span className="no">3</span>소분류
                </Typography.Title>
              }
              size="small"
              $active={
                selectedFirstCategoryId !== null &&
                selectedSecondCategoryId !== null &&
                isFunctional === null
              }
              disabled={productCategory !== null}
            >
              <List
                size="small"
                locale={{
                  emptyText: (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="중분류를 선택해 주세요"
                    />
                  ),
                }}
              >
                {selectedSecondCategoryId !== null && (
                  <>
                    <List.Item
                      className={isFunctional === false ? 'selected' : ''}
                      onClick={() => !productCategory && setIsFunctional(false)}
                    >
                      일반 화장품
                    </List.Item>
                    <List.Item
                      className={isFunctional === true ? 'selected' : ''}
                      onClick={() => !productCategory && setIsFunctional(true)}
                    >
                      기능성 화장품
                    </List.Item>
                  </>
                )}
              </List>
            </CategorySelectBlock>
          </Col>
          <Col flex="1 1 300px">
            {isFunctional && (
              <CategorySelect
                title={
                  <Typography.Title type="secondary">
                    <span className="no">4</span>상세분류
                  </Typography.Title>
                }
                categories={functionalCategories}
                selectedCategoryId={selectedFunctionalCategoryId}
                active={isFunctional !== null && !selectedFunctionalCategoryId}
                onChange={setSelectedFunctionalCategoryId}
                disabled={productCategory}
              />
            )}
          </Col>
        </Row>
        {categoryExample && (
          <ExampleBlock>
            <Typography.Title
              type="secondary"
              gutter={{ bottom: 16 }}
              style={{ textAlign: 'center' }}
            >
              카테고리 예시
            </Typography.Title>
            <Typography.Text
              type="secondary"
              style={{
                textAlign: 'center',
                border: `1px solid ${palette.primary}`,
                borderRadius: 4,
                padding: '12px',
              }}
            >
              {categoryExample}
            </Typography.Text>
          </ExampleBlock>
        )}
        <FooterBox>
          <Button
            type="primary"
            loading={addProductCategoryLoading}
            onClick={productCategory ? history.goBack : onSubmit}
          >
            {productCategory ? '뒤로' : '등록'}
          </Button>
        </FooterBox>
      </ProductCategoryBlock>
    </Spin>
  );
};

export default ProductCategory;
