import { useMemo } from 'react';

import CompositionInformation from 'components/material/rawMaterial/compositionInformation/CompositionInformation';
import RawMaterialPageTemplate from 'templates/material/RawMaterialPageTemplate';
import MistakeNoticeItem from 'components/MistakeNoticeItem';

const RawMaterialCompositionInformationPage = () => {
  const notices = useMemo(
    () => [
      '제공되는 엑셀 서식을 다운로드 하여 해당 내용을 입력한 후 파일 업로드해 주세요.',
      '국문 성분명이 없는 경우 비워주세요.',
      '원료 실 제조사로부터 Composition Range만 제공받은 경우, Composition Range는 필수 항목이며 WT%는 범위의 중간값으로 입력해 주세요.',
      'Function은 필수 항목, Function2와 Function3은 선택 항목이므로 추가적인 Function이 있는 경우에 입력해 주세요.',
      <MistakeNoticeItem />,
    ],
    [],
  );

  return (
    <RawMaterialPageTemplate
      documentName="Composition Information"
      notices={notices}
      exampleImg="Composition+Information_V2.png"
      exampleImgWidth={960}
      back
    >
      <CompositionInformation />
    </RawMaterialPageTemplate>
  );
};

export default RawMaterialCompositionInformationPage;
