import { Button, Col, Form, Radio, Row, Spin } from 'antd';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import CorrectRequestMessageModal from 'components/CorrectRequestMessageModal';
import FooterBox from 'components/FooterBox';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import UpdateLog from 'components/product/UpdateLog';
import { useProductKeepWarning } from 'service/brand/product/keepWarning';
import { requireRule } from 'lib/validate';
import { s3AssetDomain } from 'lib/consts';
import { EDocumentCode } from 'types/product';

const ProductKeepWarningBlock = styled.div``;

const RadioWrapper = styled.div`
  width: 280px;
  height: 194px;
  text-align: center;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ProductKeepWarning = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) => {
  const {
    form,
    updateMode,
    getLoading,
    fetchLoading,
    onSubmit,
  } = useProductKeepWarning(productId, countryId);
  const readOnlyMode = useSelector(
    ({ certificate }: any) => certificate.readOnlyMode,
  );
  return (
    <ProductKeepWarningBlock>
      <Row justify="end" gutter={8} style={{ marginBottom: 16 }}>
        <Col>
          <CorrectRequestMessageModal documentCode={EDocumentCode.KW} />
        </Col>
        {updateMode && (
          <Col>
            <UpdateLog
              productId={productId}
              countryId={countryId}
              documentCode={EDocumentCode.KW}
            />
          </Col>
        )}
      </Row>
      <Spin spinning={getLoading}>
        <Form form={form} layout="vertical" onFinish={onSubmit}>
          <Form.Item name="productLine" rules={[requireRule]}>
            <Radio.Group
              disabled={readOnlyMode}
              style={{ justifyContent: 'space-between' }}
            >
              <RadioWrapper>
                <Image src={`${s3AssetDomain}/cap_cover.png`} alt="Image" />
                <Radio value="LID_PRODUCT">캡/뚜껑 있는 제품</Radio>
              </RadioWrapper>
              <RadioWrapper>
                <Image src={`${s3AssetDomain}/maskpack.png`} alt="Image" />
                <Radio value="MASK_PACK">마스크팩</Radio>
              </RadioWrapper>
              <RadioWrapper>
                <Image
                  src={`${s3AssetDomain}/keep_warning_basic.png`}
                  alt="Image"
                />
                <Radio value="NOT_APPLICABLE">해당 없음</Radio>
              </RadioWrapper>
            </Radio.Group>
          </Form.Item>
          <FooterBox style={{ paddingTop: 92 }}>
            <ReadOnlyBackButton readOnly={readOnlyMode}>
              <Button loading={fetchLoading} type="primary" htmlType="submit">
                {!updateMode ? '등록' : '보완 완료'}
              </Button>
            </ReadOnlyBackButton>
          </FooterBox>
        </Form>
      </Spin>
    </ProductKeepWarningBlock>
  );
};

export default ProductKeepWarning;
