import Notarization from 'components/brand/notarization/Notarization';
import PageTemplate from 'templates/PageTemplate';

const notices = [
  '인증에 필요한 공증서류의 양식을 편하게 자동발행하여 받아보실 수 있는 페이지입니다.',
  '바이어에게 제공받은 별도의 양식이 있을 시 해당 양식으로 진행하셔도 되며, 이 페이지에서 발행되는 서류는 30COS에서 국가별 규제에 맞춰 작성해놓은 양식으로 필수로 사용하셔야 하는 서류는 아닙니다.',
];

const NotarizationPage = () => {
  return (
    <PageTemplate title="공증서류 발행" back={false} notices={notices}>
      <Notarization />
    </PageTemplate>
  );
};

export default NotarizationPage;
