export enum ECompanyStatus {
  RDY = 'RDY', // READY
  CFM = 'CFM', // CONFIRM
  REJ = 'REJ', // REJECT
  WIH = 'WIH', // WITHDRAW
  STP = 'STP', // STP
}

export interface ICompany {
  companyNameKo: string;
  companyNameEn?: string;
  representer?: string;
  address?: string;
}

export interface ICompanyBase {
  companyNameKo: string;
  companyNameEn: string;
  isManufacturer: boolean;
  addressKo: string;
  addressEn: string;
  ceoNameKo: string;
  ceoNameEn: string;
  email?: string;
  tel: string;
  fax: string;
  homepageUrl?: string;
  bizNumber: string;
  companyType: ECompanyType;
}

export interface ICompanyGet extends ICompanyBase {
  companyId: number;
  bizLicenseUrl?: string;
  nameplateUrl?: string;
  signatureUrl?: string;
  useSealUrl?: string;
  statusString: string;
  registerDt: Date;
  updateDt: Date;
  rejectReason?: string;
  status: ECompanyStatus;
}

export interface ICompanyRegister extends ICompanyBase {
  isManufacturer: boolean;
  companyNameKo: string;
  companyNameEn: string;
  bizNumber: string;
  addressKo: string;
  addressEn: string;
  ceoNameKo: string;
  ceoNameEn: string;
  tel: string;
  fax: string;
  isServiceTermsAgreed: boolean;
  isPrivacyTermsAgreed: boolean;
  isUseOfLogoAgreed: boolean;
  homepageUrl?: string;
  companyType: ECompanyType;
  bizLicenseFile: File;
  bizLicenseEnFile?: File;
}
export interface ICompanyUpdate extends ICompanyBase {
  companyId: number;
  addressEn: string;
  addressKo: string;
  bizNumber: string;
  homepageUrl?: string;
  isManufacturer: boolean;
  isPrivacyTermsAgreed: boolean;
  isServiceTermsAgreed: boolean;
}

export interface IMaterialCompanyBase extends ICompanyBase {
  companyId: number;
  mainItem: string;
  introduction: string;
}

export enum EBuyerStatus {
  NOR = 'NOR',
  DEL = 'DEL',
}

export enum ECompanySearchType {
  COMPANY_NAME_KO = 'companyNameKo',
  COMPANY_NAME_EN = 'companyNameEn',
  BIZ_NUMBER = 'bizNumber',
  CEO_NAME = 'ceoNameKo',
}

export enum ECompanyType {
  BRAND = 'BRAND',
  MANUFACTURE = 'MANUFACTURE',
  MATERIAL = 'MATERIAL',
}
export interface IMaterialCompanyFactory {
  companyId: number;
  addressKo: string;
  addressEn: string;
  seq: number;
}

export interface IManufactureCompanyFactory {
  companyId: number;
  companyFactoryId: number;
  manufacturerId: number;
  cgmpUploadFile: File;
  iso22716UploadFile: File;
  iso22716ExpireDate: string;
  addressKo: string;
  addressEn: string;
  seq: number;
}

export interface IManufactureCompanyFactoryUpdate
  extends IManufactureCompanyFactory {
  companyFactoryId: number;
  cgmpUploadFileUrl: string;
  iso22716UploadFileUrl: string;
}

export interface ICompanyBanner {
  name: string;
  url: string;
}
