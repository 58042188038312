import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Form, message, Row, Spin } from 'antd';

import { messages } from 'lib/consts';
import {
  addProductStabilityTest,
  getProductStabilityTest,
  updateProductStabilityTest,
  initializeProductStabilityTest,
} from 'modules/product';
import ProductStabilityTest from 'components/product/ProductStabilityTest';
import UpdateLog from 'components/product/UpdateLog';
import CorrectRequestMessageModal from 'components/CorrectRequestMessageModal';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';

const ProductStabilityTestContainer = () => {
  const history = useHistory();
  const params = useParams();
  const { productId } = useCurrentProduct();
  const countryId = useCountryId();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    currentProduct,
    stabilityTest,
    readOnlyMode,
    postLoading,
    updateLoading,
    getLoading,
  } = useSelector(({ product, certificate, loading }) => ({
    currentProduct: product.currentProduct,
    stabilityTest: product.stabilityTest,
    readOnlyMode: certificate.readOnlyMode,
    postLoading: loading['product/ADD_PRODUCT_STABILITY_TEST'],
    updateLoading: loading['product/UPDATE_PRODUCT_STABILITY_TEST'],
    getLoading: loading['product/GET_PRODUCT_STABILITY_TEST'],
  }));
  const updateMode = stabilityTest !== null;
  const onSubmit = (formValues) => {
    if (!updateMode) {
      dispatch(
        addProductStabilityTest({
          productId: currentProduct.productId,
          ...formValues,
        }),
      );
    } else {
      const {
        temperatureRange1,
        temperatureRange2,
        temperatureRange3,
        cycleTemperatureRange,
      } = formValues;
      const updateValues = {
        ...(temperatureRange1 !== stabilityTest.temperatureRange1 && {
          temperatureRange1,
        }),
        ...(temperatureRange2 !== stabilityTest.temperatureRange2 && {
          temperatureRange2,
        }),
        ...(temperatureRange3 !== stabilityTest.temperatureRange3 && {
          temperatureRange3,
        }),
        ...(cycleTemperatureRange !== stabilityTest.cycleTemperatureRange && {
          cycleTemperatureRange,
        }),
      };
      if (Object.keys(updateValues).length === 0)
        return message.warn(messages.NO_NEED_TO_UPDATE);
      dispatch(
        updateProductStabilityTest({
          productStabilityTestId: stabilityTest.productStabilityTestId,
          ...updateValues,
        }),
      );
    }
  };

  useEffect(() => {
    dispatch(
      getProductStabilityTest({
        productId: params.productId,
        countryId: params.countryId,
      }),
    );
    return () => dispatch(initializeProductStabilityTest());
  }, []);
  useEffect(() => {
    if (stabilityTest) {
      form.setFieldsValue(stabilityTest);
    }
  }, [stabilityTest]);
  return (
    <>
      <Row justify="end" gutter={8}>
        <Col>
          <CorrectRequestMessageModal documentCode="stab" />
        </Col>
        {updateMode && (
          <Col>
            <UpdateLog
              productId={productId}
              countryId={countryId}
              documentCode="stab"
            />
          </Col>
        )}
      </Row>
      <Spin spinning={getLoading === true}>
        <ProductStabilityTest
          updateMode={updateMode}
          readOnlyMode={readOnlyMode}
          form={form}
          submitLoading={updateLoading || postLoading}
          onSubmit={onSubmit}
          goBack={history.goBack}
        />
      </Spin>
    </>
  );
};

export default ProductStabilityTestContainer;
