import { Form, message } from 'antd';
import { useCallback, useEffect, useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';

import * as certApi from 'lib/api/certificate';
import * as certificateActions from 'modules/certificate';
import { messages } from 'lib/consts';
import history from 'lib/history';
import {
  IProductKeepWarningBasic,
  IProductKeepWarningFetch,
} from 'types/certificate';
import { useProductDocStatus } from 'service/product';
import { EDocumentCode } from 'types/product';

export const useProductKeepWarning = (productId: number, countryId: number) => {
  const productDocStatus = useProductDocStatus({
    productId,
    countryId,
    documentCode: EDocumentCode.KW,
  });
  const updateMode = productDocStatus
    ? productDocStatus.status !== 'INP'
    : false;
  const { data: productKeepWarning = null, isFetching: getLoading } = useQuery(
    ['keep-warning', productId, countryId],
    () => certApi.getProductKeepWarning({ productId, countryId: countryId! }),
    {
      select: (res) => res.data.result,
      enabled: updateMode,
    },
  );

  const { mutate: addProductKeepWarning, isLoading: addLoading } = useMutation(
    (countryVenderInfoAdd: IProductKeepWarningFetch) =>
      certApi.addProductKeepWarning(countryVenderInfoAdd),
    {
      onSuccess: () => {
        message.success('입력되었습니다.');
        history.goBack();
      },
    },
  );

  const dispatch = useDispatch();
  const {
    mutate: updateProductKeepWarning,
    isLoading: updateLoading,
  } = useMutation(
    (countryVenderInfoUpdate: IProductKeepWarningFetch) =>
      certApi.updateProductKeepWarning(countryVenderInfoUpdate),
    {
      onSuccess: () => {
        dispatch(
          certificateActions.fixDocument(productDocStatus!.productDocStatusId),
        );
        message.success('보완 완료 되었습니다.');
        history.goBack();
      },
    },
  );

  const onSubmit = useCallback(
    (formValue: IProductKeepWarningBasic) => {
      const { productLine } = formValue;
      if (!updateMode) {
        addProductKeepWarning({
          productId: productId!,
          countryId: countryId!,
          productLine,
        });
      } else {
        const updatingObject = {
          ...(productLine !== productKeepWarning?.productLine && {
            productLine,
          }),
        };
        if (typeof updatingObject.productLine === 'undefined') {
          return message.warn(messages.NO_NEED_TO_UPDATE);
        } else {
          updateProductKeepWarning({
            productId: productId!,
            countryId: countryId!,
            productLine: updatingObject.productLine,
          });
        }
      }
    },
    [productKeepWarning, updateMode],
  );

  const [form] = Form.useForm<IProductKeepWarningBasic>();
  useEffect(() => {
    if (productKeepWarning) {
      const { productLine } = productKeepWarning;
      form.setFieldsValue({
        productLine,
      });
    }
  }, [productKeepWarning]);

  return useMemo(
    () => ({
      form,
      updateMode,
      getLoading,
      fetchLoading: addLoading || updateLoading,
      onSubmit,
    }),
    [form, updateMode, getLoading, addLoading, updateLoading, onSubmit],
  );
};
