import { HomeOutlined } from '@ant-design/icons';
import path from 'lib/path';
import { Link } from 'react-router-dom';
import { Result } from '../../../node_modules/antd/lib/index';

const Error404Page = () => {
  return (
    <Result
      status="warning"
      title="404"
      subTitle="요청하신 페이지를 찾을 수 없습니다."
      extra={
        <Link to={path.main}>
          <HomeOutlined /> Home
        </Link>
      }
      style={{ marginTop: '100px' }}
    />
  );
};

export default Error404Page;
