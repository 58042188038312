import RawMaterialDetail from 'components/material/rawMaterial/detail/RawMaterialDetail';
import PageTemplate from 'templates/PageTemplate';

const RawMaterialDetailPage = () => {
  return (
    <PageTemplate
      title="원료 상세 정보"
      description="필요한 원료의 정보를 찾아 열람 가능합니다. (원료사에서 직접 등록한 데이터입니다.)"
      childrenStyle={{ paddingTop: 16 }}
    >
      <RawMaterialDetail />
    </PageTemplate>
  );
};

export default RawMaterialDetailPage;
