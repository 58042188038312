import { Switch, Route } from 'react-router-dom';

import path from 'lib/path';
import ClinicalTrialEstimatePage from 'pages/brand/clinicalTrial/ClinicalTrialEstimatePage';
import PrivateRoute from 'components/PrivateRoute';
import { useSelector } from 'react-redux';
import ClinicalTrialOngoingPage from 'pages/brand/clinicalTrial/ClinicalTrialOngoingPage';
import ClinicalTrialEstimateRequestPage from 'pages/brand/clinicalTrial/ClinicalTrialEstimateRequestPage';

const ClinicalTrialRouter = () => {
  const user = useSelector(({ auth }: any) => auth.user);
  const isLoggedIn = user !== null;

  return (
    <Switch>
      <Route
        path={path.clinicalTrial.estimate.root}
        component={ClinicalTrialEstimatePage}
        exact
      />
      <Route
        path={path.clinicalTrial.estimate.request}
        component={ClinicalTrialEstimateRequestPage}
      />
      <PrivateRoute
        path={path.clinicalTrial.ongoing}
        component={ClinicalTrialOngoingPage}
        check={isLoggedIn}
        redirect={`${path.login}/brand`}
      />
    </Switch>
  );
};

export default ClinicalTrialRouter;
