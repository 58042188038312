import { Form, InputNumber, Radio } from 'antd';

import * as S from './Styled';
import { Typography } from 'components/system';
import { requireRule } from 'lib/validate';

const PHForm = ({
  readOnlyMode,
  onChangePhRadioGroup,
}: {
  readOnlyMode: boolean;
  onChangePhRadioGroup: (isExistedPh: boolean) => void;
}) => {
  return (
    <S.StyledFormItem label="pH" required>
      <Form.Item name="isExistedPh" noStyle initialValue={true}>
        <Radio.Group
          disabled={readOnlyMode}
          onChange={(e) => onChangePhRadioGroup(e.target.value)}
        >
          <Radio value={true}>입력하기</Radio>
          <Radio value={false}>해당 없음</Radio>
        </Radio.Group>
      </Form.Item>
      <S.StyledRow style={{ marginTop: 8 }}>
        <Typography.Text gutter={{ right: 4 }}>정상 범위</Typography.Text>
        <Form.Item
          shouldUpdate={(prev, next) =>
            prev.isExistedPh !== next.isExistedPh ||
            prev.phLimitMax !== next.phLimitMax ||
            prev.phLimitMin !== next.phLimitMin
          }
          noStyle
        >
          {({ getFieldValue }) => {
            const isExistedPh = getFieldValue('isExistedPh');
            const phLimitMax = getFieldValue('phLimitMax');
            const phLimitMin = getFieldValue('phLimitMin');
            return (
              <>
                {isExistedPh ? (
                  <S.AntdExplainWrapper>
                    <Form.Item
                      name="phLimitMin"
                      dependencies={['phLimitMax']}
                      initialValue={5.0}
                      rules={[
                        requireRule,
                        {
                          validator: (_, phLimitMinValue) => {
                            if (phLimitMinValue > phLimitMax) {
                              return Promise.reject(
                                '좌측 정상 범위는 우측 정상 범위 보다 클 수 없음',
                              );
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <InputNumber
                        min={0}
                        max={14}
                        precision={2}
                        step={0.1}
                        disabled={readOnlyMode}
                      />
                    </Form.Item>
                  </S.AntdExplainWrapper>
                ) : (
                  <Form.Item>
                    <InputNumber disabled />
                  </Form.Item>
                )}
                <Typography.Text
                  style={{
                    display: 'inline-block',
                    width: '16px',
                    textAlign: 'center',
                  }}
                >
                  ~
                </Typography.Text>
                {isExistedPh ? (
                  <Form.Item
                    name="phLimitMax"
                    initialValue={10.0}
                    rules={[requireRule]}
                  >
                    <InputNumber
                      min={0}
                      max={14}
                      precision={2}
                      step={0.1}
                      disabled={readOnlyMode}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item>
                    <InputNumber disabled />
                  </Form.Item>
                )}
                <Typography.Text gutter={{ left: 16, right: 4 }}>
                  수치
                </Typography.Text>
                {isExistedPh ? (
                  <Form.Item
                    name="ph"
                    required
                    dependencies={['phLimitMin', 'phLimitMax']}
                    style={{ width: 120 }}
                    rules={[
                      requireRule,
                      {
                        validator: (_, ph) => {
                          if (ph < phLimitMin || ph > phLimitMax) {
                            return Promise.reject('정상 범위를 벗어난 수치');
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <InputNumber
                      precision={2}
                      step={0.1}
                      disabled={readOnlyMode}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item>
                    <InputNumber disabled />
                  </Form.Item>
                )}
              </>
            );
          }}
        </Form.Item>
      </S.StyledRow>
    </S.StyledFormItem>
  );
};

export default PHForm;
