import { Col, Row, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import styled, { css } from 'styled-components';

import { Typography } from 'components/system';
import palette from 'lib/styles/palette';

const StyledStar = styled.span`
  color: ${palette.primary};
`;

const TableWrapper = styled.div<{ safety?: boolean }>`
  margin-top: 8px;
  .ant-table-cell {
    font-size: 14px;
  }

  .ant-table-tbody > tr > td:first-child:not(.ant-table-selection-column) {
    padding-left: 0px;
  }

  ${(props) =>
    props.safety &&
    css`
      .ant-table-tbody > tr:first-child > td:first-child {
        border-right: 1px solid #d8d8d8;
      }
    `}

  .ant-table-tbody > tr > td {
    padding: 10px 4px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    border-bottom: 1px solid #d8d8d8;
  }

  .td > span {
    white-space: break-spaces;
  }
`;

const safetyList = [
  {
    title: '총호기성생균\n(세균 + 진균)',
    category: '영유아용 · 눈화장용',
    limit: '500개 / g(mL) 이하',
  },
  {
    category: '기타 화장품',
    limit: '1,000개 / g(mL) 이하',
  },
  {
    category: '물휴지',
    limit: '100개 /g(mL) 이하',
  },
];

const microList = [
  {
    title: '황색포도상구균(Staphylococcus aureus)',
    result: '불검출',
  },
  {
    title: '대장균(Escherichia coli)',
    result: '불검출',
  },
  {
    title: '녹농균(Pseudomonas aeruginosa)',
    result: '불검출',
  },
];

const ProductMicrobiologicalStandardTable = () => {
  const satefyColumns: ColumnsType<{
    title?: string;
    category: string;
    limit: string;
  }> = [
    {
      title: '검출 항목',
      dataIndex: 'title',
      align: 'center',
      width: 120,
      render: (value, _, index) => {
        let rowSpan = 0;
        if (index === 0) {
          rowSpan = 3;
        }
        const obj = {
          children: (
            <Typography.Text
              type="secondary"
              color="black"
              medium
              style={{ whiteSpace: 'break-spaces' }}
            >
              {value}
            </Typography.Text>
          ),
          props: { rowSpan },
        };

        return obj;
      },
    },
    {
      title: '카테고리',
      dataIndex: 'category',
      align: 'center',
      width: 120,
      render: (category) => (
        <Typography.Text type="secondary" color="gray">
          {category}
        </Typography.Text>
      ),
    },
    {
      title: '검출 허용 한도',
      dataIndex: 'limit',
      align: 'center',
      width: 120,
      render: (category) => (
        <Typography.Text type="secondary" color="gray">
          {category}
        </Typography.Text>
      ),
    },
  ];
  const microColumns: ColumnsType<{ title: string; result: string }> = [
    {
      title: '검출 항목',
      dataIndex: 'title',
      align: 'center',
      width: 210,
      render: (title) => (
        <Typography.Text type="secondary" color="black" medium>
          {title}
        </Typography.Text>
      ),
    },
    {
      title: '결과',
      dataIndex: 'result',
      align: 'center',
      width: 100,
      render: (category) => (
        <Typography.Text type="secondary" color="gray">
          {category}
        </Typography.Text>
      ),
    },
  ];
  return (
    <Row gutter={32} style={{ marginTop: -12 }}>
      <Col style={{ width: '50%' }}>
        <Row>
          <StyledStar>*</StyledStar>
          <Typography.Text type="primary">
            국내 화장품법상 유통화장품안전관리 기준
          </Typography.Text>
        </Row>
        <TableWrapper safety>
          <Table
            columns={satefyColumns}
            pagination={false}
            dataSource={safetyList}
            rowKey={({ category }) => category}
          />
        </TableWrapper>
      </Col>
      <Col style={{ width: '50%' }}>
        <Row>
          <StyledStar>*</StyledStar>
          <Typography.Text type="primary">
            국내 화장품법상 특정 미생물 기준
          </Typography.Text>
        </Row>
        <TableWrapper>
          <Table
            columns={microColumns}
            pagination={false}
            dataSource={microList}
            rowKey={({ title }) => title}
          />
        </TableWrapper>
      </Col>
    </Row>
  );
};

export default ProductMicrobiologicalStandardTable;
