import palette from 'lib/styles/palette';
import styled from 'styled-components';

const InfoIconBlock = styled.i`
  color: ${palette.primary};
`;

const InfoIcon = ({ style }: { style?: React.CSSProperties }) => {
  return (
    <InfoIconBlock className="material-icons md-18" style={style}>
      info
    </InfoIconBlock>
  );
};

export default InfoIcon;
