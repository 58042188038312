import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf';

import { fileToBase64 } from 'lib/file';
import Typography from 'components/system/general/Typography';
import palette from 'lib/styles/palette';

const FileViewBlock = styled.div`
  width: 100%;

  .ant-card-head {
    height: 54px;
    margin: 0;

    .ant-card-head-title {
      font-size: 14px;
      padding: 16px 0;
    }
  }

  .ant-card-body {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    min-height: 166px;
    overflow: hidden;
  }
`;

const PreviewHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 8px;
`;

const Navigator = styled.div`
  display: flex;
  align-items: center;

  .material-icons {
    height: 20px;
  }
`;

const ViewSectionBlock = styled.div`
  padding: 16px;
  border: 1px solid ${palette.inactive};
  overflow-y: auto;
`;

const StyledImg = styled.img`
  width: 100%;
`;

const UnknownFileFormat = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
interface IUploadedFile {
  url: string;
  name: string;
}

const ViewSection = ({
  file,
  pageNumber,
  encodedFile,
  onLoadSuccess,
}: {
  file?: File | string | IUploadedFile | null;
  pageNumber: number;
  encodedFile?: string;
  onLoadSuccess: ({ numbers }: any) => void;
}) => {
  if (file) {
    if (
      (typeof file === 'string' && file.endsWith('.pdf')) ||
      (file instanceof File && file.type === 'application/pdf')
    ) {
      return (
        <Document
          file={file}
          onLoadSuccess={onLoadSuccess}
          onLoadError={console.error}
        >
          <Page pageNumber={pageNumber} scale={2} />
        </Document>
      );
    }
    if (typeof file === 'string') {
      return <StyledImg src={file} alt="fileImage" />;
    }
    if ('url' in file && file.url) {
      if (file.url.endsWith('.pdf')) {
        return (
          <Document
            file={file.url}
            onLoadSuccess={onLoadSuccess}
            onLoadError={console.error}
          >
            <Page pageNumber={pageNumber} scale={2} />
          </Document>
        );
      }
      if (/(jpe?g)|(png)|(gif)|(bmp)$/.test(file.url)) {
        return <StyledImg src={file.url} alt="fileImage" />;
      }
    }
    if (encodedFile) {
      return <StyledImg src={encodedFile} alt="fileImage" />;
    }
    return (
      <UnknownFileFormat>
        <Typography.Text type="secondary">
          미리보기가 지원되지 않는 파일 형식입니다.
        </Typography.Text>
      </UnknownFileFormat>
    );
  }
  return null;
};

const FileViewer = ({
  title = '미리보기',
  file,
  style,
  bodyStyle,
}: {
  title?: string;
  height?: string;
  file?: File | string | IUploadedFile | null;
  style?: React.CSSProperties;
  bodyStyle?: React.CSSProperties;
}) => {
  const [encodedFile, setEncodedFile] = useState();
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const onLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  useEffect(() => {
    if (file && file instanceof File && /image\//.test(file.type)) {
      const encodeFile = async () => {
        setEncodedFile(await fileToBase64(file));
      };
      encodeFile();
    }
  }, [file]);

  const checkIsFileReadableInPDFViewer = (
    file: File | string | IUploadedFile | null,
  ) =>
    typeof file === 'string' ||
    (file && 'url' in file && file.url) ||
    (file instanceof File && file.type === 'application/pdf');

  return (
    <FileViewBlock style={style}>
      <PreviewHeader>
        <Typography.Text type="secondary">{title}</Typography.Text>
        {file && checkIsFileReadableInPDFViewer(file) && numPages > 1 && (
          <Navigator>
            <i
              className={`material-icons  ${
                pageNumber === 1 ? 'gray disabled' : 'cursor'
              }`}
              onClick={() => pageNumber > 1 && setPageNumber(pageNumber - 1)}
            >
              chevron_left
            </i>
            <span style={{ padding: '0 8px', position: 'relative', top: 2 }}>
              {pageNumber} / {numPages}
            </span>
            <i
              className={`material-icons  ${
                pageNumber === numPages ? 'gray disabled' : 'cursor'
              }`}
              onClick={() =>
                pageNumber < numPages && setPageNumber(pageNumber + 1)
              }
            >
              chevron_right
            </i>
          </Navigator>
        )}
      </PreviewHeader>
      <ViewSectionBlock style={bodyStyle}>
        <ViewSection
          file={file}
          pageNumber={pageNumber}
          encodedFile={encodedFile}
          onLoadSuccess={onLoadSuccess}
        />
      </ViewSectionBlock>
    </FileViewBlock>
  );
};

export default FileViewer;
