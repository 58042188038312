import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Row, Col, Drawer, Divider, Badge } from 'antd';
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';

import TopSubMenuContainer from 'containers/menu/TopSubMenuContainer';
import UserNoticeDrawerContainer from 'containers/service/UserNoticeDrawerContainer';
import { deviceSize, size } from 'lib/styles';
import MaterialTopMenu from './material/menu/TopMenu';
import ManufactureTopMenu from './manufacturer/menu/TopMenu';
import { ECompanyType } from 'types/company';
import BrandTopMenu from './menu/BrandTopMenu';
import Logo from './system/general/icon/Logo';

const NavigatorBlock = styled.div`
  max-width: ${size.layout.navigatorMax};
  margin: 0 auto;
`;

const CollapsableCol = styled(Col)<{
  collapsedsize: 'sm' | 'md';
  position: 'relative' | 'static';
}>`
  position: ${({ position }) => position};
  ${({ collapsedsize }) => css`
    @media ${deviceSize[collapsedsize]} {
      display: none;
    }
  `}
`;

const DrawerHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Navigator = ({
  drawerVisible,
  newUserNoticeCount,
  companyType,
  onToggleDrawer,
  onClickUserNotice,
}: {
  drawerVisible: boolean;
  newUserNoticeCount: number;
  companyType: ECompanyType;
  onToggleDrawer: () => void;
  onClickUserNotice: () => void;
}) => {
  const renderTopMenu = (inlineMode = false) => {
    switch (companyType) {
      case ECompanyType.MANUFACTURE:
        return <ManufactureTopMenu inlineMode={inlineMode} />;
      case ECompanyType.MATERIAL:
        return <MaterialTopMenu inlineMode={inlineMode} />;
      default:
        return <BrandTopMenu inlineMode={inlineMode} />;
    }
  };
  return (
    <NavigatorBlock>
      <Row
        align="middle"
        justify="space-between"
        wrap={false}
        style={{
          height: 80,
          maxWidth: '1088px',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Col>
          <Link to="/">
            <Logo />
          </Link>
        </Col>
        <CollapsableCol flex="1 1 100%" position="static" collapsedsize="sm">
          {renderTopMenu()}
        </CollapsableCol>
        <CollapsableCol flex="0 0 auto" collapsedsize="sm" position="relative">
          <TopSubMenuContainer />
        </CollapsableCol>
        <CollapsableCol collapsedsize="md" position="relative">
          <Row gutter={30}>
            <Col>
              <Badge dot={newUserNoticeCount > 0} style={{ top: -4 }}>
                <i
                  className="material-icons outlined"
                  onClick={onClickUserNotice}
                  style={{ position: 'relative', top: -8, cursor: 'pointer' }}
                >
                  notifications
                </i>
              </Badge>
            </Col>
            <Col>
              <MenuOutlined
                style={{ fontSize: 20, padding: 4 }}
                onClick={onToggleDrawer}
              />
            </Col>
          </Row>
        </CollapsableCol>
      </Row>
      <Drawer
        placement="right"
        closable={false}
        visible={drawerVisible}
        maskClosable
        bodyStyle={{ padding: 12 }}
        onClose={onToggleDrawer}
      >
        <DrawerHeader onClick={onToggleDrawer}>
          <CloseOutlined style={{ fontSize: '16px', cursor: 'pointer' }} />
        </DrawerHeader>
        <Divider style={{ margin: '12px -12px', width: 265 }} />
        {renderTopMenu(true)}
        <TopSubMenuContainer inlineMode />
      </Drawer>
      <UserNoticeDrawerContainer />
    </NavigatorBlock>
  );
};

export default Navigator;
