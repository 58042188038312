import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Select, Space, Table, Row, Col } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import palette from 'lib/styles/palette';
import SearchInput from 'components/system/form/SearchInput';
import Typography from 'components/system/general/Typography';
import ProgressModal from 'components/ui/Modal/ProgressModal';
import FileListModal from 'components/ui/Modal/FileListModal';
import {
  EClinicalTrialProcessItemStatus,
  IClinicalTrialProcessItem,
  IClinicalTrialProcessReport,
} from 'types/brand/clinicalTrial/process';
import {
  useClinicalTrialProcessItems,
  useClinicalTrialProcessReports,
} from 'service/brand/clinicalTrial/process';

const Container = styled.div`
  margin-top: 24px;
  .ant-table-thead > tr > th {
    padding: 10px 8px;
  }
  .ant-table-tbody > tr > td {
    padding: 10px 8px;
  }
`;
const searchTypeOptions = [
  { label: '전체', value: 'ALL' },
  { label: '대기중', value: EClinicalTrialProcessItemStatus.RDY },
  {
    label: '시험 진행중',
    value: EClinicalTrialProcessItemStatus.REV,
  },
  {
    label: '시험 완료',
    value: EClinicalTrialProcessItemStatus.COM,
  },
  {
    label: '중단',
    value: EClinicalTrialProcessItemStatus.STP,
  },
];

const ClinicalTrialOngoing = () => {
  const [searchType, setSearchType] = useState<
    EClinicalTrialProcessItemStatus | 'ALL'
  >('ALL');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [page, setPage] = useState(1);
  const getStatusTitle = useCallback(
    (status: EClinicalTrialProcessItemStatus) => {
      switch (status) {
        case EClinicalTrialProcessItemStatus.RDY:
          return '대기중';
        case EClinicalTrialProcessItemStatus.REV ||
          EClinicalTrialProcessItemStatus.DLV:
          return '시험 진행 중';
        case EClinicalTrialProcessItemStatus.COM:
          return '완료';
        case EClinicalTrialProcessItemStatus.STP:
          return '중단';
        default:
          return '-';
      }
    },
    [],
  );
  const handleChangeSelect = (
    value: EClinicalTrialProcessItemStatus | 'ALL',
  ) => {
    setSearchType(value);
    setSearchKeyword('');
  };
  const [
    ongoingItemStatus,
    setOngoingItemStatus,
  ] = useState<EClinicalTrialProcessItemStatus | null>(null);
  const [reportFiles, setReportFiles] = useState<
    IClinicalTrialProcessReport[] | null
  >(null);
  const {
    clinicalTrialProcessItems,
    totalElements,
  } = useClinicalTrialProcessItems({
    page,
    processStatus: searchType,
    keyword: searchKeyword,
  });
  const { getClinicalTrialProcessReports } = useClinicalTrialProcessReports();
  const handleClickReport = (clinicalTrialProcessItemId: number) => {
    getClinicalTrialProcessReports(clinicalTrialProcessItemId, {
      onSuccess: (res) => setReportFiles(res.data.result),
    });
  };

  const columns: ColumnsType<IClinicalTrialProcessItem> = useMemo(
    () => [
      {
        title: '견적서번호',
        width: 116,
        align: 'center',
        render: (_, { estimateNo }) => (
          <Row style={{ flexDirection: 'column', width: 100 }} align="middle">
            <Typography.Text medium type="secondary" color="black">
              {estimateNo}
            </Typography.Text>
            <Typography.Text type="secondary" color="gray">
              {`(${estimateNo.slice(0, 10)})`}
            </Typography.Text>
          </Row>
        ),
      },
      {
        title: '제품명',
        width: 196,
        render: (_, { productName }) => (
          <Typography.Text
            type="secondary"
            style={{
              width: 180,
              fontWeight: 500,
              color: palette.text.black,
            }}
          >
            {productName || '-'}
          </Typography.Text>
        ),
      },
      {
        title: '시험항목',
        width: 176,
        render: (_, { clinicalTrialName }) => (
          <Typography.Text
            type="secondary"
            color="gray"
            style={{
              width: 160,
            }}
          >
            {clinicalTrialName}
          </Typography.Text>
        ),
      },
      {
        title: '시험 기관',
        with: 176,
        render: (_, { agencyName }) => (
          <Typography.Text
            type="secondary"
            color="gray"
            style={{
              width: 120,
            }}
          >
            {agencyName}
          </Typography.Text>
        ),
      },
      {
        title: '진행 단계',
        width: 88,
        align: 'center',
        render: (_, { status }) => (
          <Typography.Text
            type="secondary"
            style={{
              width: 72,
              ...(status !== EClinicalTrialProcessItemStatus.STP && {
                cursor: 'pointer',
              }),
              fontWeight: 500,
            }}
            color={
              status !== EClinicalTrialProcessItemStatus.STP
                ? 'black'
                : 'disabled'
            }
            onClick={
              status !== EClinicalTrialProcessItemStatus.STP
                ? () =>
                    setOngoingItemStatus(
                      status === EClinicalTrialProcessItemStatus.DLV
                        ? EClinicalTrialProcessItemStatus.REV
                        : status,
                    )
                : undefined
            }
          >
            {getStatusTitle(status)}
          </Typography.Text>
        ),
      },
      {
        title: '입금/계약서',
        width: 104,
        align: 'center',
        render: (_, { depositFinish, receiveContractFinish }) => (
          <Row
            style={{ flexDirection: 'column' }}
            align="middle"
            gutter={[4, 4]}
          >
            <Col>
              <Typography.Label
                type={depositFinish ? 'success' : 'disabled'}
                style={{ width: 88 }}
              >
                {depositFinish ? '입금 확인 완료' : '입금 확인 중'}
              </Typography.Label>
            </Col>
            <Col>
              <Typography.Label
                type={receiveContractFinish ? 'success' : 'disabled'}
                style={{ width: 88 }}
              >
                {receiveContractFinish ? '계약서 수령 완료' : '계약서 수령 전'}
              </Typography.Label>
            </Col>
          </Row>
        ),
      },
      {
        title: '시험 레포트',
        width: 100,
        align: 'center',
        render: (_, { existsReport, clinicalTrialProcessItemId }) => {
          return existsReport ? (
            <i
              className="material-icons outlined black md-24 cursor"
              onClick={() => handleClickReport(clinicalTrialProcessItemId)}
            >
              description
            </i>
          ) : (
            <Typography.Text
              type="secondary"
              color="gray"
              style={{
                width: 60,
              }}
            >
              -
            </Typography.Text>
          );
        },
      },
    ],
    [getStatusTitle, setOngoingItemStatus],
  );

  return (
    <Container>
      {ongoingItemStatus && (
        <ProgressModal
          title="임상시험 진행 단계 안내"
          steps={[
            {
              code: EClinicalTrialProcessItemStatus.RDY,
              title: '대기중',
              content: '해당 제품의 접수가 완료되었습니다.',
            },
            {
              code: EClinicalTrialProcessItemStatus.REV,
              title: '시험 진행 중',
              content: '해당 제품의 임상시험이 진행 중에 있습니다.',
            },
            {
              code: EClinicalTrialProcessItemStatus.COM,
              title: '시험 완료',
              content: '시험 완료 : 해당 제품의 임상시험이 완료되었습니다.',
            },
          ]}
          currentStepCode={ongoingItemStatus}
          onClose={() => setOngoingItemStatus(null)}
        />
      )}
      {reportFiles && (
        <FileListModal
          title="시험 레포트"
          files={reportFiles}
          onClose={() => setReportFiles(null)}
        />
      )}
      <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
        <Select
          options={searchTypeOptions}
          value={searchType}
          onChange={handleChangeSelect}
          style={{ width: 140 }}
        />
        <SearchInput
          placeholder="제품명"
          defaultValue={searchKeyword}
          onSearch={setSearchKeyword}
          style={{ width: 400 }}
        />
      </Space>
      <Table
        columns={columns}
        dataSource={clinicalTrialProcessItems}
        rowKey="clinicalTrialProcessItemId"
        pagination={{
          current: page,
          onChange: setPage,
          total: totalElements,
        }}
        style={{ marginTop: 24 }}
      />
    </Container>
  );
};

export default ClinicalTrialOngoing;
