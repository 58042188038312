import styled from 'styled-components';
import { Button } from 'antd';
import React from 'react';
import palette from 'lib/styles/palette';

const StyledButton = styled(Button)`
  margin-top: 8px;
  height: 56px;
  width: 100%;
  color: #fff;
  background-color: ${palette.slate};
  border-color: ${palette.slate};
  font-size: 16px;

  :hover {
    background-color: ${palette.slate};
  }
`;

interface ISlateButton extends React.ComponentProps<typeof Button> {
  children: React.ReactNode;
}

const SlateButton = ({ children, ...props }: ISlateButton) => {
  return <StyledButton {...props}>{children}</StyledButton>;
};

export default SlateButton;
