import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as estimateActions from 'modules/estimate';
import PageTemplate from 'templates/PageTemplate';
import EstimateViewer from 'components/estimate/EstimateViewer';
import EstimateContract from 'components/estimate/EstimateContract';
import EstimatePayment from 'components/estimate/EstimatePayment';

const EstimateContractPage = () => {
  const dispatch = useDispatch();
  const contractStep = useSelector(({ estimate }) => estimate.contractStep);

  useEffect(
    () => () => {
      dispatch(estimateActions.initializeEstimate());
      dispatch(estimateActions.setContractStep('viewer'));
    },
    [],
  );
  return (
    <PageTemplate
      back={false}
      title={contractStep !== 'payment' ? '계약 체결' : undefined}
      description={
        contractStep === 'viewer'
          ? '아래 견적서를 확인 후 계약을 진행해 보세요.'
          : contractStep === 'contract'
          ? '아래 내용을 확인 후 계약을 체결해 주세요.'
          : undefined
      }
    >
      {contractStep === 'viewer' && <EstimateViewer />}
      {contractStep === 'contract' && <EstimateContract />}
      {contractStep === 'payment' && <EstimatePayment />}
    </PageTemplate>
  );
};
export default EstimateContractPage;
