import { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { message, Modal } from 'antd';
import { useDispatch } from 'react-redux';

import * as productApi from 'lib/api/product';
import * as certificateApi from 'lib/api/certificate';
import {
  EDocumentCode,
  EProductSearchType,
  EProductStatus,
  IProduct,
  IProductFormulaBreakdownDraft,
  IProductFormulaBreakdownItem,
} from 'types/product';
import history from 'lib/history';
import { Typography } from 'components/system';
import * as productActions from 'modules/product';
import path from 'lib/path';

export const useProducts = ({
  status = null,
  size = 10,
  page,
  searchType,
  searchKeyword,
}: {
  status?: EProductStatus | null;
  page: number;
  size?: number;
  searchType: EProductSearchType;
  searchKeyword: string;
}) => {
  const {
    data: { content: products, totalElements } = {
      content: [],
      totalElements: 0,
    },
    isFetching: getLoading,
    refetch,
  } = useQuery(
    ['product/products', page, searchType, searchKeyword],
    () =>
      productApi.getProducts({
        page,
        size,
        searchType,
        searchKeyword,
        status,
      }),
    { select: (res) => res.data.result },
  );
  const { mutate: deleteProductMutation } = useMutation(
    (productId: number) => productApi.deleteProducts([productId]),
    {
      onSuccess: () => {
        refetch();
      },
    },
  );
  const getProductIsCertificated = useGetProductIsCertificated();
  const deleteProduct = async (productId: number) => {
    const isCertificated = await getProductIsCertificated(productId);
    if (!isCertificated) {
      if (status === EProductStatus.PRD_RDY) {
        Modal.confirm({
          icon: null,
          content: (
            <Typography.Text type="secondary" align="center">
              해당 리스트에서 제품을 삭제할 경우
              <br /> [국내 입점 서류 관리] 메뉴에
              <br /> 등록된 제품도 함께 삭제됩니다.
              <br /> 정말로 삭제하시겠습니까?
            </Typography.Text>
          ),
          onOk: () => {
            deleteProductMutation(productId);
          },
        });
      } else {
        const product = products.find(
          (product) => product.productId === productId,
        )!;
        Modal.confirm({
          title: '다음 품목의 제품 정보가 완전히 삭제됩니다.',
          closable: true,
          content: (
            <Typography.Text align="center">
              {product.productDetail.productNameEn} /{' '}
              {product.productCountries![0].country.countryNameKo}
              {product.productCountries!.length > 1
                ? ` 외 ${product.productCountries!.length - 1}개 국가`
                : ''}
            </Typography.Text>
          ),
          icon: null,
          onOk: () => {
            deleteProductMutation(productId);
          },
        });
      }
    } else {
      Modal.info({
        icon: null,
        closable: true,
        maskClosable: true,
        content: (
          <Typography.Text type="secondary" style={{ textAlign: 'center' }}>
            인증 진행 혹은 인증 완료 제품은 삭제하실 수 없습니다.
            <br />
            (단, 수정은 가능합니다.)
          </Typography.Text>
        ),
      });
    }
  };

  const dispatch = useDispatch();

  const showProduct = useCallback((product: IProduct) => {
    const { productId } = product;
    // 정책 : 코드로 발행한 제품 중 기본정보가 입력 완료가 아닌 경우 기본 정보 입력 페이지로 바로 이동
    if (!product.productDetail.productNameEn) {
      history.push(`/product/${productId}/${EDocumentCode.BASIC}`);
      return;
    }
    // 정책 : 중복된 제품의 카테고리가 입력전이면 카테고리 등록 페이지로 바로 이동
    if (!product.productCategory) {
      dispatch(productActions.setSkipMode(true));
      dispatch(productActions.setNextPathname('cat'));
      history.push(`/product/${productId}/${EDocumentCode.CAT}`);
      return;
    }
    // 정책 : 중복된 제품의 국가가 입력전이면 국가 등록 페이지로 바로 이동
    if (!product.productCountries) {
      dispatch(productActions.setSkipMode(true));
      dispatch(productActions.setNextPathname('country'));
      history.push(`/product/${productId}/${EDocumentCode.COUNTRY}`);
      return;
    }
    history.push(`${path.product.root}/${product.productId}`);
  }, []);

  return useMemo(
    () => ({
      products,
      totalElements,
      getLoading,
      deleteProduct,
      showProduct,
    }),
    [products, totalElements, getLoading, deleteProduct, showProduct],
  );
};

export const useProductDetail = ({
  productId,
  countryId,
}: {
  productId?: number;
  countryId?: number;
}) => {
  const { data: productDetail = null, isLoading } = useQuery(
    ['product/getProductDetail', productId, countryId],
    () => productApi.getProductDetail({ productId: productId!, countryId }),
    {
      select: (res) => res.data.result,
      enabled: typeof productId !== 'undefined',
    },
  );

  return useMemo(() => ({ productDetail, isLoading }), [
    productDetail,
    isLoading,
  ]);
};

export const useGetProductIsCertificated = () => {
  const { mutateAsync: getProductIsCertificated } = useMutation(
    async (productId: number) => {
      const res = await productApi.getProductIsCertificated(productId);
      return res.data.result;
    },
  );

  return getProductIsCertificated;
};

export const useProductDocStatus = ({
  productId,
  countryId,
  documentCode,
}: {
  productId?: number;
  countryId?: number;
  documentCode?: EDocumentCode;
}) => {
  const { data: productDocStatus = null } = useQuery(
    ['product/docStatus', productId, countryId, documentCode],
    () =>
      productApi.getProductDocStatus({
        productId: productId!,
        countryId,
        documentCode: documentCode!,
      }),
    {
      select: (res) => res.data.result,
      suspense: true,
      enabled:
        typeof productId !== 'undefined' && typeof documentCode !== 'undefined',
    },
  );

  return productDocStatus;
};

const calculateFormulaBreakdownRowSpan = (
  formulaBreakdownItems: IProductFormulaBreakdownItem[],
) => {
  const targetKeys: (keyof IProductFormulaBreakdownItem)[] = [
    'no',
    'formulaWt',
  ];
  let rowSpanAcc = 1;
  targetKeys.forEach((targetKey) => {
    for (let i = formulaBreakdownItems.length - 1; i >= 0; i--) {
      if (formulaBreakdownItems[i][targetKey] === null) {
        rowSpanAcc++;
      } else {
        switch (targetKey) {
          case 'no': {
            formulaBreakdownItems[i].noRowSpan = rowSpanAcc;
            break;
          }
          case 'formulaWt': {
            formulaBreakdownItems[i].formulaWtRowSpan = rowSpanAcc;
            break;
          }
        }
        rowSpanAcc = 1;
      }
    }
  });
};

export const useProductFormulaBreakdown = (
  isChina: boolean,
  productId: number,
  countryId?: number,
) => {
  const {
    data: formulaBreakdownItems = [],
    isFetching: getLoading,
    refetch,
  } = useQuery(
    ['product/getProductFormulaBreakdown', isChina],
    () =>
      productApi[
        !isChina
          ? 'getProductFormulaBreakdown'
          : 'getProductFormulaBreakdownChina'
      ](productId, countryId),
    {
      select: (res) => {
        calculateFormulaBreakdownRowSpan(res.data.result);
        return res.data.result;
      },
    },
  );

  const [
    productFormulaBreakdownDraft,
    setProductFormulaBreakdownDraft,
  ] = useState<IProductFormulaBreakdownDraft | null>(null);

  const {
    mutate: uploadProductFormulaBreakdown,
    isLoading: uploadLoading,
  } = useMutation(
    productApi[
      !isChina
        ? 'uploadProductFormulaBreakdown'
        : 'uploadProductFormulaBreakdownChina'
    ],
    {
      onSuccess: (res) => {
        calculateFormulaBreakdownRowSpan(res.data.result.list);
        setProductFormulaBreakdownDraft(res.data.result);
        if (
          !res.data.result.isWtSumError &&
          !res.data.result.isSubstanceSumError &&
          res.data.result.uploadedExcelUrl === null
        ) {
          message.success('정상적으로 업로드 되었습니다.');
          refetch();
        } else {
          message.warning('업로드한 파일에 오류가 있습니다.');
        }
      },
      onError: () => {
        setProductFormulaBreakdownDraft(null);
      },
    },
  );

  const {
    mutate: fixProductFormulaBreakdown,
    isLoading: fixLoading,
  } = useMutation(
    (productDocStatusId: number) =>
      certificateApi.fixDocument(productDocStatusId),
    {
      onSuccess: () => {
        message.success('보완 완료 되었습니다.');
        history.goBack();
      },
    },
  );

  return useMemo(
    () => ({
      formulaBreakdownItems,
      getLoading,
      uploadProductFormulaBreakdown,
      uploadLoading,
      productFormulaBreakdownDraft,
      fixProductFormulaBreakdown,
      fixLoading,
    }),
    [
      formulaBreakdownItems,
      getLoading,
      uploadLoading,
      productFormulaBreakdownDraft,
      fixLoading,
    ],
  );
};
