import { Button } from 'antd';
import { useHistory } from 'react-router-dom';

const ReadOnlyBackButton = ({
  readOnly,
  children,
}: {
  readOnly: boolean;
  children?: React.ReactNode;
}) => {
  const history = useHistory();
  if (readOnly) {
    return (
      <Button type="primary" onClick={() => history.goBack()}>
        뒤로
      </Button>
    );
  }

  return children ? <>{children}</> : null;
};

export default ReadOnlyBackButton;
