import { ISavedFile } from 'types/common';
import { EClinicalTrialType } from './clinicalTrial';

export enum EClinicalTrialProcessItemStatus {
  RDY = 'RDY', // 진행
  REV = 'REV', // 시료 수령 (시험 진행중)
  DLV = 'DLV', // 시료 발송 (시험 진행중)
  STP = 'STP', // 중단
  COM = 'COM', // 완료 (시험 완료))
}
export interface IClinicalTrialProcessItem {
  clinicalTrialProcessItemId: number;
  clinicalTrialEstimateId: number;
  estimateNo: string;
  productName: string | null;
  clinicalTrialItemName: string;
  clinicalTrialId: number;
  clinicalTrialType: EClinicalTrialType;
  clinicalTrialName: string;
  clinicalTrialAgencyId: number;
  agencyName: string;
  status: EClinicalTrialProcessItemStatus;
  depositFinish: boolean;
  receiveContractFinish: boolean;
  existsReport: boolean;
}

export interface IClinicalTrialProcessReport extends ISavedFile {
  clinicalTrialProcessReportId: number;
  clinicalTrialProcessItemId: number;
  registerDt: string;
}
