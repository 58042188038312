import client from 'lib/api/client';
import { IChecklistAnswer, IChecklistItem } from 'types/brand/checklist';
import { IAPIResponse } from 'types/common';

export const getChecklist = (countryId: number) =>
  client.get<IAPIResponse<IChecklistItem[]>>(`/checklist/${countryId}`);
export const answerChecklist = (body: {
  estimateTargetId: number;
  checklistAnswers: {
    countryChecklistId: number;
    isYes?: boolean;
    subjectAnswer?: string;
  }[];
}) =>
  client.post(
    `/checklist/answer/${body.estimateTargetId}`,
    body.checklistAnswers,
  );
export const getChecklistAnswers = (params: {
  productId: number;
  countryId: number;
}) =>
  client.get<IAPIResponse<IChecklistAnswer[]>>(
    `/checklist/answer/${params.productId}/${params.countryId}`,
  );
