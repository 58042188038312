import { useQuery } from 'react-query';

import * as brandCompanyApi from 'lib/api/brand/company';

export const useBrandCompanies = (brandCompaniesParams?: {
  companyNameKo?: string;
  isExcludeUserInserted?: boolean;
}) => {
  return useQuery(
    ['pub/brand-companies', brandCompaniesParams],
    () => brandCompanyApi.getBrandCompanies(brandCompaniesParams),
    {
      select: (res) => res.data.result,
      staleTime: Number.MAX_VALUE,
    },
  );
};
