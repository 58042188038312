import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Col, Row, Typography } from 'antd';
import { SwapRightOutlined } from '@ant-design/icons';

import path from 'lib/path';
import { deviceSize } from 'lib/styles';
import palette from 'lib/styles/palette';
import { Margin } from 'components/system/general/Margin';

const PromiseItemBlock = styled.div`
  width: 330px;
  background-color: #fff;
  padding: 64px 64px 32px;

  @media ${deviceSize.sm} {
    width: 240px;
    padding: 32px 24px 24px;
  }

  img {
    display: block;
    width: 115px;
    margin: 0 auto;

    @media ${deviceSize.sm} {
      width: 80px;
    }
  }

  h5.ant-typography {
    margin-top: 16px;
    text-align: center;
    font-size: 20px;

    @media ${deviceSize.sm} {
      font-size: 16px;
    }
  }

  div.ant-typography {
    @media ${deviceSize.sm} {
      font-size: 12px;
    }
  }
`;

const PromiseItem = ({
  imageUrl,
  title,
  description,
}: {
  imageUrl: string;
  title: string;
  description: string;
}) => {
  return (
    <PromiseItemBlock>
      <img src={imageUrl} alt="" />
      <Typography.Title level={5}>{title}</Typography.Title>
      <Typography.Paragraph
        style={{ whiteSpace: 'pre-line', textAlign: 'center' }}
      >
        {description}
      </Typography.Paragraph>
    </PromiseItemBlock>
  );
};

const StepBlock = styled.div`
  .ant-typography {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    margin: 0;

    @media ${deviceSize.sm} {
      font-size: 12px;
    }
  }
`;

const StyledDot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #666;
  margin: 16px auto;
  background-color: #fff;

  &::after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background-color: ${palette.primary};
    border-radius: 50%;
  }
`;

const Step = ({ step, title }: { step: number; title: string }) => {
  return (
    <StepBlock>
      <Typography.Paragraph>STEP {step}</Typography.Paragraph>
      <StyledDot />
      <Typography.Paragraph>{title}</Typography.Paragraph>
    </StepBlock>
  );
};

const IntroPageBlock = styled.div`
  margin: -80px auto !important;
  max-width: 1464px !important;

  @media ${deviceSize.sm} {
    width: calc(100% + 32px) !important;
    margin: -40px -16px !important;
  }

  h1.ant-typography {
    font-size: 48px;
    font-family: 'Aharoni', sans-serif !important;
    font-weight: 700;
    text-align: center;

    @media ${deviceSize.sm} {
      font-size: 36px;
    }
  }
`;

const PromiseBlock = styled.div`
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)
    ),
    url('https://cdn.imweb.me/thumbnail/20200901/db739ce568f68.jpg');
  background-attachment: fixed;
  background-repeat: no-repeat, no-repeat;
  background-position: center, top center;
  background-size: 100%, 3000px;
  padding: 120px 32px;

  @media ${deviceSize.sm} {
    padding: 40px 16px;
  }
`;

const ProcessBlock = styled.div`
  padding: 120px 32px;

  @media ${deviceSize.sm} {
    padding: 40px 16px;
  }
`;

const StyledLine = styled.div`
  position: absolute;
  top: calc(50% + 16px);
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: calc(100% - 40px);
  border-top: 1px solid #666;
`;

const ProfessionalBlock = styled.div`
  position: relative;
  padding: 120px 32px;
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)
    ),
    url('https://cdn.imweb.me/thumbnail/20200901/fdb31665751d4.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media ${deviceSize.sm} {
    padding: 40px 16px;
  }

  .description {
    color: #fff;
    font-size: 18px;
    text-align: center;

    @media ${deviceSize.sm} {
      font-size: 14px;
    }
  }
`;

const IntroPage = () => {
  return (
    <IntroPageBlock>
      <PromiseBlock>
        <Typography.Title style={{ color: '#fff' }}>Promise</Typography.Title>
        <Row
          justify="space-around"
          style={{
            maxWidth: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: 32,
          }}
          gutter={[32, 32]}
        >
          <Col>
            <PromiseItem
              imageUrl="https://cdn.imweb.me/upload/S20200722cdd2b1c4e123c/049087bc6bbe7.jpg"
              title="COMMUNICATION"
              description={
                '30COS와 협업하는 제조사와\n현지 Agency 등 커뮤니케이션 대행'
              }
            />
          </Col>
          <Col>
            <PromiseItem
              imageUrl="https://cdn.imweb.me/upload/S20200722cdd2b1c4e123c/7756e68fa4108.jpg"
              title="COMMUNICATION"
              description={
                '누구나 손쉽게 사용할 수 있는\n인증 프로그램으로 30개국 인증'
              }
            />
          </Col>
          <Col>
            <PromiseItem
              imageUrl="https://cdn.imweb.me/upload/S20200722cdd2b1c4e123c/049087bc6bbe7.jpg"
              title="CONSULTANT"
              description={'국가별로 각기 다른 법규와\n환경을 반영한 전문성'}
            />
          </Col>
        </Row>
      </PromiseBlock>
      <ProcessBlock>
        <Typography.Title>Process</Typography.Title>
        <Row
          justify="space-between"
          style={{
            boxSizing: 'content-box',
            position: 'relative',
            maxWidth: '800px',
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: 32,
          }}
        >
          <StyledLine />
          <Col>
            <Step step={1} title="문의" />
          </Col>
          <Col>
            <Step step={2} title="상담" />
          </Col>
          <Col>
            <Step step={3} title="프로그램 경험" />
          </Col>
          <Col>
            <Step step={4} title="계약 체결" />
          </Col>
        </Row>
      </ProcessBlock>
      <ProfessionalBlock>
        <Typography.Title style={{ color: '#fff' }}>
          Professional
        </Typography.Title>
        <Typography.Paragraph className="description">
          인증 전문가의 노하우를 담은 프로그램을 만나보세요
        </Typography.Paragraph>
        <Row justify="center" align="middle">
          <Link
            to={path.register.type}
            style={{
              display: 'inline-block',
              textAlign: 'center',
              padding: '16px 0',
              width: 200,
              marginTop: 32,
              marginLeft: 'auto',
              marginRight: 'auto',
              border: '1px solid #fff',
              fontSize: 16,
            }}
          >
            <Typography.Text style={{ color: '#fff' }}>
              회원 가입 신청
            </Typography.Text>
            <SwapRightOutlined
              style={{
                position: 'relative',
                top: 6,
                marginLeft: 8,
                transform: 'translateY(-50%) scale(1.5, 1)',
                color: '#fff',
              }}
            />
          </Link>
        </Row>
      </ProfessionalBlock>
      <Margin size={80} />
    </IntroPageBlock>
  );
};

export default IntroPage;
