import { Modal, message } from 'antd';
import { useCallback, useMemo, useState } from 'react';

import { useMutation } from 'react-query';

import * as notarizationApi from 'lib/api/notarization';
import { IBuyer, IProductBuyer } from 'types/brand/buyer';
import { ENotarizationDocumentCode } from 'types/brand/notarization';
import LoAModalContent from 'components/brand/notarization/LoAModalContent';
import StyleNoModalContent from 'components/brand/notarization/StyleNoModalContent';
import { IProductBasic } from 'types/product';
import ProductCategoryModalContent from 'components/brand/notarization/ProductCategoryModalContent';

const defaultModalOption = {
  icon: null,
  closable: true,
  okButtonProps: { hidden: true },
};

export const usePublishNotarization = () => {
  const [publishedDocumentUrl, setPublishedDocumentUrl] = useState<
    string | string[] | null
  >(null);
  const [
    loadingDocumentCode,
    setLoadingDocumentCode,
  ] = useState<ENotarizationDocumentCode | null>(null);
  const publishNotarization = useCallback(
    ({
      documentCode,
      buyer,
      productBuyers,
    }: {
      buyer: IBuyer;
      documentCode: ENotarizationDocumentCode;
      productBuyers: IProductBuyer[];
    }) => {
      switch (documentCode) {
        case ENotarizationDocumentCode.LOA: {
          publishLoa({
            countryId: buyer.countryId,
            buyerId: buyer.buyerId,
            productIds: productBuyers.map(({ productId }) => productId),
          });
          break;
        }
        case ENotarizationDocumentCode.SC:
        case ENotarizationDocumentCode.BSEFL: {
          publishBseSc({
            countryId: buyer.countryId,
            docType: documentCode.charAt(0) as 'B' | 'S',
            products: productBuyers.map(({ product }) => product),
          });
          break;
        }
        case ENotarizationDocumentCode.LTU: {
          publishLtu({
            countryId: buyer.countryId,
            productIds: productBuyers.map(({ productId }) => productId),
          });
          break;
        }
        case ENotarizationDocumentCode.RL: {
          publishRl({
            countryId: buyer.countryId,
            products: productBuyers.map(({ product }) => product),
          });
          break;
        }
        default: {
          publishAut({
            countryId: buyer.countryId,
            buyerId: buyer.buyerId,
            productIds: productBuyers.map(({ productId }) => productId),
          });
        }
      }
    },
    [],
  );

  const { mutate: publishLoaMutate } = useMutation(
    (params: Parameters<typeof notarizationApi.publishLoa>[0]) =>
      notarizationApi.publishLoa(params),
    {
      onMutate: () => {
        setLoadingDocumentCode(ENotarizationDocumentCode.LOA);
      },
      onSuccess: (res) => {
        setPublishedDocumentUrl(res.data.result);
      },
      onError: ({ message: errorMessage }: { message: string }) => {
        message.warn(errorMessage);
      },
      onSettled: () => {
        setLoadingDocumentCode(null);
      },
    },
  );

  const publishLoa = ({
    countryId,
    buyerId,
    productIds,
  }: Omit<
    Parameters<typeof notarizationApi.publishLoa>[0],
    'loaExpireYears'
  >) => {
    const modal = Modal.info({
      ...defaultModalOption,
      content: (
        <LoAModalContent
          onSubmit={(loaExpireYears: string) => {
            publishLoaMutate({
              countryId,
              buyerId,
              productIds,
              loaExpireYears,
            });
            modal.destroy();
          }}
        />
      ),
    });
  };

  const { mutate: publishBseScMutate } = useMutation(
    (params: Parameters<typeof notarizationApi.publishBseSc>[0]) =>
      notarizationApi.publishBseSc(params),
    {
      onMutate: ({ docType }) => {
        setLoadingDocumentCode(
          docType === 'B'
            ? ENotarizationDocumentCode.BSEFL
            : ENotarizationDocumentCode.SC,
        );
      },
      onSuccess: (res) => {
        setPublishedDocumentUrl(res.data.result);
      },
      onError: ({ message: errorMessage }: { message: string }) => {
        message.warn(errorMessage);
      },
      onSettled: () => {
        setLoadingDocumentCode(null);
      },
    },
  );

  const publishBseSc = ({
    countryId,
    docType,
    products,
  }: Pick<
    Parameters<typeof notarizationApi.publishBseSc>[0],
    'countryId' | 'docType'
  > & { products: IProductBasic[] }) => {
    const modal = Modal.info({
      ...defaultModalOption,
      width: 800,
      content: (
        <StyleNoModalContent
          products={products}
          onSubmit={(productCodes: string[]) => {
            publishBseScMutate({
              countryId,
              docType,
              productIds: products.map(({ productId }) => productId),
              productCodes,
            });
            modal.destroy();
          }}
        />
      ),
    });
  };

  const { mutate: publishLtu } = useMutation(
    (params: Parameters<typeof notarizationApi.publishLtu>[0]) =>
      notarizationApi.publishLtu(params),
    {
      onMutate: () => {
        setLoadingDocumentCode(ENotarizationDocumentCode.LTU);
      },
      onSuccess: (res) => {
        setPublishedDocumentUrl(res.data.result);
      },
      onError: ({ message: errorMessage }: { message: string }) => {
        message.warn(errorMessage);
      },
      onSettled: () => {
        setLoadingDocumentCode(null);
      },
    },
  );

  const { mutate: publishRlMutate } = useMutation(
    (params: Parameters<typeof notarizationApi.publishRl>[0]) =>
      notarizationApi.publishRl(params),
    {
      onMutate: () => {
        setLoadingDocumentCode(ENotarizationDocumentCode.RL);
      },
      onSuccess: (res) => {
        setPublishedDocumentUrl(res.data.result);
      },
      onError: ({ message: errorMessage }: { message: string }) => {
        message.warn(errorMessage);
      },
      onSettled: () => {
        setLoadingDocumentCode(null);
      },
    },
  );

  const publishRl = ({
    countryId,
    products,
  }: {
    countryId: number;
    products: IProductBasic[];
  }) => {
    const modal = Modal.info({
      ...defaultModalOption,
      width: 800,
      content: (
        <ProductCategoryModalContent
          products={products}
          onSubmit={(productCategories: string[]) => {
            publishRlMutate({
              countryId,
              productIds: products.map(({ productId }) => productId),
              productCategories,
            });
            modal.destroy();
          }}
        />
      ),
    });
  };

  const { mutate: publishAut } = useMutation(
    (params: Parameters<typeof notarizationApi.publishAut>[0]) =>
      notarizationApi.publishAut(params),
    {
      onMutate: () => {
        setLoadingDocumentCode(ENotarizationDocumentCode.A);
      },
      onSuccess: (res) => {
        setPublishedDocumentUrl(res.data.result);
      },
      onError: ({ message: errorMessage }: { message: string }) => {
        message.warn(errorMessage);
      },
      onSettled: () => {
        setLoadingDocumentCode(null);
      },
    },
  );

  return useMemo(
    () => ({
      publishNotarization,
      publishedDocumentUrl,
      initPublishedDocumentUrl: () => setPublishedDocumentUrl(null),
      loadingDocumentCode,
    }),
    [publishNotarization, publishedDocumentUrl, loadingDocumentCode],
  );
};
