import { useSelector } from 'react-redux';
import { Button, Col, Row } from 'antd';

import { downloadFile } from 'lib/file';
import { contractDocumentKeyNameMap } from 'lib/consts';
import { Typography } from 'components/system';

const ContractDocuments = () => {
  const contractDocuments = useSelector(
    ({ estimate }: any) => estimate.contractDocuments,
  );
  return (
    <>
      <Typography.Title gutter={{ bottom: 16 }}>서류 다운로드</Typography.Title>
      <Row gutter={16}>
        {contractDocuments.map(({ fileKey, filename, attachUrl }: any) => (
          <Col flex="0 0 20%" key={fileKey}>
            <Button
              block
              icon={
                <i className="material-icons outlined md-18 primary">
                  file_download
                </i>
              }
              onClick={() => downloadFile(attachUrl, filename)}
            >
              {
                contractDocumentKeyNameMap[
                  fileKey as keyof typeof contractDocumentKeyNameMap
                ]
              }
            </Button>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default ContractDocuments;
