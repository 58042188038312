import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, message, Row } from 'antd';

import {
  addProductArtwork,
  getProductArtworks,
  initializeArtwork,
  updateProductArtwork,
} from 'modules/product';
import { messages } from 'lib/consts';
import ProductArtwork from 'components/product/ProductArtwork';
import UpdateLog from 'components/product/UpdateLog';
import CorrectRequestMessageModal from 'components/CorrectRequestMessageModal';
import { useCountryId } from 'service/brand/product/product';
import history from 'lib/history';

const ProductArtworkContainer = () => {
  const dispatch = useDispatch();
  const {
    currentProduct,
    savedArtworks,
    readOnlyMode,
    fetchLoading,
    updateLoading,
  } = useSelector(({ product, certificate, loading }) => ({
    currentProduct: product.currentProduct,
    savedArtworks: product.artwork,
    readOnlyMode: certificate.readOnlyMode,
    fetchLoading: loading['product/ADD_PRODUCT_ARTWORK'],
    updateLoading: loading['product/UPDATE_PRODUCT_ARTWORK'],
  }));
  const updateMode = savedArtworks.length > 0;
  const [artworks, setArtworks] = useState([]);
  const [deletedArtworkIds, setDeletedArtworkIds] = useState([]);

  const onUpload = (file) => {
    if (artworks.find((artwork) => artwork.name === file.name)) {
      message.warning(messages.DUPLICATE_FILE_NAME);
      return false;
    }
    setArtworks((draft) => draft.concat(file));
    return true;
  };

  const onDeleteArtwork = (artwork) => {
    if (artwork.url) {
      setDeletedArtworkIds((draft) => [...draft, artwork.productArtworkId]);
    }
    setArtworks((draft) => draft.filter((item) => item !== artwork));
  };

  const productId = currentProduct.productId;
  const countryId = useCountryId();

  const onSubmit = () => {
    if (artworks.length === 0) {
      message.warning(messages.REQUIRE_ONE_OR_MORE_FILES);
      return;
    }

    const newArtworks = artworks.filter((artwork) => !artwork.url);
    if (!newArtworks.length && !deletedArtworkIds.length) {
      message.warning(messages.NO_NEED_TO_UPDATE);
      return;
    }

    if (!updateMode) {
      dispatch(
        addProductArtwork({
          productId: currentProduct.productId,
          documentFiles: newArtworks,
        }),
      );
    } else {
      dispatch(
        updateProductArtwork({
          productId,
          countryId,
          newArtworks,
          deletedArtworkIds,
        }),
      );
    }
  };

  useEffect(() => {
    dispatch(
      getProductArtworks({
        productId,
        countryId,
      }),
    );
    return () => {
      dispatch(initializeArtwork());
    };
  }, []);

  useEffect(() => {
    if (savedArtworks.length) {
      setArtworks(savedArtworks);
    }
  }, [savedArtworks]);

  return (
    <>
      <Row justify="end" gutter={8}>
        <Col>
          <CorrectRequestMessageModal documentCode="art" />
        </Col>
        {updateMode && (
          <Col>
            <UpdateLog
              productId={productId}
              countryId={countryId}
              documentCode="art"
            />
          </Col>
        )}
      </Row>
      <ProductArtwork
        updateMode={updateMode}
        readOnlyMode={readOnlyMode}
        artworks={artworks}
        onUpload={onUpload}
        fetchLoading={fetchLoading || updateLoading}
        onDeleteArtwork={onDeleteArtwork}
        onSubmit={onSubmit}
        goBack={history.goBack}
      />
    </>
  );
};

export default ProductArtworkContainer;
