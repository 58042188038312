import styled from 'styled-components';
import { Col, Row, Spin } from 'antd';

import { useMain } from 'service/main';
import history from 'lib/history';
import path from 'lib/path';
import Typography from 'components/system/general/Typography';
import CompanyStatsBlock from 'components/main/CompanyStatsBlock';
import CompanyStat from 'components/main/CompanyStat';
import Shortcut from 'components/main/Shortcut';

const MainPageBlock = styled.div`
  padding-top: 40px;
  max-width: 1024px !important;
`;

const MainPage = () => {
  const { companyName, stats, getStatsLoading } = useMain();
  const { productOnCount = 0, productFinCount = 0 } = { ...stats };

  return (
    <MainPageBlock>
      <Row gutter={[16, 32]} justify="center">
        <Col>
          <CompanyStatsBlock>
            <Typography.Headline
              type="secondary"
              style={{ textAlign: 'center' }}
            >
              안녕하세요. <br className="mobile-br" />
              <mark>{companyName}</mark> 님!
              <br /> 화장품 산업 발전 연구소 30COS 입니다.
            </Typography.Headline>
            <Spin spinning={getStatsLoading}>
              <Row gutter={16} style={{ marginTop: 52 }} justify="center">
                <Col>
                  <CompanyStat
                    amount={productOnCount}
                    opacity={0.33}
                    label="등록 중 원료"
                    onClick={() => history.push(path.material.manage.ready)}
                  />
                </Col>
                <Col>
                  <CompanyStat
                    amount={productFinCount}
                    opacity={0.66}
                    label="등록 완료 원료"
                    onClick={() => history.push(path.material.manage.done)}
                  />
                </Col>
                {/* <Col>
                  <CompanyStat
                    amount={brandCompanyAuthenticated}
                    opacity={1}
                    label="자료 열람 요청"
                    onClick={() => history.push(path.material.read)}
                  />
                </Col> */}
              </Row>
            </Spin>
          </CompanyStatsBlock>
        </Col>
      </Row>
      <Typography.Title
        gutter={{ top: 64, bottom: 16 }}
        style={{ textAlign: 'center' }}
      >
        자주 찾는 메뉴
      </Typography.Title>
      <Row gutter={[16, 16]} style={{ maxWidth: 'none' }} justify="center">
        <Col xs={{ span: 12 }} sm={{ span: 6 }}>
          <Shortcut
            icon={<i className="material-icons outlined primary">new_label</i>}
            label="원료 등록"
            description="지금 바로 원료를 등록해 보세요."
            onClick={() => history.push(`${path.material.rawMaterial.basic}`)}
          />
        </Col>
        <Col xs={{ span: 12 }} sm={{ span: 6 }}>
          <Shortcut
            icon={<i className="material-icons outlined primary">science</i>}
            label="등록 원료 관리"
            description="등록된 원료 정보들을 확인하고 관리해보세요."
            onClick={() => history.push(path.material.manage.done)}
          />
        </Col>
        {/* <Col xs={{ span: 12 }} sm={{ span: 6 }}>
          <Shortcut
            icon={
              <i className="material-icons outlined primary">folder_open</i>
            }
            label="자료 열람 승인"
            description="열람 요청이 온 자료를 승인하여 제조사에게 전달해 보세요."
            onClick={() => history.push(path.material.read)}
          />
        </Col> */}
      </Row>
    </MainPageBlock>
  );
};

export default MainPage;
