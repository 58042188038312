import { Form, Button, Input, message } from 'antd';
import styled from 'styled-components';
import { useEffect, useState } from 'react';

import { Typography } from 'components/system';
import palette from 'lib/styles/palette';
import RequestSuccess from './RequestSuccess';
import {
  emailRule,
  koreanOrAlphabetRule,
  numberOrKoreanRule,
  phoneRule,
  requireRule,
} from 'lib/validate';
import {
  IClinicalTrialEstimateItem,
  IRequesterFormValues,
} from 'types/brand/clinicalTrial/estimate';
import { useRequestEstimate } from 'service/brand/clinicalTrial/estimate';

const Container = styled.div`
  width: 422px;

  input {
    border: none;
  }
`;

const RequestTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
`;

const FormContainer = styled.div`
  width: 400px !important;
  margin: 0 auto;

  counter-reset: no;
  ${RequestTitle} {
    &::before {
      display: inline-block;
      counter-increment: no;
      content: counter(no);
      background-color: ${palette.primary};
      color: #fff;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      text-align: center;
      padding-right: 1px;
      font-size: 12px;
    }
  }

  input.ant-input:not(.ant-transfer-list-search) {
    background-color: ${palette.slategray10};
  }

  .ant-form-item {
    margin-bottom: 32px;
  }

  .ant-form-item.ant-form-item-with-help {
    margin-bottom: 16px !important;
  }
`;

const RequesterForm = ({
  user,
  items,
}: {
  user: any;
  items: IClinicalTrialEstimateItem[];
}) => {
  const isLoggedIn = user !== null;
  const { requestEstimate } = useRequestEstimate();
  const [isRequestSuccess, setIsRequestSuccess] = useState(false);
  const request = (requesterFormValues: IRequesterFormValues) => {
    const clinicalTrialEstimateBasketRequests = items
      .filter(({ isCheck }) => isCheck)
      .map(({ categoryDataId, clinicalTrialId, itemQuantity, isCheck }) => ({
        categoryDataId,
        clinicalTrialId,
        itemQuantity,
        isCheck,
      }));
    requestEstimate(
      {
        ...(isLoggedIn && { clientCompanyId: user.companyId }),
        ...(isLoggedIn && { userId: user.userId }),
        ...requesterFormValues,
        clinicalTrialEstimateBasketRequests,
      },
      {
        onSuccess: () => {
          message.success('견적 요청이 완료되었습니다.');
          setIsRequestSuccess(true);
        },
      },
    );
  };

  const [form] = Form.useForm<IRequesterFormValues>();
  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        clientCompanyName: user.company.companyNameKo,
        clientEmail: user.email,
        clientTel: user.mobile,
        clientName: user.name,
      });
    }
  }, [user]);

  return (
    <Container>
      {!isRequestSuccess ? (
        <FormContainer>
          <Form form={form} onFinish={request}>
            <Typography.Headline type="secondary" gutter={{ bottom: 40 }}>
              의뢰자 정보
            </Typography.Headline>
            <RequestTitle>
              <Typography.Title inline>회사명</Typography.Title>
            </RequestTitle>
            <Form.Item
              name="clientCompanyName"
              rules={[requireRule, numberOrKoreanRule]}
              normalize={(next, prev) => {
                if (isLoggedIn) {
                  message.warn('수정할 수 없는 정보입니다.');
                  return prev;
                }
                return next;
              }}
            >
              <Input
                maxLength={20}
                placeholder="회사명 입력"
                onFocus={(e) => {
                  if (isLoggedIn) {
                    message.warn('수정할 수 없는 정보입니다.');
                  }
                }}
              />
            </Form.Item>
            <RequestTitle>
              <Typography.Title inline>성함</Typography.Title>
            </RequestTitle>
            <Form.Item
              name="clientName"
              rules={[requireRule, koreanOrAlphabetRule]}
              normalize={(next, prev) => {
                if (isLoggedIn) {
                  message.warn('수정할 수 없는 정보입니다.');
                  return prev;
                }
                return next;
              }}
            >
              <Input
                maxLength={30}
                placeholder="성함 입력"
                onFocus={(e) => {
                  if (isLoggedIn) {
                    message.warn('수정할 수 없는 정보입니다.');
                  }
                }}
              />
            </Form.Item>
            <RequestTitle>
              <Typography.Title inline>연락처</Typography.Title>
            </RequestTitle>
            <Form.Item
              name="clientTel"
              rules={[requireRule, phoneRule]}
              normalize={(next, prev) => {
                if (isLoggedIn) {
                  message.warn('수정할 수 없는 정보입니다.');
                  return prev;
                }
                return next;
              }}
            >
              <Input
                placeholder="연락처 입력"
                onFocus={(e) => {
                  if (isLoggedIn) {
                    message.warn('수정할 수 없는 정보입니다.');
                  }
                }}
              />
            </Form.Item>
            <RequestTitle>
              <Typography.Title inline>이메일</Typography.Title>
            </RequestTitle>
            <Form.Item
              name="clientEmail"
              rules={[requireRule, emailRule]}
              normalize={(next, prev) => {
                if (isLoggedIn) {
                  message.warn('수정할 수 없는 정보입니다.');
                  return prev;
                }
                return next;
              }}
            >
              <Input
                placeholder="견적이 전달되므로 실제 사용중인 메일주소를 입력"
                onFocus={(e) => {
                  if (isLoggedIn) {
                    message.warn('수정할 수 없는 정보입니다.');
                  }
                }}
              />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: '100%', height: 56, fontSize: 16 }}
            >
              입력 완료
            </Button>
          </Form>
        </FormContainer>
      ) : (
        <RequestSuccess />
      )}
    </Container>
  );
};

export default RequesterForm;
