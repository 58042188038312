import { useState, useRef } from 'react';
import styled from 'styled-components';
import { Button, Col, Form, Input, Row, Select } from 'antd';

import * as authApi from 'lib/api/auth';
import { messages } from 'lib/consts';
import {
  exceptKoreanRule,
  phoneRule,
  requireRule,
  urlRule,
} from 'lib/validate';
import Typography from 'components/system/general/Typography';
import { Tip, FileUpload } from 'components/system';
import palette from 'lib/styles/palette';
import { ECompanyType } from 'types/company';

const ManuBlockBlock = styled.div<{ $visible: boolean }>`
  display: ${({ $visible }) => ($visible ? 'block' : 'none')};
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 8px;

  &.ant-form-item-with-help {
    margin-bottom: 0;
  }
`;

const ManuForm = ({
  visible,
  form,
  updateMode,
  manufacturers,
  firstCGMP,
  secondCGMP,
  thirdCGMP,
  originalSecondFactory,
  originalThirdFactory,
  onClickAttachCGMP,
  bizLicenseEnUrl,
}: any) => {
  const [isBizNumberCheckSuccess, setIsBizNumberCheckSuccess] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const handleSelectSearch = (value: string) => {
    setSearchValue(value.replace(/\s/gi, ''));
  };
  const inputRef = useRef<Input | null>(null);
  return (
    <ManuBlockBlock $visible={visible}>
      <Typography.Title gutter={{ bottom: 16 }}>1. 회사 정보</Typography.Title>
      <Form.Item
        shouldUpdate={(prev, next) => prev.companyNameKo !== next.companyNameKo}
        label={
          <Row gutter={8} align="middle">
            <Col>회사명 (국문/영문)</Col>
            <Col>
              <Tip bodyStyle={{ width: 306 }} trigger="click">
                <Typography.Text type="quaternary">
                  사업자등록증의 사명과 동일하게 입력하되, 띄어쓰기 없이 입력해
                  <br /> 주세요. 중복 데이터 또는 오인 표기를 줄이기 위함이므로,
                </Typography.Text>
                <Typography.Text type="quaternary" color="primary">
                  회사명에 띄어쓰기가 입력되지 않아도 무방합니다.
                </Typography.Text>
              </Tip>
            </Col>
          </Row>
        }
        style={{ marginBottom: 0 }}
        required
      >
        {({ getFieldValue, setFieldsValue }) => (
          <Row gutter={8} wrap={false}>
            <Col flex="auto">
              <Form.Item
                name="companyNameKo"
                rules={[requireRule]}
                style={{ marginBottom: 0 }}
              >
                <Select
                  showSearch
                  searchValue={searchValue}
                  onSearch={handleSelectSearch}
                  disabled={updateMode}
                  placeholder="국문 회사명 검색 또는 선택"
                  notFoundContent={
                    getFieldValue('companyNameKo') !== 'companyNameKoDirect' ? (
                      <Typography.Text
                        style={{ cursor: 'pointer' }}
                        color="black"
                        type="secondary"
                        onClick={() =>
                          setFieldsValue({
                            companyNameKo: 'companyNameKoDirect',
                          })
                        }
                      >
                        직접입력
                      </Typography.Text>
                    ) : null
                  }
                  onSelect={(value) => {
                    if (value === 'companyNameKoDirect') {
                      setTimeout(() => inputRef?.current?.focus());
                    }
                  }}
                >
                  <Select.Option
                    key="companyNameKoDirect"
                    value="companyNameKoDirect"
                  >
                    직접입력
                  </Select.Option>
                  {manufacturers.map((manufacturer: any) => (
                    <Select.Option
                      key={manufacturer.manufacturerId}
                      value={manufacturer.companyNameKo}
                    >
                      {manufacturer.companyNameKo}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col flex="0 0 50%">
              <Form.Item
                name={
                  getFieldValue('companyNameKo') !== 'companyNameKoDirect'
                    ? 'companyNameKo'
                    : 'companyNameKoDirect'
                }
                normalize={(value) => value.replace(/\s/gi, '')}
                rules={[requireRule]}
                style={{ marginBottom: 8 }}
              >
                <Input
                  ref={inputRef}
                  disabled={
                    getFieldValue('companyNameKo') !== 'companyNameKoDirect' ||
                    updateMode
                  }
                  placeholder={
                    getFieldValue('companyNameKo') === 'companyNameKoDirect'
                      ? '국문 회사명 여기에 입력'
                      : undefined
                  }
                  style={
                    !updateMode
                      ? {
                          color: palette.text.black,
                          backgroundColor: '#fff',
                        }
                      : undefined
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form.Item>
      <Form.Item name="companyNameEn" rules={[requireRule, exceptKoreanRule]}>
        <Input placeholder="영문 회사명" disabled={updateMode} />
      </Form.Item>
      <Form.Item
        label="사업자등록번호"
        validateStatus={isBizNumberCheckSuccess ? 'success' : undefined}
        help={
          isBizNumberCheckSuccess ? '사용 가능한 사업자등록번호' : undefined
        }
        name="bizNumber"
        normalize={(value) => {
          if (value.length > 12) return value.substring(0, 12);
          if (/[^0-9-]/.test(value)) return value.replace(/[^0-9-]/, '');
          if (value.slice(-1) === '-') return value;
          return value
            .replace(/[-]/g, '')
            .replace(/^(\d{3})(\d{1,})/, '$1-$2')
            .replace(/^(\d{3}-\d{2})(\d{1,})/, '$1-$2');
        }}
        rules={[
          requireRule,
          {
            pattern: /^\d{3}-\d{2}-\d{5}$/,
            message: '올바르지 않은 사업자등록번호 형식',
          },
          {
            validator: async (_, bizNumber) => {
              if (updateMode || !/^\d{3}-\d{2}-\d{5}$/.test(bizNumber)) {
                return;
              }
              try {
                await authApi.bizNumberDupCheck({
                  bizNumber,
                  companyType: ECompanyType.MANUFACTURE,
                });
                setIsBizNumberCheckSuccess(true);
              } catch (e) {
                setIsBizNumberCheckSuccess(false);
                throw new Error('이미 가입되어 있는 사업자등록번호');
              }
              return;
            },
          },
        ]}
      >
        <Input
          disabled={updateMode}
          autoComplete="off"
          onChange={() => setIsBizNumberCheckSuccess(false)}
        />
      </Form.Item>
      <StyledFormItem
        label="제 1공장 소재지 (국문/영문)"
        name="firstAddressKo"
        rules={[requireRule]}
      >
        <Input placeholder="국문" />
      </StyledFormItem>
      <StyledFormItem
        name="firstAddressEn"
        rules={[requireRule, exceptKoreanRule]}
      >
        <Input placeholder="영문(보유하신 cGMP(ISO22716) 과 동일한 영문으로 입력)" />
      </StyledFormItem>
      {form.getFieldValue('firstAddressKo') &&
        form.getFieldValue('firstAddressEn') && (
          <Row gutter={8}>
            <Col>
              <Form.Item
                required
                name="firstCGMP"
                rules={[
                  {
                    validator: () => {
                      if (!firstCGMP.file) {
                        return Promise.reject(
                          new Error(messages.REQUIRED_FIELD),
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Button
                  icon={<i className="material-icons">file_upload</i>}
                  onClick={() => onClickAttachCGMP(firstCGMP)}
                >
                  제 1공장 cGMP (ISO22716) 첨부하기
                </Button>
              </Form.Item>
            </Col>
            {firstCGMP.file && (
              <Col style={{ paddingTop: 12 }}>
                <Typography.Text inline>{firstCGMP.file.name}</Typography.Text>
              </Col>
            )}
          </Row>
        )}
      <StyledFormItem
        label="제 2공장 소재지 (국문/영문)"
        name="secondAddressKo"
        rules={[
          originalSecondFactory
            ? requireRule
            : {
                validateTrigger: 'onSubmit',
                validator: (_, value) => {
                  if (!value && form.getFieldValue('secondAddressEn')) {
                    return Promise.reject(
                      new Error(
                        '현재 항목을 입력하시거나 [제 2공장 소재지 (영문)] 항목을 비워주세요. (일부 항목만 입력 불가능)',
                      ),
                    );
                  }
                  return Promise.resolve();
                },
              },
        ]}
      >
        <Input placeholder="국문" />
      </StyledFormItem>
      <StyledFormItem
        name="secondAddressEn"
        rules={[
          exceptKoreanRule,
          originalSecondFactory
            ? requireRule
            : {
                validateTrigger: 'onSubmit',
                validator: (_, value) => {
                  if (!value && form.getFieldValue('secondAddressKo')) {
                    return Promise.reject(
                      new Error(
                        '현재 항목을 입력하시거나 [제 2공장 소재지 (국문)] 항목을 비워주세요. (일부 항목만 입력 불가능)',
                      ),
                    );
                  }
                  return Promise.resolve();
                },
              },
        ]}
      >
        <Input placeholder="영문 (보유하신 cGMP(ISO22716) 과 동일한 영문으로 입력)" />
      </StyledFormItem>
      {form.getFieldValue('secondAddressKo') &&
        form.getFieldValue('secondAddressEn') && (
          <Row gutter={8}>
            <Col>
              <Form.Item
                name="secondAttachButton"
                rules={[
                  () => ({
                    validateTrigger: 'onSubmit',
                    validator: () => {
                      if (
                        form.getFieldValue('secondAddressKo') &&
                        form.getFieldValue('secondAddressEn') &&
                        !secondCGMP.file
                      ) {
                        return Promise.reject(
                          new Error(
                            '현재 항목을 입력하시거나 [제 2공장 소재지 (국문), 제 2공장 소재지 (영문)] 항목을 비워주세요. (일부 항목만 입력 불가능)',
                          ),
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Button
                  icon={<i className="material-icons">file_upload</i>}
                  onClick={() => onClickAttachCGMP(secondCGMP)}
                >
                  제 2공장 cGMP (ISO22716) 첨부하기
                </Button>
              </Form.Item>
            </Col>
            {secondCGMP.file && (
              <Col style={{ paddingTop: 12 }}>
                <Typography.Text inline>{secondCGMP.file.name}</Typography.Text>
              </Col>
            )}
          </Row>
        )}
      <StyledFormItem
        label="제 3공장 소재지 (국문/영문)"
        name="thirdAddressKo"
        rules={[
          originalThirdFactory
            ? requireRule
            : {
                validateTrigger: 'onSubmit',
                validator: (_, value) => {
                  if (!value && form.getFieldValue('thirdAddressEn')) {
                    return Promise.reject(
                      new Error(
                        '현재 항목을 입력하시거나 [제 3공장 소재지 (영문)] 항목을 비워주세요. (일부 항목만 입력 불가능)',
                      ),
                    );
                  }
                  return Promise.resolve();
                },
              },
        ]}
      >
        <Input placeholder="국문" />
      </StyledFormItem>
      <StyledFormItem
        name="thirdAddressEn"
        rules={[
          exceptKoreanRule,
          originalThirdFactory
            ? requireRule
            : {
                validateTrigger: 'onSubmit',
                validator: (_, value) => {
                  if (!value && form.getFieldValue('thirdAddressKo')) {
                    return Promise.reject(
                      new Error(
                        '현재 항목을 입력하시거나 [제 3공장 소재지 (국문)] 항목을 비워주세요. (일부 항목만 입력 불가능)',
                      ),
                    );
                  }
                  return Promise.resolve();
                },
              },
        ]}
      >
        <Input placeholder="영문 (보유하신 cGMP(ISO22716) 과 동일한 영문으로 입력)" />
      </StyledFormItem>
      {form.getFieldValue('thirdAddressKo') &&
        form.getFieldValue('thirdAddressEn') && (
          <Row gutter={8}>
            <Col>
              <Form.Item
                name="thirdAttachButton"
                rules={[
                  {
                    validator: () => {
                      if (
                        form.getFieldValue('thirdAddressKo') &&
                        form.getFieldValue('thirdAddressEn') &&
                        !thirdCGMP.file
                      ) {
                        return Promise.reject(
                          new Error(
                            '현재 항목을 입력하시거나 [제 3공장 소재지 (국문), 제 3공장 소재지 (영문)] 항목을 비워주세요. (일부 항목만 입력 불가능)',
                          ),
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Button
                  icon={<i className="material-icons">file_upload</i>}
                  onClick={() => onClickAttachCGMP(thirdCGMP)}
                >
                  제 3공장 cGMP (ISO22716) 첨부하기
                </Button>
              </Form.Item>
            </Col>
            {thirdCGMP.file && (
              <Col style={{ paddingTop: 12 }}>
                <Typography.Text inline>{thirdCGMP.file.name}</Typography.Text>
              </Col>
            )}
          </Row>
        )}
      <Row gutter={8} align="bottom">
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item
            label="대표자 성명 (국문/영문)"
            name="ceoNameKo"
            rules={[requireRule]}
          >
            <Input placeholder="국문" />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item name="ceoNameEn" rules={[requireRule, exceptKoreanRule]}>
            <Input placeholder="영문" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8} align="bottom">
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item
            label="회사 전화번호"
            name="tel"
            rules={[requireRule, phoneRule]}
          >
            <Input placeholder="숫자만 입력 가능" />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item
            label="팩스번호 (FAX)"
            name="fax"
            rules={[requireRule, phoneRule]}
          >
            <Input placeholder="숫자만 입력 가능" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label="홈페이지 URL" name="homepageUrl" rules={[urlRule]}>
        <Input placeholder="예시: https://www.30cos.com" />
      </Form.Item>
      <Form.Item
        label={
          <Row gutter={8} align="middle">
            <Col>국문 사업자등록증 (PDF)</Col>
            {updateMode && (
              <Col>
                <Tip bodyStyle={{ width: 306 }} trigger="click">
                  <Row>
                    <Col>
                      <Typography.Text
                        type="quaternary"
                        style={{ whiteSpace: 'pre' }}
                      >
                        -{' '}
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text
                        type="quaternary"
                        style={{ width: 251, whiteSpace: 'pre-wrap' }}
                      >
                        사업자등록증의 내용이 기존과 달라졌을 경우, 반드시
                        수정이 필요합니다.
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Typography.Text type="quaternary" medium>
                    - 수정을 원하시면{' '}
                    <Typography.Text
                      type="quaternary"
                      medium
                      color="primary"
                      inline
                    >
                      우측 하단 채널톡으로 문의바랍니다.
                    </Typography.Text>
                  </Typography.Text>
                </Tip>
              </Col>
            )}
          </Row>
        }
        required
        name="bizLicenseFile"
        rules={[requireRule]}
      >
        <FileUpload readOnly={updateMode} />
      </Form.Item>
      {!(updateMode && !bizLicenseEnUrl) && (
        <Form.Item
          label={
            <Row gutter={8} align="middle">
              <Col>영문 사업자등록증 (PDF)</Col>
              <Col>
                {!updateMode ? (
                  <Tip bodyStyle={{ width: 340 }} trigger="click">
                    <Typography.Text
                      type="tertiary"
                      medium
                      gutter={{ bottom: 4 }}
                    >
                      영문 사업자등록증이 없으신가요?
                    </Typography.Text>
                    <Typography.Text type="quaternary">
                      영문 사업자등록증은 인증 진행시 필요한 서류이므로 없으신
                      경우
                    </Typography.Text>
                    <Typography.Text type="quaternary" color="primary">
                      우측 하단 채널톡으로 문의바랍니다.
                    </Typography.Text>
                  </Tip>
                ) : (
                  <Tip bodyStyle={{ width: 306 }} trigger="click">
                    <Row>
                      <Col>
                        <Typography.Text
                          type="quaternary"
                          style={{ whiteSpace: 'pre' }}
                        >
                          -{' '}
                        </Typography.Text>
                      </Col>
                      <Col>
                        <Typography.Text
                          type="quaternary"
                          style={{ width: 251, whiteSpace: 'pre-wrap' }}
                        >
                          사업자등록증의 내용이 기존과 달라졌을 경우, 반드시
                          수정이 필요합니다.
                        </Typography.Text>
                      </Col>
                    </Row>
                    <Typography.Text type="quaternary">
                      - 수정을 원하시면{' '}
                      <Typography.Text type="quaternary" color="primary" inline>
                        우측 하단 채널톡으로 문의바랍니다.
                      </Typography.Text>
                    </Typography.Text>
                  </Tip>
                )}
              </Col>
            </Row>
          }
          required
          name="bizLicenseEnFile"
          rules={[requireRule]}
        >
          <FileUpload readOnly={updateMode} />
        </Form.Item>
      )}
    </ManuBlockBlock>
  );
};

export default ManuForm;
