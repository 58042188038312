import styled from 'styled-components';
import { useState } from 'react';

import NoticeCard from 'components/system/data/NoticeCard';
import Typography from 'components/system/general/Typography';
import MaterialRegisterForm from 'components/auth/material/MaterialRegisterForm';

const MaterialRegisterFormPageBlock = styled.div`
  max-width: 920px;
  margin: 0 auto;
`;

const MaterialRegisterFormPage = () => {
  const [tab, setTab] = useState<'company' | 'user'>('company');
  return (
    <MaterialRegisterFormPageBlock>
      <Typography.Headline type="secondary">정보 입력</Typography.Headline>
      <Typography.Text
        type="secondary"
        gutter={{ top: 4, bottom: 18 }}
        color="gray"
      >
        정보를 정확하게 입력해 주세요.
      </Typography.Text>
      <NoticeCard title="안내사항" style={{ marginTop: 40 }}>
        <ul>
          {tab === 'company' ? (
            <>
              <li>
                사업자등록번호 1개 당 1개 계정만 가입이 가능합니다. (가입 후
                여러명의 하위 계정을 추가할 수 있습니다.)
              </li>
              <li>
                회사 본사 주소 국문은 사업자 등록증 상 주소와 동일하게 써주시고,
                영문 주소는 써주신 국문 주소를 영문으로 입력해 주세요. (별도
                영문 사업자 등록증이 있다면 서류에 기재된 영문 주소와 동일하게
                입력해 주세요.)
              </li>
              <li>
                공장 소재지가 여러 개일 경우, 추가 버튼으로 입력하실 수
                있습니다. (없는 경우 입력하지 않으셔도 됩니다.)
              </li>
            </>
          ) : (
            <>
              <li>
                슈퍼유저 계정은 회사 당 1개의 계정만 가입 가능하며, 가입 후 여러
                하위 담당자 계정을 추가하고 관리할 수 있습니다.
              </li>
              <li>
                슈퍼유저 정보에 기재하신 이메일로 가입 승인 및 계정에 대한 안내
                메일이 발송되오니, 슈퍼유저를 사용하실 분의 이메일을 입력해
                주세요.
              </li>
            </>
          )}
        </ul>
      </NoticeCard>
      <MaterialRegisterForm onChangeTab={setTab} />
    </MaterialRegisterFormPageBlock>
  );
};

export default MaterialRegisterFormPage;
