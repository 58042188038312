import { Avatar, Col, Row } from 'antd';
import styled from 'styled-components';
import { UserOutlined } from '@ant-design/icons';

import { Typography } from 'components/system';
import palette from 'lib/styles/palette';
import { nanoid } from 'nanoid';

const StyledCircle = styled.div<{ opacity: number; delay: number }>`
  width: 8px;
  height: 8px;
  opacity: ${(props) => props.opacity};
  border-radius: 4px;
  background-color: ${palette.primary};

  @keyframes bounce {
    0%,
    50%,
    100% {
      transform: scale(1);
    }
    25% {
      transform: scale(0.6);
    }
    75% {
      transform: scale(1.4);
    }
  }
  animation: bounce 1.5s 0.5s linear infinite;
  animation-delay: ${(props) => `${props.delay}s`};
`;

const circles = [
  { opacity: 0.2, delay: 0 },
  { opacity: 0.3, delay: 0.1 },
  { opacity: 0.5, delay: 0.2 },
  { opacity: 0.8, delay: 0.3 },
  { opacity: 1, delay: 0.4 },
];

const OngoingRequestModalContent = ({
  fromUserName,
  toUserName,
}: {
  fromUserName: string;
  toUserName: string;
}) => {
  return (
    <>
      <Typography.Title gutter={{ bottom: 24 }}>
        입력 요청 중입니다.
      </Typography.Title>
      <Row
        style={{ marginBottom: 50 }}
        justify="center"
        align="middle"
        gutter={36}
      >
        <Col>
          <Avatar size={40} icon={<UserOutlined />} />
          <Typography.Text>{fromUserName}</Typography.Text>
        </Col>
        <Col>
          <Row gutter={8}>
            {circles.map(({ opacity, delay }) => (
              <Col key={nanoid()}>
                <StyledCircle opacity={opacity} delay={delay} />
              </Col>
            ))}
          </Row>
        </Col>
        <Col>
          <Avatar
            style={{ backgroundColor: palette.primary }}
            size={40}
            icon={<UserOutlined />}
          />
          <Typography.Text>{toUserName}</Typography.Text>
        </Col>
      </Row>
    </>
  );
};

export default OngoingRequestModalContent;
