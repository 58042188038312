import { useEffect, useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import * as brandProductApi from 'lib/api/brand/product';
import {
  EDocumentCode,
  IProduct,
  IProductAddParams,
  IProductDuplicatedCheckParams,
} from 'types/product';
import client from 'lib/api/client';
import { IAPIResponse } from 'types/common';
import * as productApi from 'lib/api/product';
import * as manufacturerProductApi from 'lib/api/manufacturer/product';
import { setCurrentDocStatus, setCurrentProduct } from 'modules/product';
import { setReadOnlyMode } from 'modules/certificate';
import { useProductDocStatus } from 'service/product';

export const useSetCurrentProduct = ({
  productId,
  countryId,
  manuMode = false,
}: {
  productId?: number;
  countryId?: number;
  manuMode?: boolean;
}) => {
  const dispatch = useDispatch();
  useQuery(
    ['product/getProduct', productId, countryId],
    async () => {
      const res = !manuMode
        ? await productApi.getProduct({
            productId: productId!,
            countryId,
          })
        : await manufacturerProductApi.getProduct(productId!);
      dispatch(setCurrentProduct(res.data.result));
      return res;
    },
    {
      suspense: true,
      enabled: typeof productId !== 'undefined',
    },
  );
};

export const useSetCurrentProductDocStatus = ({
  productId,
  countryId,
  documentCode,
}: {
  productId?: number;
  countryId?: number;
  documentCode?: EDocumentCode;
}) => {
  const dispatch = useDispatch();
  const productDocStatus = useProductDocStatus({
    productId,
    countryId,
    documentCode,
  });

  useEffect(() => {
    dispatch(
      setReadOnlyMode(
        typeof countryId !== 'undefined' &&
          productDocStatus !== null &&
          productDocStatus.status === 'ONG',
      ),
    );
    return () => {
      dispatch(setReadOnlyMode(false));
    };
  }, [productDocStatus]);

  useEffect(() => {
    dispatch(setCurrentDocStatus(productDocStatus));
  }, [productDocStatus]);
};

export const useCurrentProduct = () => {
  const currentProduct = useSelector(
    ({ product }: any) => product.currentProduct as IProduct,
  );
  return currentProduct;
};

export const useCountryId = () => {
  const { search } = useLocation();
  return useMemo(() => {
    const query = new URLSearchParams(search);
    return Number(query.get('countryId')) || undefined;
  }, [search]);
};

export const useAddProduct = () => {
  const {
    mutate: addProduct,
    isLoading: addProductLoading,
  } = useMutation((productAddParams: IProductAddParams) =>
    client.post<IAPIResponse<IProduct>>('/product', productAddParams),
  );

  return useMemo(() => ({ addProduct, addProductLoading }), [
    addProduct,
    addProductLoading,
  ]);
};

export const useGetDuplicatedProduct = () => {
  const {
    mutate: getDuplicatedProduct,
    isLoading: getDuplicatedProductLoading,
  } = useMutation((params: IProductDuplicatedCheckParams) =>
    brandProductApi.getDuplicatedProduct(params),
  );
  return useMemo(
    () => ({ getDuplicatedProduct, getDuplicatedProductLoading }),
    [getDuplicatedProduct, getDuplicatedProductLoading],
  );
};

export const useDeleteProducts = () => {
  const {
    mutate: deleteProducts,
    isLoading: deleteProductsLoading,
  } = useMutation((productIds: number[]) =>
    client.delete(`/products?productIds=${productIds}`),
  );

  return useMemo(() => ({ deleteProducts, deleteProductsLoading }), [
    deleteProducts,
    deleteProductsLoading,
  ]);
};
