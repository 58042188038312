import MistakeNoticeItem from 'components/MistakeNoticeItem';
import ProductSingleFileContainer from 'containers/product/ProductSingleFileContainer';
import ProductPageTemplate from 'templates/ProductPageTemplate';

const notices = [
  '브랜드 로고를 AI 파일로 업로드해 주세요.',
  <MistakeNoticeItem />,
];

const ProductLogoPage = () => {
  return (
    <ProductPageTemplate subtitle="Brand Logo (AI File)" notices={notices}>
      <ProductSingleFileContainer documentCode="logoai" accept=".ai" />
    </ProductPageTemplate>
  );
};

export default ProductLogoPage;
