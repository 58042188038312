import { useQuery } from 'react-query';
import { useMemo } from 'react';

import client from 'lib/api/client';
import {
  IClinicalTrial,
  IClinicalTrialRecommendation,
} from 'types/brand/clinicalTrial/clinicalTrial';
import { IAPIResponse } from 'types/common';

export const useClinicalTrials = () => {
  const { data: clinicalTrials = [], isFetching } = useQuery(
    ['clinical-trial/clinical-trials'],
    () =>
      client.get<IAPIResponse<IClinicalTrial[]>>(
        '/pub/clinical-trial/clinical-trials',
      ),
    {
      select: (res) => res.data.result,
      staleTime: Number.MAX_VALUE,
    },
  );

  return useMemo(
    () => ({
      clinicalTrials,
      isFetching,
    }),
    [clinicalTrials, isFetching],
  );
};
export const useClinicalTrialRecommendations = () => {
  const { data: clinicalTrialRecommendations = [], isFetching } = useQuery(
    ['clinical-trials/recommendations'],
    () =>
      client.get<IAPIResponse<IClinicalTrialRecommendation[]>>(
        '/pub/clinical-trials/recommendations',
      ),
    {
      select: (res) => res.data.result,
      staleTime: Number.MAX_VALUE,
    },
  );

  return useMemo(
    () => ({
      clinicalTrialRecommendations,
      isFetching,
    }),
    [clinicalTrialRecommendations, isFetching],
  );
};
