import ProductCMBC from 'components/brand/retailer/retailerApply/product/ProductCMBC';
import ProductPageTemplate from 'components/brand/retailer/retailerApply/product/ProductPageTemplate';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import { useParams } from 'react-router-dom';

const ProductCMBCPage = () => {
  const params = useParams<{ productId: string }>();
  const productId = Number(params.productId);
  return (
    <ProductPageTemplate
      productId={productId}
      subtitle="화장품 제조업 등록필증"
      notices={[
        '화장품 제조업 등록필증의 앞, 뒷면을 각각 스캔하여 PDF 파일을 업로드해 주세요.',
        '뒷면의 변경 및 처분 사항의 경우에는 추가된 내용이 있을 경우, 가장 최근 변경사항까지 포함된 PDF 파일로 업로드해 주세요.',
        <MistakeNoticeItem />,
      ]}
      exampleImg="CMBC_V1.png"
    >
      <ProductCMBC productId={productId} />
    </ProductPageTemplate>
  );
};

export default ProductCMBCPage;
