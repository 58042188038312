import { Typography } from 'antd';
import styled from 'styled-components';
import cx from 'classnames';

interface IProps {
  size?: string;
  bordered?: boolean;
  title?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

const Table = ({
  size,
  bordered,
  title,
  children,
  style,
  className,
}: IProps) => {
  return (
    <div className={cx(['ant-table-wrapper', className])}>
      <div className="ant-spin-nested-loading">
        <div className="ant-spin-container">
          <div
            className={cx([
              'ant-table',
              { 'ant-table-small': size === 'small' },
              { 'ant-table-bordered': bordered },
            ])}
            style={style}
          >
            {title && (
              <div className="ant-table-title">
                <Typography.Title level={5} style={{ marginBottom: 0 }}>
                  {title}
                </Typography.Title>
              </div>
            )}
            <div className="ant-table-container">
              <div className="ant-table-content">
                <table style={{ tableLayout: 'auto' }}>{children}</table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Table.Thead = ({ style, children }: IProps) => (
  <thead className="ant-table-thead" style={style}>
    {children}
  </thead>
);
Table.Tbody = ({ children }: IProps) => (
  <tbody className="ant-table-tbody">{children}</tbody>
);
Table.Tr = styled.tr.attrs<IProps>({ className: 'ant-table-cell' })``;
Table.Th = (props: any) => (
  <th {...props} className={cx('ant-table-cell', props.className)}>
    {props.children}
  </th>
);

Table.Td = styled.td.attrs<IProps>({ className: 'ant-table-cell' })`
  .ant-form-item {
    margin-bottom: 0 !important;
  }
`;

export default Table;
