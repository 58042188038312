import ProductBasic from 'components/brand/retailer/retailerApply/product/ProductBasic';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import ProductPageTemplate from 'templates/ProductPageTemplate';

const notices = [
  '화장품 제조업자, 화장품 책임판매업자 명칭은 사업자등록증 또는 화장품 제조업자, 화장품 책임판매업자 필증과 동일하게 입력해 주세요.',
  '제품명(영문)은 인증 시 사용될 제품명으로 대소문자, 띄어쓰기에 주의하여 입력해 주세요.',
  <>
    인증서에 등록될 제품명에 브랜드명이 포함되길 원하시는 경우, 브랜드명이
    포함된 제품명을 입력해 주세요.
  </>,
  '화장품책임판매업자의 주소가 잘못된 경우, 슈퍼유저 계정의 마이페이지 > 회원정보 수정에서 수정 가능합니다.',
  <MistakeNoticeItem />,
];
const ProductBasicPage = () => {
  return (
    <ProductPageTemplate subtitle="기본정보" notices={notices} hasSkip={false}>
      <ProductBasic />
    </ProductPageTemplate>
  );
};

export default ProductBasicPage;
