import { Button, message, Modal, Radio, Form } from 'antd';
import { useEffect, useState } from 'react';
import FileUploadContainer from 'containers/file/FileUploadContainer';
import FooterBox from 'components/FooterBox';
import { normalizeDate, requireRule, noPastDateRule } from 'lib/validate';
import { messages } from 'lib/consts';
import Typography from 'components/system/general/Typography';
import DateInput from 'components/system/form/DateInput';
import styled from 'styled-components';

const StyledFormItem = styled(Form.Item)`
  height: 32px;

  .ant-form-item-control-input {
    min-height: 0;
  }

  .ant-form-item-label > label {
    height: 32px;
  }
`;

const CGMPModal = ({ cGMP, onOk, onClose }: any) => {
  const [file, setFile] = useState<any>(null);
  const [form] = Form.useForm();
  const onUpload = (file: any) => {
    setFile(file);
    return true;
  };
  const onDelete = () => {
    setFile(null);
    return true;
  };
  const onSubmit = ({
    isCGMP,
    ISOExpiredDate,
  }: {
    isCGMP: string;
    ISOExpiredDate: string;
  }) => {
    if (!file) {
      return message.warn(messages.REQUIRED_FILE);
    }
    onOk({ isCGMP, ISOExpiredDate, file });
  };

  useEffect(() => {
    if (cGMP) {
      const { isCGMP, ISOExpiredDate, file } = cGMP;
      setFile(file);
      form.setFieldsValue({ isCGMP, ISOExpiredDate });
    }
  }, [cGMP]);

  return (
    <Modal
      visible={cGMP !== null}
      onCancel={onClose}
      footer={null}
      width={880}
      closable={false}
      destroyOnClose
      bodyStyle={{ padding: 32 }}
    >
      <Typography.Text gutter={{ bottom: 28 }}>
        cGMP 혹은 ISO22716 를 선택하여 서류를 첨부해 주세요. (ISO222716 선택 시
        유효기간을 입력해 주세요.)
      </Typography.Text>
      <Form
        form={form}
        layout="inline"
        colon={false}
        style={{ justifyContent: 'center', alignItems: 'center' }}
        onFinish={onSubmit}
      >
        <Form.Item name="isCGMP">
          <Radio.Group
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
            onChange={(e) => {
              if (e.target.value && form.getFieldValue('ISOExpiredDate')) {
                form.setFieldsValue({ ISOExpiredDate: '' });
              }
            }}
          >
            <Radio value={true}>cGMP</Radio>
            <Radio value={false}>ISO22716</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => prev.isCGMP !== next.isCGMP}
        >
          {({ getFieldValue }) => {
            const isCGMP = getFieldValue('isCGMP');
            return (
              <StyledFormItem
                label={
                  <Typography.Text type="secondary">유효기간</Typography.Text>
                }
                name="ISOExpiredDate"
                normalize={normalizeDate}
                required={false}
                rules={[noPastDateRule, ...(!isCGMP ? [requireRule] : [])]}
              >
                <DateInput
                  style={{ width: 140, height: 32 }}
                  disabled={isCGMP}
                />
              </StyledFormItem>
            );
          }}
        </Form.Item>
      </Form>
      <FileUploadContainer
        files={file}
        onUpload={onUpload}
        onDelete={onDelete}
        viewerVisible
        style={{ marginTop: '24px' }}
      />
      <FooterBox style={{ marginTop: 32 }} modal>
        <Button onClick={onClose}>취소</Button>
        <Button type="primary" onClick={form.submit}>
          첨부하기
        </Button>
      </FooterBox>
    </Modal>
  );
};

export default CGMPModal;
