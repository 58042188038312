import { ICompanyGet } from './company';

export enum EUserType {
  BRAND = 'BRAND',
  MANUFACTURE = 'MANUFACTURE',
  MATERIAL = 'MATERIAL',
}
export interface IUser {
  userId: number;
  companyId: number;
  company: ICompanyGet;
  username: string;
  name: string;
  deptName: string;
  positionName: string;
  isManufacturer: boolean;
  manufacturerId: number;
  manufacturerBizNumber?: string;
  authority: string;
  email: string;
  mobile: string;
  status: string;
  registerDt: Date;
  lastLoginDt: Date;
  isEmailVerified?: boolean;
  isPhoneVerified?: boolean;
  isSmsAgreed?: boolean;
  isEmailAgreed?: boolean;
  isResetPassword: boolean;
  token: string;
  refreshToken: string;
  redirectUrl: string;
  userType: EUserType;
}
export interface IUserRegister {
  isSuperUser: boolean;
  companyId: number;
  username: string;
  password: string;
  name: string;
  deptName: string;
  positionName: string;
  isManufacturer?: boolean;
  manufacturerId: number;
  manufacturerBizNumber?: string;
  email: string;
  mobile: string;
  isResetPassword: boolean;
  userType: string;
  directTelNumber: string;
}
export interface IUserUpdate {
  userId: number;
  name: string;
  deptName: string;
  positionName: string;
  isManufacturer: boolean;
  manufacturerId: number;
  manufacturerBizNumber: string;
  authority: string;
  email: string;
  mobile: string;
  status: string;
  isSmsAgreed: boolean;
  isEmailAgreed: boolean;
  userType: string;
  directTelNumber?: string;
}

export enum EAuthority {
  SUPER_USER = 'SUPER_USER',
  MA_USER = 'MA_USER',
  SUPER_ADMIN = 'SUPER_ADMIN',
}

export interface IManager {
  authority: string;
  company: ICompanyGet;
  companyId: number;
  deptName: string;
  directTelNumber: string | null;
  email: string;
  isEmailAgreed: boolean | null;
  isEmailVerified: boolean | null;
  isManufacturer: boolean;
  isPhoneVerified: boolean | null;
  isResetPassword: boolean;
  isSmsAgreed: boolean;
  lastLoginDt: string | null;
  manufacturerBizNumber: string | null;
  manufacturerId: number | null;
  mobile: string;
  name: string;
  positionName: string;
  redirectUrl: string | null;
  refreshToken: string | null;
  registerDt: string;
  status: string;
  token: string | null;
  userId: number;
  userType: EUserType;
  username: string;
}
