import { Col, PageHeader, Row } from 'antd';
import styled from 'styled-components';
import { useRouteMatch } from 'react-router-dom';

import PageTemplate from 'templates/PageTemplate';
import IngredientDetail from 'components/material/ingredient/IngredientDetail';
import IngredientCompareBox from 'components/material/ingredient/IngredientCompareBox';
import IngredientSelect from 'components/material/ingredient/IngredientSelect';
import { Typography } from 'components/system';

const CollapsableCol = styled(Col)<{ collapsedsize: 'sm' | 'md' }>`
  margin-top: 4px;
  @media (max-width: 1100px) {
    display: none;
  }
`;

const StyledRow = styled(Row)`
  margin: -36px 0 16px 0;
`;

const IngredientDetailPage = () => {
  const {
    params: { kciaIngredientId },
  } = useRouteMatch<{ kciaIngredientId: string }>();

  return (
    <PageTemplate back={false}>
      <Row>
        <Col span={21}>
          <StyledRow justify="space-between">
            <Col>
              <PageHeader
                title={
                  <Typography.Headline type="secondary">
                    성분 상세 정보
                  </Typography.Headline>
                }
                style={{ padding: 0 }}
              />
              <Typography.Text
                type="secondary"
                gutter={{ top: 4 }}
                color="gray"
              >
                원하는 성분을 찾아보고 비교함에 담아 성분들을 비교해 보세요.
              </Typography.Text>
            </Col>
            <Col>
              <IngredientSelect width={331} fromDetail />
            </Col>
          </StyledRow>
          <IngredientDetail kciaIngredientId={Number(kciaIngredientId)} />
        </Col>
        <CollapsableCol collapsedsize="sm" span={2} offset={1}>
          <IngredientCompareBox />
        </CollapsableCol>
      </Row>
    </PageTemplate>
  );
};

export default IngredientDetailPage;
