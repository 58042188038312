import { useEffect, useMemo, useState } from 'react';
import { Table } from 'antd';
import styled from 'styled-components';

import { ColumnsType } from 'antd/lib/table';
import { IIngredientSearchResult } from 'types/material/ingredient';
import { useHistory } from 'react-router-dom';
import path from 'lib/path';
import PageSizeSelect, {
  pageSizeOptions,
} from 'components/system/data/PageSizeSelect';
import { Typography } from 'components/system';
import palette from 'lib/styles/palette';
import { IAPIPageableResult } from 'types/common';
import { useIngredient } from 'service/material/ingredient';

const TableWrapper = styled.div`
  .ant-table-cell {
    max-width: 200px;
  }

  table {
    width: 100%;
    display: block;
    overflow: auto;
  }
`;

const StyledLink = styled(Typography.Text)`
  color: ${palette.text.black};
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

interface IProps {
  data?: IAPIPageableResult<IIngredientSearchResult[]>;
  firstKeyword: string;
  secondKeyword: string;
}

const IngredientSearchResultTable = ({
  data,
  firstKeyword,
  secondKeyword,
}: IProps) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(pageSizeOptions[0].value);
  const [tableData, setTableData] = useState<
    IAPIPageableResult<IIngredientSearchResult[]> | undefined | null
  >(data);
  const {
    searchAgainIngredient,
    searchAgainIngredientLoading,
  } = useIngredient();

  useEffect(() => {
    setPage(1);
    setTableData(data);
  }, [data]);

  useEffect(() => {
    setPage(1);
    if (firstKeyword) {
      searchAgainIngredient(
        {
          pageNo: 1,
          size: pageSize,
          preKeyword: firstKeyword,
          newKeyword: secondKeyword,
        },
        {
          onSuccess: (res) => setTableData(res.result),
        },
      );
    }
  }, [pageSize]);

  const history = useHistory();
  const columns: ColumnsType<IIngredientSearchResult> = useMemo(
    () => [
      {
        title: '성분명',
        width: 200,
        dataIndex: 'nameKo',
        render: (nameKo, record) => (
          <StyledLink
            medium
            type="secondary"
            onClick={() => {
              history.push(
                `${path.material.ingredient.detail}/${record.kciaIngredientId}`,
              );
            }}
          >
            {nameKo}
          </StyledLink>
        ),
      },
      {
        title: 'INCI Name',
        width: 200,
        dataIndex: 'nameEn',
        render: (nameEn) => {
          return nameEn ? nameEn : '-';
        },
      },
      {
        title: 'CAS No.',
        width: 200,
        dataIndex: 'casNo',
        render: (casNo) => {
          return casNo
            ? casNo.split('\n').map((cas: string, index: number) => {
                const len = casNo.split('\n').length;
                return index + 1 === len ? cas : `${cas.trim()}, `;
              })
            : '-';
        },
      },
      {
        title: 'EC No.',
        width: 200,
        dataIndex: 'ecNo',
        render: (ecNo) => {
          return ecNo ? ecNo : '-';
        },
      },
    ],
    [page],
  );

  return (
    <>
      <PageSizeSelect pageSize={pageSize} onChange={setPageSize} />
      <TableWrapper>
        <Table
          style={{ fontSize: 14 }}
          columns={columns}
          dataSource={tableData?.content}
          rowKey={({ adminId }) => adminId}
          loading={searchAgainIngredientLoading}
          pagination={{
            current: page,
            pageSize: pageSize,
            total: tableData?.totalElements,
            onChange: (page, _pageSize) => {
              setPage(page);
              searchAgainIngredient(
                {
                  pageNo: page,
                  size: pageSize,
                  preKeyword: firstKeyword,
                  newKeyword: secondKeyword,
                },
                {
                  onSuccess: (res) => setTableData(res.result),
                },
              );
            },
          }}
        />
      </TableWrapper>
    </>
  );
};

export default IngredientSearchResultTable;
