import PageTemplate from 'templates/PageTemplate';
import ProductDetailContainer from 'containers/product/ProductDetailContainer';
import Typography from 'components/system/general/Typography';

const notices = [
  '각 항목을 클릭하시면, 해당 자료를 등록하셨던 화면으로 연결되며 내용을 수정하실 수 있습니다.',
  <>
    국가 추가를 원하시는 경우, 아래 리스트의{' '}
    <Typography.Text type="secondary" inline style={{ fontWeight: 500 }}>
      국가 선택
    </Typography.Text>{' '}
    항목을 클릭하시면 추가가 가능합니다.
  </>,
];

const ProductDetailPage = () => {
  return (
    <PageTemplate title="제품 입력정보" notices={notices}>
      <ProductDetailContainer />
    </PageTemplate>
  );
};

export default ProductDetailPage;
