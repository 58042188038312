import { useState } from 'react';
import { Button, Col, Form, Radio, Row } from 'antd';
import styled, { css } from 'styled-components';

import { Typography } from 'components/system';
import { requireRule } from 'lib/validate';
import { EDisclosureType, IDisclosures } from 'types/material/rawMaterial';
import DisclosureManufacturerModal from './DisclosureManufacturerModal';
import { ECompanyType } from 'types/company';
import DisclosureMaterialModal from './DisclosureMaterialModal';
import DisclosureBrandModal from './DisclosureBrandModal';

const StyledButton = styled(Button)<{ visible: boolean }>`
  ${(props) =>
    props.visible
      ? css`
          visibility: visible;
          opacity: 1;
        `
      : css`
          visibility: hidden;
          opacity: 0;
        `}

  transition: all ease 0.3s;
  margin-top: 12px;
  min-width: 134px;
  padding: 0px 4px;
`;

const DisclosureFormBlock = styled.div`
  .ant-col:nth-child(2) {
    margin-left: 8px;
  }
  .ant-form-item-control-input {
    min-height: 0px;
  }
  .ant-radio-wrapper {
    line-height: 0;
    margin-top: 12px;
  }
`;

const DisclosureForm = ({
  isDiscontinued,
  disclosures,
  handleChangeDisclosures,
}: {
  isDiscontinued: boolean;
  disclosures: IDisclosures;
  handleChangeDisclosures: (disclosure: IDisclosures) => void;
}) => {
  const handleChangeDisclosureType = (type: EDisclosureType, key: string) => {
    if (type === EDisclosureType.ALL || type === EDisclosureType.CLOSE) {
      handleChangeDisclosures({
        ...disclosures,
        [key]: [],
      });
    }
  };
  const [companyType, setCompanyType] = useState<ECompanyType | null>(null);

  return (
    <DisclosureFormBlock>
      <Form.Item label="원료 공개 여부 설정" required>
        <Row gutter={6} align="middle">
          <Col>
            <Typography.Text>
              <Typography.Text
                color="primary"
                style={{ display: 'inline', marginRight: 4 }}
              >
                1.
              </Typography.Text>
              책임판매사
            </Typography.Text>
            <Form.Item
              initialValue={EDisclosureType.ALL}
              name="brandCompanyDisclosureType"
              rules={[
                requireRule,
                {
                  validator: async (_, brandCompanyDisclosureType) => {
                    if (
                      brandCompanyDisclosureType === EDisclosureType.TARGET &&
                      disclosures['disclosureBrandCompanyIds'].length === 0
                    ) {
                      throw new Error('책임판매사 지정 필요');
                    }
                  },
                  validateTrigger: 'onSubmit',
                },
              ]}
            >
              <Radio.Group
                options={[
                  { label: '모두에게 공개', value: EDisclosureType.ALL },
                  { label: '비공개', value: EDisclosureType.CLOSE },
                  { label: '공개 대상 지정', value: EDisclosureType.TARGET },
                ]}
                disabled={isDiscontinued}
                onChange={(e) => {
                  handleChangeDisclosureType(
                    e.target.value,
                    'disclosureBrandCompanyIds',
                  );
                }}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              shouldUpdate={(prev, next) =>
                prev.brandCompanyDisclosureType !==
                next.brandCompanyDisclosureType
              }
              noStyle
            >
              {({ getFieldValue }) => (
                <StyledButton
                  visible={
                    getFieldValue('brandCompanyDisclosureType') ===
                    EDisclosureType.TARGET
                  }
                  disabled={isDiscontinued}
                  onClick={() => setCompanyType(ECompanyType.BRAND)}
                >
                  책임판매사 지정{' '}
                  {disclosures['disclosureBrandCompanyIds'].length !== 0 &&
                    `(${disclosures['disclosureBrandCompanyIds'].length})`}
                </StyledButton>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={6} align="middle">
          <Col>
            <Typography.Text>
              <Typography.Text
                color="primary"
                style={{ display: 'inline', marginRight: 4 }}
              >
                2.
              </Typography.Text>
              제조사
            </Typography.Text>
            <Form.Item
              initialValue={EDisclosureType.ALL}
              name="manufacturerDisclosureType"
              rules={[
                requireRule,
                {
                  validator: async (_, manufacturerDisclosureType) => {
                    if (
                      manufacturerDisclosureType === EDisclosureType.TARGET &&
                      disclosures['disclosureManufacturerIds'].length === 0
                    ) {
                      throw new Error('제조사 지정 필요');
                    }
                  },
                  validateTrigger: 'onSubmit',
                },
              ]}
            >
              <Radio.Group
                options={[
                  { label: '모두에게 공개', value: EDisclosureType.ALL },
                  { label: '비공개', value: EDisclosureType.CLOSE },
                  { label: '공개 대상 지정', value: EDisclosureType.TARGET },
                ]}
                disabled={isDiscontinued}
                onChange={(e) =>
                  handleChangeDisclosureType(
                    e.target.value,
                    'disclosureManufacturerIds',
                  )
                }
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              shouldUpdate={(prev, next) =>
                prev.manufacturerDisclosureType !==
                next.manufacturerDisclosureType
              }
              noStyle
            >
              {({ getFieldValue }) => (
                <StyledButton
                  visible={
                    getFieldValue('manufacturerDisclosureType') ===
                    EDisclosureType.TARGET
                  }
                  disabled={isDiscontinued}
                  onClick={() => setCompanyType(ECompanyType.MANUFACTURE)}
                >
                  제조사 지정{' '}
                  {disclosures['disclosureManufacturerIds'].length !== 0 &&
                    `(${disclosures['disclosureManufacturerIds'].length})`}
                </StyledButton>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={6} align="middle">
          <Col>
            <Typography.Text>
              <Typography.Text
                color="primary"
                style={{ display: 'inline', marginRight: 4 }}
              >
                3.
              </Typography.Text>
              원료사
            </Typography.Text>
            <Form.Item
              initialValue={EDisclosureType.ALL}
              name="materialCompanyDisclosureType"
              rules={[
                requireRule,
                {
                  validator: async (_, materialCompanyDisclosureType) => {
                    if (
                      materialCompanyDisclosureType ===
                        EDisclosureType.TARGET &&
                      disclosures['disclosureMaterialCompanyIds'].length === 0
                    ) {
                      throw new Error('원료사 지정 필요');
                    }
                  },
                  validateTrigger: 'onSubmit',
                },
              ]}
            >
              <Radio.Group
                options={[
                  { label: '모두에게 공개', value: EDisclosureType.ALL },
                  { label: '비공개', value: EDisclosureType.CLOSE },
                  { label: '공개 대상 지정', value: EDisclosureType.TARGET },
                ]}
                disabled={isDiscontinued}
                onChange={(e) =>
                  handleChangeDisclosureType(
                    e.target.value,
                    'disclosureMaterialCompanyIds',
                  )
                }
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              shouldUpdate={(prev, next) =>
                prev.materialCompanyDisclosureType !==
                next.materialCompanyDisclosureType
              }
              noStyle
            >
              {({ getFieldValue }) => (
                <StyledButton
                  visible={
                    getFieldValue('materialCompanyDisclosureType') ===
                    EDisclosureType.TARGET
                  }
                  disabled={isDiscontinued}
                  onClick={() => setCompanyType(ECompanyType.MATERIAL)}
                >
                  원료사 지정{' '}
                  {disclosures['disclosureMaterialCompanyIds'].length !== 0 &&
                    `(${disclosures['disclosureMaterialCompanyIds'].length})`}
                </StyledButton>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
      <DisclosureBrandModal
        brandIds={disclosures['disclosureBrandCompanyIds']}
        visible={companyType === ECompanyType.BRAND}
        onClose={() => setCompanyType(null)}
        onChange={(disclosureBrandCompanyIds) =>
          handleChangeDisclosures({
            ...disclosures,
            disclosureBrandCompanyIds,
          })
        }
      />
      <DisclosureManufacturerModal
        manufacturerIds={disclosures['disclosureManufacturerIds']}
        visible={companyType === ECompanyType.MANUFACTURE}
        onClose={() => setCompanyType(null)}
        onChange={(disclosureManufacturerIds) =>
          handleChangeDisclosures({
            ...disclosures,
            disclosureManufacturerIds,
          })
        }
      />
      <DisclosureMaterialModal
        materialCompanyIds={disclosures['disclosureMaterialCompanyIds']}
        visible={companyType === ECompanyType.MATERIAL}
        onClose={() => setCompanyType(null)}
        onChange={(disclosureMaterialCompanyIds) =>
          handleChangeDisclosures({
            ...disclosures,
            disclosureMaterialCompanyIds,
          })
        }
      />
    </DisclosureFormBlock>
  );
};
export default DisclosureForm;
