import { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import * as companyActions from 'modules/company';

export const useMain = () => {
  const dispatch = useDispatch();
  const { user, companyName, stats, getStatsLoading } = useSelector(
    ({ auth, company, loading }: any) => ({
      user: auth.user,
      companyName: company.company?.companyNameKo,
      stats: company.stats,
      getStatsLoading: loading['company/GET_COMPANY_STATS'],
    }),
    shallowEqual,
  );
  const { companyId } = user;

  useEffect(() => {
    dispatch(companyActions.getCompanyStats(companyId));
  }, []);

  return useMemo(
    () => ({
      companyName,
      stats: stats,
      getStatsLoading,
    }),
    [companyName, stats, getStatsLoading],
  );
};
