import { Switch, Route } from 'react-router-dom';
import path from 'lib/path';
import EstimatePickPage from 'pages/estimate/EstimatePickPage';
import Error404Page from 'pages/error/Error404Page';
import EstimateContractPage from 'pages/estimate/EstimateContractPage';
import { useCheckManufacturer } from 'lib/hook';

const EstimateRouter = () => {
  useCheckManufacturer();
  return (
    <Switch>
      <Route path={path.estimate.pick} component={EstimatePickPage} />
      <Route path={path.estimate.contract} component={EstimateContractPage} />
      <Route path={path.estimate.root} component={Error404Page} />
    </Switch>
  );
};

export default EstimateRouter;
