import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import printJS from 'print-js';
import styled from 'styled-components';
import { DownloadOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, Col, Empty, Row, Spin } from 'antd';

import path from 'lib/path';
import palette from 'lib/styles/palette';
import { downloadFile } from 'lib/file';
import history from 'lib/history';
import * as estimateActions from 'modules/estimate';
import { useEstimate } from 'hook/estimate';
import { useIsMobile } from 'hook/etc';
import FooterBox from 'components/FooterBox';
import FileViewer from 'components/file/FileViewer';

const EstimateViewerBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EstimateViewer = () => {
  const dispatch = useDispatch();
  const { estimate, loading: getLoading } = useEstimate();
  const isMobile = useIsMobile();

  const handleDownload = () => {
    downloadFile(estimate.fileUrl);
  };

  const handlePrint = () => {
    printJS(estimate.fileUrl);
  };

  const handleMoveToPick = () => {
    history.push(path.estimate.pick);
  };

  const handleMoveToNext = () => {
    dispatch(estimateActions.setContractStep('contract'));
  };

  if (getLoading) {
    return <Spin style={{ width: '100%', height: '400px' }} />;
  }
  if (!estimate) {
    return (
      <EstimateViewerBlock>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="발행한 견적내역이 없거나 이미 계약이 체결되었습니다."
        />
        <Row gutter={16}>
          <Col>
            <Link
              to={path.estimate.pick}
              style={{ textDecoration: 'underline', color: palette.primary }}
            >
              새로운 견적 받기
            </Link>
          </Col>
          <Col>
            <Link
              to={`${path.my}/estimate`}
              style={{
                textDecoration: 'underline',
                marginTop: 16,
                color: palette.primary,
              }}
            >
              계약 보관함으로 이동
            </Link>
          </Col>
        </Row>
      </EstimateViewerBlock>
    );
  }
  return (
    <EstimateViewerBlock>
      <Row
        gutter={8}
        justify="start"
        style={{
          width: '100%',
          marginTop: 20,
          marginBottom: 8,
        }}
      >
        <Col xs={{ span: 24 }} flex="1 1 auto">
          <Button
            onClick={handleMoveToPick}
            style={{
              border: 'none',
              backgroundColor: '#eff1f8',
              color: '#9195a5',
              fontWeight: 500,
            }}
          >
            견적서 다시 받기
          </Button>
        </Col>
        {!isMobile && (
          <Col sm={{ flex: '0 0 100%' }}>
            <Button
              icon={<PrinterOutlined />}
              style={{ width: 144 }}
              onClick={handlePrint}
            >
              인쇄하기
            </Button>
          </Col>
        )}
        <Col sm={{ flex: '0 0 100%' }}>
          <Button
            icon={<DownloadOutlined />}
            style={{ width: 144 }}
            onClick={handleDownload}
          >
            다운로드
          </Button>
        </Col>
      </Row>
      <FileViewer
        title={' '}
        file={estimate.fileUrl}
        style={{ maxWidth: 992 }}
      />
      <FooterBox>
        <Button type="primary" onClick={handleMoveToNext}>
          이 견적으로 계속 진행
        </Button>
      </FooterBox>
    </EstimateViewerBlock>
  );
};

export default EstimateViewer;
