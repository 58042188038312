import { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Col, message, Row } from 'antd';

import { messages } from 'lib/consts';
import { addDocStatus, updateDocStatus } from 'modules/product';
import FileUploadTemplate from 'templates/FileUploadTemplate';
import UpdateLog from 'components/product/UpdateLog';
import CorrectRequestMessageModal from 'components/CorrectRequestMessageModal';
import { EDocStatus, EDocumentCode } from 'types/product';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';
import { useProductDocStatus } from 'service/product';

const ProductSingleFileContainer = ({
  documentCode,
  footerVisible = true,
  viewerVisible = true,
  accept = '.pdf',
  formUrl,
}: {
  documentCode: EDocumentCode;
  footerVisible?: boolean;
  viewerVisible?: boolean;
  accept?: string;
  formUrl?: string;
}) => {
  const { productId } = useCurrentProduct();
  const countryId = useCountryId();
  const dispatch = useDispatch();
  const currentProduct = useCurrentProduct();

  const { readOnlyMode, fetchLoading } = useSelector(
    ({ loading, certificate }: any) => ({
      readOnlyMode: certificate.readOnlyMode,
      fetchLoading:
        loading['product/ADD_PRODUCT_DOC_STATUS'] ||
        loading['product/UPDATE_PRODUCT_DOC_STATUS'],
    }),
    shallowEqual,
  );

  const docStatus = useProductDocStatus({ productId, countryId, documentCode });

  const updateMode = useMemo(
    () =>
      docStatus !== null &&
      docStatus.status !== EDocStatus.INP &&
      docStatus.status !== EDocStatus.OPT,
    [docStatus],
  );
  const [file, setFile] = useState<{ name: string; url: string } | null>(null);
  const onDelete = () => {
    setFile(null);
  };

  useEffect(() => {
    if (
      docStatus !== null &&
      docStatus.status !== EDocStatus.INP &&
      docStatus.status !== EDocStatus.OPT
    ) {
      setFile({
        name:
          docStatus.filename ||
          docStatus.uploadFileUrl
            .slice(docStatus.uploadFileUrl.lastIndexOf('/') + 1)
            .replace(/_\d{14}/, ''),
        url: docStatus.uploadFileUrl,
      });
    }
  }, [docStatus]);

  const onSubmit = () => {
    if (!file) return message.warning(messages.REQUIRED_FILE);
    if (file.url) {
      return message.warning('파일이 기존 파일과 같아 수정할 필요가 없습니다.');
    }
    if (!updateMode) {
      dispatch(
        addDocStatus({
          documentCode,
          productId: currentProduct.productId,
          documentFile: file,
          ...(countryId && { countryId }),
        }),
      );
    } else {
      dispatch(
        updateDocStatus({
          documentCode,
          productId,
          documentFile: file,
          ...(countryId && { countryId }),
          status: docStatus!.status,
          productDocStatusId: docStatus!.productDocStatusId,
        }),
      );
    }
  };
  return (
    <>
      <Row justify="end" gutter={8}>
        <Col>
          <CorrectRequestMessageModal documentCode={documentCode} />
        </Col>
        {updateMode && (
          <Col>
            <UpdateLog
              productId={productId}
              countryId={countryId}
              documentCode={documentCode}
            />
          </Col>
        )}
      </Row>
      <FileUploadTemplate
        footerVisible={footerVisible}
        updateMode={updateMode}
        readOnly={readOnlyMode}
        viewerVisible={viewerVisible}
        files={file}
        accept={accept}
        fetchLoading={fetchLoading}
        formUrl={formUrl}
        status={docStatus?.status}
        onUpload={setFile}
        onDelete={onDelete}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default ProductSingleFileContainer;
