import { useEffect, useState } from 'react';
import { Button, Col, Row, Table, Tabs } from 'antd';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';

import ManagerUpdateContainer from 'containers/auth/ManagerUpdateContainer';
import palette from 'lib/styles/palette';
import { Typography } from 'components/system';
import { IManager } from 'types/auth';
import { ColumnsType } from 'antd/lib/table';
import { useManufacturerCompanies } from 'service/manufacturer/company';

const ManagerListBlock = styled.div`
  & > .ant-table-wrapper table {
    border-collapse: separate;
    border-spacing: 0 8px;
  }

  .ant-table-row {
    cursor: pointer;

    & > .ant-table-cell {
      border: none;
      background-color: #f6f7f9;

      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        padding-left: 36px;
      }

      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        padding-right: 36px;
      }
    }
  }

  .ant-table-expanded-row {
    & > .ant-table-cell {
      background-color: #fff;
      border-bottom: none;
    }
  }

  .ant-table-title {
    padding: 0px;
  }

  .ant-tabs-tab {
    width: 160px;
    margin-right: 0px;
    justify-content: center;
  }
`;

const TableTitle = styled.div`
  display: flex;
  padding: 10px 36px;
  height: 40px;
  background-color: ${palette.lightBlue10};
`;

enum EManagerTabKey {
  ALL = 'ALL',
  BRAND = 'BRAND',
  MANUFACTURE = 'MANUFACTURE',
}

const ManagerList = ({
  getLoading = false,
  managers: initialManagers,
  onRegisterManager,
}: {
  getLoading: boolean;
  managers: IManager[];
  onRegisterManager: () => void;
}) => {
  const [managers, setManagers] = useState<IManager[]>();
  const { data: manufacturers = [] } = useManufacturerCompanies();
  useEffect(() => {
    if (initialManagers.length > 0) {
      setManagers(initialManagers);
    }
  }, [initialManagers]);

  const handleTabClick = (key: EManagerTabKey) => {
    if (key === EManagerTabKey.ALL) {
      setManagers(initialManagers);
    } else {
      setManagers(
        initialManagers.filter((manager) =>
          key === EManagerTabKey.BRAND
            ? !manager.isManufacturer
            : manager.isManufacturer,
        ),
      );
    }
  };
  const columns: ColumnsType<IManager> = [
    {
      width: 200,
      render: (_, { company, manufacturerId }) => (
        <Typography.Text type="secondary">
          {manufacturerId
            ? manufacturers.find(
                (manu) => manu.manufacturerId === manufacturerId,
              )?.companyNameKo
            : company.companyNameKo}
        </Typography.Text>
      ),
    },
    {
      width: 200,
      dataIndex: 'deptName',
    },
    {
      width: 200,
      render: (_, { username, name }) => (
        <Typography.Text type="secondary" style={{ maxWidth: 200 }}>
          {name} ({username})
        </Typography.Text>
      ),
    },
    { dataIndex: 'positionName', width: 160 },
  ];

  return (
    <ManagerListBlock>
      <Tabs onTabClick={(key) => handleTabClick(key as EManagerTabKey)}>
        <Tabs.TabPane key={EManagerTabKey.ALL} tab="전체" />
        <Tabs.TabPane key={EManagerTabKey.BRAND} tab="브랜드사" />
        <Tabs.TabPane key={EManagerTabKey.MANUFACTURE} tab="제조사" />
      </Tabs>
      <Table
        columns={columns}
        dataSource={managers}
        loading={getLoading}
        showHeader={false}
        rowKey="userId"
        title={() => (
          <TableTitle>
            <Typography.Text type="secondary" style={{ marginRight: 154 }}>
              회사명
            </Typography.Text>
            <Typography.Text type="secondary" style={{ marginRight: 186 }}>
              부서명
            </Typography.Text>
            <Typography.Text type="secondary" style={{ marginRight: 150 }}>
              이름 (아이디)
            </Typography.Text>
            <Typography.Text type="secondary">직급</Typography.Text>
          </TableTitle>
        )}
        expandable={{
          expandRowByClick: true,
          expandIconColumnIndex: 4,
          expandIcon: ({ expanded, record, onExpand }) => (
            <i
              className="material-icons cursor"
              style={{ userSelect: 'none' }}
              onClick={(e) => onExpand(record, e)}
            >
              {expanded ? 'expand_less' : 'expand_more'}
            </i>
          ),
          expandedRowRender: (manager) => (
            <ManagerUpdateContainer manager={manager} directMode={undefined} />
          ),
        }}
        footer={() => (
          <Row justify="end" style={{ marginTop: 12 }}>
            <Col>
              <Button
                onClick={onRegisterManager}
                icon={<PlusOutlined style={{ color: palette.text.primary }} />}
              >
                담당자 추가
              </Button>
            </Col>
          </Row>
        )}
      />
    </ManagerListBlock>
  );
};

export default ManagerList;
