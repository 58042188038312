import { Col, List, Row } from 'antd';
import styled from 'styled-components';

import { Typography } from 'components/system';
import palette from 'lib/styles/palette';

const StyledList = styled(List)`
  .ant-list-header {
    background: #f9f9f9;
  }
`;

const Documents = ({ documents }: { documents: string[] }) => {
  return (
    <>
      <Row
        align="middle"
        gutter={16}
        style={{ marginTop: 36, marginBottom: 12 }}
      >
        <Col>
          <Typography.Title>보유 서류 및 인증 항목</Typography.Title>
        </Col>
      </Row>
      <StyledList
        style={{
          border: 'none',
          borderRadius: 0,
          borderTop: `2px solid ${palette.primary}`,
          borderBottom: `1px solid #f0f0f0`,
          textAlign: 'center',
        }}
        header={
          <Typography.Text medium color="slate">
            보유 서류 및 인증 항목
          </Typography.Text>
        }
        bordered
        dataSource={documents}
        rowKey={(document) => document as string}
        renderItem={(document: any) => (
          <List.Item style={{ justifyContent: 'center' }}>
            <Typography.Text type="secondary" medium color="slate">
              {document}
            </Typography.Text>
          </List.Item>
        )}
      />
    </>
  );
};

export default Documents;
