import qs from 'qs';

import { IAPIPageableResponse, IAPIResponse } from 'types/common';
import client from 'lib/api/client';
import {
  IMaterialRegister,
  IMaterialRegisterGet,
} from 'types/material/register';

export const getRegisters = async (params: IMaterialRegisterGet) => {
  const {
    status,
    pageNo,
    pageItemCount = 10,
    materialNameEn,
    originManufacturerName,
  } = params;
  const res = await client.get<IAPIPageableResponse<IMaterialRegister[]>>(
    `/materials/${status}/${pageNo}/${pageItemCount}?${qs.stringify({
      materialNameEn,
      originManufacturerName,
    })}`,
  );
  return res.data;
};

export const deleteMaterials = async (materialIds: number[]) => {
  const res = await client.delete<IAPIResponse<null>>(
    `/material?materialIds=${materialIds}`,
  );
  return res.data;
};

export const discontinueMaterials = async (materialIds: number[]) => {
  const res = await client.patch<IAPIResponse<null>>(
    `/material/discontinue?materialIds=${materialIds}`,
  );
  return res.data;
};
