import { useMemo } from 'react';
import styled from 'styled-components';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { deviceSize } from 'lib/styles';
import { Typography } from 'components/system';

const EstimateListBlock = styled.div`
  padding: 0 16px;

  @media ${deviceSize.sm} {
    padding: 0;
  }
`;

const EstimateList = ({
  estimates,
  page,
  getLoading,
  onPreview,
  onChangePage,
  onShowEstimateHistories,
  onShowEstimateAdditionals,
}: any) => {
  const columns: ColumnsType<any> = useMemo(
    () => [
      { title: '견적서 번호', dataIndex: 'estimateCode', align: 'center' },
      {
        title: '견적서 발행일',
        dataIndex: 'registerDt',
        align: 'center',
        render: (registerDt) => registerDt.slice(0, 10),
      },
      {
        title: '견적 금액',
        dataIndex: 'totalPrice',
        align: 'center',
        render: (totalPrice) => `${totalPrice.toLocaleString()}원`,
      },
      {
        title: '입금 확인',
        dataIndex: 'isDepositFinish',
        align: 'center',
        render: (isDepositFinish) => (
          <Typography.Label type={isDepositFinish ? 'success' : 'disabled'}>
            {isDepositFinish ? '완료' : '대기중'}
          </Typography.Label>
        ),
      },
      {
        title: '계약서 수령',
        dataIndex: 'isReceiveContractFinish',
        align: 'center',
        render: (isReceiveContractFinish) => (
          <Typography.Label
            type={isReceiveContractFinish ? 'success' : 'disabled'}
          >
            {isReceiveContractFinish ? '완료' : '대기중'}
          </Typography.Label>
        ),
      },
      {
        title: '견적서',
        dataIndex: 'fileUrl',
        align: 'center',
        render: (fileUrl) => (
          <i
            className="material-icons outlined cursor black"
            onClick={() => onPreview(fileUrl)}
          >
            description
          </i>
        ),
      },
      {
        title: '견적서 변경 이력',
        dataIndex: 'estimateId',
        align: 'center',
        render: (estimateId) => (
          <Typography.Label
            type="primary"
            onClick={() => onShowEstimateHistories(estimateId)}
          >
            확인하기
            <i className="material-icons outlined md-12">chevron_right</i>
          </Typography.Label>
        ),
      },
      {
        title: '추가견적',
        dataIndex: 'estimateAdditionals',
        align: 'center',
        render: (estimateAdditionals) =>
          estimateAdditionals && (
            <Typography.Label
              type="primary"
              onClick={() => onShowEstimateAdditionals(estimateAdditionals)}
            >
              확인하기
              <i className="material-icons outlined md-12">chevron_right</i>
            </Typography.Label>
          ),
      },
    ],
    [onShowEstimateHistories],
  );

  return (
    <EstimateListBlock>
      <Table
        loading={getLoading}
        columns={columns}
        dataSource={estimates.list}
        rowKey={({ estimateId }) => estimateId}
        pagination={{
          position: ['bottomCenter'],
          pageSize: estimates.size,
          current: page,
          total: estimates.total,
          onChange: onChangePage,
        }}
      />
    </EstimateListBlock>
  );
};

export default EstimateList;
