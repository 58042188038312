import { DownloadOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row, Typography } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';

import { s3AssetDomain } from 'lib/consts';
import { downloadFile, downloadFiles } from 'lib/file';

const PaymentBlock = styled.div`
  width: 100%;
`;

const PaymentInfo = () => {
  const [downloadLoading, setDownloadLoading] = useState(false);

  return (
    <PaymentBlock>
      <Typography.Title level={5} style={{ fontWeight: 500 }}>
        결제 방법
      </Typography.Title>
      <Typography.Paragraph style={{ paddingLeft: 16, marginTop: 16 }}>
        계좌 이체
      </Typography.Paragraph>
      <Row style={{ paddingLeft: 32 }} gutter={[16, 8]} align="middle" wrap>
        <Col flex="0 0 44px" sm={24} xs={0}>
          <img
            style={{ width: '100%' }}
            src={`${s3AssetDomain}/hanabank.png`}
            alt="bank logo"
          />
        </Col>
        <Col flex="0 0 auto">하나은행</Col>
        <Col flex="0 0 auto">340-910039-73304</Col>
        <Col flex="auto" sm={24} xs={0}>
          <Divider style={{ margin: 0 }} />
        </Col>
        <Col
          flex="0 0 auto"
          xs={{ span: 24 }}
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() =>
            downloadFile(
              'https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/document/30COS+%E1%84%90%E1%85%A9%E1%86%BC%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A1%E1%84%87%E1%85%A9%E1%86%AB_V1.pdf',
              '30COS 통장사본.pdf',
            )
          }
        >
          <DownloadOutlined
            style={{ cursor: 'pointer', fontSize: 16, paddingRight: 8 }}
          />
          <Typography.Text underline>통장사본 다운로드</Typography.Text>
        </Col>
      </Row>
      <Row
        style={{ marginTop: 16, paddingLeft: 16 }}
        gutter={16}
        align="middle"
        wrap={false}
      >
        <Col flex="0 0 auto">사업자등록증 사본</Col>
        <Col flex="1 1 100%">
          <Divider style={{ margin: 0 }} />
        </Col>
        <Col
          flex="0 0 auto"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() =>
            downloadFile(
              'https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/document/30COS+%E1%84%89%E1%85%A1%E1%84%8B%E1%85%A5%E1%86%B8%E1%84%8C%E1%85%A1+%E1%84%83%E1%85%B3%E1%86%BC%E1%84%85%E1%85%A9%E1%86%A8%E1%84%8C%E1%85%B3%E1%86%BC_V1.pdf',
              '30COS 사업자 등록증.pdf',
            )
          }
        >
          <FilePdfOutlined
            style={{ cursor: 'pointer', fontSize: 16, paddingRight: 8 }}
          />
          <Typography.Text underline>PDF 다운로드</Typography.Text>
        </Col>
      </Row>
      <Typography.Title level={5} style={{ marginTop: 40, fontWeight: 500 }}>
        계약서 다운로드
      </Typography.Title>
      <Row
        style={{ marginTop: 16, paddingLeft: 16 }}
        gutter={16}
        align="middle"
        wrap={false}
      >
        <Col flex="0 0 auto">이용약관</Col>
        <Col flex="1 1 100%">
          <Divider style={{ margin: 0 }} />
        </Col>
        <Col
          flex="0 0 auto"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() =>
            downloadFile(
              'https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/document/30COS_%E1%84%8B%E1%85%B5%E1%84%8B%E1%85%AD%E1%86%BC%E1%84%8B%E1%85%A3%E1%86%A8%E1%84%80%E1%85%AA%E1%86%AB_V1.pdf',
              '30COS 이용약관.pdf',
            )
          }
        >
          <FilePdfOutlined
            style={{ cursor: 'pointer', fontSize: 16, paddingRight: 8 }}
          />
          <Typography.Text underline>PDF 다운로드</Typography.Text>
        </Col>
      </Row>

      <Row
        style={{ marginTop: 16, paddingLeft: 16 }}
        gutter={16}
        align="middle"
        wrap={false}
      >
        <Col flex="0 0 auto">비밀유지계약서</Col>
        <Col flex="1 1 100%">
          <Divider style={{ margin: 0 }} />
        </Col>
        <Col
          flex="0 0 auto"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() =>
            downloadFile(
              'https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/document/%E1%84%87%E1%85%B5%E1%84%86%E1%85%B5%E1%86%AF%E1%84%8B%E1%85%B2%E1%84%8C%E1%85%B5%E1%84%80%E1%85%A8%E1%84%8B%E1%85%A3%E1%86%A8%E1%84%89%E1%85%A5_V1.pdf',
              '30COS 비밀유지계약서.pdf',
            )
          }
        >
          <FilePdfOutlined
            style={{ cursor: 'pointer', fontSize: 16, paddingRight: 8 }}
          />
          <Typography.Text underline>PDF 다운로드</Typography.Text>
        </Col>
      </Row>
      <Row
        style={{ marginTop: 16, paddingLeft: 16 }}
        gutter={16}
        align="middle"
        wrap={false}
      >
        <Col flex="0 0 auto">화장품 인증대행 업무 계약서</Col>
        <Col flex="1 1 100%">
          <Divider style={{ margin: 0 }} />
        </Col>
        <Col
          flex="0 0 auto"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() =>
            downloadFile(
              'https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/document/%E1%84%92%E1%85%AA%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%91%E1%85%AE%E1%86%B7+%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%8C%E1%85%B3%E1%86%BC+%E1%84%83%E1%85%A2%E1%84%92%E1%85%A2%E1%86%BC+%E1%84%8B%E1%85%A5%E1%86%B8%E1%84%86%E1%85%AE+%E1%84%80%E1%85%A8%E1%84%8B%E1%85%A3%E1%86%A8%E1%84%89%E1%85%A5_V7.pdf',
              '30COS 표준 계약서 양식.pdf',
            )
          }
        >
          <FilePdfOutlined
            style={{ cursor: 'pointer', fontSize: 16, paddingRight: 8 }}
          />
          <Typography.Text underline>PDF 다운로드</Typography.Text>
        </Col>
      </Row>
      <Button
        icon={<DownloadOutlined />}
        loading={downloadLoading}
        style={{ display: 'block', width: 222, margin: '24px auto 0' }}
        onClick={async () => {
          setDownloadLoading(true);
          await downloadFiles(
            [
              {
                name: '30COS 통장사본',
                url:
                  'https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/document/30COS+%E1%84%90%E1%85%A9%E1%86%BC%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A1%E1%84%87%E1%85%A9%E1%86%AB_V1.pdf',
              },
              {
                name: '30COS 사업자 등록증',
                url:
                  'https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/document/30COS+%E1%84%89%E1%85%A1%E1%84%8B%E1%85%A5%E1%86%B8%E1%84%8C%E1%85%A1+%E1%84%83%E1%85%B3%E1%86%BC%E1%84%85%E1%85%A9%E1%86%A8%E1%84%8C%E1%85%B3%E1%86%BC_V1.pdf',
              },
              {
                name: '30COS 이용약관',
                url:
                  'https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/document/30COS_%E1%84%8B%E1%85%B5%E1%84%8B%E1%85%AD%E1%86%BC%E1%84%8B%E1%85%A3%E1%86%A8%E1%84%80%E1%85%AA%E1%86%AB_V1.pdf',
              },
              {
                name: '30COS 비밀유지계약서',
                url:
                  'https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/document/%E1%84%87%E1%85%B5%E1%84%86%E1%85%B5%E1%86%AF%E1%84%8B%E1%85%B2%E1%84%8C%E1%85%B5%E1%84%80%E1%85%A8%E1%84%8B%E1%85%A3%E1%86%A8%E1%84%89%E1%85%A5_V1.pdf',
              },
              {
                name: '30COS 표준 계약서 양식',
                url:
                  'https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/document/%E1%84%92%E1%85%AA%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%91%E1%85%AE%E1%86%B7+%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%8C%E1%85%B3%E1%86%BC+%E1%84%83%E1%85%A2%E1%84%92%E1%85%A2%E1%86%BC+%E1%84%8B%E1%85%A5%E1%86%B8%E1%84%86%E1%85%AE+%E1%84%80%E1%85%A8%E1%84%8B%E1%85%A3%E1%86%A8%E1%84%89%E1%85%A5_V7.pdf',
              },
            ],
            '30COS_계약관련서류.zip',
          );
          setDownloadLoading(false);
        }}
      >
        모든 자료 한번에 다운로드 받기
      </Button>
    </PaymentBlock>
  );
};

export default PaymentInfo;
