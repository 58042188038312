import { Form, Radio, Button, Spin } from 'antd';

import styled from 'styled-components';
import FooterBox from 'components/FooterBox';
import FileUploadContainer from 'containers/file/FileUploadContainer';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import { useCertificateMode } from 'hook/certificate';

const ProductAllergensListBlock = styled.div`
  .ant-form-item-control-input {
    min-height: auto;
  }
  .ant-radio-wrapper {
    line-height: 24px;
  }
`;

const ProductAllergensList = ({
  updateMode,
  readOnlyMode,
  form,
  file,
  fetchLoading,
  submitLoading,
  onUpload,
  onDelete,
  onSubmit,
}: any) => {
  const certificateMode = useCertificateMode();

  return (
    <ProductAllergensListBlock>
      <Form layout="vertical" form={form} onFinish={onSubmit}>
        <Spin spinning={fetchLoading === true}>
          <div style={{ maxWidth: 400, margin: '0 auto' }}>
            <Form.Item
              label="향료 유무"
              name="isOdourless"
              required
              initialValue={true}
            >
              <Radio.Group disabled={readOnlyMode}>
                <Radio value={true} style={{ marginRight: 32 }}>
                  무향 (향료가 없음)
                </Radio>
                <Radio value={false}>유향 (향료가 있음)</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              shouldUpdate={(prev, curr) =>
                prev.isOdourless !== curr.isOdourless
              }
              noStyle
            >
              {() =>
                !form.getFieldValue('isOdourless') ? (
                  <Form.Item
                    label="구분"
                    name="isNonAllergenOdour"
                    initialValue={true}
                  >
                    <Radio.Group disabled={readOnlyMode}>
                      <Radio value={true} style={{ marginRight: 32 }}>
                        알러지 프리 (Non-Allergen) 향료
                      </Radio>
                      <Radio value={false}>알러지 유발 성분 포함 향료</Radio>
                    </Radio.Group>
                  </Form.Item>
                ) : null
              }
            </Form.Item>
          </div>
        </Spin>
        <Form.Item
          shouldUpdate={(prev, curr) => prev.isOdourless !== curr.isOdourless}
          noStyle
        >
          {() =>
            !form.getFieldValue('isOdourless') ? (
              <FileUploadContainer
                readOnly={readOnlyMode}
                files={file}
                accept=".pdf"
                style={{ marginTop: 24 }}
                onUpload={onUpload}
                onDelete={onDelete}
              />
            ) : null
          }
        </Form.Item>
        <FooterBox>
          <ReadOnlyBackButton readOnly={readOnlyMode}>
            <Button type="primary" htmlType="submit" loading={submitLoading}>
              {!updateMode ? '등록' : !certificateMode ? '수정' : '보완 완료'}
            </Button>
          </ReadOnlyBackButton>
        </FooterBox>
      </Form>
    </ProductAllergensListBlock>
  );
};

export default ProductAllergensList;
