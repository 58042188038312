import { Button, Form, Input, message, Modal } from 'antd';
import FooterBox from 'components/FooterBox';

import { setChangePasswordModalVisible } from 'modules/auth';
import { useDispatch, useSelector } from 'react-redux';
import * as authActions from 'modules/auth';
import { requireRule } from 'lib/validate';
import { useUserType } from 'service/auth';

const ChangePasswordModal = () => {
  const dispatch = useDispatch();

  const userType = useUserType();
  const { userId, isManufacturer, visible, submitLoading } = useSelector(
    ({ auth, loading }: any) => ({
      visible: auth.changePasswordModalVisible,
      userId: auth.user.userId,
      isManufacturer: auth.user.isManufacturer,
      submitLoading: loading['auth/CHANGE_PASSWORD'],
    }),
  );
  const changePassword = (passwordForm: any) => {
    if (passwordForm.password !== passwordForm.passwordCheck) {
      return message.warn(
        '새로운 비밀번호와 비밀번호 확인이 일치하지 않습니다.',
      );
    }
    if (passwordForm.prevPassword === passwordForm.password) {
      return message.warn('이전 비밀번호와 새로운 비밀번호가 같습니다.');
    }
    dispatch(
      authActions.changePassword({
        userId,
        isManufacturer,
        userType,
        prevPassword: passwordForm.prevPassword,
        password: passwordForm.password,
      }),
    );
  };
  return (
    <Modal
      visible={visible}
      title="비밀번호 변경"
      footer={null}
      destroyOnClose
      onCancel={() => dispatch(setChangePasswordModalVisible(false))}
    >
      <Form labelCol={{ span: 8 }} colon={false} onFinish={changePassword}>
        <Form.Item
          label="이전 비밀번호"
          name="prevPassword"
          rules={[requireRule]}
        >
          <Input.Password autoComplete="new-password" />
        </Form.Item>
        <Form.Item
          label="새로운 비밀번호"
          name="password"
          rules={[requireRule]}
        >
          <Input.Password autoComplete="new-password" />
        </Form.Item>
        <Form.Item
          label="비밀번호 확인"
          name="passwordCheck"
          rules={[requireRule]}
        >
          <Input.Password autoComplete="new-password" />
        </Form.Item>
        <FooterBox>
          <Button type="primary" loading={submitLoading} htmlType="submit">
            변경하기
          </Button>
        </FooterBox>
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;
