import styled from 'styled-components';

import MistakeNoticeItem from 'components/MistakeNoticeItem';
import ProductPageTemplate from 'templates/ProductPageTemplate';
import { Typography } from 'components/system';
import ProductChallengeTest from 'components/product/ProductChallengeTest';
import palette from 'lib/styles/palette';
import { s3AssetDomain } from 'lib/consts';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';

const notices = [
  '해당 서류는 유통화장품 안전관리 기준에 맞춰 대장균(Escherichia coli), 녹농균(Pseudomonas aeruginosa), 황색 포도상구균(Staphylococcus aureus) 3종을 기본으로 발행됩니다.',
  <MistakeNoticeItem />,
];

const NoticeWrapper = styled.div`
  width: 720px;
  margin: 0 auto 24px;
`;

const Notice = styled.div`
  text-align: center;
  border: solid 1px #ededed;
  background-color: ${palette.lightBrown};
  padding: 16px 0;
  margin-top: 8px;
`;

const ProductChallengeTestPage = () => {
  const { productId } = useCurrentProduct();
  const countryId = useCountryId();
  return (
    <ProductPageTemplate subtitle="Challenge Test" notices={notices}>
      <NoticeWrapper style={{ marginTop: 20 }}>
        <img
          style={{ width: 720, height: 326 }}
          alt="challenge_text_example"
          src={`${s3AssetDomain}/challenge_test_example.jpg`}
        />
        <Notice>
          <Typography.Text type="tertiary" color="primary" medium inline>
            ISO 29621:2017 기준
          </Typography.Text>
          <Typography.Text type="tertiary" color="slate" medium inline>
            에 따라 제품의 규격이 위 표와 부합하는 경우, 혹은 일회용 제품
            (마스크팩 등)으로
            <br />
            방부력 테스트가 필요 없는 경우에는 결과 값을 30COS에 입력하지
            않으셔도 됩니다.
          </Typography.Text>
        </Notice>
      </NoticeWrapper>
      <ProductChallengeTest productId={productId} countryId={countryId} />
    </ProductPageTemplate>
  );
};

export default ProductChallengeTestPage;
