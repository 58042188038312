import { Modal, Steps } from 'antd';
import path from 'lib/path';
import { Link } from 'react-router-dom';

const steps = [
  {
    code: 'REG-FIN',
    title: '접수 완료',
    content: '해당 제품의 접수가 완료되었습니다.',
  },
  {
    code: 'CHK-DOC',
    title: '서류 검토중',
    content:
      '입력하신 정보와 업로드 하신 자료들을 현지 에이전시 혹은 바이어가 정보 검토 중에 있습니다.',
  },
  {
    code: 'CHK-SAM',
    title: '샘플 검역중',
    content:
      '샘플을 현지 국가로 배송중에 있거나, 현지 식약처에서 검역중에 있습니다.',
  },
  {
    code: 'CER-FIN',
    title: '인증 완료',
    content: (
      <>
        <span>인증이 완료되어 해당 제품이</span>{' '}
        <Link
          to={path.certificate.complete}
          style={{ textDecoration: 'underline' }}
        >
          인증 완료 제품 관리
        </Link>
        <span>로 이동됩니다.</span>
      </>
    ),
  },
];

const CertificateProgressModal = ({ currentStep, onClose }: any) => {
  const currentStepIndex = steps.findIndex((step) => step.code === currentStep);
  return (
    <Modal
      title="30COS 진행상태 단계별 안내"
      footer={null}
      width={1000}
      visible={currentStep !== null}
      onCancel={onClose}
    >
      <Steps>
        {steps.map(({ code, title }, index) => {
          const status =
            currentStepIndex === index
              ? 'process'
              : currentStepIndex > index
              ? 'finish'
              : 'wait';
          return <Steps.Step key={code} title={title} status={status} />;
        })}
      </Steps>

      <ol style={{ paddingLeft: 16, marginTop: 32 }}>
        {steps.map(({ code, title, content }) => (
          <li key={code} style={{ marginBottom: 4 }}>
            {title} : {content}
          </li>
        ))}
      </ol>
    </Modal>
  );
};

export default CertificateProgressModal;
