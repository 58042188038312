import { useMemo } from 'react';

import MistakeNoticeItem from 'components/MistakeNoticeItem';
import ProductPackingAttestation from 'components/product/ProductPackingAttestation';
import { useManuMode } from 'hook/company';
import ProductPageTemplate from 'templates/ProductPageTemplate';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';

const ProductPackingAttestationPage = () => {
  const { productId } = useCurrentProduct();
  const countryId = useCountryId();
  const manuMode = useManuMode();
  const notices = useMemo(
    () => [
      ...(manuMode
        ? [
            '브랜드사에서 용기를 사급하여 용기 재질 정보를 모르실 경우, 브랜드사 사급을 선택하시면 브랜드사에서 입력하도록 해당 페이지를 건너뛰실 수 있습니다.',
          ]
        : []),
      '"1차 포장"이란 화장품 제조 시 내용물과 직접 접촉하는 포장 용기입니다.',
      '"2차 포장"이란 1차 포장을 수용하는 1개 또는 그 이상의 포장과 보호재 및 표시의 목적으로 한 포장(첨부문서 등을 포함)입니다.',
      '1차 포장 용기(제품용기), 2차 포장 용기(단상자)의 모든 재질을 영문(알파벳 대문자)으로 나열해 주세요. ex) Body : ABL /  CAP : PP 또는 BODY : PET / PUMP : PP',
      '사진 첨부란에는 파일 업로드를 클릭한 뒤, 이미지(jpg, jpeg, gif 등) 파일을 업로드해 주세요.',
      <MistakeNoticeItem />,
    ],
    [manuMode],
  );
  return (
    <ProductPageTemplate subtitle="Packing Attestation" notices={notices}>
      <ProductPackingAttestation productId={productId} countryId={countryId} />
    </ProductPageTemplate>
  );
};

export default ProductPackingAttestationPage;
