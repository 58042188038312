import { Col, Row, Select } from 'antd';

interface IProps {
  pageSize: number;
  onChange: (pageSize: number) => void;
}

export const pageSizeOptions = [
  { label: '10개씩 보기', value: 10 },
  { label: '50개씩 보기', value: 50 },
];

const PageSizeSelect = ({ pageSize, onChange }: IProps) => {
  return (
    <Row justify="end" style={{ marginBottom: 16 }}>
      <Col>
        <Select
          value={pageSize}
          options={pageSizeOptions}
          onChange={onChange}
        />
      </Col>
    </Row>
  );
};

export default PageSizeSelect;
