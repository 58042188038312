import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'antd';

import palette from 'lib/styles/palette';
import { skipPage } from 'modules/product';
import { useManuMode, useRetailerMode } from 'hook/company';
import PageTemplate, { IPageTemplateProps } from 'templates/PageTemplate';
import { Typography } from 'components/system';
import * as S from 'templates/PageTemplateStyle';
import { useProductDetail } from 'service/product';
interface IProps extends IPageTemplateProps {
  subtitle: React.ReactNode;
  hasSkip?: boolean;
  maxWidth?: number;
  back?: boolean;
}

const ProductPageTemplate = ({
  subtitle,
  hasSkip = true,
  maxWidth = 920,
  back = true,
  ...rest
}: IProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const manuMode = useManuMode();
  const retailerMode = useRetailerMode();
  const params = useParams<{ productId?: string }>();
  const productId = Number(params.productId) || undefined;
  const { productDetail } = useProductDetail({ productId });
  const { currentProduct, skipMode } = useSelector(
    ({ product }: any) => ({
      skipMode: product.skipMode,
      currentProduct: product.currentProduct,
    }),
    shallowEqual,
  );
  const status = currentProduct?.status || 'PRD-RDY';
  const moveToNextPage = () => {
    dispatch(skipPage());
  };

  const checkHasProduct = () => {
    if (retailerMode) {
      return !!productDetail;
    }
    if (!manuMode) {
      return !!currentProduct?.productDetail;
    }

    return !!currentProduct;
  };

  const generateSubtitle = () => {
    if (manuMode) {
      return '자체 품목명 (용량)';
    } else if (retailerMode) {
      return '국문 제품명 (용량)';
    }

    return '제품명 (용량)';
  };

  const generateProductName = () => {
    if (retailerMode) {
      return `${productDetail?.productNameKo || '-'} (${
        productDetail?.netWeight
      } ${productDetail?.netWeightUnit})`;
    }
    if (!manuMode) {
      return `${currentProduct?.productDetail?.productNameEn || '-'} (${
        currentProduct?.netWeight
      } ${currentProduct?.netWeightUnit})`;
    }

    return `${currentProduct?.manufacturerItemNameKo || '-'} (${
      currentProduct?.netWeight
    } ${currentProduct?.netWeightUnit})`;
  };

  return (
    <S.PageTemplateBlock>
      <PageTemplate
        {...rest}
        title={status === 'PRD-RDY' ? '제품 등록' : '제품 관리'}
        subtitle={
          <>
            <S.NameContainer>
              <Typography.Text style={{ fontSize: 20 }} gutter={{ right: 16 }}>
                {subtitle}
              </Typography.Text>
              {checkHasProduct() && (
                <>
                  <Typography.Text
                    type="secondary"
                    inline
                    gutter={{ right: 4 }}
                    className="label"
                  >
                    {generateSubtitle()}
                  </Typography.Text>
                  <Typography.Title
                    type="secondary"
                    medium
                    className="product-name"
                    inline
                    style={{ maxWidth: manuMode ? 530 : 580 }}
                  >
                    {generateProductName()}
                  </Typography.Title>
                </>
              )}
            </S.NameContainer>
          </>
        }
        back={back}
        onBack={history.goBack}
      />

      {hasSkip && skipMode && (
        <Button
          style={{
            position: 'absolute',
            top: 20,
            right: `calc((100% - ${maxWidth}px) / 2)`,
            zIndex: 1,
            height: 32,
            border: `1px solid ${palette.inactive}`,
            color: `${palette.text.gray}`,
          }}
          type="ghost"
          onClick={moveToNextPage}
        >
          나중에 입력
          <i
            className="material-icons md-12"
            style={{
              color: `${palette.text.gray}`,
              marginLeft: 4,
              marginRight: 0,
            }}
          >
            east
          </i>
        </Button>
      )}
    </S.PageTemplateBlock>
  );
};

export default ProductPageTemplate;
