import { DownloadOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row } from 'antd';
import printJS from 'print-js';
import styled from 'styled-components';

import FileViewer from 'components/file/FileViewer';
import { downloadFile } from 'lib/file';

const FileViewerListBlock = styled.div`
  padding-top: 28px;
`;

const FileViewWrap = styled.div`
  & + & {
    margin-top: 16px;
  }
`;

const DocumentPreviewModal = ({
  documentUrls,
  footerVisible = true,
  onClose,
}: {
  documentUrls: string[] | string | null;
  footerVisible?: boolean;
  onClose: () => void;
}) => {
  return (
    <Modal
      visible={
        documentUrls instanceof Array ? documentUrls.length > 0 : !!documentUrls
      }
      destroyOnClose
      onCancel={onClose}
      footer={null}
      width={992}
    >
      {documentUrls && (
        <FileViewerListBlock>
          {(documentUrls instanceof Array ? documentUrls : [documentUrls]).map(
            (documentUrl) => (
              <FileViewWrap key={documentUrl}>
                <FileViewer key={documentUrl} file={documentUrl} />
                {footerVisible && (
                  <Row gutter={8} justify="end" style={{ marginTop: 8 }}>
                    <Col>
                      <Button
                        type="primary"
                        icon={<DownloadOutlined />}
                        onClick={() => downloadFile(documentUrl)}
                      >
                        다운로드
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        type="primary"
                        icon={<PrinterOutlined />}
                        onClick={() => printJS(documentUrl)}
                      >
                        인쇄하기
                      </Button>
                    </Col>
                  </Row>
                )}
              </FileViewWrap>
            ),
          )}
        </FileViewerListBlock>
      )}
    </Modal>
  );
};

export default DocumentPreviewModal;
