import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';

import * as checklistApi from 'lib/api/checklist';
import { IChecklistItem } from 'types/brand/checklist';

export const useChecklist = ({
  productId,
  countryId,
  updateMode,
}: {
  productId: number;
  countryId: number;
  updateMode: boolean;
}) => {
  const { data: originalChecklist = [], isLoading: getLoading } = useQuery(
    ['estimate/getChecklist', productId, countryId],
    () => checklistApi.getChecklist(countryId),
    { select: (res) => res.data.result },
  );
  const {
    data: checklistAnswers = null,
    isLoading: getChecklistAnswersLoading,
  } = useQuery(
    ['estimate/getChecklistAnswers', productId, countryId],
    () => checklistApi.getChecklistAnswers({ productId, countryId }),
    { select: (res) => res.data.result },
  );
  const [checklist, setChecklist] = useState<IChecklistItem[]>([]);
  const resetChecklist = () => {
    setChecklist([originalChecklist[0]]);
  };
  useEffect(() => {
    if (originalChecklist.length > 0) {
      if (updateMode) {
        setChecklist(originalChecklist);
      } else {
        setChecklist([originalChecklist[0]]);
      }
    }
  }, [originalChecklist, checklistAnswers]);
  const addChecklistItem = useCallback((checklistItem: IChecklistItem) => {
    setChecklist((draft) => draft.concat(checklistItem));
  }, []);
  return useMemo(
    () => ({
      checklist,
      originalChecklist,
      checklistAnswers,
      getLoading: getLoading || getChecklistAnswersLoading,
      resetChecklist,
      addChecklistItem,
    }),
    [
      getLoading,
      originalChecklist,
      checklistAnswers,
      getChecklistAnswersLoading,
      resetChecklist,
      addChecklistItem,
    ],
  );
};

export const useAnswerChecklist = () => {
  const queryClient = useQueryClient();
  const { mutate: answerChecklist, isLoading } = useMutation(
    (params: Parameters<typeof checklistApi.answerChecklist>[0]) =>
      checklistApi.answerChecklist(params),
    {
      onSuccess: () => {
        queryClient.refetchQueries('estimate/getEstimateTargets');
      },
    },
  );
  return useMemo(() => ({ answerChecklist, isLoading }), [
    answerChecklist,
    isLoading,
  ]);
};
