import { useForm } from 'antd/es/form/Form';
import ProductImport from 'components/product/ProductImport';
import * as productActions from 'modules/product';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useManufacturerCompanies } from 'service/manufacturer/company';

const ProductImportContainer = () => {
  const dispatch = useDispatch();
  const { data: manufacturers = [] } = useManufacturerCompanies();
  const { submitLoading } = useSelector(
    ({ loading }) => ({
      submitLoading: loading['product/IMPORT_PRODUCTS'],
    }),
    shallowEqual,
  );
  const [form] = useForm();
  const handleSubmit = (formValue) => {
    dispatch(productActions.importProducts(formValue));
  };
  return (
    <ProductImport
      manufacturers={manufacturers}
      form={form}
      submitLoading={submitLoading}
      onSubmit={handleSubmit}
    />
  );
};

export default ProductImportContainer;
