import { useDispatch } from 'react-redux';

import { openLoginModal } from 'modules/modal';

const useOpenLoginModal = () => {
  const dispatch = useDispatch();
  return () => dispatch(openLoginModal());
};

export default useOpenLoginModal;
