import qs from 'qs';

import { IBrandCompany } from 'types/brand/company';
import { IAPIResponse } from 'types/common';
import client from 'lib/api/client';

export const getBrandCompanies = (brandCompaniesParams?: {
  companyNameKo?: string;
  isExcludeUserInserted?: boolean;
}) =>
  client.get<IAPIResponse<IBrandCompany[]>>(
    `/pub/brand-companies?${qs.stringify({
      companyNameKo: brandCompaniesParams?.companyNameKo || '',
      isExcludeUserInserted:
        brandCompaniesParams?.isExcludeUserInserted || false,
    })}`,
  );
