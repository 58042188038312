import { Button, Col, Drawer, List, Row, Tag } from 'antd';
import styled, { css } from 'styled-components';
import parse from 'html-react-parser';
import moment from 'moment';

import palette from 'lib/styles/palette';
import Typography from 'components/system/general/Typography';
import { CloseIcon } from 'components/system/general/icon';

const StyledList = styled(List)`
  height: calc(100vh - 58px);
  overflow: scroll;
`;

const UserNoticeItem = styled<any>(List.Item)`
  display: block;
  cursor: ${(props) =>
    !props.$isConfirm || props.$hasLink ? 'pointer' : 'default'};
  padding: 16px;
  border: 1px solid #f3f3f3;
  background-color: #f6f6f6;
  color: #4e5968;

  ${(props) =>
    props.$isConfirm &&
    css`
      .ant-tag {
        color: ${palette.text.disabled};
      }
    `}

  p {
    margin-bottom: 0;
    max-height: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 16px;
  }
`;

const DrawerHeader = styled(Row)`
  padding: 16px;
`;

const ReadAllButton = styled(Button)`
  position: relative;
  top: -2px;
  height: 20px;
  font-size: 10px;
  padding: 2px 4px 3px;
  border-color: ${palette.primary};
`;

const UserNoticeDrawer = ({
  visible,
  userNotices,
  onClickUserNoticeItem,
  onClickConfirmAll,
  onCloseUserNoticeDrawer,
}: any) => {
  return (
    <Drawer
      width={320}
      visible={visible}
      closable={false}
      maskClosable={true}
      bodyStyle={{ padding: 0 }}
      onClose={onCloseUserNoticeDrawer}
    >
      <DrawerHeader align="middle">
        <Col>
          <Typography.Title type="secondary" style={{ flex: 'auto' }}>
            알림 목록
          </Typography.Title>
        </Col>
        <Col flex="auto" style={{ marginLeft: 16 }}>
          <ReadAllButton onClick={onClickConfirmAll}>모두 읽음</ReadAllButton>
        </Col>
        <Col>
          <CloseIcon onClick={onCloseUserNoticeDrawer} />
        </Col>
      </DrawerHeader>
      <StyledList
        dataSource={userNotices}
        rowKey="userNoticeId"
        renderItem={(userNotice: any) => (
          <UserNoticeItem
            $isConfirm={userNotice.isConfirm}
            $hasLink={userNotice.noticePath !== null}
            onClick={() => onClickUserNoticeItem(userNotice)}
          >
            <Row gutter={8} style={{ marginBottom: 8 }} justify="space-between">
              <Col>
                <Tag
                  color={userNotice.isConfirm ? palette.disabled : '#4e5968'}
                  style={{
                    margin: 0,
                    padding: '0 4px',
                    height: 24,
                    fontWeight: 500,
                    lineHeight: '24px',
                  }}
                >
                  {userNotice.title || '알림'}
                </Tag>
              </Col>
              <Col>
                <Typography.Text
                  style={{
                    fontSize: 10,
                    color: palette.text.disabled,
                  }}
                >
                  {moment(userNotice.registerDt).fromNow()}
                </Typography.Text>
              </Col>
            </Row>
            <Typography.Text type="secondary">
              {parse(userNotice.content)}
            </Typography.Text>
          </UserNoticeItem>
        )}
      />
    </Drawer>
  );
};

export default UserNoticeDrawer;
