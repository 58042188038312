import PageTemplate, { IPageTemplateProps } from 'templates/PageTemplate';
import { Typography } from 'components/system';
import {
  useProductSample,
  useProductSampleId,
} from 'service/manufacturer/productSample';
import { EProductSampleStatus } from 'types/manufacturer/productSample';
import * as S from 'templates/PageTemplateStyle';

const ProductSamplePageTemplate = ({
  title,
  subtitle,
  ...props
}: IPageTemplateProps) => {
  const productSampleId = useProductSampleId();
  const { productSample } = useProductSample(productSampleId);
  return (
    <S.PageTemplateBlock>
      <PageTemplate
        {...props}
        title={
          title ||
          (productSample && productSample.status !== EProductSampleStatus.RDY
            ? '샘플 관리'
            : '샘플 등록')
        }
        subtitle={
          <S.NameContainer>
            {subtitle && (
              <Typography.Text style={{ fontSize: 20 }} gutter={{ right: 16 }}>
                {subtitle}
              </Typography.Text>
            )}
            {productSample && (
              <div>
                <Typography.Text
                  type="secondary"
                  inline
                  gutter={{ right: 4 }}
                  className="label"
                >
                  랩넘버
                </Typography.Text>
                <Typography.Title
                  type="secondary"
                  medium
                  className="product-name"
                  inline
                  style={{ maxWidth: 580 }}
                >
                  {productSample.labNo}
                </Typography.Title>
              </div>
            )}
          </S.NameContainer>
        }
      />
    </S.PageTemplateBlock>
  );
};

export default ProductSamplePageTemplate;
