import { Button, Col, Form, Radio, Row } from 'antd';
import styled from 'styled-components';

import FooterBox from 'components/FooterBox';
import { Switch, Typography } from 'components/system';
import { requireRule } from 'lib/validate';
import { useMicrobiologicalProperties } from 'service/brand/product/microbiologicalProperties';

const MicrobiologicalPropertyBlock = styled.div`
  max-width: 780px;
  margin: 0 auto;
  padding-top: 45px;
`;

const FormWrapper = styled.div`
  margin-left: 80px;
`;

const ProductMicrobiologicalProperty = () => {
  const {
    form,
    checkAll,
    microbiologicalProperties,
    fetchLoading,
    onClickCheckAll,
    onChangeCheckAll,
    onSubmit,
  } = useMicrobiologicalProperties();

  return (
    <MicrobiologicalPropertyBlock>
      <Form
        form={form}
        labelCol={{ span: 11 }}
        wrapperCol={{ span: 13 }}
        labelAlign="left"
        colon={false}
        onFinish={onSubmit}
      >
        <FormWrapper>
          {microbiologicalProperties.map((microbiologicalProperty) => (
            <Form.Item
              key={microbiologicalProperty.name}
              label={microbiologicalProperty.label}
              required
            >
              <Row wrap={false} style={{ width: '100%' }} gutter={8}>
                <Col>
                  <Form.Item
                    noStyle
                    name={microbiologicalProperty.name}
                    rules={[requireRule]}
                  >
                    <Radio.Group
                      onChange={() =>
                        onChangeCheckAll(
                          !Object.values(form.getFieldsValue()).some(
                            (checked) =>
                              checked === true || checked === undefined,
                          ),
                        )
                      }
                    >
                      <Radio value={true}>
                        <Typography.Text inline>검출됨</Typography.Text>
                      </Radio>
                      <Radio value={false}>
                        <Typography.Text inline>검출되지 않음</Typography.Text>
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          ))}
          <Form.Item label=" ">
            <Switch
              active={checkAll}
              style={{ marginLeft: 78 }}
              onChange={onClickCheckAll}
            >
              전부 검출되지 않음
            </Switch>
          </Form.Item>
        </FormWrapper>
        <FooterBox>
          <Button type="primary" loading={fetchLoading} htmlType="submit">
            등록
          </Button>
        </FooterBox>
      </Form>
    </MicrobiologicalPropertyBlock>
  );
};

export default ProductMicrobiologicalProperty;
