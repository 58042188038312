import qs from 'qs';
import { IAPIResponse } from 'types/common';

import client from './client';

export const publishLoa = ({
  countryId,
  buyerId,
  productIds,
  loaExpireYears,
}: {
  countryId: number;
  buyerId: number;
  productIds: number[];
  loaExpireYears: string;
}) =>
  client.get<IAPIResponse<string | string[]>>(
    `/nota/loa/${countryId}/${buyerId}?${qs.stringify(
      { productIds: productIds, loaExpireYears: loaExpireYears },
      { indices: false },
    )}`,
  );

export const publishBseSc = ({
  countryId,
  docType,
  productIds,
  productCodes,
}: {
  countryId: number;
  docType: 'B' | 'S';
  productIds: number[];
  productCodes: string[];
}) =>
  client.get<IAPIResponse<string | string[]>>(
    `/nota/manufacturer/${countryId}/${docType}?${qs.stringify(
      { productIds, productCodes },
      { indices: false },
    )}`,
  );

export const publishLtu = ({
  countryId,
  productIds,
}: {
  countryId: number;
  productIds: number[];
}) =>
  client.get<IAPIResponse<string | string[]>>(
    `/nota/ltu/${countryId}?${qs.stringify(
      { productIds: productIds },
      { indices: false },
    )}`,
  );

export const publishRl = ({
  countryId,
  productIds,
  productCategories,
}: {
  countryId: number;
  productIds: number[];
  productCategories: string[];
}) =>
  client.post(`/nota/relationship-letter/${countryId}`, {
    productIds,
    productCategories,
  });

export const publishAut = ({
  countryId,
  buyerId,
  productIds,
}: {
  countryId: number;
  buyerId: number;
  productIds: number[];
}) =>
  client.get<IAPIResponse<string | string[]>>(
    `/nota/authorisation/${countryId}/${buyerId}?${qs.stringify(
      { productIds: productIds },
      { indices: false },
    )}`,
  );
