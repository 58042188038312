import { Form, Input } from 'antd';
import { requireRule, exceptKoreanRule } from 'lib/validate';
import { memo } from 'react';
import styled from 'styled-components';
import AlphabetLabel from './AlphabetLabel';

const MSDSOtherBlock = styled.div`
  max-width: 536px;
  padding-left: 16px;
  padding-bottom: 32px;
  margin: 0 auto;
`;

const MSDSOther = ({ updateMode }: { updateMode: boolean }) => {
  return (
    <MSDSOtherBlock>
      <Form.Item
        label={<AlphabetLabel alphabet="A">List of references</AlphabetLabel>}
        name={['other', 'listOfReferences']}
        rules={[requireRule, exceptKoreanRule]}
        required={false}
      >
        <Input.TextArea
          placeholder="자료의 출처"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={500}
          showCount
        />
      </Form.Item>
      <Form.Item
        label={<AlphabetLabel alphabet="B">Further Information</AlphabetLabel>}
        name={['other', 'furtherInformation']}
        rules={[requireRule, exceptKoreanRule]}
        required={false}
        initialValue="No data"
      >
        <Input.TextArea
          placeholder="기타"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={500}
          showCount
        />
      </Form.Item>
      <Form.Item
        label={<AlphabetLabel alphabet="C">Version</AlphabetLabel>}
        name={['other', 'version']}
        rules={[requireRule, exceptKoreanRule]}
        required={false}
        initialValue="00"
      >
        <Input placeholder="버전" maxLength={500} />
      </Form.Item>
      {updateMode && (
        <>
          <Form.Item
            label={<AlphabetLabel alphabet="D">Revision Number</AlphabetLabel>}
            name={['other', 'revisionNumber']}
            required={false}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label={<AlphabetLabel alphabet="E">Issued date</AlphabetLabel>}
            name={['other', 'issuedDate']}
            required={false}
          >
            <Input disabled />
          </Form.Item>
        </>
      )}
    </MSDSOtherBlock>
  );
};

export default memo(MSDSOther);
