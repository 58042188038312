import { useMemo } from 'react';
import { Typography as Typo } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import styled, { css } from 'styled-components';
import { nanoid } from 'nanoid';

import palette from 'lib/styles/palette';
import {
  ICompositionInformation,
  ICompositionInformationInvalidCode,
} from 'types/material/compositionInformation';
import { Typography } from 'components/system';
import { StatusIcon } from 'components/system/general/icon';

const TableWrapper = styled.div<{
  success?: boolean;
  isWtSumError?: boolean;
}>`
  border-radius: 4px;
  border: 1px solid
    ${(props) =>
      typeof props.success !== 'undefined'
        ? props.success === true
          ? palette.success
          : palette.error[0]
        : palette.inactive};

  td,
  th {
    padding: 4px;
    font-size: 12px;
  }
  ${(props) =>
    props.isWtSumError &&
    css`
      td.material__ci__wt {
        color: red !important;
      }
    `}
  td.material__ci__bigo {
    min-width: 38px;
    color: red !important;
  }

  .ant-table-container {
    overflow: auto;
  }
`;

const MessageBlock = styled.div`
  margin-top: 36px;

  > span {
    margin-bottom: 8px;
  }
`;

const CompositionInformationExcelViewerTable = ({
  dataSource,
  errorCodes,
  invalidURL,
  fetchLoading,
}: {
  dataSource: ICompositionInformation[];
  errorCodes: ICompositionInformationInvalidCode[] | null;
  invalidURL?: string;
  fetchLoading: boolean;
}) => {
  const isWtSumError =
    (errorCodes && errorCodes.some((v) => v.code === 'INVALID_WT')) || false;
  const columns: ColumnsType<ICompositionInformation> = useMemo(
    () => [
      {
        title: 'No.',
        dataIndex: 'no',
        className: 'material__ci__no',
        render: (_, __, index) => index + 1,
      },
      {
        title: '국문성분명',
        dataIndex: 'inciNameKo',
        className: 'material__ci__inciNameKo',
        align: 'center',
      },
      {
        title: 'INCI Name',
        dataIndex: 'inciNameEn',
        className: 'material__ci__inciNameEn',
        align: 'center',
      },
      {
        title: 'WT %',
        dataIndex: 'wt',
        className: 'material__ci__wt',
        align: 'center',
      },
      {
        title: 'CAS No.',
        dataIndex: 'casNo',
        className: 'material__ci__casNo',
        align: 'center',
      },
      {
        title: 'EC No.',
        dataIndex: 'euCasNo',
        className: 'material__ci__euCasNo',
        align: 'center',
      },
      {
        title: 'Composition Range',
        dataIndex: 'compositionRange',
        className: 'material__ci__compositionRange',
        align: 'center',
      },
      {
        title: 'Function',
        dataIndex: 'fun1',
        className: 'material__ci__function1',
        align: 'center',
      },
      {
        title: 'Function2',
        dataIndex: 'fun2',
        className: 'material__ci__function2',
        align: 'center',
      },
      {
        title: 'Function3',
        dataIndex: 'fun3',
        className: 'material__ci__function3',
        align: 'center',
      },
      {
        title: '구체적인 추출부위',
        dataIndex: 'extractionPart',
        className: 'material__ci__extractionPart',
        align: 'center',
      },
      {
        title: '비고',
        dataIndex: 'bigo',
        className: 'material__ci__bigo',
        align: 'center',
      },
    ],
    [],
  );
  const success = errorCodes === null ? undefined : errorCodes.length === 0;
  if (dataSource.length === 0) return null;
  return (
    <>
      <MessageBlock>
        {success === true && (
          <Typography.Text type="secondary">
            <StatusIcon status="success" /> 정상적으로 업로드 되었습니다.
          </Typography.Text>
        )}
        {success === false && (
          <Typography.Text type="secondary">
            <StatusIcon status="error" /> 업로드한 파일에 오류가 있습니다.{' '}
            <Typo.Link
              href={invalidURL}
              style={{ color: '#222', textDecoration: 'underline' }}
            >
              여기
            </Typo.Link>
            를 클릭하여 다운로드 후 수정하여 업로드해 주세요.
          </Typography.Text>
        )}
        {errorCodes &&
          errorCodes.map((code) => (
            <Typography.Text type="secondary" key={code.code}>
              <StatusIcon status="error" /> {code.message}
            </Typography.Text>
          ))}
      </MessageBlock>
      <TableWrapper success={success} isWtSumError={isWtSumError}>
        <Table
          columns={
            typeof success === 'undefined'
              ? columns.slice(0, columns.length - 1)
              : success
              ? columns.slice(0, columns.length - 1)
              : columns
          }
          dataSource={dataSource || []}
          rowKey={() => nanoid()}
          bordered
          loading={fetchLoading}
          pagination={false}
          rowClassName={(record, _) => {
            if (record.bigo) {
              return 'material__ci__bigo';
            }
            return '';
          }}
        />
      </TableWrapper>
    </>
  );
};

export default CompositionInformationExcelViewerTable;
