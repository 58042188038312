import { useState, useEffect } from 'react';
import { Transfer, Modal, Button, Row, Col } from 'antd';

import { useBrandCompanies } from 'service/brand/company';

const DisclosureBrandModal = ({
  visible,
  onClose,
  brandIds,
  onChange,
}: {
  visible: boolean;
  onClose: () => void;
  brandIds: number[];
  onChange: (brandIds: number[]) => void;
}) => {
  const { data: brands = [] } = useBrandCompanies();
  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const handleClickSelectAll = () => {
    setSelectedKeys([]);
    setTargetKeys(
      brands.map(({ brandCompanyId }: any) => brandCompanyId.toString()),
    );
  };
  const handleClickDeselectAll = () => {
    setSelectedKeys([]);
    setTargetKeys([]);
  };

  const handleClickSubmit = () => {
    onChange(targetKeys.map((key) => Number(key)));
    onClose();
  };

  useEffect(() => {
    if (visible) {
      setTargetKeys(brandIds.map((id) => id.toString()));
    }
  }, [visible]);

  return (
    <Modal
      title="책임판매사 지정"
      visible={visible}
      width={800}
      footer={null}
      onCancel={onClose}
    >
      <Transfer
        style={{ width: '100%' }}
        listStyle={{ flex: '1 1 auto' }}
        showSearch
        titles={[
          <Button
            style={{ height: 32 }}
            type="link"
            onClick={handleClickSelectAll}
          >
            모두 선택
          </Button>,
          <Button
            style={{ height: 32 }}
            type="link"
            onClick={handleClickDeselectAll}
          >
            모두 선택 해제
          </Button>,
        ]}
        targetKeys={targetKeys}
        selectedKeys={selectedKeys}
        onChange={setTargetKeys}
        onSelectChange={(selectedKeys, targetKeys) =>
          setSelectedKeys([...selectedKeys, ...targetKeys])
        }
        dataSource={brands}
        rowKey={({ brandCompanyId }) => brandCompanyId.toString()}
        render={(brand) => brand.companyNameKo}
        pagination
        operations={['공개', '비공개']}
      />
      <Row justify="center" style={{ marginTop: 24 }}>
        <Col>
          <Button type="primary" onClick={handleClickSubmit}>
            선택 완료
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default DisclosureBrandModal;
