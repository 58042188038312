import { Button, Col, Radio, Row } from 'antd';
import { Typography } from 'components/system';
import { useState } from 'react';
import styled from 'styled-components';

import { IPictogram } from 'types/material/msds';
import MSDSPictogram from './MSDSPictogram';

const MSDSPictogramSelectModalContentBlock = styled.div`
  margin-bottom: -24px;
`;

const MSDSPictogramSelectModalContent = ({
  pictograms,
  onSelectPictogram,
}: {
  pictograms: IPictogram[];
  onSelectPictogram: (pictogram: IPictogram) => void;
}) => {
  const [selectedPictogram, setSelectedPictogram] = useState<IPictogram | null>(
    null,
  );
  const handleSelectPictogram = () => {
    onSelectPictogram(selectedPictogram!);
  };
  return (
    <MSDSPictogramSelectModalContentBlock>
      <Typography.Title gutter={{ bottom: 24 }}>
        Pictogram 선택하기
      </Typography.Title>
      <Row gutter={[16, 20]}>
        {pictograms.map((pictogram) => (
          <Col
            key={pictogram.materialMsdsStatementId}
            style={{ textAlign: 'center' }}
          >
            <MSDSPictogram
              src={pictogram.attachUrl}
              onClick={() => setSelectedPictogram(pictogram)}
            />
            <Radio
              checked={pictogram.attachUrl === selectedPictogram?.attachUrl}
              style={{ marginRight: 0 }}
              onClick={() => setSelectedPictogram(pictogram)}
            />
          </Col>
        ))}
      </Row>
      <Row justify="center" style={{ marginTop: 24 }}>
        <Col>
          <Button onClick={handleSelectPictogram} disabled={!selectedPictogram}>
            선택 완료
          </Button>
        </Col>
      </Row>
    </MSDSPictogramSelectModalContentBlock>
  );
};

export default MSDSPictogramSelectModalContent;
