import { Typography } from 'components/system';
import history from 'lib/history';
import path from 'lib/path';
import palette from 'lib/styles/palette';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const RawMaterialRegisterProgressBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ProgressBar = styled.div<{ percent: number }>`
  position: relative;
  width: 520px;
  max-width: 520px;
  height: 8px;
  border-radius: 4px;
  background-color: #ebf3fe;

  &:before {
    content: '';
    display: block;
    width: ${({ percent }) => percent}%;
    height: 8px;
    border-radius: 4px;
    background-color: ${palette.primary};
    z-index: 1;
    transition: width 0.1s ease-in;
  }
`;

const RawMaterialRegisterProgress = () => {
  const [progressPercent, setProgressPercent] = useState(0);
  const [isEnd, setEnd] = useState(false);
  const [remainSecond, setRemainSecond] = useState(5);
  useEffect(() => {
    const progressInterval = setInterval(() => {
      setProgressPercent((draft) => {
        if (draft >= 100) {
          setEnd(true);
          clearInterval(progressInterval);
        }
        return Math.min(draft + Math.random() * 5, 100);
      });
    }, 100);
  }, []);
  useEffect(() => {
    if (isEnd) {
      const secondInterval = setInterval(() => {
        setRemainSecond((draft) => {
          if (draft === 1) {
            clearInterval(secondInterval);
            history.replace(path.material.manage.done);
          }
          return draft - 1;
        });
      }, 1000);
    }
  }, [isEnd]);

  return (
    <RawMaterialRegisterProgressBlock>
      {!isEnd ? (
        <>
          <Typography.Headline
            type="secondary"
            color="primary"
            gutter={{ bottom: 16 }}
          >
            {Math.floor(progressPercent)}%
          </Typography.Headline>
          <ProgressBar percent={progressPercent} />
          <Typography.Headline type="secondary" gutter={{ top: 56 }}>
            원료 데이터 검증을 진행 중입니다. 잠시만 기다려주세요 :)
          </Typography.Headline>
        </>
      ) : (
        <>
          <i className="material-icons outlined primary md-96">check</i>
          <br />
          <Typography.Headline type="secondary">
            정상적으로 완료되었습니다 !
          </Typography.Headline>
          <Typography.Text color="slate">
            <Typography.Text bold color="primary" inline>
              {remainSecond}
            </Typography.Text>
            초 후 등록 완료 목록으로 이동합니다.
          </Typography.Text>
        </>
      )}
    </RawMaterialRegisterProgressBlock>
  );
};

export default RawMaterialRegisterProgress;
