import MistakeNoticeItem from 'components/MistakeNoticeItem';
import ProductCountryVendor from 'components/product/ProductCountryVendor';
import ProductPageTemplate from 'templates/ProductPageTemplate';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';

const notices = [
  '일본 현지 판매처 정보를 기재해주세요.',
  '오프라인 판매처 없이 이커머스로 판매하기 위해 등록을 먼저 진행하시는 경우는 하단의 바이어 정보에 이커머스의 정보를 기입해 주세요. ',
  '아래에 입력된 내용이 라벨에 자동으로 반영되어 발행되며, 입력하지 않을 시에는 라벨 데이터에 반영되지 않습니다',
  <MistakeNoticeItem />,
];

const ProductCountryVendorPage = () => {
  const { productId } = useCurrentProduct();
  const countryId = useCountryId();

  return (
    <ProductPageTemplate
      subtitle="판매처 정보"
      hasSkip={false}
      notices={notices}
    >
      <ProductCountryVendor productId={productId} countryId={countryId!} />
    </ProductPageTemplate>
  );
};

export default ProductCountryVendorPage;
