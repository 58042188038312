import ProductImportContainer from 'containers/product/ProductImportContainer';
import PageTemplate from 'templates/PageTemplate';

const ProductImportPage = () => {
  return (
    <PageTemplate title="코드로 제품 등록" back={false}>
      <ProductImportContainer />
    </PageTemplate>
  );
};

export default ProductImportPage;
