import { IAPIPageableResponse, IAPIResponse } from 'types/common';
import {
  IIngredientDetail,
  IIngredientSearchResult,
} from 'types/material/ingredient';
import client from '../client';

export const getIngredientDetail = async (kciaIngredientId: number) => {
  const res = await client.get<IAPIResponse<IIngredientDetail>>(
    `ingredient/detail/${kciaIngredientId}`,
  );
  return res.data;
};

export const searchIngredient = async ({
  pageNo,
  size = 10,
  keyword,
}: {
  pageNo: number;
  size: number;
  keyword: string;
}) => {
  const res = await client.get<IAPIPageableResponse<IIngredientSearchResult[]>>(
    `/ingredient/search/${pageNo}/${size}?keyword=${encodeURIComponent(
      keyword,
    )}`,
  );
  return res.data;
};

export const searchAgainIngredient = async ({
  pageNo,
  size,
  preKeyword,
  newKeyword,
}: {
  pageNo: number;
  size: number;
  preKeyword: string;
  newKeyword: string;
}) => {
  const res = await client.get<IAPIPageableResponse<IIngredientSearchResult[]>>(
    `/ingredient/search/again/${pageNo}/${size}?newKeyword=${encodeURIComponent(
      newKeyword,
    )}&preKeyword=${encodeURIComponent(preKeyword)}`,
  );
  return res.data;
};

export const getCountryProhibitsUpdatedDate = () =>
  client.get<IAPIResponse<string>>('/ingredient/prohibit/update-date');

export const getEwgUpdatedDate = () =>
  client.get<IAPIResponse<string>>('/ingredient/ewg/update-date');
