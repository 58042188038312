import { useState } from 'react';
import { Button, Col, Row, Spin, Upload } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { nanoid } from 'nanoid';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';

import CorrectRequestMessageModal from 'components/CorrectRequestMessageModal';
import UpdateLog from './UpdateLog';
import {
  useProductDocStatus,
  useProductFormulaBreakdown,
} from 'service/product';
import { Typography } from 'components/system';
import { downloadFile } from 'lib/file';
import Table from 'components/Table';
import { countryCodeMap } from 'lib/consts';
import { EDocumentCode, IProductFormulaBreakdownItem } from 'types/product';
import { ICountryProhibitInfo } from 'types/formula';
import CountryProhibitInfosModal from 'components/modal/formula/CountryProhibitInfosModal';
import FooterBox from 'components/FooterBox';
import { useCertificateMode } from 'hook/certificate';
import history from 'lib/history';
import palette from 'lib/styles/palette';
import { StatusIcon } from 'components/system/general/icon';
import { useCountryProhibitsUpdatedDate } from 'service/material/ingredient';
import { updateCurrentPathname } from 'modules/product';
import JapanExportInformationModal from 'components/modal/formula/JapanExportInformationModal';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';

const ProductFormulaBreakdownTableBlock = styled.div``;

const ProductFormulaBreakdownTable = ({
  formulaBreakdownItems,
  isChina,
}: {
  formulaBreakdownItems: IProductFormulaBreakdownItem[];
  isChina: boolean;
}) => {
  const [
    selectedFormulaCountryInfos,
    setSelectedFormulaCountryInfos,
  ] = useState<ICountryProhibitInfo[]>([]);
  const [selectedCountryName, setSelectedCountryName] = useState<string | null>(
    null,
  );
  const handleClickMore = (
    formulaCountryInfos: ICountryProhibitInfo[],
    countryNameKo?: string,
  ) => {
    setSelectedFormulaCountryInfos(formulaCountryInfos);
    if (countryNameKo) {
      setSelectedCountryName(countryNameKo);
    }
  };
  const handleCloseCountryInfosModal = () => {
    setSelectedCountryName(null);
    setSelectedFormulaCountryInfos([]);
  };
  const [isCITES, setIsCITES] = useState<boolean | null>(null);
  return (
    <ProductFormulaBreakdownTableBlock>
      <CountryProhibitInfosModal
        defaultCountryName={selectedCountryName || undefined}
        countryInfos={selectedFormulaCountryInfos || []}
        onClose={handleCloseCountryInfosModal}
      />
      <JapanExportInformationModal
        isCITES={isCITES}
        onClose={() => setIsCITES(null)}
      />
      <Table bordered>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>No.</Table.Th>
            <Table.Th>INCI Name</Table.Th>
            <Table.Th>WT %</Table.Th>
            <Table.Th>% in raw material</Table.Th>
            <Table.Th>% calculated for each substance</Table.Th>
            <Table.Th>Function</Table.Th>
            <Table.Th>CAS No.</Table.Th>
            <Table.Th className="bigo">비고</Table.Th>
            <Table.Th colSpan={!isChina ? 2 : 1}>
              {!isChina ? '국가별 성분 규제' : '규제 사항'}
            </Table.Th>
            <Table.Th colSpan={!isChina ? 2 : 1} width="68">
              수출 정보
            </Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {formulaBreakdownItems.map(
            ({
              no,
              noRowSpan,
              inciName,
              formulaWt,
              formulaWtRowSpan,
              formulaRawMaterialPercent,
              formulaSubstancePercent,
              formulaFunction,
              formulaCasNo,
              bigo,
              useDescription,
              limitedFormulaCountryInfos,
              isJapanCitesProhibited,
              isJapanProhibited,
            }) => {
              const formulaTypes = Array.from(
                new Set(limitedFormulaCountryInfos.map(({ type }) => type)),
              ).sort();
              return (
                <Table.Tr key={nanoid()}>
                  {noRowSpan && (
                    <Table.Td
                      style={{ textAlign: 'center' }}
                      rowSpan={noRowSpan}
                    >
                      {no}
                    </Table.Td>
                  )}
                  <Table.Td>{inciName}</Table.Td>
                  {formulaWtRowSpan && (
                    <Table.Td
                      className="wt"
                      rowSpan={formulaWtRowSpan}
                      style={{ textAlign: 'right' }}
                    >
                      {formulaWt}
                    </Table.Td>
                  )}
                  <Table.Td width="60" style={{ textAlign: 'right' }}>
                    {formulaRawMaterialPercent}
                  </Table.Td>
                  <Table.Td
                    width="100"
                    className="sub-percent"
                    style={{ textAlign: 'right' }}
                  >
                    {formulaSubstancePercent}
                  </Table.Td>
                  <Table.Td>{formulaFunction}</Table.Td>
                  <Table.Td>{formulaCasNo}</Table.Td>
                  <Table.Td className="bigo">{bigo}</Table.Td>
                  <Table.Td width="60" style={{ textAlign: 'center' }}>
                    {formulaTypes.map((type) => (
                      <Typography.Label
                        key={type}
                        type={type === 'limit' ? 'check' : 'error'}
                        bordered
                        style={{
                          fontSize: 10,
                          minWidth: 32,
                          height: 18,
                          lineHeight: '10px',
                          paddingLeft: 7,
                          paddingRight: 7,
                        }}
                        onClick={() => {
                          handleClickMore(limitedFormulaCountryInfos);
                        }}
                      >
                        {type === 'limit' ? '제한' : '금지'}
                      </Typography.Label>
                    ))}
                  </Table.Td>
                  {!isChina && (
                    <Table.Td width="112" style={{ textAlign: 'center' }}>
                      <Row gutter={[2, 2]} justify="center">
                        {limitedFormulaCountryInfos.map(({ countryNameKo }) => (
                          <Col key={countryNameKo}>
                            <img
                              src={`https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/flag/${countryCodeMap[countryNameKo]}.png`}
                              alt=""
                              style={{
                                width: 20,
                                cursor: 'pointer',
                              }}
                              onClick={() =>
                                handleClickMore(
                                  limitedFormulaCountryInfos,
                                  countryNameKo,
                                )
                              }
                            />
                          </Col>
                        ))}
                      </Row>
                    </Table.Td>
                  )}
                  {/* HINT : 수출 정보 */}
                  {isChina ? (
                    <Table.Td
                      style={{ color: palette.text.error, textAlign: 'center' }}
                    >
                      {useDescription}
                    </Table.Td>
                  ) : (
                    <>
                      <Table.Td width="50" style={{ textAlign: 'center' }}>
                        {(isJapanCitesProhibited || isJapanProhibited) && (
                          <Typography.Label
                            type="error"
                            bordered
                            style={{
                              fontSize: 10,
                              minWidth: 32,
                              height: 18,
                              lineHeight: '10px',
                              paddingLeft: 7,
                              paddingRight: 7,
                            }}
                            onClick={() => setIsCITES(isJapanCitesProhibited)}
                          >
                            불가
                          </Typography.Label>
                        )}
                      </Table.Td>
                      <Table.Td width="62" style={{ textAlign: 'center' }}>
                        {(isJapanCitesProhibited || isJapanProhibited) && (
                          <img
                            src={`https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/flag/${countryCodeMap['일본']}.png`}
                            alt=""
                            style={{
                              width: 20,
                              cursor: 'pointer',
                            }}
                            onClick={() => setIsCITES(isJapanCitesProhibited)}
                          />
                        )}
                      </Table.Td>
                    </>
                  )}
                </Table.Tr>
              );
            },
          )}
        </Table.Tbody>
      </Table>
    </ProductFormulaBreakdownTableBlock>
  );
};

const ProductFormulaBreakdownBlock = styled.div<{
  success?: boolean;
  isWtSumError: boolean;
  isSubstanceSumError: boolean;
}>`
  table th {
    text-align: center;
  }

  .ant-table-cell {
    font-size: 12px;

    &.bigo {
      display: none;
    }
  }
  .ant-table-wrapper {
    border: 1px solid #aaa;
    border-radius: 4px;
  }

  ${({ success }) =>
    typeof success !== 'undefined' &&
    css`
      .ant-table-wrapper {
        border-color: ${palette[success ? 'success' : 'error']};
      }

      ${success === false &&
      css`
        .ant-table-cell.bigo {
          display: table-cell;
        }
      `}

      td.ant-table-cell.bigo {
        color: ${palette.error};
      }
    `}

  ${({ isWtSumError }) =>
    isWtSumError &&
    css`
      td.ant-table-cell.wt {
        color: ${palette.error};
      }
    `}

  ${({ isSubstanceSumError }) =>
    isSubstanceSumError &&
    css`
      td.ant-table-cell.sub-percent {
        color: ${palette.error};
      }
    `}
`;

const ProductFormulaBreakdown = ({ isChina }: { isChina: boolean }) => {
  const documentCode = !isChina ? EDocumentCode.FORMBR : EDocumentCode.FORMBRC;
  const { productId } = useCurrentProduct();
  const countryId = useCountryId();
  const dispatch = useDispatch();
  const productDocStatus = useProductDocStatus({
    productId,
    countryId,
    documentCode,
  });
  const filename =
    productDocStatus?.filename || !isChina
      ? 'Formula Breakdown.xlsx'
      : 'Formula Breakdown_china.xlsx';
  const { currentProduct, skipMode, readOnlyMode } = useSelector(
    ({ product, certificate }: any) => ({
      currentProduct: product.currentProduct,
      skipMode: product.skipMode,
      readOnlyMode: certificate.readOnlyMode,
    }),
    shallowEqual,
  );
  const updateMode = productDocStatus !== null;
  const prohibitDataUpdatedDate = useCountryProhibitsUpdatedDate();
  const certificateMode = useCertificateMode();
  const {
    formulaBreakdownItems,
    getLoading,
    uploadProductFormulaBreakdown,
    uploadLoading,
    productFormulaBreakdownDraft,
    fixProductFormulaBreakdown,
    fixLoading,
  } = useProductFormulaBreakdown(isChina, currentProduct.productId, countryId);

  const success = productFormulaBreakdownDraft
    ? !productFormulaBreakdownDraft.isWtSumError &&
      !productFormulaBreakdownDraft.isSubstanceSumError &&
      productFormulaBreakdownDraft.uploadedExcelUrl === null
    : undefined;

  const handleUpload = (file: File) => {
    uploadProductFormulaBreakdown({
      productId: currentProduct.productId,
      countryId,
      file,
    });
  };

  const handleFixDocument = () => {
    fixProductFormulaBreakdown(productDocStatus!.productDocStatusId);
  };

  const handleMoveNext = () => {
    dispatch(updateCurrentPathname());
  };

  return (
    <ProductFormulaBreakdownBlock
      success={success}
      isWtSumError={
        productFormulaBreakdownDraft
          ? productFormulaBreakdownDraft.isWtSumError
          : false
      }
      isSubstanceSumError={
        productFormulaBreakdownDraft
          ? productFormulaBreakdownDraft.isWtSumError
          : false
      }
    >
      <Row justify="end" gutter={8}>
        <Col>
          <CorrectRequestMessageModal documentCode={documentCode} />
        </Col>
        {updateMode && (
          <Col>
            <UpdateLog
              productId={productId!}
              countryId={countryId}
              documentCode={documentCode}
            />
          </Col>
        )}
      </Row>
      <Row
        gutter={8}
        justify="center"
        align="middle"
        style={{ marginBottom: 24 }}
      >
        <Col style={readOnlyMode ? { cursor: 'not-allowed' } : undefined}>
          <Button
            type="dashed"
            icon={<DownloadOutlined />}
            href="https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/sample/Product+Formula_Breakdown_V3.xlsx"
            disabled={readOnlyMode}
          >
            엑셀 서식 다운로드
          </Button>
        </Col>
        <Col>
          <Upload
            accept=".xlsx"
            beforeUpload={(file) => {
              handleUpload(file);
              return false;
            }}
            itemRender={() => null}
          >
            <Button
              loading={uploadLoading}
              icon={<UploadOutlined />}
              disabled={readOnlyMode}
              style={{ width: 122 }}
              onDrop={(e) => e.stopPropagation()}
            >
              파일 업로드
            </Button>
          </Upload>
        </Col>
        {productDocStatus && typeof success === 'undefined' && (
          <Col>
            <Typography.Text
              type="secondary"
              color="primary"
              style={{ cursor: 'pointer' }}
              onClick={() => downloadFile(productDocStatus.uploadFileUrl)}
            >
              {filename}
            </Typography.Text>
          </Col>
        )}
      </Row>
      {(productFormulaBreakdownDraft || formulaBreakdownItems.length > 0) && (
        <>
          <Row style={{ marginTop: 16, marginBottom: 8 }} align="bottom">
            <Col flex="auto">
              {productFormulaBreakdownDraft && (
                <>
                  {success && (
                    <Typography.Text type="secondary">
                      <StatusIcon status="success" />
                      정상적으로 업로드 되었습니다.
                    </Typography.Text>
                  )}
                  {productFormulaBreakdownDraft.uploadedExcelUrl && (
                    <Typography.Text type="secondary">
                      <StatusIcon status="error" />
                      업로드를 시도한 파일의 특정 셀에 오류가 있습니다.{' '}
                      <Typography.Text
                        type="secondary"
                        inline
                        style={{
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          downloadFile(
                            productFormulaBreakdownDraft.uploadedExcelUrl,
                          )
                        }
                      >
                        여기
                      </Typography.Text>
                      를 클릭하여 다운로드 후 수정하여 업로드해 주세요.
                    </Typography.Text>
                  )}
                  {productFormulaBreakdownDraft.isWtSumError && (
                    <Typography.Text type="secondary">
                      <StatusIcon status="error" />
                      모든 성분의 함량(WT %)을 더했을 때 100이 아닙니다.
                      수정하여 업로드해 주세요.
                    </Typography.Text>
                  )}
                  {productFormulaBreakdownDraft.isSubstanceSumError && (
                    <Typography.Text type="secondary">
                      <StatusIcon status="error" />
                      모든 성분의 함량(% calculated for each substance)을 더했을
                      때 100이 아닙니다. 수정하여 업로드해 주세요.
                    </Typography.Text>
                  )}
                </>
              )}
            </Col>
            <Col>
              <Typography.Label
                type="disabled"
                style={{ fontSize: 12, height: 22, lineHeight: '14px' }}
              >
                DB 업데이트
              </Typography.Label>
              <Typography.Text
                type="tertiary"
                medium
                color="slate"
                inline
                gutter={{ left: 8 }}
              >
                [국가별 성분 규제]
              </Typography.Text>
              <Typography.Text
                type="tertiary"
                medium
                color="slate"
                inline
                gutter={{ left: 4 }}
              >
                {prohibitDataUpdatedDate}
              </Typography.Text>
            </Col>
          </Row>
          <Spin spinning={getLoading}>
            <ProductFormulaBreakdownTable
              formulaBreakdownItems={
                productFormulaBreakdownDraft
                  ? productFormulaBreakdownDraft.list
                  : formulaBreakdownItems
              }
              isChina={isChina}
            />
          </Spin>
        </>
      )}
      {success && (
        <FooterBox>
          {skipMode ? (
            <Button type="primary" onClick={() => handleMoveNext()}>
              다음 단계로 이동
            </Button>
          ) : !certificateMode ? (
            <Button type="primary" onClick={history.goBack}>
              확인
            </Button>
          ) : (
            <Button
              loading={fixLoading}
              type="primary"
              onClick={() => handleFixDocument()}
            >
              보완 완료
            </Button>
          )}
        </FooterBox>
      )}
    </ProductFormulaBreakdownBlock>
  );
};

export default ProductFormulaBreakdown;
