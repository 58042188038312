import { Select, SelectProps } from 'antd';
import { SelectValue } from 'antd/lib/select';

export const ToggleSelect = ({
  value,
  onChange,
  ...restProps
}: Omit<SelectProps<SelectValue>, 'onChange'> & {
  onChange?: (value: boolean) => void;
}) => {
  return (
    <Select
      {...restProps}
      value={value?.toString()}
      onChange={(value) => onChange!(value === 'true' ? true : false)}
    />
  );
};
