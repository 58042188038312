import styled from 'styled-components';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

import { Typography } from 'components/system';
import path from 'lib/path';
import { s3AssetDomain } from 'lib/consts';

const Container = styled.div`
  text-align: center;
`;

const ClinicalTrialEstimatePage = () => {
  return (
    <Container>
      <img
        src={`${s3AssetDomain}/image-request.png`}
        alt="request"
        style={{ width: 360, height: 260 }}
      />
      <Typography.Headline type="secondary" gutter={{ top: 80 }}>
        임상시험 견적이 필요하신가요?
      </Typography.Headline>
      <Typography.Text type="secondary" gutter={{ top: 16, bottom: 40 }}>
        국내외 임상시험 업체 DB를 통해 최적의 임상 컨설팅을 받아보세요!
      </Typography.Text>
      <Button type="primary">
        <Link to={path.clinicalTrial.estimate.request}>
          24시간 내로 견적받기
        </Link>
      </Button>
    </Container>
  );
};

export default ClinicalTrialEstimatePage;
