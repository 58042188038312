import { useMemo } from 'react';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';

import { EUserType } from 'types/auth';
import * as authApi from 'lib/api/auth';

export const useIsLoggedIn = () =>
  useSelector(({ auth }: any) => auth.user !== null);

export const useUser = () => useSelector(({ auth }: any) => auth.user);

export const useUserType = () =>
  useSelector(({ auth }: any) => {
    if (!auth.user || auth.user.userType === EUserType.BRAND) {
      return EUserType.BRAND;
    } else if (auth.user.userType === EUserType.MANUFACTURE) {
      return EUserType.MANUFACTURE;
    }
    return EUserType.MATERIAL;
  });

export const useAuthority = () =>
  useSelector(({ auth }: any) => {
    return auth.user.authority;
  });

export const useRegisterUser = () => {
  const {
    mutate: registerUser,
    isLoading: registerLoading,
  } = useMutation((manager: any) => authApi.registerUser(manager));
  return useMemo(() => ({ registerUser, registerLoading }), [
    registerUser,
    registerLoading,
  ]);
};
