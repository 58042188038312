import MistakeNoticeItem from 'components/MistakeNoticeItem';
import ProductSpec from 'components/product/ProductSpec';
import ProductPageTemplate from 'templates/ProductPageTemplate';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';

const notices = ['모든 필드는 필수로 입력해 주세요.', <MistakeNoticeItem />];

const ProductSpecPage = () => {
  const { productId } = useCurrentProduct();
  const countryId = useCountryId();
  return (
    <ProductPageTemplate subtitle="Product Specifications" notices={notices}>
      <ProductSpec productId={productId} countryId={countryId!} />
    </ProductPageTemplate>
  );
};

export default ProductSpecPage;
