import { Link, useParams } from 'react-router-dom';

import DocumentsUpload from 'components/brand/retailer/retailerApply/DocumentsUpload';
import ProductPageTemplate from 'components/brand/retailer/retailerApply/product/ProductPageTemplate';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import { Typography } from 'components/system';
import path from 'lib/path';
import { EDocumentCode } from 'types/brand/retailer/retailerApply';

const ProductRHSPage = () => {
  const params = useParams<{ productId: string }>();
  const productId = Number(params.productId);
  return (
    <ProductPageTemplate
      productId={productId}
      subtitle="유해물질 성적서"
      notices={[
        <Typography.Text type="secondary">
          <Typography.Text inline bold type="secondary">
            식약처 화장품 지정 품질검사 기관
          </Typography.Text>
          에서 발행된 성적서만 가능합니다. (해당 기관 목록은{' '}
          <Link
            to={`${path.service}/guide`}
            target="_blank"
            style={{ fontWeight: 700, textDecoration: 'underline' }}
          >
            여기
          </Link>
          에서 확인이 가능합니다.)
        </Typography.Text>,
        '완제품으로 의뢰한 성적서만 인정되며, 성적서에 시료 사진은 반드시 첨부되어 있어야 합니다.',
        <Typography.Text type="secondary">
          유통화장품안전관리기준에 명시된 시험 항목이 전부 포함되어야 합니다.
          <Typography.Text type="tertiary">
            (시험 항목 : 납, 니켈, 비소, 수은, 안티몬, 카드뮴, 디옥산, 메탄올,
            포름알데하이드, 프탈레이트류(DBP, BBP, DEHP),
          </Typography.Text>
          <Typography.Text type="tertiary">
            미생물한도 (총호기성생균, 대장균, 녹농균, 황색포도상구균), 내용량,
            pH)
          </Typography.Text>
        </Typography.Text>,
        '단, 롯데홈쇼핑 제출 서류의 경우, 미생물한도, 내용량, pH는 제조사 분석 가능시 raw data를 포함한 제조사의 성적서로 대체 가능합니다.',
        <MistakeNoticeItem />,
      ]}
    >
      <DocumentsUpload
        productId={productId}
        documentCode={EDocumentCode.RHS}
        accept="application/pdf"
      />
    </ProductPageTemplate>
  );
};

export default ProductRHSPage;
