import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import PrivateRoute from 'components/PrivateRoute';
import path from 'lib/path';
import LoginPage from 'pages/auth/LoginPage';
import RegisterRouter from 'routers/RegisterRouter';
import { loginByToken } from 'modules/auth';
import Storage from 'lib/storage';
import FullLoading from 'components/FullLoading';
import { checkMobile } from 'lib/device';
import { setIsMobile } from 'modules/device';
import ChangePasswordPage from 'pages/auth/ChangePasswordPage';
import { useDetectNewVersion } from 'hook/etc';
import HomeRouter from './HomeRouter';
import MaterialRouter from './material/MaterialRouter';

const AppRouter = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const refreshToken = Storage.getItem('auth.refreshToken');
  const user = useSelector(({ auth }) => auth.user);
  const isLoggedIn = useMemo(() => user !== null, [user]);
  const loginByTokenLoading = useSelector(
    ({ loading }) => loading['auth/LOGIN_BY_TOKEN'],
  );
  const loginSuccessLoading = useSelector(
    ({ loading }) => loading['auth/LOGIN_SUCCESS'],
  );
  useEffect(() => {
    if (refreshToken) {
      dispatch(loginByToken(refreshToken));
    }
    setIsLoading(false);
  }, []);
  useEffect(() => {
    dispatch(setIsMobile(checkMobile()));
  }, []);
  useDetectNewVersion();

  if (
    isLoading ||
    (refreshToken !== null && loginByTokenLoading) ||
    loginSuccessLoading
  ) {
    return <FullLoading />;
  }

  return (
    <Switch>
      <PrivateRoute
        path={path.login}
        component={LoginPage}
        check={!isLoggedIn}
        redirect={path.main}
      />
      <PrivateRoute
        path={path.register.root}
        component={RegisterRouter}
        check={!isLoggedIn}
        redirect={path.main}
      />
      {isLoggedIn && user.isResetPassword && (
        <Route path={path.main} component={ChangePasswordPage} />
      )}
      <Route path={path.material.root} component={MaterialRouter} />
      <Route path={path.main} component={HomeRouter} />
    </Switch>
  );
};

export default AppRouter;
