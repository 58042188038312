import { AxiosResponse } from 'axios';

import { ICoaAdd, ICoaGet, ICoaUpdate } from 'types/material/coa';
import { IAPIResponse } from 'types/common';
import { ICoaPhase, ICoaTest } from 'types/material/coa';
import client from 'lib/api/client';

export const getCoaPhases = () =>
  client.get<IAPIResponse<ICoaPhase[]>>('/material/coa/phases');

export const getCoaTests = (materialCategoryId: number) =>
  client.get<IAPIResponse<ICoaTest[]>>(
    `/material/coa/phases/${materialCategoryId}/tests`,
  );

export const getCoa = async (materialId: number) => {
  const res = (await client.get<IAPIResponse<ICoaGet>>(
    `/material/coa?materialId=${materialId}`,
  )) as any;
  if (res.data.result) {
    res.data.result.materialCategoryId =
      res.data.result.materialCategory.materialCategoryId;
    res.data.result.materialCOAItems = res.data.result.materialCOAItems.map(
      (coaItem: any) => ({
        ...coaItem,
        ...(coaItem.materialCategory && {
          materialCategoryId: coaItem.materialCategory.materialCategoryId,
        }),
        isNoData: coaItem.isNoData !== null ? coaItem.isNoData : undefined,
      }),
    );
  }
  return res as AxiosResponse<IAPIResponse<ICoaGet>>;
};

export const addCoa = (coa: ICoaAdd) => client.post('/material/coa', coa);

export const addTemporaryCoa = (coa: ICoaAdd) =>
  client.post('/material/temporary-coa', coa);

export const updateCoa = (coa: ICoaUpdate) =>
  client.patch(`/material/coa/${coa.materialCOAId}`, coa);
