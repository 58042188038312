import { Col, Descriptions, Row, Empty } from 'antd';
import styled, { css } from 'styled-components';
import _ from 'lodash';

import { deviceSize } from 'lib/styles';
import palette from 'lib/styles/palette';
import { IStandardInfo } from 'types/material/ingredient';
import { useIsMobile } from 'hook/etc';
import { Typography } from 'components/system';

const StyledDescriptions = styled(Descriptions)<{ semi?: boolean }>`
  .ant-descriptions-item-label {
    padding: 10px 16px;
    width: 200px;
    @media ${deviceSize['sm']} {
      width: unset;
    }
    font-weight: 500;
    color: ${palette.darkNavy};
  }
  .ant-descriptions-item-content {
    padding: 10px 16px;
    position: relative;
    font-size: 14px;
    font-weight: 500;
    color: ${palette.darkNavy};
  }

  .ant-descriptions-view {
    ${(props) =>
      !props.semi
        ? css`
            border-left: none;
            border-right: none;
            border-top: 2px solid ${palette.primary};
            border-radius: 0;
          `
        : css`
            border: none;
          `}
  }
`;

export const StyledChip = styled.div`
  width: fit-content;
  padding: 2px 8px;
  border-radius: 12px;
  border: solid 1px ${palette.primary};
  background-color: ${palette.paleGray};
  font-size: 14px;
  font-weight: 500;
  color: ${palette.darkNavy};
`;

const StyledImage = styled.img<{ collapsedsize: 'sm' | 'md' }>`
  position: relative;
  max-width: 100%;
  background-color: #f5f5f5;

  ${(props) => css`
    @media ${deviceSize[props.collapsedsize]} {
      display: none;
    }
  `}
`;
const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 234px;
  height: 100%;
  border: 1px solid #f0f0f0;
  border-top: none;
`;

const StyledCol = styled(Col)`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
`;

interface IProps {
  standardInfo?: IStandardInfo;
  structurePicUrl?: string;
}

const IngredientStandardDetail = ({
  standardInfo,
  structurePicUrl,
}: IProps) => {
  const isMobile = useIsMobile();

  return (
    <>
      {!isMobile ? (
        <>
          <StyledDescriptions bordered column={1}>
            <Descriptions.Item label="국문명">
              {standardInfo?.nameKo || '-'}
            </Descriptions.Item>
            <Descriptions.Item label="INCI Name">
              {standardInfo?.nameEn || '-'}
            </Descriptions.Item>
            <Descriptions.Item label="구명칭">
              {standardInfo?.oldNameKo || '-'}
            </Descriptions.Item>
            <Descriptions.Item label="구 INCI Name">
              {standardInfo?.oldNameEn || '-'}
            </Descriptions.Item>
          </StyledDescriptions>
          <Row style={{ position: 'relative' }}>
            <Col span={17}>
              <StyledDescriptions
                bordered
                column={1}
                semi
                style={{ borderBottom: `1px solid #d8d8d8` }}
              >
                <Descriptions.Item label="이명">
                  <div style={{ wordBreak: 'break-all' }}>
                    {standardInfo?.nickname?.split('\n').map((name, index) => {
                      const len = standardInfo?.nickname?.split('\n').length;
                      return index + 1 === len ? name : `${name.trim()}, `;
                    }) || '-'}
                  </div>
                </Descriptions.Item>
                <Descriptions.Item label="CAS No.">
                  <div style={{ wordBreak: 'break-all' }}>
                    {standardInfo?.casNo?.split('\n').map((cas, index) => {
                      const len = standardInfo?.casNo?.split('\n').length;
                      return index + 1 === len ? cas : `${cas.trim()}, `;
                    }) || '-'}
                  </div>
                </Descriptions.Item>
                <Descriptions.Item label="EC No.">
                  {standardInfo?.ecNo || '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Function">
                  {!_.isEmpty(standardInfo?.functionNames) ? (
                    <Row gutter={[8, 8]}>
                      {standardInfo?.functionNames?.map((fun, index) => (
                        <Col key={index}>
                          <StyledChip>{fun}</StyledChip>
                        </Col>
                      ))}
                    </Row>
                  ) : (
                    '-'
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="설명">
                  {standardInfo?.originDefinition || '-'}
                </Descriptions.Item>
              </StyledDescriptions>
            </Col>
            <StyledCol span={7}>
              <ImgWrapper>
                {structurePicUrl ? (
                  <StyledImage
                    collapsedsize="sm"
                    src={structurePicUrl}
                    alt="structurePicUrl"
                  />
                ) : (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="이미지 없음"
                  />
                )}
              </ImgWrapper>
            </StyledCol>
          </Row>
          {structurePicUrl && (
            <Typography.Text
              style={{ textAlign: 'right', fontSize: 10, marginTop: 4 }}
              color="disabled"
            >
              이미지 출처 : Pubchem (pubchem.ncbi.nlm.nih.gov)
            </Typography.Text>
          )}
        </>
      ) : (
        <StyledDescriptions bordered column={1}>
          <Descriptions.Item label="국문명">
            {standardInfo?.nameKo || '-'}
          </Descriptions.Item>
          <Descriptions.Item label="INCI Name">
            {standardInfo?.nameEn || '-'}
          </Descriptions.Item>
          <Descriptions.Item label="구명칭">
            {standardInfo?.oldNameKo || '-'}
          </Descriptions.Item>
          <Descriptions.Item label="구 INCI Name">
            {standardInfo?.oldNameEn || '-'}
          </Descriptions.Item>
          <Descriptions.Item label="이명">
            <div style={{ wordBreak: 'break-all' }}>
              {standardInfo?.nickname?.split('\n').map((name, index) => {
                const len = standardInfo?.nickname?.split('\n').length;
                return index + 1 === len ? name : `${name.trim()}, `;
              }) || '-'}
            </div>
          </Descriptions.Item>
          <Descriptions.Item label="CAS No.">
            <div style={{ wordBreak: 'break-all' }}>
              {standardInfo?.casNo?.split('\n').map((cas, index) => {
                const len = standardInfo?.casNo?.split('\n').length;
                return index + 1 === len ? cas : `${cas.trim()}, `;
              }) || '-'}
            </div>
          </Descriptions.Item>
          <Descriptions.Item label="EC No.">
            {standardInfo?.ecNo || '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Function">
            {!_.isEmpty(standardInfo?.functionNames) ? (
              <Row gutter={[8, 8]}>
                {standardInfo?.functionNames?.map((fun, index) => (
                  <Col key={index}>
                    <StyledChip>{fun}</StyledChip>
                  </Col>
                ))}
              </Row>
            ) : (
              '-'
            )}
          </Descriptions.Item>
          <Descriptions.Item
            label="설명"
            style={{ borderBottom: `1px solid #d8d8d8` }}
          >
            {standardInfo?.description || '-'}
          </Descriptions.Item>
        </StyledDescriptions>
      )}
    </>
  );
};

export default IngredientStandardDetail;
