import { Button, Col, Descriptions, Row, Select, Spin, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Typography } from 'components/system';
import SearchInput from 'components/system/form/SearchInput';
import InfoIcon from 'components/system/general/icon/InfoIcon';
import history from 'lib/history';
import path from 'lib/path';
import palette from 'lib/styles/palette';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import {
  useEfficaciesAndFormulaPurposes,
  useRawMaterialSearch,
} from 'service/material/rawMaterialSearch';
import styled from 'styled-components';
import { IRawMaterial } from 'types/material/rawMaterialSearch';

const RawMaterialSearchBlock = styled.div`
  .ant-descriptions-view {
    border-radius: 0;
    border: none;
  }
  .ant-descriptions-item-label,
  .ant-descriptions-item-content {
    border-right: none;
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    vertical-align: top;
    padding: 12px 12px 24px 12px;
  }
`;

const EfficacyBlock = styled.div`
  cursor: pointer;
  user-select: none;
`;

const SearchKeywordBlock = styled.div`
  margin-top: 36px;
  padding: 16px;
  background-color: #f9f9f9;
  border-radius: 4px;
  border: 1px solid #ededed;
`;

const SearchKeywordLabelBlock = styled.span`
  position: relative;
  border: 1px solid ${palette.primary};
  padding: 4px 20px 4px 8px;
  border-radius: 12px;
  background-color: ${palette.paleGray};
  cursor: pointer;

  ::before,
  ::after {
    content: '';
    position: absolute;
    top: 11px;
    right: 6px;
    width: 11px;
    height: 1px;
    background-color: ${palette.slate};
    transform: rotate(45deg);
  }

  ::after {
    transform: rotate(-45deg);
  }
`;

const StyledTable = styled(Table)`
  margin-top: 36px;

  .ant-table-row:hover > .ant-table-cell {
    background-color: #fff;
  }

  .ant-table-row {
    cursor: pointer;

    td.ant-table-cell {
      vertical-align: top;
      letter-spacing: -0.5px;
      word-break: break-all;

      &.text-align-left {
        text-align: left !important;
      }
    }
  }
  .ant-table-expand-icon-col {
    width: 34px;
  }

  tr.ant-table-expanding-row {
    td.ant-table-cell {
      border-bottom: none;

      &.ant-table-cell-ellipsis {
        overflow: visible;
        white-space: normal;
      }

      .keyword-wrap {
        height: auto;
      }

      &.text-align-left {
        text-align: left !important;
      }
    }
  }

  tr.ant-table-expanded-row {
    .ant-table-cell {
      background-color: #fff;
    }
  }

  .keyword-wrap {
    height: 24px;
    overflow: hidden;
  }
`;

const RawMaterialSearch = () => {
  const columns: ColumnsType<any> = [
    {
      title: '원료명',
      dataIndex: 'materialNameEn',
      className: 'text-align-left',
      width: 148,
      ellipsis: true,
      align: 'center',
      render: (_, { materialNameEn }: IRawMaterial) => (
        <Typography.Text type="secondary" medium inline color="black">
          {materialNameEn}
        </Typography.Text>
      ),
    },
    {
      title: '원료사',
      dataIndex: 'companyNameKo',
      className: 'text-align-left',
      align: 'center',
      width: 88,
      ellipsis: true,
      render: (_, { companyNameKo }: IRawMaterial) => (
        <Typography.Text type="secondary" medium inline color="black">
          {companyNameKo}
        </Typography.Text>
      ),
    },
    {
      title: 'INCI Name',
      dataIndex: 'inciNames',
      className: 'text-align-left',
      align: 'center',
      width: 124,
      ellipsis: true,
      render: (_, { inciNames }: IRawMaterial) =>
        inciNames.map(({ inciNameEn }) => inciNameEn).join(', '),
    },
    {
      title: '효능',
      dataIndex: 'efficacies',
      className: 'text-align-left',
      align: 'center',
      width: 108,
      ellipsis: true,
      render: (_, { efficacies }: IRawMaterial) =>
        efficacies.map(({ efficacyName }) => efficacyName).join(', '),
    },
    {
      title: '배합 목적',
      dataIndex: 'formulaPurposes',
      className: 'text-align-left',
      align: 'center',
      width: 108,
      ellipsis: true,
      render: (_, { formulaPurposes }: IRawMaterial) =>
        formulaPurposes
          .map(({ formulaPurposeName }) => formulaPurposeName)
          .join(', '),
    },
    {
      title: '마케팅 키워드',
      dataIndex: 'marketingKeywords',
      align: 'center',
      width: 168,
      ellipsis: true,
      render: (_, { marketingKeywords }: IRawMaterial) => (
        <Row gutter={[4, 4]} className="keyword-wrap">
          {marketingKeywords.map(({ keyword }) => (
            <Col key={nanoid()}>
              <Typography.Label
                key={nanoid()}
                type="primary"
                style={{ minWidth: 0, height: 'auto', textAlign: 'left' }}
              >
                {keyword}
              </Typography.Label>
            </Col>
          ))}
        </Row>
      ),
    },
    {
      title: '특허 등록',
      dataIndex: 'isRegisterPatent',
      align: 'center',
      width: 62,
      render: (_, { isRegisterPatent }) => (isRegisterPatent ? 'Y' : 'N'),
    },
    {
      title: '마케팅 자료',
      align: 'center',
      width: 80,
      ellipsis: true,
      render: (_, { marketingDisplayType }) =>
        marketingDisplayType === 'PUBLIC'
          ? '공개형'
          : marketingDisplayType === 'LIMIT'
          ? '제한형'
          : marketingDisplayType === 'BOTH'
          ? '공개형, 제한형'
          : '-',
    },
  ];

  const {
    efficacies,
    formulaPurposes,
    getLoading,
  } = useEfficaciesAndFormulaPurposes();

  const {
    selectedEfficacies,
    selectEfficacy,
    selectedFormulaPurposes,
    selectFormulaPurpose,
    page,
    setPage,
    searchTypeOptions,
    searchType,
    setSearchType,
    searchKeyword,
    setSearchKeyword,
    searchRawMaterials,
    refreshSearch,
    rawMaterialsResult,
    getLoading: searchLoading,
  } = useRawMaterialSearch();
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
  const handleChangeSearchKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
    setTimeout(() => {
      searchRawMaterials();
    });
  };

  useEffect(() => {
    setExpandedRowKeys([]);
  }, [rawMaterialsResult]);

  return (
    <RawMaterialSearchBlock>
      <Spin spinning={getLoading}>
        <Descriptions
          bordered
          column={1}
          labelStyle={{
            width: 120,
            backgroundColor: '#eff1f8',
            borderRight: 'none',
          }}
          style={{ borderCollapse: 'collapse' }}
        >
          <Descriptions.Item
            label={<Typography.Text medium>효능</Typography.Text>}
          >
            <Row gutter={[12, 12]}>
              {efficacies.map((efficacy) => (
                <Col flex="0  20%" key={efficacy.efficacyCategoryId}>
                  <EfficacyBlock onClick={() => selectEfficacy(efficacy)}>
                    <Typography.Text
                      type="secondary"
                      medium={selectedEfficacies.includes(efficacy)}
                      color={
                        selectedEfficacies.includes(efficacy)
                          ? 'slate'
                          : 'disabled'
                      }
                      inline
                    >
                      {efficacy.efficacyName}
                    </Typography.Text>
                  </EfficacyBlock>
                </Col>
              ))}
            </Row>
          </Descriptions.Item>
          <Descriptions.Item
            label={<Typography.Text medium>배합 목적</Typography.Text>}
          >
            <Row gutter={[12, 12]}>
              {formulaPurposes.map((formulaPurpose) => (
                <Col
                  flex="0  20%"
                  key={formulaPurpose.formulaPurposeCategoryId}
                >
                  <EfficacyBlock
                    onClick={() => selectFormulaPurpose(formulaPurpose)}
                  >
                    <Typography.Text
                      type="secondary"
                      medium={selectedFormulaPurposes.includes(formulaPurpose)}
                      color={
                        selectedFormulaPurposes.includes(formulaPurpose)
                          ? 'slate'
                          : 'disabled'
                      }
                      inline
                    >
                      {formulaPurpose.formulaPurposeName}
                    </Typography.Text>
                  </EfficacyBlock>
                </Col>
              ))}
            </Row>
          </Descriptions.Item>
        </Descriptions>
      </Spin>
      <Row
        justify="center"
        gutter={4}
        wrap={false}
        style={{ marginTop: 16, marginBottom: 24 }}
      >
        <Col>
          <InfoIcon />
        </Col>
        <Col>
          <Typography.Text align="center" medium color="slate">
            효능·배합 목적에서 원하시는 단어가 없다면 아래 ‘전체’ 검색에서
            자유롭게 검색할 수 있습니다.
          </Typography.Text>
        </Col>
      </Row>
      <Row
        style={{ maxWidth: 644, marginLeft: 'auto', marginRight: 'auto' }}
        wrap={false}
      >
        <Col flex="1 1 200px" style={{ marginRight: 8 }}>
          <Select
            value={searchType}
            options={searchTypeOptions}
            onChange={(searchType) => setSearchType(searchType)}
            style={{ width: '100%' }}
          />
        </Col>
        <Col flex="1 1 360px" style={{ marginRight: 16 }}>
          <SearchInput
            defaultValue={searchKeyword}
            onSearch={handleChangeSearchKeyword}
          />
        </Col>
      </Row>
      {(selectedEfficacies.length > 0 ||
        selectedFormulaPurposes.length > 0 ||
        searchKeyword !== '') && (
        <SearchKeywordBlock>
          <Row gutter={16} wrap={false}>
            <Col flex="0 0 92px" style={{ cursor: 'pointer' }}>
              <Typography.Text
                type="secondary"
                inline
                style={{ color: '#4a4a4a' }}
                onClick={refreshSearch}
              >
                <i
                  className="material-icons outlined md-18"
                  style={{ lineHeight: 1.3, marginRight: 4 }}
                >
                  refresh
                </i>
                전체 해제
              </Typography.Text>
            </Col>
            <Col>
              <Row gutter={[8, 8]}>
                {selectedEfficacies.map((efficacy) => (
                  <Col key={efficacy.efficacyCategoryId}>
                    <SearchKeywordLabelBlock
                      onClick={() => selectEfficacy(efficacy)}
                    >
                      {efficacy.efficacyName}
                    </SearchKeywordLabelBlock>
                  </Col>
                ))}
                {selectedFormulaPurposes.map((formulaPurpose) => (
                  <Col key={formulaPurpose.formulaPurposeCategoryId}>
                    <SearchKeywordLabelBlock
                      onClick={() => selectFormulaPurpose(formulaPurpose)}
                    >
                      {formulaPurpose.formulaPurposeName}
                    </SearchKeywordLabelBlock>
                  </Col>
                ))}
                {searchKeyword !== '' && (
                  <Col>
                    <SearchKeywordLabelBlock
                      onClick={() => handleChangeSearchKeyword('')}
                    >
                      {searchKeyword}
                    </SearchKeywordLabelBlock>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </SearchKeywordBlock>
      )}
      <StyledTable
        loading={searchLoading}
        columns={columns}
        dataSource={rawMaterialsResult.content}
        rowKey={({ materialId }: any) => materialId}
        rowClassName={({ materialId }: any) =>
          expandedRowKeys.includes(materialId) ? 'ant-table-expanding-row' : ''
        }
        expandable={{
          expandRowByClick: true,
          expandIconColumnIndex: 8,
          expandedRowRender: ({ materialId }: any) => (
            <Button
              type="primary"
              size="large"
              style={{
                display: 'block',
                width: '100%',
                maxWidth: 400,
                marginLeft: 'auto',
                marginRight: 'auto',
                height: 48,
              }}
              onClick={() =>
                history.push(
                  `${path.material.rawMaterial.detail}/${materialId}`,
                )
              }
            >
              원료 정보 자세히 보러 가기{' '}
              <i className="material-icons" style={{ color: '#fff' }}>
                chevron_right
              </i>
            </Button>
          ),
          expandIcon: ({ expanded, record, onExpand }) => (
            <i
              className="material-icons primary cursor"
              style={{ userSelect: 'none' }}
              onClick={(e) => onExpand(record, e)}
            >
              {expanded ? 'expand_less' : 'expand_more'}
            </i>
          ),
          expandedRowKeys,
          onExpandedRowsChange: (keys) => setExpandedRowKeys(keys as number[]),
        }}
        pagination={{
          current: page,
          total: rawMaterialsResult.totalElements,
          onChange: setPage,
        }}
      />
    </RawMaterialSearchBlock>
  );
};

export default RawMaterialSearch;
