import { Typography } from 'antd';

import ProductCOBRMContainer from 'containers/product/ProductCOBRMContainer';
import ProductPageTemplate from 'templates/ProductPageTemplate';
import MistakeNoticeItem from 'components/MistakeNoticeItem';

const notices = [
  <Typography.Text style={{ padding: 0 }}>
    제조사의 영문 사업자등록증을 업로드해 주세요.
  </Typography.Text>,
  <MistakeNoticeItem />,
];

const ProductCOBRMPage = () => {
  return (
    <ProductPageTemplate
      subtitle="Certificate of Business Registration_Manufacturer"
      notices={notices}
      exampleImg="Certification_of_Business_Registration_Manufacturer.jpg"
    >
      <ProductCOBRMContainer />
    </ProductPageTemplate>
  );
};

export default ProductCOBRMPage;
