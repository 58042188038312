import { useState } from 'react';
import { FormInstance, Modal, Tabs } from 'antd';
import styled from 'styled-components';

import { Typography } from 'components/system';
import { useRawMaterial } from 'service/material/rawMaterial';
import {
  useMarketingTemplate,
  useMarketingUpload,
} from 'service/material/marketing';
import {
  EMarketingFileType,
  IMarketingTemplateFormData,
  MarketingUploadType,
} from 'types/material/marketing';
import MarketingTemplate from './MarketingTemplate';
import MarketingUpload from './MarketingUpload';

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav-list {
    width: 100%;
  }
  .ant-tabs-tab {
    width: 50%;
    justify-content: center;
    margin-right: 0px;
    font-size: 16px;
  }
`;

const Marketing = ({ materialId }: { materialId?: number }) => {
  const [fileType, setFileType] = useState<EMarketingFileType>(
    EMarketingFileType.UPLOAD,
  );
  const [marketingTemplateForms, setMarketingTemplateForms] = useState<
    FormInstance<IMarketingTemplateFormData>[]
  >([]);
  const [marketingUploads, setMarketingUploads] = useState<
    MarketingUploadType[]
  >([]);
  const { checkMarketingTemplateChange } = useMarketingTemplate(materialId);
  const { checkMarketingUploadChange } = useMarketingUpload(materialId);
  const { rawMaterial } = useRawMaterial(materialId);

  const openModal = (marketingFileType: EMarketingFileType) => {
    Modal.confirm({
      width: marketingFileType === EMarketingFileType.UPLOAD ? 520 : 480,
      icon: null,
      title: (
        <Typography.Text medium>
          {marketingFileType === EMarketingFileType.UPLOAD
            ? '업로드'
            : '템플릿'}
          형으로 이동하시겠습니까?
        </Typography.Text>
      ),
      content: (
        <Typography.Text type="secondary">
          현재 내용을 입력 중이시라면, 저장 후 탭을 이동해 주세요. <br />
          저장을 원하는 경우 ‘머무르기’ 클릭 후{' '}
          {marketingFileType === EMarketingFileType.UPLOAD
            ? `'등록', '수정' 또는 '임시 저장'`
            : `‘등록’ 또는 ‘수정’`}
          버튼을 눌러 주세요.
        </Typography.Text>
      ),
      okText: '탭 이동',
      closable: true,
      cancelText: '머무르기',
      onOk: () => {
        setFileType(marketingFileType);
      },
    });
  };

  const handleChangeTab = (selectedType: EMarketingFileType) => {
    if (rawMaterial?.isDiscontinued) {
      return setFileType(selectedType);
    }

    if (selectedType === EMarketingFileType.UPLOAD) {
      const limitTemplateFormData = marketingTemplateForms[0].getFieldsValue();
      const publicTemplateFormData = marketingTemplateForms[1].getFieldsValue();
      if (
        checkMarketingTemplateChange(
          limitTemplateFormData,
          publicTemplateFormData,
        )
      ) {
        openModal(selectedType);
        return;
      }
    } else if (
      checkMarketingUploadChange(marketingUploads[0], marketingUploads[1])
    ) {
      openModal(selectedType);
      return;
    }

    setFileType(selectedType);
  };

  return (
    <>
      <StyledTabs
        activeKey={fileType}
        onChange={(type) => handleChangeTab(type as EMarketingFileType)}
      >
        <Tabs.TabPane key={EMarketingFileType.UPLOAD} tab="업로드형" />
        <Tabs.TabPane key={EMarketingFileType.TEMPLATE} tab="템플릿형" />
      </StyledTabs>
      {fileType === EMarketingFileType.TEMPLATE ? (
        <MarketingTemplate
          materialId={materialId}
          onChangeMarketingTemplateForms={setMarketingTemplateForms}
        />
      ) : (
        <MarketingUpload
          materialId={materialId}
          onChangeMarketingUploads={setMarketingUploads}
        />
      )}
    </>
  );
};

export default Marketing;
