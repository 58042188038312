import { useMemo } from 'react';
import { Typography } from 'antd';

import MistakeNoticeItem from 'components/MistakeNoticeItem';
import ProductInternalResponsibleCompanyContainer from 'containers/product/ProductInternalResponsibleCompanyContainer';
import ProductPageTemplate from 'templates/ProductPageTemplate';

const ProductInternalResponsibleCompanyPage = () => {
  const notices = useMemo(
    () => [
      <Typography.Text style={{ padding: 0 }}>
        제품들을 위임하실 기존 경내책임회사의 정보를 입력해 주세요.
      </Typography.Text>,
      <MistakeNoticeItem />,
    ],
    [],
  );

  return (
    <ProductPageTemplate subtitle="경내책임회사 정보" notices={notices}>
      <ProductInternalResponsibleCompanyContainer />
    </ProductPageTemplate>
  );
};

export default ProductInternalResponsibleCompanyPage;
