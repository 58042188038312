import { IAPIResponse } from 'types/common';
import {
  IAllergenIngredient,
  IAllergensListGet,
  IAllergensListAdd,
  IAllergensListUpdate,
} from 'types/material/allergen';
import client from 'lib/api/client';

// HINT : 초기 등록한 값이 없을 때 호출하는 Default AllergensList
export const getAllergensDefaultList = async () => {
  const res = await client.get<IAPIResponse<IAllergenIngredient[]>>(
    `/material/allergen/INCIs`,
  );
  return res.data;
};

export const getAllergensList = async (materialId: number) => {
  const res = await client.get<IAPIResponse<IAllergensListGet>>(
    `/material/allergen?materialId=${materialId}`,
  );
  return res.data;
};

export const getAllergensListByMaterialAllergenListId = async (
  materialAllergenListId: number,
) => {
  const res = await client.get<IAPIResponse<IAllergensListGet>>(
    `/material/allergen/${materialAllergenListId}`,
  );
  return res.data;
};

export const addAllergensList = async (allergen: IAllergensListAdd) => {
  const res = await client.post<IAPIResponse<null>>(
    `/material/allergen`,
    allergen,
  );
  return res.data;
};

export const updateAllergensList = async (params: IAllergensListUpdate) => {
  const { materialAllergenListId, ...rest } = params;
  const res = await client.patch<IAPIResponse<null>>(
    `/material/allergen/${materialAllergenListId}`,
    rest,
  );
  return res.data;
};
