import styled from 'styled-components';

import { EUserType } from 'types/auth';
import { useAuthority, useUser, useUserType } from 'service/auth';
import ChangePasswordModal from 'components/modal/auth/ChangePasswordModal';
import ManagerUpdateContainer from 'containers/auth/ManagerUpdateContainer';
import ManuRegisterFormContainer from 'containers/auth/manu/ManuRegisterFormContainer';
import RegisterFormContainer from 'containers/auth/brand/BrandRegisterFormContainer';
import MaterialRegisterForm from 'components/auth/material/MaterialRegisterForm';

const UserInfoPageBlock = styled.div`
  padding-top: 12px;
`;

const UserInfoPage = () => {
  const authority = useAuthority();
  const userType = useUserType();
  const user = useUser();
  return (
    <UserInfoPageBlock>
      <ChangePasswordModal />
      {authority === 'SUPER_USER' ? (
        userType === EUserType.BRAND ? (
          <RegisterFormContainer />
        ) : userType === EUserType.MANUFACTURE ? (
          <ManuRegisterFormContainer />
        ) : (
          <MaterialRegisterForm />
        )
      ) : (
        <ManagerUpdateContainer directMode manager={user} />
      )}
    </UserInfoPageBlock>
  );
};

export default UserInfoPage;
