import { Button, Col, Form, Radio, Row } from 'antd';
import styled from 'styled-components';

import FooterBox from 'components/FooterBox';
import { Typography } from 'components/system';
import Switch from 'components/system/form/Switch';
import palette from 'lib/styles/palette';
import { requireRule } from 'lib/validate';

const ProductChemicalPropertyBlock = styled.div`
  max-width: 780px;
  margin: 0 auto;
  padding-top: 20px;
`;

const ProductChemicalProperty = ({
  form,
  checkAll,
  chemicalProperties,
  fetchLoading,
  onClickCheckAll,
  onChangeCheckAll,
  onSubmit,
}: any) => {
  return (
    <ProductChemicalPropertyBlock>
      <Form
        form={form}
        labelCol={{ span: 11 }}
        wrapperCol={{ span: 13 }}
        labelAlign="left"
        colon={false}
        onFinish={onSubmit}
      >
        {chemicalProperties.map(
          ({
            name,
            label,
            criteria,
          }: {
            name: string;
            label: string;
            criteria: string;
          }) => (
            <Form.Item key={name} label={label} required>
              <Row
                wrap={false}
                style={{ width: '100%' }}
                gutter={8}
                align="middle"
              >
                <Col>
                  <Form.Item noStyle name={name} rules={[requireRule]}>
                    <Radio.Group
                      onChange={() =>
                        onChangeCheckAll(
                          !Object.values(form.getFieldsValue()).some(
                            (checked) =>
                              checked === true || checked === undefined,
                          ),
                        )
                      }
                    >
                      <Radio value={true}>
                        <Typography.Text inline>검출됨</Typography.Text>
                      </Radio>
                      <Radio value={false}>
                        <Typography.Text inline>검출되지 않음</Typography.Text>
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col>
                  <Typography.Text
                    type="secondary"
                    style={{
                      display: 'inline-block',
                      whiteSpace: 'pre-line',
                      fontSize: 12,
                      lineHeight: 1.4,
                      color: palette.text.disabled,
                    }}
                  >
                    {criteria}
                  </Typography.Text>
                </Col>
              </Row>
            </Form.Item>
          ),
        )}
        <Form.Item label=" ">
          <Switch
            active={checkAll}
            style={{ marginLeft: 78 }}
            onChange={onClickCheckAll}
          >
            전부 검출되지 않음
          </Switch>
        </Form.Item>
        <FooterBox>
          <Button type="primary" loading={fetchLoading} htmlType="submit">
            등록
          </Button>
        </FooterBox>
      </Form>
    </ProductChemicalPropertyBlock>
  );
};

export default ProductChemicalProperty;
