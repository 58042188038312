import qs from 'qs';

import client from 'lib/api/client';
import { IUser } from 'types/auth';
import { IAPIResponse } from 'types/common';
import { ECompanyType } from 'types/company';
import { EUserType, IUserRegister, IUserUpdate } from 'types/auth';

export const bizNumberDupCheck = (params: {
  bizNumber: string;
  companyType: ECompanyType;
}) =>
  client.get(`/v2/auth/company/biz-number/duplicate?${qs.stringify(params)}`);
export const usernameDupCheck = (params: {
  username: string;
  userType: EUserType;
}) => client.post(`/v2/auth/username/duplicate?${qs.stringify(params)}`);
export const registerUser = (user: IUserRegister) =>
  client.post('/v2/auth/responsibility', user);
export const updateUser = (user: IUserUpdate) => client.patch('/user', user);
export const resetPassword = (userId: number) =>
  client.patch(`/v2/auth/user/reset-password/${userId}`);
export const deleteUser = (userId: number) =>
  client.delete(`/v2/auth/user/${userId}`);
export const login = (params: {
  username: string;
  password: string;
  userType: EUserType;
}) => client.post(`/v2/auth/login?${qs.stringify(params)}`);
export const getUser = () => client.get<IAPIResponse<IUser>>(`/user`);
export const findPassword = (params: {
  username: string;
  email: string;
  userType: EUserType;
}) => client.post(`/v2/auth/password/find?${qs.stringify(params)}`);
export const reissueToken = (refreshToken: string) =>
  client.post(`/v2/auth/login/auto?refreshToken=${refreshToken}`);
export const getManagers = (companyId: number) =>
  client.get(`/users/${companyId}`);
export const changePassword = (changedPassword: {
  userId: number;
  prevPassword: string;
  password: string;
  userType: EUserType;
  isManufacturer?: boolean;
}) => client.patch('/v2/auth/password', changedPassword);
