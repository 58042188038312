import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button, Form, Input, Select, Modal, Row, Col } from 'antd';

import { useCountries } from 'service/country';
import * as buyerActions from 'modules/buyer';
import FooterBox from 'components/FooterBox';
import {
  emailRule,
  exceptKoreanOrNumberRule,
  exceptKoreanRule,
  numbersRule,
  requireRule,
} from 'lib/validate';
import useUpdateEffect from 'hook/useUpdateEffect';
import { Typography } from 'components/system';
import { IBuyer } from 'types/buyer';

const BuyerAddModal = ({ countryId }: { countryId?: number }) => {
  const dispatch = useDispatch();
  const countries = useCountries();
  const { companyId, showAddModal, addLoading } = useSelector(
    ({ auth, buyer, loading }: any) => ({
      companyId: auth.user.companyId,
      showAddModal: buyer.showAddModal,
      addLoading: loading['buyer/ADD_BUYER'],
    }),
    shallowEqual,
  );
  const [form] = Form.useForm<IBuyer>();
  const toggleShowAddModal = () => {
    dispatch(buyerActions.toggleShowAddBuyerModal());
  };
  const addBuyer = (buyerValues: IBuyer) => {
    dispatch(
      buyerActions.addBuyer({
        brandCompanyId: companyId,
        ...buyerValues,
      }),
    );
  };
  useUpdateEffect(() => {
    if (!showAddModal) {
      form.resetFields();
    } else {
      form.setFieldsValue({ countryId });
    }
  }, [showAddModal]);
  useEffect(() => {
    if (countryId) {
      form.setFieldsValue({ countryId });
    }
  }, []);
  return (
    <Modal
      visible={showAddModal}
      destroyOnClose
      footer={null}
      onCancel={toggleShowAddModal}
    >
      <Typography.Title gutter={{ bottom: 16 }}>바이어 추가</Typography.Title>
      <Form form={form} layout="vertical" onFinish={addBuyer}>
        <Form.Item name="countryId" label="국가" rules={[requireRule]}>
          <Select
            showSearch
            filterOption={(keyword, option) =>
              (option?.label as any).includes(keyword)
            }
            options={countries.map(({ countryId, countryNameKo }) => ({
              label: countryNameKo,
              value: countryId,
            }))}
            disabled={typeof countryId !== 'undefined'}
          />
        </Form.Item>
        <Form.Item
          shouldUpdate={(prev, curr) => prev.countryId !== curr.countryId}
        >
          {({ getFieldValue }) => (
            <>
              <Form.Item
                name="companyName"
                label="회사명 (영문)"
                rules={[requireRule, exceptKoreanRule]}
              >
                <Input />
              </Form.Item>
              {getFieldValue('countryId') === 5 && (
                <Form.Item
                  name="countryCompanyName"
                  label="회사명 (일본어)"
                  rules={[
                    requireRule,
                    exceptKoreanRule,
                    {
                      type: 'string',
                      max: 30,
                      message: '최대 30자',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              )}
              <Form.Item
                name="companyTel"
                label="전화번호"
                rules={[requireRule, numbersRule]}
              >
                <Input placeholder="숫자만 입력해 주세요." />
              </Form.Item>
              <Form.Item
                name="companyAddress"
                label="주소 (영문)"
                rules={[requireRule, exceptKoreanRule]}
              >
                <Input placeholder="국가를 포함한 주소를 입력해 주세요." />
              </Form.Item>
              {getFieldValue('countryId') === 5 && (
                <Form.Item
                  name="countryCompanyAddress"
                  label="주소 (일본어)"
                  rules={[
                    requireRule,
                    exceptKoreanRule,
                    {
                      type: 'string',
                      max: 100,
                      message: '최대 100자',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              )}
            </>
          )}
        </Form.Item>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              name="ceoName"
              label="대표 성함 (영문)"
              rules={[requireRule, exceptKoreanOrNumberRule]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="representativeName"
              label="담당자 성함 (영문)"
              rules={[requireRule, exceptKoreanOrNumberRule]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="companyEmail"
          label="이메일"
          rules={[requireRule, emailRule]}
        >
          <Input />
        </Form.Item>
        <FooterBox style={{ paddingTop: 24 }}>
          <Button type="primary" loading={addLoading} htmlType="submit">
            등록
          </Button>
        </FooterBox>
      </Form>
    </Modal>
  );
};

export default BuyerAddModal;
