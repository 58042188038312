import styled from 'styled-components';
import { Col, Row, Spin } from 'antd';

import { useMain } from 'service/main';
import history from 'lib/history';
import path from 'lib/path';
import Typography from 'components/system/general/Typography';
import CompanyStatsBlock from 'components/main/CompanyStatsBlock';
import CompanyStat from 'components/main/CompanyStat';
import Shortcut from 'components/main/Shortcut';

const MainPageBlock = styled.div`
  padding-top: 40px;
  max-width: 1024px !important;
`;

const MainPage = () => {
  const { companyName, stats, getStatsLoading } = useMain();
  const {
    sampleConfirmationCount = 0,
    productOnCount = 0,
    issueAuthCodeCount = 0,
    brandCompanyAuthenticated = 0,
  } = { ...stats };

  const handleAddProduct = () => {
    history.push(path.manufacturer.product.basic);
  };
  return (
    <MainPageBlock>
      <Row gutter={[16, 32]} justify="center">
        <Col>
          <CompanyStatsBlock>
            <Typography.Headline
              type="secondary"
              style={{ textAlign: 'center' }}
            >
              안녕하세요.
              <br className="mobile-br" />
              <mark>{companyName}</mark> 님!
              <br /> 화장품 산업 발전 연구소 30COS 입니다.
            </Typography.Headline>
            <Spin spinning={getStatsLoading}>
              <Row gutter={16} style={{ marginTop: 52 }} justify="center">
                <Col>
                  <CompanyStat
                    amount={sampleConfirmationCount || 0}
                    opacity={0.25}
                    label="확정 완료 샘플"
                    onClick={() =>
                      history.push(path.manufacturer.productSample.list)
                    }
                  />
                </Col>
                <Col>
                  <CompanyStat
                    amount={productOnCount || 0}
                    opacity={0.5}
                    label="등록 중 제품"
                    onClick={() =>
                      history.push(path.manufacturer.product.readyList)
                    }
                  />
                </Col>
                <Col>
                  <CompanyStat
                    amount={issueAuthCodeCount || 0}
                    opacity={0.75}
                    label="코드 발행 제품"
                    onClick={() => history.push(path.manufacturer.product.list)}
                  />
                </Col>
                <Col>
                  <CompanyStat
                    amount={brandCompanyAuthenticated || 0}
                    opacity={1}
                    label="사용된 코드"
                    onClick={() => history.push(path.manufacturer.product.list)}
                  />
                </Col>
              </Row>
            </Spin>
          </CompanyStatsBlock>
        </Col>
      </Row>
      <Typography.Title
        gutter={{ top: 64, bottom: 16 }}
        style={{ textAlign: 'center' }}
      >
        자주 찾는 메뉴
      </Typography.Title>
      <Row gutter={[16, 16]} style={{ maxWidth: 'none' }} justify="center">
        <Col xs={{ span: 12 }} sm={{ span: 6 }}>
          <Shortcut
            icon={
              <i className="material-icons outlined primary">
                screen_search_desktop
              </i>
            }
            label="성분 사전"
            description="제품에 포함된 성분 정보 및 규제를 확인해 보세요."
            onClick={() => history.push(path.material.ingredient.dictionary)}
          />
        </Col>
        <Col xs={{ span: 12 }} sm={{ span: 6 }}>
          <Shortcut
            icon={<i className="material-icons outlined primary">science</i>}
            label="신규 샘플 등록"
            description="샘플 처방을 등록하여 관리해 보세요."
            onClick={() => history.push(path.manufacturer.productSample.basic)}
          />
        </Col>
        <Col xs={{ span: 12 }} sm={{ span: 6 }}>
          <Shortcut
            icon={<i className="material-icons outlined primary">new_label</i>}
            label="신규 제품 등록"
            description="한 번의 입력으로 인증 서류 대응을 완료해 보세요. "
            onClick={handleAddProduct}
          />
        </Col>
        <Col xs={{ span: 12 }} sm={{ span: 6 }}>
          <Shortcut
            icon={<i className="material-icons outlined primary">pin</i>}
            label="코드 발행"
            description="브랜드사에게 제품 정보를 전달할 수 있는 코드를 발행해 보세요."
            onClick={() => history.push(path.manufacturer.product.list)}
          />
        </Col>
      </Row>
    </MainPageBlock>
  );
};

export default MainPage;
