import styled, { css } from 'styled-components';
import { Col, Descriptions, message, Row, Spin } from 'antd';
import { useCallback, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { Typography } from 'components/system';
import { deviceSize } from 'lib/styles';
import palette from 'lib/styles/palette';
import { addIngredientToBox } from 'modules/material/ingredient';
import { countryCodeMap, s3AssetDomain } from 'lib/consts';
import CountryProhibitInfosModal from 'components/modal/formula/CountryProhibitInfosModal';
import { RootState } from 'modules';
import IngredientStandardDetail from './IngredientStandardDetail';
import {
  useCountryProhibitsUpdatedDate,
  useEwgUpdatedDate,
  useIngredient,
} from 'service/material/ingredient';
import { useHistory } from 'react-router';
import path from 'lib/path';

const IngredientDetailBlock = styled.div``;

const StyledDescriptions = styled(Descriptions)<{ semi?: boolean }>`
  .ant-descriptions-item-label {
    padding: 10px 16px;
    width: 200px;
    @media ${deviceSize['sm']} {
      width: unset;
    }
    font-weight: 500;
    font-size: 16px;
    color: ${palette.darkNavy};
  }
  .ant-descriptions-item-content {
    padding: 10px 16px;
    position: relative;
    font-size: 14px;
    font-weight: 500;
    color: ${palette.darkNavy};
  }
  .ant-descriptions-view {
    ${(props) =>
      !props.semi
        ? css`
            border-left: none;
            border-right: none;
            border-top: 2px solid ${palette.primary};
            border-radius: 0;
          `
        : css`
            border: none;
          `}
  }
`;

const PlusGray = styled.img`
  width: 20px;
  height: 20px;

  margin-right: 4px;
`;

const StyledButton = styled.button<{
  color: string;
  backgroundColor: string;
}>`
  display: flex;
  height: 40px;
  padding: 10px 16px;
  border-radius: 4px;
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  border: none;
  cursor: pointer;
`;

export const StyledChip = styled.div`
  width: fit-content;
  padding: 2px 8px;
  border-radius: 12px;
  border: solid 1px ${palette.primary};
  background-color: ${palette.paleGray};
  font-size: 14px;
  font-weight: 500;
  color: ${palette.darkNavy};
`;

const EWGScoreImg = styled.img`
  width: 24px;
  height: 24px;
`;

const GotoEWG = styled.div`
  width: fit-content;
  padding: 3px 8px;
  font-size: 12px;
  height: 24px;
  box-sizing: border-box;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${palette.text.gray};
  cursor: pointer;

  border-radius: 4px;
  border: solid 1px ${palette.inactive};
`;

interface IProps {
  kciaIngredientId: number;
}

const IngredientDetail = ({ kciaIngredientId }: IProps) => {
  const { ingredientDetail, ingredientDetailLoading } = useIngredient(
    kciaIngredientId,
  );
  const prohibitDataUpdatedDate = useCountryProhibitsUpdatedDate();
  const ewgUpdatedDate = useEwgUpdatedDate();
  const dispatch = useDispatch();
  const [selectedCountryName, setSelectedCountryName] = useState('');
  const [selectedCountryInfos, setSelectedCountryInfos] = useState([]);
  const handleCloseCountryInfosModal = useCallback(() => {
    setSelectedCountryName('');
    setSelectedCountryInfos([]);
  }, []);
  const handleClickMore = useCallback((countryInfos, countryNameKo) => {
    setSelectedCountryInfos(countryInfos || []);
    if (countryNameKo) {
      setSelectedCountryName(countryNameKo);
    }
  }, []);
  const history = useHistory();

  const { ingredientBoxs } = useSelector(
    ({ ingredient }: RootState) => ({
      ingredientBoxs: ingredient.ingredientOfBoxs,
    }),
    shallowEqual,
  );

  const handleAddToBox = () => {
    if (ingredientDetail) {
      if (
        ingredientBoxs.find(
          (i) =>
            i.kciaIngredientId ===
            ingredientDetail.standardInfo.kciaIngredientId,
        )
      )
        return message.warn('이미 추가된 성분입니다.');

      if (ingredientBoxs.length < 3) {
        dispatch(
          addIngredientToBox({
            kciaIngredientId: ingredientDetail.standardInfo.kciaIngredientId,
            nameKo: ingredientDetail.standardInfo.nameKo,
            nameEn: ingredientDetail.standardInfo.nameEn,
            functionNames: ingredientDetail.standardInfo.functionNames,
            scoreRatePicUrl:
              ingredientDetail.ewgDataResponseDto?.scoreRatePicUrl,
            countryProhibits: ingredientDetail.countryProhibits,
          }),
        );
      } else {
        message.warn('최대 3개까지 담을 수 있습니다.');
      }
    }
  };

  return (
    <IngredientDetailBlock>
      <Spin spinning={ingredientDetailLoading}>
        <Row
          justify="space-between"
          align="middle"
          style={{ marginBottom: 8 }}
          gutter={[0, 8]}
        >
          <Col>
            <Row align="middle" gutter={16}>
              <Col>
                <Typography.Title>
                  {ingredientDetail?.standardInfo.nameKo || '-'}
                </Typography.Title>
              </Col>
              <Col>
                <Typography.Text
                  style={{ marginTop: 3, wordBreak: 'break-all' }}
                  color="slate"
                  medium
                >
                  {ingredientDetail?.standardInfo.nameEn || '-'}
                </Typography.Text>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row align="middle" gutter={[8, 8]}>
              <Col>
                <StyledButton
                  color="#4e5968"
                  backgroundColor="#eff1f8"
                  onClick={handleAddToBox}
                >
                  <PlusGray
                    src={`${s3AssetDomain}/plus_gray.png`}
                    alt="plus_gray"
                  />
                  <Typography.Text type="secondary">
                    성분 비교함에 담기
                  </Typography.Text>
                </StyledButton>
              </Col>
              {!!ingredientDetail?.containMaterialCount && (
                <Col>
                  <StyledButton
                    disabled={ingredientDetail?.containMaterialCount === 0}
                    color="#fff"
                    backgroundColor="#00b9e1"
                    onClick={() =>
                      history.push(
                        `${path.material.rawMaterial.search}?inciName=${ingredientDetail?.standardInfo.nameEn}`,
                      )
                    }
                  >
                    <Typography.Text
                      type="secondary"
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      해당 성분을 포함하는 원료{' '}
                      {ingredientDetail?.containMaterialCount}개 보기
                    </Typography.Text>
                  </StyledButton>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <IngredientStandardDetail
          standardInfo={ingredientDetail?.standardInfo}
          structurePicUrl={
            ingredientDetail?.ewgDataResponseDto?.structurePicUrl
          }
        />
        <Row style={{ marginTop: 36, marginBottom: 8 }}>
          <Col flex="auto">
            <Typography.Title> EWG Skin Deep</Typography.Title>
          </Col>
          <Col style={{ paddingTop: 14 }}>
            <Typography.Label
              type="disabled"
              style={{ fontSize: 12, height: 22, lineHeight: '14px' }}
            >
              DB 업데이트
            </Typography.Label>
            <Typography.Text
              type="tertiary"
              medium
              color="slate"
              inline
              gutter={{ left: 8 }}
            >
              [EWG Skin deep]
            </Typography.Text>
            <Typography.Text
              type="tertiary"
              medium
              color="slate"
              inline
              gutter={{ left: 4 }}
            >
              {ewgUpdatedDate}
            </Typography.Text>
          </Col>
        </Row>
        <StyledDescriptions
          bordered
          column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
        >
          <Descriptions.Item label="EWG Skin Deep Score">
            {ingredientDetail?.ewgDataResponseDto?.scoreRatePicUrl ? (
              <EWGScoreImg
                src={ingredientDetail?.ewgDataResponseDto.scoreRatePicUrl}
                alt="ewg"
              />
            ) : (
              '-'
            )}
          </Descriptions.Item>
          <Descriptions.Item label="EWG Skin Deep Data">
            {ingredientDetail?.ewgDataResponseDto?.ingredientData || '-'}
          </Descriptions.Item>
          <Descriptions.Item label="EWG Skin Deep Url" span={2}>
            {ingredientDetail?.ewgDataResponseDto?.ewgPageUrl ? (
              <GotoEWG
                onClick={() =>
                  window.open(ingredientDetail?.ewgDataResponseDto?.ewgPageUrl)
                }
              >
                사이트 가기
              </GotoEWG>
            ) : (
              '-'
            )}
          </Descriptions.Item>
        </StyledDescriptions>
        <Row style={{ marginTop: 36, marginBottom: 8 }}>
          <Col flex="auto">
            <Typography.Title>국가별 성분 규제</Typography.Title>
          </Col>
          <Col style={{ paddingTop: 12 }}>
            <Typography.Label
              type="disabled"
              style={{ fontSize: 12, height: 22, lineHeight: '14px' }}
            >
              DB 업데이트
            </Typography.Label>
            <Typography.Text
              type="tertiary"
              medium
              color="slate"
              inline
              gutter={{ left: 8 }}
            >
              [국가별 성분 규제]
            </Typography.Text>
            <Typography.Text
              type="tertiary"
              medium
              color="slate"
              inline
              gutter={{ left: 4 }}
            >
              {prohibitDataUpdatedDate}
            </Typography.Text>
          </Col>
        </Row>
        <StyledDescriptions bordered column={1}>
          <Descriptions.Item label="제한">
            {!_.isEmpty(ingredientDetail?.countryProhibits)
              ? ingredientDetail?.countryProhibits
                  ?.filter((c) => c.type === 'limit')
                  .map((country, index) => (
                    <img
                      key={index}
                      src={`https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/flag/${
                        countryCodeMap[country.countryNameKo]
                      }.png`}
                      alt={country.countryNameKo}
                      onClick={() => {
                        handleClickMore(
                          ingredientDetail?.countryProhibits,
                          country.countryNameKo,
                        );
                      }}
                      style={{
                        width: '20px',
                        cursor: 'pointer',
                        ...(index % 4 !== 0 && { marginLeft: 2 }),
                      }}
                    />
                  ))
              : '-'}
          </Descriptions.Item>
          <Descriptions.Item label="금지">
            {!_.isEmpty(ingredientDetail?.countryProhibits)
              ? ingredientDetail?.countryProhibits
                  ?.filter((c) => c.type === 'prohibit')
                  .map((country, index) => (
                    <img
                      key={index}
                      src={`https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/flag/${
                        countryCodeMap[country.countryNameKo]
                      }.png`}
                      alt={country.countryNameKo}
                      onClick={() => {
                        handleClickMore(
                          ingredientDetail?.countryProhibits,
                          country.countryNameKo,
                        );
                      }}
                      style={{
                        width: '20px',
                        cursor: 'pointer',
                        ...(index % 4 !== 0 && { marginLeft: 2 }),
                      }}
                    />
                  ))
              : '-'}
          </Descriptions.Item>
        </StyledDescriptions>
        <Typography.Title style={{ marginTop: 36, marginBottom: 12 }}>
          명칭 변경 이력
        </Typography.Title>
        <StyledDescriptions bordered column={1}>
          {!_.isEmpty(
            ingredientDetail?.standardInfo.kciaNameChangeHistories,
          ) ? (
            _.map(
              ingredientDetail?.standardInfo.kciaNameChangeHistories,
              (n, index) => (
                <Descriptions.Item label={n.changeDate} key={index}>
                  {n.changeHistory}
                </Descriptions.Item>
              ),
            )
          ) : (
            <Descriptions.Item label="-">-</Descriptions.Item>
          )}
        </StyledDescriptions>
        <CountryProhibitInfosModal
          countryInfos={selectedCountryInfos}
          defaultCountryName={selectedCountryName}
          onClose={handleCloseCountryInfosModal}
        />
      </Spin>
    </IngredientDetailBlock>
  );
};

export default IngredientDetail;
