import { useHistory, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Form, message, Modal } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { messages } from 'lib/consts';
import palette from 'lib/styles/palette';
import {
  addProduct,
  updateProduct,
  setNextPathname,
  setSkipMode,
  getDocuments,
  initializeDocuments,
} from 'modules/product';
import UpdateLog from 'components/product/UpdateLog';
import ProductBasicForm from 'components/product/ProductBasicForm';
import { useManufacturerCompanies } from 'service/manufacturer/company';
import { useGetDuplicatedProduct } from 'service/brand/product/product';

const ProductBasicContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const params = useParams();
  const updateMode = typeof params.productId !== 'undefined';
  const productId = Number(params.productId);

  const { data: manufacturers = [] } = useManufacturerCompanies();
  const {
    user,
    company,
    currentProduct,
    fetchProductLoading,
    updateProductLoading,
  } = useSelector(
    ({ auth, company, product, loading }) => ({
      user: auth.user,
      company: company.company,
      currentProduct: product.currentProduct,
      fetchProductLoading: loading['product/ADD_PRODUCT'],
      updateProductLoading: loading['product/UPDATE_PRODUCT'],
    }),
    shallowEqual,
  );
  const { manufacturerId, isManufacturer, companyId } = user;
  const {
    getDuplicatedProduct,
    getDuplicatedProductLoading,
  } = useGetDuplicatedProduct();
  const checkIsDuplicatedProduct = (product) => {
    if (!product) {
      return false;
    }
    if (updateMode) {
      if (product.productId === productId) {
        return false;
      }
    }
    return true;
  };
  const productDupCheckHook = () => {
    let netWeightUnit = form.getFieldValue('netWeightUnit');
    if (netWeightUnit === 'others') {
      netWeightUnit = form.getFieldValue('netWeightUnitDirect');
    }
    getDuplicatedProduct(
      {
        companyId,
        productNameEn: form.getFieldValue('productNameEn'),
        netWeight: form.getFieldValue('netWeight'),
        netWeightUnit,
      },
      {
        onSuccess: (res) => {
          const dupProduct = res.data.result;
          if (!checkIsDuplicatedProduct(dupProduct)) {
            Modal.confirm({
              width: 420,
              content: (
                <>
                  <span style={{ color: palette.primary }}>*</span>
                  화장품제조업자(영문)와{' '}
                  <span style={{ color: palette.primary }}>*</span>
                  화장품제조업자 주소(영문)를 cGMP
                  <br /> 또는 ISO22716에 등록된 영문명과 영문 주소로 동일하게
                  입력하셨습니까?
                </>
              ),
              okText: '네, 동일하게 입력했습니다.',
              cancelText: '아니오',
              icon: null,
              onOk: () => {
                submitProduct();
              },
            });
          }
          if (checkIsDuplicatedProduct(dupProduct)) {
            if (dupProduct.status === 'PRD-RDY') {
              Modal.confirm({
                width: 420,
                style: { textAlign: 'center' },
                content: (
                  <p>
                    이미 등록 중인 제품명과 용량입니다.
                    <br />
                    해당 제품을 이어서 등록하시겠습니까?
                  </p>
                ),
                okText: '네',
                cancelText: '아니오',
                icon: null,
                onOk: () => {
                  onOkProductDupCheckModal(dupProduct);
                },
              });
            } else {
              Modal.info({
                width: 420,
                style: { textAlign: 'center' },
                content: (
                  <p>
                    기존에 등록한 제품 중 같은 이름으로
                    <br />
                    등록된 동일 용량의 제품이 있습니다.
                  </p>
                ),
                okText: '확인',
                cancelText: null,
                icon: null,
              });
            }
          }
        },
      },
    );
  };

  const submitProduct = () => {
    let {
      netWeightUnitDirect,
      netWeightUnit,
      ...restFormValues
    } = form.getFieldsValue();
    if (netWeightUnit === 'others') netWeightUnit = netWeightUnitDirect;
    if (!updateMode) {
      dispatch(
        addProduct({
          ...restFormValues,
          netWeightUnit,
          manufacturerNameKo: manufacturers.find(
            ({ manufacturerId }) =>
              manufacturerId === form.getFieldValue('manufacturerId'),
          ).companyNameKo,
          companyId,
        }),
      );
    } else {
      const { productDetail } = currentProduct;
      let {
        brandNameEn,
        productNameKo,
        productNameEn,
        manufacturerId,
        manufacturerNameEn,
        manufacturerAddressEn,
        manufacturerTel,
        manufacturerFax,
        netWeight,
        netWeightUnit,
        netWeightUnitDirect,
      } = form.getFieldsValue();

      if (netWeightUnit === 'others') netWeightUnit = netWeightUnitDirect;
      const updateFormValues = {
        ...(brandNameEn !== productDetail.brandNameEn && { brandNameEn }),
        ...(productNameKo !== productDetail.productNameKo && { productNameKo }),
        ...(productNameEn !== productDetail.productNameEn && { productNameEn }),
        ...(netWeight !== currentProduct.netWeight && { netWeight }),
        ...(netWeightUnit !== currentProduct.netWeightUnit && {
          netWeightUnit,
        }),
        ...(productNameEn !== productDetail.productNameEn && { productNameEn }),
        ...(manufacturerId !== productDetail.manufacturerId && {
          manufacturerId,
        }),
        ...(manufacturerId !== productDetail.manufacturerId && {
          manufacturerNameKo: manufacturers.find(
            (manufacturer) => manufacturer.manufacturerId === manufacturerId,
          ).companyNameKo,
        }),
        ...(manufacturerNameEn !== productDetail.manufacturerNameEn && {
          manufacturerNameEn,
        }),
        ...(manufacturerAddressEn !== productDetail.manufacturerAddressEn && {
          manufacturerAddressEn,
        }),
        ...(manufacturerTel !== productDetail.manufacturerTel && {
          manufacturerTel,
        }),
        ...(manufacturerFax !== productDetail.manufacturerFax && {
          manufacturerFax,
        }),
      };
      if (Object.keys(updateFormValues).length === 0) {
        return message.warn(messages.NO_NEED_TO_UPDATE);
      }

      dispatch(
        updateProduct({
          productId,
          ...updateFormValues,
        }),
      );
    }
  };

  const onOkProductDupCheckModal = (duplicatedProduct) => {
    const { productId, productCategory, productCountries } = duplicatedProduct;
    // HINT : 중복된 제품의 카테고리가 입력전이면 카테고리 등록 페이지로 바로 이동
    if (productCategory === null) {
      dispatch(setSkipMode(true));
      dispatch(setNextPathname('cat'));
      dispatch(getDocuments(productId));
      history.replace(`/product/${productId}/cat`);
      return;
    }

    // HINT : 중복된 제품의 국가가 입력전이면 국가 등록 페이지로 바로 이동
    if (productCountries === null) {
      dispatch(setSkipMode(true));
      dispatch(setNextPathname('country'));
      history.replace(`/product/${productId}/country`);
      return;
    }

    // HINT : 중복된 제품의 상세화면으로 이동
    history.replace(`/product/${productId}`);
  };

  useEffect(() => {
    dispatch(initializeDocuments());
    if (!updateMode) {
      form.resetFields();
      dispatch(setSkipMode(true));
    }
    form.setFieldsValue({
      brandCompanyNameKo: company.companyNameKo,
      brandCompanyNameEn: company.companyNameEn,
      brandCompanyAddressKo: company.addressKo,
      brandCompanyAddressEn: company.addressEn,
    });
  }, [updateMode]);

  useEffect(() => {
    if (!updateMode) {
      dispatch(setNextPathname('basic'));
    } else {
      const {
        brandNameEn,
        productNameKo,
        productNameEn,
        manufacturerId,
        manufacturerNameEn,
        manufacturerAddressEn,
        manufacturerTel,
        manufacturerFax,
      } = currentProduct.productDetail;
      const { netWeight, netWeightUnit } = currentProduct;
      form.setFieldsValue({
        brandNameEn,
        productNameKo,
        productNameEn,
        manufacturerId,
        manufacturerNameEn,
        manufacturerAddressEn,
        manufacturerTel,
        manufacturerFax,
        netWeight,
        netWeightUnit,
      });
    }
  }, []);

  useEffect(() => {
    if (!updateMode && manufacturerId && manufacturers.length > 0) {
      form.setFieldsValue({
        manufacturerNameEn: manufacturers.find(
          (manufacturer) => manufacturer.manufacturerId === manufacturerId,
        ).companyNameEn,
        manufacturerId,
      });
    }
  }, [manufacturers]);

  useEffect(() => {
    if (!updateMode && process.env.NODE_ENV === 'development') {
      form.setFieldsValue({
        brandNameEn: 'Dowith',
        productNameKo: '두윗 세럼',
        productNameEn: `Dowith Serum-${moment().format('YY-MM-DD HH:mm')}`,
        ...(!isManufacturer && { manufacturerId: 1 }),
        manufacturerNameEn: 'LK Systems',
        manufacturerAddressEn:
          '12-11 Doekgogae-gil, Jeonui-myeon, Sejong Metropolitan Autonomous City',
        manufacturerTel: '0212345678',
        manufacturerFax: '0212345679',
        netWeight: 10,
        netWeightUnit: 'mL',
      });
    }
  }, [updateMode]);

  return (
    <>
      {updateMode && <UpdateLog productId={productId} documentCode="basic" />}
      <ProductBasicForm
        updateMode={updateMode}
        form={form}
        fetchLoading={
          getDuplicatedProductLoading ||
          fetchProductLoading ||
          updateProductLoading
        }
        manufacturers={manufacturers}
        isManufacturer={isManufacturer}
        onFormFinished={productDupCheckHook}
      />
    </>
  );
};

export default ProductBasicContainer;
