import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Col, message, Row, Spin } from 'antd';

import { messages } from 'lib/consts';
import { createBlobFile } from 'lib/file';
import { addDocStatus, updateDocStatus } from 'modules/product';
import FileUploadTemplate from 'templates/FileUploadTemplate';
import UpdateLog from 'components/product/UpdateLog';
import CorrectRequestMessageModal from 'components/CorrectRequestMessageModal';
import { useManufacturerBizLicenseEnUrl } from 'service/company';
import { EDocumentCode } from 'types/product';
import { useProductDocStatus } from 'service/product';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';

const ProductCOBRMContainer = () => {
  const documentCode = EDocumentCode.COBRM;
  const params = useParams<{ productId: string; countryId: string }>();
  const currentProduct = useCurrentProduct();
  const { productId } = currentProduct;
  const countryId = useCountryId();
  const productDocStatus = useProductDocStatus({
    productId,
    countryId,
    documentCode,
  });
  const dispatch = useDispatch();
  const { readOnlyMode, fetchLoading } = useSelector(
    ({ loading, certificate }: any) => ({
      readOnlyMode: certificate.readOnlyMode,
      fetchLoading:
        loading['product/ADD_PRODUCT_DOC_STATUS'] ||
        loading['product/UPDATE_PRODUCT_DOC_STATUS'],
    }),
    shallowEqual,
  );
  const updateMode = productDocStatus !== null;
  const [file, setFile] = useState<null | File | { name: string; url: string }>(
    null,
  );
  const {
    manufacturerBizLicenseEnUrl,
    getLoading: getManufacturerBizLicenseEnUrlLoading,
  } = useManufacturerBizLicenseEnUrl(!updateMode ? productId : undefined);
  const [
    convertManufacturerBizLicenseEnLoading,
    setConvertManufacturerBizLicenseEnLoading,
  ] = useState(false);
  const onDelete = () => {
    setFile(null);
  };

  useEffect(() => {
    if (productDocStatus) {
      setFile({
        name:
          productDocStatus.filename ||
          productDocStatus.uploadFileUrl
            .slice(productDocStatus.uploadFileUrl.lastIndexOf('/') + 1)
            .replace(/_\d{14}/, ''),
        url: productDocStatus.uploadFileUrl,
      });
    }
  }, [productDocStatus]);

  const onSubmit = () => {
    if (!file) return message.warning(messages.REQUIRED_FILE);
    if ('url' in file && file.url) {
      return message.warning('파일이 기존 파일과 같아 수정할 필요가 없습니다.');
    }
    if (!updateMode) {
      dispatch(
        addDocStatus({
          documentCode,
          productId: currentProduct.productId,
          documentFile: file,
          ...(params.countryId && { countryId: params.countryId }),
        }),
      );
    } else {
      dispatch(
        updateDocStatus({
          documentCode,
          productId,
          documentFile: file,
          ...(params.countryId && { countryId: params.countryId }),
          status: productDocStatus!.status,
          productDocStatusId: productDocStatus!.productDocStatusId,
        }),
      );
    }
  };

  useEffect(() => {
    (async () => {
      if (manufacturerBizLicenseEnUrl !== null) {
        setConvertManufacturerBizLicenseEnLoading(true);
        const blob = await createBlobFile(manufacturerBizLicenseEnUrl);
        const file = new File(
          [blob],
          decodeURIComponent(
            manufacturerBizLicenseEnUrl.slice(
              manufacturerBizLicenseEnUrl.lastIndexOf('/') + 1,
            ),
          ),
          {
            type: 'application/pdf',
          },
        );
        setFile(file);
        setConvertManufacturerBizLicenseEnLoading(false);
      }
    })();
  }, [manufacturerBizLicenseEnUrl]);
  return (
    <Spin
      spinning={
        getManufacturerBizLicenseEnUrlLoading ||
        convertManufacturerBizLicenseEnLoading
      }
    >
      <Row justify="end" gutter={8}>
        <Col>
          <CorrectRequestMessageModal documentCode={documentCode} />
        </Col>
        {updateMode && (
          <Col>
            <UpdateLog
              productId={productId}
              countryId={countryId}
              documentCode={documentCode}
            />
          </Col>
        )}
      </Row>
      <FileUploadTemplate
        updateMode={updateMode}
        readOnly={readOnlyMode}
        files={file}
        fetchLoading={fetchLoading}
        accept=".pdf"
        viewerVisible={true}
        footerVisible={true}
        status={productDocStatus?.status}
        onUpload={setFile}
        onDelete={onDelete}
        onSubmit={onSubmit}
      />
    </Spin>
  );
};

export default ProductCOBRMContainer;
