import React from 'react';
import { PageHeader } from 'antd';
import { nanoid } from 'nanoid';
import styled from 'styled-components';
import { GlassMagnifier } from 'react-image-magnifiers';
import { useHistory } from 'react-router-dom';

import NoticeCard from 'components/system/data/NoticeCard';
import Typography from 'components/system/general/Typography';
import { ReactComponent as BackArrow } from 'asset/svg/back_arrow.svg';

const PageTemplateBlock = styled.div`
  margin: 0 auto;
`;

const ExampleBlock = styled.div`
  margin: 32px 0;
`;

const ChildrenBlock = styled.div`
  padding-top: 36px;
`;

export interface IPageTemplateProps {
  title?: string;
  subtitle?: React.ReactNode;
  description?: string;
  notices?: React.ReactNode[];
  subInfo?: string;
  exampleImg?: string;
  exampleImgWidth?: number;
  back?: boolean;
  magnify?: boolean;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  childrenStyle?: React.CSSProperties;
  onBack?: () => void;
}

const PageTemplate = ({
  title,
  subtitle,
  description,
  notices,
  subInfo,
  exampleImg,
  exampleImgWidth = 800,
  back = true,
  magnify = true,
  style,
  children,
  childrenStyle,
  onBack,
}: IPageTemplateProps) => {
  const history = useHistory();
  const handleBack = () => {
    if (onBack) {
      onBack();
    } else {
      history.goBack();
    }
  };

  return (
    <PageTemplateBlock style={style}>
      {title && (
        <PageHeader
          title={
            <Typography.Headline type="secondary">{title}</Typography.Headline>
          }
          subTitle={subtitle}
          backIcon={<BackArrow />}
          style={{ padding: 0 }}
          onBack={back ? handleBack : undefined}
        />
      )}
      {description && (
        <Typography.Text type="secondary" gutter={{ top: 4 }} color="gray">
          {description}
        </Typography.Text>
      )}
      {notices && (
        <NoticeCard title="안내사항">
          <ul>
            {notices.map((notice) => (
              <li key={nanoid()}>{notice}</li>
            ))}
          </ul>
        </NoticeCard>
      )}
      {subInfo}
      {exampleImg && (
        <ExampleBlock>
          <Typography.Title type="secondary" style={{ marginBottom: 16 }}>
            업로드 예시
          </Typography.Title>
          {magnify ? (
            <GlassMagnifier
              imageSrc={`https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/document_example/${exampleImg}`}
              imageAlt=""
              largeImageSrc={`https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/document_example/${exampleImg}`}
              cursorStyle="zoom-in"
              magnifierSize="50%"
              style={{ maxWidth: exampleImgWidth, margin: '0 auto' }}
            />
          ) : (
            <img
              src={`https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/document_example/${exampleImg}`}
              alt="example"
              style={{
                display: 'block',
                maxWidth: exampleImgWidth,
                margin: '0 auto',
              }}
            />
          )}
        </ExampleBlock>
      )}
      <ChildrenBlock style={childrenStyle}>{children}</ChildrenBlock>
    </PageTemplateBlock>
  );
};

export default PageTemplate;
