import { Suspense, useState } from 'react';
import styled from 'styled-components';
import { Button, Input, Modal, Spin } from 'antd';

import { Typography } from 'components/system';
import FooterBox from 'components/FooterBox';
import { CloseIcon } from 'components/system/general/icon';
import { useProductDocStatus } from 'service/product';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';
import { EDocumentCode } from 'types/product';

const StyledButton = styled(Button)`
  position: relative;
  height: 32px;

  .material-icons {
    transform: scaleX(-1);
    font-size: 16px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 8px;
    left: 50%;
    display: block;
    width: 16px;
    height: 16px;
    background-color: #2cf4f0;
    transform: translateX(-50%);
    opacity: 0.44;
    border-radius: 50%;
    animation-name: flicker;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;

    @keyframes flicker {
      from {
        transform: scale(1);
      }

      50% {
        transform: scale(3.5);
      }

      to {
        transform: scale(1);
      }
    }
  }
`;

const StyledTextArea = styled(Input.TextArea)`
  &:hover,
  &:focus {
    border-color: #d9d9d9;
  }
`;

const CorrectRequestMessageModal = ({
  documentCode,
}: {
  documentCode: EDocumentCode;
}) => {
  const { productId } = useCurrentProduct();
  const countryId = useCountryId();
  const productDocStatus = useProductDocStatus({
    productId,
    countryId,
    documentCode,
  });
  const [visible, setVisible] = useState(false);
  if (productDocStatus === null || productDocStatus.status !== 'MOD') {
    return null;
  }
  return (
    <>
      <StyledButton
        className="ant-btn-gray"
        icon={<i className="material-icons outlined gray">textsms</i>}
        onClick={() => setVisible(true)}
      >
        보완 문구 보기
      </StyledButton>
      <Modal
        visible={visible}
        width={800}
        footer={null}
        onCancel={() => setVisible(false)}
        closeIcon={<CloseIcon />}
      >
        <Typography.Title>보완 문구 보기</Typography.Title>
        <StyledTextArea
          rows={5}
          value={productDocStatus.correctRequestMessage || ''}
          readOnly
          style={{ marginTop: 16, cursor: 'default' }}
        />
        <FooterBox style={{ paddingTop: 24 }}>
          <Button type="primary" onClick={() => setVisible(false)}>
            확인
          </Button>
        </FooterBox>
      </Modal>
    </>
  );
};

const SuspendedCorrectRequestMessageModal = ({
  documentCode,
}: {
  documentCode: EDocumentCode;
}) => {
  return (
    <Suspense fallback={<Spin />}>
      <CorrectRequestMessageModal documentCode={documentCode} />
    </Suspense>
  );
};

export default SuspendedCorrectRequestMessageModal;
