import { Form, InputNumber, Radio } from 'antd';

import * as S from './Styled';
import { Typography } from 'components/system';
import { requireRule } from 'lib/validate';

const SpecificGravityForm = ({
  readOnlyMode,
  onChangeSpecificGravityRadioGroup,
}: {
  readOnlyMode: boolean;
  onChangeSpecificGravityRadioGroup: (
    isExistedSpecificGravity: boolean,
  ) => void;
}) => {
  return (
    <S.StyledFormItem label="Specific Gravity" required>
      <Form.Item name="isExistedSpecificGravity" noStyle initialValue={true}>
        <Radio.Group
          disabled={readOnlyMode}
          onChange={(e) => onChangeSpecificGravityRadioGroup(e.target.value)}
        >
          <Radio value={true}>입력하기</Radio>
          <Radio value={false}>해당 없음</Radio>
        </Radio.Group>
      </Form.Item>
      <S.StyledRow style={{ marginTop: 8 }}>
        <Typography.Text gutter={{ right: 4 }}>정상 범위</Typography.Text>
        <Form.Item
          shouldUpdate={(prev, next) =>
            prev.isExistedSpecificGravity !== next.isExistedSpecificGravity ||
            prev.specificGravityLimitMax !== next.specificGravityLimitMax ||
            prev.specificGravityLimitMin !== next.specificGravityLimitMin
          }
          noStyle
        >
          {({ getFieldValue }) => {
            const isExistedSpecificGravity = getFieldValue(
              'isExistedSpecificGravity',
            );
            const specificGravityLimitMax = getFieldValue(
              'specificGravityLimitMax',
            );
            const specificGravityLimitMin = getFieldValue(
              'specificGravityLimitMin',
            );
            return (
              <>
                {isExistedSpecificGravity ? (
                  <S.AntdExplainWrapper>
                    <Form.Item
                      name="specificGravityLimitMin"
                      dependencies={['specificGravityLimitMax']}
                      initialValue={0.9}
                      rules={[
                        requireRule,
                        {
                          validator: (_, specificGravityLimitMinValue) => {
                            if (
                              specificGravityLimitMinValue >
                              specificGravityLimitMax
                            ) {
                              return Promise.reject(
                                '좌측 정상 범위는 우측 정상 범위 보다 클 수 없음',
                              );
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <InputNumber
                        precision={5}
                        step={0.1}
                        min={0.5}
                        max={1.9}
                        disabled={readOnlyMode}
                      />
                    </Form.Item>
                  </S.AntdExplainWrapper>
                ) : (
                  <Form.Item>
                    <InputNumber disabled />
                  </Form.Item>
                )}
                <Typography.Text
                  style={{
                    display: 'inline-block',
                    width: '16px',
                    textAlign: 'center',
                  }}
                >
                  ~
                </Typography.Text>
                {isExistedSpecificGravity ? (
                  <Form.Item
                    name="specificGravityLimitMax"
                    initialValue={1.1}
                    rules={[requireRule]}
                  >
                    <InputNumber
                      precision={5}
                      step={0.1}
                      min={0}
                      max={1.9}
                      disabled={readOnlyMode}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item>
                    <InputNumber disabled />
                  </Form.Item>
                )}
                <Typography.Text gutter={{ left: 16, right: 4 }}>
                  수치
                </Typography.Text>
                {isExistedSpecificGravity ? (
                  <Form.Item
                    name="specificGravity"
                    style={{ width: 120 }}
                    required
                    dependencies={[
                      'specificGravityLimitMin',
                      'specificGravityLimitMax',
                    ]}
                    rules={[
                      requireRule,
                      {
                        validator: (_, specificGravity) => {
                          if (
                            specificGravity < specificGravityLimitMin ||
                            specificGravity > specificGravityLimitMax
                          ) {
                            return Promise.reject('정상 범위를 벗어난 수치');
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <InputNumber
                      precision={5}
                      step={0.1}
                      disabled={readOnlyMode}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item>
                    <InputNumber disabled />
                  </Form.Item>
                )}
              </>
            );
          }}
        </Form.Item>
      </S.StyledRow>
    </S.StyledFormItem>
  );
};

export default SpecificGravityForm;
