import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Input, message, Modal, Row } from 'antd';

import { messages } from 'lib/consts';
import { exceptKoreanRule, phoneRule, requireRule } from 'lib/validate';
import { toggleCompanyInfoModalVisible } from 'modules/certificate';
import { updateCompanyInfo } from 'modules/product';
import FooterBox from 'components/FooterBox';
import UpdateLog from 'components/product/UpdateLog';
import CorrectRequestMessageModal from 'components/CorrectRequestMessageModal';
import { EDocumentCode } from 'types/product';
import { useCurrentProduct } from 'service/brand/product/product';

const CompanyInfoModal = () => {
  const dispatch = useDispatch();
  const { productDetail } = useCurrentProduct();
  const visible = useSelector(
    ({ certificate }: any) => certificate.companyInfoModalVisible,
  );
  const { estimateTarget, readOnlyMode } = useSelector(
    ({ certificate }: any) => ({
      estimateTarget: certificate.certificate.estimateTarget,
      readOnlyMode: certificate.readOnlyMode,
    }),
    shallowEqual,
  );
  const { productId, countryId } = estimateTarget;
  const submitLoading = useSelector(
    ({ loading }: any) => loading['product/UPDATE_COMPANY_INFO'],
  );
  const [form] = Form.useForm();
  const onClose = () => {
    dispatch(toggleCompanyInfoModalVisible());
  };
  const onSubmit = ({
    manufacturerNameKo,
    manufacturerNameEn,
    manufacturerAddressEn,
    manufacturerTel,
    manufacturerFax,
  }: any) => {
    const updateValues = {
      ...(manufacturerNameKo !== productDetail.manufacturerNameKo && {
        manufacturerNameKo,
      }),
      ...(manufacturerNameEn !== productDetail.manufacturerNameEn && {
        manufacturerNameEn,
      }),
      ...(manufacturerAddressEn !== productDetail.manufacturerAddressEn && {
        manufacturerAddressEn,
      }),
      ...(manufacturerTel !== productDetail.manufacturerTel && {
        manufacturerTel,
      }),
      ...(manufacturerFax !== productDetail.manufacturerFax && {
        manufacturerFax,
      }),
    };
    if (Object.keys(updateValues).length === 0) {
      return message.warn(messages.NO_NEED_TO_UPDATE);
    }
    dispatch(updateCompanyInfo({ productId, countryId, ...updateValues }));
  };
  useEffect(() => {
    if (productDetail) {
      form.setFieldsValue(productDetail);
    }
  }, [productDetail]);
  return (
    <Modal
      visible={visible}
      title="회사 정보"
      footer={null}
      width={700}
      destroyOnClose
      maskClosable={false}
      onCancel={onClose}
    >
      <Row justify="end" gutter={8}>
        <Col>
          <CorrectRequestMessageModal documentCode={EDocumentCode.CI} />
        </Col>
        <Col>
          <UpdateLog
            productId={productId}
            countryId={countryId}
            documentCode={EDocumentCode.BASIC}
            validCodes={[
              'BASIC01-MNK',
              'BASIC01-MNE',
              'BASIC01-MAE',
              'BASIC01-MT',
              'BASIC01-MF',
            ]}
          />
        </Col>
      </Row>

      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Form.Item
          label="화장품제조업자 (국문)"
          name="manufacturerNameKo"
          required
          rules={[requireRule]}
        >
          <Input disabled={readOnlyMode} />
        </Form.Item>
        <Form.Item
          label="화장품제조업자 (영문)"
          name="manufacturerNameEn"
          required
          rules={[requireRule, exceptKoreanRule]}
        >
          <Input disabled={readOnlyMode} />
        </Form.Item>
        <Form.Item
          label="화장품제조업자 주소 (영문)"
          name="manufacturerAddressEn"
          required
          rules={[requireRule, exceptKoreanRule]}
        >
          <Input disabled={readOnlyMode} />
        </Form.Item>
        <Form.Item
          label="화장품제조업자 전화번호"
          name="manufacturerTel"
          required
          rules={[requireRule, phoneRule]}
        >
          <Input placeholder={messages.PHONE_FORMAT} disabled={readOnlyMode} />
        </Form.Item>
        <Form.Item
          label="화장품제조업자 팩스번호"
          name="manufacturerFax"
          required
          rules={[requireRule, phoneRule]}
        >
          <Input placeholder={messages.PHONE_FORMAT} disabled={readOnlyMode} />
        </Form.Item>
        {!readOnlyMode && (
          <FooterBox style={{ marginTop: 20 }}>
            <Button type="primary" loading={submitLoading} htmlType="submit">
              보완 완료
            </Button>
          </FooterBox>
        )}
      </Form>
    </Modal>
  );
};

export default CompanyInfoModal;
